import { contentGenerationByKeywords, contentGenerationByUrl } from "api";
import { useNotification, useReactQuery, useTwitterAuth } from "hooks";
import { usePersonalDetails } from "hooks/Profile";
import { usePostNow, useSchedulePost } from "hooks/Twitter";
import { createContext } from "react";
import { setContents } from "redux/slices/ContentCreation";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import {
  IContentGenerationByKeywordsFormValues,
  IContentGenerationFormByUrlValues,
  IPostNowFormValues,
  ISchedulePostFormValues,
} from "../Twitter/types";

type TContentGenerationContext = {
  isUrlLoading: boolean;
  isKeywordsLoading: boolean;
  handlePostNow: (formValues: IPostNowFormValues) => void;
  handleSchedulePost: (formValues: ISchedulePostFormValues) => void;
  handleContentGenerateByUrl: (
    formValues: IContentGenerationFormByUrlValues
  ) => void;
  handleContentGenerateByKeywords: (
    formValues: IContentGenerationByKeywordsFormValues
  ) => void;
};

export const ContentGenerationContext =
  createContext<TContentGenerationContext | null>(null);

const ContentGenerationProvider = (props: React.PropsWithChildren<{}>) => {
  const postNow = usePostNow();
  const schedulePost = useSchedulePost();
  const notification = useNotification();
  const twitterUser = useTwitterAuth();
  const personalDetails = usePersonalDetails();

  const uid = twitterUser.selectedAccount.uid;
  const timezone = personalDetails.details.timezone;

  const dispatch = useAppDispatch();

  const retweetTime = useAppSelector(
    (state) => state.twitter.thread.retweetDateTime
  );
  const retweet = useAppSelector((state) => state.twitter.thread.autoRetweet);

  const { execute: generateByUrl, isLoading: isUrlLoading } = useReactQuery(
    contentGenerationByUrl
  );

  const { execute: generateByKeywords, isLoading: isKeywordsLoading } =
    useReactQuery(contentGenerationByKeywords);

  const handleContentGenerateByUrl = (
    values: IContentGenerationFormByUrlValues
  ) => {
    generateByUrl(values, {
      onSuccess: (data) => {
        if (data) dispatch(setContents(data.generatedContent));
      },
    });
  };

  const handleContentGenerateByKeywords = (
    values: IContentGenerationByKeywordsFormValues
  ) => {
    generateByKeywords(values, {
      onSuccess: (data) => {
        if (data) dispatch(setContents(data.generatedContent));
      },
    });
  };

  const handlePostNow = (formValues: IPostNowFormValues) => {
    postNow.execute(
      { ...formValues, uid, timezone, retweetTime, retweet },
      {
        onSuccess: () => {
          notification.notifySuccess("Tweet posted successfully");
        },
      }
    );
  };

  const handleSchedulePost = (formValues: ISchedulePostFormValues) => {
    schedulePost.execute(
      {
        ...formValues,
        timezone,
        retweetTime,
        retweet,
      },
      {
        onSuccess: () => {
          notification.notifySuccess("Tweet scheduled successfully");
        },
      }
    );
  };

  return (
    <ContentGenerationContext.Provider
      value={{
        isUrlLoading,
        isKeywordsLoading,
        handlePostNow,
        handleSchedulePost,
        handleContentGenerateByUrl,
        handleContentGenerateByKeywords,
      }}
    >
      {props.children}
    </ContentGenerationContext.Provider>
  );
};

export default ContentGenerationProvider;
