import { useField } from "formik";
import { DateTimePicker } from "components/common";
import { Header, Flex } from "components/core";
import { IScheduleCreateFormValues } from "./types";
import { isToday } from "date-fns";

interface IDateTimeProps {
  name: string;
}

const PostDateTime = (props: IDateTimeProps) => {
  const [field, , helpers] = useField<IScheduleCreateFormValues["datetime"]>(
    props.name
  );

  return (
    <>
      <Header
        as="div"
        font="poppins"
        size="small"
        mb="small"
        content="Select Date & Time"
      />
      <Flex justify="space-between" mb="small">
        <DateTimePicker>
          <DateTimePicker.DatePicker
            value={field.value}
            onChange={(newDate) => helpers.setValue(newDate)}
            minDate={new Date()}
          />
          <DateTimePicker.TimePicker
            value={field.value}
            onChange={(newDate) => helpers.setValue(newDate)}
            minTime={isToday(field.value) ? new Date() : undefined}
          />
        </DateTimePicker>
      </Flex>
    </>
  );
};

export default PostDateTime;
