import styled from "styled-components";
import { Icon } from "components/core";

const QuestionIcon = styled(Icon).attrs({
  name: "question",
})`
  &&& {
    height: 14px;
    width: 14px;
    color: ${({ theme }) => theme.colors.alpha};
    cursor: pointer;
  }
`;

export { QuestionIcon };
