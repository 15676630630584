import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ReactComponent as CalendarIcon } from "assets/icons/common/calendar.svg";
import { Input, Portal } from "components/core";
import { useOnClickOutside, useToggle, usePositionElement } from "hooks/common";
import Container from "./Container";
import InputContainer from "./InputContainer";
import Icon from "./Icon";
import PickerContainer from "./PickerContainer";

interface IDatePickerProps {
  value: Date;
  onChange: (newDate: Date) => void;
  style?: React.CSSProperties;
  name?: string;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  closeOnChange?: boolean;
}

const DEFAULT_DATE_FORMAT = "MMM dd, yyyy";

/* 
  date-fns specific date formats.
  See: https://date-fns.org/v2.28.0/docs/format
*/

const DatePicker = ({
  name,
  value,
  onChange,
  style,
  dateFormat = DEFAULT_DATE_FORMAT,
  closeOnChange,
  className,
  ...props
}: IDatePickerProps) => {
  const [showPicker, setShowPicker] = useToggle();
  const positionElement = usePositionElement({
    itemSize: { width: 320, height: 357 },
  });
  const containerRef = useOnClickOutside<HTMLDivElement>(() =>
    setShowPicker(false)
  );
  const handleTogglePicker = () => setShowPicker();

  const renderPicker = () => {
    if (showPicker)
      return (
        <Portal>
          <PickerContainer
            ref={containerRef}
            position={positionElement.position}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={(newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  if (closeOnChange) {
                    setShowPicker();
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
                {...props}
              />
            </LocalizationProvider>
          </PickerContainer>
        </Portal>
      );

    return null;
  };

  return (
    <Container className={className}>
      <InputContainer
        onClick={(e) => {
          handleTogglePicker();
          positionElement.captureEvent(e);
        }}
      >
        <Input
          name={name}
          readOnly
          value={format(value, dateFormat)}
          style={style}
        />
        <Icon icon={CalendarIcon} />
      </InputContainer>
      {renderPicker()}
    </Container>
  );
};

export default DatePicker;
