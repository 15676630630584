import {
  Dropdown as SemanticDropdown,
  StrictDropdownProps,
} from "semantic-ui-react";
import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

export interface IDropdownProps extends Omit<StrictDropdownProps, "size"> {
  success?: boolean;
  danger?: boolean;
  glow?: boolean;
  style?: React.CSSProperties;
}

const BaseDropdown = styled(SemanticDropdown)<IDropdownProps>`
  &&&& {
    ${({ theme }) => css`
      background-color: unset;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 120px;
      flex-shrink: 0;
      flex-flow: row wrap;
      color: ${theme.colors.alpha};
      border: 1px solid ${theme.colors.extraLightGrey};
      ${helper.getFontSize("extraSmall")};
      ${helper.getBorderRadius("small")};
      font-family: ${theme.fonts.poppins};
      .default.text {
        color: ${theme.colors.lightGrey};
      }
      .ui.label {
        background-color: ${theme.colors.beta};
        color: ${theme.colors.alpha};
        display: inline-flex;
        justify-content: space-between;
        gap: ${helper.getSpacing("extraSmall")};
      }
      .icon,
      &.loading .icon {
        color: ${theme.colors.lightGrey};
        padding: unset !important;
        position: unset;
        display: block;
        margin: unset;
        ::before,
        ::after {
          left: unset;
          right: 5%;
        }
      }
      &&,
      &&&.loading {
        padding: ${helper.getSpacing("extraSmall")}
          ${helper.getSpacing("extraSmall")};
        .icon {
          margin-left: auto;
          ${helper.getFontSize("extraSmall")};
        }
      }
      &&&.active {
        color: ${theme.colors.alpha};
        .default.text {
          color: ${theme.colors.alpha};
        }

        border: 1px solid ${theme.colors.extraLightGrey};
        .menu {
          border: 1px solid ${theme.colors.extraLightGrey};
          ${helper.getBoxShadow("extraLightGrey", {
            intensity: "medium",
            important: true,
          })}
          ${helper.getStyledScrollbar()}
          .item {
            color: ${theme.colors.alpha};
            ${helper.getFontSize("extraSmall")};
            &:hover {
              background-color: ${theme.colors.beta} !important;
              z-index: 1;
            }
          }
        }
      }
    `}
    ${({ glow }) =>
      glow &&
      css`
        ${helper.formControl.getUnfocusedBoxShadow()};
        :focus-within {
          ${helper.formControl.getFocusedBoxShadow()};
        }
      `}
    ${({ success }) =>
      success &&
      css`
        border-color: ${({ theme }) => theme.colors.success};
      `}
    ${({ danger }) =>
      danger &&
      css`
        border-color: ${({ theme }) => theme.colors.danger};
      `}
  }
`;
const Dropdown = (props: IDropdownProps) => <BaseDropdown {...props} />;
Dropdown.Item = SemanticDropdown.Item;
Dropdown.Menu = SemanticDropdown.Menu;
Dropdown.Header = SemanticDropdown.Header;
Dropdown.Divider = SemanticDropdown.Divider;
export default Dropdown;
