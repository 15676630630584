import styled from "styled-components";

const Container = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.colors.lightGrey};
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 0.8rem;
  max-height: 25%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0.2rem;
  }
  ::-webkit-scrollbar-track {
    background-color: unset;
  }
`;

export { Container };
