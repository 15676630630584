import { TwitterService } from "services/Auth";
import { TLeadsTweetCard } from "./types";

type TAPIPayload = {
  potential_customer: boolean;
  potential_lead: boolean;
  influencer: boolean;
};

type TAPIResponse = {
  message: string;
};

class RemoveLeadAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    if (apiResponse.message) {
      return {
        message: apiResponse.message,
      };
    }
  };

  getPostUrl = (url: string, tweetCard: TLeadsTweetCard) =>
    `${url}/${tweetCard.leadUsername}`;

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TLeadsTweetCard): TAPIPayload => {
    return {
      potential_customer: data.isPotentialCustomer,
      potential_lead: true,
      influencer: data.isInfluencer,
    };
  };

  parseRemoveResponse = this.parseResponse;
}

export default RemoveLeadAdapter;
