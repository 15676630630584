import { Container } from "./Actions.styles";
import { Button, Icon } from "components/core";

export interface IActionProps {
  onDelete?: () => void;
  onEdit?: () => void;
}

const Actions = ({ onDelete, onEdit }: IActionProps) => {
  return (
    <Container>
      {onDelete && (
        <Icon name="trash" style={{ cursor: "pointer" }} onClick={onDelete} />
      )}
      {onEdit && (
        <Button variant="alpha" content="Edit" size="mini" onClick={onEdit} />
      )}
    </Container>
  );
};

export default Actions;
