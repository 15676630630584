import UrlHelper from "helpers/UrlHelper";
import { originUrl, backendApiUrl } from "helpers/UrlInstances";

// Base Auth API Url
const baseAuthApiUrl = new UrlHelper(
  backendApiUrl.append("/authentication/v1/public")
);

const baseDolphynAccountApiUrl = new UrlHelper(
  backendApiUrl.append("/profile/v1/public")
);

// Social Media Auth APIs

export const twitterAuthApi = new UrlHelper(baseAuthApiUrl.append("/twitter"));
export const facebookAuthApi = new UrlHelper(
  baseAuthApiUrl.append("/facebook")
);
export const linkedinAuthApi = new UrlHelper(
  baseAuthApiUrl.append("/linkedin")
);
export const instagramAuthApi = new UrlHelper(
  baseAuthApiUrl.append("/instagram")
);

export const SOCIAL_LOGIN_DEFAULT_REDIRECT_URL = originUrl.append(
  (routes) => routes.rootRoutes.signIn
);

export const SOCIAL_LOGIN_TWITTER_AUTH_URL =
  twitterAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.rootRoutes.signIn),
  }));

export const SOCIAL_SIGNUP_TWITTER_AUTH_URL =
  twitterAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.rootRoutes.signUp),
  }));

// Base URLs

// Exposes dolphyn's regular authentication APIs
export const BASE_ACCOUNTS_API_URL = baseDolphynAccountApiUrl;

// Exposes backend oauth APIs used in modules like twitter, social media, etc
export const BASE_AUTHENTICATION_API_URL = baseAuthApiUrl;

// Redirects

export const DEFAULT_RESET_PASSWORD_REDIRECT_URL = originUrl.append(
  (routes) => routes.rootRoutes.resetPassword
);
