import { LocalStorageService } from "services/common";
import { constants } from "config/WebAnalytics";
import { ITrackPerformanceMenuState } from "redux/slices";

class PerformanceMenuService {
  private selection: LocalStorageService<ITrackPerformanceMenuState> =
    new LocalStorageService(
      constants.TRACK_PERFORMANCE_SELECTED_DATA_LOCALSTORAGE
    );

  private storageInitialValue = {
    period: constants.DEFAULT_TRACK_PERFORMANCE_MENU_PERIOD,
    periodType: constants.DEFAULT_TRACK_PERFORMANCE_MENU_PERIOD_TYPE,
  };

  setMenu = (data: ITrackPerformanceMenuState) => {
    this.selection.setItem(data);
  };

  getMenu = () => {
    return this.selection.getOrCreate(this.storageInitialValue);
  };
}

export default PerformanceMenuService;
