import { GraphHelper } from "helpers";
import { SearchConsoleService } from "services/WebAnalytics";
import {
  ISearchConsoleData,
  TClicksGraph,
  TclickThroughRateGraph,
  TCountryHighestClicksGraph,
  TGraphMeta,
  TImpressionsGraph,
  TMostClickedQueryGraph,
  TMostVisitedPageGraph,
  TPositionGraph,
  TQueryHighestClicksGraph,
  TSearchConsoleCardStat,
  TSearchConsoleCardText,
  TSearchConsolePayload,
  TSearchConsoleRecommendation,
} from "./types";

type TAPIResponse = {
  most_visited_page: {
    card_data: TSearchConsoleCardText;
    graph_data: TMostVisitedPageGraph[];
  };
  most_clicked_query: {
    card_data: TSearchConsoleCardText;
    graph_data: TMostClickedQueryGraph[];
  };
  country_highest_clicks: {
    card_data: TSearchConsoleCardText;
    graph_data: TCountryHighestClicksGraph[];
  };
  country_query_highest_clicks: {
    card_data: TSearchConsoleCardText;
    graph_data: TQueryHighestClicksGraph[];
  };
  impressions: {
    card_data: TSearchConsoleCardStat;
    graph_data: TImpressionsGraph[];
  };
  clicks: {
    card_data: TSearchConsoleCardStat;
    graph_data: TClicksGraph[];
  };
  click_through_rate: {
    card_data: TSearchConsoleCardStat;
    graph_data: TclickThroughRateGraph[];
  };
  position: {
    card_data: TSearchConsoleCardStat;
    graph_data: TPositionGraph[];
  };
  recommendations: TSearchConsoleRecommendation[];
};

type TAPIGETPayload = {
  period: number;
  period_type: string;
  google_search_console_site: string;
};

class SearchConsoleAdapter {
  private graphHelper = new GraphHelper();

  private getGraphMetaData = (payload: Record<string, any>[]) => {
    const maxValue = this.graphHelper.findHighestValue(payload);
    const maxAxisValue = this.graphHelper.converToMultiple(maxValue);
    const data: TGraphMeta = {
      maxAxisValue,
    };
    return data;
  };

  private parseResponse = (apiData: TAPIResponse): ISearchConsoleData => {
    const {
      click_through_rate,
      clicks,
      country_highest_clicks,
      country_query_highest_clicks,
      impressions,
      most_clicked_query,
      most_visited_page,
      position,
      recommendations,
    } = apiData;

    const parsedData: ISearchConsoleData = {
      clickThroughRate: {
        ...click_through_rate,
        graphMetaData: this.getGraphMetaData(click_through_rate.graph_data),
      },
      clicks: {
        ...clicks,
        graphMetaData: this.getGraphMetaData(clicks.graph_data),
      },
      countryHighestClicks: {
        ...country_highest_clicks,
        graphMetaData: this.getGraphMetaData(country_highest_clicks.graph_data),
      },
      queryHighestClicks: {
        ...country_query_highest_clicks,
        graphMetaData: this.getGraphMetaData(
          country_query_highest_clicks.graph_data
        ),
      },
      impressions: {
        ...impressions,
        graphMetaData: this.getGraphMetaData(impressions.graph_data),
      },
      mostClickedQuery: {
        ...most_clicked_query,
        graphMetaData: this.getGraphMetaData(most_clicked_query.graph_data),
      },
      mostVisitedPage: {
        ...most_visited_page,
        graphMetaData: this.getGraphMetaData(most_visited_page.graph_data),
      },
      position: {
        ...position,
        graphMetaData: this.getGraphMetaData(position.graph_data),
      },
      recommendation: recommendations,
    };

    return parsedData;
  };

  parseGetResponse = (data: TAPIResponse): ISearchConsoleData => {
    return this.parseResponse(data);
  };

  getGetPayload = async ({
    period,
    periodType,
    site,
  }: TSearchConsolePayload) => {
    const searchConsoleService = new SearchConsoleService();
    const headers = await searchConsoleService.getHeadersOrError();
    const params: TAPIGETPayload = {
      period,
      period_type: periodType,
      google_search_console_site: site,
    };

    return {
      params,
      headers,
    };
  };
}

export default SearchConsoleAdapter;
