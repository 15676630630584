import { Formik } from "formik";
import { useScheduler } from "hooks/SocialMediaPost";
import { IScheduleCreateFormValues } from "./types";

const ScheduleFormProvider = (props: React.PropsWithChildren<{}>) => {
  const scheduler = useScheduler();

  const handleFormikSubmit = (values: IScheduleCreateFormValues) => {
    scheduler.handleSchedule(values);
  };

  return (
    <Formik
      initialValues={scheduler.getInitialValues()}
      onSubmit={handleFormikSubmit}
    >
      {() => props.children}
    </Formik>
  );
};

export default ScheduleFormProvider;
