import WebMatrix from "assets/images/WebAnalytics/web-matrics-landing.png";
import Optimizer from "assets/images/WebAnalytics/optimizer-landing.png";
import PageMatrix from "assets/images/WebAnalytics/page-metrics-landing.png";
import SearchConsole from "assets/images/WebAnalytics/search-console-landing.png";
import { applicationRoutes as routes } from "config/common";

const data = [
  {
    id: 1,
    header: "Web Performace Metrics",
    description:
      "Check the website performance with our special KPIs that help you understand where you miss the ball . Pick those areas and improve gradually of course using our recommendations",
    buttonText: "Get Started",
    image: WebMatrix,
    url: routes.webAnalytics.authorizeGoogleAnalytics,
  },
  {
    id: 2,
    header: "Page Performance Metrics",
    description:
      "Dig down to page level metrics. Check the visits, engagements, conversions, loading speed by Individual website pages. Receive suggestions by pages. You need to understand your page metrics to improve the web performance.",
    buttonText: "Get Started",
    image: PageMatrix,
    url: routes.webAnalytics.authorizeGoogleAnalytics,
  },
  {
    id: 3,
    header: "Google Analytics Optimizer",
    description:
      "You might be missing important Goals or Campaign metrics to capture in your Google Analytics account. Optimize your Google account with the expert suggestions",
    buttonText: "Get Started",
    image: Optimizer,
    url: routes.webAnalytics.authorizeGoogleAnalytics,
  },
  {
    id: 4,
    header: "Google Search Console",
    description:
      "Search console proivdes data about how does your website perform on Search Engine compare to other websites. Set up the account, track the data and receicve suggestions from us",
    buttonText: "Get Started",
    image: SearchConsole,
    url: routes.webAnalytics.authorizeGoogleSearchConsole,
  },
  {
    id: 5,
    header: "User Profile",
    description:
      "Understand new users, returning users, and social media users. Age, Gender, Cities, Landing page, Medium from where are they coming and what is their behavior all you can know here ",
    buttonText: "Get Started",
    image: SearchConsole,
    url: routes.webAnalytics.authorizeGoogleAnalytics,
  },
];

export default data;
