import { GoogleAnalyticsService } from "services/WebAnalytics";
import { IPageMatrixData, TPageMatrixPayload } from "./types";

type TAPIResponseCard = {
  value: string;
  is_nagetive: boolean;
};

type TAPIResponse = {
  cards: {
    audience_engagement: TAPIResponseCard;
    content_interaction: TAPIResponseCard;
    average_time_on_page: TAPIResponseCard;
    average_page_load_time: TAPIResponseCard;
    second_page: TAPIResponseCard;
    new_users_growth: TAPIResponseCard;
    returning_users_growth: TAPIResponseCard;
  };
  recommendations: {
    text: string;
    data: {
      text: string;
    }[];
  }[];
};

type TAPIGETPayload = {
  period: number;
  period_type: string;
  google_analytics_view: string;
  page_title: string;
};

class PageMatrixAdapter {
  private parseResponse = ({
    cards,
    recommendations,
  }: TAPIResponse): IPageMatrixData => {
    const parsedData: IPageMatrixData = {
      cards: {
        audienceEngagement: {
          value: cards.audience_engagement.value,
          isNagetive: cards.audience_engagement.is_nagetive,
        },
        averagePageLoadTime: {
          value: cards.average_page_load_time.value,
          isNagetive: cards.average_page_load_time.is_nagetive,
        },
        averageTimeOnPage: {
          value: cards.average_time_on_page.value,
          isNagetive: cards.average_time_on_page.is_nagetive,
        },
        contentInteraction: {
          value: cards.content_interaction.value,
          isNagetive: cards.content_interaction.is_nagetive,
        },
        newUsersGrowth: {
          value: cards.new_users_growth.value,
          isNagetive: cards.new_users_growth.is_nagetive,
        },
        returningUsersGrowth: {
          value: cards.returning_users_growth.value,
          isNagetive: cards.returning_users_growth.is_nagetive,
        },
      },
      recommendation: recommendations,
    };

    return parsedData;
  };

  parseGetResponse = (data: TAPIResponse): IPageMatrixData => {
    return this.parseResponse(data);
  };

  getGetPayload = async ({
    period,
    periodType,
    view,
    page,
  }: TPageMatrixPayload) => {
    const googleAnalyticsService = new GoogleAnalyticsService();
    const headers = await googleAnalyticsService.getHeadersOrError();
    const params: TAPIGETPayload = {
      period,
      period_type: periodType,
      google_analytics_view: view,
      page_title: page.value,
    };

    return {
      params,
      headers,
    };
  };
}

export default PageMatrixAdapter;
