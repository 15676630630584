import { Form } from "components/core";

interface INotificationsFormProps {
  editable: boolean;
  formId: string;
  onSubmit: () => void;
}

const NotificationsForm = ({
  editable,
  formId,
  onSubmit,
}: INotificationsFormProps) => {
  return (
    <Form>
      <Form.Form id={formId} onSubmit={onSubmit}>
        <Form.Field>
          <Form.Label content="Daily Activities Email" />
          <Form.Toggle
            variant="alpha"
            name="dailyActivity"
            readOnly={!editable}
          />
        </Form.Field>
        <Form.Field>
          <Form.Label content="Newsletter Email" />
          <Form.Toggle variant="alpha" name="newsletter" readOnly={!editable} />
        </Form.Field>
        <Form.Field>
          <Form.Label content="Marketing Email" />
          <Form.Toggle variant="alpha" name="marketing" readOnly={!editable} />
        </Form.Field>
        <Form.Field>
          <Form.Label content="Social Media Post Notification" />
          <Form.Toggle
            variant="alpha"
            name="socialMediaPost"
            readOnly={!editable}
          />
        </Form.Field>
        <Form.Field>
          <Form.Label content="Twitter Email" />
          <Form.Toggle
            variant="alpha"
            name="twitterEmail"
            readOnly={!editable}
          />
        </Form.Field>
      </Form.Form>
    </Form>
  );
};

export default NotificationsForm;
