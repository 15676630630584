import { Icon } from "components/core";
import styled from "styled-components";

const FormContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const InfoIcon = styled(Icon).attrs({
  name: "info",
})`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export { FormContainer, InfoIcon };
