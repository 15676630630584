import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  position: relative;
  height: 80vh;
  width: 60vw;
  ${helper.getBorderRadius("small")};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
})`
  &&& {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

export { Container, CloseIcon };
