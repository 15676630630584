import { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import {
  resetPassword as resetPasswordApi,
  forgotPassword as forgotPasswordApi,
} from "api/Auth";
import { IForgotPassword } from "api/Auth/forgotPassword";
import { IResetPassword } from "api/Auth/resetPassword";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { useNotification, useNavigation, useReactQuery } from "hooks/common";
import {
  useTwitterAuth,
  useFacebookAuth,
  useInstagramAuth,
  useLinkedinAuth,
} from "hooks/Auth";
import {
  setDolphynSignedIn,
  clearData as clearDolphynData,
  setDolphynSignedOut,
} from "redux/slices/Auth";
import {
  cleaGoogleAnalyticsData,
  clearGoogleSearchConsoleData,
  clearGoogleAnalyticsSelections,
  clearSearchConsoleSelection,
} from "redux/slices/WebAnalytics";
import { AuthService } from "services/Auth";

type TContext = {
  isSignedIn: boolean;
  handleSignout: () => void;
  handleSendResetEmail: (data: IForgotPassword) => void;
  handleResetPassword: (
    data: IResetPassword,
    options?: TResetPasswordOptions
  ) => void;
};

type TResetPasswordOptions = {
  onSuccess?: () => void;
};

export const AuthContext = createContext<TContext | null>(null);

const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const navigation = useNavigation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const twitter = useTwitterAuth();
  const fb = useFacebookAuth();
  const instagram = useInstagramAuth();
  const linkedin = useLinkedinAuth();
  const authService = new AuthService();
  const isDolphynAuthenticated = authService.isAuthenticated();

  useEffect(() => {
    isDolphynAuthenticated
      ? dispatch(setDolphynSignedIn())
      : dispatch(setDolphynSignedOut());
  }, [dispatch, isDolphynAuthenticated]);

  const handleSignout = () => {
    authService.clearData();
    twitter.clearData();
    fb.clearData();
    instagram.clearData();
    linkedin.clearData();
    dispatch(clearDolphynData());
    dispatch(cleaGoogleAnalyticsData());
    dispatch(clearGoogleSearchConsoleData());
    dispatch(clearGoogleAnalyticsSelections());
    dispatch(clearSearchConsoleSelection());
    navigation.navigate((routes) => routes.rootRoutes.signIn);
  };

  const resetPassword = useReactQuery(resetPasswordApi, {
    onSuccess: (data) => notification.notifySuccess(data.message),
  });

  const forgotPassword = useReactQuery(forgotPasswordApi, {
    onSuccess: (data) => notification.notifySuccess(data.message),
  });
  const isSignedIn = useAppSelector((state) => state.auth.dolphyn.isSignedIn);

  const handleSendResetEmail = (data: IForgotPassword) =>
    forgotPassword.execute(data);
  const handleResetPassword = (
    data: IResetPassword,
    options?: TResetPasswordOptions
  ) => resetPassword.execute(data, options);

  const isLoading = resetPassword.isLoading || forgotPassword.isLoading;

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        handleSignout,
        handleSendResetEmail,
        handleResetPassword,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
