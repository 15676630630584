import { setDate, getDate } from "date-fns";

class CalendarDaysHelper {
  getViewDate = (date: Date) => {
    return setDate(date, 1);
  };

  getNextDay = (date: Date) => setDate(date, getDate(date) + 1);

  getPreviousDay = (date: Date) => setDate(date, getDate(date) - 1);
}

export default CalendarDaysHelper;
