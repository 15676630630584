import { TPostPreviewMenuData } from "components/SocialMediaPost/SchedulePost/PostPreview/types";
import postPreviewMenuMap from "./postPreviewMenuMap";

const postPreviewMenuData: TPostPreviewMenuData = [
  {
    id: postPreviewMenuMap.twitter,
    title: "Twitter",
  },
  {
    id: postPreviewMenuMap.facebook,
    title: "Facebook",
  },
  {
    id: postPreviewMenuMap.instagram,
    title: "Instagram",
  },
  {
    id: postPreviewMenuMap.linkedIn,
    title: "LinkedIn",
  },
];

export default postPreviewMenuData;
