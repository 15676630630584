import leadGeneration from "assets/images/Twitter/lead-generation-landing.png";
import scheduleRetweet from "assets/images/Twitter/schedule-retweet-landing.png";
import instantReply from "assets/images/Twitter/instant-reply-landing.png";
import analytics from "assets/images/Twitter/analytics-landing.png";
import audit from "assets/images/Twitter/audit-landing.png";
import { applicationRoutes as routes } from "config/common";

const data = [
  {
    id: 1,
    header: "Lead Generation",
    description:
      "Increase your sales through Twitter. Use the Dolphy's smart algorithm to generate leads of Twitter accounts for your business. Communicate with them and promote your business",
    buttonText: "Get Started",
    image: leadGeneration,
    url: routes.easyTwitter.leadGeneration,
  },
  {
    id: 2,
    header: "Schedule Retweet",
    description:
      "Retweeting influencer's tweet is one of the best practice to build strong presence on Twitter. Use our automated system to schedule the Retweets from influencer's account for a month in advance",
    buttonText: "Get Started",
    image: scheduleRetweet,
    url: routes.easyTwitter.scheduleRetweets,
  },
  {
    id: 3,
    header: "Instant Reply",
    description:
      "Stay connected to your followers. Answer their questions, solve their queries and be a respected brand on Twitter. Use Instant reply feature to reply to all tweets mentioned you",
    buttonText: "Get Started",
    image: instantReply,
    url: routes.easyTwitter.brandMonitoring,
  },
  {
    id: 4,
    header: "Analytics",
    description:
      "Track analytics of your tweets for last one month. Check what your followers loved the most and which content needed better strategy all here",
    buttonText: "Get Started",
    image: analytics,
    url: routes.easyTwitter.analytics,
  },
  {
    id: 5,
    header: "Audit",
    description:
      "We will audit your activities on Twitter and compare it with best practices suggested by Industry veterans. We will recommend you the areas of improvements right here",
    buttonText: "Get Started",
    image: audit,
    url: routes.easyTwitter.audit,
  },
];

export default data;
