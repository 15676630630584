import { TwitterService } from "services/Auth";
import {
  Dictionary,
  ITweetSearchData,
  ITweetSearchPayload,
  TRecommendation,
} from "./types";

type TAPIResponse = {
  search_tweets: {
    tweet_id: string;
    twitter_username: string;
    tweet_text: string;
    likes: number;
    retweets: number;
    like_status: boolean;
    influencer: boolean;
    potential_customer: boolean;
    search_keywords: string[];
    profile_pic_url: string;
    twitter_name: string;
    created_at: string;
    bio: string;
    followers: number;
    followings: number;
    tweets: number;
    website: string;
    location: string;
    twitter_profile_url: string;
  }[];
  user_recommendations: TRecommendation[];
  isTwitterConnected: boolean;
  tweet_actions: boolean;
  offset: Dictionary<number>;
};

type TFilterPayload = {
  start_date?: string;
  end_date?: string;
  is_reply?: boolean;
  likes?: number;
  replies?: number;
  retweets?: number;
};

type TAPIPayload = {
  keywords: string;
  language: string;
  offset: Dictionary<number>;
  filters?: TFilterPayload;
};

class KeywordListeningAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): ITweetSearchData => {
    const cards = apiResponse.search_tweets.map((tweet) => ({
      tweetId: tweet.tweet_id,
      username: tweet.twitter_username,
      text: tweet.tweet_text,
      likes: tweet.likes,
      retweets: tweet.retweets,
      isLiked: tweet.like_status,
      influencer: tweet.influencer,
      potentialCustomer: tweet.potential_customer,
      keywords: tweet.search_keywords,
      name: tweet.twitter_name,
      profilePicUrl: tweet.profile_pic_url,
      createdAt: tweet.created_at,
      bio: tweet.bio,
      followers: tweet.followers,
      followings: tweet.followings,
      tweets: tweet.tweets,
      website: tweet.website,
      location: tweet.location,
      profileUrl: tweet.twitter_profile_url,
    }));
    return {
      cards,
      recommendation: apiResponse.user_recommendations,
      isTwitterConnected: apiResponse.isTwitterConnected,
      tweetActions: apiResponse.tweet_actions,
      offset: apiResponse.offset,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: ITweetSearchPayload) => {
    if (data.filters) {
      return {
        keywords: data.keywords.filter((word) => word !== "").join(),
        language: data.language,
        offset: data.offset,
        filters: {
          start_date: `${data.filters?.startDate.getFullYear()}-${
            data.filters?.startDate.getMonth() + 1
          }-${data.filters?.startDate.getDate()}`,
          end_date: `${data.filters?.endDate.getFullYear()}-${
            data.filters?.endDate.getMonth() + 1
          }-${data.filters?.endDate.getDate()}`,
          is_reply: data.filters?.isReply,
          likes: data.filters?.likes,
          replies: data.filters?.replies,
          retweets: data.filters?.retweets,
        },
      } as TAPIPayload;
    }
    const payload: TAPIPayload = {
      keywords: data.keywords.filter((word) => word !== "").join(),
      language: data.language,
      offset: data.offset,
    };
    return payload;
  };
  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default KeywordListeningAdapter;
