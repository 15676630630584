import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 1rem;
  box-shadow: 1px 1px 2px 0 ${({ theme }) => theme.colors.extraLightGrey},
    -1px -1px 2px 0 ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 8px;
  padding: 0.75rem;
  overflow: visible;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
`;

const Head = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Bio = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const TweetSearchBio = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 13px;
  white-space: pre-line;
  overflow-wrap: break-word;
`;

const Stats = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const Action = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
`;

const ScheduledAt = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.alpha};
  font-size: 0.7rem;
  margin-left: auto;
`;

const FlexBetweet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export {
  Container,
  Action,
  Stats,
  Bio,
  Content,
  Head,
  Image,
  TweetSearchBio,
  ScheduledAt,
  FlexBetweet,
};
