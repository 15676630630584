import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TStepsStyleProps } from "./types";

const helper = new ThemeHelper();

interface IContainerProps extends TStepsStyleProps {
  active?: boolean;
}

const Container = styled.div<IContainerProps>`
  background-color: ${({ theme, variant = "lavendar" }) =>
    theme.colors[variant]};
  border-radius: 50%;
  ${helper.getFontSize("small")};
  width: 2em;
  height: 2em;
  display: grid;
  place-items: center;
  z-index: 1;
  transition: 0.4s background-color ease-in;
  ${({ active, theme, activeVariant = "darkPastelBlue" }) => {
    if (active) {
      return css`
        background-color: ${theme.colors[activeVariant]};
      `;
    }
  }}
`;

export { Container };
