import { usePostsStateMachine } from "hooks/SocialMediaPost";
import {
  TBaseSocialMediaPost,
  TFacebookPostObject,
  TInstagramPostObject,
  TLinkedinPostObject,
  TTwitterPostObject,
} from "adapters/SocialMediaPost/types";
import {
  TPostFilterFormValues,
  TPostStatusFilterValues,
} from "components/SocialMediaPost/common/types";
import { isAfter } from "date-fns/esm";
import { postStatusFilterMap } from "config/SocialMediaPost";

const applyFilters = <T extends TBaseSocialMediaPost>(
  data: T[],
  filterValues: TPostFilterFormValues
) => {
  let result: T[] = data;

  const status: TPostStatusFilterValues = filterValues.postStatus;

  result = result.filter((post) => {
    if (status === postStatusFilterMap.all) return true;
    return post.postStatus === status;
  });

  result = result.filter((post) => {
    return filterValues.platforms.includes(post.platform);
  });

  result = result.filter((post) => {
    const { startDate, endDate } = filterValues.dateRange;
    if (startDate && endDate) {
      return (
        isAfter(post.datetime, startDate) && isAfter(endDate, post.datetime)
      );
    }
    return true;
  });

  return result;
};

const usePosts = () => {
  const posts = usePostsStateMachine();
  const filteredPosts = usePostsStateMachine();

  const facebook = {
    onFetchSuccess: (payload: TFacebookPostObject[]) => {
      posts.facebook.dispatch({
        type: posts.facebook.actions.SET,
        payload,
      });
      filteredPosts.facebook.dispatch({
        type: filteredPosts.facebook.actions.SET,
        payload,
      });
    },
    onRemoveSuccess: (payload: string | undefined) => {
      if (payload) {
        posts.facebook.dispatch({
          type: posts.facebook.actions.REMOVE,
          payload,
        });
        filteredPosts.facebook.dispatch({
          type: filteredPosts.facebook.actions.REMOVE,
          payload,
        });
      }
    },
    onUpdateSuccess: (payload: TFacebookPostObject | undefined) => {
      if (payload) {
        posts.facebook.dispatch({
          type: posts.facebook.actions.UPDATE,
          payload,
        });
        filteredPosts.facebook.dispatch({
          type: filteredPosts.facebook.actions.UPDATE,
          payload,
        });
      }
    },
    onFilter: (filterValues: TPostFilterFormValues) => {
      filteredPosts.facebook.dispatch({
        type: filteredPosts.facebook.actions.SET,
        payload: applyFilters(posts.facebook.state.data, filterValues),
      });
    },
  };
  const linkedin = {
    onFetchSuccess: (payload: TLinkedinPostObject[]) => {
      posts.linkedin.dispatch({
        type: posts.linkedin.actions.SET,
        payload,
      });
      filteredPosts.linkedin.dispatch({
        type: filteredPosts.linkedin.actions.SET,
        payload,
      });
    },
    onRemoveSuccess: (payload: string | undefined) => {
      if (payload) {
        posts.linkedin.dispatch({
          type: posts.linkedin.actions.REMOVE,
          payload,
        });
        filteredPosts.linkedin.dispatch({
          type: filteredPosts.linkedin.actions.REMOVE,
          payload,
        });
      }
    },
    onUpdateSuccess: (payload: TLinkedinPostObject | undefined) => {
      if (payload) {
        posts.linkedin.dispatch({
          type: posts.linkedin.actions.UPDATE,
          payload,
        });
        filteredPosts.linkedin.dispatch({
          type: filteredPosts.linkedin.actions.UPDATE,
          payload,
        });
      }
    },
    onFilter: (filterValues: TPostFilterFormValues) => {
      filteredPosts.linkedin.dispatch({
        type: filteredPosts.linkedin.actions.SET,
        payload: applyFilters(posts.linkedin.state.data, filterValues),
      });
    },
  };

  const instagram = {
    onFetchSuccess: (payload: TInstagramPostObject[]) => {
      posts.instagram.dispatch({
        type: posts.instagram.actions.SET,
        payload,
      });
      filteredPosts.instagram.dispatch({
        type: filteredPosts.instagram.actions.SET,
        payload,
      });
    },
    onRemoveSuccess: (payload: string | undefined) => {
      if (payload) {
        posts.instagram.dispatch({
          type: posts.instagram.actions.REMOVE,
          payload,
        });
        filteredPosts.instagram.dispatch({
          type: filteredPosts.instagram.actions.REMOVE,
          payload,
        });
      }
    },
    onUpdateSuccess: (payload: TInstagramPostObject | undefined) => {
      if (payload) {
        posts.instagram.dispatch({
          type: posts.instagram.actions.UPDATE,
          payload,
        });
        filteredPosts.instagram.dispatch({
          type: filteredPosts.instagram.actions.UPDATE,
          payload,
        });
      }
    },
    onFilter: (filterValues: TPostFilterFormValues) => {
      filteredPosts.instagram.dispatch({
        type: filteredPosts.instagram.actions.SET,
        payload: applyFilters(posts.instagram.state.data, filterValues),
      });
    },
  };

  const twitter = {
    onFetchSuccess: (payload: TTwitterPostObject[]) => {
      posts.twitter.dispatch({
        type: posts.twitter.actions.SET,
        payload,
      });
      filteredPosts.twitter.dispatch({
        type: filteredPosts.twitter.actions.SET,
        payload,
      });
    },
    onRemoveSuccess: (payload: string | undefined) => {
      if (payload) {
        posts.twitter.dispatch({
          type: posts.twitter.actions.REMOVE,
          payload,
        });
        filteredPosts.twitter.dispatch({
          type: filteredPosts.twitter.actions.REMOVE,
          payload,
        });
      }
    },
    onUpdateSuccess: (payload: TTwitterPostObject | undefined) => {
      if (payload) {
        posts.twitter.dispatch({
          type: posts.twitter.actions.UPDATE,
          payload,
        });
        filteredPosts.twitter.dispatch({
          type: filteredPosts.twitter.actions.UPDATE,
          payload,
        });
      }
    },
    onFilter: (filterValues: TPostFilterFormValues) => {
      filteredPosts.twitter.dispatch({
        type: filteredPosts.twitter.actions.SET,
        payload: applyFilters(posts.twitter.state.data, filterValues),
      });
    },
  };

  const data = [
    ...filteredPosts.facebook.state.data,
    ...filteredPosts.instagram.state.data,
    ...filteredPosts.linkedin.state.data,
    ...filteredPosts.twitter.state.data,
  ];
  return {
    facebook,
    twitter,
    linkedin,
    instagram,
    data,
  };
};

export default usePosts;
