import styled from "styled-components";

const Container = styled.div`
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }

  &.active,
  &:hover {
    border-radius: 5px;
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
    background-color: ${({ theme }) => theme.colors.alpha};
  }
`;

export { Container };
