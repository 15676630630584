import styled, { css } from "styled-components";
import InfoCardHeader from "./Header";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

type ContainerProps = {
  active?: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85px;
  border: 1px solid;
  border-radius: 10px;
  padding: ${helper.getSpacing("extraSmall")};
  border-color: ${({ theme }) => theme.colors.extraLightGrey};
  cursor: pointer;
  ${({ active }) => {
    if (active) {
      return css`
        ${InfoCardHeader} {
          color: ${({ theme }) => theme.colors.alpha};
        }
        border-color: ${({ theme }) => theme.colors.alpha};
      `;
    }
  }}
`;

export default Container;
