import { format } from "date-fns";
import NavigateDate from "./NavigateDate";
import { useEventCalendar } from "hooks/common";

const WeekTools = () => {
  const eventCalendar = useEventCalendar();

  const renderText = () => {
    return `${format(eventCalendar.startDate, "dd MMM")} - ${format(
      eventCalendar.endDate,
      "dd MMM"
    )}`;
  };

  return (
    <NavigateDate
      onForwardClick={eventCalendar.goToNextWeek}
      onBackwardClick={eventCalendar.goToPreviousWeek}
      text={renderText()}
    />
  );
};

export default WeekTools;
