import { Container } from "./SocialLogin.styles";
import SocialButton from "./SocialButton";
import { constants, links } from "config/Auth";
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { ReactComponent as FacebookIcon } from "assets/icons/common/facebook-icon.svg";
import { ReactComponent as GoogleIcon } from "assets/icons/common/google-icon.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/common/twitter-icon.svg";
import AuthPopUpWindow from "../AuthPopUpWindow";
import { useDolphynSocialLogin } from "hooks/Auth";

const SocialLogin = () => {
  const { handleGoogleAuth, handleFacebookAuth, handleTwitterAuth } =
    useDolphynSocialLogin();

  return (
    <Container>
      <AuthPopUpWindow
        url={links.SOCIAL_LOGIN_TWITTER_AUTH_URL}
        trigger={
          <SocialButton icon={TwitterIcon} content="Sign in with Twitter" />
        }
        expectedParam="oauth_token"
        onSuccess={(params, redirectUrl) =>
          redirectUrl && handleTwitterAuth(redirectUrl)
        }
      />

      <FacebookLogin
        appId={constants.FACEBOOK_APP_ID as string}
        fields="name,email,picture"
        callback={(data: any) => handleFacebookAuth({ data })}
        render={(props: any) => (
          <SocialButton
            icon={FacebookIcon}
            content="Sign in with Facebook"
            onClick={props.onClick}
          />
        )}
      />

      <GoogleLogin
        clientId={constants.GOOGLE_CLIENT_ID as string}
        onSuccess={(data: any) => {
          handleGoogleAuth({
            data: {
              accessToken: data.accessToken as string,
              idToken: data.tokenId as string,
            },
          });
        }}
        render={(props) => (
          <SocialButton
            onClick={props.onClick}
            icon={GoogleIcon}
            content="Sign in with Google"
          />
        )}
      />
    </Container>
  );
};

export default SocialLogin;
