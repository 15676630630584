import { format } from "date-fns";
import NavigateDate from "./NavigateDate";
import { useEventCalendar } from "hooks/common";

const MonthTools = () => {
  const eventCalendar = useEventCalendar();

  const renderText = () => format(eventCalendar.currentMonth, "MMMM yyyy");

  return (
    <NavigateDate
      onForwardClick={eventCalendar.goToNextMonth}
      onBackwardClick={eventCalendar.goToPreviousMonth}
      text={renderText()}
    />
  );
};

export default MonthTools;
