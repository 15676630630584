import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InstagramService, { TUid } from "services/Auth/instagramService";

const service = new InstagramService();

const selectedAccount = service.getSelectedAccount();
const pages = service.getPages(selectedAccount.uid);

const initialState = {
  uid: selectedAccount.uid,
  selectedAccount: selectedAccount,
  selectedAccountPages: pages,
};

export const instagramSelection = createSlice({
  name: "instagramSelection",
  initialState,
  reducers: {
    reInitialize: (state) => {
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
      state.selectedAccountPages = service.getPages(selectedAccount.uid);
    },
    selectAccount: (state, action: PayloadAction<TUid>) => {
      service.setSelectedAccount(action.payload);
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
      state.selectedAccountPages = service.getPages(selectedAccount.uid);
    },
    resetSelection: (state) => {
      service.removeSelectedAccount();
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
      state.selectedAccountPages = [];
    },
  },
});

export const { reInitialize, selectAccount, resetSelection } =
  instagramSelection.actions;

export default instagramSelection.reducer;
