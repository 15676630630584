import { useDemandByCountry } from "hooks/ProductDemand";
import SingleKeywordSearchProvider from "./SingleKeywordSearchProvider";

const DemandByCountryFormProvider = (props: React.PropsWithChildren<{}>) => {
  const demandByCountry = useDemandByCountry();
  return (
    <SingleKeywordSearchProvider onSubmit={demandByCountry.handleSearch}>
      {props.children}
    </SingleKeywordSearchProvider>
  );
};

export default DemandByCountryFormProvider;
