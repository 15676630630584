import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: ${helper.getSpacing("tiny")} ${helper.getSpacing("small")};
`;

export { Container };
