import * as Yup from "yup";

const signInValidation = {
  schema: Yup.object({
    email: Yup.string()
      .required("Email is Required!")
      .email("Invalid Email address!"),
    password: Yup.string().required("Password is Required!"),
  }),
};

export { signInValidation };
