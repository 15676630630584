import { range } from "lodash";
import { TKeywordsDetails, TSecondaryKeywords } from "./types";

type TAPIResponse = {
  secondary_keywords: string[];
  primary_keyword: string;
};

const SECONDARY_KEYWORDS_LENGTH = 9;

class KeywordsDetailsAdapter {
  private parseResponse = (data: TAPIResponse): TKeywordsDetails => {
    const parseSecondaryKeywords = (
      keywords: TAPIResponse["secondary_keywords"]
    ) => {
      const createEmptyArray = (length: number) =>
        range(0, length).map(() => "");

      if (keywords.length < SECONDARY_KEYWORDS_LENGTH) {
        const fillupKeywords = createEmptyArray(
          SECONDARY_KEYWORDS_LENGTH - keywords.length
        );
        return [...keywords, ...fillupKeywords] as TSecondaryKeywords;
      }

      if (keywords.length > SECONDARY_KEYWORDS_LENGTH) {
        return keywords.slice(0, 9) as TSecondaryKeywords;
      }

      return keywords as TSecondaryKeywords;
    };

    const keywordDetails: TKeywordsDetails = {
      secondaryKeywords: parseSecondaryKeywords(data.secondary_keywords),
      primaryKeyword: data.primary_keyword || "",
    };

    return keywordDetails;
  };

  parseGetResponse = (data: TAPIResponse): TKeywordsDetails => {
    return this.parseResponse(data);
  };

  getPatchPayload = (payload: Partial<TKeywordsDetails>) => {
    const parseSecondaryKeywords = (
      keywords?: TKeywordsDetails["secondaryKeywords"]
    ) => {
      if (!keywords) return undefined;
      return keywords.filter((keyword) => keyword !== "");
    };
    const apiPayload: Partial<TAPIResponse> = {
      primary_keyword: payload.primaryKeyword,
      secondary_keywords: parseSecondaryKeywords(payload.secondaryKeywords),
    };

    return apiPayload;
  };

  parsePatchResponse = (data: TAPIResponse): TKeywordsDetails => {
    return this.parseResponse(data);
  };
}

export default KeywordsDetailsAdapter;
