import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TFontProps, TMarginProps, TSpacings } from "theme/types";

const helper = new ThemeHelper();

interface TFormErrorProps extends TFontProps, TMarginProps {
  padding?: keyof TSpacings;
}

const FormError = styled.p<TFormErrorProps>`
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${({ size }) => helper.getFontSize(size)}
  color: ${({ theme }) => theme.colors.danger};
  padding: ${({ padding = "small" }) => helper.getSpacing(padding)};
  ${({ mb = "small", mt, ms, me }) =>
    helper.getMargins({
      top: mt,
      bottom: mb,
      left: ms,
      right: me,
    })};
`;

export default FormError;
