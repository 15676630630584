import { useField } from "formik";
import FormError from "./FormError";
import Input from "./Input";

interface IInputProps extends React.ComponentPropsWithoutRef<"input"> {
  name: string;
  focused?: boolean;
}

const InputField = ({ name, ...inputProps }: IInputProps) => {
  const [field, meta] = useField(name);

  return (
    <>
      <Input {...field} {...inputProps} />
      {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
    </>
  );
};

export default InputField;
