import { TConversation, TInstantReplies } from "adapters/Twitter/types";
import ThirdReplyCard from "./ThirdReplyCard";

type Props = {
  conversations: TConversation[];
  setCurrentTweet: (values: TInstantReplies) => void;
};

const CurrentConversation = ({ conversations, setCurrentTweet }: Props) => {
  return (
    <>
      {conversations.map((tweet) => (
        <ThirdReplyCard
          key={tweet.leadUsername}
          conversation={tweet}
          setCurrentTweet={setCurrentTweet}
        />
      ))}
    </>
  );
};

export default CurrentConversation;
