import { TConversation, TInstantReplies } from "adapters/Twitter/types";
import { Linkify } from "components/common";
import { useToggle } from "hooks";
import { useBrandMonitoring } from "hooks/Twitter";
import { useState } from "react";
import { ReplyingCard } from "../common";
import InstantReplyActions from "./InstantReplyActions";
import ReplyCard from "./ReplyCard";

interface ThirdReplyCardProps {
  conversation: TConversation;
  setCurrentTweet: (values: TInstantReplies) => void;
}

const ThirdReplyCard = ({
  conversation,
  setCurrentTweet,
}: ThirdReplyCardProps) => {
  const { handleLikeTweet, handleReply, handleRetweet } = useBrandMonitoring();
  const [isTweetReplyCardOpen, toggleTweetReplyCard] = useToggle();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");

  return (
    <>
      <ReplyingCard>
        <ReplyingCard.Sidebar>
          <ReplyingCard.Image
            src={conversation.imageUrl}
            alt={conversation.name}
          />
        </ReplyingCard.Sidebar>
        <ReplyingCard.Content>
          <ReplyingCard.Head>
            <ReplyingCard.ReplyingUsername
              name={conversation.name}
              username={conversation.leadUsername}
              datetime={new Date(conversation.createdAt)}
              replyingTo={conversation.replyingTo}
            />
          </ReplyingCard.Head>
          <ReplyingCard.Bio>
            <Linkify>{conversation.text}</Linkify>
          </ReplyingCard.Bio>
          <ReplyingCard.Action>
            <InstantReplyActions
              data={conversation}
              onLikeClick={() => handleLikeTweet(conversation)}
              onReplyClick={toggleTweetReplyCard}
              onRetweetClick={() => handleRetweet(conversation.tweetId)}
              onOpenThread={() => setCurrentTweet(conversation)}
            />
          </ReplyingCard.Action>
        </ReplyingCard.Content>
      </ReplyingCard>
      <ReplyCard
        tweetId={conversation.conversationId}
        tweetText={conversation.conversationId}
        open={isTweetReplyCardOpen}
        onCancel={() => toggleTweetReplyCard(false)}
        files={files}
        onFilesChange={(files) => setFiles(files)}
        text={text}
        onTextChange={(value) => setText(value)}
        onSave={() => {
          handleReply({ ...conversation, files, text });
          setText("");
          setFiles([]);
          toggleTweetReplyCard(false);
        }}
      />
    </>
  );
};

export default ThirdReplyCard;
