import { EnterpriseAdapter } from "adapters/Auth";
import { TEnterpriseSignUp } from "adapters/Auth/types";
import { dolphynPublicAPI } from "api/instances";
import { links } from "config/Profile";
import { APIError } from "errors";

const errors = new APIError();
const URL = links.BASE_PROFILE_API_URL.append("/enterprise/signup");
const adapter = new EnterpriseAdapter();

const loginEnterprise = async (values: TEnterpriseSignUp) => {
  try {
    const apiPayload = adapter.getPostPayload(values);
    const { data } = await dolphynPublicAPI.post(URL, apiPayload);
    return adapter.getResponse(data);
  } catch (e) {
    errors.throwAxiosError(e);
  }
};

export default loginEnterprise;
