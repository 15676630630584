import styled, { DefaultColors, css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TGeneralSizes } from "theme/types";

const themeHelper = new ThemeHelper();

type TCheckboxProps = {
  variant?: keyof DefaultColors;
  circular?: boolean;
  elementSize?: keyof TGeneralSizes;
};

const Checkbox = styled.input.attrs({
  type: "checkbox",
})<TCheckboxProps>`
  ${({ elementSize }) => {
    return css`
      --sizeFactor: ${themeHelper.getGeneralSize(elementSize, {
        multiplier: 1.2,
      })};
    `;
  }}
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0;
  border: 0.05em solid
    ${({ theme }) => themeHelper.lighten(theme.colors.lightGrey)};
  display: grid;
  place-content: center;
  ${({ circular }) => {
    return css`
      border-radius: ${circular ? "50%" : 0};
    `;
  }}
  height: calc(calc(var(--sizeFactor) * 1rem) + 1px);
  width: calc(calc(var(--sizeFactor) * 1rem) + 1px);

  &::before {
    display: block;
    content: "";
    height: calc(var(--sizeFactor) * 0.5rem);
    width: calc(var(--sizeFactor) * 0.5rem);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked {
    background-color: ${({ theme, variant }) =>
      variant ? theme.colors[variant] : theme.colors.alpha};
  }

  &:checked::before {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default Checkbox;
