import { IconContainer } from "./Icon.styles";
import { ReactComponent as LogoIcon } from "assets/icons/brand-logo.svg";

import type { IIconProps } from "./types";

const Icon = ({ icon: Icon = LogoIcon, ...props }: IIconProps) => {
  return (
    <IconContainer {...props}>
      <Icon />
    </IconContainer>
  );
};

export default Icon;
