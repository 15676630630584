import { Logout } from "components/Profile/CompleteRegistration";
import { Flex } from "components/core";
import { TopLogoLayout } from "layout/common";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <TopLogoLayout.Container>
      <TopLogoLayout.Content>
        <Flex justify="space-between" align="center">
          <TopLogoLayout.BrandLogo />
          <Logout />
        </Flex>
        <TopLogoLayout.Body>{props.children}</TopLogoLayout.Body>
      </TopLogoLayout.Content>
    </TopLogoLayout.Container>
  );
};

export default ModuleLayout;
