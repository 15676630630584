import { backendApiUrl } from "helpers/UrlInstances";

const { REACT_APP_USERSNAP_GLOBAL_KEY, REACT_APP_CUSTOMFITAI_KEY } =
  process.env;

const USERSNAP_API_URL = "https://widget.usersnap.com/global/load";
export const USERSNAP_SCRIPT_URL = `${USERSNAP_API_URL}/${REACT_APP_USERSNAP_GLOBAL_KEY}?onload=onUsersnapCXLoad`;

export const CUSTOMFITAI_SCRIPT_URL = `https://sdk.customfit.ai/${REACT_APP_CUSTOMFITAI_KEY}/cf-js-sdk-min.js`;

export const fileUploadBaseUrl = backendApiUrl.append(
  "/social_media/v1/public/upload_file"
);

export const liveTrendsUrl = backendApiUrl.append(
  "/trends/v1/public/live_trends"
);

export const timezonesUrl = backendApiUrl.append(
  "/profile/v1/public/timezone_by_country"
);
