import { Menu as BaseMenu } from "semantic-ui-react";
import styled from "styled-components";

const PrimaryMenu = styled(BaseMenu)`
  &&& {
    width: unset;
    border: unset;
    box-shadow: unset;
  }
`;

export default PrimaryMenu;
