import { AuthService } from "services/Auth";
import { dolphynPublicRoot } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/social_auth/facebook");

export interface ISocialLoginFacebook {
  data: { accessToken: string; signedRequest: string };
  redirectUri?: string;
}

const socialLoginFacebook = async ({
  data,
  redirectUri = links.SOCIAL_LOGIN_DEFAULT_REDIRECT_URL,
}: ISocialLoginFacebook) => {
  try {
    const { accessToken: access_token, signedRequest: id_token } = data;

    const { data: apiData } = await dolphynPublicRoot.post(
      URL,
      { access_token, id_token },
      {
        headers: {
          redirectUri,
        },
      }
    );

    if (!("access_token" in apiData)) {
      throw new Error();
    }
    const { access_token: access, refresh_token: refresh } = apiData;

    const authService = new AuthService();

    authService.setCookies(access, refresh);

    return apiData;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default socialLoginFacebook;
