import {
  IComparisonTable,
  TAllFeaturesTable,
  TSocialMediaFeaturesTable,
} from "./types";

type TAPIResponse = {
  allFeaturesTable: TAllFeaturesTable;
  socialMediaFeaturesTable: TSocialMediaFeaturesTable;
};

class CompareAdapter {
  private parseResponse = (data: TAPIResponse): IComparisonTable => ({
    allFeaturesTable: data.allFeaturesTable,
    socialMediaFeaturesTable: data.socialMediaFeaturesTable,
  });

  parseGetResponse = (data: TAPIResponse) => this.parseResponse(data);
}

export default CompareAdapter;
