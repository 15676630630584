import { ForwardBackward } from "components/core";
import { DefaultColors } from "styled-components";

interface ISliderProps {
  onForwardClick?: () => void;
  onBackwardClick?: () => void;
  variant?: keyof DefaultColors;
  isLastItem?: boolean;
  isFirstItem?: boolean;
}

const Slider = ({
  onForwardClick,
  onBackwardClick,
  variant,
  isFirstItem,
  isLastItem,
  children,
}: React.PropsWithChildren<ISliderProps>) => {
  return (
    <>
      <ForwardBackward.Backward
        onClick={onBackwardClick}
        size="large"
        disabled={isFirstItem}
      />
      {children}
      <ForwardBackward.Forward
        onClick={onForwardClick}
        size="large"
        disabled={isLastItem}
      />
    </>
  );
};

Slider.Forward = ForwardBackward.Forward;
Slider.Backward = ForwardBackward.Backward;

export default Slider;
