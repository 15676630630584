import { Token } from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { IInstantReplies, TInstantReplies, TRecommendation } from "./types";

type TParams = {
  tokens: Token[] | null;
};

type TAPIResponse = {
  instantReplies: TInstantReplies[];
  recommendations: TRecommendation[];
  tweetActions: boolean;
};

class BrandMonitoringAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IInstantReplies => {
    return {
      instantReplies: apiResponse.instantReplies,
      recommendations: apiResponse.recommendations,
      tweetActions: apiResponse.tweetActions,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPayload = ({ tokens }: TParams) => {
    return { convo_next_tokens: tokens };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default BrandMonitoringAdapter;
