import { combineReducers } from "redux";
import dolphyn from "./dolphyn";
import facebook from "./facebook";
import facebookSelection from "./facebookSelection";
import linkedin from "./linkedin";
import linkedinSelection from "./linkedinSelection";
import twitter from "./twitter";
import twitterSelection from "./twitterSelection";
import instagram from "./instagram";
import instagramSelection from "./instagramSelection";

export default combineReducers({
  dolphyn,
  facebook,
  facebookSelection,
  linkedin,
  linkedinSelection,
  twitter,
  twitterSelection,
  instagram,
  instagramSelection,
});

export * from "./dolphyn";
