import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Profile";
import { RegistrationStatusAdapter } from "adapters/Profile";

const URL = links.BASE_PROFILE_API_URL.append("/registration_status");
const adapter = new RegistrationStatusAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR);

const registrationStatus = {
  get: async () => {
    try {
      const { data } = await dolphynAPI(URL);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default registrationStatus;
