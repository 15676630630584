import { createContext, useEffect } from "react";
import { useScriptLoader } from "hooks/common";
import { links } from "config/common";

export const UsernSnapContext = createContext(null);

interface IUserSnapProviderProps {}

const UserSnapProvider = ({
  children,
}: React.PropsWithChildren<IUserSnapProviderProps>) => {
  const scriptLoader = useScriptLoader();

  useEffect(() => {
    scriptLoader.load(links.USERSNAP_SCRIPT_URL, {
      beforeInit: () => {
        // @ts-ignore
        window.onUsersnapCXLoad = (api) => api.init();
      },
      location: "head",
      defer: true,
    });
  }, [scriptLoader]);

  return (
    <UsernSnapContext.Provider value={null}>
      {children}
    </UsernSnapContext.Provider>
  );
};

export default UserSnapProvider;
