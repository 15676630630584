import { createContext, useEffect, useState } from "react";
import { Admin } from "api/admin";
import { Spinner } from "components/common";
import { useReactQuery } from "hooks/common";
import { useDolphynAuth } from "hooks";

type TAdminContext = {
  isAdmin: boolean;
};

const initialState = {
  isAdmin: false,
};

export const AdminContext = createContext<TAdminContext | null>(null);

const AdminProvider = (props: React.PropsWithChildren<{}>) => {
  const [isAdmin, setIsAdmin] = useState(initialState.isAdmin);
  const { isSignedIn } = useDolphynAuth();

  const { execute, isLoading } = useReactQuery(Admin.isAdmin, {
    onSuccess: (data) => setIsAdmin(data.isAdmin),
  });

  useEffect(() => {
    if (isSignedIn) execute();
  }, [execute, isSignedIn]);

  return (
    <AdminContext.Provider value={{ isAdmin }}>
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
