const instagramConnectPopupData = {
  header: "You need to have an Instagram business account",
  subHeader:
    "To convert your Instagram page to a business page follow the steps below:",
  points: [
    "To switch your profile to a business account:",
    "Go to your profile and tap Menu in the upper right corner.",
    "Tap Settings.",
    "For some accounts, the Switch to Professional Account option will be listed directly under Settings.",
    "Tap Account.",
    "Tap Switch to Professional Account.",
    "Tap Continue.",
    "Select a Category for your business and tap Done.",
    "Tap OK to confirm.",
    "Tap Business.",
    "Tap Next.",
    "Add Contact details and tap Next. Or tap Don’t use my contact info to skip this step.",
  ],
  footer:
    "If you'd like, you can follow the steps to connect your business account to a Facebook Page associated with your business. This step is optional and will make it easier to use all of the features available for businesses across the Facebook family of apps. At this time, only one Facebook Page can be connected to your business account. Tap X on the top right corner to return to your profile.",
};

export default instagramConnectPopupData;
