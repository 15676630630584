import { constants } from "config/Auth";
import BaseAuthCookieService, { TUid } from "./baseSocialAccountService";

export type TTwitterAccount = {
  tokenId: string;
  userId: string;
  userName: string;
  uid: TUid;
  name: string;
  profileImageUrl: string;
  id: TUid;
};

class TwitterService extends BaseAuthCookieService<TTwitterAccount> {
  constructor() {
    super(constants.TWITTER_COOKIE, constants.TWITTER_LOCAL_STORAGE);
  }

  /*   
    Strategy: 

    - Get the current cookies
    - Check if account uid is already in current cookie list 
    - If not, add new account value to the list
    - If yes, update the existing one

   */

  setAuthCookie = (cookie: TTwitterAccount) => {
    super.accountExists(cookie.uid)
      ? super.updateAccount(cookie)
      : super.addNewAccount(cookie);
    const currentAccounts = super.getAccounts();
    if (currentAccounts.length === 1) {
      this.setSelectedAccount(currentAccounts[0].uid);
    }
  };

  getAccounts = () => {
    return super.getAccounts();
  };

  getAccount = (uid: TUid) => {
    return super.getAccount(uid);
  };

  getUid = (username: string) => {
    return super.getUid(username);
  };

  isAuthenticated = () => {
    return super.isAuthenticated();
  };

  clearData = () => {
    super.clearCookie();
  };

  removeAccount = (uid: TUid) => {
    super.removeAccount(uid);
  };

  setSelectedAccount = (uid: TUid) => {
    super.setSelectedAccount(uid);
  };

  removeSelectedAccount = () => {
    super.reInitializeSelectedAccount({
      id: "",
      tokenId: "",
      userId: "",
      userName: "",
      uid: "",
      name: "",
      profileImageUrl: "",
    });
  };

  getSelectedAccount = () => {
    return super.getSelectedAccount({
      id: "",
      tokenId: "",
      userId: "",
      userName: "",
      uid: "",
      name: "",
      profileImageUrl: "",
    });
  };

  getHeadersOrError = () => {
    const cred = this.getSelectedAccount();
    if (cred.tokenId === "") throw new Error();
    return {
      tokenid: cred.tokenId,
    };
  };
}

export type { TUid } from "./baseSocialAccountService";
export default TwitterService;
