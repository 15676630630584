import { Flex } from "components/core";
import ContentCreationLayout from "./ContentCreationLayout";
import AIContentHeader from "./AIContentHeader";

const AIContent = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <AIContentHeader header="Create Content 🤖" />
      <ContentCreationLayout.Body>{props.children}</ContentCreationLayout.Body>
    </Flex>
  );
};

export default AIContent;
