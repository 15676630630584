import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

type TSectionGridProps = {
  templateColumns: Array<number>;
  templateRows: Array<number>;
};

const SectionGrid = styled.div<TSectionGridProps>`
  padding-inline-end: ${helper.getSpacing("small")};
  height: 100%;
  display: grid;
  grid-template-columns: ${({ templateColumns }) => {
    const columns = templateColumns.join("fr ");
    return `${columns}fr`;
  }};
  grid-template-rows: ${({ templateRows }) => {
    const rows = templateRows.join("fr ");
    return `${rows}fr`;
  }};
  gap: ${helper.getSpacing("small")};
  justify-content: center;
`;

export default SectionGrid;
