import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scheduled: false,
  posted: false,
};

export const scheduleRetweets = createSlice({
  name: "scheduleRetweets",
  initialState,
  reducers: {
    setScheduledRetweets: (state) => {
      return {
        ...state,
        scheduled: true,
        posted: false,
      };
    },
    setPostedRetweets: (state) => {
      return {
        ...state,
        posted: true,
        scheduled: false,
      };
    },
  },
});

export const { setPostedRetweets, setScheduledRetweets } =
  scheduleRetweets.actions;

export default scheduleRetweets.reducer;
