import { TextSection } from "components/common";

interface IModuleHeaderProps {
  header: string;
  description: string;
}

const ModuleHeader = (props: IModuleHeaderProps) => {
  return (
    <TextSection>
      <TextSection.Header
        font="playfair"
        variant="darkGrey"
        size="medium"
        mb="extraSmall"
        content={props.header}
      />
      <TextSection.Description
        font="poppins"
        variant="lightGrey"
        style={{ maxWidth: "80ch", fontSize: "14px" }}
        content={props.description}
      />
    </TextSection>
  );
};

export default ModuleHeader;
