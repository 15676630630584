import { TweetCard } from "../common";
import { TInstantReplies } from "adapters/Twitter/types";
import { ShareIcon } from "../common/icons";

interface IInstantReplyActionsProps {
  data: TInstantReplies;
  onReplyClick: () => void;
  onLikeClick: () => void;
  onRetweetClick: (datetime: Date) => void;
  onOpenThread: () => void;
}

const InstantReplyActions = ({
  data,
  onReplyClick,
  onLikeClick,
  onRetweetClick,
  onOpenThread,
}: IInstantReplyActionsProps) => {
  return (
    <>
      <TweetCard.InstantReplyButton
        count={data.replyCount}
        onClick={onReplyClick}
      />
      <TweetCard.RetweetButton
        count={data.retweetCount}
        onSubmit={onRetweetClick}
      />
      <TweetCard.LikeButton
        count={data.likeCount}
        isLiked={data.likeStatus}
        onClick={onLikeClick}
      />
      <TweetCard.IconButton icon={ShareIcon} onClick={onOpenThread} />
    </>
  );
};

export default InstantReplyActions;
