import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  outline: none;
  border: unset;
  padding: 1rem;
  &,
  &::placeholder {
    font-family: ${({ theme }) => theme.fonts.poppins};
    ${helper.getFontSize("extraSmall")};
  }
  color: ${({ theme }) => theme.colors.darkGrey};
  border-radius: 10px 10px 0 0;
  ${helper.getStyledScrollbar()};
`;

const Toolbar = styled.div`
  background: ${({ theme }) => theme.colors.beta};
  border-radius: 0 0 10px 10px;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;

export { Container, Toolbar, TextArea };
