import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
`;

const ProfileImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`;

const Username = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 14px;
`;

const Location = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 12px;
  font-weight: 300;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

type TDefaultProps = {
  profileImage: string;
  username: string;
  location: string;
};

const PostHeader = ({ profileImage, username, location }: TDefaultProps) => {
  return (
    <Container>
      <ProfileImage src={profileImage} alt={username} />
      <Wrapper>
        <Username>{username}</Username>
        <Location>{location}</Location>
      </Wrapper>
    </Container>
  );
};

export default PostHeader;
