import React from "react";
import { Segment, Dimmer } from "semantic-ui-react";
import Spinner from "../Spinner/Spinner";
import "./RoundedBox.scss";

const RoundedBox = ({
  variant = "",
  loading,
  children,
  minHeight,
  maxHeight,
  spinnerProps,
  style,
  ...rest
}) => {
  return (
    <Dimmer.Dimmable
      as={Segment}
      blurring
      dimmed={loading}
      className={`${variant} roundedBox`}
      style={{ ...style, minHeight: `${minHeight}`, maxHeight: `${maxHeight}` }}
      {...rest}
    >
      <Dimmer active={loading} className="isLoading">
        {loading && <Spinner {...spinnerProps} />}
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  );
};

export default RoundedBox;
