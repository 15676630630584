import { useEffect, useState } from "react";
import { useField } from "formik";
import { timezoneListConfig } from "config/common";
import { QuestionCard } from "../common";
import { useTimezonesQuery } from "hooks/common";
import { TCompleteRegistrationFormValues } from "./types";

interface ITimezoneProps {
  name: string;
  currentCountry: string;
}

const Timezone = ({ name, currentCountry }: ITimezoneProps) => {
  const [options, setOptions] = useState(timezoneListConfig);
  const [field, , helpers] =
    useField<TCompleteRegistrationFormValues["timezone"]>(name);
  const { executeAsync, isLoading } = useTimezonesQuery();

  useEffect(() => {
    if (currentCountry) {
      (async () => {
        const data = await executeAsync({ country: currentCountry });
        if (data && data.length > 0) {
          setOptions(data);
          helpers.setValue(data[0].value);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeAsync, currentCountry]);

  return (
    <>
      <QuestionCard.Header
        as="p"
        size="medium"
        font="poppins"
        fontWeight="high"
        variant="darkGrey"
        textAlign="center"
        content="Select your Timezone"
      />
      <QuestionCard.Dropdown
        loading={isLoading}
        options={options}
        placeholder="Select Timezone"
        selection
        search
        value={field.value}
        onChange={(e, { value }) => {
          if (typeof value === "string") {
            helpers.setValue(value);
          }
        }}
      />
    </>
  );
};

export default Timezone;
