import { createContext, useEffect, useState } from "react";
import { fetchInfluencers } from "api/Twitter";
import { useReactQuery, useNotification } from "hooks/common";
import {
  useDMLead,
  useRemoveInfluencer,
  useAddInfluencer,
  useReplyHelper,
  useFilterHelper,
  useLeadHelper,
  useRecentTweets,
  useRecentTweetRedux,
  useReports,
  useFollowInfluencer,
} from "hooks/Twitter";
import { Loader, Spinner } from "components/common";
import {
  IInfluencers,
  IRecentTweetsPayload,
  TInfluencer,
} from "adapters/Twitter/types";
import {
  IFollowersLimitFormValues,
  IInfluencerFilterFormValues,
  IReportsGenerationFormValues,
  TInfluencerDMFormValues,
} from "./types";
import { InfluencersDataBase, recentTweetMessage } from "config/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import { setFollowersLimit } from "redux/slices/Twitter";
import { useTwitterAuth } from "hooks";

type TInfluencersContext = {
  cards: IInfluencers["influencers"];
  tweetActions: IInfluencers["tweetActions"];
  isTwitterConnected: IInfluencers["isTwitterConnected"];
  handleInfluencer: (data: TInfluencer) => void;
  handleFollow: (data: TInfluencer) => void;
  handleRemove: (data: TInfluencer) => void;
  handleDM: (values: TInfluencerDMFormValues) => void;
  handleFilter: (value: string) => void;
  handleInfluencerTweets: (data: IRecentTweetsPayload) => void;
  handleFollowersLimit: (formValues: IFollowersLimitFormValues) => void;
  handleFilterSearch: (formValues: IInfluencerFilterFormValues) => void;
  handleReportsGeneration: (formValues: IReportsGenerationFormValues) => void;
  handleConnectTwitter: (value: boolean) => void;
};

export const InfluencersContext = createContext<TInfluencersContext | null>(
  null
);

const InfluencersProvider = (props: React.PropsWithChildren<{}>) => {
  const [cards, setCards] = useState<IInfluencers["influencers"]>([]);
  const [tweetActions, setTweetActions] =
    useState<IInfluencers["tweetActions"]>(false);
  const [isTwitterConnected, setTwitterConnected] =
    useState<IInfluencers["isTwitterConnected"]>(false);
  const { isSignedIn, selectedAccount } = useTwitterAuth();
  const addInfluecer = useAddInfluencer();
  const removeInfluencer = useRemoveInfluencer();
  const replyHelper = useReplyHelper();
  const followInfluencer = useFollowInfluencer();
  const dmLead = useDMLead();
  const leadHelper = useLeadHelper();
  const filter = useFilterHelper();
  const recentTweets = useRecentTweets();
  const recentTweetRedux = useRecentTweetRedux();
  const reports = useReports();
  const notification = useNotification();
  const dispatch = useAppDispatch();

  const { execute, isLoading } = useReactQuery(fetchInfluencers, {
    onSuccess: (data) => {
      setCards(data.influencers);
      setTweetActions(data.tweetActions);
      setTwitterConnected(data.isTwitterConnected);
      if (data.message) {
        notification.notifyInfo(data.message);
      }
    },
  });

  useEffect(() => {
    execute({ isAuthenticated: isSignedIn });
  }, [execute, isSignedIn, selectedAccount.userName]);

  const handleFilterSearch = (formValues: IInfluencerFilterFormValues) => {
    execute(
      { isAuthenticated: isSignedIn, ...formValues },
      {
        onSuccess: (data) => {
          if (data) {
            setCards(data.influencers);
          }
        },
      }
    );
  };

  const handleInfluencer = (data: TInfluencer) => {
    addInfluecer.execute(
      { ...data, isAuthenticated: isSignedIn, word: data.keyword[0] },
      {
        onSuccess: (message) => {
          if (message) {
            const tweet = { ...data, influencer: !data.influencer };
            setCards((current) => leadHelper.findAndReplace(current, tweet));
            notification.notifySuccess(message.message);
          }
        },
      }
    );
  };

  const handleReportsGeneration = (
    formValues: IReportsGenerationFormValues
  ) => {
    notification.notifyInfo("Download will start shortly");
    reports.execute({
      potentialLeads: false,
      potentialCustomers: false,
      influencers: true,
      socialInfo: formValues.socialInfo,
    });
  };

  const handleFollowersLimit = (formValues: IFollowersLimitFormValues) => {
    execute(formValues, {
      onSuccess: (data) => {
        if (data) {
          setCards(data.influencers);
          if (data.followersLimit) {
            dispatch(setFollowersLimit(data.followersLimit));
          }
          if (data.message) {
            notification.notifyInfo(data.message);
          }
        }
      },
    });
  };

  const handleInfluencerTweets = (data: IRecentTweetsPayload) => {
    recentTweets.execute(data, {
      onSuccess: (response) => {
        if (response?.leadTweets) {
          recentTweetRedux.setRecentTweets(response.leadTweets);
        } else {
          notification.notifyInfo(response!.message);
        }
      },
    });
  };

  const handleFollow = (data: TInfluencer) =>
    followInfluencer.execute(
      { ...data, isAuthenticated: isSignedIn },
      {
        onSuccess: (message) => {
          if (message) {
            const tweet = { ...data, follow: !data.follow };
            setCards((current) => leadHelper.findAndReplace(current, tweet));
            notification.notifySuccess(message.message);
          }
        },
      }
    );

  const handleRemove = (data: TInfluencer) => {
    removeInfluencer.execute(data, {
      onSuccess: (message) => {
        if (message) {
          setCards((current) => leadHelper.findAndRemove(current, data));
          notification.notifySuccess(message.message);
        }
      },
    });
  };

  const handleFilter = (value: string) => {
    if (value === "tweets") {
      setCards((current) => filter.sortByTweets(current));
    }

    if (value === "followers") {
      setCards((current) => filter.sortByFollowers(current));
    }

    if (value === "following") {
      setCards((current) => filter.sortByFollowings(current));
    }
  };

  const handleDM = (values: TInfluencerDMFormValues) => {
    replyHelper.handleDM(values);
  };

  const handleConnectTwitter = (value: boolean) => {
    setTwitterConnected(value);
  };

  return (
    <InfluencersContext.Provider
      value={{
        cards,
        tweetActions,
        isTwitterConnected,
        handleDM,
        handleInfluencer,
        handleFollow,
        handleRemove,
        handleFilter,
        handleInfluencerTweets,
        handleFollowersLimit,
        handleFilterSearch,
        handleReportsGeneration,
        handleConnectTwitter,
      }}
    >
      <Loader textData={InfluencersDataBase} hidden={!isLoading} />
      <Loader textData={recentTweetMessage} hidden={!recentTweets.isLoading} />
      <Spinner fullscreen hidden={!dmLead.isLoading} />
      {props.children}
    </InfluencersContext.Provider>
  );
};

export default InfluencersProvider;
