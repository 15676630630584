export type TFileAPIPayload = {
  files: File[];
};
export type TFileAPIResponse = {
  attachment_id: string;
  user: string;
  file_type: string;
  file_url: string;
}[];

export type TDefaultFileTypes = "image" | "video";

export type TFileObject<FileTypes = TDefaultFileTypes> = {
  id: string;
  attachmentId: string;
  user: string;
  fileType: FileTypes;
  fileUrl: string;
};

class FileAdapter<T = TDefaultFileTypes> {
  getPostPayload = (values: TFileAPIPayload) => {
    const formData = new FormData();

    values.files.forEach((file) => formData.append("files", file));
    return formData;
  };

  parsePostResponse = (data: TFileAPIResponse) => {
    return data.map((file) => ({
      id: file.attachment_id,
      attachmentId: file.attachment_id,
      user: file.user,
      fileType: file.file_type as unknown as T,
      fileUrl: file.file_url,
    })) as TFileObject<T>[];
  };

  parsePostValues = (data: TFileObject<T>[]) => {
    return data.map((file) => ({
      attachment_id: file.attachmentId,
      user: file.user,
      file_type: file.fileType as unknown as string,
      file_url: file.fileUrl,
    })) as TFileAPIResponse;
  };
}

export default FileAdapter;
