import styled from "styled-components";
import { TPreviewUrl } from "helpers/FileHelper";
import { Flex } from "components/core";
import { Fragment } from "react";

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

type TMediaPreviewProps = {
  previewUrls: TPreviewUrl[];
};

const MediaPreview = ({ previewUrls }: TMediaPreviewProps) => {
  const renderPreview = (file: TPreviewUrl) => {
    switch (file.type) {
      case "image":
        return <StyledImage src={file.url} alt="preview" />;
      case "video":
        return (
          <video controls>
            <source src={file.url} type="video/mp4" />
          </video>
        );
      default:
        return null;
    }
  };

  if (!previewUrls) return null;

  return (
    <Flex justify="flex-start" align="center" gap="small">
      {previewUrls.map((url, key) => (
        <Fragment key={key}>{renderPreview(url)}</Fragment>
      ))}
    </Flex>
  );
};

export default MediaPreview;
