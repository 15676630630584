import { combineReducers } from "redux";
import recommendation from "./recommendation";
import autodm from "./autodm";
import recentTweets from "./recentTweets";
import lead from "./lead";
import potentialCustomer from "./potentialCustomer";
import influencer from "./influencer";
import tweetSearch from "./tweetSearch";
import common from "./common";
import current from "./current";
import thread from "./thread";
import scheduler from "./scheduler";
import scheduleRetweets from "./scheduleRetweets";
import instantReply from "./instantReply";

export default combineReducers({
  recommendation,
  autodm,
  recentTweets,
  lead,
  potentialCustomer,
  influencer,
  tweetSearch,
  common,
  current,
  thread,
  scheduler,
  scheduleRetweets,
  instantReply,
});

export * from "./recommendation";
export * from "./autodm";
export * from "./recentTweets";
export * from "./lead";
export * from "./potentialCustomer";
export * from "./influencer";
export * from "./tweetSearch";
export * from "./common";
export * from "./current";
export * from "./thread";
export * from "./scheduler";
export * from "./scheduleRetweets";
export * from "./instantReply";
