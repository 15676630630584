import { PostHeader, PostAction, PostContent } from "./Instagram";
import { PostCard } from "./InstagramPreview.styles";
import { SelectInfo } from "./Common.styles";

type TDefaultProps = {
  instagram: any;
  text: string;
  files: File[];
};

const InstagramPreview = ({ instagram, text, files }: TDefaultProps) => {
  if (!instagram.length)
    return <SelectInfo>Select Instagram for Instagram Post Preview</SelectInfo>;

  return (
    <PostCard>
      <PostHeader
        profileImage={instagram[0].image}
        username={instagram[0].title}
        location="New Delhi, India"
      />
      <PostContent postText={text} postFiles={files} />
      <PostAction />
    </PostCard>
  );
};

export default InstagramPreview;
