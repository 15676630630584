import { useState } from "react";
import { TMetricsData } from "adapters/Twitter/types";
import { useAnalytics } from "hooks/Twitter";
import { ConnectTwitter, TweetCard } from "../common";
import { useTwitterAuth } from "hooks";
import AnalyticsGraph from "./AnalyticsGraph";
import { Linkify } from "components/common";
import { TwitterReply, TwitterRetweet, TwitterHeart } from "../common/icons";
import { AnalyticsTweetGrid, TweetContainer } from "./AnalyticsTweet.styles";
import { relativeTime } from "helpers";

const AnalyticsTweet = () => {
  const {
    data: { analyticsData, tweetActions },
  } = useAnalytics();
  const { isSignedIn } = useTwitterAuth();

  const [metrics, setMetrics] = useState<TMetricsData>();

  if (!tweetActions && !isSignedIn) {
    return <ConnectTwitter connect />;
  }

  return (
    <AnalyticsTweetGrid>
      <TweetContainer>
        {analyticsData.map((tweet, index) => (
          <TweetCard key={index}>
            <TweetCard.Image src={tweet.profilePicUrl} alt={tweet.name} />
            <TweetCard.Content
              onClick={() => setMetrics(tweet.metricsData)}
              style={{ cursor: "pointer" }}
            >
              <TweetCard.Head>
                <TweetCard.Username
                  name={tweet.name}
                  username={tweet.username}
                />
                <TweetCard.ScheduledAt>
                  {relativeTime(tweet.createdAt)}
                </TweetCard.ScheduledAt>
              </TweetCard.Head>
              <TweetCard.TSBio>
                <Linkify>{tweet.text}</Linkify>
              </TweetCard.TSBio>
              <TweetCard.Action>
                <TweetCard.Stat icon={TwitterReply} value={tweet.replyCount} />
                <TweetCard.Stat
                  icon={TwitterRetweet}
                  value={tweet.retweetCount}
                />
                <TweetCard.Stat icon={TwitterHeart} value={tweet.likeCount} />
              </TweetCard.Action>
            </TweetCard.Content>
          </TweetCard>
        ))}
      </TweetContainer>
      {metrics && <AnalyticsGraph metricsData={metrics} />}
    </AnalyticsTweetGrid>
  );
};

export default AnalyticsTweet;
