import { FilterMenu } from "../common";
import { useActivity } from "hooks/SocialMediaPost";

const Menu = () => {
  const activity = useActivity();
  return (
    <FilterMenu onSubmit={activity.handleFilter} bordered>
      <FilterMenu.PostStatus />
      <FilterMenu.Platform />
      <FilterMenu.DateRange />
    </FilterMenu>
  );
};

export default Menu;
