import styled from "styled-components";
import { Icon } from "components/core";

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 10px;
  width: 150px;
  height: 150px;
  position: relative;
  img,
  video {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
  }
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 5px;
  top: 2px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  color: ${({ theme }) => theme.colors.lightGrey};
  cursor: pointer;
  z-index: 1;
  .close.icon {
    height: 15px;
    width: 15px;
  }
`;

export { Container, CloseIcon };
