import { Formik } from "formik";
import { Form, Container } from "./common";
import { resetPasswordValidation } from "config/Auth";
import { useNavigation } from "hooks/common";
import { useDolphynAuth } from "hooks";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ResetPasswordForm = () => {
  const nav = useNavigation();

  const { handleResetPassword } = useDolphynAuth();

  if (!nav.location.search) return null;

  return (
    <Container>
      <Form>
        <Form.Header
          as="h1"
          size="medium"
          variant="lightGrey"
          textAlign="center"
          content="Log In"
          font="poppins"
        />

        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordValidation.schema}
          onSubmit={(values) =>
            handleResetPassword(
              {
                password: values.password,
                searchParams: nav.location.search,
              },
              {
                onSuccess: () =>
                  nav.navigate((routes) => routes.rootRoutes.signIn),
              }
            )
          }
        >
          {({ handleSubmit }) => (
            <Form.Form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Input
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Field>
              <Form.Field>
                <Form.Button
                  variant="alpha"
                  type="submit"
                  size="huge"
                  fluid
                  content="Submit"
                />
              </Form.Field>
            </Form.Form>
          )}
        </Formik>
        <Form.Text
          variant="alpha"
          style={{ cursor: "pointer", textAlign: "center" }}
          content="Login"
          onClick={() => nav.navigate((routes) => routes.rootRoutes.signIn)}
        />
      </Form>
    </Container>
  );
};

export default ResetPasswordForm;
