import { useReactQuery, useNotification } from "hooks/common";
import { leads } from "api/Twitter";
import {
  IPotentialCustomerData,
  IRecentTweetsPayload,
  TPotentialCustomer,
} from "adapters/Twitter/types";
import { Loader } from "components/common";
import { createContext, useEffect, useState } from "react";
import {
  useRemovePotential,
  useAddInfluencer,
  useFilterHelper,
  useReplyHelper,
  useLeadHelper,
  useRecentTweets,
  useRecentTweetRedux,
  useReports,
  useFollowLead,
} from "hooks/Twitter";
import {
  ILeadsFilterFormValues,
  IReportsGenerationFormValues,
  TPotentialCustomerDMFormValues,
} from "./types";
import { fetchLeads, recentTweetMessage } from "config/Twitter";
import { useTwitterAuth } from "hooks";

type TLeadsContext = {
  cards: IPotentialCustomerData["potentialCustomerData"];
  tweetActions: IPotentialCustomerData["tweetActions"];
  isTwitterConnected: IPotentialCustomerData["isTwitterConnected"];
  handleInfluencer: (data: TPotentialCustomer) => void;
  handleFollow: (data: TPotentialCustomer) => void;
  handleRemove: (data: TPotentialCustomer) => void;
  handleDM: (values: TPotentialCustomerDMFormValues) => void;
  handleFilter: (value: string) => void;
  handleLeadTweets: (data: IRecentTweetsPayload) => void;
  handleFilterSearch: (formValues: ILeadsFilterFormValues) => void;
  handleReportsGeneration: (formValues: IReportsGenerationFormValues) => void;
  handleConnectTwitter: (value: boolean) => void;
};

export const LeadsContext = createContext<TLeadsContext | null>(null);

const LeadsProvider = (props: React.PropsWithChildren<{}>) => {
  const [cards, setCards] = useState<
    IPotentialCustomerData["potentialCustomerData"]
  >([]);
  const [tweetActions, setTweetActions] =
    useState<IPotentialCustomerData["tweetActions"]>(false);
  const [isTwitterConnected, setTwitterConnected] =
    useState<IPotentialCustomerData["isTwitterConnected"]>(false);
  const { isSignedIn, selectedAccount } = useTwitterAuth();
  const addInfluecer = useAddInfluencer();
  const removePotential = useRemovePotential();
  const filter = useFilterHelper();
  const replyHelper = useReplyHelper();
  const leadHelper = useLeadHelper();
  const followLead = useFollowLead();
  const recentTweets = useRecentTweets();
  const recentTweetRedux = useRecentTweetRedux();
  const reports = useReports();
  const notification = useNotification();

  const { execute, isLoading } = useReactQuery(leads, {
    onSuccess: (data) => {
      setCards(data.potentialCustomerData);
      setTweetActions(data.tweetActions);
      setTwitterConnected(data.isTwitterConnected);
    },
  });

  useEffect(
    () =>
      execute({
        isAuthenticated: isSignedIn,
      }),
    [execute, isSignedIn, selectedAccount.userName]
  );

  const handleFilterSearch = (formValues: ILeadsFilterFormValues) => {
    execute(
      { isAuthenticated: isSignedIn, ...formValues },
      {
        onSuccess: (data) => {
          if (data) {
            setCards(data.potentialCustomerData);
          }
        },
      }
    );
  };

  const handleLeadTweets = (data: IRecentTweetsPayload) => {
    recentTweets.execute(data, {
      onSuccess: (response) => {
        if (response) {
          recentTweetRedux.setRecentTweets(response.leadTweets);
          notification.notifyInfo(response.message);
        }
      },
    });
  };

  const handleReportsGeneration = (
    formValues: IReportsGenerationFormValues
  ) => {
    notification.notifyInfo("Download will start shortly");
    reports.execute({
      potentialLeads: false,
      potentialCustomers: true,
      influencers: false,
      socialInfo: formValues.socialInfo,
    });
  };

  const handleInfluencer = (data: TPotentialCustomer) => {
    addInfluecer.execute(
      { isAuthenticated: isSignedIn, word: data.keyword[0], ...data },
      {
        onSuccess: (message) => {
          if (message) {
            const tweet = { ...data, influencer: !data.influencer };
            setCards((current) => leadHelper.findAndReplace(current, tweet));
            notification.notifySuccess(message.message);
          }
        },
      }
    );
  };

  const handleFollow = (data: TPotentialCustomer) =>
    followLead.execute(
      { isAuthenticated: isSignedIn, ...data },
      {
        onSuccess: (message) => {
          if (message) {
            const tweet = { ...data, follow: !data.follow };
            setCards((current) => leadHelper.findAndReplace(current, tweet));
            notification.notifySuccess(message.message);
          }
        },
      }
    );

  const handleRemove = (data: TPotentialCustomer) => {
    removePotential.execute(data, {
      onSuccess: (message) => {
        if (message) {
          setCards((current) => leadHelper.findAndRemove(current, data));
          notification.notifySuccess(message.message);
        }
      },
    });
  };

  const handleFilter = (value: string) => {
    if (value === "tweets") {
      setCards((current) => filter.sortByTweets(current));
    }

    if (value === "followers") {
      setCards((current) => filter.sortByFollowers(current));
    }

    if (value === "following") {
      setCards((current) => filter.sortByFollowings(current));
    }
  };

  const handleDM = (values: TPotentialCustomerDMFormValues) => {
    replyHelper.handleDM(values);
  };

  const handleConnectTwitter = (value: boolean) => {
    setTwitterConnected(value);
  };

  return (
    <LeadsContext.Provider
      value={{
        cards,
        tweetActions,
        isTwitterConnected,
        handleInfluencer,
        handleFollow,
        handleDM,
        handleRemove,
        handleFilter,
        handleLeadTweets,
        handleFilterSearch,
        handleReportsGeneration,
        handleConnectTwitter,
      }}
    >
      <Loader textData={fetchLeads} hidden={!isLoading} />
      <Loader textData={recentTweetMessage} hidden={!recentTweets.isLoading} />
      {props.children}
    </LeadsContext.Provider>
  );
};

export default LeadsProvider;
