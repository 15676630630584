import { menuItems } from "config/SideBar";
import { Container } from "./MainNavigation.styles";
import Navigator from "./Navigator";
import SubNavigation from "./SubNavigation";
import { subnavigationData as easyTwitter } from "config/Twitter";
import { subnavigationData as contentCreation } from "config/ContentCreation";

const MainNavigation = () => {
  return (
    <Container>
      {menuItems.map(({ path, title, icon }, index) => (
        <>
          <Navigator
            path={path}
            key={index}
            title={title}
            Icon={icon}
            styles={{ fontWeight: 600 }}
          />
          {title === "Easy Twitter" ? (
            <SubNavigation data={easyTwitter} />
          ) : null}
          {title === "Content Creation" ? (
            <SubNavigation data={contentCreation} />
          ) : null}
        </>
      ))}
    </Container>
  );
};

export default MainNavigation;
