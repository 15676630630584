import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showLeadFilter: false,
};

export const lead = createSlice({
  name: "lead",
  initialState,
  reducers: {
    setShowLeadFilter: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showLeadFilter: action.payload,
      };
    },
  },
});

export const { setShowLeadFilter } = lead.actions;

export default lead.reducer;
