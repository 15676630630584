import { useState } from "react";
import { range } from "lodash";

type TUseKeywordInputArrayValues = [
  string[],
  (e: React.ChangeEvent<HTMLInputElement>, inputIndex: number) => void,
  {
    getChangedKeywordsArray: (
      currentList: string[],
      changedInputIndex: number,
      value: string
    ) => string[];
  }
];

const useKeywordInputArray = (length: number): TUseKeywordInputArrayValues => {
  const [keywords, setKeywords] = useState<string[]>(
    range(0, length).map(() => "")
  );

  const helpers = {
    getChangedKeywordsArray: (
      currentList: string[],
      changedInputIndex: number,
      value: string
    ) => {
      if (currentList.length !== length) {
        return [];
      }
      const mutableCurrentList = currentList.slice();
      mutableCurrentList[changedInputIndex] = value;
      return mutableCurrentList;
    },
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputIndex: number
  ) => {
    setKeywords((currentList) =>
      helpers.getChangedKeywordsArray(currentList, inputIndex, e.target.value)
    );
  };

  return [keywords, handleChange, helpers];
};

export default useKeywordInputArray;
