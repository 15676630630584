import { AuthFormIllustration } from "./common";
import illustration from "assets/images/Auth/signin-illustration.png";

const ForgotPasswordIllustration = () => {
  return (
    <AuthFormIllustration
      header="Reset Your Password"
      subHeader="Don’t worry. We’ll email you instructions to reset your password."
      illustration={illustration}
    />
  );
};

export default ForgotPasswordIllustration;
