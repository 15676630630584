import { applicationRoutes as routes } from "config/common";

const subnavigationData = [
  {
    title: "AI Content",
    route: routes.contentCreation.aiContent,
  },
  {
    title: "Activity",
    route: routes.contentCreation.activity,
  },
  {
    title: "Calendar",
    route: routes.contentCreation.calendar,
  },
  {
    title: "Scheduler",
    route: routes.contentCreation.scheduler,
  },
  {
    title: "Live Trends",
    route: routes.contentCreation.liveTrends,
  },
];

export default subnavigationData;
