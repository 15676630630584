import { useState } from "react";
import { securityDetailsViews } from "config/Profile/securityDetailsViews";
import { TSecurityDetailsViewsMap } from "./types";
import TwoFactorAuth from "./TwoFactorAuth";
import ChangePassword from "./ChangePassword";

const SecurityDetails = () => {
  const [currentView, setCurrentView] = useState<TSecurityDetailsViewsMap>(
    securityDetailsViews.twoFactorAuth
  );

  const handleChangeView = (view: TSecurityDetailsViewsMap) =>
    setCurrentView(view);

  const renderCurrentView = () => {
    switch (currentView) {
      case securityDetailsViews.twoFactorAuth:
        return <TwoFactorAuth onChangeView={handleChangeView} />;

      case securityDetailsViews.changePassword:
        return <ChangePassword onChangeView={handleChangeView} />;

      default:
        break;
    }
  };

  return <>{renderCurrentView()}</>;
};

export default SecurityDetails;
