import { useEffect } from "react";
import { scheduleTwitter as api } from "api/SocialMediaPost";
import useTwitterPosts from "./useTwitterPosts";
import { useTwitterAuth, useReactQuery } from "hooks";
import { constants } from "config/SocialMediaPost";
import { TTwitterPostObject } from "adapters/SocialMediaPost/types";

type TOptions = {
  onSuccess?: (data: TTwitterPostObject[]) => void;
};

const useTwitterActivity = (options?: TOptions) => {
  const { execute: fetch, isLoading: fetching } = useReactQuery(
    api.fetchActivity,
    {
      onSuccess: ({ data }) => {
        if (data && options?.onSuccess) {
          options?.onSuccess(data);
        }
      },
    }
  );
  const auth = useTwitterAuth();
  const { create, update, remove, isLoading } = useTwitterPosts();
  const uid = auth.selectedAccount.uid;

  useEffect(() => {
    if (uid) {
      fetch({
        uid,
        page: 1,
        limit: constants.DEFAULT_ACTIVITY_PAGE_LIMIT,
      });
    }
  }, [fetch, uid]);

  return {
    fetch: () => {
      if (uid) {
        fetch({
          uid,
          page: 1,
          limit: constants.DEFAULT_ACTIVITY_PAGE_LIMIT,
        });
      }
    },
    create,
    update,
    remove,
    isLoading: fetching || isLoading,
  };
};

export default useTwitterActivity;
