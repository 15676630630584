import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const TextWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("medium")};
  animation: leftToRight 0.5s ease-in;

  @keyframes leftToRight {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export { TextWrapper };
