import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Profile";
import { KeywordsDetailsAdapter } from "adapters/Profile";

const URL = links.BASE_PROFILE_API_URL.append("/keyword_details");
const adapter = new KeywordsDetailsAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR);

export type TKeywordsDetails = {
  primaryKeyword: string;
  secondaryKeywords: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];
};

const keywordDetails = {
  get: async () => {
    try {
      const { data } = await dolphynAPI(URL);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  patch: async (values: Partial<TKeywordsDetails>) => {
    const payload = adapter.getPatchPayload(values);
    try {
      const response = await dolphynAPI.patch(URL, payload);

      return adapter.parsePatchResponse(response.data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default keywordDetails;
