import { IEngagementResponse } from "adapters/Twitter/types";
import { leadNurturing } from "api/Twitter";
import { Loader } from "components/common";
import { leadNurturingLoader } from "config/Twitter";
import { useTwitterAuth } from "hooks";
import { useReactQuery, useNotification } from "hooks/common";
import {
  useCreateEngagement,
  useLikeTweet,
  useTweetHelper,
  useReplyHelper,
  useRetweet,
  useKeywordListeningScheduleRetweet,
} from "hooks/Twitter";
import { createContext, useEffect, useState } from "react";
import {
  ILikeFormValues,
  IRetweetFormValues,
  IScheduleRetwetFormValues,
  ITweetReplyFormValues,
  TEngagementCreateFormValues,
} from "./types";

type TLeadNurturingContext = {
  tweets: IEngagementResponse["tweets"];
  keywords: IEngagementResponse["keywords"];
  handleLike: (formValues: ILikeFormValues) => void;
  handleReply: (formValues: ITweetReplyFormValues) => void;
  handleRetweet: (formValues: IRetweetFormValues) => void;
  handleScheduleRetweet: (values: IScheduleRetwetFormValues) => void;
  handleCreateEngagement: (formValues: TEngagementCreateFormValues) => void;
};

export const LeadNurturingContext = createContext<TLeadNurturingContext | null>(
  null
);

const initialData: IEngagementResponse = {
  tweets: [],
  keywords: [],
};

const LeadNurturingProvider = (props: React.PropsWithChildren<{}>) => {
  const [tweets, setTweets] = useState<IEngagementResponse["tweets"]>(
    initialData.tweets
  );
  const [keywords, setKeywords] = useState<IEngagementResponse["keywords"]>(
    initialData.keywords
  );
  const notification = useNotification();
  const likeTweet = useLikeTweet();
  const replyHelper = useReplyHelper();
  const tweetHelper = useTweetHelper();
  const retweet = useRetweet();
  const scheduleRetweet = useKeywordListeningScheduleRetweet();
  const { isSignedIn, selectedAccount } = useTwitterAuth();
  const createEngagement = useCreateEngagement();

  const { execute, isLoading } = useReactQuery(leadNurturing.get, {
    onSuccess: (data) => {
      if (data) {
        setKeywords(data.keywords);
        setTweets(data.tweets);
      }
    },
  });

  useEffect(
    () => execute({ isAuthenticated: isSignedIn }),
    [execute, isSignedIn, selectedAccount.userName]
  );

  const handleCreateEngagement = (formValues: TEngagementCreateFormValues) => {
    createEngagement.execute(
      { keywords: formValues.keywords, matchNumber: "1" },
      {
        onSuccess: (data) => {
          if (data) notification.notifyInfo(data.message);
        },
      }
    );
  };

  const handleLike = (values: ILikeFormValues) => {
    likeTweet.execute({ tweetId: values.tweetId, isLiked: values.isLiked });
    const tweet = tweets.find((tweet) => tweet.tweetId === values.tweetId);
    if (tweet) {
      const updatedTweet = {
        ...tweet,
        likes: tweet.isLiked ? tweet.likes - 1 : tweet.likes + 1,
        isLiked: !tweet.isLiked,
      };
      setTweets((current) => tweetHelper.findAndReplace(current, updatedTweet));
    }
  };

  const handleReply = (values: ITweetReplyFormValues) => {
    replyHelper.handleReply(values);
  };

  const handleRetweet = (values: IRetweetFormValues) => {
    retweet.execute(values, {
      onSuccess: (data) => {
        if (data?.retweet) {
          const tweet = tweets.find(
            (tweet) => tweet.tweetId === values.tweetId
          );
          if (tweet) {
            const updatedTweet = { ...tweet, retweets: tweet?.retweets + 1 };
            setTweets((current) =>
              tweetHelper.findAndReplace(current, updatedTweet)
            );
          }
        }
      },
    });
  };

  const handleScheduleRetweet = (values: IScheduleRetwetFormValues) =>
    scheduleRetweet.execute(values, {
      onSuccess: (data) => {
        if (data) {
          notification.notifySuccess(data.message);
        }
      },
    });

  return (
    <LeadNurturingContext.Provider
      value={{
        tweets,
        keywords,
        handleLike,
        handleReply,
        handleRetweet,
        handleScheduleRetweet,
        handleCreateEngagement,
      }}
    >
      <Loader textData={leadNurturingLoader} hidden={!isLoading} />
      {props.children}
    </LeadNurturingContext.Provider>
  );
};

export default LeadNurturingProvider;
