import { Dropdown, Text, Flex } from "components/core";
import { filterConfig } from "config/Twitter";
import { useAnalytics } from "hooks/Twitter";

const FilterBy = () => {
  const analytics = useAnalytics();

  return (
    <Flex align="center">
      <Text size="extraSmall" content="Filter By: " />
      <Dropdown
        style={{
          minWidth: "132px",
          marginBottom: "unset",
          border: "unset",
          alignSelf: "flex-end",
        }}
        placeholder="Last 30 days"
        options={filterConfig}
        onChange={(e, { value }) =>
          typeof value === "number" && analytics.handleFilter({ days: value })
        }
      />
    </Flex>
  );
};

export default FilterBy;
