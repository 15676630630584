export const profileNavItemsMap = {
  personalDetails: "personalDetails",
  securityDetails: "securityDetails",
  keywordsDetails: "keywordsDetails",
  paymentDetails: "paymentDetails",
  notifications: "notifications",
  inviteEnterprises: "inviteEnterprises",
  generateCoupon: "generateCoupon",
  redeemCoupon: "redeemCoupon",
} as const;
