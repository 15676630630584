import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPlanDetails } from "adapters/Profile/types";
import { plansMap } from "config/Profile/plansMap";

const initialState: TPlanDetails = {
  plan: plansMap.free,
  currency: "",
  expiry: null,
  paymentMethod: "",
  paymentDetails: [],
};

export const planDetails = createSlice({
  name: "planDetails",
  initialState,
  reducers: {
    setPlanDetails: (state, action: PayloadAction<TPlanDetails>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setPlanDetails } = planDetails.actions;

export default planDetails.reducer;
