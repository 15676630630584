import { TBaseLeadObject } from "./types";

const useLeadHelper = () => {
  const findAndReplace = <T extends TBaseLeadObject>(
    data: T[],
    replaceWith?: T
  ) => {
    if (replaceWith) {
      const newData = data.map((tweetCard) =>
        replaceWith.leadUsername === tweetCard.leadUsername
          ? replaceWith
          : tweetCard
      );

      return [...newData];
    }
    return data;
  };

  const findAndRemove = <T extends TBaseLeadObject>(data: T[], removeIt: T) => {
    if (removeIt) {
      const newArr = data.filter(
        ({ leadUsername }) => removeIt.leadUsername !== leadUsername
      );
      return [...newArr];
    }
    return data;
  };

  const addFrequentlyUsedKeywords = <T extends TBaseLeadObject>(
    data: T[],
    replaceWith: T
  ) => {
    const newArr = data.map((tweetCard) =>
      tweetCard.leadUsername === replaceWith.leadUsername
        ? replaceWith
        : tweetCard
    );

    return [...newArr];
  };

  return { findAndReplace, findAndRemove, addFrequentlyUsedKeywords };
};

export default useLeadHelper;
