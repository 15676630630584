import { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { setDolphynSignedOut, setDolphynSignedIn } from "redux/slices/Auth";
import { loginDolphyn as dolphynAuthApi } from "api/Auth";
import { ILoginDolphyn } from "api/Auth/loginDolphyn";
import { SocialLoginProvider } from "context/Auth";
import { useAppDispatch } from "redux/store/hooks";
import { useNavigation, useReactQuery } from "hooks/common";
import { useDolphynAuth } from "hooks/Auth";

type TContext = {
  handleLogin: (values: ILoginDolphyn) => void;
};

export const SigninContext = createContext<TContext | null>(null);

const SigninProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { isSignedIn } = useDolphynAuth();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const { execute: loginDolphyn, isLoading } = useReactQuery(dolphynAuthApi, {
    onSuccess: () => {
      dispatch(setDolphynSignedIn());
      navigation.navigate((routes) => routes.rootRoutes.contentCreation);
    },
    onError: () => dispatch(setDolphynSignedOut()),
  });

  const handleLogin = (values: ILoginDolphyn) => loginDolphyn(values);

  useEffect(() => {
    if (isSignedIn) {
      navigation.navigate((routes) => routes.rootRoutes.contentCreation);
    }
  }, [dispatch, isSignedIn, navigation]);

  return (
    <SigninContext.Provider
      value={{
        handleLogin,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      <SocialLoginProvider>{children}</SocialLoginProvider>
    </SigninContext.Provider>
  );
};

export default SigninProvider;
