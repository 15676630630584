import { createContext, useEffect, useState } from "react";
import { Email } from "api/admin";
import { Spinner } from "components/common";
import { useNotification, useReactQuery } from "hooks";
import { IEmailPayload } from "adapters/Admin/types";

type IEmailContext = {
  senderEmail: string;
  senderName: string;
  subject: string;
  emailBody: string;
  templates: string[];
  handleUpdate: (data: IEmailPayload) => void;
  handleUpdateAsync: (data: IEmailPayload) => void;
};

const initialState = {
  senderEmail: "",
  senderName: "",
  subject: "",
  emailBody: "",
  templates: [],
};

export const EmailContext = createContext<IEmailContext | null>(null);

const EmailProvider = (props: React.PropsWithChildren<{}>) => {
  const [senderEmail, setSenderEmail] = useState(initialState.senderEmail);
  const [senderName, setSenderName] = useState(initialState.senderName);
  const [subject, setSubject] = useState(initialState.subject);
  const [emailBody, setEmailBody] = useState(initialState.emailBody);
  const [templates, setTemplates] = useState<string[]>(initialState.templates);
  const notification = useNotification();

  const { execute, isLoading } = useReactQuery(Email.getEmail, {
    onSuccess: (data) => {
      setSenderEmail(data.sender_email);
      setSenderName(data.sender_name);
      setSubject(data.subject);
      setEmailBody(data.email_body);
      setTemplates(data.templates);
    },
  });

  const { execute: handleUpdate, executeAsync: handleUpdateAsync } =
    useReactQuery(Email.postEmail, {
      onSuccess: (data) => {
        setSenderEmail(data.sender_email);
        setSenderName(data.sender_name);
        setSubject(data.subject);
        setEmailBody(data.email_body);
        setTemplates(data.templates);
        notification.notifySuccess(data.message);
      },
    });

  useEffect(() => {
    execute();
  }, [execute]);

  return (
    <EmailContext.Provider
      value={{
        senderEmail,
        senderName,
        subject,
        emailBody,
        templates,
        handleUpdate,
        handleUpdateAsync,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </EmailContext.Provider>
  );
};

export default EmailProvider;
