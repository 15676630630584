import { Container } from "./Media.styles";
import { useSlider } from "hooks/common";
import { Slider } from "components/common";

type TMediaTypes = "image" | "video";
type TMedia = { id: number | string; src: string; type: TMediaTypes };

export interface IMediaProps extends React.ComponentPropsWithoutRef<"div"> {
  media: TMedia[];
}

const Media = ({ media, ...props }: IMediaProps) => {
  const slider = useSlider(media);

  const renderPreview = () => {
    switch (media[slider.currentIndex].type) {
      case "image":
        return renderImage();

      case "video":
        return renderVideo();

      default:
        return null;
    }
  };

  const renderVideo = () => {
    return (
      <video controls>
        <source src={media[slider.currentIndex].src} type="video/mp4" />
      </video>
    );
  };

  const renderImage = () => {
    return <img src={media[slider.currentIndex].src} alt="post" />;
  };

  return (
    <Container {...props}>
      {media.length !== 0 && (
        <Slider
          onForwardClick={slider.goNext}
          onBackwardClick={slider.goPrevious}
          isFirstItem={slider.isFirstItem}
          isLastItem={slider.isLastItem}
          variant="black"
        >
          {renderPreview()}
        </Slider>
      )}
    </Container>
  );
};

export default Media;
