import { TweetHeader, TweetAction } from "./Twitter";
import { TweetCard } from "./TwitterPreview.styles";
import { SelectInfo } from "./Common.styles";
import PostContent from "./PostContent";

type TTwitter = {
  id: string;
  image: string;
  meta: string;
  title: string;
};

type TDefaultProps = {
  twitter: TTwitter[];
  datetime: Date;
  text: string;
  files: File[];
};

const TwitterPreview = ({ datetime, text, files, twitter }: TDefaultProps) => {
  if (!twitter.length)
    return <SelectInfo>Select Twitter for Twitter Post Preview</SelectInfo>;

  return (
    <TweetCard>
      <TweetHeader
        profileImage={twitter[0].image}
        name={twitter[0].title}
        username={twitter[0].meta}
        datetime={datetime}
      />
      <PostContent postText={text} postFiles={files} />
      <TweetAction />
    </TweetCard>
  );
};

export default TwitterPreview;
