import { Form } from "components/core";
import { generateCouponValidation } from "config/Profile";
import { Formik } from "formik";
import { useGenerateCoupon } from "hooks/Admin";
import { useAppSelector } from "redux/store/hooks";

const initialFormValues = {
  acronym: "",
  plan: "",
  noOfCoupons: "",
};

const GenerateCoupon: React.FC = () => {
  const { execute: handleGenerateCoupon } = useGenerateCoupon();

  const planListConfig = useAppSelector((state) =>
    state.profile.couponSystem.allPlans.map(({ id, type }) => ({
      key: id,
      value: id,
      text: type,
    }))
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={generateCouponValidation}
      onSubmit={(values) => handleGenerateCoupon(values)}
    >
      {({ handleSubmit }) => (
        <Form>
          <Form.Form onSubmit={handleSubmit}>
            <Form.Field>
              <Form.Label content="Acronym" />
              <Form.Input name="acronym" type="text" />
            </Form.Field>
            <Form.Field>
              <Form.Label content="No of Coupons" />
              <Form.Input name="noOfCoupons" type="text" />
            </Form.Field>
            <Form.Field>
              <Form.Label content="Plan Type" />
              <Form.DropdownField
                glow
                placeholder="Select Plan"
                name="plan"
                options={planListConfig}
              />
            </Form.Field>
            <Form.Button
              variant="alpha"
              size="small"
              mt="small"
              type="submit"
              content="Generate"
            />
          </Form.Form>
        </Form>
      )}
    </Formik>
  );
};

export default GenerateCoupon;
