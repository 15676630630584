import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { IDimmerStyleProps } from "./types";

const helper = new ThemeHelper();

const Container = styled.div<IDimmerStyleProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme, variant = "darkGrey", noBg }) => {
    if (!noBg) {
      return css`
        background-color: ${helper.transparentize(
          theme.colors[variant],
          "high"
        )};
      `;
    }
  }};
`;

export { Container };
