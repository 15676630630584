import {
  TPatchScheduleRetweetPayload,
  TPostScheduleRetweetPayload,
} from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { IScheduleRetweet, TScheduleRetweet } from "./types";

type TAPIResponse = {
  scheduleRetweets: TScheduleRetweet[];
  tweetActions: boolean;
};

type TPOSTPayload = {
  influencer: string;
  highest_liked: boolean;
  datetime: Date;
};

type TPATCHPayload = {
  highest_liked: boolean;
  datetime: Date;
};

class ScheduleRetweetAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IScheduleRetweet => {
    return {
      scheduleRetweet: apiResponse.scheduleRetweets,
      tweetActions: apiResponse.tweetActions,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = ({
    influencer,
    highest_liked,
    datetime,
  }: TPostScheduleRetweetPayload) => {
    const payload: TPOSTPayload = {
      influencer,
      highest_liked,
      datetime,
    };
    return payload;
  };

  getPatchPayload = ({
    highest_liked,
    datetime,
  }: TPatchScheduleRetweetPayload) => {
    const payload: TPATCHPayload = {
      highest_liked,
      datetime,
    };
    return payload;
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);

  parsePostResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);

  parseDeleteResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default ScheduleRetweetAdapter;
