interface ILocalStorageService<T> {
  storageKey: string;
  isItemPresent: () => boolean;
  getItem: () => T | undefined;
  setItem: (value: T) => void;
  removeItem: (value: T) => void;
}

class LocalStorageService<T> implements ILocalStorageService<T> {
  storageKey: string;

  constructor(key: string) {
    this.storageKey = key;
  }

  getItem = () => {
    const item = localStorage.getItem(this.storageKey);
    if (item) {
      try {
        const parsedItem = JSON.parse(item);
        return parsedItem as T;
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  };

  setItem = (value: T) => {
    localStorage.setItem(this.storageKey, JSON.stringify(value));
  };

  removeItem = () => {
    localStorage.removeItem(this.storageKey);
  };

  getOrCreate = (initialValue: T) => {
    let item = this.getItem();
    if (item) return item as T;
    this.setItem(initialValue);
    item = this.getItem();
    return item as T;
  };

  isItemPresent = () => {
    const item = this.getItem();
    if (item) return true;
    return false;
  };
}

export default LocalStorageService;
