import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSecurityDetails } from "adapters/Profile/types";

const initialState: TSecurityDetails = {
  countryCode: "",
  phoneNumber: "",
  twoFactorAuth: false,
};

export const securityDetails = createSlice({
  name: "securityDetails",
  initialState,
  reducers: {
    setSecurityDetails: (
      state,
      action: PayloadAction<Partial<TSecurityDetails>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSecurityDetails } = securityDetails.actions;

export default securityDetails.reducer;
