import { Flex } from "components/core";
import { ModuleContainer } from "./ModuleLayout.styles";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex style={{ height: "100vh" }}>
      <ModuleContainer>{props.children}</ModuleContainer>
    </Flex>
  );
};

export default ModuleLayout;
