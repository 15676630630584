import { useGeneralPreferences, usePersonalDetails } from "hooks/Profile";

const usePendo = () => {
  const {
    details: { visitorId },
  } = useGeneralPreferences();

  const {
    details: { firstName, lastName, country },
  } = usePersonalDetails();

  const initializePendo = () => {
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: visitorId, // Required if user is logged in
        firstName,
        lastName,
        country,
      },

      // account: {
      // id: "ACCOUNT-UNIQUE-ID", // Required if using Pendo Feedback
    });
  };

  return { initializePendo };
};

export default usePendo;
