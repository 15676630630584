import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Pricing";
import { PaymentAdapter } from "adapters/Pricing";
import { APIError } from "errors";
import { TPaymentPayload } from "adapters/Pricing/types";

const URL = links.BASE_PRODUCT_URL.append("/payment");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new PaymentAdapter();

const newPayment = {
  post: async (values: TPaymentPayload) => {
    try {
      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload);
      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default newPayment;
