import { ReactComponent as AscendingIcon } from "assets/icons/WebAnalytics/sort-ascending-icon.svg";
import { ReactComponent as DescendingIcon } from "assets/icons/WebAnalytics/sort-descending-icon.svg";
import { Icon } from "components/core";
import { Container, SortTitle } from "./Sort.styles";

export enum SortType {
  ascending,
  descending,
  none,
}

interface ISort extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  type: any;
}

const Sort = ({ type, title, ...props }: ISort) => {
  return (
    <Container {...props}>
      {type === SortType.descending && <DescendingIcon />}
      {type === SortType.ascending && <AscendingIcon />}
      {type === SortType.none && <Icon name="sort" variant="alpha" />}
      <SortTitle>{title}</SortTitle>
    </Container>
  );
};

export default Sort;
