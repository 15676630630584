import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  position: relative;
  min-width: 120px;
`;

const Dropdown = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: ${helper.getSpacing("extraSmall")};
  gap: ${helper.getSpacing("extraSmall")};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  font-family: ${({ theme }) => theme.fonts.poppins};
  border-radius: 10px;
  cursor: pointer;
`;

export { Container, Dropdown };
