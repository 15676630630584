import { createContext, useState } from "react";

export const SubHeadingContext = createContext({
  heading: "",
  handleChangeHeading: (text: string) => {},
});

const SubHeadingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [heading, setHeading] = useState("");
  return (
    <SubHeadingContext.Provider
      value={{
        heading,
        handleChangeHeading: (text: string) => setHeading(text),
      }}
    >
      {children}
    </SubHeadingContext.Provider>
  );
};

export default SubHeadingProvider;
