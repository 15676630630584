import { TwitterService } from "services/Auth";
import {
  IPotentialLeadsData,
  IPotentialLeadsPayload,
  TLeadsTweetCard,
  TRecommendation,
} from "./types";

type TAPIResponse = {
  potentialLeadTweets: {
    addition_date: string;
    bio: string;
    followers: number;
    following_since: string;
    followings: number;
    influencer: boolean;
    joined_since: string;
    keyword: string[];
    language: string;
    lead_username: string;
    location: string;
    name: string;
    potential_customer: boolean;
    profile_pic_url: string;
    tweets: number;
    tweets_liked: number;
    tweets_replied: number;
    twitter_username: string;
    website: string;
    twitter_profile_url: string;
  }[];
  recommendations: TRecommendation[];
  totalPages: number;
  tweetActions: boolean;
  isTwitterConnected: boolean;
};

type TAPIPayload = {
  keywords: string;
  language: string;
  page?: number;
  filter_keywords?: string;
  location?: string;
  bio_includes?: string;
  bio_excludes?: string;
};

class PotentialLeadsAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IPotentialLeadsData => {
    const cards: TLeadsTweetCard[] = apiResponse.potentialLeadTweets.map(
      ({
        addition_date,
        bio,
        followers,
        following_since,
        followings,
        influencer,
        joined_since,
        keyword,
        language,
        lead_username,
        location,
        name,
        potential_customer,
        profile_pic_url,
        tweets,
        tweets_liked,
        tweets_replied,
        twitter_username,
        website,
        twitter_profile_url,
      }) => ({
        additionDate: new Date(addition_date),
        bio,
        followers,
        followingSince: new Date(following_since),
        followings,
        isInfluencer: influencer,
        joinedSince: new Date(joined_since),
        keywords: keyword,
        language,
        leadUsername: lead_username,
        location,
        name,
        isPotentialCustomer: potential_customer,
        profilePicUrl: profile_pic_url,
        tweets,
        tweetsLiked: tweets_liked,
        tweetsReplied: tweets_replied,
        twitterUsername: twitter_username,
        website,
        profileUrl: twitter_profile_url,
        frequentKeywords: [],
      })
    );
    return {
      cards,
      totalPages: apiResponse.totalPages,
      recommendation: apiResponse.recommendations,
      isTwitterConnected: apiResponse.isTwitterConnected,
      tweetActions: apiResponse.tweetActions,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getGetPayload = (data: IPotentialLeadsPayload) => {
    const payload: TAPIPayload = {
      keywords: data.keywords.filter((word) => word !== "").join(),
      language: data.language,
      page: data.page,
      filter_keywords: data.filterKeywords,
      location: data.location,
      bio_excludes: data.bioExcludes,
      bio_includes: data.bioIncludes,
    };
    return payload;
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default PotentialLeadsAdapter;
