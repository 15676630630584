import { Formik } from "formik";
import { useHistory } from "react-router";
import { signInValidation } from "config/Auth";
import { Form, Container, SocialLogin } from "./common";
import { applicationRoutes as routes } from "config/common";
import { useDolphynSignin } from "hooks";

const initialValues = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const { handleLogin } = useDolphynSignin();
  const history = useHistory();

  return (
    <Container>
      <Form>
        <Form.Header
          as="h1"
          size="medium"
          variant="lightGrey"
          textAlign="center"
          content="Log In"
          font="poppins"
        />
        <SocialLogin />
        <Form.Or />

        <Formik
          initialValues={initialValues}
          onSubmit={handleLogin}
          validationSchema={signInValidation.schema}
        >
          {({ handleSubmit }) => (
            <Form.Form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Input name="email" type="email" placeholder="Email" />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </Form.Field>
              <Form.Text
                variant="alpha"
                font="poppins"
                size="small"
                mb="small"
                style={{
                  textAlign: "right",
                  cursor: "pointer",
                }}
                content="Forgot Password?"
                onClick={() => history.push(routes.rootRoutes.forgotPassword)}
              />
              <Form.Button
                variant="alpha"
                type="submit"
                size="huge"
                fluid
                content="Submit"
                style={{ marginBottom: "2rem" }}
              />
            </Form.Form>
          )}
        </Formik>
        <Form.Text style={{ textAlign: "center" }}>
          Don’t have an account?{" "}
          <Form.Text
            as="span"
            variant="alpha"
            style={{ cursor: "pointer" }}
            content="Create Account"
            onClick={() => history.push(routes.rootRoutes.signUp)}
          />
        </Form.Text>
      </Form>
    </Container>
  );
};

export default SignInForm;
