import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TGridDivisionUnit, TWebAnalyticsGridProps } from "./types";

const helper = new ThemeHelper();

const getDivisions = (
  payload: number[] = [],
  unit: TGridDivisionUnit = "fr"
) => {
  if (!payload.length) return "";
  return payload.join(`${unit} `) + unit;
};

const Grid = styled.div<TWebAnalyticsGridProps>`
  display: grid;
  grid-template-columns: ${({ templateColumns, unit }) =>
    getDivisions(templateColumns, unit)};
  grid-template-rows: ${({ templateRows, unit }) =>
    getDivisions(templateRows, unit)};
  gap: ${({ gap }) => gap && helper.getSpacing(gap)};
  justify-content: ${({ justify }) => justify};
  height: ${({ height }) => height};
`;

export default Grid;
