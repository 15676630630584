import styled from "styled-components";
import { Button as Basebutton } from "components/core";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.alpha};
  border-radius: 5px;
  padding: 0.5rem;
  background-color: ${({ theme }) =>
    helper.lighten(theme.colors.alpha, "high")};
`;

const Input = styled.input`
  &&&& {
    display: block;
    outline: unset;
    border: unset;
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    background-color: ${({ theme }) =>
      helper.lighten(theme.colors.alpha, "high")};
    font-family: ${({ theme }) => theme.fonts.poppins};
    ::placeholder {
      color: ${({ theme }) => theme.colors.alpha};
      font-family: ${({ theme }) => theme.fonts.poppins};
    }

    @media screen and (max-width: 768px) {
      padding: 0 0.5rem;
      font-size: calc((50vw + 50vh) / 60 + 4px);
    }
  }
`;

const Button = styled(Basebutton)`
  &&&& {
    font-family: ${({ theme }) => theme.fonts.poppins};
    border-radius: 8px;
  }
`;

export { Container, Input, Button };
