export const frequentKeywordInfo =
  "No Frequently Used Not enough tweets found to get frequently used words.";

export const textKeys = {
  Text1: "text1",
  Text2: "text2",
  Text3: "text3",
  Text4: "text4",
};

export const twitterDMCharacterLimit = 10000;
