import { throwApiError } from "errors";
import { dolphynPublicRoot } from "api/instances";
import { constants, links } from "config/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/reset_password");

export interface IResetPassword {
  password: string;
  searchParams: string;
}

interface IResetPasswordData {
  message: string;
}

const resetPassword = async ({ password, searchParams }: IResetPassword) => {
  try {
    const searchParamsObject = new URLSearchParams(searchParams);
    const uidb64 = searchParamsObject.get("uidb64");
    const token = searchParamsObject.get("token");
    const { data } = await dolphynPublicRoot.post(URL, {
      new_password: password,
      uidb64,
      token,
    });
    const message = data.message;
    return { message } as IResetPasswordData;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default resetPassword;
