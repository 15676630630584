import { createContext } from "react";
import { useMutation } from "react-query";
import { Spinner } from "components/common";
import SocialLoginProvider from "./SocialLoginProvider";
import { signupDolphyn as signupDolhynApi } from "api/Auth";
import { ISignupDolphyn } from "api/Auth/signupDolphyn";
import { useNotification } from "hooks";

type TContext = {
  handleSignup: (values: ISignupDolphyn) => void;
};

export const SignupContext = createContext<TContext | null>(null);

const SignupProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const notification = useNotification();

  const { mutate: signupDolphyn, isLoading } = useMutation(signupDolhynApi, {
    onSuccess: (data) => {
      if (data) {
        notification.notifyInfo(data.message);
      }
    },
    onError: ({ displayMessage }) => {
      notification.notifyDanger(displayMessage);
    },
  });

  const handleSignup = (values: ISignupDolphyn) => signupDolphyn(values);

  return (
    <SignupContext.Provider
      value={{
        handleSignup,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      <SocialLoginProvider>{children}</SocialLoginProvider>
    </SignupContext.Provider>
  );
};

export default SignupProvider;
