import { loginLinkedin as authApi } from "api/Auth";
import * as linkedin from "redux/slices/Auth/linkedin";
import * as linkedinSelection from "redux/slices/Auth/linkedinSelection";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { useReactQuery } from "hooks/common";

const useLinkedinAuth = () => {
  const dispatch = useAppDispatch();
  const reInitialize = () => {
    dispatch(linkedin.reInitialize());
    dispatch(linkedinSelection.reInitialize());
  };
  const { execute: login, isLoading } = useReactQuery(authApi, {
    onSuccess: () => {
      reInitialize();
    },
  });

  /* 
    States
  */

  const isSignedIn = useAppSelector((state) => state.auth.linkedin.isSignedIn);
  const accounts = useAppSelector((state) => state.auth.linkedin.accounts);
  const selectedAccount = useAppSelector(
    (state) => state.auth.linkedinSelection.selectedAccount
  );

  const isAccountSelected = (uid: linkedin.TUid) => selectedAccount.uid === uid;

  /* 
    Handlers
  */

  const removeAccount = (uid: linkedin.TUid) => {
    dispatch(linkedin.removeAccount(uid));
    dispatch(linkedinSelection.resetSelection());
  };

  const selectAccount = (uid: string) => {
    dispatch(linkedinSelection.selectAccount(uid));
  };

  const clearData = () => {
    dispatch(linkedin.clearData());
    dispatch(linkedinSelection.resetSelection());
  };

  return {
    isSignedIn,
    isLoading,
    login,
    accounts,
    selectedAccount,
    isAccountSelected,
    removeAccount,
    selectAccount,
    clearData,
  };
};

export default useLinkedinAuth;
