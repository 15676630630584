import { IUserProfileBaseSectionData } from "adapters/WebAnalytics/types";

const data: IUserProfileBaseSectionData = {
  pageView: {
    title: "Page View",
    table: {
      rows: [],
      columns: [],
    },
  },
  city: {
    title: "City",
    table: {
      rows: [],
      columns: [],
    },
  },
  landingPage: {
    title: "LandingPage",
    table: {
      rows: [],
      columns: [],
    },
  },
  exitPage: {
    title: "Exit Page",
    table: {
      rows: [],
      columns: [],
    },
  },
  medium: {
    title: "Medium",
    table: {
      rows: [],
      columns: [],
    },
  },
  source: {
    title: "Source",
    table: {
      rows: [],
      columns: [],
    },
  },
};

export default data;
