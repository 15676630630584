import { Menu } from "./Navbar.styles";
import { BrandLogo } from "components/common";
import { useNavigation, useWindowRedirect } from "hooks/common";
import { ReactComponent as LogoIcon } from "assets/icons/brand-logo.svg";
import { Button } from "components/core";
import { links } from "config/Home";

const Navbar = () => {
  const nav = useNavigation();
  const windowRedirect = useWindowRedirect();

  return (
    <Menu forwardedAs="nav" secondary>
      <Menu.Item position="left">
        <BrandLogo
          icon={LogoIcon}
          text="Dolphy"
          size="medium"
          variant="alpha"
          onClick={() =>
            windowRedirect.redirectAbsolute(links.navbar.nullInnovation)
          }
        />
      </Menu.Item>
      <Menu.Menu>
        <Menu.Item
          active={nav.location.pathname === "/"}
          onClick={() => nav.navigate((routes) => routes.rootRoutes.home)}
        >
          Dolphy
        </Menu.Item>
        <Menu.Item
          active={nav.location.pathname === "/blogs"}
          onClick={() => windowRedirect.redirectAbsolute(links.navbar.blogs)}
        >
          Blogs
        </Menu.Item>
        <Menu.Item
          active={nav.location.pathname === "/pricing"}
          onClick={() => nav.navigate((routes) => routes.rootRoutes.pricing)}
        >
          Pricing
        </Menu.Item>
        <Menu.Item
          active={nav.location.pathname === "/nft-tweet-analytics"}
          onClick={() => nav.navigate((routes) => routes.rootRoutes.nft)}
        >
          NFT
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right">
        <Menu.Item
          onClick={() => {
            nav.navigate((routes) => routes.rootRoutes.signIn);
          }}
        >
          Login
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            nav.navigate((routes) => routes.rootRoutes.signUp);
          }}
        >
          <Button variant="alpha" content="Free Trial" />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default Navbar;
