import { Graph } from "components/common";
import { Button } from "components/core";
import { useGraphHelper } from "hooks";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";
import { GraphAction, GraphContainer } from "./AnalyticsGraph.styles";

type TAnalyticsGraph = {
  metric: string;
  value: number;
};
interface IAnalyticsGraph {
  metricsData: {
    graphData: TAnalyticsGraph[];
    profileVisit: number;
    urlClicks: number;
    followers: number;
  };
}

const AnalyticsGraph = (props: IAnalyticsGraph) => {
  const theme = useTheme();
  const graph = useGraphHelper(props.metricsData.graphData);

  return (
    <GraphContainer>
      <GraphAction>
        <Button
          variant="alpha"
          content={`Profile Visits : ${props.metricsData.profileVisit}`}
          outline
        />
        <Button
          variant="alpha"
          content={`URL Clicks : ${props.metricsData.urlClicks}`}
          outline
        />
        <Button
          variant="alpha"
          content={`Followers : ${props.metricsData.followers}`}
          outline
        />
      </GraphAction>
      <Graph.Body>
        <BarChart
          data={props.metricsData.graphData}
          margin={{
            top: 24,
            right: 12,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={graph.getDataKeysMap().metric}
            tickFormatter={graph.capitalizeTick}
          />
          <YAxis scale="linear" axisLine={false} tickLine={false} />
          <Tooltip
            labelStyle={{ textTransform: "capitalize", fontFamily: "poppins" }}
            contentStyle={{
              textTransform: "capitalize",
              fontFamily: "poppins",
            }}
          />
          <Bar
            name="Count"
            barSize={50}
            dataKey={graph.getDataKeysMap().value}
            fill={theme.colors.alpha}
            label={{
              position: "top",
              fontFamily: "poppins",
              fill: theme.colors.alpha,
            }}
          />
        </BarChart>
      </Graph.Body>
    </GraphContainer>
  );
};

export default AnalyticsGraph;
