import { EventCalendar } from "components/common";
import { Flex } from "components/core";
import { FilterMenu } from "../common";
import { useSocialCalendar } from "hooks/SocialMediaPost";

const Menu = () => {
  const calendar = useSocialCalendar();
  return (
    <Flex justify="space-between">
      <FilterMenu onSubmit={calendar.handleFilter}>
        <FilterMenu.PostStatus />
        <FilterMenu.Platform />
      </FilterMenu>
      <EventCalendar.Menu />
    </Flex>
  );
};

export default Menu;
