import { Formik, FormikProps } from "formik";
import {
  InnerContainer,
  OuterContainer,
  Footer,
} from "./CompleteRegistration.styles";
import { useNavigation, useNotification } from "hooks/common";
import { Steps } from "components/common";
import OccupationForm from "./OccupationForm";
import ProductForm from "./ProductForm";
import TimezoneLanguageForm from "./TimezoneLanguageForm";
import CountryForm from "./CountryForm";
import KeywordsForm from "./KeywordsForm";
import {
  useGeneralPreferences,
  useKeywordsDetails,
  usePersonalDetails,
  useRegistrationStatus,
  useRegistrationSteps,
} from "hooks/Profile";
import { TCompleteRegistrationFormValues } from "./types";
import { registrationCompleted } from "api/Profile";
import ReferralForm from "./ReferralForm";
import { useAppSelector } from "redux/store/hooks";

const initialState: TCompleteRegistrationFormValues = {
  occupation: "",
  product: "",
  country: "",
  timezone: "",
  email: "",
  referral: "",
  language: "English",
  primaryKeyword: "",
  secondaryKeywords: ["", "", "", "", "", "", "", "", ""],
};

const CompleteRegistration = () => {
  const steps = useRegistrationSteps();
  const personalDetails = usePersonalDetails();
  const generalDetails = useGeneralPreferences();
  const keywordsDetails = useKeywordsDetails();
  const registrationStatus = useRegistrationStatus();
  const nav = useNavigation();
  const notification = useNotification();

  const isKeywords = useAppSelector(
    (state) => state.profile.registrationStatus.keywords
  );
  const isCountry = useAppSelector(
    (state) => state.profile.registrationStatus.country
  );
  const isOccupation = useAppSelector(
    (state) => state.profile.registrationStatus.occupation
  );
  const isProduct = useAppSelector(
    (state) => state.profile.registrationStatus.product
  );
  const isReferral = useAppSelector(
    (state) => state.profile.registrationStatus.referral
  );
  const isTimezoneLanguage = useAppSelector(
    (state) => state.profile.registrationStatus.timezone_language
  );

  const handleFormikSubmit = async ({
    occupation,
    product,
    country,
    timezone,
    language,
    email,
    referral,
    primaryKeyword,
    secondaryKeywords,
  }: TCompleteRegistrationFormValues) => {
    if (
      !email ||
      primaryKeyword.split(" ").length > 2 ||
      secondaryKeywords.some((keyword) => keyword.split(" ").length > 2)
    ) {
      if (!email) {
        notification.notifyInfo("Email can't be empty!");
      }
      if (primaryKeyword.split(" ").length > 2) {
        notification.notifyInfo(
          "Primary keyword should have maximum two words!"
        );
      }
      if (secondaryKeywords.some((keyword) => keyword.split(" ").length > 2)) {
        notification.notifyInfo(
          "Secondary keywords should have maximum two words!"
        );
      }
    } else {
      if (!isCountry) {
        await personalDetails.handleUpdateAsync({
          country,
        });
      }
      if (!isOccupation) {
        await generalDetails.handleUpdateAsync({
          occupation,
        });
      }
      if (!isReferral) {
        await generalDetails.handleUpdateAsync({
          referral,
        });
      }
      if (!isProduct) {
        await generalDetails.handleUpdateAsync({
          product,
        });
      }
      if (!isTimezoneLanguage) {
        await generalDetails.handleUpdateAsync({
          language,
        });
        await personalDetails.handleUpdateAsync({
          timezone,
        });
      }
      if (!isKeywords) {
        await keywordsDetails.handleUpdateAsync({
          primaryKeyword,
          secondaryKeywords,
        });
      }
      await personalDetails.handleUpdateAsync({
        email,
      });
      const status = await registrationStatus.fetchAsync();
      if (status) {
        nav.navigate((routes) => routes.leadGeneration.keywordListening);
        registrationCompleted();
      }
    }
  };

  const renderQuestion = (
    formik: FormikProps<TCompleteRegistrationFormValues>
  ) => {
    switch (steps.currentStep) {
      case steps.stepsMap.OCCUPATION:
        return <OccupationForm name="occupation" />;
      case steps.stepsMap.PRODUCT:
        return <ProductForm name="product" />;
      case steps.stepsMap.COUNTRY:
        return <CountryForm name="country" />;
      case steps.stepsMap.TIMEZONE_LANGUAGE:
        return (
          <TimezoneLanguageForm
            currentCountry={formik.values.country}
            name={{
              timezone: "timezone",
              language: "language",
              email: "email",
            }}
          />
        );
      case steps.stepsMap.KEYWORDS:
        return (
          <KeywordsForm
            name={{
              primaryKeyword: "primaryKeyword",
              secondaryKeywords: "secondaryKeywords",
            }}
          />
        );
      case steps.stepsMap.REFERRAL:
        return (
          <ReferralForm
            name="referral"
            onGetStarted={() => formik.handleSubmit()}
          />
        );
      default:
        return null;
    }
  };

  return (
    <OuterContainer>
      <Formik
        initialValues={{
          ...initialState,
          email: personalDetails.details.email,
        }}
        enableReinitialize
        onSubmit={handleFormikSubmit}
      >
        {(formik) => (
          <>
            <InnerContainer>
              <Steps.Backward
                round
                onClick={() => steps.handleGoToPrevStep(formik.values)}
                disabled={steps.getIsBackDisabled(formik.values)}
              />
              {renderQuestion(formik)}
              <Steps.Forward
                round
                onClick={() => steps.handleGoToNextStep(formik.values)}
                disabled={steps.getIsNextDisabled(formik.values)}
              />
            </InnerContainer>
            <Footer>
              <Steps total={steps.totalSteps} current={steps.currentStep} />
            </Footer>
          </>
        )}
      </Formik>
    </OuterContainer>
  );
};

export default CompleteRegistration;
