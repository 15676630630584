import { CouponSystemAdapter } from "adapters/Admin";
import { dolphynAPI } from "api/instances";
import { links } from "config/Admin";
import { links as profile } from "config/Profile";
import { APIError } from "errors";
import { IGenerateCouponPayload, IRedeemCouponPayload } from "./types";

const error = new APIError();
const URL = links.baseAdminApiUrl.append("/generate_coupon_code");
const RURL = profile.BASE_PROFILE_API_URL.append("/redeem_coupon_code");
const adapter = new CouponSystemAdapter();

const couponSystem = {
  generate: async (values: IGenerateCouponPayload) => {
    try {
      const apiPayload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, apiPayload);
      return adapter.getResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  redeem: async (values: IRedeemCouponPayload) => {
    try {
      const apiPayload = adapter.getRedeemPayload(values);
      const { data } = await dolphynAPI.post(RURL, apiPayload);
      return adapter.getRedeemResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default couponSystem;
