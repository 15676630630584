import { Menu } from "./Menu.styles";
import { MenuProps } from "semantic-ui-react";
import Item from "./MenuItem";

interface IModuleMenuProps extends MenuProps {
  items?: { title: string; route: string }[];
}

const ModuleMenu = ({ items, children, ...props }: IModuleMenuProps) => {
  if (items) {
    return (
      <Menu secondary {...props}>
        {items.map(({ title, route }, id) => (
          <Item key={id} routerPath={route}>
            {title}
          </Item>
        ))}
        {children}
      </Menu>
    );
  }
  return (
    <Menu secondary {...props}>
      {children}
    </Menu>
  );
};

ModuleMenu.Menu = ModuleMenu;
ModuleMenu.Item = Item;

export default ModuleMenu;
