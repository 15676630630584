import { ActionButton } from "components/core";

interface ILikeButtonProps {
  count: number;
  isLiked: boolean;
  onClick?: () => void;
}

const LikeButton = ({ count, isLiked, ...props }: ILikeButtonProps) => {
  return (
    <ActionButton
      icon="heart"
      variant={isLiked ? "danger" : "alpha"}
      content={count}
      size="tiny"
      borderless
      {...props}
    />
  );
};

export default LikeButton;
