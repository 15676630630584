import { links } from "config/Twitter";
import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import FileAdapter, { TFileAPIPayload } from "adapters/Twitter/files";
import { APIError } from "errors";

const URL = links.baseTwitterApiUrl.append("/upload_file");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new FileAdapter();

type TFilesPostValues = TFileAPIPayload;

const files = {
  post: async (values: TFilesPostValues) => {
    try {
      const formData = adapter.getPostPayload(values);

      const response = await dolphynAPI.post(URL, formData);
      const data = adapter.parsePostResponse(response.data);
      return adapter.parsePostValues(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default files;
