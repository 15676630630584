import { Form } from "components/core";
import {
  timezoneListConfig,
  countryListConfig,
  languagesListConfig,
} from "config/common";

interface IPersonalDetailsFormProps {
  editable: boolean;
  formId: string;
  onSubmit: () => void;
}

const PersonalDetailsForm = ({
  editable,
  formId,
  onSubmit,
}: IPersonalDetailsFormProps) => {
  return (
    <Form>
      <Form.Form
        id={formId}
        onSubmit={onSubmit}
        style={{ gridTemplateColumns: "1fr 1fr" }}
      >
        <div>
          <Form.Field>
            <Form.Label content="First Name" />
            <Form.Input
              name="firstName"
              type="text"
              focused
              readOnly={!editable}
            />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Last Name" />
            <Form.Input
              name="lastName"
              type="text"
              readOnly={!editable}
              focused
            />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Email" />
            <Form.Input name="email" type="email" focused readOnly />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Organization Name" />
            <Form.Input
              name="organizationName"
              type="text"
              readOnly={!editable}
              focused
            />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Language" />
            <Form.DropdownField
              glow
              placeholder="Select Language"
              name="language"
              readOnly={!editable}
              options={languagesListConfig}
            />
          </Form.Field>
        </div>
        <div>
          <Form.Field>
            <Form.Label content="Username" />
            <Form.Input
              name="username"
              type="text"
              readOnly={!editable}
              focused
            />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Country" />
            <Form.DropdownField
              glow
              placeholder="Select Country"
              name="country"
              readOnly={!editable}
              options={countryListConfig}
            />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Timezone" />
            <Form.DropdownField
              glow
              placeholder="Select Timezone"
              name="timezone"
              readOnly={!editable}
              options={timezoneListConfig}
            />
          </Form.Field>
          <Form.Field>
            <Form.Label content="Size of your organization" />
            <Form.Input
              name="organizationSize"
              type="text"
              readOnly={!editable}
              focused
            />
          </Form.Field>
        </div>
      </Form.Form>
    </Form>
  );
};

export default PersonalDetailsForm;
