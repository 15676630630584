import { TInstantReplies } from "adapters/Twitter/types";
import CurrentTweet from "./CurrentTweet";
import CurrentConversation from "./CurrentConversation";
import { InstantRepliesCardContainer } from "./InstantRepliesCard.styles";

interface TInstantRepliesCard {
  currentTweet: TInstantReplies;
  setCurrentTweet: (values: TInstantReplies) => void;
}

const InstantRepliesCard = ({
  currentTweet,
  setCurrentTweet,
}: TInstantRepliesCard) => {
  return (
    <InstantRepliesCardContainer>
      <CurrentTweet currentTweet={currentTweet} />
      <CurrentConversation
        conversations={currentTweet.conversation}
        setCurrentTweet={setCurrentTweet}
      />
    </InstantRepliesCardContainer>
  );
};

export default InstantRepliesCard;
