import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showSetLimit: false,
  followersLimit: 10000,
  bioIncludes: [] as string[],
  bioExcludes: [] as string[],
  location: [] as string[],
  filterKeywords: [] as string[],
};

export const influencer = createSlice({
  name: "influencer",
  initialState,
  reducers: {
    setShowSetLimit: (state, action: PayloadAction<boolean>) => {
      return { ...state, showSetLimit: action.payload };
    },
    setFollowersLimit: (state, action: PayloadAction<number>) => {
      return { ...state, followersLimit: action.payload };
    },
    setBioIncludesI: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        bioIncludes: action.payload,
      };
    },
    setBioExcludesI: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        bioExcludes: action.payload,
      };
    },
    setLocationI: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        location: action.payload,
      };
    },
    setFilterKeywordsI: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        filterKeywords: action.payload,
      };
    },
  },
});

export const {
  setShowSetLimit,
  setFollowersLimit,
  setBioIncludesI,
  setBioExcludesI,
  setLocationI,
  setFilterKeywordsI,
} = influencer.actions;

export default influencer.reducer;
