import { InputContainer } from "./CheckboxField.styles";
import { FieldHookConfig, useField } from "formik";
import FormError from "./FormError";
import Checkbox from "./Checkbox";

type IInputProps = React.HTMLAttributes<HTMLInputElement> &
  FieldHookConfig<any> & {
    label: React.ReactNode;
  };

const CheckboxField = ({ label, style, ...props }: IInputProps) => {
  const [field, meta] = useField(props);

  return (
    <>
      <InputContainer style={style}>
        <Checkbox
          {...props}
          {...field}
          style={{ display: "inline-grid" }}
          type="checkbox"
        />
        {label}
      </InputContainer>
      {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
    </>
  );
};

export default CheckboxField;
