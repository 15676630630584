import { Redirect as RouterRedirect, RedirectProps } from "react-router";
import { applicationRoutes } from "config/common";

interface IRedirectProps extends Omit<RedirectProps, "to"> {
  to: (routes: typeof applicationRoutes) => string;
}

const Redirect = ({ to, ...props }: IRedirectProps) => {
  return <RouterRedirect to={to(applicationRoutes)} {...props} />;
};

export default Redirect;
