import { ISentPostPayload } from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { TPosts } from "./types";

type TAPIResponse = {
  data: TPosts[];
};

class SentPostAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      posts: apiResponse.data,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getParams = (data: ISentPostPayload) => {
    const account = this.twitter.getAccount(data.uid);
    if (account)
      return {
        timezone: data.timezone,
        status: "sent",
        account_id: account.userId,
      };
    return null;
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default SentPostAdapter;
