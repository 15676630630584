import { TwitterService } from "services/Auth";
import { TInstantReplies } from "./types";

type TAPIPayload = {
  like: boolean;
};

type TAPIResponse = {
  like: boolean;
};

class LikeInstantAdapter {
  private twitter = new TwitterService();
  private parseResponse = (
    instantReply: TInstantReplies,
    apiResponse: TAPIResponse
  ): TInstantReplies => {
    if (apiResponse.like) {
      return {
        ...instantReply,
        likeStatus: true,
        likeCount: instantReply.likeCount + 1,
      };
    }
    return {
      ...instantReply,
      likeStatus: false,
      likeCount: instantReply.likeCount - 1,
    };
  };

  getPostUrl = (url: string, instantReply: TInstantReplies) =>
    `${url}/${instantReply.tweetId}`;
  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });
  getPostPayload = (data: TInstantReplies): TAPIPayload => {
    return {
      like: !data.likeStatus,
    };
  };
  parsePostResponse = this.parseResponse;
}

export default LikeInstantAdapter;
