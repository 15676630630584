import { useEffect, useState } from "react";
import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const ContentWrapper = styled.div`
  margin-top: ${helper.getSpacing("small")};
  min-height: 125px;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

type TDefaultProps = {
  postText: string;
  postFiles: File[];
};

const PostContent = ({ postText, postFiles }: TDefaultProps) => {
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    if (postFiles.length) {
      const objectUrl = URL.createObjectURL(postFiles[0]);
      setPreviewUrl(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [postFiles]);

  return (
    <ContentWrapper>
      {previewUrl && <PreviewImage src={previewUrl} alt="preview" />}
    </ContentWrapper>
  );
};

export default PostContent;
