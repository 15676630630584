import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ILoginSearchConsoleData } from "api/Auth/loginGoogleSearchConsole";
import { SearchConsoleService } from "services/WebAnalytics";

const searchConsoleService = new SearchConsoleService();

const initialState: ILoginSearchConsoleData = searchConsoleService.getSites();

export const googleSearchConsoleSiteData = createSlice({
  name: "googleSearchConsoleSiteData",
  initialState,
  reducers: {
    setGoogleSearchConsoleSitesData: (
      state,
      action: PayloadAction<ILoginSearchConsoleData>
    ) => {
      const paylaod = {
        ...state,
        sites: action.payload.sites,
        userEmail: action.payload.userEmail,
        profilePicUrl: action.payload.profilePicUrl,
      };
      searchConsoleService.setSites(paylaod);
      return paylaod;
    },
    removeGoogleSearchConsoleSitesData: (state) => {
      searchConsoleService.removeSites();
      searchConsoleService.removeAuthCookie();
      const payload = searchConsoleService.getSites();
      return { ...state, ...payload };
    },
  },
});

export const {
  setGoogleSearchConsoleSitesData,
  removeGoogleSearchConsoleSitesData,
} = googleSearchConsoleSiteData.actions;

export default googleSearchConsoleSiteData.reducer;
