import { SemanticICONS } from "semantic-ui-react";
import trendingKeywordsTableMenuMap from "./trendingKeywordsTableMenuMap";

const trendingKeywordsTableIcons = [
  {
    id: trendingKeywordsTableMenuMap.youtube,
    iconNameProp: "youtube" as SemanticICONS,
  },
  {
    id: trendingKeywordsTableMenuMap.twitter,
    iconNameProp: "twitter" as SemanticICONS,
  },
  {
    id: trendingKeywordsTableMenuMap.google,
    iconNameProp: "google" as SemanticICONS,
  },
];

export default trendingKeywordsTableIcons;
