import styled from "styled-components";
import { ModuleMenu as SubMenu } from "components/common";
import { useEventCalendar } from "hooks/common";

const Container = styled.div`
  margin-bottom: 1rem;
`;

const Menu = () => {
  const eventCalendar = useEventCalendar();

  return (
    <Container>
      <SubMenu style={{ border: "unset", justifyContent: "flex-end" }}>
        <SubMenu.Item
          active={eventCalendar.view === eventCalendar.viewMap.month}
          onClick={() => eventCalendar.changeView(eventCalendar.viewMap.month)}
        >
          Month
        </SubMenu.Item>
        <SubMenu.Item
          active={eventCalendar.view === eventCalendar.viewMap.week}
          onClick={() => eventCalendar.changeView(eventCalendar.viewMap.week)}
        >
          Week
        </SubMenu.Item>
        <SubMenu.Item
          active={eventCalendar.view === eventCalendar.viewMap.day}
          onClick={() => eventCalendar.changeView(eventCalendar.viewMap.day)}
        >
          Day
        </SubMenu.Item>
      </SubMenu>
    </Container>
  );
};

export default Menu;
