import { useField } from "formik";
import { Header } from "components/core";
import { IScheduleUpdateFormValues } from "./types";
import Preview from "components/common/FileDrop/Preview";
import { useThemeHelper } from "hooks";

interface IPostFileProps {
  name: string;
}

const PostServerFiles = (props: IPostFileProps) => {
  const [field, , helpers] = useField<IScheduleUpdateFormValues["serverFiles"]>(
    props.name
  );
  const theme = useThemeHelper();

  const getPreviewUrls = () => {
    return field.value.map((file) => ({
      id: file.id,
      url: file.fileUrl,
      type: file.fileType,
    }));
  };

  return (
    <>
      <Header
        as="div"
        font="poppins"
        size="small"
        mb="small"
        content="Files you previously uploaded"
      />
      <Preview
        style={{ marginBottom: theme.helper.getSpacing("small") }}
        previewUrls={getPreviewUrls()}
        onDelete={({ id }) =>
          helpers.setValue(field.value.filter((file) => file.id !== id))
        }
      />
    </>
  );
};

export default PostServerFiles;
