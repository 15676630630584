import { Fragment } from "react";
import { range } from "lodash";
import { useEventCalendar } from "hooks/common";
import { format, getHours, isSameDay } from "date-fns";
import {
  Container,
  Body,
  WeekContainer,
  WeekBox,
  TimeBox,
  HeaderContainer,
  HeaderBox,
} from "./WeekView.styles";
import { IBaseCalendarProps, TDayViewCol, TEventData } from "./types";

const weekNames = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderBox />
      {range(0, 7).map((key) => (
        <HeaderBox key={key}>{weekNames[key]}</HeaderBox>
      ))}
    </HeaderContainer>
  );
};

interface IWeekViewProps<T extends TEventData, U extends TDayViewCol>
  extends IBaseCalendarProps<T, U> {}

const WeekView = <T extends TEventData, U extends TDayViewCol>(
  props: IWeekViewProps<T, U>
) => {
  const eventCalendar = useEventCalendar();

  const renderWeekDate = (date: Date) => {
    return format(date, "d");
  };

  const renderEvents = (date: Date, hour: number) => {
    const events = props.events.filter((event) => {
      const offsetDatetime = eventCalendar.getOffsetDatetime(date, hour);

      return (
        isSameDay(event.datetime, date) &&
        getHours(event.datetime) === getHours(offsetDatetime)
      );
    });
    return props.renderEvents(events);
  };

  const renderHour = (hour: number) => {
    const offsetDatetime = eventCalendar.getOffsetDatetime(new Date(), hour);
    return format(offsetDatetime, "hh:mm a");
  };

  const renderBody = () => {
    const { timeGridOffset: offset = 0 } = props;
    return range(0 + offset, 24 + offset).map((hour) => (
      <Fragment key={hour}>
        <TimeBox>{renderHour(hour)}</TimeBox>
        {eventCalendar.dates.map((date, key) => (
          <TimeBox key={key}>{renderEvents(date, hour)}</TimeBox>
        ))}
      </Fragment>
    ));
  };

  const renderWeeks = () => {
    return (
      <WeekContainer>
        <div></div>
        {eventCalendar.dates.map((date, key) => (
          <WeekBox key={key}>{renderWeekDate(date)}</WeekBox>
        ))}
      </WeekContainer>
    );
  };

  return (
    <>
      <Container>
        <Header />
        {renderWeeks()}
        <Body>{renderBody()}</Body>
      </Container>
    </>
  );
};

export default WeekView;
