import styled from "styled-components";
import { styles } from "../Common";

const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.alpha};
`;

const InnerContainer = styled.div`
  padding: ${styles.helper.getSpacing("large")} 0;
  ${styles.helper.containerize(80)}
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${styles.helper.getSpacing("medium")};
  color: ${({ theme }) => theme.colors.white};
  ${styles.helper.getFontSize("small")}
  @media screen and (max-width: 768px) {
    gap: ${styles.helper.getSpacing("small")};
  }
`;

const CompanyInfoContainer = styled.div``;

const Header = styled.div`
  &&& {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-family: ${({ theme }) => theme.fonts.playfair};
  }
`;

const Description = styled.p`
  max-width: 30ch;
`;

const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: ${styles.helper.getSpacing("small")};
  padding: ${styles.helper.getSpacing("small")} 0;
  svg {
    cursor: pointer;
  }
`;

const CopyRightTextContainer = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.alpha};
  color: ${({ theme }) => theme.colors.white};
  padding: ${styles.helper.getSpacing("small")} 0;
  ${styles.helper.getFontSize("small")}
`;

export {
  Container,
  CompanyInfoContainer,
  Header,
  Description,
  SocialLinksContainer,
  CopyRightTextContainer,
  InnerContainer,
};
