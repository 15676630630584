import styled, { css } from "styled-components";

type TContainerProps = {
  dragging: boolean;
};

const Container = styled.div<TContainerProps>`
  display: grid;
  place-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px dashed ${({ theme }) => theme.colors.lightGrey};
  border-radius: 10px;
  padding: 1rem;
  min-height: 100px;
  transition: 0.3s border;
  ${({ dragging }) => {
    return (
      dragging &&
      css`
        border-color: ${({ theme }) => theme.colors.alpha};
        border-width: 2px;
        background-color: ${({ theme }) => theme.colors.beta};
      `
    );
  }}
`;

export { Container };
