import { useField } from "formik";
import { QuestionCard } from "../common";
import { countryListConfig } from "config/common";
import { useRegistrationSteps } from "hooks/Profile";
import ContentContainer from "../common/ContentContainer";
import illustration from "assets/images/Profile/country-page-illustration.png";
import { countryFormData } from "config/Profile";
import { TCompleteRegistrationFormValues } from "./types";

interface ICountryForm {
  name: string;
}

const CountryForm = (props: ICountryForm) => {
  const [field, , helpers] = useField<
    TCompleteRegistrationFormValues["country"]
  >(props.name);
  const steps = useRegistrationSteps();

  const handleUpdate = (country: string) => {
    helpers.setValue(country);
    steps.handleGoToNextStep();
  };

  return (
    <ContentContainer>
      <ContentContainer.Header
        image={illustration}
        style={{ alignSelf: "flex-start", marginLeft: "1rem" }}
      />
      <QuestionCard>
        <QuestionCard.Header
          as="h1"
          size="medium"
          font="poppins"
          fontWeight="high"
          variant="darkGrey"
          textAlign="center"
          content="Where are you from?"
        />
        <QuestionCard.Options>
          {countryFormData.map(({ id, value, icon, text }) => (
            <QuestionCard.Option
              key={id}
              icon={icon}
              text={text}
              active={field.value === value}
              onClick={() => handleUpdate(value)}
            />
          ))}
        </QuestionCard.Options>
        <QuestionCard.OtherOption
          value={field.value}
          onSubmit={(value) => handleUpdate(value)}
        >
          {({ values, setFieldValue }) => (
            <QuestionCard.Dropdown
              options={countryListConfig}
              placeholder="Select a country"
              selection
              search
              value={values.other}
              onChange={(e, { value }) => setFieldValue("other", value)}
            />
          )}
        </QuestionCard.OtherOption>
      </QuestionCard>
    </ContentContainer>
  );
};

export default CountryForm;
