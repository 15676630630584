import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const ProfileIcon = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.alpha};
  padding: 12px;
  text-align: center;
  ${helper.getFontSize("small")}
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  text-transform: uppercase;
`;

export default ProfileIcon;
