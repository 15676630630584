import { TInstantReplies } from "adapters/Twitter/types";
import { Linkify } from "components/common";
import { ReplyingCard, TweetCard } from "../common";
import { TwitterHeart, TwitterReply, TwitterRetweet } from "../common/icons";

interface Props {
  currentTweet: TInstantReplies;
}

const CurrentTweet = ({ currentTweet }: Props) => {
  return (
    <ReplyingCard>
      <ReplyingCard.Sidebar>
        <ReplyingCard.Image
          src={currentTweet.imageUrl}
          alt={currentTweet.name}
        />
        {currentTweet.conversation.length !== 0 && (
          <ReplyingCard.Line variant="extraLightGrey" />
        )}
      </ReplyingCard.Sidebar>
      <ReplyingCard.Content>
        <ReplyingCard.Head>
          <ReplyingCard.ReplyingUsername
            name={currentTweet.name}
            username={currentTweet.leadUsername}
            datetime={new Date(currentTweet.createdAt)}
          />
        </ReplyingCard.Head>
        <ReplyingCard.Bio>
          <Linkify>{currentTweet.text}</Linkify>
        </ReplyingCard.Bio>
        <ReplyingCard.Action>
          <TweetCard.Stat icon={TwitterReply} value={currentTweet.replyCount} />
          <TweetCard.Stat
            icon={TwitterRetweet}
            value={currentTweet.retweetCount}
          />
          <TweetCard.Stat icon={TwitterHeart} value={currentTweet.likeCount} />
        </ReplyingCard.Action>
      </ReplyingCard.Content>
    </ReplyingCard>
  );
};

export default CurrentTweet;
