import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { styles } from "../Common";

const helper = new ThemeHelper();

type MenuItemProps = {
  active?: boolean;
};

const Container = styled.div<MenuItemProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${styles.helper.getSpacing("small")};
  gap: ${styles.helper.getSpacing("small")};
  height: 80px;
  cursor: pointer;
  svg {
    height: 100%;
    path {
      fill: ${({ theme }) => theme.colors.alpha};
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 120%;
    height: 5px;
    background-color: ${({ theme }) =>
      helper.transparentize(theme.colors.alpha, "high")};
    bottom: -15px;
  }
  ${({ active }) => {
    return (
      active &&
      css`
        &::after {
          background-color: ${({ theme }) => theme.colors.alpha};
        }
      `
    );
  }}

  @media screen and (max-width: 768px) {
    width: 12vw;

    svg {
      height: 75%;
    }

    span {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    width: 6vw;

    svg {
      height: 50%;
    }

    &::after {
      width: 80%;
      height: 4px;
    }
  }
`;

export { Container };
