import { Form } from "components/core";
import { redeemCouponValidation } from "config/Profile";
import { Formik } from "formik";
import { useNotification } from "hooks";
import { useRedeemCoupon } from "hooks/Admin";

const initialFormValues = {
  coupon: "",
};

const RedeemCoupon = () => {
  const notification = useNotification();
  const { execute: handleRedeemCoupon } = useRedeemCoupon();

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={redeemCouponValidation}
      onSubmit={(values) =>
        handleRedeemCoupon(values, {
          onSuccess: (data) => {
            if (data) notification.notifyInfo(data.message);
          },
        })
      }
    >
      {({ handleSubmit }) => (
        <Form>
          <Form.Form onSubmit={handleSubmit}>
            <Form.Field>
              <Form.Label content="Coupon Code" />
              <Form.Input name="coupon" type="text" />
            </Form.Field>
            <Form.Button
              variant="alpha"
              size="small"
              mt="small"
              type="submit"
              content="Redeem"
            />
          </Form.Form>
        </Form>
      )}
    </Formik>
  );
};

export default RedeemCoupon;
