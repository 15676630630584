import { Button as BaseButton } from "components/core";
import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: ${helper.getSpacing("medium")} 0;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.extraLightGrey};
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: ${helper.getSpacing("small")};
  }
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${helper.getSpacing("medium")};
  max-width: 400px;
  @media screen and (max-width: 1000px) {
    max-width: unset;
    text-align: center;
  }
`;

const Header = styled.h2`
  font-family: ${({ theme }) => theme.fonts.playfair};
  ${helper.getFontSize("medium")}
  ${helper.getFontWeight("low")}
`;

const Description = styled.p`
  ${helper.getFontSize("small")}
  ${helper.getLineHeight("medium")}
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  @media screen and (max-width: 768px) {
    font-size: calc(0.6rem + (40vh + 40vw) / 100);
  }
`;

const Button = styled(BaseButton)`
  &&& {
    align-self: center;
    padding: ${helper.getSpacing("extraSmall")};
  }
`;

export {
  Container,
  ImageContainer,
  TextContainer,
  Header,
  Description,
  Button,
};
