import { Button, LinkButton } from "components/core";
import { TextSection } from "../Common";
import { useNavigation } from "hooks";
import { ButtonContainer, Strong } from "../Common/styles";

const About = () => {
  const nav = useNavigation();

  return (
    <TextSection style={{ marginBottom: "10rem" }}>
      <TextSection.Header as="h2">
        About
        <span>Dolphy</span>
      </TextSection.Header>
      <TextSection.Description>
        <p>
          Dolphy works on 4 Cs <Strong>C</Strong>onnect, <Strong>C</Strong>
          ommunicate, <Strong>C</Strong>onvert and <Strong>C</Strong>omfort.
          Using AI-text analysis, it finds the relevant tweets, leads and
          influencers using keywords you mentioned. Communicate easily by
          sending personalised replies, like, retweeting and setting up
          auto-welcome message. Nurture those relationship by accessing your
          followings' last 7 days' tweets, replies, retweets and understand
          their interest, intent. Provide support to your customers with instant
          reply. Ultimately, Dolphy helps you increase your conversation rate
          with less customer acquisition cost (CAC).
        </p>
        <ButtonContainer>
          <Button
            variant="alpha"
            content="Sign Up"
            onClick={() => nav.navigate((routes) => routes.rootRoutes.signUp)}
          />
          <LinkButton
            content="Watch Video"
            style={{ cursor: "pointer", fontWeight: "500" }}
          />
        </ButtonContainer>
      </TextSection.Description>
    </TextSection>
  );
};

export default About;
