import { TwitterService } from "services/Auth";
import {
  TScheduleCreatePayload,
  TScheduleUpdatePayload,
} from "api/SocialMediaPost/types";
import FileAdapter, { TFileAPIResponse } from "adapters/common/files";
import { platforms, platformsIconMap } from "config/SocialMediaPost/platforms";
import { TPostStatus, TTwitterPostObject } from "./types";
import { DateTimeHelper } from "helpers";

type TPOSTPayload = {
  token_id: string;
  account_name: string;
  account_id: string;
  datetime: string;
  message: string;
  files?: TFileAPIResponse;
  email_notify?: number;
};

type TResponseData = {
  post_id: string;
  account_name: string;
  message: string;
  files: TFileAPIResponse;
  datetime: string;
  post_status: string;
  email_notify: number;
};

type TPUTPayload = TPOSTPayload;

type TPOSTResponse = {
  data: TResponseData[];
  available_quota: {
    posts: number;
  };
};

type TPUTResponse = {
  data: TResponseData[];
  available_quota: {
    posts: number;
  };
};

type TGETResponse = {
  data: TResponseData[];
  total_pages: number;
};

const dateTime = new DateTimeHelper();

class ScheduleTWAdapter {
  private service: TwitterService = new TwitterService();
  private fileAdapter: FileAdapter = new FileAdapter();

  private getPostCredentials = (twitter: TScheduleCreatePayload["twitter"]) => {
    const accountCred = this.service.getAccount(twitter.uid);

    return accountCred;
  };

  private getPutCredentials = (twitter: TScheduleUpdatePayload["twitter"]) => {
    if (twitter) {
      const accountCred = this.service.getAccount(twitter.uid);

      return accountCred;
    }
    return null;
  };

  private getImagePicUrl = (username: string) => {
    const uid = this.service.getUid(username);

    const page = this.service.getAccount(uid);
    if (page) return page.profileImageUrl;
    return "";
  };

  private parseResponseData = (
    responseData: TResponseData[]
  ): TTwitterPostObject[] => {
    return responseData.map((postObject) => ({
      id: postObject.post_id,
      name: postObject.account_name,
      text: postObject.message,
      serverFiles: this.fileAdapter.parsePostResponse(postObject.files),
      datetime: dateTime.getUTCAsLocal(new Date(postObject.datetime)),
      postStatus: postObject.post_status as TPostStatus,
      platform: platforms.twitter,
      icon: platformsIconMap.twitter.icon,
      dayViewColId: platforms.twitter,
      profileImageUrl: this.getImagePicUrl(postObject.account_name),
      twitter: {
        uid: this.service.getUid(postObject.account_name),
        accountName: postObject.account_name,
      },
    }));
  };

  getHeaders = (uid: string) => {
    const account = this.service.getAccount(uid);
    if (account) {
      const headers = { "Twitter-Account": account.userId };
      return headers;
    }
  };

  getPostPayload = ({
    datetime,
    text,
    twitter,
    serverFiles,
  }: TScheduleCreatePayload) => {
    const account = this.getPostCredentials(twitter);
    if (account) {
      const payload: TPOSTPayload = {
        datetime: datetime.toISOString(),
        message: text,
        token_id: account.tokenId,
        files: this.fileAdapter.parsePostValues(serverFiles),
        account_name: account.userName,
        account_id: account.userId,
      };
      return payload;
    }
    return null;
  };

  getPutPayload = ({
    datetime,
    text,
    twitter,
    serverFiles,
  }: TScheduleUpdatePayload) => {
    const account = this.getPutCredentials(twitter);
    if (account) {
      const payload: TPUTPayload = {
        datetime: datetime.toISOString(),
        message: text,
        token_id: account.tokenId,
        files: this.fileAdapter.parsePostValues(serverFiles),
        account_name: account.userName,
        account_id: account.userId,
      };
      return payload;
    }
    return null;
  };

  parseCalendarResponse = (data: TGETResponse) => {
    return this.parseResponseData(data.data);
  };

  parseActivityResponse = (data: TGETResponse) => {
    return {
      data: this.parseResponseData(data.data),
      totalPages: data.total_pages,
    };
  };

  parsePutResponse = ({ data }: TPUTResponse): TTwitterPostObject =>
    this.parseResponseData(data)[0];

  parsePostResponse = ({ data }: TPOSTResponse): TTwitterPostObject[] =>
    this.parseResponseData(data);
}

export default ScheduleTWAdapter;
