import { Text, ForwardBackward } from "components/core";
import { Container } from "./NavigateDate.styles";

interface INavigateDateProps {
  onForwardClick: () => void;
  onBackwardClick: () => void;
  text: string;
}

const NavigateDate = (props: INavigateDateProps) => {
  return (
    <Container>
      <ForwardBackward
        onForwardClick={props.onForwardClick}
        onBackwardClick={props.onBackwardClick}
      >
        <Text as="h1" variant="darkGrey" content={props.text} />
      </ForwardBackward>
    </Container>
  );
};

export default NavigateDate;
