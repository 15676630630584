import { CalendarMonthsHelper } from "helpers";

const useCalendarMonths = () => {
  /* 
    Hook to handle state operations of Date[] to be used in Calendar Month view
  
  */

  const helper = new CalendarMonthsHelper();
  const getViewDates = (dates: Date[]) => helper.getViewDates(dates[0]);
  const getCurrentMonthViewDates = () => helper.getViewDates(new Date());
  const getNextMonthDates = (dates: Date[]) => {
    const currentMonth = helper.getViewMonth(dates[0]);
    const nextMonth = helper.getNextMonth(currentMonth);
    return helper.getViewDates(nextMonth);
  };
  const getPreviousMonthDates = (dates: Date[]) => {
    const currentMonth = helper.getViewMonth(dates[0]);
    const prevMonth = helper.getPreviousMonth(currentMonth);
    return helper.getViewDates(prevMonth);
  };
  const getCurrentMonth = (dates: Date[]) => helper.getViewMonth(dates[0]);
  return {
    getViewDates,
    getCurrentMonthViewDates,
    getNextMonthDates,
    getPreviousMonthDates,
    getCurrentMonth,
  };
};

export default useCalendarMonths;
