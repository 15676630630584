import { useKeywordListening } from "hooks/Twitter";
import { setLiked, setRetweeted } from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { Menu, Item } from "./SortBy.styles";

const SortBy = () => {
  const { handleFilter } = useKeywordListening();
  const dispatch = useAppDispatch();
  const liked = useAppSelector((state) => state.twitter.tweetSearch.liked);
  const retweet = useAppSelector((state) => state.twitter.tweetSearch.retweet);

  return (
    <Menu text>
      <Item
        name="mostLiked"
        active={liked}
        onClick={() => {
          handleFilter("likes");
          dispatch(setLiked());
        }}
      />
      <Item
        name="mostRetweeted"
        active={retweet}
        onClick={() => {
          handleFilter("retweets");
          dispatch(setRetweeted());
        }}
      />
    </Menu>
  );
};

export default SortBy;
