import styled from "styled-components";
import { Button, IconButton, LinkButton } from "components/core";

const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

interface IProfileSectionProps
  extends React.PropsWithChildren<{
    editable?: boolean;
    onCancel?: () => void;
    formId?: string;
    onEdit?: () => void;
  }> {}

const ProfileActions = ({
  children,
  editable,
  onCancel,
  formId,
  onEdit,
}: IProfileSectionProps) => {
  const renderActions = () => {
    if (editable)
      return (
        <>
          <Button
            type="submit"
            variant="alpha"
            size="small"
            content="Save"
            me="extraSmall"
            form={formId}
          />
          <LinkButton as="button" content="Cancel" onClick={onCancel} />
        </>
      );
    return (
      <IconButton size="small" variant="alpha" name="pencil" onClick={onEdit} />
    );
  };

  if (children) {
    return <Container>{children}</Container>;
  }

  return <Container>{renderActions()}</Container>;
};

export default ProfileActions;
