import {
  addDays,
  isSunday,
  startOfMonth,
  setMonth,
  getMonth,
  previousSunday,
  getDate,
} from "date-fns";
import { range } from "lodash";

class CalendarMonthsHelper {
  getStartOfMonth = (date: Date) => {
    return startOfMonth(date);
  };

  getNextMonth = (date: Date) => setMonth(date, getMonth(date) + 1);

  getPreviousMonth = (date: Date) => setMonth(date, getMonth(date) - 1);

  getViewDates = (date: Date) => {
    /* 
          Returns only 35 dates starting from sunday for a given date for the view. Only pass date of current month to get 35 dates of month view. 
    */
    const firstDayOfmonth = startOfMonth(date);
    const months = isSunday(firstDayOfmonth)
      ? range(0, 35).map((day) => addDays(firstDayOfmonth, day))
      : range(0, 35).map((day) =>
          addDays(previousSunday(firstDayOfmonth), day)
        );
    return months;
  };

  getViewMonth = (date: Date) => {
    /* 
      Pass any date of a month view. 
      Returns the first date with of the actual month. 
    */
    const currentMonthDate =
      getDate(date) === 1 ? date : setMonth(date, getMonth(date) + 1);

    return startOfMonth(currentMonthDate);
  };
}

export default CalendarMonthsHelper;
