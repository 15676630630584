import digitalMarketingServiceIcon from "assets/icons/Profile/digital-marketing-services-icon.svg";
import b2CIcon from "assets/icons/Profile/b2c-product-icon.svg";
import b2BIcon from "assets/icons/Profile/b2b-product-icon.svg";

const data = [
  {
    id: 1,
    icon: digitalMarketingServiceIcon,
    text: "Digital marketing services",
    value: "digital marketing services",
  },
  {
    id: 2,
    icon: b2BIcon,
    text: "B2B Product",
    value: "b2b product",
  },
  {
    id: 3,
    icon: b2CIcon,
    text: "B2C Product",
    value: "b2c product",
  },
];

export default data;
