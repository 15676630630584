import { Flex } from "components/core";
import ContentCreationLayout from "./ContentCreationLayout";
import LayoutHeader from "./LayoutHeader";

const LiveTrends = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <LayoutHeader header="Live Tending Topics 📈" />
      <ContentCreationLayout.Body>{props.children}</ContentCreationLayout.Body>
    </Flex>
  );
};

export default LiveTrends;
