import ReactTooltip from "react-tooltip";
import { useDrag } from "react-dnd";
import { useEffect, useState } from "react";
import { Linkify } from "components/common";
import { useLeads } from "hooks/Twitter";
import { useNumberHelper, useToggle } from "hooks/common";
import { TweetCard, DMCard } from "../common";
import { TPotentialCustomer } from "adapters/Twitter/types";
import { getPastTime } from "helpers";
import { setShowPotentialFilter } from "redux/slices/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import { CalendarIcon, DMIcon, LocationIcon } from "../common/icons";
import { ItemTypes } from "./ItemTypes";
import {
  Stat,
  WebsiteIcon,
  TwitterIcon,
  StyledIcon,
} from "./UserInfoCard.styles";
import { Container } from "./LeadsCard.styles";

interface DropResult {
  name: string;
}
interface LeadsCardProps {
  tweet: TPotentialCustomer;
}

const LeadsCard = ({ tweet }: LeadsCardProps) => {
  const [{ opacity }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: { id: tweet.leadUsername, tweet },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult?.name === ItemTypes.FOLLOW) {
        handleFollow(tweet);
      }
      if (item && dropResult?.name === ItemTypes.INFLUENCER) {
        handleInfluencer(tweet);
      }
      if (item && dropResult?.name === ItemTypes.REMOVE) {
        handleRemove(tweet);
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const {
    handleFollow,
    handleDM,
    handleRemove,
    handleInfluencer,
    handleLeadTweets,
  } = useLeads();
  const dispatch = useAppDispatch();
  const { abbreviate } = useNumberHelper();

  const [isTweetReplyCardOpen, toggleTweetReplyCard] = useToggle();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");

  const openLink = (url: string) => window.open(url, "_blank");

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container ref={drag} style={{ opacity }}>
      <TweetCard>
        <TweetCard.Content style={{ position: "relative" }}>
          <TweetCard.Head>
            <TweetCard.Image
              src={tweet.profilePicUrl}
              alt={tweet.name}
              data-tip="Go to Twitter"
              onClick={() => window.open(tweet.profileUrl, "_blank")}
            />
            <TweetCard.Username
              name={tweet.name}
              username={tweet.leadUsername}
              following={tweet.followedBack}
              keywords={tweet.keyword}
              dataTip="Last 20 Tweets"
              onClick={() => {
                handleLeadTweets({ leadUsername: tweet.leadUsername });
                dispatch(setShowPotentialFilter(false));
              }}
            />
            {tweet.website && (
              <StyledIcon
                data-tip="Open Website"
                icon={WebsiteIcon}
                onClick={() => openLink(tweet.website)}
              />
            )}
            <StyledIcon
              data-tip="DM"
              icon={DMIcon}
              style={{ marginRight: "unset" }}
              onClick={() => toggleTweetReplyCard(true)}
            />
          </TweetCard.Head>
          <TweetCard.Bio>
            <Linkify>{tweet.bio}</Linkify>
          </TweetCard.Bio>
          <TweetCard.Stats>
            <Stat>
              {abbreviate(tweet.followings)} <span>Followings</span>
            </Stat>
            <Stat>
              {abbreviate(tweet.followers)} <span>Followers</span>
            </Stat>
            <Stat>
              <TwitterIcon /> {abbreviate(tweet.tweets)}
            </Stat>
          </TweetCard.Stats>
          <TweetCard.Stats>
            <TweetCard.Stat
              icon={CalendarIcon}
              value={getPastTime(tweet.joinedSince)}
            />
            {tweet.location && (
              <TweetCard.Stat icon={LocationIcon} value={tweet.location} />
            )}
          </TweetCard.Stats>
          <DMCard
            open={isTweetReplyCardOpen}
            onCancel={() => toggleTweetReplyCard(false)}
            files={files}
            onFilesChange={(files) => setFiles(files)}
            text={text}
            onTextChange={(value) => setText(value)}
            onSave={() => {
              handleDM({ ...tweet, files, text });
              setText("");
              setFiles([]);
              toggleTweetReplyCard(false);
            }}
          />
        </TweetCard.Content>
      </TweetCard>
    </Container>
  );
};

export default LeadsCard;
