import { allPlans } from "api/Pricing";
import { useReactQuery } from "hooks";
import { createContext, useEffect } from "react";
import { setAllPlans } from "redux/slices/Profile";
import { useAppDispatch } from "redux/store/hooks";

export const CouponSystemContext = createContext(null);

const CouponSystemProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();

  const { execute } = useReactQuery(allPlans, {
    onSuccess: (data) => {
      if (data) dispatch(setAllPlans(data.allPlans));
    },
  });

  useEffect(() => {
    execute();
  }, [execute]);

  return (
    <CouponSystemContext.Provider value={null}>
      {children}
    </CouponSystemContext.Provider>
  );
};

export default CouponSystemProvider;
