import { createContext, useEffect } from "react";
import { useReactQuery } from "hooks/common";
import { pricing as api } from "api/Pricing";
import { Spinner } from "components/common";
import { IPricingCards } from "adapters/Pricing/types";
import { useAppDispatch } from "redux/store/hooks";
import { setPlans } from "redux/slices/Pricing/plans";
import { useDolphynAuth } from "hooks";

type TPricingContext = {
  data: IPricingCards;
};

const initialState: IPricingCards = {
  monthView: [],
  yearView: [],
};

interface IPircingProviderProps {
  isPublic?: boolean;
}

export const PricingContext = createContext<TPricingContext | null>(null);

const PricingProvider = (
  props: React.PropsWithChildren<IPircingProviderProps>
) => {
  const dispatch = useAppDispatch();
  const auth = useDolphynAuth();
  const { isPublic = false } = props;

  const {
    execute,
    isLoading,
    data = initialState,
  } = useReactQuery(api.get, {
    onSuccess: (data) => dispatch(setPlans(data.monthView)),
  });

  useEffect(() => {
    execute({ isPublic });
  }, [auth.isSignedIn, execute, isPublic]);

  return (
    <PricingContext.Provider value={{ data }}>
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </PricingContext.Provider>
  );
};

export default PricingProvider;
