import { createContext } from "react";
import { TCompleteRegistrationFormValues } from "components/Profile/CompleteRegistration/types";
import { useSteps } from "hooks/common";
import { useAppSelector } from "redux/store/hooks";

// const TOTAL_STEPS = 6;

// const stepsMap = {
//   OCCUPATION: 1,
//   PRODUCT: 2,
//   COUNTRY: 3,
//   TIMEZONE_LANGUAGE: 4,
//   KEYWORDS: 5,
//   REFERRAL: 6,
// } as const;

const stepsMap = {} as { [key: string]: number };

interface IRegistrationStepsContext {
  totalSteps: number;
  currentStep: number;
  handleGoToNextStep: (values?: TCompleteRegistrationFormValues) => void;
  handleGoToPrevStep: (values?: TCompleteRegistrationFormValues) => void;
  getIsBackDisabled: (values: TCompleteRegistrationFormValues) => boolean;
  getIsNextDisabled: (values: TCompleteRegistrationFormValues) => boolean;
  handleGotoStep: (step: number) => void;
  stepsMap: typeof stepsMap;
}

export const RegistrationStepsContext =
  createContext<null | IRegistrationStepsContext>(null);

const RegistrationStepsProvider = (props: React.PropsWithChildren<{}>) => {
  const country = useAppSelector(
    (state) => state.profile.registrationStatus.country
  );
  const occupation = useAppSelector(
    (state) => state.profile.registrationStatus.occupation
  );
  const keywords = useAppSelector(
    (state) => state.profile.registrationStatus.keywords
  );
  const product = useAppSelector(
    (state) => state.profile.registrationStatus.product
  );
  const referral = useAppSelector(
    (state) => state.profile.registrationStatus.referral
  );
  const timezone_language = useAppSelector(
    (state) => state.profile.registrationStatus.timezone_language
  );

  const requiredSteps = {
    occupation,
    product,
    country,
    timezone_language,
    keywords,
    referral,
  } as { [key: string]: boolean };

  var initialCount = 1;
  for (const key of Object.keys(requiredSteps)) {
    if (!requiredSteps[key]) {
      stepsMap[key.toUpperCase()] = initialCount;
      initialCount += 1;
    }
  }

  const TOTAL_STEPS = Object.keys(stepsMap).length;

  const steps = useSteps(TOTAL_STEPS);

  const getShouldProductFormSkipped = (
    values?: TCompleteRegistrationFormValues
  ) => {
    return values?.occupation.toLowerCase() === "student";
  };

  const handleGoToNextStep = (values?: TCompleteRegistrationFormValues) => {
    if (
      getShouldProductFormSkipped(values) &&
      steps.currentStep === stepsMap.OCCUPATION
    ) {
      return steps.handleGotoStep(stepsMap.COUNTRY);
    }
    steps.handleGoToNextStep();
  };

  const handleGoToPrevStep = (values?: TCompleteRegistrationFormValues) => {
    if (
      getShouldProductFormSkipped(values) &&
      steps.currentStep === stepsMap.COUNTRY
    ) {
      return steps.handleGotoStep(stepsMap.OCCUPATION);
    }
    steps.handleGoToPrevStep();
  };

  const getIsBackDisabled = () => {
    return steps.currentStep === stepsMap.OCCUPATION;
  };

  const getIsNextDisabled = (values: TCompleteRegistrationFormValues) => {
    switch (steps.currentStep) {
      case stepsMap.OCCUPATION:
        return !values.occupation;
      case stepsMap.PRODUCT:
        return !values.product;
      case stepsMap.COUNTRY:
        return !values.country;
      case stepsMap.TIMEZONE_LANGUAGE:
        return !values.language || !values.timezone;
      case stepsMap.KEYWORDS:
        return !values.primaryKeyword || !values.secondaryKeywords;
      case stepsMap.REFERRAL:
        return !values.referral;
      default:
        return false;
    }
  };

  return (
    <RegistrationStepsContext.Provider
      value={{
        handleGoToNextStep,
        handleGoToPrevStep,
        getIsBackDisabled,
        getIsNextDisabled,
        totalSteps: TOTAL_STEPS,
        currentStep: steps.currentStep,
        handleGotoStep: steps.handleGotoStep,
        stepsMap,
      }}
    >
      {props.children}
    </RegistrationStepsContext.Provider>
  );
};

export default RegistrationStepsProvider;
