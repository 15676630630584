import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  ${helper.getBorderRadius("medium")}
  ${helper.getBorderRadius("medium")}
  padding: ${helper.getSpacing("medium")};
  width: min(600px, 40vw);

  @media screen and (max-width: 1272px) {
    padding: ${helper.getSpacing("small")};
    width: min(600px, 45vw);
  }

  @media screen and (max-width: 1054px) {
    padding: ${helper.getSpacing("small")};
    width: min(600px, 50vw);
  }

  @media screen and (max-width: 938px) {
    padding: ${helper.getSpacing("small")};
    width: min(500px, 55vw);
  }

  @media screen and (max-width: 768px) {
    padding: ${helper.getSpacing("small")};
    width: min(575px, 75vw);
  }

  @media screen and (max-width: 625px) {
    padding: ${helper.getSpacing("small")};
    width: min(575px, 80vw);
  }

  @media screen and (max-width: 525px) {
    padding: ${helper.getSpacing("small")};
    width: min(575px, 90vw);
  }
`;

export default Container;
