import { useField } from "formik";
import { Dropdown, FormError } from "components/core";
import Filter from "./Filter";
import { IDropdownProps } from "components/core/types";

interface IMultiSelectDropdownFieldProps extends IDropdownProps {
  name: string;
  placeholder?: string;
  filter?: boolean;
}

const MultiSelectDropdownField = ({
  name,
  placeholder,
  filter,
  ...props
}: IMultiSelectDropdownFieldProps) => {
  const [field, meta, helpers] = useField<string[]>(name);

  const getOptions = () => {
    if (!field.value) return [];
    return field.value
      .map((val) => ({ key: val, text: val, value: val }))
      .filter((item) => item.value !== "");
  };

  const getFieldVal = () => {
    if (!field.value) return [];
    return field.value.filter((val) => val !== "");
  };

  const handleAddition = (value: any) => {
    if (typeof value === "string") {
      helpers.setValue([...getFieldVal(), value]);
    }
  };

  const handleChange = (value: any) => {
    if (Array.isArray(value) && typeof value[0] === "string") {
      return helpers.setValue(value);
    }
    if (Array.isArray(value) && !value.length) {
      return helpers.setValue([]);
    }
  };

  return (
    <>
      <Dropdown
        icon={filter ? null : ""}
        noResultsMessage=""
        placeholder={placeholder}
        selection
        search
        fluid
        multiple
        allowAdditions
        options={getOptions()}
        value={getFieldVal()}
        onAddItem={(e, { value }) => handleAddition(value)}
        onChange={(e, { value = [] }) => handleChange(value)}
        {...props}
      >
        {filter && <Filter />}
      </Dropdown>
      {meta.error && <FormError>{meta.error}</FormError>}
    </>
  );
};

export default MultiSelectDropdownField;
