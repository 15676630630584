import styled from "styled-components";
import { styles } from "../Common";

const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  padding: ${styles.helper.getSpacing("large")} 0;
  ${styles.marginBottom}
`;

const Grid = styled.div`
  ${styles.helper.containerize(80)}
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: 3rem;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${styles.helper.getSpacing("medium")};
`;

const IconContainer = styled.div`
  svg {
    width: 100%;
  }
`;

const Header = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  ${styles.helper.getLineHeight("large")}
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getFontSize("medium")}
  text-align: center;
  margin: unset;
`;

const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  color: ${({ theme }) => theme.colors.lightGrey};
  text-align: center;
  ${styles.helper.getLineHeight("medium")}
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getFontSize("small")}
  max-width: 24ch;
`;

export { Container, Grid, Card, IconContainer, Header, Description };
