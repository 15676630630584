import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import { DateTimePicker } from "components/common";
import { Flex, Text, Toggle } from "components/core";
import { useToggle } from "hooks";
import { QuestionIcon } from "./common.styles";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setAutoRetweet, setRetweetDateTime } from "redux/slices/Twitter";

const AutoRetweet = () => {
  const TODAY = new Date();
  const [open, toggle] = useToggle();

  const dispatch = useAppDispatch();
  const datetime = useAppSelector(
    (state) => state.twitter.thread.retweetDateTime
  );

  const toggleAutoRetweet = () => {
    dispatch(setAutoRetweet());
    toggle();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Flex align="center" gap="small">
        <QuestionIcon data-tip="Auto Retweet - Set Auto Retweet Time!" />
        <Text variant="darkGrey" fontWeight="medium" content="Auto Retweet" />
        <Toggle checked={open} onChange={() => toggleAutoRetweet()} />
      </Flex>
      {open && (
        <Flex>
          <DateTimePicker.DatePicker
            value={datetime}
            onChange={(value) => dispatch(setRetweetDateTime(value))}
            minDate={TODAY}
          />
          <DateTimePicker.TimePicker
            value={datetime}
            onChange={(value) => dispatch(setRetweetDateTime(value))}
            minTime={TODAY}
          />
        </Flex>
      )}
    </>
  );
};

export default AutoRetweet;
