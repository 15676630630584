import { IconButton } from "components/core";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import {
  Body,
  Content,
  Expander,
  Header,
  Container,
} from "./PostScheduler.styles";
import SchedulerMenu from "./SchedulerMenu";
import PostComposer from "./PostComposer";
import ScheduledList from "./ScheduledList";
import SentList from "./SentList";
import { setSubNavClose, setSubNavOpen } from "redux/slices/Twitter";

const PostScheduler = () => {
  const dispatch = useAppDispatch();
  const isSubNavOpen = useAppSelector(
    (state) => state.twitter.scheduler.isSubNavOpen
  );

  const postComposer = useAppSelector(
    (state) => state.twitter.current.postComposer
  );
  const scheduledList = useAppSelector(
    (state) => state.twitter.current.scheduledList
  );
  const sentList = useAppSelector((state) => state.twitter.current.sentList);

  const subNavClose = () => {
    dispatch(setSubNavClose());
  };

  // const drawerRef = useOnClickOutside<HTMLDivElement>(subNavClose);

  const renderContent = () => {
    if (isSubNavOpen) {
      return (
        <Content>
          <Header>
            <IconButton
              id="navigatorIcon"
              name="arrow right"
              variant="alpha"
              invert
              onClick={() => subNavClose()}
            />
            <SchedulerMenu />
          </Header>
          <Body>
            {postComposer && <PostComposer />}
            {scheduledList && <ScheduledList />}
            {sentList && <SentList />}
          </Body>
        </Content>
      );
    }

    return (
      <Expander>
        <IconButton
          id="navigatorIcon"
          name="compose"
          variant="alpha"
          size="medium"
          style={{
            margin: "unset",
            height: "2.7rem",
            width: "2.7rem",
            borderRadius: "50%",
            padding: "0.45rem",
          }}
          invert
          onClick={() => dispatch(setSubNavOpen())}
        />
      </Expander>
    );
  };

  return <Container>{renderContent()}</Container>;
};

export default PostScheduler;
