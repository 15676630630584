import { createContext, useEffect } from "react";
import { IDemandByCountryData } from "adapters/ProductDemand/types";
import { useReactQuery } from "hooks/common";
import { fetchDemandByCountry } from "api/ProductDemand";
import { usePreferences, useRecommendation } from "hooks/ProductDemand";
import { ISingleKeywordSearchFormikValues } from "./types";
import { Loader } from "components/common";
import { demandByCountry } from "config/ProductDemand";

type TDemandByCountryContext = {
  data: IDemandByCountryData;
  handleSearch: (payload: ISingleKeywordSearchFormikValues) => void;
};

export const DemandByCountryContext =
  createContext<TDemandByCountryContext | null>(null);

const initialData: IDemandByCountryData = {
  worldMap: [],
  recommendation: [],
};

const DemandByCountryProvider = (props: React.PropsWithChildren<{}>) => {
  const preferences = usePreferences();
  const recommendation = useRecommendation();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchDemandByCountry, {
    onSuccess: (data) => recommendation.setRecommendation(data.recommendation),
  });

  const handleSearch = (payload: ISingleKeywordSearchFormikValues) => {
    execute(payload);
  };

  useEffect(() => {
    if (
      preferences.data.endDate &&
      preferences.data.startDate &&
      preferences.data.keyword
    ) {
      execute({
        keyword: preferences.data.keyword,
        startDate: preferences.data.startDate,
        endDate: preferences.data.endDate,
      });
    }
  }, [
    execute,
    preferences.data.keyword,
    preferences.data.endDate,
    preferences.data.startDate,
  ]);

  return (
    <DemandByCountryContext.Provider value={{ data, handleSearch }}>
      <Loader textData={demandByCountry} hidden={!isLoading} />
      {props.children}
    </DemandByCountryContext.Provider>
  );
};

export default DemandByCountryProvider;
