import { darken, getLuminance, lighten, transparentize } from "polished";
import { TColorBrightness } from "./types";

export type TOptions = {
  multiplier?: number;
};

class Color {
  private colorBrightnessFactors = {
    low: 20,
    medium: 35,
    high: 45,
  };

  getComplementaryColor = (color: string) =>
    getLuminance(color) > 0.5 ? darken(0.5, color) : lighten(0.5, color);

  lighten = (
    color: string,
    scale: keyof TColorBrightness = "low",
    options?: TOptions
  ) => {
    if (options) {
      const { multiplier = 1 } = options;
      return lighten(
        (this.colorBrightnessFactors[scale] / 100) * multiplier,
        color
      );
    }
    return lighten(this.colorBrightnessFactors[scale] / 100, color);
  };

  darken = (
    color: string,
    scale: keyof TColorBrightness = "low",
    options?: TOptions
  ) => {
    if (options) {
      const { multiplier = 1 } = options;
      return darken(
        (this.colorBrightnessFactors[scale] / 100) * multiplier,
        color
      );
    }
    return darken(this.colorBrightnessFactors[scale] / 100, color);
  };

  transparentize = (
    color: string,
    scale: keyof TColorBrightness = "low",
    options?: TOptions
  ) => {
    if (options) {
      const { multiplier = 1 } = options;
      return transparentize(
        (this.colorBrightnessFactors[scale] / 100) * multiplier,
        color
      );
    }
    return transparentize(this.colorBrightnessFactors[scale] / 100, color);
  };
}

export default Color;
