import styled from "styled-components";
import { Header } from "components/core";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: ${helper.getSpacing("small")};

  @media screen and (max-width: 1272px) {
    width: min(400px, 40vw);
  }

  @media screen and (max-width: 768px) {
    width: min(600px, 60vw);
    order: -1;
  }
`;

const Illustration = styled.div`
  height: min(45vh, 1000px);
  position: relative;
  img {
    max-width: 250px;
    height: 100%;
    position: absolute;
    bottom: -15%;
    left: 55%;
  }

  @media screen and (max-width: 1272px) {
    img {
      max-width: 225px;
      left: 25%;
    }
  }

  @media screen and (max-width: 1054px) {
    img {
      max-width: 225px;
      left: 35%;
    }
  }

  @media screen and (max-width: 768px) {
    height: min(15vh, 25px);
    img {
      display: none;
    }
  }

  @media screen and (max-width: 525px) {
    height: min(15vh, 0px);
  }
`;

interface IAuthFormIllustrationProps {
  header: string;
  subHeader: string;
  illustration: string;
}

const AuthFormIllustration = ({
  header,
  subHeader,
  illustration,
}: IAuthFormIllustrationProps) => {
  return (
    <Container>
      <Header
        as="div"
        variant="black"
        textAlign="center"
        content={header}
        mb="medium"
        font="playfair"
        size="huge"
      />
      <Header
        as="div"
        variant="lightGrey"
        textAlign="center"
        content={subHeader}
        mb="medium"
        font="poppins"
        size="medium"
      />
      <Illustration>
        <img src={illustration} alt="authentication-illustration" />
      </Illustration>
    </Container>
  );
};

export default AuthFormIllustration;
