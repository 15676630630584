import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const marginBottom = css`
  margin-bottom: ${helper.getSpacing("huge")};

  @media screen and (max-width: 768px) {
    margin-bottom: ${helper.getSpacing("large")};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    gap: 1rem;
  }
`;

const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.alpha};
`;

export { marginBottom, helper, ButtonContainer, Strong };
