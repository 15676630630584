import { useMutation } from "react-query";
import { files as api } from "api/Twitter";
import { TFileObject } from "api/Twitter/types";
import { useNotification } from "hooks/common";

type TFileUploadOptions = {
  onSuccess: (data: TFileObject[]) => void;
  onError?: (errorMessage: string) => void;
};

const useFileUpload = () => {
  const notification = useNotification();

  const { mutate, isLoading } = useMutation(api.post, {
    onError: ({ displayMessage }) => {
      notification.notifyDanger(displayMessage);
    },
  });

  const upload = (files: File[], options: TFileUploadOptions) => {
    mutate(
      { files },
      {
        onSuccess: (data) => data && options.onSuccess(data),
        onError: ({ displayMessage }) =>
          options.onError && options.onError(displayMessage),
      }
    );
  };

  return { upload, uploading: isLoading };
};

export default useFileUpload;
