import { applicationRoutes } from "config/common";
import { useHistory, useLocation } from "react-router";

type TPathCallback = (routes: typeof applicationRoutes) => string;

type TIsMatchOptions = {
  partial?: boolean;
};

const useNavigation = () => {
  const routes = applicationRoutes;
  const history = useHistory();
  const location = useLocation();

  const navigate = (pathCallback: TPathCallback, state?: unknown) => {
    const pushPath = pathCallback(routes);
    history.push(pushPath, state);
  };

  const replace = (pathCallback: TPathCallback, state?: unknown) => {
    const pushPath = pathCallback(routes);
    history.replace(pushPath, state);
  };

  const getSearchParams = () => {
    const params = new URLSearchParams(history.location.search);
    return params;
  };

  const isMatch = (pathCallback: TPathCallback, options?: TIsMatchOptions) => {
    const { partial = false } = options || {};
    if (partial) {
      return location.pathname.includes(pathCallback(routes));
    }
    return location.pathname === pathCallback(routes);
  };
  return { navigate, replace, getSearchParams, location, isMatch };
};

export default useNavigation;
