import React from "react";
import { Button, Icon } from "semantic-ui-react";
import "./CustomButton.scss";

const CustomButton = ({
  iconPosition,
  icon,
  variant,
  text,
  children,
  ...rest
}) => {
  if (iconPosition === "left") {
    return (
      <Button
        icon
        labelPosition="left"
        className={`${variant} customButton`}
        {...rest}
      >
        <Icon name={icon} />
        {children || text}
      </Button>
    );
  }
  if (iconPosition === "right") {
    return (
      <Button
        icon
        labelPosition="right"
        className={`${variant} customButton`}
        {...rest}
      >
        {children || text}
        <Icon name={icon} />
      </Button>
    );
  }

  return (
    <Button className={`${variant} customButton`} {...rest}>
      {children || text}
    </Button>
  );
};

export default CustomButton;
