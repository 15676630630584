import { TBestPractices } from "adapters/Twitter/types";
import { Header } from "components/core";
import CircularProgress from "./CircularProgress";
import { BestPracticesGrid } from "./BestPractices.styles";

interface IBestPracticesProps {
  bestPractices: TBestPractices[];
}

const BestPractices = ({ bestPractices }: IBestPracticesProps) => {
  return (
    <div>
      <Header
        size="small"
        font="poppins"
        variant="black"
        content="Check if you're following the best practices"
        mb="small"
      />
      <BestPracticesGrid>
        {bestPractices.map((practice) => (
          <CircularProgress
            text={practice.bestPracticeData}
            value={practice.value}
          />
        ))}
      </BestPracticesGrid>
    </div>
  );
};

export default BestPractices;
