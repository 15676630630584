import RegistrationStatusProvider from "./RegistrationStatusProvider";
import GeneralPreferencesProvider from "./GeneralPreferencesProvider";
import KeywordsDetailsProvider from "./KeywordsDetailsProvider";
import NotificationPreferencesProvider from "./NotificationPreferencesProvider";
import PersonalDetailsProvider from "./PersonalDetailsProvider";
import SecurityDetailsProvider from "./SecurityDetailsProvider";
import PlanDetailsProvider from "./PlanDetailsProvider";
import EnterpriseProvider from "./EnterpriseProvider";
import { AdminProvider } from "context/Admin";
import CouponSystemProvider from "./CouponSystemProvider";

const ProfileProvider = (props: React.PropsWithChildren<{}>) => (
  <RegistrationStatusProvider>
    <PlanDetailsProvider>
      <PersonalDetailsProvider>
        <SecurityDetailsProvider>
          <NotificationPreferencesProvider>
            <GeneralPreferencesProvider>
              <KeywordsDetailsProvider>
                <CouponSystemProvider>
                  <EnterpriseProvider>
                    <AdminProvider>{props.children}</AdminProvider>
                  </EnterpriseProvider>
                </CouponSystemProvider>
              </KeywordsDetailsProvider>
            </GeneralPreferencesProvider>
          </NotificationPreferencesProvider>
        </SecurityDetailsProvider>
      </PersonalDetailsProvider>
    </PlanDetailsProvider>
  </RegistrationStatusProvider>
);

export default ProfileProvider;
