import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Pricing";
import { CustomerPortalAdapter } from "adapters/Pricing";
import { APIError } from "errors";
import { TCustomerPortalPayload } from "adapters/Pricing/types";

const URL = links.BASE_PRODUCT_URL.append("/customer_portal");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new CustomerPortalAdapter();

const subscribe = {
  post: async (values: TCustomerPortalPayload) => {
    try {
      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI(URL, payload);
      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default subscribe;
