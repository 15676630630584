export const getKeywordsLength = (data: string[]) => {
  let arrLength = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i] !== "") arrLength += 1;
  }
  return arrLength;
};

export const parseKeywords = (data: string[]) => {
  const arrLength = 10 - data.length;
  for (let i = 0; i < arrLength; i++) {
    data.push("");
  }
  return data;
};
