import { Flex, Icon } from "components/core";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { setSubNavOpen, setTweetText } from "redux/slices/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import styled from "styled-components";

const Editor = styled(Icon).attrs({
  name: "edit",
})`
  color: ${({ theme }) => theme.colors.alpha};
  width: 14px;
  height: 14px;
  margin-right: 4px;
  cursor: pointer;
`;

type Props = {
  text: string;
};

const ActionIcons: React.FC<Props> = ({ text }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Flex justify="flex-end" gap="medium">
      <Editor
        data-tip="Edit"
        onClick={() => {
          dispatch(setTweetText(text));
          dispatch(setSubNavOpen());
        }}
      />
    </Flex>
  );
};

export default ActionIcons;
