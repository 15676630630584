import { ModuleMenu } from "components/common";
import { postPreviewMenuData } from "config/SocialMediaPost";
import { Container } from "./Menu.styles";
import { TPostPreviewMenu } from "./types";

interface IMenuProps {
  activeItem: TPostPreviewMenu;
  onChange: (value: TPostPreviewMenu) => void;
}

const Menu = ({ activeItem, onChange }: IMenuProps) => {
  return (
    <Container>
      <ModuleMenu>
        {postPreviewMenuData.map((menu) => (
          <ModuleMenu.Item
            key={menu.id}
            active={activeItem === menu.id}
            content={menu.title}
            onClick={() => onChange(menu.id)}
          />
        ))}
      </ModuleMenu>
    </Container>
  );
};

export default Menu;
