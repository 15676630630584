import { Flex } from "components/core";
import { ITopIconProps } from "./types";

const TopIcons = ({ data = [] }: ITopIconProps) => {
  return (
    <Flex align="center" justify="center" gap="small" mt="medium" mb="medium">
      {data.map(({ src, alt, id }) => (
        <img style={{ height: "40px" }} key={id} src={src} alt={alt} />
      ))}
    </Flex>
  );
};

export default TopIcons;
