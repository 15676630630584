import { Linkify } from "components/common";
import { Text } from "components/core";
import { Fragment } from "react";
import { removeFromThread } from "redux/slices/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import { TwitterService } from "services/Auth";
import {
  CardContainer,
  ImageContainer,
  StyledImage,
  Line,
  Content,
  UserProfile,
  TweetText,
  ActionSection,
  RemoveIcon,
  FileContainer,
  FilesPreview,
  Image,
} from "./PreviewCard.styles";

type TPreviewCardProps = {
  text: string;
  threadId?: string;
  files?: any[];
  previewUrls?: any;
  showLine?: boolean | string;
};

const twitterService = new TwitterService();

const PreviewCard = ({
  text,
  threadId,
  files,
  previewUrls,
  showLine,
}: TPreviewCardProps) => {
  const selectedAccount = twitterService.getSelectedAccount();
  const dispatch = useAppDispatch();

  const onThreadRemove = () => {
    dispatch(removeFromThread(threadId));
  };

  const renderPreview = (file: any) => {
    switch (file.file_type) {
      case "image":
        return (
          <FileContainer>
            <StyledImage
              src={file.file_url}
              alt={file.user}
              style={{ borderRadius: "8px" }}
            />
          </FileContainer>
        );
      case "video":
        return (
          <FileContainer>
            <video controls>
              <source src={file.file_url} type="video/mp4" />
            </video>
          </FileContainer>
        );
      default:
        return null;
    }
  };

  const renderCurrentPreview = (file: any) => {
    switch (file.type) {
      case "image":
        return (
          <FileContainer>
            <StyledImage
              src={file.url}
              alt="preview"
              style={{ borderRadius: "8px" }}
            />
          </FileContainer>
        );
      case "video":
        return (
          <FileContainer>
            <video controls>
              <source src={file.url} type="video/mp4" />
            </video>
          </FileContainer>
        );
      default:
        return null;
    }
  };

  const renderFiles = () => {
    if (files) {
      return (
        <FilesPreview>
          {files.map((file) => (
            <Fragment key={file.file_url}>{renderPreview(file)}</Fragment>
          ))}
        </FilesPreview>
      );
    }
  };

  const renderCurrentFiles = () => {
    if (previewUrls) {
      return (
        <FilesPreview>
          {previewUrls.map((file: any) => (
            <Fragment key={file.file_url}>
              {renderCurrentPreview(file)}
            </Fragment>
          ))}
        </FilesPreview>
      );
    }
  };

  return (
    <CardContainer>
      <ImageContainer>
        <Image
          src={selectedAccount.profileImageUrl}
          alt={selectedAccount.userName}
        />
        {showLine && <Line />}
      </ImageContainer>
      <Content>
        <UserProfile>
          <Text
            className="underlined"
            fontWeight="medium"
            variant="darkGrey"
            size="extraSmall"
            content={selectedAccount.name}
          />

          <Text
            className="underlined"
            variant="darkGrey"
            size="extraSmall"
            content={`@${selectedAccount.userName}`}
          />
        </UserProfile>
        <TweetText>
          <Linkify>{text}</Linkify>
        </TweetText>
        {files && renderFiles()}
        {previewUrls && renderCurrentFiles()}
        <ActionSection>
          {threadId && <RemoveIcon onClick={() => onThreadRemove()} />}
        </ActionSection>
      </Content>
    </CardContainer>
  );
};

export default PreviewCard;
