import { AutoDmAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Twitter";
import { APIError } from "errors";
import { TAutoDmPostPayload } from "./types";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/auto_dm");
const adapter = new AutoDmAdapter();

export type TAutoDMPayload = {
  isAuthenticated?: boolean;
};

const AutoDm = {
  fetchAutoDm: async ({ isAuthenticated }: TAutoDMPayload) => {
    try {
      if (isAuthenticated) {
        const headers = adapter.getConfig();
        const { data } = await dolphynAPI(URL, headers);

        return adapter.parseGetResponse(data);
      }

      const { data } = await dolphynAPI(URL);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  postAutoDm: async (values: TAutoDmPostPayload) => {
    try {
      if (values.isAuthenticated) {
        const headers = adapter.getConfig();
        const payload = adapter.getPostPayload(values);
        const { data } = await dolphynAPI.post(URL, payload, headers);

        return adapter.parseGetResponse(data);
      }

      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  deleteAutoDm: async ({ isAuthenticated }: TAutoDMPayload) => {
    try {
      if (isAuthenticated) {
        const headers = adapter.getConfig();
        const { data } = await dolphynAPI.delete(URL, headers);

        return adapter.parseDeleteResponse(data);
      }

      const { data } = await dolphynAPI.delete(URL);

      return adapter.parseDeleteResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default AutoDm;
