import { Menu as BaseMenu } from "semantic-ui-react";
import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const PrimaryMenuItem = styled(BaseMenu.Item).attrs(() => ({
  className: "item",
}))`
  &&&&& {
    margin: ${helper.getSpacing("extraSmall")} 0;
    border-radius: 5px;
    &::after {
      display: none;
    }
    svg {
      width: 16px;
      height: 16px;
    }

    &:first-child {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &.active,
    &:hover {
      border-radius: 5px;
      svg {
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
      background-color: ${({ theme }) => theme.colors.alpha};
    }
  }
`;

export default PrimaryMenuItem;
