import { useEffect } from "react";
import { useCheckboxToggleRef, useOnClickOutside } from "hooks/common";
import { Checkbox, Icon, Text } from "components/core";
import { Container, Dropdown } from "./BaseDropdown.styles";
import { IBaseMultiCheckDropdownProps } from "./types";

const BaseDropdown = ({
  placeholder,
  areAllSelected,
  children,
  handleToggle,
  onOutsideClick,
}: React.PropsWithChildren<IBaseMultiCheckDropdownProps>) => {
  const [checkboxRef, handleCheckbox] = useCheckboxToggleRef();
  const containerRef = useOnClickOutside<HTMLDivElement>(onOutsideClick);

  useEffect(() => {
    areAllSelected ? handleCheckbox(true) : handleCheckbox(false);
  }, [handleCheckbox, areAllSelected]);

  return (
    <Container ref={containerRef}>
      <Dropdown onClick={handleToggle}>
        <Text content={placeholder} />
        <Checkbox
          variant="success"
          circular
          style={{ pointerEvents: "none", borderStyle: "dashed" }}
          ref={checkboxRef}
        />
        <Icon variant="lightGrey" name="dropdown" />
      </Dropdown>
      {children}
    </Container>
  );
};

export default BaseDropdown;
