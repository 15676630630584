import { backendApiUrl } from "helpers/UrlInstances";
import UrlHelper from "helpers/UrlHelper";

const {
  REACT_APP_NULL_ROOT_URL: nullRoot,
  REACT_APP_BACKEND_ROOT_URL: dolphynBackendRoot,
  REACT_APP_NULL_BACKEND_ROOT_URL: nullBackendRoot,
  REACT_APP_DOLPHY_ROOT_URL: dolphynRoot,
} = process.env;

// Pricing
const pricingBaseApi = new UrlHelper(
  backendApiUrl.append("/product/v1/public")
);

const navbar = {
  nullInnovation: nullRoot || "",
  blogs: `${dolphynBackendRoot}/blog`,
};

const footer = {
  dolphyn: dolphynRoot as string,
  survivor: `${nullBackendRoot}/Survivr:A-Life-Saver-Band`,
  aboutUs: `${nullBackendRoot}/about-us`,
  contactUs: `${nullBackendRoot}/contact-us`,
  framegen: `${nullBackendRoot}/Framegen:You-Think-It-We'll-Build-It`,
  blogs: `${dolphynBackendRoot}/blog`,
  home: nullRoot as string,
  whatWeDo: `${nullBackendRoot}/what-we-do`,
  refund: `${nullBackendRoot}/refund-and-cancellation-policy`,
  terms: `${nullBackendRoot}/terms-and-condition`,
  privacy: `${nullBackendRoot}/Data-Privacy-Policy`,
  sitemap: `${nullBackendRoot}/sitemap`,
};

export const BASE_PRODUCT_URL = pricingBaseApi;

export { navbar, footer };
