import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  margin: unset;
`;

const SubTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin: unset;
`;

const DateTime = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  margin: unset;
`;

const Reply = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin: unset;
`;

const ReplyUsers = styled.h4`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.pictonBlue};
  margin: unset;
`;

export {
  ReplyUsers,
  Reply,
  DateTime,
  SubTitle,
  Title,
  Container,
  MainContainer,
};
