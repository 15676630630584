import { originUrl, backendApiUrl } from "helpers/UrlInstances";
import UrlHelper from "helpers/UrlHelper";

// Base Web Analytics API Url

export const baseWebAnalyticsApiUrl = new UrlHelper(
  backendApiUrl.append("/analytics/v1/public")
);

// Web Analytics Auth APIs

const googleAnalyticsAuthApi = new UrlHelper(
  baseWebAnalyticsApiUrl.append("/ga/authenticate")
);

const searchConsoleAuthApi = new UrlHelper(
  baseWebAnalyticsApiUrl.append("/gsc/authenticate")
);

export const DEFAULT_GOOGLE_ANALYTICS_AUTH_LINK =
  googleAnalyticsAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.webAnalytics.authorizeGoogleAnalytics),
  }));

export const DEFAULT_GOOGLE_SEARCH_CONSOLE_AUTH_LINK =
  searchConsoleAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(
      routes.webAnalytics.authorizeGoogleSearchConsole
    ),
  }));
