import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IScheduledPost } from "adapters/ContentGeneration/types";

const initialState = {
  contents: [] as string[],
  scheduledPosts: [] as IScheduledPost[],
};

export const aiContent = createSlice({
  name: "aiContent",
  initialState,
  reducers: {
    setContents: (state, action: PayloadAction<string[]>) => {
      return { ...state, scheduledPosts: [], contents: action.payload };
    },
    removeContent: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        contents: state.contents.filter(
          (content) => content !== action.payload
        ),
      };
    },
    setScheduledPosts: (state, action: PayloadAction<IScheduledPost[]>) => {
      return {
        ...state,
        scheduledPosts: action.payload,
      };
    },
  },
});

export const { setScheduledPosts, setContents, removeContent } =
  aiContent.actions;

export default aiContent.reducer;
