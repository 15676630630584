import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRecommendation } from "adapters/ProductDemand/types";

const initialState = {
  data: [] as TRecommendation[],
  showRecommendation: false,
};

export const recommendation = createSlice({
  name: "recommendation",
  initialState,
  reducers: {
    setRecommendation: (state, action: PayloadAction<TRecommendation[]>) => {
      return { ...state, data: action.payload };
    },
    setShowRecommendation: (state, action: PayloadAction<boolean>) => {
      return { ...state, showRecommendation: action.payload };
    },
  },
});

export const { setRecommendation, setShowRecommendation } =
  recommendation.actions;

export default recommendation.reducer;
