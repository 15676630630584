import { customerPortal } from "api/Pricing";
import { useReactQuery, useWindowRedirect } from "hooks/common";

const useCustomerPortal = () => {
  const windowRedirect = useWindowRedirect();

  return useReactQuery(customerPortal.post, {
    onSuccess: (data) => windowRedirect.redirectExternal(data.redirectUrl),
  });
};

export default useCustomerPortal;
