import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TSpacings } from "theme/types";

const themeHelper = new ThemeHelper();

interface IContainerProps {
  direction?: React.CSSProperties["flexDirection"];
  gap?: keyof TSpacings;
}

const Container = styled.div<IContainerProps>`
  display: flex;
  margin-right: ${themeHelper.getSpacing("medium")};
  ${({ direction, gap }) => {
    return css`
      flex-direction: ${direction || "column"};
      gap: ${themeHelper.getSpacing(gap || "none")};
    `;
  }}
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  margin: unset;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

const SubTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin: unset;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha};
  }

  span {
    font-family: ${({ theme }) => theme.fonts.poppins};
    color: ${({ theme }) => theme.colors.alpha};
  }
  position: relative;
`;

const TimeStamp = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.alpha};
  font-size: 13px;
  cursor: pointer;
`;

export { Container, Title, SubTitle, TimeStamp };
