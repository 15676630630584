import styled from "styled-components";

const Text = styled.span`
  color: ${({ theme }) => theme.colors.alpha};
  font-size: 0.8rem;
  cursor: pointer;
`;

interface IReadMoreProps {
  showReadMore: boolean;
  toggleShowReadMore: () => void;
}

const ReadMore = ({ showReadMore, toggleShowReadMore }: IReadMoreProps) => {
  const text = !showReadMore ? "Read more" : "Read less";
  return <Text onClick={toggleShowReadMore}>{text}</Text>;
};
export default ReadMore;
