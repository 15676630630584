import { dolphynPublicRoot } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";
import { AuthService } from "services/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/social_auth/twitter");
const TWITTER_AUTH_URL =
  links.BASE_AUTHENTICATION_API_URL.append("/social/twitter");

const authService = new AuthService();

export type TSocialLoginTwitter = string;

const socialLoginTwitter = async (redirectUrl: TSocialLoginTwitter) => {
  try {
    const { data: twitterData } = await dolphynPublicRoot.post(
      TWITTER_AUTH_URL,
      {
        twitter_redirect: redirectUrl,
      }
    );
    const socialLoginPayload = {
      token_secret: twitterData.access_token_secret,
      access_token: twitterData.access_token,
    };

    const { data: apiData } = await dolphynPublicRoot.post(
      URL,
      socialLoginPayload,
      {
        headers: {
          redirectUrl,
        },
      }
    );

    if (!("access_token" in apiData)) {
      throw new Error();
    }

    const { access_token: access, refresh_token: refresh } = apiData;

    authService.setCookies(access, refresh);

    return { access, refresh };
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default socialLoginTwitter;
