import { Formik, FormikProps } from "formik";
import { useState } from "react";
import { Form, Text } from "components/core";
import { ReactComponent as OtherIcon } from "assets/icons/common/three-circles.svg";
import { OtherContainer, FormContainer } from "./OtherOption.styles";

interface IOtherOptionProps {
  value: string;
  onSubmit: (value: string) => void;
  children?: (
    formikProps: FormikProps<{
      other: string;
    }>
  ) => React.ReactNode;
}

const OtherOption = ({ value, onSubmit, children }: IOtherOptionProps) => {
  const [showInput, setShowInput] = useState<boolean>(false);

  const handleSubmit = (payload: { other: string }) => {
    if (payload.other) onSubmit(payload.other);
  };

  const renderInput = (
    formikProps: FormikProps<{
      other: string;
    }>
  ) => {
    if (children) return <>{children(formikProps)}</>;

    return (
      <Form.Input
        name="other"
        placeholder="Other"
        style={{ marginBottom: "unset" }}
      />
    );
  };

  if (showInput) {
    return (
      <Formik
        enableReinitialize
        initialValues={{ other: value }}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <Form.Form onSubmit={formikProps.handleSubmit}>
              <FormContainer>
                {renderInput(formikProps)}
                <Form.Button variant="alpha" icon="checkmark" type="submit" />
              </FormContainer>
            </Form.Form>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <OtherContainer onClick={() => setShowInput(true)}>
      <OtherIcon />
      <Text as="div" variant="darkGrey" content="Other" />
    </OtherContainer>
  );
};

export default OtherOption;
