import { ActionButton } from "components/core";

interface IReplyButtonProps {
  onClick?: () => void;
}

const ReplyButton = ({ onClick }: IReplyButtonProps) => {
  return (
    <ActionButton
      icon="chat"
      variant="alpha"
      size="tiny"
      outline
      onClick={onClick}
      borderless
    />
  );
};

export default ReplyButton;
