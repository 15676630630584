import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
  border: 0.125rem solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: ${helper.getSpacing("extraSmall")};
  padding: ${helper.getSpacing("small")};
  justify-content: center;
  align-items: center;
`;

export { CardContainer };
