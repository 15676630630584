import { Container } from "./PlatformIcon.styles";

export interface IPlatformIconProps {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const PlatformIcon = (props: IPlatformIconProps) => {
  const Icon = props.icon;
  if (!Icon) return null;

  return (
    <Container>
      <Icon />
    </Container>
  );
};

export default PlatformIcon;
