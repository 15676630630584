import { Linkify } from "components/common";
import { TweetCard } from "../common";
import { TInstantReplies } from "adapters/Twitter/types";
import { relativeTime } from "helpers";
import {
  ShareIcon,
  TwitterHeart,
  TwitterReply,
  TwitterRetweet,
} from "../common/icons";

interface InstantReplyCardProps {
  tweet: TInstantReplies;
  setCurrentTweet: (values: TInstantReplies) => void;
}

const InstantReplyCard = ({
  tweet,
  setCurrentTweet,
}: InstantReplyCardProps) => {
  return (
    <TweetCard>
      <TweetCard.Image src={tweet.imageUrl} alt={tweet.name} />
      <TweetCard.Content>
        <TweetCard.Head>
          <TweetCard.Username name={tweet.name} username={tweet.leadUsername} />
          <TweetCard.ScheduledAt>
            {relativeTime(tweet.createdAt)}
          </TweetCard.ScheduledAt>
        </TweetCard.Head>
        <TweetCard.Bio>
          <Linkify>{tweet.text}</Linkify>
        </TweetCard.Bio>
        <TweetCard.Action>
          <TweetCard.Stat icon={TwitterReply} value={tweet.replyCount} />
          <TweetCard.Stat icon={TwitterRetweet} value={tweet.retweetCount} />
          <TweetCard.Stat icon={TwitterHeart} value={tweet.likeCount} />
          <TweetCard.IconButton
            icon={ShareIcon}
            onClick={() => setCurrentTweet(tweet)}
          />
        </TweetCard.Action>
      </TweetCard.Content>
    </TweetCard>
  );
};

export default InstantReplyCard;
