import { TPersonalDetails } from "./types";

type TAPIResponse = {
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  country: string;
  timezone: string;
  organization_name: string;
  organization_size: string;
  language: string;
};

class PersonalDetailsAdapter {
  private parseResponse = (data: TAPIResponse): TPersonalDetails => {
    const personalDetails: TPersonalDetails = {
      firstName: data.first_name || "",
      lastName: data.last_name || "",
      username: data.user_name || "",
      email: data.email || "",
      organizationName: data.organization_name || "",
      organizationSize: data.organization_size || "",
      timezone: data.timezone || "",
      country: data.country || "",
      language: data.language || "",
    };

    return personalDetails;
  };

  parseGetResponse = (data: TAPIResponse): TPersonalDetails => {
    return this.parseResponse(data);
  };

  getPatchPayload = (payload: Partial<TPersonalDetails>) => {
    const apiPayload: Partial<TAPIResponse> = {
      first_name: payload.firstName,
      last_name: payload.lastName,
      user_name: payload.username,
      email: payload.email,
      organization_name: payload.organizationName,
      organization_size: payload.organizationSize,
      timezone: payload.timezone,
      country: payload.country,
      language: payload.language,
    };

    return apiPayload;
  };

  parsePatchResponse = (data: TAPIResponse): TPersonalDetails => {
    return this.parseResponse(data);
  };
}

export default PersonalDetailsAdapter;
