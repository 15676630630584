const data = {
  header: {
    title: "Free",
    subtitle: "Only for BETA Users",
  },
  subHeader: {
    title: "Access to all Premium Features for 3 months",
  },
  features: {
    title: "What we need from you",
    items: [
      { text: "Your help in building the best tool for digital marketers" },
      { text: "Occasionally fill out the survey form" },
      {
        text: "Once in a month face to face virtual interview you are providing us feedback",
      },
      { text: "Tell us what you need in the tool and we will build for you" },
    ],
  },
};

export default data;
