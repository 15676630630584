import { useTwitterAuth } from "hooks";
import { usePostScheduler } from "hooks/Twitter";
import {
  setComposer,
  setConnectTwitter,
  setScheduled,
  setSent,
} from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { Menu, Item } from "./SchedulerMenu.styles";

const SchedulerMenu = () => {
  const { getScheduledPost, getSentPost } = usePostScheduler();
  const { isSignedIn } = useTwitterAuth();
  const dispatch = useAppDispatch();
  const postComposer = useAppSelector(
    (state) => state.twitter.current.postComposer
  );
  const scheduledList = useAppSelector(
    (state) => state.twitter.current.scheduledList
  );
  const sentList = useAppSelector((state) => state.twitter.current.sentList);

  return (
    <Menu secondary>
      <Item
        name="compose"
        active={postComposer}
        onClick={() => {
          if (isSignedIn) {
            dispatch(setComposer());
            getScheduledPost();
          } else {
            dispatch(setConnectTwitter(true));
          }
        }}
      />
      <Item
        name="scheduled"
        active={scheduledList}
        onClick={() => {
          if (isSignedIn) {
            dispatch(setScheduled());
            getScheduledPost();
          } else {
            dispatch(setConnectTwitter(true));
          }
        }}
      />
      <Item
        name="sent"
        active={sentList}
        onClick={() => {
          if (isSignedIn) {
            dispatch(setSent());
            getSentPost();
          } else {
            dispatch(setConnectTwitter(true));
          }
        }}
      />
    </Menu>
  );
};

export default SchedulerMenu;
