export type TBaseCollection = {
  id: number | string;
  [prop: string]: any;
};

type TGetItem<T extends TBaseCollection> = Parameters<
  CollectionHelper<T>["getItem"]
>;

class CollectionHelper<T extends TBaseCollection> {
  getItem = (collection: T[], id: T["id"]) =>
    collection.find((option) => option.id === id);

  isItemPresent = (...args: TGetItem<T>) => this.getItem(...args) !== undefined;

  addItem = (collection: T[], value: T) => {
    if (this.isItemPresent(collection, value.id)) {
      return collection;
    }
    return [...collection, value];
  };

  removeItem = (...args: TGetItem<T>) =>
    args[0].filter((option) => option.id !== args[1]);

  updateItem = (collection: T[], value: T) =>
    collection.map((option) => {
      if (option.id === value.id) return value;
      return option;
    });

  filterItems = <U extends keyof T>(
    collection: T[],
    property: U,
    value: T[U]
  ) => {
    return collection.filter((item) => item[property] === value);
  };
}

export default CollectionHelper;
