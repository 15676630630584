import styled from "styled-components";

import { ReactComponent as Threedots } from "assets/icons/SocialMediaPost/dots-three.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 12px;
`;

const Position = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 14px;
`;

const Name = styled(Position)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DotsContainer = styled.div`
  margin-left: auto;
  justify-self: flex-end;
  width: 36px;
  height: 36px;
`;

type TDefaultProps = {
  profileImage: string;
  name: string;
  position: string;
};

const LinkedInPostHeader = ({
  profileImage,
  name,
  position,
}: TDefaultProps) => {
  return (
    <Wrapper>
      <ProfileImage src={profileImage} alt={name} />
      <InfoContainer>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </InfoContainer>
      <DotsContainer>
        <Threedots />
      </DotsContainer>
    </Wrapper>
  );
};

export default LinkedInPostHeader;
