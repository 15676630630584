import styled from "styled-components";
import ProfileSection from "./ProfileSection";
import { Button, Input, LinkButton, Label, Field, Icon } from "components/core";
import { format } from "date-fns";
import { useNavigation, useToggle } from "hooks";
import { usePlanDetails } from "hooks/Profile";
import { useCustomerPortal } from "hooks/Pricing";
import { originUrl } from "helpers/UrlInstances";
import { applicationRoutes } from "config/common";

const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const PlanDetails = () => {
  const planDetails = usePlanDetails();
  const navigation = useNavigation();
  const customerPortal = useCustomerPortal();
  const [showDetails, toggleShowDetails] = useToggle();
  const { expiry, currency, paymentDetails, plan, paymentMethod } =
    planDetails.details;

  const renderPaymentDetails = () => {
    if (showDetails) {
      return (
        <Content>
          {paymentDetails.map(({ title, value }, key) => (
            <Field key={key}>
              <Label content={title} />
              <Input focused value={value} type="text" readOnly />
            </Field>
          ))}
        </Content>
      );
    }
    return null;
  };

  return (
    <ProfileSection>
      <ProfileSection.Actions>
        <Button
          type="submit"
          variant="alpha"
          size="small"
          content="Upgrade"
          onClick={() =>
            navigation.navigate((routes) => routes.pricing.upgrade)
          }
          me="small"
        />
        <LinkButton
          content="Content Creation"
          onClick={() =>
            customerPortal.execute({
              returnUrl: originUrl.append(
                applicationRoutes.rootRoutes.contentCreation
              ),
            })
          }
        />
      </ProfileSection.Actions>
      <ProfileSection.Content>
        <Container>
          <Content>
            {currency && (
              <Field>
                <Label content="Currency" />
                <Input focused value={currency} type="text" readOnly />
              </Field>
            )}
            {paymentMethod && (
              <Field>
                <Label content="Payment Method" />
                <Input
                  focused
                  value={paymentMethod}
                  type="text"
                  readOnly
                  cursor="pointer"
                  onClick={() => toggleShowDetails()}
                  icon={
                    <Icon
                      className="icon"
                      name="caret right"
                      variant="alpha"
                      size="large"
                    />
                  }
                />
              </Field>
            )}
            <Field>
              <Label content="Plan" />
              <Input focused value={plan} type="text" readOnly />
            </Field>
            {expiry && (
              <Field>
                <Label content="Expires on" />
                <Input
                  focused
                  value={format(new Date(expiry), "dd/MM/yyyy")}
                  type="text"
                  readOnly
                />
              </Field>
            )}
          </Content>
          {renderPaymentDetails()}
        </Container>
      </ProfileSection.Content>
    </ProfileSection>
  );
};

export default PlanDetails;
