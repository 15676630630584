import { createContext } from "react";
import {
  useTwitterCalendar,
  useInstagramCalendar,
  useLinkedinCalendar,
  useFacebookCalendar,
  usePosts,
} from "hooks/SocialMediaPost";
import { TBaseSocialMediaPost } from "adapters/SocialMediaPost/types";
import { IScheduleDeleteFormValues, IScheduleUpdateFormValues } from "./types";
import { TScheduleUpdatePayload } from "api/SocialMediaPost/types";
import { useFileUpload } from "hooks";
import { TPostFilterFormValues } from "components/SocialMediaPost/common/types";
import { Loader } from "components/common";
import { calendar } from "config/SocialMediaPost";

export const SocialCalendarContext = createContext({
  data: [] as TBaseSocialMediaPost[],
  handleUpdate: (formValues: IScheduleUpdateFormValues) => {},
  handleDelete: (formValues: IScheduleDeleteFormValues) => {},
  handleFilter: (filterValues: TPostFilterFormValues) => {},
});

const SocialCalendarProvider = (props: React.PropsWithChildren<{}>) => {
  const posts = usePosts();
  const fileUploadHandler = useFileUpload();

  const facebook = useFacebookCalendar({
    onSuccess: posts.facebook.onFetchSuccess,
  });
  const linkedin = useLinkedinCalendar({
    onSuccess: posts.linkedin.onFetchSuccess,
  });
  const twitter = useTwitterCalendar({
    onSuccess: posts.twitter.onFetchSuccess,
  });
  const instagram = useInstagramCalendar({
    onSuccess: posts.instagram.onFetchSuccess,
  });

  const handleDelete = (formValues: IScheduleDeleteFormValues) => {
    facebook.remove(formValues, {
      onSuccess: posts.facebook.onRemoveSuccess,
    });
    linkedin.remove(formValues, {
      onSuccess: posts.linkedin.onRemoveSuccess,
    });
    instagram.remove(formValues, {
      onSuccess: posts.instagram.onRemoveSuccess,
    });
    twitter.remove(formValues, {
      onSuccess: posts.twitter.onRemoveSuccess,
    });
  };

  const handleUpdate = (formValues: IScheduleUpdateFormValues) => {
    const updateSchedule = (data: TScheduleUpdatePayload) => {
      facebook.update(data, {
        onSuccess: posts.facebook.onUpdateSuccess,
      });
      linkedin.update(data, {
        onSuccess: posts.linkedin.onUpdateSuccess,
      });
      instagram.update(data, {
        onSuccess: posts.instagram.onUpdateSuccess,
      });
      twitter.update(data, {
        onSuccess: posts.twitter.onUpdateSuccess,
      });
    };

    if (formValues.files.length) {
      fileUploadHandler.upload(formValues.files, {
        onSuccess: (files) => {
          updateSchedule({
            ...formValues,
            serverFiles: [...files, ...formValues.serverFiles],
          });
        },
      });
      return;
    }

    updateSchedule(formValues);
  };

  const handleFilter = (filterValues: TPostFilterFormValues) => {
    posts.facebook.onFilter(filterValues);
    posts.twitter.onFilter(filterValues);
    posts.instagram.onFilter(filterValues);
    posts.linkedin.onFilter(filterValues);
  };

  return (
    <SocialCalendarContext.Provider
      value={{
        data: posts.data,
        handleDelete,
        handleUpdate,
        handleFilter,
      }}
    >
      <Loader
        textData={calendar}
        hidden={
          !facebook.isLoading &&
          !instagram.isLoading &&
          !twitter.isLoading &&
          !linkedin.isLoading &&
          !fileUploadHandler.uploading
        }
      />
      {props.children}
    </SocialCalendarContext.Provider>
  );
};

export default SocialCalendarProvider;
