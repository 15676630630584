import styled, { css, DefaultColors } from "styled-components";
import { Icon as BaseIcon, IconProps } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";
import { TFontSizes } from "theme/types";

const helper = new ThemeHelper();

type TIconContainer = {
  variant?: keyof DefaultColors;
  size?: keyof TFontSizes;
  disable?: boolean;
};

const Container = styled.div<TIconContainer>`
  display: inline-grid;
  place-content: center;
  &&& i,
  svg {
    margin: unset;
    display: block;
    height: 100%;
    width: 100%;
  }
  &&& i {
    ${({ size }) => helper.getFontSize(size)};
  }
  &&& svg {
    width: ${({ size }) => helper.getGeneralSize(size)};
    height: ${({ size }) => helper.getGeneralSize(size)};
  }
  ${({ variant, theme }) => {
    if (variant) {
      return css`
        &&& i {
          color: ${theme.colors[variant]};
        }
        svg,
        svg rect,
        svg path {
          stroke: ${theme.colors[variant]};
        }
      `;
    }
  }}

  ${({ onClick }) => {
    if (onClick) {
      return css`
        &&& i,
        svg {
          cursor: pointer;
        }
      `;
    }
  }}
`;

// type of props become TIconOwnProps if icon is passed or else becomes IconProps coming from semantic UI

type TPropIcon = React.FC<React.SVGProps<SVGSVGElement>>;

export interface TIconOwnProps extends TIconContainer {
  icon?: TPropIcon;
}

export type TIconProps = TIconOwnProps & Omit<IconProps, "size">;

const Icon = ({
  icon: PropIcon,
  className,
  style,
  variant,
  size,
  ...props
}: TIconProps) => {
  const iconOwnProps = { className, style, variant, size };

  if (PropIcon)
    return (
      <Container {...iconOwnProps} {...props}>
        <PropIcon />
      </Container>
    );

  return (
    <Container {...iconOwnProps}>
      <BaseIcon {...props} />
    </Container>
  );
};

export default Icon;
