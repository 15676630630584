import { constants } from "config/Auth";
import BaseService, { TUid } from "./baseSocialAccountService";

export type TLinkedinAccount = {
  tokenId: string;
  uid: TUid;
  name: string;
  profileImageUrl: string;
  id: string;
};

export type TLinkedinPage = {
  name: string;
  id: string;
  profileImageUrl: string;
};

export type TLinkedinPages = {
  uid: TUid;
  pages: TLinkedinPage[];
};

class LinkedinService extends BaseService<
  TLinkedinAccount,
  TLinkedinPages,
  TLinkedinPage
> {
  constructor() {
    super(constants.LINKEDIN_COOKIE, constants.LINKEDIN_LOCAL_STORAGE);
  }

  setAuthCookie = (cookie: TLinkedinAccount) => {
    this.accountExists(cookie.uid)
      ? this.updateAccount(cookie)
      : this.addNewAccount(cookie);
    const currentAccounts = super.getAccounts();
    if (currentAccounts.length === 1) {
      this.setSelectedAccount(currentAccounts[0].uid);
    }
  };

  setPages = (pages: TLinkedinPages) => {
    this.pageListExists(pages.uid)
      ? this.updatePageList(pages)
      : this.addNewPageList(pages);
  };

  isAuthenticated = () => {
    return super.isAuthenticated();
  };

  removeAccount = (uid: TUid) => {
    super.removeAccount(uid);
    super.removePageList(uid);
  };

  getAccounts = () => super.getAccounts();

  clearData = () => {
    super.clearCookie();
    super.clearStorage();
  };

  getAccount = (uid: TUid) => {
    return super.getAccount(uid);
  };

  getPageLists = () => {
    return super.getPageLists();
  };

  getPages = (uid: TUid) => {
    return super.getPages(uid);
  };

  getPage = (uid: TUid, pageId: string) => {
    return super.getPage(uid, pageId);
  };

  filterPages = (uid: TUid, pageIds: string[]) => {
    return super.filterPages(uid, pageIds);
  };

  removePageList = (uid: TUid) => {
    super.removePageList(uid);
  };

  setSelectedAccount = (uid: TUid) => {
    super.setSelectedAccount(uid);
  };

  removeSelectedAccount = () => {
    super.reInitializeSelectedAccount({
      tokenId: "",
      uid: "",
      name: "",
      profileImageUrl: "",
      id: "",
    });
  };

  getSelectedAccount = () => {
    return super.getSelectedAccount({
      tokenId: "",
      uid: "",
      name: "",
      profileImageUrl: "",
      id: "",
    });
  };
}

export type { TUid } from "./baseSocialAccountService";
export default LinkedinService;
