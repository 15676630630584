import { TwitterService } from "services/Auth";
import { TTweetReplyPayload } from "./types";

type TAPIPayload = {
  message: string;
  files: any;
};

class ReplyTweetAdapter {
  private twitter = new TwitterService();
  getPostUrl = (url: string, payload: TTweetReplyPayload) =>
    `${url}/${payload.tweetId}`;
  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });
  getPostPayload = ({
    message,
    files = [],
  }: TTweetReplyPayload): TAPIPayload => {
    return {
      message,
      files,
    };
  };
}

export default ReplyTweetAdapter;
