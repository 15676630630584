import styled from "styled-components";

const Container = styled.div`
  color: black;
  text-align: center;
  display: grid;
  justify-items: center;
  align-content: center;
  position: absolute;
  z-index: 10;
  bottom: 1%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 16vw;
  height: 175px;
  border: 1px dashed black;
  border-radius: 15px;

  font-family: ${({ theme }) => theme.fonts.poppins};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export { Container };
