import { Formik } from "formik";
import { Button, Modal } from "components/core";
import { IScheduleUpdateFormValues } from "./types";
import { IModalProps } from "components/core/types";
import { usePlatformWordLimit } from "hooks/SocialMediaPost";
import PostFile from "./PostFile";
import PostText from "./PostText";
import PostDateTime from "./PostDateTime";
import PostServerFiles from "./PostServerFiles";

interface IUpdatePostFormProps {
  open: IModalProps["open"];
  onClose: IModalProps["onClose"];
  formValues: IScheduleUpdateFormValues;
  onSubmit: (values: IScheduleUpdateFormValues) => void;
}

const UpdatePostProps = ({
  open,
  onClose,
  formValues,
  onSubmit,
}: IUpdatePostFormProps) => {
  const wordLimit = usePlatformWordLimit();

  return (
    <Modal open={open} onClose={onClose}>
      <Formik initialValues={formValues} onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <PostServerFiles name="serverFiles" />
            <PostFile name="files" />
            <PostText
              name="text"
              wordLimit={wordLimit.getLimit({
                facebook: !!values.facebook,
                instagram: !!values.instagram,
                twitter: !!values.twitter,
                linkedin: !!values.linkedin,
              })}
            />
            <PostDateTime name="datetime" />

            <Button
              variant="alpha"
              content="Submit"
              style={{ marginBottom: "2rem" }}
              type="submit"
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdatePostProps;
