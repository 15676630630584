import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const IconContainer = styled.div`
  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  align-self: flex-start;
`;

interface IStatProps extends React.ComponentPropsWithoutRef<"div"> {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: string;
  value: string | number;
}

const Stat = ({ icon: Icon, title, value, ...props }: IStatProps) => {
  return (
    <Container {...props}>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      {title && <Title>{title}</Title>}
      <Description>{value}</Description>
    </Container>
  );
};

export default Stat;
