import { loginInstagram as authApi } from "api/Auth";
import * as instagram from "redux/slices/Auth/instagram";
import * as instagramSelection from "redux/slices/Auth/instagramSelection";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { useReactQuery } from "hooks/common";

const useInstagramAuth = () => {
  const dispatch = useAppDispatch();
  const reInitialize = () => {
    dispatch(instagram.reInitialize());
    dispatch(instagramSelection.reInitialize());
  };
  const { execute: login, isLoading } = useReactQuery(authApi, {
    onSuccess: () => {
      reInitialize();
    },
  });

  /* 
    States
  */

  const isSignedIn = useAppSelector((state) => state.auth.instagram.isSignedIn);
  const accounts = useAppSelector((state) => state.auth.instagram.accounts);
  const selectedAccount = useAppSelector(
    (state) => state.auth.instagramSelection.selectedAccount
  );
  const isAccountSelected = (uid: instagram.TUid) =>
    selectedAccount.uid === uid;

  /* 
    Handlers
  */

  const removeAccount = (uid: instagram.TUid) => {
    dispatch(instagram.removeAccount(uid));
    dispatch(instagramSelection.resetSelection());
  };

  const selectAccount = (uid: string) => {
    dispatch(instagramSelection.selectAccount(uid));
  };

  const clearData = () => {
    dispatch(instagram.clearData());
    dispatch(instagramSelection.resetSelection());
  };

  return {
    isSignedIn,
    isLoading,
    login,
    accounts,
    selectedAccount,
    isAccountSelected,
    removeAccount,
    selectAccount,
    clearData,
  };
};

export default useInstagramAuth;
