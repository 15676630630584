import dolphynPublicRoot from "api/instances/dolphynPublicRoot";
import { BASIC_AUTH_HEADER_VALUE } from "config/common/constants";
import { throwApiError } from "errors";
import { format } from "date-fns";

const URL = "/api/twitter/v1/public/basic_auth_potential_leads";

const defaultError = "Unable to process the request!";

const headers = {
  Authorization: BASIC_AUTH_HEADER_VALUE,
};

type TAPIResponse = {
  name: string;
  lead_username: string;
  twitter_username: string;
  bio: string;
  location: string;
  website: string;
  influencer: boolean;
  followers: number;
  followings: number;
  tweets: number;
  keyword: string;
  language: string;
  profile_pic_url: string;
  joined_since: string;
  potential_customer: boolean;
  tweets_liked: number;
  tweets_replied: number;
  following_since: string;
  addition_date: null;
}[];

export type TTwittercard = {
  name: string;
  username: string;
  bio: string;
  location: string;
  followers: number;
  followings: number;
  tweets: number;
  profilePicUrl: string;
  joinedSince: string;
};

export type IPotentialLeadsData = TTwittercard[];

export interface ISearchKeywords {
  country: string;
  keyword: string;
}

const fetchPotentialLeads = async ({ country, keyword }: ISearchKeywords) => {
  try {
    const response = await dolphynPublicRoot(URL, {
      headers,
      params: {
        country,
        keywords: keyword,
      },
    });

    const data = response.data as TAPIResponse;

    const twitterData: TTwittercard[] = data.map(
      ({
        name,
        lead_username,
        bio,
        location,
        followers,
        followings,
        tweets,
        profile_pic_url,
        joined_since,
      }: any) => ({
        name,
        username: lead_username,
        bio,
        location,
        followers,
        followings,
        tweets,
        profilePicUrl: profile_pic_url,
        joinedSince: format(new Date(joined_since), "MMM dd, yyyy E"),
      })
    );

    return twitterData as IPotentialLeadsData;
  } catch (e) {
    throwApiError(e, defaultError);
  }
};

export default fetchPotentialLeads;
