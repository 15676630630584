import { ContentGenerationByKeywordsAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/Twitter";
import { APIError } from "errors";
import { IContentGenerationByKeywordsPayload } from "./types";

const errors = new APIError();
const URL = links.baseTwitterApiUrl.append("/ai_content_generation/keywords");
const adapter = new ContentGenerationByKeywordsAdapter();

const contentGenerationByKeywords = async (
  values: IContentGenerationByKeywordsPayload
) => {
  try {
    const apiPayload = adapter.getPostPayload(values);
    const headers = adapter.getConfig();
    const { data } = await dolphynAPI.post(URL, apiPayload, headers);
    return adapter.getResponse(data);
  } catch (e) {
    errors.throwAxiosError(e);
  }
};

export default contentGenerationByKeywords;
