import { Dropdown } from "components/core";
import { useField } from "formik";
import { postStatusFilterMap } from "config/SocialMediaPost";
import { TPostFilterFormValues, TPostStatusFilterValues } from "../types";

type TOptions = {
  key: string;
  text: string;
  value: TPostStatusFilterValues;
};

const OPTIONS: TOptions[] = [
  { key: "all", text: "All", value: postStatusFilterMap.all },
  { key: "scheduled", text: "Scheduled", value: postStatusFilterMap.scheduled },
  { key: "history", text: "History", value: postStatusFilterMap.posted },
];

interface IPostStatusFilterProps {
  name: string;
}

const PostStatusFilter = ({ name }: IPostStatusFilterProps) => {
  const [field, , helpers] =
    useField<TPostFilterFormValues["postStatus"]>(name);

  return (
    <Dropdown
      selection
      placeholder="Post type"
      options={OPTIONS}
      value={field.value}
      onChange={(e, { value }) => {
        helpers.setValue(value as TPostStatusFilterValues);
      }}
    />
  );
};

export default PostStatusFilter;
