import { Header } from "components/core";
import { Container, SubContainer, TopSection } from "./ModuleLayout.styles";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Container>
      <TopSection>
        <Header
          as="h1"
          content="Link Account . Create  Post . Schedule"
          variant="black"
          textAlign="center"
          size="large"
          mb="large"
          mt="small"
        />
      </TopSection>
      <SubContainer>{props.children}</SubContainer>
    </Container>
  );
};

export default ModuleLayout;
