import styled from "styled-components";
import Card from "./Card";
import { useLiveTrends } from "hooks/SocialMediaPost";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

const TrendCards = () => {
  const liveTrends = useLiveTrends();
  return (
    <Container>
      <Card header="Google" data={liveTrends.google} />
      <Card header="Twitter" data={liveTrends.twitter} />
      <Card header="Youtube" data={liveTrends.youtube} />
    </Container>
  );
};

export default TrendCards;
