import { useSearchDemand } from "hooks/ProductDemand";
import SingleKeywordSearchProvider from "./SingleKeywordSearchProvider";

const SearchDemandFormProvider = (props: React.PropsWithChildren<{}>) => {
  const searchDemand = useSearchDemand();

  return (
    <SingleKeywordSearchProvider onSubmit={searchDemand.handleSearch}>
      {props.children}
    </SingleKeywordSearchProvider>
  );
};

export default SearchDemandFormProvider;
