import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: ${helper.getSpacing("extraSmall")} 0;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  width: 200px;
  top: 110%;
  cursor: pointer;
  ${helper.getBoxShadow("extraLightGrey", { intensity: "medium" })}
`;

const ButtonContainer = styled.div`
  padding: ${helper.getSpacing("extraSmall")};
`;

export { Container, ButtonContainer };
