import { IReplyAIGeneratedPayload } from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { IAIGeneratedReply } from "./types";

type TAPIResponse = {
  referenced_tweet_id: string;
  generated_reply: string;
  sentiment: string;
};

type TAPIPayload = {
  sentiment: string;
  referenced_tweet_id: string;
  tweet_text: string;
};

class AIGeneratedReplyAdapter {
  private twitterService = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IAIGeneratedReply => {
    return {
      referencedId: apiResponse.referenced_tweet_id,
      reply: apiResponse.generated_reply,
      sentiment: apiResponse.sentiment,
    };
  };

  getConfig = () => ({ headers: this.twitterService.getHeadersOrError() });

  getPostPayload = (payload: IReplyAIGeneratedPayload): TAPIPayload => {
    return {
      sentiment: payload.sentiment,
      referenced_tweet_id: payload.tweetId,
      tweet_text: payload.tweetText,
    };
  };

  getPostResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default AIGeneratedReplyAdapter;
