import { Container } from "./GraphInfo.styles";

type TGraphInfo = { title: string; value: string };

interface IGraphInfo {
  data: TGraphInfo[];
}

const GraphInfo = ({ data }: IGraphInfo) => {
  return (
    <Container>
      {data.map(({ title, value }, key) => (
        <div key={key}>
          {title} - <span>{value}</span>
        </div>
      ))}
    </Container>
  );
};

export default GraphInfo;
