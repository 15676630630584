import { Dropdown } from "components/core";
import { useField } from "formik";
import { IDropdownProps } from "components/core/types";

interface IDropdownFieldProps extends IDropdownProps {
  readOnly?: boolean;
  name: string;
}

const DropdownField = ({
  name,
  readOnly,
  options,
  placeholder,
  ...props
}: IDropdownFieldProps) => {
  const [field, , helper] = useField<string>(name);

  return (
    <Dropdown
      placeholder={placeholder}
      options={options}
      upward={false}
      search={!readOnly}
      selection={!readOnly}
      open={readOnly ? false : undefined}
      value={field.value}
      onChange={(e, { value }) =>
        typeof value === "string" && helper.setValue(value)
      }
      {...props}
    />
  );
};

export default DropdownField;
