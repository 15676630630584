import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${helper.getSpacing("small")};
  align-items: flex-start;

  @media screen and (max-width: 1274px) {
    gap: ${helper.getSpacing("tiny")};
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export { Container };
