import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Grid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 8% 7% 85%;
  overflow: hidden;
  padding-inline: ${helper.getSpacing("large")};
`;

const TopSection = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: end;

  .modal-icon {
    display: flex;
    gap: ${helper.getSpacing("extraSmall")};
  }
`;

const List = styled.div``;

const MenuBar = styled.div``;

const Body = styled.div``;

export { Grid, TopSection, List, MenuBar, Body };
