import { TwitterService } from "services/Auth";
import { IReportsPayload } from "./types";
import { utils, writeFile } from "xlsx";

type TAPIPayload = {
  potential_leads: boolean;
  potential_customers: boolean;
  influencers: boolean;
  social_info: boolean;
};

class ReportsAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: any) => {
    const worksheet = utils.json_to_sheet(apiResponse.reportsData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet");
    writeFile(workbook, "reports.xlsx");
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: IReportsPayload) => {
    const payload: TAPIPayload = {
      potential_leads: data.potentialLeads,
      potential_customers: data.potentialCustomers,
      influencers: data.influencers,
      social_info: data.socialInfo,
    };

    return payload;
  };

  postParseResponse = this.parseResponse;
}

export default ReportsAdapter;
