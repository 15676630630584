import ClipLoader from "react-spinners/ClipLoader";
import { DefaultColors } from "styled-components";

interface Props {
  size?: number;
  variant?: keyof DefaultColors;
}

const CustomSpinner = ({ size = 32, variant = "extraLightGrey" }: Props) => {
  return (
    <ClipLoader
      color={variant}
      size={size}
      aria-label="Spinner"
      data-testid="loader"
    />
  );
};

export default CustomSpinner;
