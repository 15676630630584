import { AuthPopUpWindow } from "components/Auth";
import { links, socialMedia } from "config/SocialMediaPost";
import { AccountCard, Loader } from "components/common";
import { ReactComponent as FacebookIcon } from "assets/icons/SocialMediaPost/social-media-post-facebook-icon.svg";
import { useFacebookAuth } from "hooks/Auth";

const FacebookAccountCard = () => {
  const facebook = useFacebookAuth();

  return (
    <>
      <Loader textData={socialMedia} hidden={!facebook.isLoading} />
      <AccountCard>
        <AccountCard.Logo icon={<FacebookIcon />} />
        {facebook.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.name}
            image={account.profileImageUrl}
            onDelete={() => facebook.removeAccount(account.uid)}
            active={facebook.isAccountSelected(account.uid)}
            onClick={() => facebook.selectAccount(account.uid)}
          />
        ))}
        <AuthPopUpWindow
          trigger={
            <AccountCard.Button content="Connect" variant="success" outline />
          }
          url={links.SOCIAL_ACCOUNT_FACEBOOK_AUTH_LINK}
          expectedParam="code"
          onSuccess={(params, redirectUrl) =>
            redirectUrl && facebook.login(redirectUrl)
          }
        />
      </AccountCard>
    </>
  );
};

export default FacebookAccountCard;
