import { useContext, useState } from "react";
import styled from "styled-components";
import { Spinner } from "components/common";
import { SearchKeywordsContext } from "context/Home";
import TwitterCard from "./TwitterCard";
import { v4 as uuid } from "uuid";
import TrialButton from "./TrialButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EasyTwitter = () => {
  const [showTrial, setShowTrial] = useState(false);

  const { potentialLeadsData } = useContext(SearchKeywordsContext);

  const { isLoading } = useContext(SearchKeywordsContext);

  if (showTrial) return <TrialButton />;

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  if (!potentialLeadsData) return null;

  return (
    <Container>
      {potentialLeadsData.map((data) => (
        <TwitterCard
          key={uuid()}
          data={data}
          onAction={() => setShowTrial(true)}
        />
      ))}
    </Container>
  );
};

export default EasyTwitter;
