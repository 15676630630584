import { dolphynAPI } from "api/instances";
import { throwApiError } from "errors";
import { constants } from "config/Auth";
import { GoogleAnalyticsService } from "services/WebAnalytics";

export type TAccount = {
  text: string;
  value: string;
};
export type TProperty = {
  text: string;
  value: string;
  account: string;
};
export type TView = {
  text: string;
  value: string;
  account: string;
  property: string;
};

export interface IGoogleAnalyticsAccountData {
  accounts: TAccount[];
  properties: TProperty[];
  views: TView[];
  userEmail: string;
  profilePicUrl: string;
}

const webAnalyticsService = new GoogleAnalyticsService();

const loginGoogleAnalytics = async (redirectUrl: string) => {
  try {
    const { data } = await dolphynAPI.post(
      `/analytics/v1/public/ga/authenticate`,
      {
        google_redirect: redirectUrl,
      }
    );

    const credentials = {
      token: data.token as string,
      refresh: data.refresh_token as string,
    };

    const userEmail = data.analytics_email;
    const profilePicUrl = data.profile_url;

    const accounts: TAccount[] = data.accounts;

    const properties: TProperty[] = data.properties;

    const views: TView[] = data.views;

    const accountsData = {
      accounts,
      properties,
      views,
      userEmail,
      profilePicUrl,
    } as IGoogleAnalyticsAccountData;

    webAnalyticsService.setAuthCookie(credentials);
    webAnalyticsService.setAccounts(accountsData);

    return accountsData;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginGoogleAnalytics;
