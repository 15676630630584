import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Admin";
import { APIError } from "errors";
import { AdminAdapter } from "adapters/Admin";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseAdminApiUrl.append("/is_admin");
const adapter = new AdminAdapter();

const Admin = {
  isAdmin: async () => {
    try {
      const { data } = await dolphynAPI(URL);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default Admin;
