import { AccountCard } from "components/common";
import { ReactComponent as InstagramIcon } from "assets/icons/SocialMediaPost/social-media-post-instagram-icon.svg";
import { useInstagramAuth } from "hooks/Auth";
import { useBoolean } from "hooks/common";
import InstagramConnectPopup from "./InstagramConnectPopup";

const InstagramAccountCard = () => {
  const instagram = useInstagramAuth();
  const [isModalOpen, setModalOpen, setModalClose] = useBoolean();

  return (
    <>
      <AccountCard>
        <AccountCard.Logo icon={<InstagramIcon />} />
        {instagram.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.name}
            image={account.profileImageUrl}
            onDelete={() => instagram.removeAccount(account.uid)}
            active={instagram.isAccountSelected(account.uid)}
            onClick={() => instagram.selectAccount(account.uid)}
          />
        ))}
        {isModalOpen && (
          <InstagramConnectPopup open={isModalOpen} onClose={setModalClose} />
        )}
        <AccountCard.Button
          content="Connect"
          variant="success"
          outline
          onClick={() => setModalOpen()}
        />
      </AccountCard>
    </>
  );
};

export default InstagramAccountCard;
