import { Container, Line, Sidebar } from "./ReplyingCard.styles";
import ReplyingUsername from "./ReplyingUsername";
import { Action, Bio, Content, Head, Image } from "./TweetCard.styles";

interface IReplyingCardProps {
  children?: React.ReactNode;
}

const ReplyingCard = ({ children }: IReplyingCardProps) => {
  return <Container>{children}</Container>;
};

ReplyingCard.Image = Image;
ReplyingCard.Content = Content;
ReplyingCard.Sidebar = Sidebar;
ReplyingCard.Action = Action;
ReplyingCard.Bio = Bio;
ReplyingCard.Head = Head;
ReplyingCard.Line = Line;
ReplyingCard.ReplyingUsername = ReplyingUsername;

export default ReplyingCard;
