const {
  REACT_APP_BASIC_AUTH_USERNAME: USERNAME,
  REACT_APP_BASIC_AUTH_PASSWORD: PASSWORD,
} = process.env;

const BASIC_AUTH_HEADER_VALUE = `Basic ${window.btoa(
  `${USERNAME}:${PASSWORD}`
)}`;

const DEFAULT_API_ERROR = "Something went wrong. Please try again.";

export { BASIC_AUTH_HEADER_VALUE, DEFAULT_API_ERROR };

export const GTM_ID = process.env.REACT_APP_GTM_ID as string;
