import StringHelper from "helpers/StringHelper";

type TDataKeyMap<T> = {
  [key in keyof T]: string;
};

const stringHelper = new StringHelper();
const useGraphHelper = <T>(data: T[] = []) => {
  const formatTick = (value: string) => stringHelper.truncate(value, 15);

  const _convertToMultiple = (payload: number, multipleOf = 10) => {
    const payloadString = String(Math.ceil(payload));
    const result =
      (parseInt(payloadString[0]) + 1) *
      Math.pow(multipleOf, payloadString.length - 1);

    return result;
  };

  const getDataKeysMap = () => {
    if (data.length <= 0) return {} as TDataKeyMap<T>;
    const map: {
      [prop: string]: string;
    } = {};

    // @ts-ignore
    Object.entries(data[0]).forEach(([key, value]) => {
      map[key] = key;
    });

    return map as TDataKeyMap<T>;
  };

  const getDomain = (): [number, (value: number) => number] => {
    return [0, _convertToMultiple];
  };

  const capitalizeTick = (value: any) => {
    if (typeof value === "string") {
      return value[0].toUpperCase() + value.slice(1);
    }
    return "";
  };

  return { formatTick, getDomain, getDataKeysMap, capitalizeTick };
};

export default useGraphHelper;
