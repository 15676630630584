import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div``;

const Body = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  ${helper.getBorderRadius("small")};
`;

export { Container, Body };
