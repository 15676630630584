import styled from "styled-components";
import { styles } from "../Common";

const Container = styled.section`
  ${styles.helper.containerize(80)}
  ${styles.marginBottom}
`;

const Header = styled.h2`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getFontSize("large")}
  text-align: center;
  color: ${({ theme }) => theme.colors.alpha};
  margin-bottom: ${styles.helper.getSpacing("large")};
  ::after {
    content: "";
    width: 50px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.extraLightGrey};
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  gap: ${styles.helper.getSpacing("medium")};
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${styles.helper.getSpacing("medium")};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 8px;
  padding: ${styles.helper.getSpacing("medium")};
`;

const Review = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  ${styles.helper.getLineHeight("medium")}
  ${styles.helper.getFontSize("small")}
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

const Image = styled.img`
  width: 50px;
  border-radius: 50%;
`;

const Detail = styled.div``;

const Name = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  ${styles.helper.getFontSize("small")}
  ${styles.helper.getFontWeight("medium")}
  margin-bottom: ${styles.helper.getSpacing("extraSmall")};
`;

const Role = styled.div`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  color: ${({ theme }) => theme.colors.lightGrey};
`;

export {
  Container,
  Header,
  CardsContainer,
  ReviewCard,
  Review,
  Customer,
  Image,
  Detail,
  Name,
  Role,
};
