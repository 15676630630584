import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
const helper = new ThemeHelper();

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  column-gap: ${helper.getSpacing("small")};
  height: 100%;
  align-content: start;
`;

export { Grid };
