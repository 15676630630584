import { Formik } from "formik";
import { useSecurityDetails } from "hooks/Profile";
import { Button, LinkButton } from "components/core";
import { securityDetailsViews } from "config/Profile/securityDetailsViews";
import { TSecurityDetailsViewsMap } from "./types";
import ProfileSection from "./ProfileSection";
import ChangePasswordForm from "./ChangePasswordForm";
import { TChangePasswordPayload } from "adapters/Profile/types";

const FORM_ID = "ChangePassword";

interface IChangePasswordProps {
  onChangeView: (view: TSecurityDetailsViewsMap) => void;
}

const initialValues: TChangePasswordPayload = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePassword = (props: IChangePasswordProps) => {
  const securityDetails = useSecurityDetails();

  return (
    <ProfileSection>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          securityDetails.handleChangePassword(values);
          resetForm();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <ProfileSection.Actions>
              <Button
                type="submit"
                variant="alpha"
                size="small"
                content="Save"
                form={FORM_ID}
              />
              <LinkButton
                content="Go Back"
                onClick={() =>
                  props.onChangeView(securityDetailsViews.twoFactorAuth)
                }
              />
            </ProfileSection.Actions>
            <ProfileSection.Content>
              <ChangePasswordForm formId={FORM_ID} onSubmit={handleSubmit} />
            </ProfileSection.Content>
          </>
        )}
      </Formik>
    </ProfileSection>
  );
};

export default ChangePassword;
