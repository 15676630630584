import { TTweetSearchScheduleRetweetPayload } from "api/Twitter/types";
import { TwitterService } from "services/Auth";

type TAPIResponse = {
  message: string;
};

type TPOSTPayload = {
  tweet_id: string;
  lead_username: string;
  datetime: Date;
};

class TweetSearchScheduleRetweetAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = ({
    tweetId,
    twitterUsername,
    datetime,
  }: TTweetSearchScheduleRetweetPayload) => {
    const payload: TPOSTPayload = {
      tweet_id: tweetId,
      lead_username: twitterUsername,
      datetime,
    };
    return payload;
  };

  parsePostResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default TweetSearchScheduleRetweetAdapter;
