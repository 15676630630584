import styled from "styled-components";
import { Menu } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const StyledItem = styled(Menu.Item)`
  &&&&&& {
    font-family: ${({ theme }) => theme.fonts.poppins};
    color: ${({ theme }) => theme.colors.lightGrey};
    font-size: 14px;
    padding: ${helper.getSpacing("extraSmall")} 0;
    margin-right: ${helper.getSpacing("small")};
    &.active {
      ${helper.getFontWeight("medium")}
      border-bottom: 2px solid ${({ theme }) => theme.colors.alpha};
      border-radius: unset;
      color: ${({ theme }) => theme.colors.alpha};
      background-color: unset;
    }
    &:hover {
      background-color: unset;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export { StyledItem };
