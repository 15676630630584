import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const EventBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.2rem;
  align-items: center;
  height: 20%;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid
    ${({ theme }) => helper.lighten(theme.colors.lightGrey, "medium")};
  svg {
    height: 20px;
  }
`;

const Text = styled.p`
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export { EventBox, Text };
