import Dimmer from "../Dimmer";
import { useOnClickOutside } from "hooks";
import { Container, CloseIcon } from "./ModalView.styles";
import { IModalViewProps } from "./types";

const ModalView = ({
  open,
  onOutsideClick,
  onClose,
  children,
  ...props
}: IModalViewProps) => {
  const handleOutsideClick = () => {
    if (onOutsideClick) onOutsideClick();
  };

  const modalViewRef = useOnClickOutside<HTMLDivElement>(handleOutsideClick);

  if (!open) return null;

  return (
    <Dimmer>
      <Container ref={modalViewRef} {...props}>
        {onClose && <CloseIcon onClick={onClose} />}
        {children}
      </Container>
    </Dimmer>
  );
};

export default ModalView;
