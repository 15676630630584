import { ISingleKeywordSearchFormikValues } from "context/ProductDemand/types";

const singleKeywordSearchFieldsMap: {
  [prop in keyof ISingleKeywordSearchFormikValues]: string;
} = {
  keyword: "keyword",
  startDate: "startDate",
  endDate: "endDate",
  country: "country",
} as const;

export default singleKeywordSearchFieldsMap;
