import { createContext, useEffect } from "react";
import { ISearchDemandData } from "adapters/ProductDemand/types";
import { useReactQuery, useBoolean } from "hooks/common";
import { fetchSearchDemand } from "api/ProductDemand";
import {
  usePreferences,
  useRecommendation,
  useSearchDemandForecast,
} from "hooks/ProductDemand";
import { ISingleKeywordSearchFormikValues } from "./types";
import { Loader } from "components/common";
import { productSearchDemand } from "config/ProductDemand";

type TSearchDemandContext = {
  data: ISearchDemandData;
  handleSearch: (payload: ISingleKeywordSearchFormikValues) => void;
};

export const SearchDemandContext = createContext<TSearchDemandContext | null>(
  null
);

const initialData: ISearchDemandData = {
  graph: [],
  recommendation: [],
  graphMeta: {
    maxAxisValue: 0,
  },
};

const SearchDemandProvider = (props: React.PropsWithChildren<{}>) => {
  const [initialized, setInitializedTrue] = useBoolean();
  const preferences = usePreferences();
  const recommendation = useRecommendation();
  const searchDemandForecast = useSearchDemandForecast();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchSearchDemand, {
    onSuccess: (data) => recommendation.setRecommendation(data.recommendation),
  });

  const handleSearch = (payload: ISingleKeywordSearchFormikValues) => {
    execute(payload);
    searchDemandForecast.setShowGraphFalse();
  };

  useEffect(() => {
    if (
      preferences.data.currentCountry &&
      preferences.data.endDate &&
      preferences.data.startDate &&
      preferences.data.keyword &&
      !initialized
    ) {
      execute({
        country: preferences.data.currentCountry,
        keyword: preferences.data.keyword,
        startDate: preferences.data.startDate,
        endDate: preferences.data.endDate,
      });
      setInitializedTrue();
    }
  }, [
    execute,
    preferences.data.keyword,
    preferences.data.currentCountry,
    preferences.data.endDate,
    preferences.data.startDate,
    initialized,
    setInitializedTrue,
  ]);

  return (
    <SearchDemandContext.Provider value={{ data, handleSearch }}>
      <Loader textData={productSearchDemand} hidden={!isLoading} />
      {props.children}
    </SearchDemandContext.Provider>
  );
};

export default SearchDemandProvider;
