import React from "react";
import Container from "./Container";
import Header from "./Header";
import Description from "./Description";
import StatBody from "./StatBody";
import Stat from "./Stat";

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: string;
  description?: string;
  active?: boolean;
  children?: React.ReactNode;
}

const InfoCard = ({ header, description, children, ...rest }: IProps) => {
  if (children) {
    return <Container {...rest}>{children}</Container>;
  }
  return (
    <Container {...rest}>
      <Header>{header}</Header>
      <Description variant="alpha">{description}</Description>
    </Container>
  );
};

InfoCard.defaultProps = {
  active: false,
};

InfoCard.Header = Header;
InfoCard.Description = Description;
InfoCard.StatBody = StatBody;
InfoCard.Stat = Stat;

export default InfoCard;
