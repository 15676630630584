import { dolphynAPI } from "api/instances";
import { links, constants } from "config/common";
import LiveTrendsAdapter from "adapters/common/liveTrends";
import { APIError } from "errors";

const URL = links.liveTrendsUrl;
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new LiveTrendsAdapter();

const liveTrends = {
  get: async () => {
    try {
      const response = await dolphynAPI(URL);
      const data = adapter.parseGetResponse(response.data);
      return data;
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default liveTrends;
