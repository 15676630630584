import { dolphynAPI } from "api/instances";
import { throwApiError } from "errors";
import { constants } from "config/Auth";
import { SearchConsoleService } from "services/WebAnalytics";

export type TSite = {
  text: string;
  value: string;
};

export interface ILoginSearchConsoleData {
  sites: TSite[];
  userEmail: string;
  profilePicUrl: string;
}

const searchConsoleService = new SearchConsoleService();

const loginGoogleSearchConsole = async (redirectUrl: string) => {
  try {
    const { data } = await dolphynAPI.post(
      `/analytics/v1/public/gsc/authenticate`,
      {
        google_redirect: redirectUrl,
      }
    );

    const credentials = {
      token: data.token as string,
      refresh: data.refresh_token as string,
    };

    const userEmail = data.analytics_email;
    const profilePicUrl = data.profile_url;

    const sites: TSite[] = data.sites;

    const sitesData = {
      sites,
      userEmail,
      profilePicUrl,
    } as ILoginSearchConsoleData;

    searchConsoleService.setAuthCookie(credentials);
    searchConsoleService.setSites(sitesData);

    return sitesData;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginGoogleSearchConsole;
