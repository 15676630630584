import { TAllPlans } from "./types";

type TAPIResponse = {
  all_plans: TAllPlans[];
};

class AllPlansAdapter {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      allPlans: apiResponse.all_plans,
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default AllPlansAdapter;
