import styled, { css } from "styled-components";
import { Icon } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const titleStyles = css`
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("extraSmall")};
  ${helper.getFontWeight("medium")};
  margin: unset;
`;

type TContainerProps = {
  active?: boolean;
};

const Container = styled.div<TContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${helper.getSpacing("small")};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 10px;
  padding: ${helper.getSpacing("extraSmall")};
  width: 100%;
  ${({ active, theme: { colors } }) => {
    if (active)
      return css`
        border-color: ${colors.alpha};
        background-color: ${colors.beta};
      `;
  }}
  ${({ onClick }) => {
    if (onClick)
      return css`
        cursor: pointer;
      `;
  }}
`;

const Image = styled.img`
  border-radius: 50%;
  width: 25px;
  height: 25px;
`;

const Text = styled.div`
  ${helper.centerContent()};
  flex-direction: column;
`;

const Title = styled.div`
  ${titleStyles}
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubTitle = styled.div`
  ${titleStyles}
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const DeleteIcon = styled(Icon).attrs({
  name: "trash",
})`
  &&& {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-size: 1rem;
    cursor: pointer;
  }
`;

interface IAccountProps
  extends TContainerProps,
    React.ComponentPropsWithoutRef<"div"> {
  title?: string;
  subtitle?: string;
  image?: string;
  active?: boolean;
  onDelete?: () => void;
}

const Account = ({
  title,
  subtitle,
  image,
  onDelete,
  ...props
}: IAccountProps) => (
  <Container {...props}>
    {image && <Image src={image} alt="account-image" />}
    <Text>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Text>
    {onDelete && (
      <DeleteIcon
        onClick={(e: React.MouseEvent<SVGElement>) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    )}
  </Container>
);

export default Account;
