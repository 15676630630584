import { TwitterService } from "services/Auth";
import { TLeadDMPayload } from "./types";

type TAPIPayload = {
  message: string;
  files: any;
};

type TAPIResponse = {
  message: string;
};

class DMLeadAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    if (apiResponse.message) {
      return {
        message: apiResponse.message,
      };
    }
  };

  getPostUrl = (url: string, payload: TLeadDMPayload) =>
    `${url}/${payload.leadUsername}`;
  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });
  getPostPayload = ({ message, files = [] }: TLeadDMPayload): TAPIPayload => {
    return {
      message,
      files,
    };
  };

  parsePostResponse = this.parseResponse;
}

export default DMLeadAdapter;
