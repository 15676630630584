import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import FacebookService, { TUid } from "services/Auth/facebookService";

const service = new FacebookService();

const selectedAccount = service.getSelectedAccount();
const pages = service.getPages(selectedAccount.uid);

const initialState = {
  uid: selectedAccount.uid,
  selectedAccount: selectedAccount,
  selectedAccountPages: pages,
};

export const facebookSelection = createSlice({
  name: "facebookSelection",
  initialState,
  reducers: {
    reInitialize: (state) => {
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
      state.selectedAccountPages = service.getPages(selectedAccount.uid);
    },
    selectAccount: (state, action: PayloadAction<TUid>) => {
      service.setSelectedAccount(action.payload);
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
      state.selectedAccountPages = service.getPages(selectedAccount.uid);
    },
    resetSelection: (state) => {
      service.removeSelectedAccount();
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
      state.selectedAccountPages = [];
    },
  },
});

export const { reInitialize, selectAccount, resetSelection } =
  facebookSelection.actions;

export default facebookSelection.reducer;
