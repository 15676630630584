import { applicationRoutes } from "config/common";
import { originUrl } from "helpers/UrlInstances";

const useWindowRedirect = () => {
  const redirectRelative = (
    to: (routes: typeof applicationRoutes) => string
  ) => {
    const url = originUrl.append(to(applicationRoutes));
    window.location.href = url;
  };

  const redirectAbsolute = (to: string) => {
    window.location.href = to;
  };

  const redirectExternal = (to: string) => {
    window.open(to);
  };

  return { redirectRelative, redirectAbsolute, redirectExternal };
};

export default useWindowRedirect;
