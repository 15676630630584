import styled from "styled-components";

const Toolbar = styled.div`
  border-radius: 0 0 10px 10px;
  min-height: 50px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 0.5rem;

  .file-icon {
    color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`;

export { Toolbar };
