import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Twitter";
import { LeadsAdapter } from "adapters/Twitter";
import { APIError } from "errors";
import { IPotentialCustomerPayload } from "adapters/Twitter/types";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/potential_customers");
const adapter = new LeadsAdapter();

const leads = async (values: IPotentialCustomerPayload) => {
  try {
    if (values.isAuthenticated) {
      const apiPayload = adapter.getGetPayload(values);
      const { headers } = adapter.getConfig();
      const { data } = await dolphynAPI(URL, { params: apiPayload, headers });
      return adapter.parseGetResponse(data);
    }
    const apiPayload = adapter.getGetPayload(values);
    const { data } = await dolphynAPI(URL, { params: apiPayload });
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default leads;
