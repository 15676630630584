import styled from "styled-components";

const Container = styled.div`
  flex: 1;
`;

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return <Container>{props.children}</Container>;
};

export default ModuleLayout;
