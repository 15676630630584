import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  padding: ${helper.getSpacing("small")};
  gap: ${helper.getSpacing("small")};
  flex-wrap: wrap;
  justify-content: center;
`;

export { Container };
