import { v4 as uuid } from "uuid";
import { Linkify, List as ListItems } from "components/core";
import { useRecommendation } from "hooks/ProductDemand";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setShowRecommendation } from "redux/slices/ProductDemand";
import { Message, Portal } from "semantic-ui-react";

const Recommendation = (props: React.ComponentPropsWithoutRef<"div">) => {
  const { data } = useRecommendation();

  const isOpen = useAppSelector(
    (state) => state.productDemand.recommendation.showRecommendation
  );
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setShowRecommendation(false));
  };

  const renderListItems = () => {
    return data.map(({ text }) => (
      <ListItems.Item key={uuid()} style={{ fontSize: "14px" }}>
        <Linkify>{text}</Linkify>
      </ListItems.Item>
    ));
  };

  return (
    <Portal open={isOpen} onClose={onClose}>
      <Message
        onDismiss={onClose}
        style={{
          position: "fixed",
          left: "15%",
          top: "8%",
          zIndex: 99,
          maxWidth: "70vw",
        }}
      >
        {renderListItems()}
      </Message>
    </Portal>
  );
};

export default Recommendation;
