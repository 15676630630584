import { TwitterService } from "services/Auth";
import { IRecentTweetsPayload, TRecentTweets } from "./types";

type TAPIResponse = {
  leadTweets: TRecentTweets[];
  message: string;
};

class RecentTweetsAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      leadTweets: apiResponse.leadTweets,
      message: apiResponse.message,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostUrl = (url: string, tweetCard: IRecentTweetsPayload) =>
    `${url}/${tweetCard.leadUsername}`;

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default RecentTweetsAdapter;
