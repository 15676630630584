import { CardSection } from "components/common";
import { Button } from "components/core";
import { useNavigation } from "hooks/common";
import TopIcons from "./TopIcons";
import { ICallToActionCardData } from "./types";

const CallToActionCard = ({
  data,
  variant = "alpha",
  onActionClick,
  ...props
}: ICallToActionCardData) => {
  const nav = useNavigation();

  if (!data) {
    return <div {...props}>{props.children}</div>;
  }

  return (
    <div {...props}>
      <TopIcons data={data.topIcons} />
      {data.image ? (
        <CardSection
          header={data.header}
          description={data.description}
          media={<img src={data.image} alt={`connect-${data.header}`} />}
          variant={variant}
          content={
            <Button
              variant={variant}
              content={data.action}
              onClick={() => onActionClick && nav.navigate(onActionClick)}
            />
          }
        />
      ) : (
        <CardSection
          header={data.header}
          description={data.description}
          media={
            <iframe
              src={data.video}
              title={`connect-${data.header}`}
              frameBorder={0}
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          }
          variant={variant}
          content={
            <Button
              variant={variant}
              content={data.action}
              onClick={() => onActionClick && nav.navigate(onActionClick)}
            />
          }
        />
      )}
    </div>
  );
};

CallToActionCard.TopIcons = TopIcons;
CallToActionCard.CardSection = CardSection;

export default CallToActionCard;
