import { MenuItemProps } from "semantic-ui-react";
import { useNavigation } from "hooks";
import { StyledItem } from "./MenuItem.styles";

interface IItemProps extends MenuItemProps {
  routerPath?: string;
}

const Item = ({ routerPath, ...props }: IItemProps) => {
  const nav = useNavigation();
  if (routerPath) {
    return (
      <StyledItem
        active={nav.location.pathname.includes(routerPath)}
        onClick={() => nav.navigate(() => routerPath)}
        {...props}
      />
    );
  }
  return <StyledItem {...props} />;
};

export default Item;
