import { createContext, useEffect } from "react";
import { Loader } from "components/common";
import { keywordsDetailsApi } from "api/Profile";
import { setPrimaryKeyword, setSecondaryKeywords } from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TKeywordsDetails } from "api/Profile/keywordsDetails";
import { keywordDetailsLoader } from "config/Profile";

type TKeywordsDetailsContext = {
  isLoading: boolean;
  handleUpdate: (data: Partial<TKeywordsDetails>) => void;
  fetch: () => void;
  details: TKeywordsDetails;
  handleUpdateAsync: (
    data: Partial<TKeywordsDetails>
  ) => Promise<TKeywordsDetails | undefined>;
};

export const KeywordsDetailsContext =
  createContext<null | TKeywordsDetailsContext>(null);

const KeywordsDetailsProvider = (props: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();
  const details = useAppSelector((state) => state.profile.keywordsDetails);

  const { execute: fetch, isLoading: fetching } = useReactQuery(
    keywordsDetailsApi.get,
    {
      onSuccess: (data) => {
        dispatch(setPrimaryKeyword(data.primaryKeyword));
        dispatch(setSecondaryKeywords(data.secondaryKeywords));
      },
    }
  );

  const {
    execute: handleUpdate,
    executeAsync: handleUpdateAsync,
    isLoading: updating,
  } = useReactQuery(keywordsDetailsApi.patch, {
    onSuccess: (data) => {
      dispatch(setPrimaryKeyword(data.primaryKeyword));
      dispatch(setSecondaryKeywords(data.secondaryKeywords));
    },
  });

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  const isLoading = fetching || updating;

  return (
    <KeywordsDetailsContext.Provider
      value={{ handleUpdate, handleUpdateAsync, details, fetch, isLoading }}
    >
      <Loader textData={keywordDetailsLoader} hidden={!isLoading} />
      {props.children}
    </KeywordsDetailsContext.Provider>
  );
};

export default KeywordsDetailsProvider;
