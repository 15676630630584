import { css } from "styled-components";
import { TFontSizes, TFontWeights, TLineHeights } from "./types";

class Font {
  private fontSizes: TFontSizes = {
    extraSmall: 80,
    small: 100,
    medium: 140,
    large: 180,
    extraLarge: 220,
    huge: 260,
    none: 0,
  };

  private fontWeights: TFontWeights = {
    low: 400,
    medium: 500,
    high: 700,
  };

  private lineHeights: TLineHeights = {
    small: 100,
    medium: 130,
    large: 160,
  };

  getFontSize = (value: keyof TFontSizes = "small") => {
    return css`
      font-size: ${this.fontSizes[value] / 100 + "rem"};
    `;
  };

  getLineHeight = (value: keyof TLineHeights = "small") => {
    return css`
      line-height: ${this.lineHeights[value] + "%"};
    `;
  };

  getFontWeight = (value: keyof TFontWeights = "low") => {
    return css`
      font-weight: ${this.fontWeights[value]};
    `;
  };
}

export default Font;
