import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const StyledLabel = styled.label`
  display: block;
  ${helper.getFontSize("extraSmall")};
  color: ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: ${helper.getSpacing("extraSmall")};
`;

interface IFormLabelProps extends React.ComponentPropsWithoutRef<"label"> {
  content?: string;
}

const Label = ({ content, children, ...props }: IFormLabelProps) => {
  return <StyledLabel {...props}>{content || children}</StyledLabel>;
};

export default Label;
