import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: grid;
  max-width: 768px;
  grid-template-columns: 15fr 1fr;
  column-gap: ${helper.getSpacing("small")};

  @media screen and (max-width: 768px) {
    column-gap: ${helper.getSpacing("extraSmall")};
  }
`;

export { Container };
