type TAPIPayload = {
  email: string;
  password: string;
};

type TAPIResponse = {
  message: string;
};

class EnterpriseAdapter {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  getPostPayload = (data: TAPIPayload): TAPIPayload => {
    return {
      email: data.email,
      password: data.password,
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default EnterpriseAdapter;
