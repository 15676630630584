class ObjectHelper {
  removeEmptyStringValues = <T extends Record<string, any>>(data: T) => {
    const newData: Record<string, any> = {};
    Object.entries(data).forEach(([key, val]) => {
      if (!!val) {
        newData[key] = val;
      }
    });

    return newData as Partial<T>;
  };
}

export default ObjectHelper;
