import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRecentTweets } from "adapters/Twitter/types";

const initialState = {
  data: [] as TRecentTweets[],
};

export const recentTweets = createSlice({
  name: "recentTweets",
  initialState,
  reducers: {
    setRecentTweets: (state, action: PayloadAction<TRecentTweets[]>) => {
      return { ...state, data: action.payload };
    },
    replaceTweet: (state, action: PayloadAction<TRecentTweets>) => {
      return {
        ...state,
        data: state.data.map((tweetCard) =>
          action.payload.tweetId === tweetCard.tweetId
            ? action.payload
            : tweetCard
        ),
      };
    },
  },
});

export const { setRecentTweets, replaceTweet } = recentTweets.actions;

export default recentTweets.reducer;
