import { useNotification } from "hooks/common";
import { TBaseDMObject, TBaseReplyObject } from "./types";
import { useDMLead, useFileUpload, useReplyTweet } from "hooks/Twitter";
import { useTwitterAuth } from "hooks/Auth";

const useReplyHelper = () => {
  const fileUpload = useFileUpload();
  const replyTweet = useReplyTweet();
  const dmLead = useDMLead();
  const notification = useNotification();
  const { isSignedIn } = useTwitterAuth();

  const handleReply = <T extends TBaseReplyObject>(values: T) => {
    if (values.files && Array.from(values.files).length > 0) {
      return fileUpload.upload(values.files, {
        onSuccess: async (data) => {
          try {
            await replyTweet.executeAsync({
              files: data,
              message: values.text,
              tweetId: values.tweetId,
            });
            notification.notifySuccess("Reply sent");
          } catch (error) {}
        },
      });
    }
    replyTweet.execute({
      message: values.text,
      tweetId: values.tweetId,
    });
    notification.notifySuccess("Reply sent");
  };

  const handleDM = <T extends TBaseDMObject>(values: T) => {
    if (values.files && Array.from(values.files).length > 0) {
      return fileUpload.upload(values.files, {
        onSuccess: async (data) => {
          try {
            await dmLead.executeAsync(
              {
                files: data,
                message: values.text,
                leadUsername: values.leadUsername,
                isAuthenticated: isSignedIn,
              },
              {
                onSuccess: (message) => {
                  if (message) {
                    notification.notifySuccess(message.message);
                  }
                },
              }
            );
          } catch (error) {}
        },
      });
    }
    dmLead.execute(
      {
        message: values.text,
        leadUsername: values.leadUsername,
        isAuthenticated: isSignedIn,
      },
      {
        onSuccess: (message) => {
          if (message) {
            notification.notifySuccess(message.message);
          }
        },
      }
    );
  };

  return { handleReply, handleDM };
};

export default useReplyHelper;
