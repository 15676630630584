import { Container } from "./MenuItem.styles";

interface IMenuItemProps extends React.ComponentPropsWithoutRef<"div"> {
  title: string;
  active?: boolean;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const MenuItem = ({ title, icon: Icon, ...props }: IMenuItemProps) => {
  return (
    <Container {...props}>
      <Icon />
      <span>{title}</span>
    </Container>
  );
};

export default MenuItem;
