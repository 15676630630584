import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { InfluencersAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { IInfluencerPayload } from "adapters/Twitter/types";

const URL = links.baseTwitterApiUrl.append("/influencers");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new InfluencersAdapter();

const fetchInfluencers = async (payload: IInfluencerPayload) => {
  try {
    if (payload.isAuthenticated) {
      const apiPayload = adapter.getGetPayload(payload);
      const { headers } = adapter.getConfig();
      const { data } = await dolphynAPI(URL, { params: apiPayload, headers });
      return adapter.parseGetResponse(data);
    }

    if (payload) {
      const apiPayload = adapter.getGetPayload(payload);
      const { data } = await dolphynAPI(URL, { params: apiPayload });
      return adapter.parseGetResponse(data);
    }

    const { data } = await dolphynAPI(URL);
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchInfluencers;
