import { generalValidationSchema } from "config/common";
import * as Yup from "yup";

const signUpValidation = {
  schema: Yup.object({
    firstName: Yup.string().required("First Name is Required!"),
    lastName: Yup.string().required("Last Name is Required!"),
    username: Yup.string().required("Username is Required!"),
    email: generalValidationSchema.email,
    password: generalValidationSchema.password,
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match!"
    ),
    termsConditions: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions!"
    ),
  }),
};

export { signUpValidation };
