import { useState } from "react";
import { postPreviewMenuMap } from "config/SocialMediaPost";
import { Header } from "components/core";
import { Container, Body } from "./PostPreview.styles";
import { useScheduleFormContext } from "hooks/SocialMediaPost";
import Menu from "./Menu";
import { TPostPreviewMenu } from "./types";
import TwitterPreview from "./TwitterPreview";
import FacebookPreview from "./FacebookPreview";
import InstagramPreview from "./InstagramPreview";
import LinkedInPreview from "./LinkedInPreview";

const PostPreview = () => {
  const context = useScheduleFormContext();
  const [activeItem, setActiveItem] = useState<TPostPreviewMenu>(
    postPreviewMenuMap.twitter
  );

  const renderPreview = () => {
    switch (activeItem) {
      case postPreviewMenuMap.twitter:
        return (
          <TwitterPreview
            twitter={context.values.twitter}
            datetime={context.values.datetime}
            text={context.values.text}
            files={context.values.files}
          />
        );
      case postPreviewMenuMap.facebook:
        return (
          <FacebookPreview
            facebook={context.values.facebook}
            text={context.values.text}
            files={context.values.files}
          />
        );
      case postPreviewMenuMap.instagram:
        return (
          <InstagramPreview
            instagram={context.values.instagram}
            text={context.values.text}
            files={context.values.files}
          />
        );
      case postPreviewMenuMap.linkedIn:
        return (
          <LinkedInPreview
            linkedin={context.values.linkedin}
            text={context.values.text}
            files={context.values.files}
          />
        );
      default:
        break;
    }
  };

  return (
    <Container>
      <Header
        as="div"
        font="poppins"
        size="small"
        mb="small"
        content="Preview"
      />
      <Body>
        <Menu
          activeItem={activeItem}
          onChange={(value) => setActiveItem(value)}
        />
        {renderPreview()}
      </Body>
    </Container>
  );
};

export default PostPreview;
