import BaseUrlHelper, { TSearchParams } from "./BaseUrlHelper";
import { applicationRoutes } from "config/common";

type TAppendRouteCallback = (routes: typeof applicationRoutes) => string;

type TAddParamCallback = (routes: typeof applicationRoutes) => TSearchParams;

class UrlHelper extends BaseUrlHelper {
  append = (arg: string | TAppendRouteCallback) => {
    if (typeof arg === "string") {
      return super.append(arg);
    }
    const path = arg(applicationRoutes);
    return super.append(path);
  };
  addSearchParamsToUrl = (arg: TSearchParams | TAddParamCallback) => {
    let paramsObject = arg;
    if (typeof arg === "function") {
      paramsObject = arg(applicationRoutes);
      return super.addSearchParamsToUrl(paramsObject);
    }
    return super.addSearchParamsToUrl(paramsObject as TSearchParams);
  };
}

export default UrlHelper;
