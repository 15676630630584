import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("extraSmall")}
  color: ${({ theme }) => theme.colors.lightGrey};
  span {
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

export { Container };
