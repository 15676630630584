import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const AutoWelcomeContainer = styled.div`
  padding-inline-end: ${helper.getSpacing("medium")};
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
`;

export { AutoWelcomeContainer };
