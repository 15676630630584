import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Admin";
import { APIError } from "errors";
import FileAdapter, { TFileAPIPayload } from "adapters/Twitter/files";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseAdminApiUrl.append("/upload_csv");
const adapter = new FileAdapter();

const File = {
  post: async (payload: TFileAPIPayload) => {
    try {
      const apiPayload = adapter.getPostPayload(payload);
      const { data } = await dolphynAPI.post(URL, apiPayload);
      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default File;
