import { multiKeywordsSearchValidation } from "config/ProductDemand";
import { Formik } from "formik";
import { usePreferences } from "hooks/ProductDemand";
import { IMultiKeywordSearchFormikValues } from "./types";

interface IMultiKeywordsSearchProvider {
  onSubmit: (values: IMultiKeywordSearchFormikValues) => void;
}

const MultiKeywordsSearchProvider = (
  props: React.PropsWithChildren<IMultiKeywordsSearchProvider>
) => {
  const preferences = usePreferences();

  const handleFormikSubmit = (values: IMultiKeywordSearchFormikValues) => {
    props.onSubmit(values);
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={multiKeywordsSearchValidation}
      initialValues={{
        keywords: preferences.data.comparisonKeywords,
        country: preferences.data.currentCountry,
      }}
      onSubmit={handleFormikSubmit}
    >
      {() => props.children}
    </Formik>
  );
};

export default MultiKeywordsSearchProvider;
