import { TSecurityDetails } from "./types";

type TAPIResponse = {
  country_code: string;
  phone_number: string;
  two_factor_authentication: boolean;
  password: string;
};

class SecurityDetailsAdapter {
  private parseResponse = (data: TAPIResponse): TSecurityDetails => {
    const securityDetails: TSecurityDetails = {
      countryCode: data.country_code || "",
      phoneNumber: data.phone_number || "",
      twoFactorAuth: data.two_factor_authentication,
    };

    return securityDetails;
  };

  parseGetResponse = (data: TAPIResponse): TSecurityDetails => {
    return this.parseResponse(data);
  };

  getPatchPayload = (payload: Partial<TSecurityDetails>) => {
    const apiPayload: Partial<TAPIResponse> = {
      country_code: payload.countryCode,
      phone_number: payload.phoneNumber,
      two_factor_authentication: payload.twoFactorAuth,
    };

    return apiPayload;
  };
}

export default SecurityDetailsAdapter;
