import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { replaceTweet, setRecentTweets } from "redux/slices/Twitter";
import { TRecentTweets } from "adapters/Twitter/types";

const useRecentTweetRedux = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.twitter.recentTweets.data);

  return {
    data,
    setRecentTweets: (data: TRecentTweets[]) => dispatch(setRecentTweets(data)),
    replaceTweet: (data: TRecentTweets) => dispatch(replaceTweet(data)),
  };
};

export default useRecentTweetRedux;
