import { NavLink } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: ${helper.getSpacing("small")};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};

  svg {
    width: 16px;
    height: 16px;
  }

  &:first-child {
    svg {
      width: 16px;
      height: 24px;
    }
  }

  &.active,
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.alpha};
      }
    }
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

type Props = {
  title: string;
  path: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  styles?: CSSProperties;
};

const Navigator = ({ title, path, Icon, styles }: Props) => {
  return (
    <Link to={path} style={styles}>
      {Icon && <Icon />} {title}
    </Link>
  );
};

export default Navigator;
