import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Button = styled.button`
  ${helper.getFontSize("small")}
  color: ${({ theme }) => theme.colors.darkGrey};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  padding: ${helper.getSpacing("small")};
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.poppins};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${helper.getSpacing("extraSmall")};
  &:hover {
    background-color: ${({ theme }) => theme.colors.extraLightGrey};
  }
  svg {
    width: 25px;
    height: 25px;
    margin: unset;
  }
`;

interface ISocialButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  content?: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const SocialButton = ({
  content,
  children,
  icon: Icon,
  ...props
}: ISocialButtonProps) => {
  return (
    <Button {...props}>
      <Icon />

      {content || children}
    </Button>
  );
};

export default SocialButton;
