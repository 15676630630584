import { useEffect } from "react";
import { useReactQuery } from "hooks/common";
import { scheduleLinkedin as api } from "api/SocialMediaPost";
import { useLinkedinAuth } from "hooks/Auth";
import { useEventCalendar } from "hooks/common";
import { TLinkedinPostObject } from "adapters/SocialMediaPost/types";
import { useLinkedinPosts } from ".";

type TOptions = {
  onSuccess: (data: TLinkedinPostObject[]) => void;
};

const useLinkedinCalendar = (options?: TOptions) => {
  const lkAuth = useLinkedinAuth();
  const uid = lkAuth.selectedAccount.uid;
  const { execute, isLoading: fetching } = useReactQuery(api.fetchCalendar, {
    onSuccess: (data) => options?.onSuccess(data),
  });
  const { create, update, remove, isLoading } = useLinkedinPosts();
  const { startDate, endDate } = useEventCalendar();

  useEffect(() => {
    if (uid) {
      execute({
        uid,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [endDate, startDate, uid, execute]);

  return { create, update, remove, isLoading: fetching || isLoading };
};

export default useLinkedinCalendar;
