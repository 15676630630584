import twitterIcon from "assets/icons/common/twitter-icon.svg";
import connectCardImage from "assets/images/Twitter/twitter-call-to-action-illustration.png";

const data = {
  header: "Connect . Engage . Convert Audience",
  description:
    "Twitter has a million possibilities, make it right for you. Connect your Twitter account and follow as we assist you",
  action: "Connect With Twitter",
  topIcons: [
    {
      id: 1,
      src: twitterIcon,
      alt: "twitter-icon",
    },
  ],
  image: connectCardImage,
};

export default data;
