import { useState } from "react";
import { PostCard } from "../common";
import { useActivity } from "hooks/SocialMediaPost";
import { UpdatePostForm } from "../SchedulePost";
import { IScheduleUpdateFormValues } from "../SchedulePost/types";

interface ICardProps extends Omit<React.ComponentPropsWithoutRef<"div">, "id"> {
  name: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  profileImageUrl: string;
  formValues: IScheduleUpdateFormValues;
  editable?: boolean;
  deletable?: boolean;
}

const Card = (props: ICardProps) => {
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const {
    profileImageUrl,
    name,
    icon,
    style,
    formValues,
    editable,
    deletable,
  } = props;
  const activity = useActivity();

  return (
    <>
      <PostCard
        platformIcon={icon}
        accountImage={profileImageUrl}
        accountName={name}
        text={formValues.text}
        onEdit={editable ? () => setShowUpdateForm(true) : undefined}
        onDelete={
          deletable ? () => activity.handleDelete(formValues) : undefined
        }
        datetime={formValues.datetime}
        media={formValues.serverFiles}
        style={style}
      />
      <UpdatePostForm
        open={showUpdateForm}
        onClose={() => setShowUpdateForm(false)}
        formValues={formValues}
        onSubmit={(values) => {
          activity.handleUpdate(values);
          setShowUpdateForm(false);
        }}
      />
    </>
  );
};

export default Card;
