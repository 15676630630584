import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { TweetSearchScheduleRetweetAdapter } from "adapters/Twitter";
import { TTweetSearchScheduleRetweetPayload } from "./types";

const URL = links.baseTwitterApiUrl.append("/tweet/schedule_retweet");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new TweetSearchScheduleRetweetAdapter();

const tweetSearchScheduleRetweet = {
  postScheduleRetweet: async (values: TTweetSearchScheduleRetweetPayload) => {
    try {
      const headers = adapter.getConfig();
      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload, headers);

      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default tweetSearchScheduleRetweet;
