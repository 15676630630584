type TScriptAttribute = "id" | "type";

type TLoadScriptOptions = {
  onSuccess?: (script: HTMLScriptElement) => void;
  onError?: () => void;
  location?: "body" | "head";
  defer?: boolean;
  beforeInit?: () => void;
  additionalAttributes?: {
    [key in TScriptAttribute]: string;
  };
};

const useScriptLoader = () => {
  const load = async (src: string, options?: TLoadScriptOptions) => {
    const {
      onSuccess,
      location = "body",
      defer = false,
      onError,
      beforeInit,
      additionalAttributes,
    } = options || {};

    if (beforeInit) {
      beforeInit();
    }

    try {
      const result = await new Promise(
        (resolve: (value: HTMLScriptElement) => void, reject: () => void) => {
          const script = document.createElement("script");
          script.src = src;
          script.defer = defer;

          if (additionalAttributes) {
            Object.entries(additionalAttributes).forEach(([key, value]) => {
              script[key as TScriptAttribute] = value;
            });
          }

          script.onload = () => {
            resolve(script);
          };
          script.onerror = () => {
            reject();
          };
          document[location].appendChild(script);
        }
      );
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onError) {
        onError();
      }
    }
  };

  return { load };
};

export default useScriptLoader;
