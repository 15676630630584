import { StyledKeyword, Container } from "./Keywords.styles";

interface IKeywordProps {
  keywords: string[];
}

const Keywords = ({ keywords }: IKeywordProps) => {
  if (keywords.length === 0) return <StyledKeyword>No Keyword</StyledKeyword>;

  if (keywords.length <= 5)
    return (
      <>
        {keywords.map((keyword, key) => (
          <StyledKeyword key={key}>{keyword}</StyledKeyword>
        ))}
      </>
    );

  return (
    <Container>
      {keywords.map((keyword, key) => (
        <StyledKeyword key={key}>{keyword}</StyledKeyword>
      ))}
    </Container>
  );
};

export default Keywords;
