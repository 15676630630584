import { format } from "date-fns";
import DateTimeHelper from "./dateTimeHelper";

const helper = new DateTimeHelper();

export const relativeTime = (datetime: string) => {
  const newDateTime = new Date(datetime);

  const local = helper.getUTCAsLocal(newDateTime);

  const date = format(local, "MMM dd, yyyy");
  const time = format(local, "hh:mm aa");

  return `${date} ${time}`;
};

export const facebookPostTime = (datetime: string) => {
  const newDateTime = new Date(datetime);

  const date = format(newDateTime, "MMMM dd");
  const time = format(newDateTime, "hh:mm aa");

  return `${date} at ${time}`;
};

export const currentTime = (datetime: Date) => {
  const date = format(datetime, "MMM dd, yyyy");
  const time = format(datetime, "hh:mm aa");

  return `${date} ${time}`;
};
