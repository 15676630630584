import { useSimilarKeyword } from "hooks/ProductDemand";
import SingleKeywordSearchProvider from "./SingleKeywordSearchProvider";

const SimilarKeywordFormProvider = (props: React.PropsWithChildren<{}>) => {
  const similarKeyword = useSimilarKeyword();

  return (
    <SingleKeywordSearchProvider onSubmit={similarKeyword.handleSearch}>
      {props.children}
    </SingleKeywordSearchProvider>
  );
};

export default SimilarKeywordFormProvider;
