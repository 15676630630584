import UrlHelper from "../../helpers/BaseUrlHelper";

const dashboardPath = new UrlHelper("/dashboard");
const productDemandPath = new UrlHelper("/product-demand");
const webAnalyticsPath = new UrlHelper("/web-analytics");
const easyTwitterPath = new UrlHelper("/easy-twitter");
const socialMediaPostPath = new UrlHelper("/social-media-post");
const signUpPath = new UrlHelper("/signup");
const signInPath = new UrlHelper("/signin");
const resetPasswordPath = new UrlHelper("/reset-password");
const forgotPasswordPath = new UrlHelper("/forgot-password");
const profilePath = new UrlHelper("/profile");
const pricingPath = new UrlHelper("/pricing");
const adminPath = new UrlHelper("/admin-mail");
const nftTweetAnalytics = new UrlHelper("/nft-tweet-analytics");
const blogs = new UrlHelper("/blogs");
const loginEnterprise = new UrlHelper("/create-password");
const socialAccount = new UrlHelper("/social-account");
const contentCreationPath = new UrlHelper("/content-creation");

const rootRoutes = {
  home: "/",
  dashboard: dashboardPath.url,
  socialAccount: socialAccount.url,
  productDemand: productDemandPath.url,
  webAnalytics: webAnalyticsPath.url,
  easyTwitter: easyTwitterPath.url,
  socialMediaPost: socialMediaPostPath.url,
  signUp: signUpPath.url,
  signIn: signInPath.url,
  resetPassword: resetPasswordPath.url,
  forgotPassword: forgotPasswordPath.url,
  profile: profilePath.url,
  pricing: pricingPath.url,
  adminMail: adminPath.url,
  nft: nftTweetAnalytics.url,
  blog: blogs.url,
  loginEnterprise: loginEnterprise.url,
  contentCreation: contentCreationPath.url,
};

const webAnalytics = {
  authorizeGoogleAnalytics: webAnalyticsPath.append(
    "/connect/google-analytics/authorize"
  ),
  selectGoogleAnalytics: webAnalyticsPath.append(
    "/connect/google-analytics/select-view"
  ),
  authorizeGoogleSearchConsole: webAnalyticsPath.append(
    "/connect/google-search-console/authorize"
  ),
  selectGoogleSearchConsole: webAnalyticsPath.append(
    "/connect/google-search-console/select-site"
  ),
  webMatrix: webAnalyticsPath.append("/web-matrix"),
  pageMatrix: webAnalyticsPath.append("/page-matrix"),
  optimizer: webAnalyticsPath.append("/optimizer"),
  userProfiles: webAnalyticsPath.append("/user-profiles"),
  searchConsole: webAnalyticsPath.append("/search-console"),
};

const contentCreation = {
  aiContent: contentCreationPath.append("/ai-content"),
  evergreenTweet: contentCreationPath.append("evergreen-tweet"),
  scheduler: contentCreationPath.append("/scheduler"),
  activity: contentCreationPath.append("/activity"),
  calendar: contentCreationPath.append("/calendar"),
  liveTrends: contentCreationPath.append("/live-trends"),
};

const userProfile = {
  newUser: webAnalyticsPath.append("/user-profiles/new-user"),
  returningUser: webAnalyticsPath.append("/user-profiles/returning-user"),
  socialMediaUser: webAnalyticsPath.append("/user-profiles/social-media-users"),
};

const profile = {
  registration: profilePath.append("/registration"),
};

const easyTwitter = {
  leadGeneration: easyTwitterPath.append("/lead-generation"),
  brandMonitoring: easyTwitterPath.append("/brand-monitoring"),
  scheduleRetweets: easyTwitterPath.append("/schedule-retweets"),
  analytics: easyTwitterPath.append("/analytics"),
  audit: easyTwitterPath.append("/audit"),
  autoWelcome: easyTwitterPath.append("/auto-welcome"),
  influencers: easyTwitterPath.append("/influencers"),
};

const leadGeneration = {
  keywordListening: easyTwitterPath.append(
    "/lead-generation/keyword-listening"
  ),
  leads: easyTwitterPath.append("/lead-generation/leads"),
  leadNurturing: easyTwitterPath.append("/lead-generation/lead-nurturing"),
  admin: easyTwitterPath.append("/lead-generation/admin-engagement"),
};

const scheduleRetweets = {
  schedule: easyTwitterPath.append("/schedule-retweets/schedule"),
  influencers: easyTwitterPath.append("/schedule-retweets/influencers"),
};

const socialMediaPost = {
  schedulePost: socialMediaPostPath.append("/schedule-post"),
  activity: socialMediaPostPath.append("/activity"),
  calendar: socialMediaPostPath.append("/calendar"),
  liveTrends: socialMediaPostPath.append("/live-trends"),
};

const productDemand = {
  searchDemandPath: productDemandPath.append("/search-demand"),
  demandByCountryPath: productDemandPath.append("/demand-by-country"),
  similarKeywordPath: productDemandPath.append("/similar-keyword"),
  keywordComparisonPath: productDemandPath.append("/keyword-comparison"),
};

const pricing = {
  upgrade: pricingPath.append("/upgrade"),
  success: pricingPath.append("/success"),
  cancel: pricingPath.append("/cancel"),
};

const applicationRoutes = {
  rootRoutes,
  webAnalytics,
  userProfile,
  profile,
  productDemand,
  easyTwitter,
  leadGeneration,
  scheduleRetweets,
  socialMediaPost,
  contentCreation,
  pricing,
};

export default applicationRoutes;
