import { Form } from "components/core";

interface IChangePasswordFormProps {
  formId: string;
  onSubmit: () => void;
}

const ChangePasswordForm = ({ formId, onSubmit }: IChangePasswordFormProps) => {
  return (
    <Form>
      <Form.Form id={formId} onSubmit={onSubmit}>
        <Form.Field>
          <Form.Label content="Re-enter Old Password" />
          <Form.Input focused name="oldPassword" type="password" />
        </Form.Field>
        <Form.Field>
          <Form.Label content="New Password" />
          <Form.Input focused name="newPassword" type="password" />
        </Form.Field>
        <Form.Field>
          <Form.Label content="Confirm New Password" />
          <Form.Input focused name="confirmNewPassword" type="password" />
        </Form.Field>
      </Form.Form>
    </Form>
  );
};

export default ChangePasswordForm;
