import axios from "axios";

const rapidAPI = axios.create({
  baseURL: process.env.REACT_APP_RAPID_API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
    "X-RapidAPI-Host":
      "rewriter-paraphraser-text-changer-multi-language.p.rapidapi.com",
  },
});

export default rapidAPI;
