import { dolphynAPI } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";
import InstagramService, {
  TInstagramPage,
  TInstagramAccount,
  TInstagramPages,
} from "services/Auth/instagramService";

type TAPIResponse = {
  facebook_account_id: string;
  instagram_user_access_token: string;
  name: string;
  profile_image: string;
  page_list: {
    name: string;
    id: string;
    profile_image: string;
    facebook_page_name: string;
    facebook_page_id: string;
  }[];
};

const URL = links.BASE_AUTHENTICATION_API_URL.append("/instagram");

const service = new InstagramService();

const loginInstagram = async (redirectUrl: String) => {
  try {
    const response = await dolphynAPI.post(URL, {
      instagram_redirect: redirectUrl,
    });

    const data = response.data as TAPIResponse;
    const uid = service.getUid(data.facebook_account_id);

    const cookie: TInstagramAccount = {
      uid,
      tokenId: data.instagram_user_access_token,
      profileImageUrl: data.profile_image,
      name: data.name,
      id: data.facebook_account_id,
    };

    const pages: TInstagramPage[] = data.page_list.map((page) => ({
      name: page.name,
      id: page.id,
      profileImageUrl: page.profile_image,
      pageName: page.facebook_page_name,
      pageId: page.facebook_page_id,
    }));

    const pageList: TInstagramPages = {
      uid,
      pages,
    };

    service.setAuthCookie(cookie);
    service.setPages(pageList);

    return null;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginInstagram;
