import { v4 as uuid } from "uuid";
import { useTwitterAuth } from "hooks";
import SingleAccount from "./SingleAccount";
import { Container } from "./MultipleAccounts.styles";

const MultipleAccounts = () => {
  const { accounts } = useTwitterAuth();

  return (
    <Container>
      {accounts &&
        accounts.map((account) => (
          <SingleAccount key={uuid()} account={account} />
        ))}
    </Container>
  );
};

export default MultipleAccounts;
