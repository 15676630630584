import { v4 as uuid } from "uuid";
import { Button, Flex } from "components/core";
import {
  CustomSpinner,
  EmojiPicker,
  FileAttacher,
  Textbox,
} from "components/common";
import { socialMediaCharactersLimit } from "config/common";
import { usePostScheduler } from "hooks/Twitter";
import { Toolbar } from "../common/Common.styles";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import {
  addToThread,
  reinitializeThread,
  setConnectTwitter,
  setFiles,
  setTweetText,
} from "redux/slices/Twitter";
import { useFileUpload, useToggle, useTwitterAuth } from "hooks";
import SchedulePanel from "./SchedulePanel";
import Phrase from "./Phrase";

const Scheduler = () => {
  const {
    handlePostNow,
    handleScheduleThread,
    handleSchedulePost,
    handleTweetThread,
    isPosting,
    isScheduling,
  } = usePostScheduler();
  const { isSignedIn } = useTwitterAuth();
  const text = useAppSelector((state) => state.twitter.thread.tweetText);
  const files = useAppSelector((state) => state.twitter.scheduler.files);

  const [showPicker, togglePicker] = useToggle();

  const dispatch = useAppDispatch();
  const threads = useAppSelector((state) => state.twitter.thread.thread);
  const interval = useAppSelector((state) => state.twitter.thread.interval);

  const fileUpload = useFileUpload();

  const reinitialize = () => {
    dispatch(setFiles([]));
    dispatch(setTweetText(""));
  };

  const onAddToThread = () => {
    if (files.length) {
      return fileUpload.upload(files, {
        onSuccess: async (data) => {
          dispatch(
            addToThread({
              threadId: uuid(),
              message: text,
              files: data.map((file) => ({
                attachment_id: file.attachmentId,
                file_type: file.fileType,
                file_url: file.fileUrl,
                id: file.id,
                user: file.user,
              })),
            })
          );
          reinitialize();
        },
      });
    }
    dispatch(
      addToThread({
        threadId: uuid(),
        message: text,
        files: [],
      })
    );
    dispatch(setTweetText(""));
  };

  const onPostNow = () => {
    if (files.length) {
      return fileUpload.upload(files, {
        onSuccess: async (data) => {
          handlePostNow({
            postNow: true,
            isThread: false,
            message: text,
            files: data.map((file) => ({
              attachment_id: file.attachmentId,
              file_type: file.fileType,
              file_url: file.fileUrl,
              id: file.id,
              user: file.user,
            })),
          });
          reinitialize();
        },
      });
    }
    handlePostNow({
      postNow: true,
      isThread: false,
      message: text,
      files: files,
    });
    dispatch(setTweetText(""));
  };

  const onScheduleThread = (datetime: Date) => {
    handleScheduleThread({
      threads,
      datetime,
      interval,
    });
    dispatch(reinitializeThread());
  };

  const onSchedulePost = (datetime: Date) => {
    if (files.length) {
      return fileUpload.upload(files, {
        onSuccess: async (data) => {
          handleSchedulePost({
            datetime: datetime,
            message: text,
            files: data.map((file) => ({
              attachment_id: file.attachmentId,
              file_type: file.fileType,
              file_url: file.fileUrl,
              id: file.id,
              user: file.user,
            })),
          });
          reinitialize();
        },
      });
    }
    handleSchedulePost({
      message: text,
      datetime,
      files: files,
    });
    dispatch(setTweetText(""));
  };

  const onTweetNowAndTweetThread = () => {
    if (threads.length) {
      handleTweetThread({
        postNow: true,
        isThread: true,
        interval: interval,
        threads: threads,
      });
      dispatch(reinitializeThread());
    } else {
      onPostNow();
    }
  };

  return (
    <>
      <Textbox
        value={text}
        onChange={(e) => {
          if (e.target.value.length - 1 < socialMediaCharactersLimit.twitter) {
            dispatch(setTweetText(e.target.value));
          }
        }}
        style={{ position: "relative" }}
      >
        <Textbox.Toolbar
          style={{ justifyContent: "space-between", background: "unset" }}
        >
          <Toolbar>
            <EmojiPicker
              onSelect={(emoji) => dispatch(setTweetText(text + emoji))}
            />
            <FileAttacher onChange={(files) => dispatch(setFiles(files))} />
            <Phrase />
          </Toolbar>
          <Toolbar>
            <Textbox.WordLimit
              remaining={socialMediaCharactersLimit.twitter - text.length}
            />
          </Toolbar>
        </Textbox.Toolbar>
      </Textbox>
      <Flex justify="space-between" gap="none">
        <Button
          variant="alpha"
          size="mini"
          content="Add To Thread"
          onClick={() => {
            if (isSignedIn) onAddToThread();
            else dispatch(setConnectTwitter(true));
          }}
        />
        <Button
          variant="alpha"
          size="mini"
          content={
            isScheduling ? (
              <>
                <CustomSpinner size={10} /> Schedule
              </>
            ) : (
              "Schedule"
            )
          }
          onClick={() => {
            if (isSignedIn) togglePicker();
            else dispatch(setConnectTwitter(true));
          }}
        />
        <Button
          variant="alpha"
          size="mini"
          content={
            isPosting ? (
              <>
                <CustomSpinner size={10} /> Post Now
              </>
            ) : (
              "Post Now"
            )
          }
          onClick={() => {
            if (isSignedIn) onTweetNowAndTweetThread();
            else dispatch(setConnectTwitter(true));
          }}
        />
      </Flex>
      {showPicker && (
        <SchedulePanel
          onSubmit={(datetime) => {
            if (threads.length) {
              onScheduleThread(datetime);
            } else {
              onSchedulePost(datetime);
            }
          }}
          setPanelClose={() => togglePicker(false)}
        />
      )}
    </>
  );
};

export default Scheduler;
