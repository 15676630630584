import { Form } from "components/core";
import { enterpriseValidation } from "config/Profile";
import { Formik } from "formik";
import { useEnterprise } from "hooks/Profile";
import ProfileSection from "./ProfileSection";

const initialFormValues = {
  email: "",
  firstName: "",
  lastName: "",
};

const Enterprise = () => {
  const { handleInvite } = useEnterprise();

  return (
    <ProfileSection>
      <Formik
        initialValues={initialFormValues}
        validationSchema={enterpriseValidation}
        onSubmit={(values) => handleInvite(values)}
      >
        {({ handleSubmit }) => (
          <Form>
            <Form.Form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Label content="First Name" />
                <Form.Input name="firstName" type="text" />
              </Form.Field>
              <Form.Field>
                <Form.Label content="Last Name" />
                <Form.Input name="lastName" type="text" />
              </Form.Field>
              <Form.Field>
                <Form.Label content="Email" />
                <Form.Input name="email" type="email" />
              </Form.Field>
              <Form.Button
                variant="alpha"
                size="small"
                mt="small"
                type="submit"
                content="Invite"
              />
            </Form.Form>
          </Form>
        )}
      </Formik>
    </ProfileSection>
  );
};

export default Enterprise;
