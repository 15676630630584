import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const AnalyticsTweetGrid = styled.div`
  padding-inline-end: ${helper.getSpacing("medium")};
  display: grid;
  grid-template-columns: 4fr 5fr;
  column-gap: ${helper.getSpacing("medium")};
`;

const TweetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("extraSmall")};
  padding: 2px 4px;
  height: 76vh;
  overflow: auto;
  ${helper.getStyledScrollbar()};
`;

export { AnalyticsTweetGrid, TweetContainer };
