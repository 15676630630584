import { PostNowAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/SocialMediaPost";
import { APIError } from "errors";
import { IPostNowPayload } from "./types";

const error = new APIError();
const URL = links.socialMediaBaseUrl.append("/auth/post-now/twitter");
const adapter = new PostNowAdapter();

const PostNow = async (values: IPostNowPayload) => {
  try {
    const headers = adapter.getConfig();
    const apiPayload = adapter.getPostPayload(values);
    const { data } = await dolphynAPI.post(URL, apiPayload, headers);
    return data;
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default PostNow;
