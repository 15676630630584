import { Text } from "components/core";
import { Cell, Pie, PieChart } from "recharts";
import { useTheme } from "styled-components";
import { CardContainer } from "./CircularProgress.styles";

interface ICircularProgressProps {
  value: string;
  text: string;
}

const CircularProgress = ({ value, text }: ICircularProgressProps) => {
  const theme = useTheme();

  const _value = parseInt(value.substring(0, 2));

  const data = [
    { id: "1", name: "L1", value: _value },
    { id: "2", name: "L2", value: isNaN(_value) ? 100 : 100 - _value },
  ];

  return (
    <CardContainer>
      <Text
        content={text}
        size="extraSmall"
        style={{ textAlign: "center" }}
        mb="small"
      />
      <PieChart width={110} height={110}>
        <text
          x={55}
          y={55}
          textAnchor="middle"
          fontSize={25}
          dominantBaseline="middle"
        >
          {value}
        </text>
        <Pie
          data={data}
          dataKey="value"
          innerRadius="70%"
          outerRadius="100%"
          fill={theme.colors.darkPastelBlue}
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
          blendStroke
        >
          <Cell key="test" fill={theme.colors.alpha} />
        </Pie>
      </PieChart>
    </CardContainer>
  );
};

export default CircularProgress;
