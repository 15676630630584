import { Text } from "components/core";
import { useNavigation } from "hooks/common";
import { applicationRoutes } from "config/common";

type TAsProp = "a" | "button" | "span";

type TLinkButtonOwnProps<T extends TAsProp> = {
  as?: T;
  to?: (routes: typeof applicationRoutes) => string;
  content?: string;
};

type TLinkButtonProps<T extends TAsProp> = TLinkButtonOwnProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof TLinkButtonOwnProps<T>>;

const LinkButton = <T extends TAsProp = "button">({
  as,
  content,
  to,
  ...props
}: TLinkButtonProps<T>) => {
  const navigation = useNavigation();

  if (to) {
    return (
      <Text
        as={(as as React.ElementType) || "button"}
        variant="alpha"
        content={content}
        onClick={() => navigation.navigate(to)}
        {...props}
      />
    );
  }

  return (
    <Text
      as={(as as React.ElementType) || "button"}
      variant="alpha"
      content={content}
      {...props}
    />
  );
};

export default LinkButton;
