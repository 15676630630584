import { useFile, useTwitterAuth } from "hooks";
import { useAppSelector } from "redux/store/hooks";
import PreviewCard from "./PreviewCard";
import { Container } from "./PreviewList.styles";

const PreviewList = () => {
  const fileHandler = useFile("images-videos");

  const { isSignedIn } = useTwitterAuth();

  const text = useAppSelector((state) => state.twitter.thread.tweetText);
  const threads = useAppSelector((state) => state.twitter.thread.thread);
  const files = useAppSelector((state) => state.twitter.scheduler.files);

  return (
    <>
      {(threads || text) && (
        <Container>
          {threads.map((thread) => (
            <PreviewCard
              key={thread.threadId}
              threadId={thread.threadId}
              text={thread.message}
              files={thread.files}
              showLine={thread !== threads[threads.length - 1] || text}
            />
          ))}
          {text && isSignedIn && (
            <PreviewCard
              text={text}
              previewUrls={fileHandler.getPreviewUrls(files)}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default PreviewList;
