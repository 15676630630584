import { TPlanCard, IPricingCards } from "./types";

type TAPIResponse = {
  monthView: TPlanCard[];
  yearView: TPlanCard[];
};

class PricingAdapter {
  private parseResponse = (data: TAPIResponse): IPricingCards => ({
    monthView: data.monthView,
    yearView: data.yearView,
  });

  parseGetResponse = (data: TAPIResponse) => this.parseResponse(data);
}

export default PricingAdapter;
