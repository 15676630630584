import { Icon } from "components/core";
import { useToggle } from "hooks/common";
import { Container, StyledInput } from "./Input.styles";
import type { TInputContainerProps, TStyledInputProps } from "./Input.styles";

interface IInputProps
  extends TStyledInputProps,
    TInputContainerProps,
    React.InputHTMLAttributes<HTMLInputElement> {}

const Input = ({ style, icon, focused, ...inputProps }: IInputProps) => {
  const [showPassword, toggleShowPassword] = useToggle();
  const containerProps = { style, focused };

  const renderEyeButton = () => {
    if (showPassword) {
      return (
        <Icon name="eye" className="eye-icon" onClick={toggleShowPassword} />
      );
    }
    return (
      <Icon
        name="eye slash"
        className="eye-icon"
        onClick={toggleShowPassword}
      />
    );
  };

  if (inputProps.type === "password")
    return (
      <>
        <Container {...containerProps}>
          <StyledInput
            {...inputProps}
            type={showPassword ? "text" : "password"}
          />
          {renderEyeButton()}
        </Container>
      </>
    );

  return (
    <>
      <Container {...containerProps}>
        <StyledInput {...inputProps} />
        {icon}
      </Container>
    </>
  );
};

export default Input;
