import styled from "styled-components";
import { TStepsStyleProps } from "./types";

interface ITrackProps extends TStepsStyleProps {
  filledRatio: number;
  /* completedSteps - 1 / totalSteps - 1  */
}

const Track = styled.div<ITrackProps>`
  position: absolute;
  height: 0.5rem;
  width: 100%;
  background-color: ${({ theme, variant = "lavendar" }) =>
    theme.colors[variant]};
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    height: 0.5rem;
    transition: 0.4s width ease-in;
    width: ${({ filledRatio }) => filledRatio * 100 + "%"};
    background-color: ${({ theme, activeVariant = "darkPastelBlue" }) =>
      theme.colors[activeVariant]};
  }
`;

export default Track;
