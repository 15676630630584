import { useEffect } from "react";
import { Redirect, Route, useRouteMatch } from "react-router";
import { applicationRoutes as routes } from "config/common";
import SimpleRoute, { ISimpleRouteProps } from "controllers/common/SimpleRoute";
import { useRegistrationStatus } from "hooks/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useNavigation } from "hooks/common";

export interface IProtectedRouteProps extends ISimpleRouteProps {}

const ProtectedRoute = ({ children, ...props }: IProtectedRouteProps) => {
  const { isSignedIn } = useDolphynAuth();
  const registrationStatus = useRegistrationStatus();
  const navigation = useNavigation();
  const isRegistrationRoute = useRouteMatch(routes.profile.registration);

  useEffect(() => {
    if (!registrationStatus.isRegistrationComplete && !isRegistrationRoute) {
      navigation.navigate((routes) => routes.profile.registration);
    }
  }, [
    registrationStatus.isRegistrationComplete,
    isRegistrationRoute,
    navigation,
  ]);

  if (!isSignedIn) {
    return (
      <Route>
        <Redirect to={routes.rootRoutes.signIn} />
      </Route>
    );
  }

  if (registrationStatus.toBeFetched) return null;

  return <SimpleRoute {...props}>{children}</SimpleRoute>;
};

export default ProtectedRoute;
