import { useEffect } from "react";
import { Redirect, Route, useRouteMatch } from "react-router";
import { applicationRoutes as routes } from "config/common";
import SimpleRoute, { ISimpleRouteProps } from "controllers/common/SimpleRoute";
import { useRegistrationStatus } from "hooks/Profile";
import { useNavigation } from "hooks/common";
import { useAdmin } from "hooks/Admin";

export interface IAdminRouteProps extends ISimpleRouteProps {}

const AdminRoute = ({ children, ...props }: IAdminRouteProps) => {
  const { isAdmin } = useAdmin();
  const registrationStatus = useRegistrationStatus();
  const navigation = useNavigation();
  const isRegistrationRoute = useRouteMatch(routes.profile.registration);

  useEffect(() => {
    if (!registrationStatus.isRegistrationComplete && !isRegistrationRoute) {
      navigation.navigate((routes) => routes.profile.registration);
    }
  }, [
    registrationStatus.isRegistrationComplete,
    isRegistrationRoute,
    navigation,
  ]);

  if (!isAdmin) {
    return (
      <Route>
        <Redirect to={routes.rootRoutes.signIn} />
      </Route>
    );
  }

  if (registrationStatus.toBeFetched) return null;

  return <SimpleRoute {...props}>{children}</SimpleRoute>;
};

export default AdminRoute;
