import { useEffect, useState } from "react";
import { EmojiPicker, FileAttacher, Textbox } from "components/common";
import { Button, Flex } from "components/core";
import { textKeys, twitterDMCharacterLimit } from "config/Twitter";
import { useToggle } from "hooks";
import { useTextHelper } from "hooks/Twitter";
import { MessageEditor } from "../common";
import { Toolbar } from "./AutoWelcomeTextbox.styles";

interface Props {
  onSave: () => void;
  text: string;
  onTextChange: (value: string) => void;
  placeholder?: string;
  files: File[];
  onFilesChange: (files: File[]) => void;
}

const AutoWelcomeTextbox = ({
  text,
  files,
  placeholder,
  onSave,
  onTextChange,
  onFilesChange,
}: Props) => {
  const { text1Message, text2Message, text3Message, setTextMessage } =
    useTextHelper();

  const [editorText1, setEditorText1] = useState(text1Message);
  const [editorText2, setEditorText2] = useState(text2Message);
  const [editorText3, setEditorText3] = useState(text3Message);
  const [openEditor1, toggleEditor1] = useToggle();
  const [openEditor2, toggleEditor2] = useToggle();
  const [openEditor3, toggleEditor3] = useToggle();

  useEffect(() => {
    setEditorText1(text1Message);
    setEditorText2(text2Message);
    setEditorText3(text3Message);
  }, [text1Message, text2Message, text3Message]);

  return (
    <Textbox
      placeholder={placeholder}
      value={text}
      onChange={(e) => {
        if (e.target.value.length - 1 < twitterDMCharacterLimit) {
          onTextChange(e.target.value);
        }
      }}
      style={{ position: "relative" }}
    >
      <MessageEditor
        open={openEditor1}
        text={editorText1}
        onCancel={() => toggleEditor1(false)}
        onTextChange={(value) => setEditorText1(value)}
        onSave={() => {
          setTextMessage(textKeys.Text1, editorText1);
          toggleEditor1(false);
        }}
      />
      <MessageEditor
        open={openEditor2}
        text={editorText2}
        onCancel={() => toggleEditor2(false)}
        onTextChange={(value) => setEditorText2(value)}
        onSave={() => {
          setTextMessage(textKeys.Text2, editorText2);
          toggleEditor2(false);
        }}
      />
      <MessageEditor
        open={openEditor3}
        text={editorText3}
        onCancel={() => toggleEditor3(false)}
        onTextChange={(value) => setEditorText3(value)}
        onSave={() => {
          setTextMessage(textKeys.Text3, editorText3);
          toggleEditor3(false);
        }}
      />
      <Textbox.WordLimit remaining={twitterDMCharacterLimit - text.length} />
      <Textbox.Toolbar
        style={{ justifyContent: "space-between", background: "unset" }}
      >
        <Toolbar>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text1"
              size="mini"
              onClick={() => onTextChange(text + text1Message)}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="mini"
              onClick={() => toggleEditor1(true)}
            />
          </Flex>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text2"
              size="mini"
              onClick={() => onTextChange(text + text2Message)}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="mini"
              onClick={() => toggleEditor2(true)}
            />
          </Flex>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text3"
              size="mini"
              onClick={() => onTextChange(text + text3Message)}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="mini"
              onClick={() => toggleEditor3(true)}
            />
          </Flex>
          <EmojiPicker onSelect={(emoji) => onTextChange(text + emoji)} />
          <FileAttacher onChange={onFilesChange} />
        </Toolbar>
        <Toolbar>
          <Button variant="alpha" size="mini" content="Save" onClick={onSave} />
        </Toolbar>
      </Textbox.Toolbar>
    </Textbox>
  );
};

export default AutoWelcomeTextbox;
