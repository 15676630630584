import { TopSectionWrapper } from "./LayoutHeader.styles";
import { Header } from "components/core";
import { BaseModuleLayout } from "layout/common";
import { MultipleAccounts } from "components/Twitter/common";
import Recommendation, { ModalIcon } from "components/Twitter/Recommendation";

interface ITwitterLayoutHeaderProps {
  header: string;
}

const TwitterLayoutHeader = (props: ITwitterLayoutHeaderProps) => {
  return (
    <TopSectionWrapper>
      <BaseModuleLayout.TopSection>
        <Header
          font="playfair"
          variant="darkGrey"
          size="medium"
          content={props.header}
        />
        <ModalIcon />
        <MultipleAccounts />
      </BaseModuleLayout.TopSection>
      <Recommendation id="twitter-recommendation" />
    </TopSectionWrapper>
  );
};

export default TwitterLayoutHeader;
