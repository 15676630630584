import { Checkbox as SemanticToggle, CheckboxProps } from "semantic-ui-react";
import styled, { DefaultColors } from "styled-components";

type TBaseToggleProps = {
  variant: keyof DefaultColors;
};

const Component = styled(SemanticToggle).attrs({
  toggle: true,
})<TBaseToggleProps>`
  &&&&.ui.toggle.checkbox input:checked ~ label::before {
    background-color: ${({ theme, variant }) =>
      theme.colors[variant]} !important;
  }
`;

interface IToggleProps extends CheckboxProps {}

const Toggle = (props: IToggleProps) => {
  return <Component {...props} />;
};

export default Toggle;
