import { ReactComponent as ProductDemandIcon } from "assets/icons/SideBar/product-demand-icon.svg";
import { ReactComponent as SocialAccountIcon } from "assets/icons/SideBar/social-media-icon.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/SideBar/twitter-icon.svg";
import { ReactComponent as ContentCreationIcon } from "assets/icons/SideBar/bot.svg";
import { applicationRoutes as routes } from "../common";

const items = [
  {
    icon: ContentCreationIcon,
    path: routes.rootRoutes.contentCreation,
    title: "Content Creation",
  },
  {
    icon: SocialAccountIcon,
    path: routes.rootRoutes.socialAccount,
    title: "Social Account",
  },
  {
    icon: TwitterIcon,
    path: routes.easyTwitter.leadGeneration,
    title: "Easy Twitter",
  },
  {
    icon: ProductDemandIcon,
    path: routes.rootRoutes.productDemand,
    title: "Keyword Research",
  },
];

export default items;
