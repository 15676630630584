import { Button, Field, Icon, Input } from "components/core";
import { useBoolean } from "hooks/common";
import { useScheduleSecondaryPane } from "hooks/SocialMediaPost";
import { Container } from "./CampaignLink.styles";

interface ICampaignLinkProps {
  link: string;
}

const CampaignLink = ({ link }: ICampaignLinkProps) => {
  const [copied, setCopiedTrue] = useBoolean();
  const secondaryPane = useScheduleSecondaryPane();
  const handleCopy = () => {
    window.navigator.clipboard.writeText(link);
    setCopiedTrue();
  };

  const renderIcon = () => {
    if (copied) {
      return <Icon size="medium" name="check" />;
    }
    return (
      <Icon
        size="medium"
        name="copy"
        style={{ cursor: "pointer" }}
        onClick={handleCopy}
      />
    );
  };

  return (
    <Container>
      <Field>
        <Input value={link} readOnly icon={renderIcon()} />
      </Field>
      <Button
        content="Done"
        variant="alpha"
        onClick={() => secondaryPane.changePane((pane) => pane.preview)}
      />
    </Container>
  );
};

export default CampaignLink;
