import usePostReducer from "./usePostReducer";
import {
  TFacebookPostObject,
  TInstagramPostObject,
  TLinkedinPostObject,
  TTwitterPostObject,
} from "adapters/SocialMediaPost/types";

const usePostsStateMachine = () => {
  const facebook = usePostReducer<TFacebookPostObject>([]);
  const linkedin = usePostReducer<TLinkedinPostObject>([]);
  const twitter = usePostReducer<TTwitterPostObject>([]);
  const instagram = usePostReducer<TInstagramPostObject>([]);

  return {
    facebook,
    linkedin,
    twitter,
    instagram,
  };
};

export default usePostsStateMachine;
