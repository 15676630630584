import { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { ProductDemandRoute } from "controllers/ProductDemand";
import { ModuleLayout as ProductDemandModuleLayout } from "layout/ProductDemand";
import { Spinner } from "components/common";

const SearchDemand = lazy(() => import("pages/ProductDemand/SearchDemand"));
const DemandByCountry = lazy(
  () => import("pages/ProductDemand/DemandByCountry")
);
const SimilarKeyword = lazy(() => import("pages/ProductDemand/SimilarKeyword"));
const KeywordsComparison = lazy(
  () => import("pages/ProductDemand/KeywordsComparison")
);

const ProductDemand = () => {
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <Route exact path={routes.rootRoutes.productDemand}>
          <Redirect to={routes.productDemand.searchDemandPath} />
        </Route>
        <ProductDemandRoute
          layout={ProductDemandModuleLayout}
          exact
          path={routes.productDemand.searchDemandPath}
        >
          <SearchDemand />
        </ProductDemandRoute>
        <ProductDemandRoute
          layout={ProductDemandModuleLayout}
          exact
          path={routes.productDemand.demandByCountryPath}
        >
          <DemandByCountry />
        </ProductDemandRoute>
        <ProductDemandRoute
          layout={ProductDemandModuleLayout}
          exact
          path={routes.productDemand.similarKeywordPath}
        >
          <SimilarKeyword />
        </ProductDemandRoute>
        <ProductDemandRoute
          layout={ProductDemandModuleLayout}
          exact
          path={routes.productDemand.keywordComparisonPath}
        >
          <KeywordsComparison />
        </ProductDemandRoute>
      </Switch>
    </Suspense>
  );
};

export default ProductDemand;
