import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

const StyledKeyword = styled.span`
  padding: 0 ${helper.getSpacing("tiny")};
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.alpha};
  background: ${({ theme }) => theme.colors.antiFlashWhite};
  border-radius: ${helper.getSpacing("tiny")};
  ${helper.getFontSize("extraSmall")};
  ${helper.getFontWeight("medium")};
`;

export { StyledKeyword, Container };
