import { TwitterService } from "services/Auth";
import { IScheduledPostResponse, IScheduledPosts } from "./types";

type TAPIResponse = {
  message: string;
  scheduled_tweets: IScheduledPostResponse[];
};

type TAPIPayload = {
  tweets: string[];
  prompt: string;
  timezone: string;
};

type TPOSTAPIPayload = {
  tweets: string[];
  instruction: string;
  timezone: string;
};

class ScheduleForMonthAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IScheduledPosts => {
    return {
      message: apiResponse.message,
      scheduledPosts: apiResponse.scheduled_tweets.map((post) => ({
        message: post.message,
        postId: post.post_id,
        datetime: post.datetime,
      })),
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TAPIPayload): TPOSTAPIPayload => {
    return {
      tweets: data.tweets,
      instruction: data.prompt,
      timezone: data.timezone,
    };
  };

  getResonse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default ScheduleForMonthAdapter;
