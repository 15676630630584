import styled from "styled-components";
import { Menu as BaseMenu } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Menu = styled(BaseMenu)`
  &&& {
    gap: ${helper.getSpacing("medium")};
    overflow: scroll;
    ${helper.getStyledScrollbar(0.1)};

    @media screen and (max-width: 425px) {
      max-width: 100vw;
    }
  }
`;

export { Menu };
