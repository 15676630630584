import React from "react";
import { Grid, SideBarCol, BodyCol, Wrapper } from "./DolphynAppsLayout.styles";
import ReactTooltip from "react-tooltip";
import { Hamburger } from "components/Sidebar";

export interface ILayout {
  children: React.ReactNode;
}

const DolphynAppsLayout = ({ children }: ILayout) => {
  return (
    <Wrapper>
      <Grid className="tabletOrAbove">
        <SideBarCol>
          <Hamburger />
        </SideBarCol>
        <BodyCol className="bodyColumn">{children}</BodyCol>
      </Grid>
      <ReactTooltip className="custom-tooltip" />
    </Wrapper>
  );
};

export default DolphynAppsLayout;
