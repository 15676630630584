import { constants } from "config/common";
import { dolphynAPI } from "api/instances";
import { links } from "config/Twitter";
import { APIError } from "errors";
import { IRecentTweetsPayload } from "adapters/Twitter/types";
import { RecentTweetsAdapter } from "adapters/Twitter";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/lead_tweets");
const adapter = new RecentTweetsAdapter();

const fetchRecentTweets = async (values: IRecentTweetsPayload) => {
  try {
    const postUrl = adapter.getPostUrl(URL, values);
    const headers = adapter.getConfig();
    const { data } = await dolphynAPI(postUrl, headers);
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchRecentTweets;
