import { IAdminEngagementPayload } from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { IAdminEngagementResult } from "./types";

type TAPIPayload = {
  keywords: string[];
  match_number: number;
  like: boolean;
  reply: boolean;
  reply_text: string;
  files: {
    attachment_id: string;
    file_url: string;
    user: string;
  }[];
};

type TAPIDeleteResponse = {
  message: string;
};

interface TAPIResponse extends IAdminEngagementResult {}

class AdminEngagementAdapter {
  private twitter = new TwitterService();

  private parseResponse = (
    apiResponse: TAPIResponse
  ): IAdminEngagementResult => {
    return {
      id: apiResponse.id,
      twitterUsername: apiResponse.twitterUsername,
      name: apiResponse.name,
      imageUrl: apiResponse.imageUrl,
      keywords: apiResponse.keywords,
      matchNumber: apiResponse.matchNumber,
      like: apiResponse.like,
      reply: apiResponse.reply,
      replyText: apiResponse.replyText,
      files: apiResponse.files,
      enable: apiResponse.enable,
      tweetActions: apiResponse.tweetActions,
    };
  };

  private parseRemoveResponse = (apiResponse: TAPIDeleteResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: IAdminEngagementPayload): TAPIPayload => {
    const parseKeywords = (keywords: IAdminEngagementPayload["keywords"]) =>
      keywords.filter((keyword) => keyword !== "");

    return {
      keywords: parseKeywords(data.keywords),
      match_number: Number(data.matchNumber),
      like: data.like,
      reply: data.reply,
      reply_text: data.replyText,
      files: data.files,
    };
  };

  getParseResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);

  getDeleteResponse = (apiResponse: TAPIDeleteResponse) =>
    this.parseRemoveResponse(apiResponse);
}

export default AdminEngagementAdapter;
