import styled from "styled-components";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { ReactComponent as EmojiPickerIcon } from "assets/icons/common/emoji-picker.svg";
import { useToggle, useOnClickOutside } from "hooks/common";

const Container = styled.div`
  position: relative;
  svg {
    cursor: pointer;
  }
`;

const PickerContainer = styled.div`
  position: absolute;
  top: 100%;
  z-index: 3;
`;

interface IEmojiPickerProps {
  onSelect: (emoji: string) => void;
}

const EmojiPicker = ({ onSelect }: IEmojiPickerProps) => {
  const [showPicker, toggleShowPicker] = useToggle();

  const containerRef = useOnClickOutside<HTMLDivElement>(() =>
    toggleShowPicker(false)
  );
  const renderPicker = () => {
    if (showPicker)
      return (
        <PickerContainer>
          <Picker
            showPreview={false}
            title=""
            emoji=""
            onSelect={(data: any) => onSelect(data.native as string)}
          />
        </PickerContainer>
      );

    return null;
  };
  return (
    <Container ref={containerRef}>
      <EmojiPickerIcon onClick={() => toggleShowPicker()} />
      {renderPicker()}
    </Container>
  );
};

export default EmojiPicker;
