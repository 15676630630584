import React, { useEffect, useRef, useState } from "react";

interface ICreatePopup {
  url?: string;
  title?: string;
  height?: number;
  width?: number;
}

interface IProps {
  url: string;
  title?: string;
  expectedParam: string;
  onSuccess: (
    params: URLSearchParams,
    currentUrl?: string,
    ...args: any[]
  ) => void;
  onClose?: () => void;
  trigger: React.ReactElement;
}

const createPopup = ({
  url,
  title,
  height = 800,
  width = 600,
}: ICreatePopup) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );
  return externalPopup;
};

const AuthPopUpWindow = ({
  url,
  title = "",
  expectedParam,
  onSuccess,
  onClose,
  trigger,
}: IProps) => {
  const [externalWindow, setExternalWindow] = useState<Window | null>(null);
  const intervalRef = useRef<number>();
  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const onContainerClick = () => {
    setExternalWindow(
      createPopup({
        url,
        title,
      })
    );
  };

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const params = new URL(currentUrl).searchParams;
          if (!params.get(expectedParam)) {
            return;
          }
          onSuccess(params, currentUrl);
          externalWindow.close();
          clearTimer();
        } catch (error) {
          // eslint-ignore-line G
        } finally {
          if (!externalWindow || externalWindow.closed) {
            if (onClose) onClose();
            clearTimer();
          }
        }
      }, 100);
    }

    return () => {
      if (externalWindow) {
        clearTimer();
        externalWindow.close();
      }
      if (onClose) onClose();
    };
  }, [externalWindow, onClose, onSuccess, expectedParam]);

  return React.cloneElement(trigger, {
    onClick: onContainerClick,
  });
};

export default AuthPopUpWindow;
