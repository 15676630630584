import { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { RegistrationStepsProvider } from "context/Profile";
import { CompleteRegistrationRoute } from "controllers/Profile";
import { Spinner } from "components/common";

const CompleteRegistrationPage = lazy(
  () => import("pages/Profile/CompleteRegistration")
);

const Profile = () => {
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <CompleteRegistrationRoute path={routes.profile.registration}>
          <RegistrationStepsProvider>
            <CompleteRegistrationPage />
          </RegistrationStepsProvider>
        </CompleteRegistrationRoute>
      </Switch>
    </Suspense>
  );
};

export default Profile;
