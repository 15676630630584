import { BrandLogo } from "components/common";
import { useWindowRedirect } from "hooks/common";
import { links } from "config/Home";
import {
  Container,
  TopSection,
  SubContainer,
  Body,
} from "./TopLogoLayout.styles";

const Logo = () => {
  const { redirectAbsolute } = useWindowRedirect();

  return (
    <BrandLogo
      text="Dolphy"
      size="large"
      variant="alpha"
      onClick={() => redirectAbsolute(links.navbar.dolphy)}
    />
  );
};

const TopLogoLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Container>
      <SubContainer>
        <TopSection>
          <Logo />
        </TopSection>
        <Body>{props.children}</Body>
      </SubContainer>
    </Container>
  );
};

TopLogoLayout.Container = Container;
TopLogoLayout.Content = SubContainer;
TopLogoLayout.TopSection = TopSection;
TopLogoLayout.Body = Body;
TopLogoLayout.BrandLogo = Logo;

export default TopLogoLayout;
