import styled from "styled-components";
import { ReactComponent as LogoIcon } from "assets/icons/brand-logo.svg";
import { useWindowRedirect } from "hooks";
import { links } from "config/Home";

const Container = styled.div`
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const BrandLogo = () => {
  const { redirectAbsolute } = useWindowRedirect();

  return (
    <Container>
      <LogoIcon onClick={() => redirectAbsolute(links.navbar.dolphy)} />
    </Container>
  );
};

export default BrandLogo;
