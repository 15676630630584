type TAPIResponse = {
  message: string;
};

class RegistrationCompletedAdapter {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  parseGetResponse = (data: TAPIResponse) => {
    return this.parseResponse(data);
  };
}

export default RegistrationCompletedAdapter;
