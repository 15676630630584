import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: min(1000px, 75vw);
  background: ${({ theme }) => theme.colors.white};
  ${helper.getBorderRadius("small")};
  ${helper.getBoxShadow("extraLightGrey", { intensity: "high" })};
  padding: ${helper.getSpacing("small")} 0;
  justify-content: center;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  justify-content: center;
  height: 100%;
  padding: ${helper.getSpacing("extraSmall")};
  width: min(600px, 100%);
  margin: auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
`;

export { Container, OptionContainer, Content };
