import React from "react";
import { Link } from "react-router-dom";
import { Icon, Header, Container } from "semantic-ui-react";
import RoundedBox from "../RoundedBox/RoundedBox";
import CustomButton from "../CustomButton";
import "./ErrorPage.scss";

const ErrorPage = ({
  icon,
  message,
  redirectPath,
  button,
  buttonText,
  variant,
  onClick,
  ...rest
}) => {
  return (
    <RoundedBox variant={`error-page ${variant ? variant : ""}`} {...rest}>
      <Container>
        <Icon name={icon || `warning sign`} className="icon" />
        <Header content={message} textAlign="center" />

        {button ? (
          <CustomButton
            as={Link}
            to={redirectPath}
            variant="primary flat"
            text={buttonText || "Back to home"}
            onClick={onClick}
          />
        ) : null}
      </Container>
    </RoundedBox>
  );
};

export default ErrorPage;
