import * as Yup from "yup";

export default Yup.object({
  firstName: Yup.string().required("First name cannot be empty."),
  lastName: Yup.string().required("Last name cannot be empty."),
  username: Yup.string().required("Username cannot be empty."),
  timezone: Yup.string().required("Timezone cannot be empty."),
  country: Yup.string().required("Country cannot be empty."),
  email: Yup.string().email("Enter a valid email"),
});
