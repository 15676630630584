import * as dateFns from "date-fns";

class DateTimeHelper {
  dateFns = dateFns;
  getUTCAsLocal = (dateTimePayload: Date) => {
    const dateTime = new Date(dateTimePayload);
    dateTime.setMinutes(dateTime.getMinutes() + dateTime.getTimezoneOffset());
    return dateTime;
  };
}

export default DateTimeHelper;
