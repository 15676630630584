import { v4 as uuid } from "uuid";
import { Text } from "components/core";
import { usePostScheduler } from "hooks/Twitter";
import { ListContianer } from "./SentList.styles";
import SentThread from "./sentThread";

const SentList = () => {
  const { sentList } = usePostScheduler();

  if (!sentList.length)
    return (
      <Text
        variant="alpha"
        content="No Sent Tweets or Threads"
        style={{ textAlign: "center" }}
      />
    );

  return (
    <ListContianer>
      {sentList.map((tweet) => (
        <SentThread key={uuid()} threads={tweet.threads} />
      ))}
    </ListContianer>
  );
};

export default SentList;
