import styled from "styled-components";
import { styles } from "../Common";

const Container = styled.div`
  display: flex;
  gap: ${styles.helper.getSpacing("large")};
  ${styles.helper.containerize(90)}
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Content = styled.div`
  max-width: 500px;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.alpha};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getFontSize("small")}
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 60 + 4px);
  }
`;

const SubHeader = styled.h2`
  margin-top: 4rem;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  ${styles.helper.getLineHeight("medium")};
  ${styles.helper.getFontWeight("medium")};
  ${styles.helper.getFontSize("medium")};
  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 40 + 5px);
  }
  span {
    color: ${({ theme }) => theme.colors.alpha};
    ${styles.helper.getFontWeight("high")}
  }
`;

const Header = styled.h2`
  margin-bottom: 1rem;
  &,
  span {
    font-family: ${({ theme }) => theme.fonts.montserrat};
    ${styles.helper.getLineHeight("large")}
    ${styles.helper.getFontWeight("medium")}
    ${styles.helper.getFontSize("extraLarge")}
    @media screen and (max-width: 768px) {
      font-size: calc((50vw + 50vh) / 20 + 5px);
      line-height: initial;
    }
  }
  span {
    color: ${({ theme }) => theme.colors.alpha};
    ${styles.helper.getFontWeight("high")}
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: min(600px, 40vw);
  height: min(700px, 50vw);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: -15%;
  right: -15%;
  z-index: -1;
  @media screen and (max-width: 768px) {
    position: unset;
    width: 100%;
  }
`;

const Example = styled.small`
  display: block;
  color: ${({ theme }) => theme.colors.lightGrey};
  padding: ${`${styles.helper.getSpacing("small")} ${styles.helper.getSpacing(
    "medium"
  )}`};
  font-family: ${({ theme }) => theme.fonts.roboto};
  ${styles.helper.getFontSize("small")}

  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 60 + 4px);
    line-height: initial;
  }
`;

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

export {
  Container,
  Content,
  Title,
  SubHeader,
  Header,
  Image,
  Example,
  ImageContainer,
  ErrorText,
};
