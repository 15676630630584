import styled from "styled-components";

import { ReactComponent as LikeIcon } from "assets/icons/SocialMediaPost/facebook-like.svg";
import { ReactComponent as CommentIcon } from "assets/icons/SocialMediaPost/facebook-comment.svg";
import { ReactComponent as ShareIcon } from "assets/icons/SocialMediaPost/facebook-share.svg";

const Wrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid ${({ theme }) => theme.colors.extraLightGrey};

  svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const PostAction = () => {
  return (
    <Wrapper>
      <span>
        <LikeIcon /> Like
      </span>
      <span>
        <CommentIcon /> Comment
      </span>
      <span>
        <ShareIcon /> Share
      </span>
    </Wrapper>
  );
};

export default PostAction;
