import { Header, Text } from "components/core";
import { Container } from "./TextSection.styles";

interface TextSectionProps extends React.ComponentPropsWithoutRef<"div"> {
  header?: string;
  description?: string;
}

const TextSection = ({
  header,
  description,
  children,
  ...rest
}: TextSectionProps) => {
  const renderHeader = () => {
    if (header)
      return (
        <Header font="playfair" variant="darkGrey" size="medium" mb="small">
          {header}
        </Header>
      );
  };

  const renderDescription = () => {
    if (description)
      return (
        <Text font="poppins" variant="lightGrey" style={{ maxWidth: "80ch" }}>
          {description}
        </Text>
      );
  };

  return (
    <Container {...rest}>
      {renderHeader()}
      {renderDescription()}
      {children}
    </Container>
  );
};

TextSection.Header = Header;
TextSection.Description = Text;

export default TextSection;
