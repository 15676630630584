import { addDays, startOfWeek, setWeek, getWeek } from "date-fns";
import { range } from "lodash";

class CalendarWeeksHelper {
  getStartOfWeek = (date: Date) => {
    return startOfWeek(date);
  };

  getNextWeek = (date: Date) => setWeek(date, getWeek(date) + 1);

  getPreviousWeek = (date: Date) => setWeek(date, getWeek(date) - 1);

  getViewDates = (date: Date) => {
    /* 
            Returns only 7 dates starting from sunday for a given date for the view. Only pass date of current week to get 7 dates of week view. 
      */
    const firstDayOfWeek = startOfWeek(date);
    const weeks = range(0, 7).map((day) => addDays(firstDayOfWeek, day));

    return weeks;
  };
}

export default CalendarWeeksHelper;
