import { useField } from "formik";
import { useAppSelector } from "redux/store/hooks";
import { Text } from "components/core";
import { QuestionCard } from "../common";
import { useRegistrationSteps } from "hooks/Profile";
import occupationIllustration from "assets/images/Profile/occupation-page-illustration.png";
import { occupationFormData } from "config/Profile";
import ContentContainer from "../common/ContentContainer";
import { TCompleteRegistrationFormValues } from "./types";

interface IOccupationForm {
  name: string;
}

const OccupationForm = ({ name }: IOccupationForm) => {
  const [field, , helpers] =
    useField<TCompleteRegistrationFormValues["occupation"]>(name);
  const steps = useRegistrationSteps();
  const username = useAppSelector(
    (state) => state.profile.personalDetails.username
  );

  const handleUpdate = async (occupation: string) => {
    helpers.setValue(occupation);
    if (occupation.toLowerCase() === "student") {
      return steps.handleGotoStep(steps.stepsMap.COUNTRY);
    }
    steps.handleGoToNextStep();
  };

  return (
    <ContentContainer>
      <ContentContainer.Header image={occupationIllustration}>
        Hey <Text as="span" variant="alpha" content={username} />, I would like
        to know you to create better experience
      </ContentContainer.Header>
      <QuestionCard>
        <QuestionCard.Header
          as="h1"
          size="medium"
          font="poppins"
          fontWeight="high"
          variant="darkGrey"
          textAlign="center"
          content="Who are you?"
        />
        <QuestionCard.Options>
          {occupationFormData.map(({ icon, text, value, id }) => (
            <QuestionCard.Option
              key={id}
              icon={icon}
              text={text}
              active={field.value === value}
              onClick={() => handleUpdate(value)}
            />
          ))}
        </QuestionCard.Options>
        <QuestionCard.OtherOption
          value={field.value}
          onSubmit={(value) => handleUpdate(value)}
        />
      </QuestionCard>
    </ContentContainer>
  );
};

export default OccupationForm;
