import { TwitterService } from "services/Auth";
import {
  IAnalyticsData,
  IAnalyticsPayload,
  TAnalyticsData,
  TRecommendation,
} from "./types";

type TAPIResponse = {
  analyticsData: TAnalyticsData[];
  filePathExcel: string;
  filePathCsv: string;
  recommendations: TRecommendation[];
  tweetActions: boolean;
};

type TAPIPayload = {
  days: number;
};

class AnalyticsAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IAnalyticsData => {
    return {
      analyticsData: apiResponse.analyticsData,
      filePathExcel: apiResponse.filePathExcel,
      filePathCsv: apiResponse.filePathCsv,
      recommendation: apiResponse.recommendations,
      tweetActions: apiResponse.tweetActions,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getGetPayload = (data: IAnalyticsPayload) => {
    const payload: TAPIPayload = {
      days: data.days,
    };
    return payload;
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default AnalyticsAdapter;
