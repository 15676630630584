import { TTwitterPlatformDropdownItem } from "context/SocialMediaPost/types";
import { useAppSelector } from "redux/store/hooks";

const useTwitterAccounts = () => {
  const accountUid = useAppSelector((state) => state.auth.twitterSelection.uid);
  const selectedAccount = useAppSelector(
    (state) => state.auth.twitterSelection.selectedAccount
  );

  const initialize = () => {
    const account: TTwitterPlatformDropdownItem = {
      id: selectedAccount.uid,
      image: selectedAccount.profileImageUrl,
      meta: selectedAccount.userName,
      title: selectedAccount.name,
    };
    return selectedAccount.id !== "" ? [account] : [];
  };

  const options = initialize();

  return {
    accountUid,
    options,
  };
};

export default useTwitterAccounts;
