import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
  &.active,
  &:hover {
    border-radius: 5px;
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
    background-color: ${({ theme }) => theme.colors.alpha};
  }
`;

const WidgetContainer = styled.div`
  position: fixed;
  left: 6.5%;
  top: 40%;
  width: 400px;
  height: 400px;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  ${helper.getBoxShadow("extraLightGrey")};
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export { Container, WidgetContainer };
