import { useState } from "react";
import { TPositionObject } from "theme/types";

/* 
    - Pass your element/component's height & width in config
    - Returns calculated position per the screen of element that can be used to position it on screen
    - Has a capture event function to trigger internal position calculator function which updated position value in state 
*/

type TCoordinate = {
  x: number;
  y: number;
};

type TPositionElementConfig = {
  itemSize: {
    width: number;
    height: number;
  };
  padding?: TCoordinate;
  defaultPosition?: TPositionObject;
};

const usePositionElement = (config: TPositionElementConfig) => {
  const [position, setPosition] = useState<TPositionObject>({});

  const getPosition = (coordinates: TCoordinate): TPositionObject => {
    const page: TCoordinate = { x: window.innerWidth, y: window.innerHeight };
    const {
      itemSize,
      padding = { x: 0, y: 0 },
      defaultPosition = {
        left: page.x / 2 - itemSize.width / 2,
        top: page.y / 2 - itemSize.height / 2,
      },
    } = config;

    if (
      itemSize.width + padding.x > coordinates.x &&
      itemSize.height + padding.y < coordinates.y
    ) {
      /* 
            First quadrant
          */

      return {
        bottom: page.y - coordinates.y + padding.y,
        left: coordinates.x + padding.x,
      };
    }

    if (
      itemSize.width + padding.x > coordinates.x &&
      itemSize.height + padding.y > coordinates.y
    ) {
      /* 
            Second quadrant
          */

      return {
        top: coordinates.y + padding.y,
        left: coordinates.x + padding.x,
      };
    }

    if (
      itemSize.width + padding.x < coordinates.x &&
      itemSize.height + padding.y < page.y - coordinates.y
    ) {
      /* 
            Third quadrant
          */

      return {
        top: coordinates.y + padding.y,
        right: page.x - coordinates.x + padding.x,
      };
    }

    if (
      itemSize.width + padding.x < coordinates.x &&
      itemSize.height + padding.y < coordinates.y
    ) {
      /* 
            Fourth quadrant
          */

      return {
        bottom: page.y - coordinates.y + padding.y,
        right: page.x - coordinates.x + padding.x,
      };
    }

    return defaultPosition;
  };

  const captureEvent = (e: React.MouseEvent) => {
    const { clientX: x, clientY: y } = e;
    setPosition(() => getPosition({ x, y }));
  };

  return { position, captureEvent };
};

export default usePositionElement;
