import { TwitterService } from "services/Auth";
import {
  IAuditData,
  TActions,
  TBestPractices,
  TTwitterAudit,
  TRecommendation,
} from "./types";

type TAPIResponse = {
  twitterAudit: TTwitterAudit[];
  bestPractices: TBestPractices[];
  actions: TActions[];
  recommendations: TRecommendation[];
  tweetActions: boolean;
};

class AuditAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IAuditData => {
    return {
      twitterAudit: apiResponse.twitterAudit,
      bestPractices: apiResponse.bestPractices,
      actions: apiResponse.actions,
      recommendation: apiResponse.recommendations,
      tweetActions: apiResponse.tweetActions,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default AuditAdapter;
