import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ReactComponent as ClockIcon } from "assets/icons/common/clock-icon.svg";
import { Input, Portal } from "components/core";
import { useOnClickOutside, useToggle, usePositionElement } from "hooks/common";
import Container from "./Container";
import InputContainer from "./InputContainer";
import Icon from "./Icon";
import PickerContainer from "./PickerContainer";

interface ITimePickerProps {
  value: Date;
  onChange: (newDate: Date) => void;
  style?: React.CSSProperties;
  className?: string;
  minTime?: Date;
  maxTime?: Date;
  timeFormat?: string;
}

const DEFAULT_TIME_FORMAT = "hh:mm aa";

/* 
  date-fns specific date formats.
  See: https://date-fns.org/v2.28.0/docs/format
*/

const TimePicker = ({
  value,
  onChange,
  style,
  timeFormat = DEFAULT_TIME_FORMAT,
  className,
  ...props
}: ITimePickerProps) => {
  const [showPicker, setShowPicker] = useToggle();
  const positionElement = usePositionElement({
    itemSize: { width: 320, height: 357 },
    padding: { x: 10, y: 10 },
  });
  const containerRef = useOnClickOutside<HTMLDivElement>(() =>
    setShowPicker(false)
  );

  const handleTogglePicker = () => setShowPicker();

  const renderPicker = () => {
    if (showPicker)
      return (
        <Portal>
          <PickerContainer
            ref={containerRef}
            position={positionElement.position}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticTimePicker
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={(newValue) => {
                  newValue && onChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                {...props}
              />
            </LocalizationProvider>
          </PickerContainer>
        </Portal>
      );

    return null;
  };

  return (
    <Container style={style} className={className}>
      <InputContainer
        onClick={(e) => {
          handleTogglePicker();
          positionElement.captureEvent(e);
        }}
      >
        <Input readOnly value={format(value, timeFormat)} style={style} />
        <Icon icon={ClockIcon} />
      </InputContainer>
      {renderPicker()}
    </Container>
  );
};

export default TimePicker;
