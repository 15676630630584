import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${helper.getSpacing("extraSmall")};
  height: 80%;
  img,
  video {
    display: block;
    border-radius: 5px;
    width: 90%;
    height: 100%;
  }
`;

export { Container };
