import digitalMarketerIcon from "assets/icons/Profile/digital-marketer-icon.svg";
import seoExpertIcon from "assets/icons/Profile/seo-expert-icon.svg";
import marketingManagerIcon from "assets/icons/Profile/marketing-manager-icon.svg";
import socialMediaManagerIcon from "assets/icons/Profile/social-media-marketer-icon.svg";
import ceoIcon from "assets/icons/Profile/ceo-icon.svg";
import influencerIcon from "assets/icons/Profile/influencer-icon.svg";
import studentIcon from "assets/icons/Profile/student-icon.svg";
import founderIcon from "assets/icons/Profile/founder-icon.svg";

const data = [
  {
    id: 1,
    icon: digitalMarketerIcon,
    text: "Digital marketer",
    value: "digital marketer",
  },
  {
    id: 2,
    icon: seoExpertIcon,
    text: "SEO expert",
    value: "seo expert",
  },
  {
    id: 3,
    icon: marketingManagerIcon,
    text: "Marketing manager",
    value: "marketing manager",
  },
  {
    id: 4,
    icon: socialMediaManagerIcon,
    text: "Social media marketer",
    value: "social media marketer",
  },
  { id: 5, icon: ceoIcon, text: "CEO", value: "ceo" },
  { id: 6, icon: influencerIcon, text: "Influencer", value: "influencer" },
  { id: 8, icon: studentIcon, text: "Student", value: "student" },
  { id: 9, icon: founderIcon, text: "Founder", value: "founder" },
];

export default data;
