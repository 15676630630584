import { EventCalendar } from "components/common";
import { platformsIconMap } from "config/SocialMediaPost/platforms";
import { useSocialCalendar } from "hooks/SocialMediaPost";
import SocialCalendarEvent from "./SocialCalendarEvent";

const SocialCalendar = () => {
  const calendar = useSocialCalendar();

  const getDayViewCols = () =>
    Object.entries(platformsIconMap).map(([platform, data]) => ({
      dayViewColId: platform,
      icon: data.icon,
    }));

  return (
    <>
      <EventCalendar.Body>
        <EventCalendar.View
          dayViewCols={getDayViewCols()}
          events={calendar.data}
          timeGridOffset={9}
          renderEvents={(events) =>
            events.map((event) => (
              <SocialCalendarEvent key={event.id} event={event} />
            ))
          }
        />
      </EventCalendar.Body>
    </>
  );
};

export default SocialCalendar;
