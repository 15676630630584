import { applicationRoutes as routes } from "config/common";

const subnavigationData = [
  {
    title: "Lead Generation",
    route: routes.easyTwitter.leadGeneration,
  },
  {
    title: "Brand Monitoring",
    route: routes.easyTwitter.brandMonitoring,
  },
  {
    title: "Influencers",
    route: routes.easyTwitter.influencers,
  },
  {
    title: "Schedule Retweets",
    route: routes.easyTwitter.scheduleRetweets,
  },
  {
    title: "Analytics",
    route: routes.easyTwitter.analytics,
  },
  {
    title: "Auto Welcome",
    route: routes.easyTwitter.autoWelcome,
  },
  {
    title: "Audit",
    route: routes.easyTwitter.audit,
  },
];

export default subnavigationData;
