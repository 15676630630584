import ReactDOM from "react-dom";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
`;

interface IPortalProps extends React.ComponentPropsWithRef<"div"> {
  children?: React.ReactNode;
}

const Portal = ({ children, ...rest }: IPortalProps) => {
  return ReactDOM.createPortal(
    <Container {...rest}>{children}</Container>,
    document.getElementById("portal")!
  );
};

export default Portal;
