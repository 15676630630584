import { createContext, useEffect } from "react";
import { compare as api } from "api/Pricing";
import { useReactQuery } from "hooks/common";
import { Spinner } from "components/common";
import { IComparisonTable } from "adapters/Pricing/types";

type TCompareTableContext = {
  data: IComparisonTable;
};

export const CompareTableContext = createContext<TCompareTableContext | null>(
  null
);

const initialState: IComparisonTable = {
  allFeaturesTable: {
    easyTwitter: [],
    socialMediaScheduler: [],
    additionalFeatures: [],
  },
  socialMediaFeaturesTable: {
    socialMediaScheduler: [],
  },
};

const CompareTableProvider = (props: React.PropsWithChildren<{}>) => {
  const { execute, isLoading, data = initialState } = useReactQuery(api.get);

  useEffect(() => {
    execute();
  }, [execute]);

  return (
    <CompareTableContext.Provider value={{ data }}>
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </CompareTableContext.Provider>
  );
};

export default CompareTableProvider;
