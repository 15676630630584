import { ModuleHeader } from "components/common";
import {
  Container,
  TopSection,
  Body,
  MenuBar,
} from "./BaseModuleLayout.styles";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return <Container>{props.children}</Container>;
};

ModuleLayout.TopSection = TopSection;
ModuleLayout.Body = Body;
ModuleLayout.MenuBar = MenuBar;
ModuleLayout.ModuleHeader = ModuleHeader;

export default ModuleLayout;
