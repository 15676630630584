import { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { PricingLayout } from "layout/Pricing";
import { ProtectedRoute } from "controllers/Auth";
import { Spinner } from "components/common";

const Upgrade = lazy(() => import("pages/Pricing/Upgrade"));
const Success = lazy(() => import("pages/Pricing/Success"));
const Cancel = lazy(() => import("pages/Pricing/Cancel"));

const Pricing = () => {
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <ProtectedRoute
          exact
          layout={PricingLayout}
          path={routes.pricing.upgrade}
        >
          <Upgrade />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          layout={PricingLayout}
          path={routes.pricing.success}
        >
          <Success />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          layout={PricingLayout}
          path={routes.pricing.cancel}
        >
          <Cancel />
        </ProtectedRoute>
      </Switch>
    </Suspense>
  );
};

export default Pricing;
