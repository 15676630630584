import { Container, TopSection, SubContainer } from "./ModuleLayout.styles";
import { useAppSelector } from "redux/store/hooks";
import { Header } from "components/core";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  const firstName = useAppSelector(
    (state) => state.profile.personalDetails.firstName
  );
  return (
    <Container>
      <TopSection>
        <Header
          size="medium"
          font="playfair"
          variant="darkGrey"
          content={`Hello ${firstName},`}
          mb="small"
        />
      </TopSection>
      <SubContainer>{props.children}</SubContainer>
    </Container>
  );
};

export default ModuleLayout;
