import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TNotificationPreferences } from "adapters/Profile/types";

const initialState: TNotificationPreferences = {
  dailyActivity: false,
  newsletter: false,
  marketing: false,
  socialMediaPost: false,
  twitterEmail: false,
};

export const notificationPreferences = createSlice({
  name: "notificationPreferences",
  initialState,
  reducers: {
    setNotficationPreferences: (
      state,
      action: PayloadAction<Partial<TNotificationPreferences>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setNotficationPreferences } = notificationPreferences.actions;

export default notificationPreferences.reducer;
