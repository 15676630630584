import { ICampaignBuilderFormValues } from "components/SocialMediaPost/SchedulePost/types";

const campaignFormInitalValues: ICampaignBuilderFormValues = {
  websiteUrl: "",
  campaignId: "",
  campaignSource: "",
  campaignMedium: "",
  campaignName: "",
};

export default campaignFormInitalValues;
