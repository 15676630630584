import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const SecondaryMenu = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("medium")};
`;

export default SecondaryMenu;
