import { ActionButton } from "components/core";
import { useBoolean } from "hooks/common";
import RetweetPanel from "./RetweetPanel";

interface IRetweetButtonProps {
  count: number;
  onSubmit: (datetime: Date) => void;
}

const RetweetButton = ({ count, onSubmit }: IRetweetButtonProps) => {
  const [isPanelOpen, setPanelOpen, setPanelClose] = useBoolean();
  if (!isPanelOpen) {
    return (
      <ActionButton
        icon="retweet"
        variant="alpha"
        content={count}
        size="tiny"
        borderless
        onClick={setPanelOpen}
      />
    );
  }
  return (
    <>
      <ActionButton
        icon="close"
        variant="alpha"
        size="tiny"
        outline
        onClick={setPanelClose}
      />
      <RetweetPanel onSubmit={onSubmit} setPanelClose={setPanelClose} />
    </>
  );
};

export default RetweetButton;
