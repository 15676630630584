import { IAnalyticsData, IAnalyticsPayload } from "adapters/Twitter/types";
import { fetchAnalytics } from "api/Twitter";
import { Loader } from "components/common";
import { analytics } from "config/Twitter";
import { useTwitterAuth } from "hooks";
import { useReactQuery } from "hooks/common";
import { useRecommendation } from "hooks/Twitter";
import { createContext, useEffect } from "react";

type TAnalyticsContext = {
  data: IAnalyticsData;
  handleFilter: (payload: IAnalyticsPayload) => void;
};

export const AnalyticsContext = createContext<TAnalyticsContext | null>(null);

const initialData: IAnalyticsData = {
  analyticsData: [],
  filePathExcel: "",
  filePathCsv: "",
  recommendation: [],
  tweetActions: false,
};

const AnalyticsProvider = (props: React.PropsWithChildren<{}>) => {
  const recommendation = useRecommendation();
  const { isSignedIn, selectedAccount } = useTwitterAuth();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchAnalytics, {
    onSuccess: (data) => recommendation.setRecommendation(data.recommendation),
  });

  const handleFilter = (payload: IAnalyticsPayload) => {
    execute(payload);
  };

  useEffect(
    () => execute({ days: 30, isAuthenticated: isSignedIn }),
    [execute, isSignedIn, selectedAccount.userName]
  );

  return (
    <AnalyticsContext.Provider value={{ data, handleFilter }}>
      <Loader textData={analytics} hidden={!isLoading} />
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
