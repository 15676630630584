type TAPIResponse = {
  is_admin: boolean;
};

class AdminAdapter {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      isAdmin: apiResponse.is_admin,
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default AdminAdapter;
