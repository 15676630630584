import { TopSectionWrapper } from "./LayoutHeader.styles";
import { Header } from "components/core";
import { BaseModuleLayout } from "layout/common";
import { MultipleAccounts } from "components/Twitter/common";
import { useAppSelector } from "redux/store/hooks";

interface IAuditLayoutHeaderProps {
  header: string;
}

const AuditLayoutHeader = (props: IAuditLayoutHeaderProps) => {
  const firstName = useAppSelector(
    (state) => state.profile.personalDetails.firstName
  );

  return (
    <TopSectionWrapper>
      <BaseModuleLayout.TopSection>
        <Header
          size="small"
          font="poppins"
          variant="alpha"
          content={`👋 Hey ${firstName}!`}
          mb="small"
        />
        <MultipleAccounts />
      </BaseModuleLayout.TopSection>
    </TopSectionWrapper>
  );
};

export default AuditLayoutHeader;
