import styled from "styled-components";
import * as styles from "../styles";

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${styles.helper.containerize(80)}
`;

const Header = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.alpha};
  padding: ${styles.helper.getSpacing("medium")} 0;
  flex: 0 0 30%;
  color: ${({ theme }) => theme.colors.alpha};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getFontSize("medium")}

  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 50 + 4px);
  }

  span {
    display: block;
    margin: ${styles.helper.getSpacing("small")} 0;
    font-family: ${({ theme }) => theme.fonts.poppins};
    ${styles.helper.getFontWeight("high")}
    ${styles.helper.getFontSize("extraLarge")};
  }
`;

const Description = styled.div`
  flex: 0 0 80ch;
  @media screen and (max-width: 768px) {
    flex: 1 0 100%;
  }
  p {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-family: ${({ theme }) => theme.fonts.montserrat};
    ${styles.helper.getFontWeight("low")}
    ${styles.helper.getFontSize("medium")}
    ${styles.helper.getLineHeight("large")}

    @media screen and (max-width: 768px) {
      font-size: calc((50vw + 50vh) / 40 + 5px);
    }
  }
`;

export { Container, Header, Description };
