import Illustration from "./Illustration";
import ContentHeader from "./ContentHeader";
import Content from "./Content";

const Container = (props: React.PropsWithChildren<{}>) => {
  return <Content>{props.children}</Content>;
};

Container.Header = ContentHeader;
Container.Illustration = Illustration;

export default Container;
