import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const PostCard = styled.div`
  margin: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("extraSmall")} ${helper.getSpacing("small")};
  box-shadow: 2px 2px 4px 0 ${({ theme }) => theme.colors.extraLightGrey},
    -2px -2px 4px 0 ${({ theme }) => theme.colors.extraLightGrey};
`;

export { PostCard };
