import { BaseModuleLayout } from "layout/common";
import TwitterLayoutHeader from "./LayoutHeader";
import TwitterGrid from "./TwitterGrid";

const Influencers = (props: React.PropsWithChildren<{}>) => {
  return (
    <TwitterGrid templateRows={[8, 92]}>
      <TwitterLayoutHeader header="Influencers" />
      <BaseModuleLayout.Body>{props.children}</BaseModuleLayout.Body>
    </TwitterGrid>
  );
};

export default Influencers;
