import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  gap: ${helper.getSpacing("extraSmall")};
  align-items: center;
  i {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export { Container };
