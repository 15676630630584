import { useContext, useEffect } from "react";
import styled from "styled-components";
import { CardListSection, TextSection } from "components/common";
import { BorderedPage, Container, Header, Button, Flex } from "components/core";
import { ChangeAccountCards } from "components/SocialMediaPost/common";
import { landingPageItems } from "config/SocialMediaPost";
import { SelectPlatformContext } from "context/SocialMediaPost";
import { useNavigation, usePendo } from "hooks/common";

const AccountsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 20%);
  }
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  iframe {
    width: 525px;
    height: 275px;
  }
`;

const Landing = () => {
  const { isAtLeastOneConnected } = useContext(SelectPlatformContext);
  const navigation = useNavigation();

  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGetStarted = () => {
    if (isAtLeastOneConnected)
      return (
        <Flex justify="center">
          <Button
            content="Get Started"
            variant="alpha"
            style={{ marginBottom: "2rem" }}
            onClick={() =>
              navigation.navigate(
                (routes) => routes.socialMediaPost.schedulePost
              )
            }
          />
        </Flex>
      );
  };

  const renderVideo = () => {
    return (
      <iframe
        src="https://www.youtube.com/embed/nt5nLELVbYI"
        title="Social Media Introduction Video"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ border: "0px" }}
      />
    );
  };

  return (
    <BorderedPage variant="nyanza">
      <Container>
        <Header
          as="h1"
          content="Link Account . Create  Post . Schedule"
          variant="black"
          textAlign="center"
          mb="large"
          mt="large"
          size="medium"
        />
        <Flex gap="medium">
          <AccountsGrid>
            <ChangeAccountCards />
          </AccountsGrid>
          <VideoContainer>
            {renderVideo()}
            {renderGetStarted()}
          </VideoContainer>
        </Flex>

        <TextSection
          header="No Idea? No Problem"
          description="We’ll show you how to grow your audience and keep them engaging"
          style={{ marginBottom: "5rem" }}
        />

        {landingPageItems.map((item) => (
          <CardListSection key={item.id}>
            <CardListSection.Image src={item.image} />
            <CardListSection.Text
              header={item.header}
              description={item.description}
            />
          </CardListSection>
        ))}
      </Container>
    </BorderedPage>
  );
};
export default Landing;
