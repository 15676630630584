import { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { SimpleRoute } from "controllers/common";
import { SocialMediaPostLayout } from "layout/SocialMediaPost";
import {
  SelectPtatformProvider,
  SchedulerProvider,
  ScheduleSecondaryPaneProvider,
} from "context/SocialMediaPost";
import { Spinner } from "components/common";

const Landing = lazy(() => import("pages/SocialMediaPost/Landing"));
const Activity = lazy(() => import("pages/SocialMediaPost/Activity"));
const SchedulePost = lazy(() => import("pages/SocialMediaPost/SchedulePost"));
const Calendar = lazy(() => import("pages/SocialMediaPost/Calendar"));
const LiveTrends = lazy(() => import("pages/SocialMediaPost/LiveTrends"));

const SocialMediaPost = () => {
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <SimpleRoute exact path={routes.rootRoutes.socialMediaPost}>
          <SelectPtatformProvider>
            <Landing />
          </SelectPtatformProvider>
        </SimpleRoute>
        <SimpleRoute
          exact
          layout={SocialMediaPostLayout}
          path={routes.socialMediaPost.schedulePost}
        >
          <SelectPtatformProvider>
            <SchedulerProvider>
              <ScheduleSecondaryPaneProvider>
                <SchedulePost />
              </ScheduleSecondaryPaneProvider>
            </SchedulerProvider>
          </SelectPtatformProvider>
        </SimpleRoute>
        <SimpleRoute
          exact
          layout={SocialMediaPostLayout}
          path={routes.socialMediaPost.activity}
        >
          <Activity />
        </SimpleRoute>
        <SimpleRoute
          exact
          layout={SocialMediaPostLayout}
          path={routes.socialMediaPost.calendar}
        >
          <Calendar />
        </SimpleRoute>
        <SimpleRoute
          exact
          layout={SocialMediaPostLayout}
          path={routes.socialMediaPost.liveTrends}
        >
          <LiveTrends />
        </SimpleRoute>
      </Switch>
    </Suspense>
  );
};

export default SocialMediaPost;
