import { TAutoDmPostPayload, TFilePayload } from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { IAutoDm, TFiles } from "./types";

type TAPIResponse = {
  twitterUsername: string;
  replyText: string;
  files: TFiles[];
  enabled: boolean;
  message?: string;
  tweetActions: boolean;
};

type TPOSTPayload = {
  reply_text: string;
  files: TFilePayload[];
};

type TAPIDeleteResponse = {
  message: string;
};

class AutoDmAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IAutoDm => {
    return {
      twitterUsername: apiResponse.twitterUsername,
      replyText: apiResponse.replyText,
      files: apiResponse.files,
      enabled: apiResponse.enabled,
      message: apiResponse.message,
      tweetActions: apiResponse.tweetActions,
    };
  };

  private parseRemoveResponse = (apiResponse: TAPIDeleteResponse) => {
    if (apiResponse.message) {
      return {
        message: apiResponse.message,
      };
    }
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = ({ reply_text, files }: TAutoDmPostPayload) => {
    const payload: TPOSTPayload = {
      reply_text: reply_text,
      files: files,
    };
    return payload;
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);

  parsePostResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);

  parseDeleteResponse = (apiResponse: TAPIDeleteResponse) =>
    this.parseRemoveResponse(apiResponse);
}

export default AutoDmAdapter;
