import styled from "styled-components";

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  svg {
    cursor: pointer;
  }
`;

export { IconContainer };
