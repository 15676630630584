import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type {
  IGoogleAnalyticsAccountData,
  TAccount,
  TProperty,
  TView,
} from "api/Auth";
import { GoogleAnalyticsService } from "services/WebAnalytics";

const webAnalyticsService = new GoogleAnalyticsService();

const initialState: IGoogleAnalyticsAccountData =
  webAnalyticsService.getAccounts();

export const googleAnalyticsAccountData = createSlice({
  name: "googleAnalyticsAccountData",
  initialState,
  reducers: {
    setGoogleAnalyticsAccountsData: (
      state,
      action: PayloadAction<IGoogleAnalyticsAccountData>
    ) => {
      webAnalyticsService.setAccounts(action.payload);
      state.accounts = action.payload.accounts;
      state.properties = action.payload.properties;
      state.views = action.payload.views;
      state.userEmail = action.payload.userEmail;
      state.profilePicUrl = action.payload.profilePicUrl;
    },
    removeGoogleAnalyticsAccountsData: (state) => {
      webAnalyticsService.removeAccounts();
      webAnalyticsService.removeAuthCookie();
      const newState = webAnalyticsService.getAccounts();
      return { ...state, ...newState };
    },
  },
});

export const {
  setGoogleAnalyticsAccountsData,
  removeGoogleAnalyticsAccountsData,
} = googleAnalyticsAccountData.actions;

export default googleAnalyticsAccountData.reducer;

export type { TAccount, TProperty, TView };
