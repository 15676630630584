import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { Linkify } from "components/common";
import { useFile } from "hooks";
import MediaPreview from "./MediaPreview";

const helper = new ThemeHelper();

const ContentWrapper = styled.div`
  padding: ${helper.getSpacing("small")};
  min-height: 125px;
`;

const PostText = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 14px;
  white-space: pre-line;
`;

type TDefaultProps = {
  postText: string;
  postFiles: File[];
};

const PostContent = ({ postText, postFiles }: TDefaultProps) => {
  const fileHandler = useFile("images-videos");

  return (
    <ContentWrapper>
      <PostText>
        <Linkify>{postText}</Linkify>
      </PostText>
      <MediaPreview previewUrls={fileHandler.getPreviewUrls(postFiles)} />
    </ContentWrapper>
  );
};

export default PostContent;
