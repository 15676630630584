import { createSlice } from "@reduxjs/toolkit";
import { SearchConsoleService } from "services/WebAnalytics";

export interface googleSearchConsoleAuthState {
  isSignedIn: boolean;
}

const service = new SearchConsoleService();

const initialState: googleSearchConsoleAuthState = {
  isSignedIn: service.isAuthenticated(),
};

export const googleSearchConsoleAuth = createSlice({
  name: "googleSearchConsoleAuth",
  initialState,
  reducers: {
    setGoogleSearchConsoleSignedIn: (state) => {
      state.isSignedIn = true;
    },
    setGoogleSearchConsoleSignedOut: (state) => {
      state.isSignedIn = false;
    },
    clearGoogleSearchConsoleData: (state) => {
      state.isSignedIn = false;
      service.clearData();
    },
  },
});

export const {
  setGoogleSearchConsoleSignedIn,
  setGoogleSearchConsoleSignedOut,
  clearGoogleSearchConsoleData,
} = googleSearchConsoleAuth.actions;

export default googleSearchConsoleAuth.reducer;
