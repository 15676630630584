import { GraphHelper } from "helpers";
import { GoogleAnalyticsService } from "services/WebAnalytics";
import {
  IWebMatrixData,
  TBrandAwarenessGraph,
  TBrandEngagementGraph,
  TConversionGraph,
  TGraphMeta,
  TNewVisitorGraph,
  TPeakTrafficGraph,
  TReturningVisitorGraph,
  TSocialMediaAudienceGraph,
  TSocialMediaTrafficGraph,
  TWebMatrixCardData,
  TWebMatrixPayload,
  TWebMatrixRecommendation,
  TWebsiteExplorationGraph,
  TWebsiteRelevanceGraph,
} from "./types";

type TAPIResponse = {
  brand_awareness_rate: {
    card_data: TWebMatrixCardData;
    graph_data: TBrandAwarenessGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  social_media_audience_match: {
    card_data: TWebMatrixCardData;
    graph_data: TSocialMediaAudienceGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  social_media_traffic_rate: {
    card_data: TWebMatrixCardData;
    graph_data: TSocialMediaTrafficGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  brand_engagement_rate: {
    card_data: TWebMatrixCardData;
    graph_data: TBrandEngagementGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  website_content_relevance: {
    card_data: TWebMatrixCardData;
    graph_data: TWebsiteRelevanceGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  conversion_rate: {
    card_data: TWebMatrixCardData;
    graph_data: TConversionGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  website_exploration: {
    card_data: TWebMatrixCardData;
    graph_data: TWebsiteExplorationGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  new_visitor_next_page: {
    card_data: TWebMatrixCardData;
    graph_data: TNewVisitorGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  returning_visitor_next_page: {
    card_data: TWebMatrixCardData;
    graph_data: TReturningVisitorGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
  peak_traffic_time: {
    card_data: TWebMatrixCardData;
    graph_data: TPeakTrafficGraph[];
    recommendation: TWebMatrixRecommendation[];
  };
};

type TAPIGETPayload = {
  period: number;
  period_type: string;
  google_analytics_view: string;
};

class WebMatrixAdapter {
  private graphHelper = new GraphHelper();

  private getGraphMetaData = (payload: Record<string, any>[]) => {
    const maxValue = this.graphHelper.findHighestValue(payload);
    const maxAxisValue = this.graphHelper.converToMultiple(maxValue);
    const data: TGraphMeta = {
      maxAxisValue,
    };
    return data;
  };

  private parseResponse = (apiData: TAPIResponse): IWebMatrixData => {
    const {
      brand_awareness_rate,
      social_media_audience_match,
      social_media_traffic_rate,
      brand_engagement_rate,
      website_content_relevance,
      conversion_rate,
      website_exploration,
      new_visitor_next_page,
      returning_visitor_next_page,
      peak_traffic_time,
    } = apiData;

    const parsedData: IWebMatrixData = {
      brandAwareness: {
        ...brand_awareness_rate,
        graphMetaData: this.getGraphMetaData(brand_awareness_rate.graph_data),
      },
      socialMediaAudience: {
        ...social_media_audience_match,
        graphMetaData: this.getGraphMetaData(
          social_media_audience_match.graph_data
        ),
      },
      socialMediaTraffic: {
        ...social_media_traffic_rate,
        graphMetaData: this.getGraphMetaData(
          social_media_traffic_rate.graph_data
        ),
      },
      brandEngagement: {
        ...brand_engagement_rate,
        graphMetaData: this.getGraphMetaData(brand_engagement_rate.graph_data),
      },
      websiteContentRelevance: {
        ...website_content_relevance,
        graphMetaData: this.getGraphMetaData(
          website_content_relevance.graph_data
        ),
      },
      conversion: {
        ...conversion_rate,
        graphMetaData: this.getGraphMetaData(conversion_rate.graph_data),
      },
      websiteExploration: {
        ...website_exploration,
        graphMetaData: this.getGraphMetaData(website_exploration.graph_data),
      },
      newVisitorNextPage: {
        ...new_visitor_next_page,
        graphMetaData: this.getGraphMetaData(new_visitor_next_page.graph_data),
      },
      returningVisitorNextPage: {
        ...returning_visitor_next_page,
        graphMetaData: this.getGraphMetaData(
          returning_visitor_next_page.graph_data
        ),
      },
      peakTrafficTime: {
        ...peak_traffic_time,
        graphMetaData: this.getGraphMetaData(peak_traffic_time.graph_data),
      },
    };

    return parsedData;
  };

  parseGetResponse = (data: TAPIResponse): IWebMatrixData => {
    return this.parseResponse(data);
  };

  getGetPayload = async ({ period, periodType, view }: TWebMatrixPayload) => {
    const googleAnalyticsService = new GoogleAnalyticsService();
    const headers = await googleAnalyticsService.getHeadersOrError();
    const params: TAPIGETPayload = {
      period,
      period_type: periodType,
      google_analytics_view: view,
    };

    return {
      params,
      headers,
    };
  };
}

export default WebMatrixAdapter;
