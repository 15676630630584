import { dolphynAPI } from "api/instances";
import { APIError } from "errors";
import { constants } from "config/common";
import { links } from "config/ProductDemand";
import { DemandByCountryAdapter } from "adapters/ProductDemand";
import { TDemandByCountryPayload } from "adapters/ProductDemand/types";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseProductDemandApiUrl.append("/demand_by_country");
const adapter = new DemandByCountryAdapter();

const fetchDemandByCountry = async (payload: TDemandByCountryPayload) => {
  try {
    const { data } = await dolphynAPI(URL, {
      params: adapter.getGetPayload(payload),
    });
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchDemandByCountry;
