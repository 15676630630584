import * as Yup from "yup";

export const generateCouponValidation = Yup.object({
  acronym: Yup.string()
    .max(4, "Maximum 4 characters allowed.")
    .required("Acronym cannot be empty."),
  noOfCoupons: Yup.string().required("No of Coupons cannot be empty."),
  plan: Yup.string().required("Plan cannot be empty."),
});

export const redeemCouponValidation = Yup.object({
  coupon: Yup.string().required("Coupon code cannot be empty."),
});
