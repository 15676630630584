import { Flex } from "components/core";
import ContentCreationLayout from "./ContentCreationLayout";
import LayoutHeader from "./LayoutHeader";

const Activity = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <LayoutHeader header="Activity 📝" />
      <ContentCreationLayout.Body>{props.children}</ContentCreationLayout.Body>
    </Flex>
  );
};

export default Activity;
