import { TCampaignFormField } from "components/SocialMediaPost/SchedulePost/types";

const campaignFieldsData: TCampaignFormField[] = [
  {
    name: "websiteUrl",
    placeholder: "Website URL",
    description: "The full website URL (e.g. https://www.example.com)",
  },
  {
    name: "campaignId",
    placeholder: "Campaign ID (optional)",
    description: "The ads campaign id",
  },
  {
    name: "campaignSource",
    placeholder: "Campaign Source",
    description: "The referrer (e.g. google.newsletter)",
  },
  {
    name: "campaignMedium",
    placeholder: "Campaign Medium",
    description: "Marketing medium (e.g. cpc, banner, email)",
  },
  {
    name: "campaignName",
    placeholder: "Campaign Name",
    description:
      "Product, promo code, or slogan (e.g. spring_sale) One of campaign name or campaign id are required",
  },
];

export default campaignFieldsData;
