import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { ActionButton } from "components/core";

type TEditButtonProps = {
  onClick?: () => void;
};

const EditButton = ({ onClick }: TEditButtonProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <ActionButton
      icon="edit"
      variant="alpha"
      size="tiny"
      outline
      onClick={onClick}
      borderless
      data-tip="Edit & Tweet"
    />
  );
};

export default EditButton;
