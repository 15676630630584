import { useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router";
import { Form, Container, SocialLogin } from "./common";
import { signUpValidation } from "config/Auth";
import { applicationRoutes as routes } from "config/common";
import { useDolphynSignup } from "hooks/Auth";
import TermsConditions from "./TermsConditions";

const initialValues = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  password: "",
  confirmPassword: "",
  termsConditions: false,
};

const SignupForm = () => {
  const { handleSignup } = useDolphynSignup();
  const [showTerms, setShowTerms] = useState(false);
  const history = useHistory();

  return (
    <Container>
      <Form>
        <Form.Header
          as="h1"
          size="medium"
          variant="lightGrey"
          textAlign="center"
          content="Sign up"
          font="poppins"
        />
        <SocialLogin />
        <Form.Or />

        <Formik
          initialValues={initialValues}
          onSubmit={handleSignup}
          validationSchema={signUpValidation.schema}
        >
          {({ handleSubmit }) => (
            <Form.Form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Input
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="username"
                  type="text"
                  placeholder="Username"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input name="email" type="text" placeholder="Email" />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  name="termsConditions"
                  id="termsConditions"
                  label={
                    <Form.Label
                      style={{
                        textAlign: "center",
                        display: "inline",
                        cursor: "pointer",
                      }}
                      htmlFor="termsConditions"
                    >
                      I Agree with Dolphyn's{" "}
                      <Form.Text
                        as="span"
                        variant="alpha"
                        style={{ cursor: "pointer" }}
                        content="Terms & Conditions"
                        onClick={() => setShowTerms(true)}
                      />
                      <TermsConditions
                        open={showTerms}
                        onClose={() => setShowTerms(false)}
                        onOutsideClick={() => setShowTerms(false)}
                      />
                    </Form.Label>
                  }
                />
              </Form.Field>
              <Form.Button
                variant="alpha"
                type="submit"
                size="huge"
                fluid
                content="Submit"
                style={{ marginBottom: "1rem" }}
              />
            </Form.Form>
          )}
        </Formik>
        <Form.Text style={{ textAlign: "center" }}>
          Already have an account?{" "}
          <Form.Text
            as="span"
            variant="alpha"
            style={{ cursor: "pointer" }}
            content="Log In"
            onClick={() => history.push(routes.rootRoutes.signIn)}
          />
        </Form.Text>
      </Form>
    </Container>
  );
};

export default SignupForm;
