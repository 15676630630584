import Toggle from "./Toggle";
import { DefaultColors } from "styled-components";
import { CheckboxProps } from "semantic-ui-react";
import { useField } from "formik";
import FormError from "./FormError";

type TBaseToggleFieldProps = {
  variant: keyof DefaultColors;
  name: string;
};

interface ToggleFieldProps
  extends Omit<CheckboxProps, keyof TBaseToggleFieldProps>,
    TBaseToggleFieldProps {}

const ToggleField = ({ name, ...props }: ToggleFieldProps) => {
  const [field, meta, helpers] = useField<boolean>(name);
  return (
    <>
      <Toggle
        checked={field.value}
        onChange={(e, { checked }) => {
          if (checked !== undefined) {
            helpers.setValue(checked);
          }
        }}
        {...props}
      />
      {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
    </>
  );
};

export default ToggleField;
