import { css } from "styled-components";
import { transparentize } from "polished";

class FormControl {
  getFocusedBoxShadow = () => css`
    box-shadow: 0px 0px 10px
      ${({ theme }) => transparentize(0.2, theme.colors.alpha)};
  `;

  getUnfocusedBoxShadow = () => css`
    box-shadow: 0px 0px 10px
      ${({ theme }) => transparentize(0.5, theme.colors.alpha)};
  `;
}

export default FormControl;
