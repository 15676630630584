import { GoogleAnalyticsService } from "services/WebAnalytics";
import { IPageMatrixPagesData, TPageMatrixPagesPayload } from "./types";

type TAPIResponse = {
  page_list: string[];
};

type TAPIGETPayload = {
  period: number;
  period_type: string;
  google_analytics_view: string;
};

class PageMatrixPagesAdapter {
  private parseResponse = ({
    page_list,
  }: TAPIResponse): IPageMatrixPagesData => {
    const pages = page_list.map((page: string) => ({
      text: page,
      value: page,
    }));

    return { pages };
  };

  parseGetResponse = (data: TAPIResponse): IPageMatrixPagesData => {
    return this.parseResponse(data);
  };

  getGetPayload = async ({
    period,
    periodType,
    view,
  }: TPageMatrixPagesPayload) => {
    const googleAnalyticsService = new GoogleAnalyticsService();
    const headers = await googleAnalyticsService.getHeadersOrError();
    const params: TAPIGETPayload = {
      period,
      period_type: periodType,
      google_analytics_view: view,
    };

    return {
      params,
      headers,
    };
  };
}

export default PageMatrixPagesAdapter;
