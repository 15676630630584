import { AuditAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Twitter";
import { APIError } from "errors";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/audit");
const adapter = new AuditAdapter();

export type TAuditPayload = {
  isAuthenticated?: boolean;
};

const fetchAudit = async ({ isAuthenticated }: TAuditPayload) => {
  try {
    if (isAuthenticated) {
      const payload = adapter.getConfig();
      const { data } = await dolphynAPI(URL, payload);
      return adapter.parseGetResponse(data);
    }
    const { data } = await dolphynAPI(URL);
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchAudit;
