import { throwApiError } from "errors";
import { dolphynPublicRoot } from "api/instances";
import { links, constants } from "config/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/forgot_password");

export interface IForgotPassword {
  email: string;
  redirectUrl?: string;
}

const forgotPassword = async ({
  email,
  redirectUrl = links.DEFAULT_RESET_PASSWORD_REDIRECT_URL,
}: IForgotPassword) => {
  try {
    const { data } = await dolphynPublicRoot(URL, {
      params: {
        email,
        redirect_url: redirectUrl,
      },
    });

    return data;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default forgotPassword;
