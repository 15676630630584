import { useState } from "react";

type TOptions = {
  onChange?: (page: number) => void;
};

const usePagination = <T>(
  data: T[],
  itemsPerPage: number,
  options?: TOptions
) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getTotalPages = () => Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    if (options?.onChange) {
      options.onChange(value);
    }
  };

  const getPageRange = () => {
    return {
      start: (currentPage - 1) * itemsPerPage,
      end: (currentPage - 1) * itemsPerPage + itemsPerPage,
    };
  };

  const getPaginatedData = () => {
    const { start, end } = getPageRange();
    return data.slice(start, end);
  };

  return {
    totalPages: getTotalPages(),
    pageRange: getPageRange(),
    paginatedData: getPaginatedData(),
    handlePageChange,
    currentPage,
  };
};

export default usePagination;
