import { applicationRoutes as routes } from "config/common";
import { SimpleRoute } from "controllers/common";
import { SocialAccountLayout } from "layout/SocialAccount";
import { SocialAccountPage } from "pages/SocialAccount";
import { Switch } from "react-router-dom";

const Dashboard = () => {
  return (
    <Switch>
      <SimpleRoute
        layout={SocialAccountLayout}
        exact
        path={routes.rootRoutes.socialAccount}
      >
        <SocialAccountPage />
      </SimpleRoute>
    </Switch>
  );
};

export default Dashboard;
