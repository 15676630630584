import * as Yup from "yup";

export default Yup.object({
  twoFactorAuth: Yup.boolean().required(),
  countryCode: Yup.string().when("twoFactorAuth", {
    is: true,
    then: Yup.string().required("A country code cannot be empty."),
  }),
  phoneNumber: Yup.string().when("twoFactorAuth", {
    is: true,
    then: Yup.string()
      .required("Phone number cannot be empty.")
      .test("is-number", "Phone numbers should be numeric", (value) => {
        return value ? !isNaN(parseFloat(value)) : false;
      })
      .min(10, "A phone number should be minimum of 10 digits.")
      .max(15, "A phone number should be maximum of 15 digits."),
  }),
});
