import { createContext, useState } from "react";
import { TScheduleSceondaryPane } from "./types";
import { scheduleSecondaryPaneMap } from "config/SocialMediaPost";

type TChangePanceCB = (
  map: typeof scheduleSecondaryPaneMap
) => TScheduleSceondaryPane;

type TSecondaryPaneContext = {
  currentPane: TScheduleSceondaryPane;
  changePane: (cb: TChangePanceCB) => void;
};

export const ScheduleSecondaryPaneContext =
  createContext<TSecondaryPaneContext | null>(null);

const ScheduleSecondaryPaneProvider = (props: React.PropsWithChildren<{}>) => {
  const [currentPane, setCurrentPane] = useState<TScheduleSceondaryPane>(
    scheduleSecondaryPaneMap.preview
  );

  return (
    <ScheduleSecondaryPaneContext.Provider
      value={{
        currentPane,
        changePane: (cb: TChangePanceCB) =>
          setCurrentPane(cb(scheduleSecondaryPaneMap)),
      }}
    >
      {props.children}
    </ScheduleSecondaryPaneContext.Provider>
  );
};

export default ScheduleSecondaryPaneProvider;
