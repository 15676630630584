import { Formik } from "formik";
import { Form, Container } from "./common";
import { useDolphynAuth } from "hooks/Auth";
import { useNavigation } from "hooks/common";
import { forgotPasswordFormValidation } from "config/Auth";

const initialValues = {
  email: "",
};

const ForgotPasswordForm = () => {
  const nav = useNavigation();
  const { handleSendResetEmail } = useDolphynAuth();

  return (
    <Container>
      <Form>
        <Form.Header
          as="h1"
          size="medium"
          variant="lightGrey"
          textAlign="center"
          content="Log In"
          font="poppins"
        />

        <Formik
          validationSchema={forgotPasswordFormValidation}
          initialValues={initialValues}
          onSubmit={handleSendResetEmail}
        >
          {({ handleSubmit }) => (
            <Form.Form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Input name="email" type="text" placeholder="Email" />
              </Form.Field>
              <Form.Field>
                <Form.Button
                  variant="alpha"
                  type="submit"
                  size="huge"
                  fluid
                  content="Submit"
                />
              </Form.Field>
            </Form.Form>
          )}
        </Formik>
        <Form.Text style={{ textAlign: "center" }}>
          <Form.Text
            as="span"
            variant="alpha"
            style={{ cursor: "pointer" }}
            content="Log In"
            onClick={() => nav.navigate((routes) => routes.rootRoutes.signIn)}
          />
        </Form.Text>
      </Form>
    </Container>
  );
};

export default ForgotPasswordForm;
