import { v4 as uuid } from "uuid";
import { useRecommendation } from "hooks/Twitter";
import List from "./List";
import { TRecommendation } from "adapters/Twitter/types";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setShowRecommendation } from "redux/slices/Twitter";
import { Message, Portal } from "semantic-ui-react";

const Recommendation = (props: React.ComponentPropsWithoutRef<"div">) => {
  const { data: recommendation } = useRecommendation();
  const isOpen = useAppSelector(
    (state) => state.twitter.recommendation.showRecommendation
  );
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setShowRecommendation(false));
  };

  const renderRecommendation = () => {
    return recommendation.map(
      ({ title, statements: data }: TRecommendation) => (
        <List key={uuid()} title={title} data={data} />
      )
    );
  };

  return (
    <Portal open={isOpen} onClose={onClose}>
      <Message
        onDismiss={onClose}
        style={{
          position: "fixed",
          left: "20%",
          top: "8%",
          zIndex: 99,
          maxWidth: "70vw",
        }}
      >
        {renderRecommendation()}
      </Message>
    </Portal>
  );
};

export default Recommendation;
