import { Text } from "components/core";
import { Container } from "./AccountPic.styles";

export interface IAccountPicProps {
  image?: string;
  title?: string;
}

const AccountPic = ({ image, title }: IAccountPicProps) => {
  const renderAlt = () => {
    return title ? `${title}-profile` : "profile";
  };

  return (
    <Container>
      <img src={image} alt={renderAlt()} />
      <Text variant="darkGrey" font="poppins" size="extraSmall">
        {title}
      </Text>
    </Container>
  );
};

export default AccountPic;
