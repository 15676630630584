import { Flex } from "components/core";
import styled from "styled-components";

const ModuleContainer = styled.div`
  flex: 1;
`;

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex style={{ height: "100vh" }}>
      <ModuleContainer>{props.children}</ModuleContainer>
    </Flex>
  );
};

export default ModuleLayout;
