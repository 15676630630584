import { createPasswordValidation } from "config/Auth";
import { Formik } from "formik";
import { useLoginEnterprise, useNavigation } from "hooks";
import { useLocation } from "react-router-dom";
import { Container, Form } from "./common";

const LoginEnterpriseForm = () => {
  const { handleSignup } = useLoginEnterprise();
  const { navigate } = useNavigation();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const email = urlParams.get("email");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Container>
      <Form>
        <Form.Header
          as="h1"
          size="medium"
          variant="lightGrey"
          textAlign="center"
          content="Log In"
          font="poppins"
        />
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSignup({ email: email!, password: values.password });
            navigate((routes) => routes.rootRoutes.signIn);
          }}
          validationSchema={createPasswordValidation.schema}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <Form.Form onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Input
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Field>
              <Form.Button
                variant="alpha"
                type="submit"
                size="huge"
                fluid
                content="Submit"
                style={{ marginBottom: "2rem" }}
              />
            </Form.Form>
          )}
        </Formik>
      </Form>
    </Container>
  );
};

export default LoginEnterpriseForm;
