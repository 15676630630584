import { Icon } from "components/core";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 4px 0 #e9e9e9, -2px -2px 4px 0 #e9e9e9;
  position: absolute;
  z-index: 1;
  top: 35%;
  left: 7%;
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
})`
  &&& {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 5%;
  top: 5%;
  margin-right: 32px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const About = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stats = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
`;

const Bio = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  white-space: pre-line;
`;

const Stat = styled(Bio)`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 500;

  span {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-family: ${({ theme }) => theme.fonts.poppins};
    font-weight: 300;
  }
`;

const TwitterIcon = styled(Icon).attrs({
  name: "twitter",
})`
  cursor: pointer;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.alpha};
`;

const WebsiteIcon = styled(Icon).attrs({
  name: "globe",
})`
  cursor: pointer;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.alpha};
`;

const MapIcon = styled(Icon).attrs({
  name: "map marker alternate",
})`
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.125rem;
  color: ${({ theme }) => theme.colors.alpha};
`;

const ProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const UserName = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const Name = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

const Username = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 14px;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

export {
  CardContainer,
  ProfileImage,
  TwitterIcon,
  WebsiteIcon,
  StyledIcon,
  CloseIcon,
  MapIcon,
  UserName,
  Name,
  Username,
  Content,
  About,
  Stats,
  Stat,
  Bio,
};
