import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { LikeReplyAdapter } from "adapters/Twitter";
import { TConversation } from "adapters/Twitter/types";
import { dolphynAPI } from "api/instances";

const URL = links.baseTwitterApiUrl.append(links.likeUnlikeApiUrl);
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new LikeReplyAdapter();

const likeReply = async (values: TConversation) => {
  try {
    const payload = adapter.getPostPayload(values);
    const config = adapter.getConfig();
    const postUrl = adapter.getPostUrl(URL, values);
    const { data } = await dolphynAPI.post(postUrl, payload, config);
    return adapter.parsePostResponse(values, data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default likeReply;
