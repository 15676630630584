import { Linkify } from "components/common";
import { TweetCard } from "components/Twitter/common";
import { relativeTime } from "helpers";
import { useTwitterAuth } from "hooks";
import { useDrag } from "react-dnd";
import { removeContent } from "redux/slices/ContentCreation";
import { useAppDispatch } from "redux/store/hooks";
import styled from "styled-components";
import ActionIcons from "./ActionIcons";
import { ItemTypes } from "./ItemTypes";

const Container = styled.div`
  position: relative;
`;

type Props = {
  content: string;
  datetime?: string;
};

interface DropResult {
  name: string;
}

const ContentCard: React.FC<Props> = ({ content, datetime }) => {
  const dispatch = useAppDispatch();

  const [{ opacity }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: { id: content },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult?.name === ItemTypes.REMOVE) {
        dispatch(removeContent(content));
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const { selectedAccount } = useTwitterAuth();

  return (
    <Container ref={drag} style={{ opacity }}>
      <TweetCard>
        <TweetCard.Content>
          <TweetCard.Head>
            <TweetCard.Image
              src={selectedAccount.profileImageUrl}
              alt={selectedAccount.name}
            />
            <TweetCard.Username
              name={selectedAccount.name}
              username={selectedAccount.userName}
            />
            {datetime && (
              <TweetCard.ScheduledAt>
                {relativeTime(datetime)}
              </TweetCard.ScheduledAt>
            )}
          </TweetCard.Head>
          <TweetCard.TSBio>
            <Linkify>{content}</Linkify>
          </TweetCard.TSBio>
          <TweetCard.Action style={{ justifyContent: "flex-end" }}>
            <ActionIcons text={content} />
          </TweetCard.Action>
        </TweetCard.Content>
      </TweetCard>
    </Container>
  );
};

export default ContentCard;
