import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

type DescriptionProps = { variant: "alpha" | "success" | "danger" };

const Description = styled.span<DescriptionProps>`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${helper.getFontSize("small")};
  ${helper.getFontWeight("high")};
  color: ${({ theme, variant }) => theme.colors[variant]};
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default Description;
