import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setRecommendation } from "redux/slices/Twitter";
import { TRecommendation } from "adapters/Twitter/types";

const useRecommendation = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.twitter.recommendation.data);

  return {
    data,
    setRecommendation: (data: TRecommendation[]) =>
      dispatch(setRecommendation(data)),
  };
};

export default useRecommendation;
