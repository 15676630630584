import {
  ISimilarKeywordData,
  TSimilarKeywordCardEntry,
  TSimilarKeywordPayload,
  TRecommendation,
} from "./types";

type TAPIResponse = {
  rising_keywords: TSimilarKeywordCardEntry[];
  top_keywords: TSimilarKeywordCardEntry[];
  questions: TSimilarKeywordCardEntry[];
  recommendations: TRecommendation[];
};

type TAPIPayload = {
  country: string;
  keyword: string;
  start_date: string;
  end_date: string;
};

class SimilarKeywordAdapter {
  private parseResponse = (apiResponse: TAPIResponse): ISimilarKeywordData => {
    return {
      cards: {
        risingKeywords: apiResponse.rising_keywords,
        questions: apiResponse.questions,
        topKeywords: apiResponse.top_keywords,
      },
      recommendation: apiResponse.recommendations,
    };
  };

  getGetPayload = (data: TSimilarKeywordPayload): TAPIPayload => {
    return {
      country: data.country,
      keyword: data.keyword,
      start_date: data.startDate.toISOString(),
      end_date: data.endDate.toISOString(),
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default SimilarKeywordAdapter;
