import { scheduleForMonth } from "api/ContentGeneration";
import { useNotification, useReactQuery } from "hooks";
import { createContext } from "react";
import { setScheduledPosts } from "redux/slices/ContentCreation";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TScheduleForMonthFormValues } from "./types";

interface IScheduleForMonthContext {
  isSchedulingForMonth: boolean;
  handleScheduleForMonth: (formValues: TScheduleForMonthFormValues) => void;
}

export const ScheduleForMonthContext =
  createContext<IScheduleForMonthContext | null>(null);

const ScheduleForMonthProvider = (props: React.PropsWithChildren<{}>) => {
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const tweets = useAppSelector(
    (state) => state.contentCreation.aiContent.contents
  );

  const { execute: executeScheduleForMonth, isLoading: isSchedulingForMonth } =
    useReactQuery(scheduleForMonth);

  const handleScheduleForMonth = (values: TScheduleForMonthFormValues) => {
    executeScheduleForMonth(
      {
        tweets,
        prompt: values.prompt,
        timezone: values.timezone,
      },
      {
        onSuccess: (data) => {
          if (data) {
            notification.notifySuccess(data.message);
            dispatch(setScheduledPosts(data.scheduledPosts));
          }
        },
      }
    );
  };

  return (
    <ScheduleForMonthContext.Provider
      value={{ handleScheduleForMonth, isSchedulingForMonth }}
    >
      {props.children}
    </ScheduleForMonthContext.Provider>
  );
};

export default ScheduleForMonthProvider;
