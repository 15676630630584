import activity from "assets/images/SocialMediaPost/activity-landing-image.png";
import calender from "assets/images/SocialMediaPost/calender-landing-image.png";
import liveTrends from "assets/images/SocialMediaPost/live-trends-landing-image.png";
import schedulePost from "assets/images/SocialMediaPost/schedule-post-landing-image.png";

export const listData = [
  {
    id: 1,
    header: "Schedule Post",
    description:
      "With one click, schedule and post the content on four social media platforms. You can add UTM parameter, hashtags and receive time recommendation for your posts",
    buttonText: "Get Started",
    image: schedulePost,
  },
  {
    id: 2,
    header: "Activity",
    description:
      "Check the previous posts for one month. Check and do edits on scheduled post for next four months, from here.",
    buttonText: "Get Started",
    image: activity,
  },
  {
    id: 3,
    header: "Calendar",
    description:
      "You can manage what you see. Track and edit posts from here. You can check scheduled content by day, week and month views and plan accordingly. Also check for important days",
    buttonText: "Get Started",
    image: calender,
  },
  {
    id: 4,
    header: "Live Trends",
    description:
      "Trending topics attract the highest audience attenton and engagement on Social Media. Latest study shows brand value increases with content created on trending topics.",
    buttonText: "Get Started",
    image: liveTrends,
  },
];
