import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export { Container };
