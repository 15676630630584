import { applicationRoutes as routes } from "config/common";

const items = [
  {
    title: "Keyword Listening",
    route: routes.leadGeneration.keywordListening,
  },
  {
    title: "Leads",
    route: routes.leadGeneration.leads,
  },
  {
    title: "Lead Nurturing",
    route: routes.leadGeneration.leadNurturing,
  },
];

export default items;
