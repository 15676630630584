import { ResponsiveContainer } from "recharts";
import { IGraphBodyProps } from "./types";
import { OuterContainer, InnerContainer } from "./Body.styles";

const Body = ({ overflow, data, children }: IGraphBodyProps) => {
  if (overflow && data) {
    return (
      <OuterContainer>
        <InnerContainer layout={overflow} enteriesCount={data.length}>
          <ResponsiveContainer width="100%" height="100%">
            {children}
          </ResponsiveContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      {children}
    </ResponsiveContainer>
  );
};

export default Body;
