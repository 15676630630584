import { loginTwitter as authApi } from "api/Auth";
import * as twitter from "redux/slices/Auth/twitter";
import * as twitterSelection from "redux/slices/Auth/twitterSelection";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { useReactQuery } from "hooks/common";

const useTwitterAuth = () => {
  const dispatch = useAppDispatch();
  const reInitialize = () => {
    dispatch(twitter.reInitialize());
    dispatch(twitterSelection.reInitialize());
  };
  const {
    execute: login,
    executeAsync: loginAsync,
    isLoading,
  } = useReactQuery(authApi, {
    onSuccess: () => {
      reInitialize();
    },
  });

  /* 
    States
  */

  const isSignedIn = useAppSelector((state) => state.auth.twitter.isSignedIn);
  const accounts = useAppSelector((state) => state.auth.twitter.accounts);
  const selectedAccount = useAppSelector(
    (state) => state.auth.twitterSelection.selectedAccount
  );
  const isAccountSelected = (uid: twitter.TUid) => selectedAccount.uid === uid;

  /* 
    Handlers
  */

  const removeAccount = (uid: twitter.TUid) => {
    dispatch(twitter.removeAccount(uid));
    dispatch(twitterSelection.resetSelection());
  };

  const selectAccount = (uid: string) => {
    dispatch(twitterSelection.selectAccount(uid));
  };

  const clearData = () => {
    dispatch(twitter.clearData());
    dispatch(twitterSelection.resetSelection());
  };

  return {
    isSignedIn,
    isLoading,
    login,
    loginAsync,
    accounts,
    selectedAccount,
    isAccountSelected,
    removeAccount,
    selectAccount,
    clearData,
  };
};

export default useTwitterAuth;
