import { ModuleMenu } from "components/common";
import { moduleMenuItems } from "config/SocialMediaPost";
import { useSubHeading } from "hooks/common";
import { ChangeAccountButton } from "components/SocialMediaPost/common";
import SocialMediaLayout from "./SocialMediaLayout";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  const [heading] = useSubHeading();
  return (
    <SocialMediaLayout>
      <SocialMediaLayout.TopSection>
        <SocialMediaLayout.ModuleHeader
          header="Social Media Post"
          description={heading}
        />
        <ChangeAccountButton />
      </SocialMediaLayout.TopSection>
      <SocialMediaLayout.MenuBar>
        <ModuleMenu items={moduleMenuItems} />
      </SocialMediaLayout.MenuBar>
      <SocialMediaLayout.Body>{props.children}</SocialMediaLayout.Body>
    </SocialMediaLayout>
  );
};

export default ModuleLayout;
