import { v4 as uuid } from "uuid";
import { Header, Linkify, List as ListItems } from "components/core";
import { Container } from "./List.styles";

type TListItem = {
  text: string;
};

interface IList extends React.ComponentPropsWithoutRef<"div"> {
  title?: string;
  data?: TListItem[] | null;
  children?: React.ReactNode;
}

const List = ({ title, data, children, ...rest }: IList) => {
  const renderListItems = () => {
    if (!data) return null;

    return data.map(({ text }) => (
      <ListItems.Item key={uuid()}>
        <Linkify>{text}</Linkify>
      </ListItems.Item>
    ));
  };

  if (children) {
    return <Container {...rest}>{children}</Container>;
  }

  return (
    <Container {...rest}>
      <Header size="extraSmall" font="poppins" variant="alpha">
        <Linkify contentText="Click here">{title}</Linkify>
      </Header>
      <ListItems
        size="extraSmall"
        spacing="none"
        padding="none"
        font="poppins"
        variant="alpha"
        bulletVariant="alpha"
        bulleted
      >
        {renderListItems()}
      </ListItems>
    </Container>
  );
};

List.Header = Header;
List.ListItems = ListItems;
List.Item = ListItems.Item;

export default List;
