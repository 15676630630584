import { dolphynAPI } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";
import FacebookService, {
  TFacebookPage,
  TFacebookAccount,
  TFacebookPages,
} from "services/Auth/facebookService";

type TAPIResponse = {
  token_id: string;
  name: string;
  email: string;
  id: string;
  profile_image: string;
  page_list: {
    name: string;
    id: string;
    profile_image: string;
    token_id: string;
  }[];
};

const URL = links.BASE_AUTHENTICATION_API_URL.append("/facebook");

const service = new FacebookService();

const loginFacebook = async (redirectUrl: String) => {
  try {
    const response = await dolphynAPI.post(URL, {
      facebook_redirect: redirectUrl,
    });

    const data = response.data as TAPIResponse;

    const uid = service.getUid(data.id);

    const cookie: TFacebookAccount = {
      uid,
      tokenId: data.token_id,
      name: data.name,
      email: data.email,
      id: data.id,
      profileImageUrl: data.profile_image,
    };

    const pages: TFacebookPage[] = data.page_list.map((page) => ({
      name: page.name,
      id: page.id,
      profileImageUrl: page.profile_image,
      tokenId: page.token_id,
    }));

    const pageList: TFacebookPages = {
      uid,
      pages,
    };

    service.setAuthCookie(cookie);
    service.setPages(pageList);

    return null;
  } catch (e) {
    service.clearData();
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginFacebook;
