import { createContext, useState } from "react";
import { useMutation } from "react-query";
import { Message } from "semantic-ui-react";
import {
  fetchKeywordsForecast as keywordsForecastApi,
  fetchPotentialLeads as potentialLeadsApi,
  fetchSearchDemand as searchDemandApi,
} from "api/Home";
import { IKeywordsForecastData } from "api/Home/fetchKeywordsForecast";
import { ISearchDemandData } from "api/Home/fetchSearchDemand";
import { IPotentialLeadsData } from "api/Home/fetchPotentialLeads";

const DEFAULT_COUNTRY = {
  key: "us",
  value: "United States",
  flag: "us",
  text: "United States",
};

type TContext = {
  forecastData: IKeywordsForecastData | null;
  searchDemandData: ISearchDemandData | null;
  potentialLeadsData: IPotentialLeadsData | null;
  isLoading: boolean;
  handleCountryChange: (val: TCountry) => void;
  handleSearch: () => void;
  handleKeywordChange: (val: string) => void;
  keyword: string;
  country: TCountry;
};

export type TCountry = {
  key: string;
  value: string;
  flag: string;
  text: string;
};

export const SearchKeywordsContext = createContext<TContext>({
  forecastData: null,
  searchDemandData: null,
  potentialLeadsData: null,
  isLoading: false,
  handleSearch: () => {},
  handleCountryChange: () => {},
  handleKeywordChange: () => {},
  keyword: "",
  country: DEFAULT_COUNTRY,
});

const SearchKeywordProvider = ({ children }: { children: React.ReactNode }) => {
  const [keyword, setKeyword] = useState<string>("");
  const [country, setCountry] = useState<TCountry>(DEFAULT_COUNTRY);

  const [forecastData, setForecastData] =
    useState<IKeywordsForecastData | null>(null);
  const [searchDemandData, setSearchDemandData] =
    useState<ISearchDemandData | null>(null);
  const [potentialLeadsData, setPotentialLeadsData] =
    useState<IPotentialLeadsData | null>(null);

  const [error, setError] = useState("");
  const handleDismissError = () => setError("");

  const { mutate: fetchKeywordsForecast, isLoading: keywordsForecastLoading } =
    useMutation(keywordsForecastApi, {
      onSuccess: (data) => {
        setError("");
        data && setForecastData(data);
      },
      onError: ({ displayMessage }) => {
        setError(displayMessage);
      },
    });

  const { mutate: fetchSearchDemand, isLoading: searchDemandLoading } =
    useMutation(searchDemandApi, {
      onSuccess: (data) => {
        setError("");
        data && setSearchDemandData(data);
      },
      onError: ({ displayMessage }) => {
        setError(displayMessage);
      },
    });
  const { mutate: fetchPotentialLeads, isLoading: potentialLeadsLoading } =
    useMutation(potentialLeadsApi, {
      onSuccess: (data) => {
        setError("");
        data && setPotentialLeadsData(data);
      },
      onError: ({ displayMessage }) => {
        setError(displayMessage);
      },
    });

  const handleCountryChange = (newCountry: TCountry) => {
    setCountry(() => {
      if (keyword && country.value) {
        const payload = { country: newCountry.value, keyword };
        fetchKeywordsForecast(payload);
        fetchSearchDemand(payload);
      }
      return newCountry;
    });
  };

  const handleKeywordChange = (val: string) => {
    setKeyword(val);
  };

  const handleSearch = () => {
    if (keyword && country.value) {
      const payload = { country: country.value, keyword };
      fetchKeywordsForecast(payload);
      fetchSearchDemand(payload);
      fetchPotentialLeads(payload);
    }
  };

  return (
    <SearchKeywordsContext.Provider
      value={{
        forecastData,
        searchDemandData,
        potentialLeadsData,
        isLoading:
          keywordsForecastLoading ||
          searchDemandLoading ||
          potentialLeadsLoading,
        handleSearch,
        handleCountryChange,
        handleKeywordChange,
        keyword,
        country,
      }}
    >
      <Message
        hidden={!error}
        error
        content={error}
        onDismiss={handleDismissError}
      />
      {children}
    </SearchKeywordsContext.Provider>
  );
};

export default SearchKeywordProvider;
