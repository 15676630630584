import { v4 as uuid } from "uuid";
import { useLeads } from "hooks/Twitter";
import { Flex, Text } from "components/core";
import LeadsCard from "./LeadsCard";
import { ConnectTwitter } from "../common";
import { useTwitterAuth } from "hooks";
import { LeadsWrapper } from "./LeadsList.styles";
import Masonry from "react-masonry-css";
import { useMediaQuery } from "@mui/material";

const LeadsList = () => {
  const { cards, isTwitterConnected } = useLeads();
  const { isSignedIn } = useTwitterAuth();
  const isMobileScreen = useMediaQuery("(max-width: 425px)");
  const isTabletScreen = useMediaQuery("(max-width: 768px)");

  if (isTwitterConnected && !isSignedIn) {
    return <ConnectTwitter connect={isTwitterConnected} />;
  }

  if (cards.length === 0) {
    return (
      <Flex justify="center">
        <Text
          as="span"
          variant="alpha"
          content="You haven't added any Potential Customer yet, add them from Lead!"
        />
      </Flex>
    );
  }

  return (
    <LeadsWrapper>
      <Masonry
        breakpointCols={isMobileScreen ? 1 : isTabletScreen ? 2 : 3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {cards.map((tweet) => (
          <LeadsCard tweet={tweet} key={uuid()} />
        ))}
      </Masonry>
    </LeadsWrapper>
  );
};

export default LeadsList;
