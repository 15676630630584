import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  postId: 0,
};

export const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setPostId: (state, action: PayloadAction<number>) => {
      return { ...state, postId: action.payload };
    },
  },
});

export const { setPostId } = blog.actions;

export default blog.reducer;
