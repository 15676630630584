import styled from "styled-components";

type TContainerProps = {
  selected?: boolean;
};

const Container = styled.div<TContainerProps>`
  width: 48px;
  height: 48px;
  padding: 2px;
  border: ${({ selected }) =>
    selected ? "2px solid #355fa3" : "2px solid #e9e9e9"};
  border-radius: 50%;
  cursor: pointer;
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export { Container, Image };
