import { useEffect, useState } from "react";

interface IUseChangingTextOptions {
  reInitialize?: boolean;
  timeout?: number;
}

const useChangingText = <T>(
  textData: T[],
  options?: IUseChangingTextOptions
) => {
  /* Component where this hook will be used needs to be remounted to reset the timer */

  const [index, setIndex] = useState(0);
  const { timeout = 5 } = options || {};

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((current) =>
        current < textData.length - 1 ? current + 1 : current
      );
    }, timeout * 1000);
    return () => clearInterval(interval);
  }, [setIndex, textData.length, timeout]);

  return { currentText: textData[index] };
};

export default useChangingText;
