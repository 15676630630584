import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setRecommendation } from "redux/slices/ProductDemand";
import { TRecommendation } from "adapters/ProductDemand/types";

const useRecommendation = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state) => state.productDemand.recommendation.data
  );

  return {
    data,
    setRecommendation: (data: TRecommendation[]) =>
      dispatch(setRecommendation(data)),
  };
};

export default useRecommendation;
