import {
  Container,
  InnerContainer,
  Header,
  Description,
  Content,
  Button,
  IllutstationContainer,
} from "./Signup.styles";
import illustration from "assets/images/Home/sign-up-illustration.png";
import { useNavigation } from "hooks";

const Signup = () => {
  const nav = useNavigation();

  return (
    <Container>
      <InnerContainer>
        <IllutstationContainer>
          <img src={illustration} alt="sign-up-dolphyn" />
        </IllutstationContainer>
        <Content>
          <Header>Dive-In</Header>
          <Description>
            Our Plans Start with
            <span> just $1</span>
            <br />
            <span> No credit Card </span>
            required.
          </Description>
          <Button
            variant="alpha"
            fluid
            content="Free Trial"
            onClick={() => nav.navigate((routes) => routes.rootRoutes.signUp)}
          />
        </Content>
      </InnerContainer>
    </Container>
  );
};

export default Signup;
