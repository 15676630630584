import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TKeywordsDetails } from "api/Profile/keywordsDetails";

const initialState: TKeywordsDetails = {
  primaryKeyword: "",
  secondaryKeywords: ["", "", "", "", "", "", "", "", ""],
};

export const keywordsDetails = createSlice({
  name: "keywordsDetails",
  initialState,
  reducers: {
    setPrimaryKeyword: (state, action: PayloadAction<string>) => {
      state.primaryKeyword = action.payload;
    },
    setSecondaryKeywords: (
      state,
      action: PayloadAction<TKeywordsDetails["secondaryKeywords"]>
    ) => {
      state.secondaryKeywords = action.payload;
    },
  },
});

export const { setPrimaryKeyword, setSecondaryKeywords } =
  keywordsDetails.actions;

export default keywordsDetails.reducer;
