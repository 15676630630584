import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  height: 85%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, minmax(50px, 216px));
`;

const Monthbox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-collapse: collapse;
  ${helper.getFontWeight("medium")}
  ${helper.getFontSize("small")}
  color: ${({ theme }) => theme.colors.darkGrey};
  overflow: auto;
  ${helper.getStyledScrollbar()}
`;
const HeaderContainer = styled.div`
  height: 5%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const HeaderBox = styled.div`
  text-align: center;
  ${helper.getFontSize("small")}
  ${helper.getFontWeight("medium")}
  color: ${({ theme }) => theme.colors.darkGrey};
`;

const DateContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${helper.getSpacing("extraSmall")};
  padding-bottom: 0;
  margin-bottom: ${helper.getSpacing("extraSmall")};
`;

export { Container, Monthbox, HeaderContainer, HeaderBox, DateContainer };
