import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Twitter";
import { KeywordListeningAdapter } from "adapters/Twitter";
import { APIError } from "errors";
import { ITweetSearchPayload } from "adapters/Twitter/types";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/tweets");
const adapter = new KeywordListeningAdapter();

const keywordListening = async (payload: ITweetSearchPayload) => {
  try {
    if (payload.isAuthenticated) {
      const apiPayload = adapter.getPostPayload(payload);
      const headers = adapter.getConfig();
      const { data } = await dolphynAPI.post(URL, apiPayload, headers);
      return adapter.parseGetResponse(data);
    }
    const apiPayload = adapter.getPostPayload(payload);
    const { data } = await dolphynAPI.post(URL, apiPayload);
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default keywordListening;
