import { Container, Body } from "./PostCard.styles";
import PlatformIcon, { IPlatformIconProps } from "./PlatformIcon";
import Media from "./Media";
import Content, { IContentProps } from "./Content";
import Datetime, { IDatetimeProps } from "./Datetime";
import Toolbar, { IToolbarProps } from "./Toolbar";
import { TServerFile } from "api/SocialMediaPost/types";

interface IPostCardProps extends React.ComponentPropsWithoutRef<"div"> {
  platformIcon?: IPlatformIconProps["icon"];
  accountImage?: IToolbarProps["image"];
  accountName?: IToolbarProps["title"];
  media?: TServerFile[];
  text?: IContentProps["text"];
  datetime?: IDatetimeProps["datetime"];
  onEdit?: IToolbarProps["onEdit"];
  onDelete?: IToolbarProps["onDelete"];
}

const PostCard = ({
  children,
  platformIcon,
  accountImage,
  accountName,
  text,
  datetime,
  onEdit,
  onDelete,
  media,
  ...props
}: React.PropsWithChildren<IPostCardProps>) => {
  const renderMedia = () => {
    if (!media || !media.length) return null;

    const mediaProps = media.map((media) => ({
      id: media.id,
      type: media.fileType,
      src: media.fileUrl,
    }));

    return <Media media={mediaProps} style={{ marginBottom: "1rem" }} />;
  };

  const renderContent = () => {
    if (!media?.length)
      return (
        <Content
          style={{ maxHeight: "100%" }}
          truncateLength={600}
          text={text}
        />
      );

    return <Content text={text} />;
  };

  if (!children)
    return (
      <Container {...props}>
        <PlatformIcon icon={platformIcon} />
        <Toolbar
          image={accountImage}
          title={accountName}
          onEdit={onEdit}
          onDelete={onDelete}
        />
        <Body>
          {renderMedia()}
          {renderContent()}
        </Body>
        <Datetime datetime={datetime} />
      </Container>
    );

  return <Container {...props}>{children}</Container>;
};

PostCard.PlatformIcon = PlatformIcon;
PostCard.Toolbar = Toolbar;
PostCard.Body = Body;
PostCard.Content = Content;
PostCard.Media = Media;
PostCard.Datetime = Datetime;

export default PostCard;
