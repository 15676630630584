import { format } from "date-fns";
import { Container } from "./Datetime.styles";

export interface IDatetimeProps {
  datetime?: Date;
}

const Datetime = ({ datetime }: IDatetimeProps) => {
  const renderDateTime = () => {
    if (datetime) {
      const date = format(datetime, "MMMM dd, yyyy");
      const time = format(datetime, "hh:mm aa");
      return `${date} ${time}`;
    }
  };
  return <Container>{renderDateTime()}</Container>;
};

export default Datetime;
