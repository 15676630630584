import { activateTrialAdapter } from "adapters/Pricing";
import { TActivateTrialPayload } from "adapters/Pricing/types";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Pricing";
import { APIError } from "errors";

const URL = links.BASE_PRODUCT_URL.append("/plan/trial/activate");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new activateTrialAdapter();

const activateTrial = {
  post: async (values: TActivateTrialPayload) => {
    try {
      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default activateTrial;
