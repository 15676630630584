import { AccountCard } from "components/common";
import { ReactComponent as InstagramIcon } from "assets/icons/SocialMediaPost/social-media-post-instagram-icon.svg";
import { useInstagramAuth } from "hooks/Auth";

const InstagramAccountCard = () => {
  const instagram = useInstagramAuth();

  return (
    <>
      <AccountCard>
        <AccountCard.Logo icon={<InstagramIcon />} />
        {instagram.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.name}
            image={account.profileImageUrl}
            onDelete={() => instagram.removeAccount(account.uid)}
            active={instagram.isAccountSelected(account.uid)}
            onClick={() => instagram.selectAccount(account.uid)}
          />
        ))}
      </AccountCard>
    </>
  );
};

export default InstagramAccountCard;
