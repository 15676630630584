import { TActions } from "adapters/Twitter/types";
import { Text, Linkify, Header } from "components/core";
import { ActionsCardContainer } from "./ActionsCard.styles";

interface IActionsCardProps {
  actions: TActions[];
}

const ActionsCard = ({ actions }: IActionsCardProps) => {
  return (
    <div>
      <Header
        size="small"
        font="poppins"
        variant="black"
        content="Actions need to be done"
        mb="small"
      />
      <ActionsCardContainer>
        {actions.map(({ text }) => (
          <Text style={{ fontSize: "15px" }}>
            <Linkify contentText="Click here">{text}</Linkify>
          </Text>
        ))}
      </ActionsCardContainer>
    </div>
  );
};

export default ActionsCard;
