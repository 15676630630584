import { useEffect } from "react";
import { Grid } from "./SchedulePost.styles";
import { usePendo, useSubHeading } from "hooks/common";
import {
  scheduleSecondaryPaneMap,
  subHeadingMap,
} from "config/SocialMediaPost";
import {
  PostForm,
  PostPreview,
  CampaignBuilderForm,
} from "components/SocialMediaPost/SchedulePost";
import { useScheduleSecondaryPane } from "hooks/SocialMediaPost";
import { ScheduleFormProvider } from "context/SocialMediaPost";

const SchedulePost = () => {
  const secondaryPane = useScheduleSecondaryPane();
  const [, setHeading] = useSubHeading();
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeading(subHeadingMap.schedulePost);
  }, [setHeading]);

  const renderSecondaryPane = () => {
    switch (secondaryPane.currentPane) {
      case scheduleSecondaryPaneMap.preview:
        return <PostPreview />;

      case scheduleSecondaryPaneMap.utm:
        return <CampaignBuilderForm />;

      default:
        break;
    }
  };

  return (
    <Grid>
      <ScheduleFormProvider>
        <PostForm />
        {renderSecondaryPane()}
      </ScheduleFormProvider>
    </Grid>
  );
};

export default SchedulePost;
