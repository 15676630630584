import { CalendarDaysHelper } from "helpers";

const useCalendarDays = () => {
  /* 
    Hook to handle state operations of Date[] to be used in Calendar Month view
  
  */

  const helper = new CalendarDaysHelper();
  /* Include today's date as only date in array */
  const getViewDate = () => [new Date()];
  const getNextDayDate = (dates: Date[]) => {
    return [helper.getNextDay(dates[0])];
  };
  const getPreviousDayDate = (dates: Date[]) => {
    return [helper.getPreviousDay(dates[0])];
  };

  return {
    getViewDate,
    getNextDayDate,
    getPreviousDayDate,
  };
};

export default useCalendarDays;
