import { useReactQuery, useNotification } from "hooks/common";
import { changePassword as api } from "api/Profile";

const useChangePassword = () => {
  const notification = useNotification();

  return useReactQuery(api.post, {
    onSuccess: ({ message }) => {
      notification.notifySuccess(message);
    },
  });
};

export default useChangePassword;
