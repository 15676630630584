import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { InfluencersListAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { IAuthenticatedPayload } from "./types";

const URL = links.baseTwitterApiUrl.append("/influencer_list");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new InfluencersListAdapter();

const fetchInfluencersList = async ({
  isAuthenticated,
}: IAuthenticatedPayload) => {
  try {
    if (isAuthenticated) {
      const headers = adapter.getConfig();
      const { data } = await dolphynAPI(URL, headers);
      return adapter.parseGetResponse(data);
    }

    const { data } = await dolphynAPI(URL);
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchInfluencersList;
