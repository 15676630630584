import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("small")};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 5px;
`;

const Body = styled.div`
  height: min(30vh, 250px);
`;

export { Container, Body };
