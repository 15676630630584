import { SchedulePostAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/SocialMediaPost";
import { APIError } from "errors";
import { ISchedulePostPayload } from "./types";

const error = new APIError();
const URL = links.scheduleBaseUrl.append("/twitter");
const adapter = new SchedulePostAdapter();

const SchedulePost = {
  post: async (formValues: ISchedulePostPayload) => {
    try {
      const headers = adapter.getConfig();
      const apiPayload = adapter.getPostPayload(formValues);
      const { data } = await dolphynAPI.post(URL, apiPayload, headers);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default SchedulePost;
