import styled from "styled-components";
import Navigator from "./Navigator";

const Container = styled.div`
  padding-left: 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface ISubNavigationProps {
  data: {
    title: string;
    route: string;
  }[];
}

const SubNavigation: React.FC<ISubNavigationProps> = ({ data }) => {
  return (
    <Container>
      {data.map(({ title, route }, index) => (
        <Navigator key={index} path={route} title={title} />
      ))}
    </Container>
  );
};

export default SubNavigation;
