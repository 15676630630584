import { ReactComponent as AddFollowerIcon } from "assets/icons/Twitter/add-follower.svg";
import { ReactComponent as AddInfluencerIcon } from "assets/icons/Twitter/add-influencer.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/Twitter/remove.svg";
import { ReactComponent as DMIcon } from "assets/icons/Twitter/dm.svg";
import { ReactComponent as LocationIcon } from "assets/icons/Twitter/location.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/common/calendar.svg";
import { TTwittercard } from "api/Home/fetchPotentialLeads";
import { TweetCard } from "components/Twitter/common";
import { StyledTwitterIcon } from "components/Twitter/common/Common.styles";

interface ITwitterCardProps {
  data: TTwittercard;
  onAction: () => void;
}

const TwitterCard = ({ data, onAction }: ITwitterCardProps) => {
  return (
    <TweetCard>
      <TweetCard.Image src={data.profilePicUrl} />
      <TweetCard.Content>
        <TweetCard.Head>
          <TweetCard.Username name={data.name} username={data.username} />
          <TweetCard.Action>
            <TweetCard.IconButton icon={AddInfluencerIcon} onClick={onAction} />
            <TweetCard.IconButton icon={AddFollowerIcon} onClick={onAction} />
            <TweetCard.IconButton icon={DMIcon} onClick={onAction} />
            <TweetCard.IconButton icon={RemoveIcon} onClick={onAction} />
          </TweetCard.Action>
        </TweetCard.Head>
        <TweetCard.Bio>{data.bio}</TweetCard.Bio>
        <TweetCard.Stats>
          <TweetCard.Stat title="Following" value={data.followings} />
          <TweetCard.Stat title="Followers" value={data.followers} />
          <TweetCard.Stat icon={StyledTwitterIcon} value={data.tweets} />
        </TweetCard.Stats>
        <TweetCard.Stats style={{ gridTemplateColumns: "1fr 1fr" }}>
          <TweetCard.Stat icon={CalendarIcon} value={data.joinedSince} />
          <TweetCard.Stat icon={LocationIcon} value={data.location} />
        </TweetCard.Stats>
      </TweetCard.Content>
    </TweetCard>
  );
};

export default TwitterCard;
