import { createContext, useEffect, useState } from "react";
import { useReactQuery } from "hooks/common";
import { scheduleRetweet } from "api/Twitter";
import { Loader } from "components/common";
import { IScheduleRetweet } from "adapters/Twitter/types";
import { useTwitterSchedule, useFilterHelper } from "hooks/Twitter";
import {
  IScheduleRetweetCreateFormValues,
  IScheduleRetweetDeleteFormValues,
  IScheduleRetweetUpdateFormValues,
} from "./types";
import { scheduleRetweetBtnMsg, scheduleRetweetLoader } from "config/Twitter";
import { useTwitterAuth } from "hooks";

type TScheduleRetweetContext = {
  retweetCards: IScheduleRetweet["scheduleRetweet"];
  tweetActions: IScheduleRetweet["tweetActions"];
  handleSchedule: (formValues: IScheduleRetweetCreateFormValues) => void;
  handleUpdate: (formValues: IScheduleRetweetUpdateFormValues) => void;
  handleDelete: (formValues: IScheduleRetweetDeleteFormValues) => void;
  handleFilter: (value: string) => void;
};

export const ScheduleRetweetContext =
  createContext<TScheduleRetweetContext | null>(null);

const ScheduleRetweetProvider = (props: React.PropsWithChildren<{}>) => {
  const [retweetCards, setRetweetCards] = useState<
    IScheduleRetweet["scheduleRetweet"]
  >([]);
  const [tweetActions, setTweetActions] =
    useState<IScheduleRetweet["tweetActions"]>(false);
  const schedule = useTwitterSchedule();
  const filterHelper = useFilterHelper();
  const { isSignedIn, selectedAccount } = useTwitterAuth();

  const { execute, isLoading } = useReactQuery(
    scheduleRetweet.getScheduleRetweet,
    {
      onSuccess: (data) => {
        setRetweetCards(data.scheduleRetweet);
        setTweetActions(data.tweetActions);
      },
    }
  );

  useEffect(() => {
    execute({ isAuthenticated: isSignedIn });
  }, [execute, isSignedIn, selectedAccount.userName]);

  const handleSchedule = (formValues: IScheduleRetweetCreateFormValues) => {
    schedule.create(
      { isAuthenticated: isSignedIn, ...formValues },
      {
        onSuccess: (data) => {
          if (data) {
            setRetweetCards(data.scheduleRetweet);
          }
        },
      }
    );
  };

  const handleDelete = (formValues: IScheduleRetweetDeleteFormValues) => {
    schedule.remove(
      { isAuthenticated: isSignedIn, ...formValues },
      {
        onSuccess: (data) => {
          if (data) {
            setRetweetCards(data.scheduleRetweet);
          }
        },
      }
    );
  };

  const handleUpdate = (formValues: IScheduleRetweetUpdateFormValues) => {
    schedule.update(
      { isAuthenticated: isSignedIn, ...formValues },
      {
        onSuccess: (data) => {
          if (data) {
            setRetweetCards(data.scheduleRetweet);
          }
        },
      }
    );
  };

  const handleFilter = (value: string) => {
    if (value === "RETWEETED") {
      setRetweetCards((current) => filterHelper.posted(current));
    }

    if (value === "SCHEDULED") {
      setRetweetCards((current) => filterHelper.scheduled(current));
    }
  };

  return (
    <ScheduleRetweetContext.Provider
      value={{
        retweetCards,
        tweetActions,
        handleDelete,
        handleSchedule,
        handleUpdate,
        handleFilter,
      }}
    >
      <Loader textData={scheduleRetweetLoader} hidden={!isLoading} />
      <Loader textData={scheduleRetweetBtnMsg} hidden={!schedule.isLoading} />
      {props.children}
    </ScheduleRetweetContext.Provider>
  );
};

export default ScheduleRetweetProvider;
