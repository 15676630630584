import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { Icon } from "components/core";

const helper = new ThemeHelper();

const CardContainer = styled.div`
  display: flex;
  padding: ${helper.getSpacing("small")};
  gap: ${helper.getSpacing("small")};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 13px;
  gap: ${helper.getSpacing("extraSmall")};
`;

const ImageContainer = styled.div`
  width: 32px;
`;

const UserProfile = styled.div`
  display: flex;
  gap: 0.25rem;

  .underlined {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const Line = styled.div`
  width: 2px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #e9e9e9;
`;

const TweetText = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  white-space: pre-line;
  font-size: 13px;
`;

const FilesPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FileContainer = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 10px;
`;

const ActionSection = styled.div`
  padding: 0.5rem 0;
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

const RemoveIcon = styled(Icon).attrs({
  name: "trash",
})`
  margin-left: auto;
  color: #5f5f5f;
  cursor: pointer;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export {
  CardContainer,
  Content,
  ImageContainer,
  UserProfile,
  Line,
  Image,
  TweetText,
  FilesPreview,
  FileContainer,
  ActionSection,
  RemoveIcon,
  StyledImage,
};
