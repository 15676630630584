import { useAudit } from "hooks/Twitter";
import BestPractices from "./BestPractices";
import { AuditRecommendation } from "../Recommendation";
import { ConnectTwitter } from "../common";
import { useTwitterAuth } from "hooks";
import {
  TwitterAuditContainer,
  RecommendationContainer,
} from "./TwitterAudit.styles";
import TwitterAuditGraph from "./TwitterAuditGraph";
import ActionsCard from "./ActionsCard";

const TwitterAudit = () => {
  const {
    data: { tweetActions, twitterAudit, actions, bestPractices },
  } = useAudit();
  const { isSignedIn } = useTwitterAuth();

  if (!tweetActions && !isSignedIn) {
    return <ConnectTwitter connect />;
  }

  return (
    <TwitterAuditContainer>
      <TwitterAuditGraph data={twitterAudit} />
      <ActionsCard actions={actions} />
      <BestPractices bestPractices={bestPractices} />
      <RecommendationContainer>
        <AuditRecommendation id="audit" />
      </RecommendationContainer>
    </TwitterAuditContainer>
  );
};

export default TwitterAudit;
