import { AuthFormIllustration } from "./common";
import illustration from "assets/images/Auth/signin-illustration.png";

const ResetPasswordIllustration = () => {
  return (
    <AuthFormIllustration
      header="Reset Your Password"
      subHeader="Enter your details to reset your password!"
      illustration={illustration}
    />
  );
};

export default ResetPasswordIllustration;
