import { Header, Text } from "components/core";
import { DefaultColors } from "styled-components";
import {
  Container,
  MediaContainer,
  Content,
  TContainerProps,
} from "./CardSection.styles";

interface ICardContentProps {
  header?: string;
  description?: string;
  content?: React.ReactElement;
  variant?: keyof DefaultColors;
}

const CardContent = ({
  header,
  description,
  content,
  variant = "darkGrey",
}: ICardContentProps) => {
  return (
    <Content>
      <Header
        variant={variant}
        size="medium"
        font="poppins"
        fontWeight="high"
        content={header}
      />
      <Text
        variant="darkGrey"
        font="poppins"
        size="small"
        content={description}
      />
      {content}
    </Content>
  );
};

export interface ICardSectionProps
  extends TContainerProps,
    ICardContentProps,
    React.ComponentPropsWithoutRef<"div"> {
  media?: React.ReactElement;
}

const CardSection = ({
  header,
  description,
  content,
  media,
  variant,
  children,
  ...props
}: ICardSectionProps) => {
  if (children) return <Container {...props}>{children}</Container>;

  return (
    <Container {...props}>
      <CardContent
        header={header}
        description={description}
        content={content}
        variant={variant}
      />
      <MediaContainer>{media}</MediaContainer>
    </Container>
  );
};

CardSection.Content = CardContent;
CardSection.MediaContent = MediaContainer;

export default CardSection;
