import { Button, Text } from "components/core";
import { useTwitterAuth } from "hooks";
import { TTwitterAccount } from "services/Auth/twitterService";
import { Container, Image } from "./SingleAccount.styles";

type TCurrentAccountPorps = {
  account: TTwitterAccount;
};

const CurrentAccount = ({ account }: TCurrentAccountPorps) => {
  const { selectAccount, selectedAccount } = useTwitterAuth();

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Container style={{ margin: "0 auto" }} selected>
        <Image src={account.profileImageUrl} alt={account.userName} />
      </Container>
      <Text content={account.name} />
      <Text content={account.userName} />
      <Button
        variant="alpha"
        size="mini"
        mt="extraSmall"
        onClick={() => selectAccount(account.uid)}
      >
        {selectedAccount.userName === account.userName ? "Selected" : "Select"}
      </Button>
    </div>
  );
};

export default CurrentAccount;
