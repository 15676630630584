import { useState } from "react";
import { useMutation } from "react-query";
import { Formik } from "formik";
import * as Yup from "yup";
import type { IError, IResponse } from "api/Home/subscribe";
import { subscribe as subscribeApi } from "api/Home";
import {
  Container,
  InnerContainer,
  Background,
  Form,
  Header,
  Description,
  InputButton,
} from "./Newsletter.styles";
import backgroundImage from "assets/images/Home/newsletter-background.png";
import { Message } from "semantic-ui-react";

const intialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Please enter a valid email."),
});

const Newsletter = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { mutate: subscribe, isLoading } = useMutation<
    IResponse,
    IError,
    string,
    unknown
  >(subscribeApi, {
    onSuccess: (data) => {
      setError("");
      setSuccess(data.message);
    },
    onError: (data) => {
      setError(data.message);
      setSuccess("");
    },
  });
  const handleSubmit = (values: typeof intialValues) => subscribe(values.email);
  return (
    <Container>
      <InnerContainer>
        <Background src={backgroundImage} alt="newsletter-background" />
        <Formik
          initialValues={intialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Header>Newsletter</Header>
              <Description>
                Subscribe Our <br /> Newsletter
              </Description>
              <InputButton>
                <InputButton.Input
                  name="email"
                  placeholder="Enter your email..."
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                />
                <InputButton.Button variant="alpha" loading={isLoading}>
                  Subscribe
                </InputButton.Button>
              </InputButton>

              <Message
                error
                content={props.errors.email || error}
                hidden={
                  error === "" && !(props.touched.email && props.errors.email)
                }
              />
              <Message
                style={{ display: success === "" ? "none" : "block" }}
                success
                content={success}
              />
            </Form>
          )}
        </Formik>
      </InnerContainer>
    </Container>
  );
};

export default Newsletter;
