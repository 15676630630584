import { Header, Modal } from "components/core";
import TermsConditionsStatic from "./TermsConditionsStatic";

interface ITermsConditionsProps {
  open: boolean;
  onClose: () => void;
  onOutsideClick?: () => void;
}

const TermsConditions = (props: ITermsConditionsProps) => {
  return (
    <Modal {...props}>
      <Header
        as="h3"
        content="Terms and Conditions:"
        style={{ marginBottom: "2rem" }}
      />
      <TermsConditionsStatic />
    </Modal>
  );
};

export default TermsConditions;
