import { PostCard } from "../common";
import { platformsIconMap, TPlatform } from "config/SocialMediaPost/platforms";
import { TServerFile } from "api/SocialMediaPost/types";

interface IViewCardProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "id"> {
  id: number | string;
  text: string;
  name: string;
  profileImageUrl: string;
  datetime: Date;
  serverFiles: TServerFile[];
  platform: TPlatform;
  onEdit: () => void;
  onDelete: () => void;
  editable?: boolean;
  deletable?: boolean;
}

const CalendarCard = ({
  id,
  platform,
  profileImageUrl,
  name,
  text,
  onEdit,
  onDelete,
  datetime,
  serverFiles,
  editable,
  deletable,
}: IViewCardProps) => {
  return (
    <PostCard
      key={id}
      platformIcon={platformsIconMap[platform].icon}
      accountImage={profileImageUrl}
      accountName={name}
      text={text}
      onEdit={editable ? () => onEdit() : undefined}
      onDelete={deletable ? () => onDelete() : undefined}
      datetime={datetime}
      media={serverFiles}
      style={{ height: "100%" }}
    />
  );
};

export default CalendarCard;
