import { TwitterService } from "services/Auth";
import { TRetweetPayload } from "./types";

type TAPIPayload = {
  datetime: string;
};

type TAPIResponse = {
  retweet: boolean;
};

class RetweetTweetAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      retweet: apiResponse.retweet,
    };
  };

  getPostUrl = (url: string, payload: TRetweetPayload) =>
    `${url}/${payload.tweetId}`;

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = ({
    datetime = new Date(),
  }: TRetweetPayload): TAPIPayload => {
    return {
      datetime: datetime.toISOString(),
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default RetweetTweetAdapter;
