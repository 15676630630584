import * as Yup from "yup";

export default Yup.object({
  primaryKeyword: Yup.string().required("Primary keyword cannot be empty."),
  secondaryKeywords: Yup.array()
    .of(Yup.string())
    .test(
      "at-least-one-keyword",
      "Please enter at least one Secondary keyword.",
      (keywords) => {
        const filteredKeywords = keywords?.filter(
          (keyword = "") => keyword !== ""
        );
        if (!filteredKeywords) return false;
        return filteredKeywords.length > 0;
      }
    )
    .max(9),
});
