import { Route, RouteProps } from "react-router";

export interface IReactChildren {
  children: React.ReactNode;
}

export type TLayout = ({ children }: IReactChildren) => JSX.Element;

export interface ISimpleRouteProps extends RouteProps {
  children: React.ReactNode;
  layout?: TLayout;
}

const SimpleRoute = ({
  layout: Layout,
  children,
  ...props
}: ISimpleRouteProps) => {
  if (Layout)
    return (
      <Route {...props}>
        <Layout>{children}</Layout>
      </Route>
    );

  return <Route {...props}>{children}</Route>;
};

export default SimpleRoute;
