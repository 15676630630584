import { useEffect, useState } from "react";

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== "undefined") {
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

const useWindowVisibilityChange = (runTask, time = 250000) => {
  const [task, setTask] = useState(false);

  useEffect(() => {
    if (task) {
      runTask();
      setTask(false);
    }

    const timer = setInterval(() => {
      setTask(true);
    }, time);

    return () => clearInterval(timer);
  }, [task, runTask, time]);

  const handleVisibilityChange = () => {
    if (!document[hidden]) {
      setTask(true);
    }
  };

  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }, []);
};

export default useWindowVisibilityChange;
