import { v4 as uuid } from "uuid";
import { Box } from "components/core";
import { useRecommendation } from "hooks/Twitter";
import List from "./List";
import { TRecommendation } from "adapters/Twitter/types";

interface IAuditRecommendationProps {
  id: string;
}

const AuditRecommendation = (props: IAuditRecommendationProps) => {
  const recommendation = useRecommendation();

  const renderRecommendation = () => {
    return recommendation.data.map(
      ({ title, statements: data }: TRecommendation) => (
        <List key={uuid()} title={title} data={data} />
      )
    );
  };

  return (
    <Box
      variant="beta"
      pt="small"
      pb="small"
      ps="small"
      pe="small"
      shadow={false}
      style={{ overflow: "auto" }}
      {...props}
    >
      {renderRecommendation()}
    </Box>
  );
};

export default AuditRecommendation;
