import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPeriodType, TPeriod } from "config/WebAnalytics";
import { PerformanceMenuService } from "services/WebAnalytics";

export interface ITrackPerformanceMenuState {
  period: TPeriod;
  periodType: TPeriodType;
}

const performaneMenuService = new PerformanceMenuService();

const initialState: ITrackPerformanceMenuState =
  performaneMenuService.getMenu();

export const trackPerformanceMenu = createSlice({
  name: "trackPerformanceMenu",
  initialState,
  reducers: {
    setTrackPerformancePeriod: (state, action: PayloadAction<number>) => {
      performaneMenuService.setMenu({
        ...state,
        period: action.payload,
      });

      state.period = action.payload;
    },
    setTrackPerformancePeriodType: (
      state,
      action: PayloadAction<TPeriodType>
    ) => {
      performaneMenuService.setMenu({
        ...state,
        periodType: action.payload,
      });

      state.periodType = action.payload;
    },
    increaseTrackPerformancePeriod: (state) => {
      performaneMenuService.setMenu({
        ...state,
        period: state.period + 1,
      });

      state.period = state.period + 1;
    },
    decreaseTrackPerformancePeriod: (state) => {
      performaneMenuService.setMenu({
        ...state,
        period: state.period - 1,
      });

      state.period = state.period - 1;
    },
  },
});

export const {
  setTrackPerformancePeriod,
  setTrackPerformancePeriodType,
  increaseTrackPerformancePeriod,
  decreaseTrackPerformancePeriod,
} = trackPerformanceMenu.actions;

export default trackPerformanceMenu.reducer;
