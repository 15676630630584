import { constants } from "config/Auth";
import BaseService, { TUid } from "./baseSocialAccountService";

export type TInstagramAccount = {
  tokenId: string;
  uid: TUid;
  profileImageUrl: string;
  name: string;
  id: string;
};

export type TInstagramPage = {
  name: string;
  id: string;
  profileImageUrl: string;
  pageName: string;
  pageId: string;
};

export type TInstagramPages = {
  uid: TUid;
  pages: TInstagramPage[];
};

class InstagramService extends BaseService<
  TInstagramAccount,
  TInstagramPages,
  TInstagramPage
> {
  constructor() {
    super(constants.INSTAGRAM_COOKIE, constants.INSTAGRAM_LOCAL_STORAGE);
  }

  setAuthCookie = (cookie: TInstagramAccount) => {
    super.accountExists(cookie.uid)
      ? super.updateAccount(cookie)
      : super.addNewAccount(cookie);
    const currentAccounts = super.getAccounts();
    if (currentAccounts.length === 1) {
      super.setSelectedAccount(currentAccounts[0].uid);
    }
  };

  setPages = (pages: TInstagramPages) => {
    super.pageListExists(pages.uid)
      ? super.updatePageList(pages)
      : super.addNewPageList(pages);
  };

  isAuthenticated = () => {
    return super.isAuthenticated();
  };

  removeAccount = (uid: TUid) => {
    super.removeAccount(uid);
  };

  getAccounts = () => super.getAccounts();

  clearData = () => {
    super.clearCookie();
    super.clearStorage();
  };

  getAccount = (uid: TUid) => {
    return super.getAccount(uid);
  };

  getPageLists = () => {
    return super.getPageLists();
  };

  getPages = (uid: TUid) => {
    return super.getPages(uid);
  };

  getPage = (uid: TUid, pageId: string) => {
    return super.getPage(uid, pageId);
  };

  filterPages = (uid: TUid, pageIds: string[]) => {
    return super.filterPages(uid, pageIds);
  };

  removePageList = (uid: TUid) => {
    super.removePageList(uid);
  };

  setSelectedAccount = (uid: TUid) => {
    super.setSelectedAccount(uid);
  };

  removeSelectedAccount = () => {
    super.reInitializeSelectedAccount({
      tokenId: "",
      uid: "",
      name: "",
      profileImageUrl: "",
      id: "",
    });
  };

  getSelectedAccount = () => {
    return super.getSelectedAccount({
      tokenId: "",
      uid: "",
      name: "",
      profileImageUrl: "",
      id: "",
    });
  };
}

export type { TUid } from "./baseSocialAccountService";

export default InstagramService;
