import { combineReducers } from "redux";
import selectedPlan from "./selectedPlan";
import plans from "./plans";

export default combineReducers({
  selectedPlan,
  plans,
});

export * from "./selectedPlan";
