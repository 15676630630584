import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postComposer: true,
  scheduledList: false,
  sentList: false,
};

export const current = createSlice({
  name: "current",
  initialState,
  reducers: {
    setComposer: (state) => {
      return {
        ...state,
        postComposer: true,
        scheduledList: false,
        sentList: false,
      };
    },
    setScheduled: (state) => {
      return {
        ...state,
        postComposer: false,
        scheduledList: true,
        sentList: false,
      };
    },
    setSent: (state) => {
      return {
        ...state,
        postComposer: false,
        scheduledList: false,
        sentList: true,
      };
    },
  },
});

export const { setComposer, setScheduled, setSent } = current.actions;

export default current.reducer;
