import styled, { css } from "styled-components";
import { IGoogleButtonStyleProps } from "./types";

const gTheme = {
  dark: {
    bg: "#4285F4",
    bgPressed: "#3367d6;",
    text: "#fff",
  },
  light: {
    bg: "#fff",
    bgPressed: "#eee",
    text: "rgba(0, 0, 0, 0.54)",
  },
  shadow: "rgba(0, 0, 0, 0.24)",
  shadowHovered: "rgba(66, 133, 244, 0.3)",
};

const Button = styled.button<IGoogleButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 4px 0 ${gTheme.shadow};
  padding: 0px;
  border-radius: 1px;
  gap: 10px;
  border: 1px solid transparent;
  outline: none;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 3px 3px ${gTheme.shadowHovered};
  }
  ${({ googleTheme }) => {
    return css`
      background-color: ${gTheme[googleTheme].bg};
      color: ${gTheme[googleTheme].text};
      &:active {
        cursor: "pointer";
        background-color: ${gTheme[googleTheme].bgPressed};
        color: ${gTheme[googleTheme].text};
        opacity: 1;
      }
    `;
  }};
`;

const Content = styled.span`
  padding: 12px;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.21px;
`;

export { Button, Content };
