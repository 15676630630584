import PulseLoader from "react-spinners/PulseLoader";
import { DefaultColors } from "styled-components";

interface Props {
  size?: number;
  variant?: keyof DefaultColors;
}

const Loader: React.FC<Props> = ({ size = 32, variant = "extraLightGrey" }) => {
  return (
    <PulseLoader
      color={variant}
      size={size}
      aria-label="Spinner"
      data-testid="pulse-loader"
    />
  );
};

export default Loader;
