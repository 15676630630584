import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TThreadObject } from "adapters/Twitter/types";

const initialState = {
  thread: [] as TThreadObject[],
  interval: 1,
  datetime: new Date(),
  tweetText: "",
  retweetDateTime: new Date(),
  autoRetweet: false,
};

export const thread = createSlice({
  name: "thread",
  initialState,
  reducers: {
    addToThread: (state, action: PayloadAction<TThreadObject>) => {
      return {
        ...state,
        thread: [...state.thread, action.payload],
      };
    },
    reinitializeThread: (state) => {
      return {
        ...state,
        thread: [],
      };
    },
    removeFromThread: (state, action) => {
      return {
        ...state,
        thread: state.thread.filter((th) => th.threadId !== action.payload),
      };
    },
    setInterval: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        interval: action.payload,
      };
    },
    setDatetime: (state, action: PayloadAction<Date>) => {
      return {
        ...state,
        datetime: action.payload,
      };
    },
    setTweetText: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tweetText: action.payload,
      };
    },
    setRetweetDateTime: (state, action: PayloadAction<Date>) => {
      return {
        ...state,
        retweetDateTime: action.payload,
      };
    },
    setAutoRetweet: (state) => {
      return {
        ...state,
        autoRetweet: !state.autoRetweet,
      };
    },
  },
});

export const {
  addToThread,
  reinitializeThread,
  removeFromThread,
  setInterval,
  setDatetime,
  setTweetText,
  setRetweetDateTime,
  setAutoRetweet,
} = thread.actions;

export default thread.reducer;
