import { useState } from "react";

type TUseToggleReturn = [boolean, (value?: boolean) => void];

const useToggle = (initialValue?: boolean): TUseToggleReturn => {
  const [current, setCurrent] = useState<boolean>(false || !!initialValue);
  const handleToggle = (value?: boolean) => {
    if (typeof value === "boolean") {
      setCurrent(value);
      return;
    }
    setCurrent((current) => !current);
  };

  return [current, handleToggle];
};

export default useToggle;
