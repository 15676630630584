import {
  CustomSpinner,
  EmojiPicker,
  FileAttacher,
  Textbox,
} from "components/common";
import { Button, Flex, LinkButton } from "components/core";
import { socialMediaCharactersLimit } from "config/common";
import { textKeys } from "config/Twitter";
import { useEffect, useState } from "react";
import { useToggle } from "hooks";
import { useReplyAIGenerated, useTextHelper } from "hooks/Twitter";
import { MessageEditor } from "../common";
import { Toolbar } from "../LeadGeneration/TweetReplyCard.styles";

interface IReplyCard {
  tweetId: string;
  tweetText: string;
  open?: boolean;
  onCancel: () => void;
  onSave?: () => void;
  text: string;
  onTextChange: (value: string) => void;
  placeholder?: string;
  files: File[];
  onFilesChange: (files: File[]) => void;
}

const ReplyCard = ({
  open,
  text,
  onTextChange,
  placeholder,
  onSave,
  onCancel,
  tweetId,
  tweetText,
  files,
  onFilesChange,
}: IReplyCard) => {
  const { text1Message, text2Message, setTextMessage } = useTextHelper();

  const [editorText1, setEditorText1] = useState(text1Message);
  const [editorText2, setEditorText2] = useState(text2Message);
  const [openEditor1, toggleEditor1] = useToggle();
  const [openEditor2, toggleEditor2] = useToggle();

  const replyAIGenerated = useReplyAIGenerated();

  const handleGenerateReply = () => {
    replyAIGenerated.execute(
      { tweetId, tweetText, sentiment: "positive" },
      {
        onSuccess: (data) => {
          if (data) onTextChange(data.reply);
        },
      }
    );
  };

  useEffect(() => {
    setEditorText1(text1Message);
    setEditorText2(text2Message);
  }, [text1Message, text2Message]);

  if (!open) return null;

  return (
    <Textbox
      placeholder={placeholder}
      value={text}
      onChange={(e) => {
        if (e.target.value.length - 1 < socialMediaCharactersLimit.twitter) {
          onTextChange(e.target.value);
        }
      }}
      style={{ position: "relative" }}
    >
      <MessageEditor
        open={openEditor1}
        text={editorText1}
        onCancel={() => toggleEditor1(false)}
        onTextChange={(value) => setEditorText1(value)}
        onSave={() => {
          setTextMessage(textKeys.Text1, editorText1);
          toggleEditor1(false);
        }}
      />
      <MessageEditor
        open={openEditor2}
        text={editorText2}
        onCancel={() => toggleEditor2(false)}
        onTextChange={(value) => setEditorText2(value)}
        onSave={() => {
          setTextMessage(textKeys.Text2, editorText2);
          toggleEditor2(false);
        }}
      />
      <Textbox.WordLimit
        remaining={socialMediaCharactersLimit.twitter - text.length}
      />
      <Textbox.Toolbar
        style={{ justifyContent: "space-between", background: "unset" }}
      >
        <Toolbar>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text1"
              size="mini"
              onClick={() => onTextChange(text + text1Message)}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="mini"
              onClick={() => toggleEditor1(true)}
            />
          </Flex>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text2"
              size="mini"
              onClick={() => onTextChange(text + text2Message)}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="mini"
              onClick={() => toggleEditor2(true)}
            />
          </Flex>
          <EmojiPicker onSelect={(emoji) => onTextChange(text + emoji)} />
          <FileAttacher onChange={onFilesChange} />
        </Toolbar>
        <Toolbar>
          <Button
            variant="alpha"
            size="mini"
            content={
              replyAIGenerated.isLoading ? <CustomSpinner size={12} /> : "AI"
            }
            onClick={handleGenerateReply}
          />
          <Button
            type="submit"
            variant="alpha"
            content="Save"
            size="mini"
            onClick={onSave}
          />

          <LinkButton content="Cancel" onClick={onCancel} />
        </Toolbar>
      </Textbox.Toolbar>
    </Textbox>
  );
};

export default ReplyCard;
