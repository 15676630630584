import styled from "styled-components";

import { ReactComponent as HeartIcon } from "assets/icons/SocialMediaPost/heart.svg";
import { ReactComponent as ReplyIcon } from "assets/icons/SocialMediaPost/comment.svg";
import { ReactComponent as ShareIcon } from "assets/icons/SocialMediaPost/share.svg";
import { ReactComponent as SaveIcon } from "assets/icons/SocialMediaPost/save.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  gap: 16px;

  svg {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  .save-icon {
    margin-left: auto;
  }
`;

const PostAction = () => {
  return (
    <Wrapper>
      <HeartIcon />
      <ReplyIcon />
      <ShareIcon />
      <SaveIcon className="save-icon" />
    </Wrapper>
  );
};

export default PostAction;
