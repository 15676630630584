import { dolphynAPI } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";
import TwitterService, { TTwitterAccount } from "services/Auth/twitterService";

type TAPIResponse = {
  token_id: string;
  user_id: string;
  name: string;
  user_name: string;
  profile_image: string;
};

const URL = links.BASE_AUTHENTICATION_API_URL.append("/twitter");

const service = new TwitterService();

const loginTwitter = async (redirectUrl: String) => {
  try {
    const response = await dolphynAPI.post(URL, {
      twitter_redirect: redirectUrl,
    });

    const data = response.data as TAPIResponse;

    const uid = service.getUid(data.user_name);

    const cookie: TTwitterAccount = {
      id: uid,
      uid,
      tokenId: data.token_id,
      name: data.name,
      profileImageUrl: data.profile_image,
      userName: data.user_name,
      userId: data.user_id,
    };

    service.setAuthCookie(cookie);

    return null;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginTwitter;
