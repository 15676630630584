import { useEffect } from "react";
import { Checkbox, Text } from "components/core";
import { useCheckboxToggleRef } from "hooks/common";
import { Container, Image, Details } from "./Item.styles";

interface IItemProps {
  image?: string;
  title?: string;
  meta?: string;
  onClick: () => void;
  checked: boolean;
}

const Item = ({ image, title, meta, checked, onClick }: IItemProps) => {
  const [checkboxRef, handleToggle] = useCheckboxToggleRef();

  const handleClick = () => {
    if (onClick) onClick();
  };

  useEffect(() => {
    handleToggle(checked);
  }, [checked, handleToggle]);

  return (
    <Container onClick={handleClick}>
      {image && <Image src={image} />}
      <Details>
        <Text
          as="div"
          content={title}
          font="poppins"
          size="extraSmall"
          variant="darkGrey"
          style={{
            width: "90px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
        {meta && <Text as="span" variant="darkGrey" content={meta} />}
      </Details>
      <Checkbox
        ref={checkboxRef}
        variant="success"
        circular
        style={{ pointerEvents: "none" }}
      />
    </Container>
  );
};

export default Item;
