import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  height: 100%;
`;

const Body = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  ${helper.getBorderRadius("small")};
  height: 100%;
`;

export { Container, Body };
