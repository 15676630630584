import { CookieService, LocalStorageService } from "services/common";

export type TUid = string;

type TCollectionWithUID = { uid: TUid; [prop: string]: any };

interface TPage {
  id: string;
  [prop: string]: any;
}

interface TAccount extends TCollectionWithUID {}

interface TPageList<T extends TPage> extends TCollectionWithUID {
  pages: T[];
}

class BaseSocialAccountService<
  T extends TAccount,
  U extends TPageList<V> = { uid: ""; pages: [] },
  V extends TPage = { id: "" }
> {
  private cookie: CookieService<T[]>;
  private storage: LocalStorageService<U[]>;
  private selectedAccount: LocalStorageService<T>;

  constructor(cookieKey: string, storageKey: string = "__unused__") {
    this.cookie = new CookieService(cookieKey);
    this.storage = new LocalStorageService(storageKey);
    this.selectedAccount = new LocalStorageService(
      `${storageKey}_selectedAccount`
    );
  }

  /* 
    functionality outsourcing
  
  */

  private getItemFromList = <W>(list: W[], key: keyof W, value: any) => {
    return list.find((item) => item[key] === value);
  };

  private itemsExistsInList = <W>(list: W[], key: keyof W, value: any) => {
    return list.findIndex((item) => item[key] === value) !== -1;
  };

  private updateItemInList = <W>(list: W[], key: keyof W, value: W) => {
    return list.map((item) => (item[key] === value[key] ? value : item));
  };

  private addNewItemInList = <W>(list: W[], value: W) => {
    return [...list, value];
  };

  private removeItemFromList = <W>(list: W[], key: keyof W, value: any) => {
    return list.filter((item) => item[key] !== value);
  };

  /* 
    Accounts handling methods
  */

  protected isAuthenticated() {
    return this.cookie.isCookiePresent();
  }

  protected getAccounts() {
    const currentCookie = this.cookie.getCookie() || [];
    return currentCookie;
  }

  protected getAccount(uid: TUid) {
    const currentCookie = this.cookie.getCookie() || [];
    const account = this.getItemFromList(currentCookie, "uid", uid);
    return account;
  }

  protected accountExists(uid: TUid) {
    const currentCookie = this.cookie.getCookie();
    if (!currentCookie) return false;
    return this.itemsExistsInList(currentCookie, "uid", uid);
  }

  protected updateAccount(cookie: T) {
    const currentCookie = this.cookie.getCookie() || [];
    const newCookie = this.updateItemInList(currentCookie, "uid", cookie);
    this.cookie.setCookie(newCookie);
  }

  protected addNewAccount(account: T) {
    const currentCookie = this.cookie.getCookie() || [];
    const newCookie = this.addNewItemInList(currentCookie, account);
    this.cookie.setCookie(newCookie);
  }

  protected removeAccount(uid: TUid) {
    const currentCookie = this.cookie.getCookie() || [];
    const newCookie = this.removeItemFromList(currentCookie, "uid", uid);
    if (newCookie.length > 0) {
      this.cookie.setCookie(newCookie);
      return;
    }
    this.cookie.removeCookie();
  }

  protected clearCookie() {
    this.cookie.removeCookie();
  }

  /* 
    Page List handling methods
  */

  protected getPageLists() {
    const pageLists = this.storage.getItem() || [];
    return pageLists;
  }

  protected getPages(uid: string) {
    const pageList = this.getItemFromList(this.getPageLists(), "uid", uid);
    const pages = pageList?.pages || [];
    return pages;
  }

  protected getPage(uid: string, pageId: string) {
    const pages = this.getPages(uid);
    if (pages) {
      const page = this.getItemFromList(pages, "id", pageId);
      return page;
    }
    return undefined;
  }

  protected filterPages(uid: string, pageIds: string[]) {
    const pages = this.getPages(uid);
    const newPages = pages.filter((page) => pageIds.includes(page.id));
    return newPages;
  }

  protected pageListExists(uid: string) {
    const pageLists = this.getPageLists();
    if (!pageLists) return false;
    return this.itemsExistsInList(pageLists, "uid", uid);
  }

  protected updatePageList(pages: U) {
    const pageLists = this.getPageLists();
    const newPages = this.updateItemInList(pageLists, "uid", pages);
    this.storage.setItem(newPages);
  }

  protected addNewPageList(pages: U) {
    const pageLists = this.getPageLists();
    const newPages = this.addNewItemInList(pageLists, pages);
    this.storage.setItem(newPages);
  }

  protected removePageList(uid: string) {
    const pageLists = this.getPageLists();
    const newPageList = this.removeItemFromList(pageLists, "uid", uid);
    this.storage.setItem(newPageList);
  }

  /* 
  
  selection handlers
  
  */

  protected setSelectedAccount(uid: TUid) {
    const account = this.getAccount(uid);
    if (account) {
      this.selectedAccount.setItem(account);
    }
  }

  protected reInitializeSelectedAccount(account: T) {
    this.selectedAccount.setItem(account);
  }

  protected getSelectedAccount(account: T) {
    return this.selectedAccount.getOrCreate(account);
  }

  protected clearStorage() {
    this.storage.removeItem();
    this.selectedAccount.removeItem();
  }

  /* 
    utility
  */

  getUid(accountId: string) {
    return window.btoa(accountId);
  }

  getAccountIdfromUid(uid: string) {
    return window.atob(uid);
  }
}

export default BaseSocialAccountService;
