import { LinkButton } from "components/core";
import { useScheduleSecondaryPane } from "hooks/SocialMediaPost";
import { Container } from "./CampaignBuilder.styles";

const CampaignBuilder = () => {
  const secondaryPane = useScheduleSecondaryPane();
  return (
    <Container>
      <LinkButton
        type="button"
        content="UTM parameter"
        onClick={() => secondaryPane.changePane((pane) => pane.utm)}
      />
    </Container>
  );
};

export default CampaignBuilder;
