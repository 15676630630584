import { InstagramService } from "services/Auth";
import {
  TScheduleCreatePayload,
  TScheduleUpdatePayload,
} from "api/SocialMediaPost/types";
import FileAdapter, { TFileAPIResponse } from "adapters/common/files";
import { platforms, platformsIconMap } from "config/SocialMediaPost/platforms";
import { TInstagramPostObject, TPostStatus } from "./types";
import { DateTimeHelper } from "helpers";

type TPOSTPayload = {
  token_id: string;
  page_credentials: {
    id: string;
    name: string;
  }[];
  account_id: string;
  message: string;
  files?: TFileAPIResponse;
  datetime: string;
  email_notify?: number;
};

type TResponseData = {
  post_id: string;
  account_id: string;
  page_name: string;
  page_id: string;
  social_media: string;
  message: string;
  files: TFileAPIResponse;
  datetime: string;
  post_status: string;
  email_notify: number;
};

type TPUTPayload = TPOSTPayload;

type TPOSTResponse = {
  data: TResponseData[];
  available_quota: {
    posts: number;
  };
};

type TPUTResponse = {
  data: TResponseData[];
  available_quota: {
    posts: number;
  };
};

type TGETResponse = {
  data: TResponseData[];
  total_pages: number;
};

const dateTime = new DateTimeHelper();

class ScheduleIGAdapter {
  private service: InstagramService = new InstagramService();
  private fileAdapter: FileAdapter = new FileAdapter();

  private getPostCredentials = (
    instagram: TScheduleCreatePayload["instagram"]
  ) => {
    const accountCred = this.service.getAccount(instagram.uid);

    const pageCred = this.service
      .filterPages(instagram.uid, instagram.pages)
      .map((page) => ({
        id: page.id,
        name: page.name,
      }));

    return { accountCred, pageCred };
  };

  private getPutCredentials = (
    instagram: TScheduleUpdatePayload["instagram"]
  ) => {
    if (instagram) {
      const accountCred = this.service.getAccount(instagram.uid);

      const page = this.service.getPage(instagram.uid, instagram.pageId);
      if (page) {
        const pageCred = [
          {
            id: page.id,
            name: page.name,
          },
        ];
        return { accountCred, pageCred };
      }
    }

    return { accountCred: null, pageCred: null };
  };

  private getProfileImageUrl = (accountId: string, pageId: string) => {
    const uid = this.service.getUid(accountId);

    const page = this.service.getPage(uid, pageId);
    if (page) return page.profileImageUrl;
    return "";
  };

  private parseData = (
    responseData: TResponseData[]
  ): TInstagramPostObject[] => {
    return responseData.map((postObject) => ({
      id: postObject.post_id,
      name: postObject.page_name,
      text: postObject.message,
      serverFiles: this.fileAdapter.parsePostResponse(postObject.files),
      datetime: dateTime.getUTCAsLocal(new Date(postObject.datetime)),
      postStatus: postObject.post_status as TPostStatus,
      platform: platforms.instagram,
      icon: platformsIconMap.instagram.icon,
      dayViewColId: platforms.instagram,
      profileImageUrl: this.getProfileImageUrl(
        postObject.account_id,
        postObject.page_id
      ),
      instagram: {
        uid: this.service.getUid(postObject.account_id),
        accountId: postObject.account_id,
        pageId: postObject.page_id,
        pageName: postObject.page_name,
      },
    }));
  };

  getHeaders = (uid: string) => {
    const account = this.service.getAccount(uid);
    if (account) {
      const headers = { "Instagram-Account": account.id };
      return headers;
    }
  };

  getPostPayload = ({
    datetime,
    text,
    instagram,
    serverFiles,
  }: TScheduleCreatePayload) => {
    const { accountCred, pageCred } = this.getPostCredentials(instagram);
    if (accountCred) {
      const payload: TPOSTPayload = {
        token_id: accountCred.tokenId,
        datetime: datetime.toISOString(),
        message: text,
        account_id: accountCred.id,
        page_credentials: pageCred,
        files: this.fileAdapter.parsePostValues(serverFiles),
      };
      return payload;
    }
    return null;
  };

  getPutPayload = ({
    datetime,
    text,
    instagram,
    serverFiles,
  }: TScheduleUpdatePayload) => {
    const { accountCred, pageCred } = this.getPutCredentials(instagram);
    if (accountCred && pageCred) {
      const payload: TPUTPayload = {
        token_id: accountCred.tokenId,
        datetime: datetime.toISOString(),
        message: text,
        account_id: accountCred.id,
        page_credentials: pageCred,
        files: this.fileAdapter.parsePostValues(serverFiles),
      };
      return payload;
    }
    return null;
  };

  parseCalendarResponse = (data: TGETResponse) => {
    return this.parseData(data.data);
  };

  parseActivityResponse = (data: TGETResponse) => {
    return {
      data: this.parseData(data.data),
      totalPages: data.total_pages,
    };
  };

  parsePutResponse = ({ data }: TPUTResponse): TInstagramPostObject =>
    this.parseData(data)[0];

  parsePostResponse = ({ data }: TPOSTResponse): TInstagramPostObject[] =>
    this.parseData(data);
}

export default ScheduleIGAdapter;
