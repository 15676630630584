import { createSlice } from "@reduxjs/toolkit";
import { GoogleAnalyticsService } from "services/WebAnalytics";

export interface googleAnalyticsAuthState {
  isSignedIn: boolean;
}

const service = new GoogleAnalyticsService();

const initialState: googleAnalyticsAuthState = {
  isSignedIn: service.isAuthenticated(),
};

export const googleAnalyticsAuth = createSlice({
  name: "googleAnalyticsAuth",
  initialState,
  reducers: {
    setGoogleAnalyticsSignedIn: (state) => {
      state.isSignedIn = true;
    },
    setGoogleAnalyticsSignedOut: (state) => {
      state.isSignedIn = false;
    },
    cleaGoogleAnalyticsData: (state) => {
      state.isSignedIn = false;
      service.clearData();
    },
  },
});

export const {
  setGoogleAnalyticsSignedIn,
  setGoogleAnalyticsSignedOut,
  cleaGoogleAnalyticsData,
} = googleAnalyticsAuth.actions;

export default googleAnalyticsAuth.reducer;
