import { TwitterService } from "services/Auth";
import { IInfluencersList } from "./types";

type TAPIResponse = {
  influencerList: string[];
  tweetActions: boolean;
};

class InfluencersListAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IInfluencersList => {
    return {
      influencerList: apiResponse.influencerList,
      tweetActions: apiResponse.tweetActions,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default InfluencersListAdapter;
