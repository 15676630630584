import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  position: fixed;
  height: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 1px 0px 4px
    ${({ theme }) => helper.transparentize(theme.colors.alpha)};

  #navigatorIcon {
    font-size: 10px;
    padding: 0.5em;
    i.icon.arrow {
      font-size: inherit;
    }
  }
`;

const Content = styled.div`
  height: 100%;
  transform-origin: left;
  min-width: max(12.5vw, 175px);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("medium")} ${helper.getSpacing("small")};
`;

const Body = styled.div`
  padding: ${helper.getSpacing("small")} 0;
`;

const Expander = styled.div`
  position: relative;
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.alpha};
  #navigatorIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export { Container, Header, Expander, Body, Content };
