import { Content } from "./Modal.styles";
import ModalView from "./ModalView";
import { IModalProps } from "./types";

const Modal = ({ children, ...props }: IModalProps) => {
  return (
    <ModalView {...props}>
      <Content>{children}</Content>
    </ModalView>
  );
};

Modal.View = ModalView;
Modal.Content = Content;

export default Modal;
