import { PostHeader, PostAction } from "./Facebook";
import { PostCard } from "./FacebookPreview.styles";
import { SelectInfo } from "./Common.styles";
import PostContent from "./PostContent";

type TDefaultProps = {
  facebook: any;
  text: string;
  files: File[];
};

const FacebookPreview = ({ facebook, text, files }: TDefaultProps) => {
  if (!facebook.length)
    return <SelectInfo>Select Facebook for Facebook Post Preview</SelectInfo>;

  return (
    <PostCard>
      <PostHeader
        profileImage={facebook[0].image}
        name={facebook[0].title}
        time={new Date().toISOString()}
      />
      <PostContent postText={text} postFiles={files} />
      <PostAction />
    </PostCard>
  );
};

export default FacebookPreview;
