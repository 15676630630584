import { utils, writeFile } from "xlsx";
import { IGenerateCouponPayload, IRedeemCouponPayload } from "api/admin/types";

type TAPIResponse = {
  message: string;
};

type TAPIPayload = {
  plan: string;
  no_of_coupons: number;
  acronym: string;
};

class CouponSystemAdapter {
  private parseResponse = (apiResponse: any) => {
    const worksheet = utils.json_to_sheet(apiResponse.coupon_codes);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet");
    writeFile(workbook, "dolphy_coupons.xlsx");
  };

  private parseRedeemResponse = (apiResponse: TAPIResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  getPostPayload = (data: IGenerateCouponPayload): TAPIPayload => {
    return {
      plan: data.plan,
      no_of_coupons: parseInt(data.noOfCoupons),
      acronym: data.acronym,
    };
  };

  getRedeemPayload = (data: IRedeemCouponPayload) => {
    return {
      coupon_code: data.coupon,
    };
  };

  getResponse = (apiResponse: any) => this.parseResponse(apiResponse);

  getRedeemResponse = (apiResponse: TAPIResponse) =>
    this.parseRedeemResponse(apiResponse);
}

export default CouponSystemAdapter;
