import { Fragment } from "react";
import { range } from "lodash";
import { getHours, isSameDay, format } from "date-fns";
import { useEventCalendar } from "hooks/common";
import { HeaderContainer, TimeBox, Body } from "./DayView.styles";
import { IBaseCalendarProps, TDayViewCol, TEventData } from "./types";

interface IDayViewProps<T extends TEventData, U extends TDayViewCol>
  extends IBaseCalendarProps<T, U> {}

const DayView = <T extends TEventData, U extends TDayViewCol>(
  props: IDayViewProps<T, U>
) => {
  const eventCalendar = useEventCalendar();

  const renderEvents = (dayViewColId: number | string, hour: number) => {
    const offsetDatetime = eventCalendar.getOffsetDatetime(
      eventCalendar.startDate,
      hour
    );
    const events = props.events.filter((event) => {
      return (
        isSameDay(event.datetime, eventCalendar.startDate) &&
        getHours(event.datetime) === getHours(offsetDatetime) &&
        event.dayViewColId === dayViewColId
      );
    });
    return props.renderEvents(events);
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <div> </div>
        {props.dayViewCols.map((col) => {
          const Component = col.icon;
          return <Component key={col.dayViewColId} />;
        })}
      </HeaderContainer>
    );
  };

  const renderHour = (hour: number) => {
    const offsetDatetime = eventCalendar.getOffsetDatetime(
      eventCalendar.startDate,
      hour
    );
    return format(offsetDatetime, "hh:mm a");
  };

  const renderBody = () => {
    const { timeGridOffset: offset = 0 } = props;
    return range(0 + offset, 24 + offset).map((hour) => (
      <Fragment key={hour}>
        <TimeBox>{renderHour(hour)}</TimeBox>
        {props.dayViewCols.map((col, key) => (
          <TimeBox key={key}>{renderEvents(col.dayViewColId, hour)}</TimeBox>
        ))}
      </Fragment>
    ));
  };

  return (
    <>
      {renderHeader()}
      <Body>{renderBody()}</Body>
    </>
  );
};

export default DayView;
