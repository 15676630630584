import { useReactQuery } from "hooks/common";
import { scheduleTwitter as api } from "api/SocialMediaPost";
import {
  TScheduleCreatePayload,
  TScheduleDeletePayload,
  TScheduleUpdatePayload,
} from "api/SocialMediaPost/types";
import { TTwitterPostObject } from "adapters/SocialMediaPost/types";

type TUpdateOptions = {
  onSuccess?: (data?: TTwitterPostObject) => void;
};

type TDeleteOptions = {
  onSuccess?: (data?: string) => void;
};

const useTwitterPosts = () => {
  const postApi = useReactQuery(api.post);
  const putApi = useReactQuery(api.put);
  const deleteApi = useReactQuery(api.delete);

  const create = async (data: TScheduleCreatePayload) => {
    if (!data.twitter.isSelected) return;
    await postApi.executeAsync(data);
  };

  const update = (data: TScheduleUpdatePayload, options?: TUpdateOptions) => {
    if (!data.twitter) return;
    putApi.execute(data, options);
  };

  const remove = (data: TScheduleDeletePayload, options?: TDeleteOptions) => {
    if (!data.twitter) return;
    deleteApi.execute(data, options);
  };

  const isLoading =
    postApi.isLoading || putApi.isLoading || deleteApi.isLoading;

  return {
    create,
    update,
    remove,
    isLoading,
  };
};

export default useTwitterPosts;
