import { combineReducers } from "redux";
import generalPreferences from "./generalPreferences";
import notificationPreferences from "./notificationPreferences";
import personalDetails from "./personalDetails";
import securityDetails from "./securityDetails";
import keywordsDetails from "./keywordsDetails";
import registrationStatus from "./registrationStatus";
import couponSystem from "./couponSystem";
import planDetails from "./planDetails";

export default combineReducers({
  generalPreferences,
  notificationPreferences,
  personalDetails,
  securityDetails,
  keywordsDetails,
  registrationStatus,
  planDetails,
  couponSystem,
});

export * from "./generalPreferences";
export * from "./notificationPreferences";
export * from "./personalDetails";
export * from "./securityDetails";
export * from "./registrationStatus";
export * from "./keywordsDetails";
export * from "./planDetails";
export * from "./couponSystem";
