import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  cursor: pointer;
  && {
    input {
      cursor: pointer;
      width: 100%;
    }
  }
`;

export default InputContainer;
