import { ScheduledPostAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/SocialMediaPost";
import { APIError } from "errors";
import { IScheduledPostPayload } from "./types";

const error = new APIError();
const URL = links.scheduleBaseUrl.append("/auth/twitter/thread");
const adapter = new ScheduledPostAdapter();

const ScheduledPost = async (values: IScheduledPostPayload) => {
  try {
    const headers = adapter.getConfig();
    const params = adapter.getParams(values);
    const { data } = await dolphynAPI(URL, { params, headers });
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default ScheduledPost;
