import { Box, Button, Header, List, Modal, Text } from "components/core";
import { AuthPopUpWindow } from "components/Auth";
import { Loader } from "components/common";
import {
  instagramConnectPopupData,
  links,
  socialMedia,
} from "config/SocialMediaPost";
import { useInstagramAuth } from "hooks/Auth";

interface IInstagramConnectPopupProps {
  open: boolean;
  onClose: () => void;
}

const InstagramConnectPopup = (props: IInstagramConnectPopupProps) => {
  const instagram = useInstagramAuth();

  return (
    <Modal {...props}>
      <Loader textData={socialMedia} hidden={!instagram.isLoading} />
      <Header
        content={instagramConnectPopupData.header}
        font="poppins"
        size="medium"
        textAlign="center"
        mb="small"
      />
      <Header
        content={instagramConnectPopupData.subHeader}
        font="poppins"
        size="small"
        mb="small"
      />
      <List bulleted bulletVariant="alpha">
        {instagramConnectPopupData.points.map((listItem, key) => (
          <List.Item key={key}>{listItem}</List.Item>
        ))}
      </List>
      <Box
        pt="small"
        pb="small"
        ps="small"
        pe="small"
        variant="beta"
        mb="small"
        shadow={false}
      >
        <Text
          content={instagramConnectPopupData.footer}
          variant="alpha"
          font="roboto"
          style={{ textAlign: "center" }}
        />
      </Box>
      <AuthPopUpWindow
        trigger={<Button content="Connect" variant="alpha" outline />}
        url={links.SOCIAL_ACCOUNT_INSTAGRAM_AUTH_LINK}
        expectedParam="code"
        onSuccess={(params, redirectUrl) => {
          if (redirectUrl) {
            instagram.login(redirectUrl, {
              onSuccess: () => {
                props.onClose();
              },
            });
          }
        }}
      />
    </Modal>
  );
};

export default InstagramConnectPopup;
