import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { BrandMonitoringCards } from "components/Twitter/BrandMonitoring";
import { BrandMonitoringProvider } from "context/Twitter";

const BrandMonitoring = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrandMonitoringProvider>
      <BrandMonitoringCards />
    </BrandMonitoringProvider>
  );
};

export default BrandMonitoring;
