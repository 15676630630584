import { Modal } from "components/core";
import { useBoolean } from "hooks";
import TwitterAccountCard from "./TwitterAccountCard";
import { AccountsGrid } from "./Common.styles";
import { useAppDispatch } from "redux/store/hooks";
import { setConnectTwitter } from "redux/slices/Twitter";

type TConnectTwitter = {
  connect: boolean;
};

const ConnectTwitter = ({ connect }: TConnectTwitter) => {
  const dispatch = useAppDispatch();
  const [showModal, , setShowModalFalse] = useBoolean(connect);

  return (
    <Modal
      open={showModal}
      onOutsideClick={() => {
        setShowModalFalse();
        dispatch(setConnectTwitter(false));
      }}
      onClose={() => {
        setShowModalFalse();
        dispatch(setConnectTwitter(false));
      }}
      style={{ width: "40vw", height: "56vh" }}
    >
      <AccountsGrid>
        <TwitterAccountCard />
      </AccountsGrid>
    </Modal>
  );
};

export default ConnectTwitter;
