import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";

const DateTimePicker = (props: React.PropsWithChildren<{}>) => {
  return <>{props.children}</>;
};

DateTimePicker.DatePicker = DatePicker;
DateTimePicker.TimePicker = TimePicker;

export default DateTimePicker;
