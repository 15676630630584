import { applicationRoutes as routes } from "config/common";

const items = [
  {
    title: "Schedule Post",
    route: routes.socialMediaPost.schedulePost,
  },
  {
    title: "Activity",
    route: routes.socialMediaPost.activity,
  },
  {
    title: "Calendar",
    route: routes.socialMediaPost.calendar,
  },
  {
    title: "Live Trends",
    route: routes.socialMediaPost.liveTrends,
  },
];

export default items;
