import Options from "./Options";
import PreviewList from "./PreviewList";
import Scheduler from "./Scheduler";

const PostComposer = () => {
  return (
    <>
      <Scheduler />
      <Options />
      <PreviewList />
    </>
  );
};

export default PostComposer;
