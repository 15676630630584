import { ContentGenerationByUrlAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/Twitter";
import { APIError } from "errors";
import { IContentGenerationPayload } from "./types";

const errors = new APIError();
const URL = links.baseTwitterApiUrl.append("/ai_content_generation/url");
const adapter = new ContentGenerationByUrlAdapter();

const contentGenerationByUrl = async (values: IContentGenerationPayload) => {
  try {
    const apiPayload = adapter.getPostPayload(values);
    const headers = adapter.getConfig();
    const { data } = await dolphynAPI.post(URL, apiPayload, headers);
    return adapter.getResponse(data);
  } catch (e) {
    errors.throwAxiosError(e);
  }
};

export default contentGenerationByUrl;
