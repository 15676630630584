import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import webAnalytics from "../slices/WebAnalytics";
import auth from "../slices/Auth";
import profile from "../slices/Profile";
import pricing from "../slices/Pricing";
import productDemand from "../slices/ProductDemand";
import twitter from "../slices/Twitter";
import blog from "../slices/Blog";
import contentCreation from "../slices/ContentCreation";

const reducer = combineReducers({
  auth,
  profile,
  pricing,
  webAnalytics,
  productDemand,
  twitter,
  blog,
  contentCreation,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
