import { useState } from "react";
import { DateTimePicker } from "components/common";
import { Button, IconButton } from "components/core";
import { DatetimeContainer } from "./RetweetPanel.styles";
import { useAppDispatch } from "redux/store/hooks";
import { setPostNow } from "redux/slices/Twitter";

interface IRetweetPanel {
  onSubmit: (datetime: Date) => void;
  setPanelClose: () => void;
}

const retweetPanelMenuMap = {
  postNow: "postNow",
  schedule: "schedule",
} as const;

type TRetweetPanelMenuMap = typeof retweetPanelMenuMap;
type TRetweetPanelMenu = keyof TRetweetPanelMenuMap;

const RetweetPanel = ({ onSubmit, setPanelClose }: IRetweetPanel) => {
  const TODAY = new Date();
  const [activeMenu, setActiveMenu] = useState<TRetweetPanelMenu>(
    retweetPanelMenuMap.postNow
  );
  const [datetime, setDatetime] = useState(TODAY);

  const dispatch = useAppDispatch();

  const renderBody = () => {
    switch (activeMenu) {
      case retweetPanelMenuMap.postNow:
        return null;

      default:
        return (
          <DatetimeContainer>
            <DateTimePicker.DatePicker
              className="picker"
              value={datetime}
              minDate={TODAY}
              onChange={(val) => setDatetime(val)}
            />
            <DateTimePicker.TimePicker
              className="picker"
              value={datetime}
              minTime={TODAY}
              onChange={(val) => setDatetime(val)}
            />
          </DatetimeContainer>
        );
    }
  };

  return (
    <>
      <Button
        outline={activeMenu !== retweetPanelMenuMap.postNow}
        variant="alpha"
        content="Post now"
        size="mini"
        onClick={() => {
          setActiveMenu(retweetPanelMenuMap.postNow);
          dispatch(setPostNow(true));
        }}
      />
      <Button
        outline={activeMenu !== retweetPanelMenuMap.schedule}
        variant="alpha"
        content="Schedule"
        size="mini"
        onClick={() => {
          setActiveMenu(retweetPanelMenuMap.schedule);
          dispatch(setPostNow(false));
        }}
      />
      {renderBody()}
      <IconButton
        variant="alpha"
        invert
        name="check"
        size="extraSmall"
        style={{ padding: "8px" }}
        onClick={() => {
          onSubmit(datetime);
          setPanelClose();
        }}
      />
    </>
  );
};

export default RetweetPanel;
