import { Dimmer } from "components/core";
import { Content } from "./Loader.styles";
import { ILoaderProps } from "./types";
import Bar from "./Bar";
import LoaderText from "./LoaderText";

const Loader = ({
  hidden,
  textData,
  background = "white",
  barVariant = "alpha",
}: ILoaderProps) => {
  const renderText = () => {
    return <LoaderText textData={textData} />;
  };

  if (hidden) return null;

  return (
    <Dimmer variant={background}>
      <Content>
        {renderText()} <Bar variant={barVariant} />
      </Content>
    </Dimmer>
  );
};

export default Loader;
