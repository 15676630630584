import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { APIError } from "errors";
import { UserProfilesAdapter } from "adapters/WebAnalytics";
import { TUserProfilePayload } from "adapters/WebAnalytics/types";
import { links } from "config/WebAnalytics";

const error = new APIError(constants.DEFAULT_API_ERROR);
const NEW_USER_URL = links.baseWebAnalyticsApiUrl.append(
  "/user_profile/new_user"
);
const RETURNING_USER_URL = links.baseWebAnalyticsApiUrl.append(
  "/user_profile/returning_user"
);
const SOCIAL_MEDIA_USER_URL = links.baseWebAnalyticsApiUrl.append(
  "/user_profile/social_user"
);
const adapter = new UserProfilesAdapter();

const userProfiles = {
  fetchNewUser: async (payload: TUserProfilePayload) => {
    try {
      const { headers, params } = await adapter.getGetPayload(payload);
      const { data } = await dolphynAPI(NEW_USER_URL, {
        params,
        headers,
      });

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  fetchReturningUser: async (payload: TUserProfilePayload) => {
    try {
      const { headers, params } = await adapter.getGetPayload(payload);
      const { data } = await dolphynAPI(RETURNING_USER_URL, {
        params,
        headers,
      });

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  fetchSocialUser: async (payload: TUserProfilePayload) => {
    try {
      const { headers, params } = await adapter.getGetPayload(payload);
      const { data } = await dolphynAPI(SOCIAL_MEDIA_USER_URL, {
        params,
        headers,
      });

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default userProfiles;
