import { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { notificationPreferencesApi } from "api/Profile";
import { setNotficationPreferences } from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TNotificationPreferences } from "adapters/Profile/types";

export const NotificationPreferencesContext = createContext({
  isLoading: false,
  handleUpdate: (data: Partial<TNotificationPreferences>) => {},
  fetch: () => {},
  details: {
    dailyActivity: false,
    newsletter: false,
    marketing: false,
    socialMediaPost: false,
    twitterEmail: false,
  } as TNotificationPreferences,
});

const NotificationPreferencesProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();
  const details = useAppSelector(
    (state) => state.profile.notificationPreferences
  );

  const { execute: fetch, isLoading: fetching } = useReactQuery(
    notificationPreferencesApi.get,
    {
      onSuccess: (data) => {
        dispatch(setNotficationPreferences(data));
      },
    }
  );

  const { execute: handleUpdate, isLoading: updating } = useReactQuery(
    notificationPreferencesApi.patch,
    {
      onSuccess: (data) => {
        dispatch(setNotficationPreferences(data));
      },
    }
  );

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  const isLoading = fetching || updating;

  return (
    <NotificationPreferencesContext.Provider
      value={{ details, handleUpdate, fetch, isLoading }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </NotificationPreferencesContext.Provider>
  );
};

export default NotificationPreferencesProvider;
