import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: ${helper.getSpacing("extraSmall")};
  &:hover {
    background-color: ${({ theme }) => theme.colors.beta};
  }
  &:last-child {
    align-items: flex-end;
  }
  &:first-child {
    align-items: flex-start;
  }
`;

const SortTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("small")}
  ${helper.getFontWeight("medium")}
  color: ${({ theme }) => theme.colors.alpha};
`;

export { Container, SortTitle };
