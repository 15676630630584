class TextHelper {
  splitFromBrackets = (text: string) => {
    const placeholderRegEx = new RegExp(/\[.*\]/i);

    const result = placeholderRegEx.exec(text);

    if (placeholderRegEx.test(text) && result) {
      const placeholder = result[0].toString();
      const [textFirsthalf, textSecondHalf] = text.split(placeholder);
      return { textFirsthalf, textSecondHalf, placeholder };
    }

    return text;
  };
  splitFromUrl = (text: string) => {
    const urlRegEx = new RegExp(
      /http[s]?:\/\/(www\.)?[a-z\d-]*\.[a-z]{2,8}(\.[a-z]{2,8})?\/?/i
    );

    if (typeof text !== "string") return null;

    const result = urlRegEx.exec(text);
    if (urlRegEx.test(text) && result) {
      const url = result[0].toString();
      const [textFirsthalf, textSecondHalf] = text.split(url);
      return { textFirsthalf, textSecondHalf, url };
    }

    return null;
  };

  truncate = (payload: string, limit = 20) =>
    payload.length > limit ? `${payload.slice(0, limit)}...` : payload;
}

export default TextHelper;
