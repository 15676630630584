import { ModuleHeader } from "components/common";
import { Container, TopSection, Body } from "./ContentCreationLayout.styles";

const ContentCreationLayout = (props: React.PropsWithChildren<{}>) => {
  return <Container>{props.children}</Container>;
};

ContentCreationLayout.TopSection = TopSection;
ContentCreationLayout.Body = Body;
ContentCreationLayout.ModuleHeader = ModuleHeader;

export default ContentCreationLayout;
