import { TwitterService } from "services/Auth";
import { IPotentialCustomerData, IPotentialCustomerPayload } from "./types";

type TAPIPayload = {
  filter_keywords?: string;
  location?: string;
  bio_includes?: string;
  bio_excludes?: string;
  followers?: boolean;
  followings?: boolean;
  followings_followed_you?: boolean;
  followings_not_followed_you?: boolean;
};

type TAPIResponse = {
  potentialLeadTweets: {
    name: string;
    lead_username: string;
    twitter_username: string;
    bio: string;
    location: string;
    website: string;
    influencer: boolean;
    followers: number;
    followings: number;
    tweets: number;
    keyword: string[];
    profile_pic_url: string;
    joined_since: string;
    following_since: string;
    addition_date: string;
    tweets_liked: number;
    tweets_replied: number;
    follow: boolean;
    followed_back: boolean;
    twitter_profile_url: string;
  }[];
  isTwitterConnected: boolean;
  tweetActions: boolean;
  totalPages: number;
};

class LeadsAdapter {
  private twitter = new TwitterService();

  private parseResponse = (
    apiResponse: TAPIResponse
  ): IPotentialCustomerData => {
    const CustomerTweet = apiResponse.potentialLeadTweets.map((tweet) => ({
      name: tweet.name,
      leadUsername: tweet.lead_username,
      twitterUsername: tweet.twitter_username,
      bio: tweet.bio,
      location: tweet.location,
      website: tweet.website,
      influencer: tweet.influencer,
      followers: tweet.followers,
      followings: tweet.followings,
      tweets: tweet.tweets,
      keyword: tweet.keyword,
      profilePicUrl: tweet.profile_pic_url,
      joinedSince: tweet.joined_since,
      followingSince: tweet.following_since,
      additionDate: tweet.addition_date,
      tweetsLiked: tweet.tweets_liked,
      tweetsReplied: tweet.tweets_replied,
      follow: tweet.follow,
      followedBack: tweet.followed_back,
      profileUrl: tweet.twitter_profile_url,
      frequentKeywords: [],
    }));
    return {
      potentialCustomerData: CustomerTweet,
      isTwitterConnected: apiResponse.isTwitterConnected,
      tweetActions: apiResponse.tweetActions,
      totalPages: apiResponse.totalPages,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getGetPayload = (data: IPotentialCustomerPayload) => {
    const payload: TAPIPayload = {
      filter_keywords: data.filterKeywords
        ?.filter((word) => word !== "")
        .join(),
      location: data.location?.filter((word) => word !== "").join(),
      bio_includes: data.bioIncludes?.filter((word) => word !== "").join(),
      bio_excludes: data.bioExcludes?.filter((word) => word !== "").join(),
    };
    return payload;
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default LeadsAdapter;
