import { EmojiPicker, FileAttacher, Textbox } from "components/common";
import { Button, Flex, LinkButton } from "components/core";
import { useTextHelper } from "hooks/Twitter";
import { theme } from "theme";
import MessageEditor from "./MessageEditor";
import { Toolbar } from "./DMCard.styles";
import { useToggle } from "hooks";
import { useEffect, useState } from "react";
import { textKeys, twitterDMCharacterLimit } from "config/Twitter";

interface IDMCard {
  open?: boolean;
  onCancel: () => void;
  onSave: () => void;
  text: string;
  onTextChange: (value: string) => void;
  placeholder?: string;
  files: File[];
  onFilesChange: (files: File[]) => void;
}

const DMCard = ({
  open,
  text,
  onTextChange,
  placeholder,
  onSave,
  onCancel,
  files,
  onFilesChange,
  ...styles
}: IDMCard) => {
  const { text4Message, setTextMessage } = useTextHelper();

  const [openEditor, toggleOpenEditor] = useToggle();
  const [editorText, setEditorText] = useState(text4Message);

  useEffect(() => {
    setEditorText(text4Message);
  }, [text4Message]);

  if (!open) return null;

  return (
    <Textbox
      placeholder={placeholder}
      value={text}
      onChange={(e) => {
        if (e.target.value.length - 1 < twitterDMCharacterLimit) {
          onTextChange(e.target.value);
        }
      }}
      style={{
        zIndex: 2,
        position: "absolute",
        top: "20%",
        left: "-10%",
        background: theme.colors.white,
        width: "425px",
        ...styles,
      }}
    >
      <MessageEditor
        open={openEditor}
        onCancel={() => toggleOpenEditor(false)}
        text={editorText}
        onTextChange={(value) => setEditorText(value)}
        onSave={() => {
          toggleOpenEditor(false);
          setTextMessage(textKeys.Text4, editorText);
        }}
      />
      <Textbox.WordLimit remaining={twitterDMCharacterLimit - text.length} />
      <Textbox.Toolbar
        style={{ justifyContent: "space-between", background: "unset" }}
      >
        <Toolbar>
          <Flex gap="none">
            <Button
              variant="alpha"
              size="mini"
              content="Text"
              onClick={() => onTextChange(text4Message)}
            />
            <Button
              variant="alpha"
              size="mini"
              icon="edit"
              onClick={() => toggleOpenEditor(true)}
            />
          </Flex>
          <EmojiPicker onSelect={(emoji) => onTextChange(text + emoji)} />
          <FileAttacher onChange={onFilesChange} />
        </Toolbar>
        <Toolbar>
          <Button
            type="submit"
            variant="alpha"
            content="Save"
            size="tiny"
            onClick={onSave}
          />
          <LinkButton content="Cancel" onClick={onCancel} />
        </Toolbar>
      </Textbox.Toolbar>
    </Textbox>
  );
};

export default DMCard;
