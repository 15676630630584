import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TGeneralPreferences } from "adapters/Profile/types";

const initialState: TGeneralPreferences = {
  occupation: "",
  referral: "",
  product: "",
  language: "",
  visitorId: "",
};

export const generalPreferences = createSlice({
  name: "generalPreferences",
  initialState,
  reducers: {
    setGeneralPreferences: (
      state,
      action: PayloadAction<Partial<TGeneralPreferences>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setGeneralPreferences } = generalPreferences.actions;

export default generalPreferences.reducer;
