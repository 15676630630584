import { useState } from "react";

interface ISliderOptions<T> {
  onForwardClick: (content?: T[]) => void;
  onBackwardClick: (content?: T[]) => void;
}

const useSlider = <T>(content: T[], options?: ISliderOptions<T>) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goNext = () => {
    if (content && currentIndex < content.length - 1) {
      setCurrentIndex((current) => current + 1);
      return;
    }
    options?.onForwardClick();
  };

  const goPrevious = () => {
    if (content && currentIndex !== 0) {
      setCurrentIndex((current) => current - 1);
      return;
    }
    options?.onBackwardClick();
  };

  const currentContent = content[currentIndex];

  const isLastItem = currentIndex === content.length - 1;
  const isFirstItem = currentIndex === 0;

  return {
    currentIndex,
    currentContent,
    isLastItem,
    isFirstItem,
    goNext,
    goPrevious,
  };
};

export default useSlider;
