import { dolphynAPI } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";
import LinkedinService, {
  TLinkedinPage,
  TLinkedinAccount,
  TLinkedinPages,
} from "services/Auth/linkedinService";

type TAPIResponse = {
  token_id: string;
  name: string;
  id: string;
  profile_image: string;
  page_list: {
    name: string;
    profile_image: string;
    id: string;
  }[];
};

const URL = links.BASE_AUTHENTICATION_API_URL.append("/linkedin");

const service = new LinkedinService();

const loginLinkedin = async (redirectUrl: String) => {
  try {
    const response = await dolphynAPI.post(URL, {
      linkedin_redirect: redirectUrl,
    });

    const data = response.data as TAPIResponse;

    const uid = service.getUid(data.id);

    const cookie: TLinkedinAccount = {
      uid,
      tokenId: data.token_id,
      name: data.name,
      profileImageUrl: data.profile_image,
      id: data.id,
    };

    const pages: TLinkedinPage[] = data.page_list.map((page) => ({
      name: page.name,
      id: page.id,
      profileImageUrl: page.profile_image,
    }));

    const pageList: TLinkedinPages = {
      uid,
      pages,
    };

    service.setAuthCookie(cookie);
    service.setPages(pageList);

    return null;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginLinkedin;
