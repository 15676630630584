import { useEffect } from "react";
import { usePendo, useSubHeading } from "hooks/common";
import { subHeadingMap } from "config/SocialMediaPost";
import { Container } from "components/core";
import { TrendCards } from "components/SocialMediaPost/LiveTrends";
import { LiveTrendsProvider } from "context/SocialMediaPost";

const LiveTrends = () => {
  const [, setHeading] = useSubHeading();
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeading(subHeadingMap.liveTrends);
  }, [setHeading]);

  return (
    <LiveTrendsProvider>
      <Container>
        <TrendCards />
      </Container>
    </LiveTrendsProvider>
  );
};

export default LiveTrends;
