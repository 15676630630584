import BaseLinkify from "react-linkify";
import { LinkButton } from "components/core";
import { useStringHelper } from "hooks/common";

interface ILinkifyProps {
  contentText?: string;
}

const Linkify = ({
  children,
  contentText,
}: React.PropsWithChildren<ILinkifyProps>) => {
  const stringHelper = useStringHelper();

  return (
    <BaseLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <LinkButton
          style={{ wordWrap: "break-word", fontSize: "14px" }}
          as="a"
          href={decoratedHref}
          target="_blank"
          key={key}
          content={contentText || stringHelper.truncate(decoratedHref, 50)}
        />
      )}
    >
      {children}
    </BaseLinkify>
  );
};

export default Linkify;
