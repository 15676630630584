import styled from "styled-components";

const Container = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export { Container };
