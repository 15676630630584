import { ModuleMenu } from "components/common";
import { leadGenerationMenuData } from "config/Twitter";
import { applicationRoutes as routes } from "config/common";
import { BaseModuleLayout } from "layout/common";
import LayoutHeader from "./LayoutHeader";
import { useAdmin } from "hooks/Admin";

const LeadGenerationLayout = (props: React.PropsWithChildren<{}>) => {
  const { isAdmin } = useAdmin();

  return (
    <>
      <LayoutHeader header="Generate Leads 🧑‍🤝‍🧑" />
      <BaseModuleLayout.MenuBar>
        <ModuleMenu style={{ gap: "0.5rem" }} items={leadGenerationMenuData}>
          {isAdmin && (
            <ModuleMenu.Item
              routerPath={routes.leadGeneration.admin}
              style={{ alignSelf: "center" }}
            >
              Admin Engagement
            </ModuleMenu.Item>
          )}
          <ModuleMenu.Menu pointing={false} floated="right" />
        </ModuleMenu>
      </BaseModuleLayout.MenuBar>
      <BaseModuleLayout.Body>{props.children}</BaseModuleLayout.Body>
    </>
  );
};

export default LeadGenerationLayout;
