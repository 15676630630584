import indiaFlag from "assets/icons/Profile/india-flag.png";
import usaFlag from "assets/icons/Profile/usa-flag.png";
import ukFlag from "assets/icons/Profile/uk-flag.png";
import canadaFlag from "assets/icons/Profile/canada-flag.png";
import australiaFlag from "assets/icons/Profile/australia-flag.png";
import newZealandFlag from "assets/icons/Profile/new-zealand-flag.png";
import philippinesFlag from "assets/icons/Profile/philippines-flag.png";
import brazilFlag from "assets/icons/Profile/brazil-flag.png";
import kenyaFlag from "assets/icons/Profile/kenya-flag.png";
import bangladeshFlag from "assets/icons/Profile/bangladesh-flag.png";

const data = [
  {
    id: 1,
    icon: indiaFlag,
    text: "India",
    value: "India",
  },
  {
    id: 2,
    icon: usaFlag,
    text: "USA",
    value: "United States",
  },
  {
    id: 3,
    icon: ukFlag,
    text: "UK",
    value: "United Kingdom",
  },
  {
    id: 4,
    icon: canadaFlag,
    text: "Canada",
    value: "Canada",
  },
  {
    id: 5,
    icon: australiaFlag,
    text: "Australia",
    value: "Australia",
  },
  {
    id: 6,
    icon: newZealandFlag,
    text: "New Zealand",
    value: "New Zealand",
  },
  {
    id: 7,
    icon: philippinesFlag,
    text: "Philippines",
    value: "Philippines",
  },
  {
    id: 8,
    icon: brazilFlag,
    text: "Brazil",
    value: "Brazil",
  },
  {
    id: 9,
    icon: kenyaFlag,
    text: "Kenya",
    value: "Kenya",
  },
  {
    id: 10,
    icon: bangladeshFlag,
    text: "Bangladesh",
    value: "Bangladesh",
  },
];

export default data;
