import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

type TContainerProps = {
  focused?: boolean;
};

type TStyledInputProps = {
  icon?: React.ReactNode;
  cursor?: React.CSSProperties["cursor"];
};

const helper = new ThemeHelper();

const Container = styled.div<TContainerProps>`
  display: flex;
  justify-content: space-between;
  ${helper.getFontSize("extraSmall")};
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};
  ${helper.formControl.getUnfocusedBoxShadow()};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  height: 42px;
  ${helper.getBorderRadius("small")}
  :focus-within {
    ${helper.formControl.getFocusedBoxShadow()}
  }
  .eye-icon,
  .icon {
    align-self: center;
    padding: 0 ${helper.getSpacing("tiny")};
  }
  .eye-icon {
    cursor: pointer;
  }
`;

const StyledInput = styled.input<TStyledInputProps>`
  flex: 1;
  display: block;
  border: unset;
  outline: unset;
  height: 100%;
  width: 100%;
  ${helper.getBorderRadius("small")}
  color: ${({ theme }) => theme.colors.lightGrey};
  padding: ${helper.getSpacing("tiny")} ${helper.getSpacing("extraSmall")};
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("extraSmall")};
  &::placeholder {
    font-family: ${({ theme }) => theme.fonts.poppins};
    ${helper.getFontSize("extraSmall")};
    color: ${({ theme }) => theme.colors.placeHolder};
  }
  &:-webkit-autofill {
    background-color: unset !important;
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.white} inset;
    font-family: ${({ theme }) => theme.fonts.poppins};
    ${helper.getFontSize("extraSmall")};
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px ${({ theme }) => theme.colors.white} inset;
  }
  ${({ cursor }) => {
    if (cursor) {
      return css`
        cursor: ${cursor};
      `;
    }
  }};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export { Container, StyledInput };
export type { TContainerProps as TInputContainerProps, TStyledInputProps };
