import { ReactComponent as ContentCreationIcon } from "assets/icons/Home/content-creation.svg";
import { ReactComponent as LeadGenerationIcon } from "assets/icons/Home/lead-generation.svg";
import { ReactComponent as SocialMediaIcon } from "assets/icons/Home/social-media.svg";

const data = [
  {
    icon: ContentCreationIcon,
    header: "Everyday Tweets",
    description:
      "Powerful AI-text analysis provides you relevant tweets and active leads everyday for you or your client's business.",
  },
  {
    icon: LeadGenerationIcon,
    header: "Lead Generation",
    description:
      "Use recommended leads to follow, and nurture relationship by liking, replying, retweeting and DMing",
  },
  {
    icon: SocialMediaIcon,
    header: "Influencer Identification",
    description:
      "One of biggest hurdle is to generate lists of influencers in your Industry. Dolphy automatically does it for you.",
  },
  {
    icon: ContentCreationIcon,
    header: "Schedule Retweet",
    description:
      "Schedule a retweet from your influencer's most engaged tweet all months in advance.",
  },
  {
    icon: LeadGenerationIcon,
    header: "Auto-Welcome Message",
    description:
      "Conversation start when your followers receive a warm welcome from you.",
  },
  {
    icon: SocialMediaIcon,
    header: "Twitter Analytics",
    description:
      "Understand Twitter analytics, audit your Twitter profile and receive the recommendation.",
  },
];

export default data;
