import styled from "styled-components";
import { useState, Fragment } from "react";
import { Icon } from "semantic-ui-react";
import { EventCalendar } from "components/common";
import { Portal } from "components/core";
import { usePositionElement } from "hooks/common";
import { UpdatePostForm } from "../SchedulePost";
import CalendarCard from "./CalendarCard";
import { TBaseSocialMediaPost } from "adapters/SocialMediaPost/types";
import { useSocialCalendar } from "hooks/SocialMediaPost";
import ThemeHelper from "theme/themeHelper";
import { TPositionObject } from "theme/types";
import { postStatusMap } from "config/SocialMediaPost";

type TActivityContainerProps = {
  position: TPositionObject;
};

const helper = new ThemeHelper();

const ActivityContainer = styled.div<TActivityContainerProps>`
  height: 400px;
  width: 400px;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  ${({ position }) => helper.getPosition(position)}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.4rem;
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
})`
  &&& {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

type TSocialCalendarEvent = TBaseSocialMediaPost;

interface ISocialCalendarEventProps {
  event: TSocialCalendarEvent;
}

const SocialCalendarEvent = ({ event }: ISocialCalendarEventProps) => {
  const calendar = useSocialCalendar();

  const [showCalendarCard, setShowCalendarCard] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const positionElement = usePositionElement({
    defaultPosition: { top: 50, left: 50 },
    itemSize: { width: 400, height: 400 },
    padding: { x: 10, y: 10 },
  });

  const renderCalendarCard = (event: TSocialCalendarEvent) => {
    if (showCalendarCard) {
      return (
        <Portal>
          <ActivityContainer position={positionElement.position}>
            <CalendarCard
              editable={event.postStatus === postStatusMap.scheduled}
              deletable
              onEdit={() => {
                setShowCalendarCard(false);
                setShowUpdateForm(true);
              }}
              onDelete={() => {
                const formValues = { ...event, files: [] };
                calendar.handleDelete(formValues);
                setShowCalendarCard(false);
              }}
              {...event}
            />
            <CloseIcon onClick={() => setShowCalendarCard(false)} />
          </ActivityContainer>
        </Portal>
      );
    }
  };

  const renderUpdateForm = (event: TSocialCalendarEvent) => {
    if (showUpdateForm) {
      return (
        <UpdatePostForm
          open={showUpdateForm}
          onClose={() => setShowUpdateForm(false)}
          formValues={{ ...event, files: [] }}
          onSubmit={(values) => {
            calendar.handleUpdate(values);
            setShowUpdateForm(false);
          }}
        />
      );
    }
  };

  const renderEvents = () => {
    return (
      <Fragment>
        <EventCalendar.Event
          style={{ cursor: "pointer" }}
          event={event}
          onClick={(syntheticEvent) => {
            setShowCalendarCard(true);
            positionElement.captureEvent(syntheticEvent);
          }}
        />
        {renderCalendarCard(event)}
        {renderUpdateForm(event)}
      </Fragment>
    );
  };

  return <Container>{renderEvents()}</Container>;
};

export default SocialCalendarEvent;
