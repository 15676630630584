import styled, { css, DefaultColors } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TFontSizes } from "theme/types";

export type TIconButtonContainerProps = {
  variant: keyof DefaultColors;
  size?: keyof TFontSizes;
  invert?: boolean;
};

const helper = new ThemeHelper();

const Container = styled.button<TIconButtonContainerProps>`
  border-radius: 5px;
  padding: ${({ size }) =>
    helper.getGeneralSize(size, { multiplier: 0.6 }) + "rem"};
  cursor: pointer;
  outline: none;
  border: none;
  display: grid;
  place-items: center;
  transition: all 300ms ease;
  i.icon {
    margin: unset;
    color: ${({ theme, variant }) => theme.colors[variant]};
    ${({ size }) => helper.getFontSize(size)}
  }
  svg {
    width: ${({ size }) => helper.getGeneralSize(size)};
    height: ${({ size }) => helper.getGeneralSize(size)};
    stroke: ${({ theme, variant }) => theme.colors[variant]};
  }
  ${({ theme, variant, invert }) => {
    if (invert) {
      return css`
        i.icon {
          color: ${theme.colors.white};
        }
        background-color: ${theme.colors[variant]};
        &:hover {
          background-color: ${helper.lighten(theme.colors[variant])};
        }
      `;
    }
    return css`
      background-color: ${helper.lighten(theme.colors[variant], "high")};
      &:hover {
        background-color: ${helper.lighten(theme.colors[variant], "medium")};
      }
    `;
  }}
`;

export { Container };
