import { setAdvancedSearchIsOpen } from "redux/slices/Twitter";
import { useAppDispatch } from "redux/store/hooks";

import { IconContainer } from "./Filter.styles";

import { ReactComponent as FilterIcon } from "assets/icons/common/filter.svg";

const Filter = () => {
  const dispatch = useAppDispatch();

  return (
    <IconContainer onClick={() => dispatch(setAdvancedSearchIsOpen(true))}>
      <FilterIcon />
    </IconContainer>
  );
};

export default Filter;
