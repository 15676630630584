import { TActivateTrialPayload } from "./types";

type TAPIResponse = {
  message: string;
};

type TAPIPayload = {
  plan_id: string;
};

class ActivateTrialAdapter {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      message: apiResponse.message,
    };
  };
  getPostPayload = (values: TActivateTrialPayload): TAPIPayload => {
    return {
      plan_id: values.planId,
    };
  };

  parseGetResponse = (data: TAPIResponse) => this.parseResponse(data);
}

export default ActivateTrialAdapter;
