import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import ReactTooltip from "react-tooltip";
import { SubTitle, Title, Container, TimeStamp } from "./Username.styles";
import { ReactComponent as TickMark } from "assets/icons/Pricing/rounded-check.svg";
import { TSpacings } from "theme/types";
import { relativeTime, timeSince } from "helpers";

interface IUsernameProps {
  name: string;
  username: string;
  following?: boolean;
  keywords?: string[];
  createdAt?: Date;
  onClick?: () => void;
  direction?: React.CSSProperties["flexDirection"];
  gap?: keyof TSpacings;
  dataTip?: string;
}

const Username = ({
  name,
  username,
  following,
  keywords,
  onClick,
  createdAt,
  direction,
  gap,
  dataTip,
}: IUsernameProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const renderKeywords = () => {
    if (!keywords) return null;

    return (
      <>
        {keywords.map((keyword) => (
          <span key={uuid()}>{keyword} </span>
        ))}
      </>
    );
  };

  const renderCreatedAt = () => {
    if (!createdAt) return null;
    const createdAtTime = timeSince(createdAt);

    return (
      <TimeStamp data-tip={relativeTime(createdAt.toString())}>
        {createdAtTime}
      </TimeStamp>
    );
  };

  return (
    <Container direction={direction} gap={gap}>
      <Title onClick={onClick} data-tip={dataTip}>
        {name} {following && <TickMark data-tip="Follows you" />}
        {renderCreatedAt()}
      </Title>
      <SubTitle onClick={onClick} data-tip={dataTip}>
        @{username} {renderKeywords()}
      </SubTitle>
    </Container>
  );
};

export default Username;
