import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { TwitterAudit } from "components/Twitter/Audit";
import { AuditProvider } from "context/Twitter";

const Audit = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuditProvider>
      <TwitterAudit />
    </AuditProvider>
  );
};

export default Audit;
