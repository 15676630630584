import FileHelper, { TPreviewUrl } from "helpers/FileHelper";

const fileTypes = {
  images: ["jpeg", "jpg", "png", "gif"],
  videos: ["mp4"],
  "images-videos": ["jpeg", "jpg", "png", "gif", "mp4"],
  other: ["csv"],
};

export type TFileType = keyof typeof fileTypes;

const useFile = (acceptedTypes: TFileType, config?: FileHelper["config"]) => {
  const helper = new FileHelper(fileTypes[acceptedTypes], config);

  const validateFiles = (files: File[]) => helper.validateFiles(files);

  const getPreviewUrls = (files: File[]) => helper.getObjectUrls(files);

  const removeFile = (files: File[], url: TPreviewUrl) =>
    helper.removeFile(files, url);

  const getDataTransferFiles = (e: React.DragEvent<HTMLDivElement>) =>
    helper.getFileList(e.dataTransfer.files);

  const getInputFiles = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.files ? helper.getFileList(e.target.files) : [];

  const getAcceptedTypes = () => fileTypes[acceptedTypes].join(", ");

  return {
    validateFiles,
    getPreviewUrls,
    getDataTransferFiles,
    getInputFiles,
    removeFile,
    getAcceptedTypes,
  };
};

export type { TPreviewUrl } from "helpers/FileHelper";
export type TConfig = FileHelper["config"];
export default useFile;
