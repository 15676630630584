import styled from "styled-components";

const Grid = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const SideBarCol = styled.div`
  height: 100%;
`;

const BodyCol = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const Wrapper = styled.div`
  .custom-tooltip {
    max-width: 384px;
    white-space: pre-line;
  }
`;

export { Grid, SideBarCol, BodyCol, Wrapper };
