import styled from "styled-components";

const Body = styled.div`
  padding: 0 1rem;
  max-width: 96%;
  margin: auto;
  height: 100%;
`;

export { Body };
