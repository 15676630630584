const delayTimes = [
  {
    key: "one",
    value: 1,
    text: "1 minute",
  },
  {
    key: "two",
    value: 2,
    text: "2 minutes",
  },
  {
    key: "three",
    value: 3,
    text: "3 minutes",
  },
  {
    key: "five",
    value: 5,
    text: "5 minutes",
  },
  {
    key: "seven",
    value: 7,
    text: "7 minutes",
  },
  {
    key: "ten",
    value: 10,
    text: "10 minutes",
  },
];

export default delayTimes;
