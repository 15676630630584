import { applicationRoutes as routes } from "config/common";

const items = [
  {
    title: "Web metrics",
    route: routes.webAnalytics.webMatrix,
  },
  {
    title: "Page metrics",
    route: routes.webAnalytics.pageMatrix,
  },
  {
    title: "GA optimizer",
    route: routes.webAnalytics.optimizer,
  },
  {
    title: "User Profiles",
    route: routes.webAnalytics.userProfiles,
  },
  {
    title: "Search Console",
    route: routes.webAnalytics.searchConsole,
  },
];

export default items;
