import styled, { css } from "styled-components";
import { TForwardBackwardStyles } from "./types";

interface IArrowContainerProps extends TForwardBackwardStyles {
  disabled?: boolean;
}

const ArrowContainer = styled.div<IArrowContainerProps>`
  border: 1px solid ${({ theme, variant = "alpha" }) => theme.colors[variant]};
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: grid;
  place-items: center;
  &&& i.icon {
    color: ${({ theme, variant = "alpha" }) => theme.colors[variant]};
  }
  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        border: 1px solid ${theme.colors.lightGrey};
        &,
        &&& i.icon {
          cursor: not-allowed;
          color: ${theme.colors.lightGrey};
        }
      `;
    }
  }}

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

export default ArrowContainer;
