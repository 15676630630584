import { TwitterService } from "services/Auth";

type TAPIPostObject = {
  uid: string;
  timezone: string;
  retweetTime: Date;
  retweet: boolean;
  postNow: boolean;
  isThread: boolean;
  message?: string;
  files?: any[];
  interval?: number;
  threads?: {
    message: string;
    files: any[];
  }[];
};

class PostNowAdapter {
  private twitter = new TwitterService();

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TAPIPostObject) => {
    const account = this.twitter.getAccount(data.uid);
    if (data.retweet) {
      if (!data.isThread && account) {
        return {
          token_id: account.tokenId,
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          post_now: data.postNow,
          retweet_schedule_datetime: data.retweetTime.toISOString(),
          is_thread: data.isThread,
          message: data.message,
          files: data.files,
        };
      }
      if (account) {
        return {
          token_id: account.tokenId,
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          post_now: data.postNow,
          retweet_schedule_datetime: data.retweetTime.toISOString(),
          is_thread: data.isThread,
          interval: data.interval,
          threads: data.threads,
        };
      }
    } else {
      if (!data.isThread && account) {
        return {
          token_id: account.tokenId,
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          post_now: data.postNow,
          is_thread: data.isThread,
          message: data.message,
          files: data.files,
        };
      }
      if (account) {
        return {
          token_id: account.tokenId,
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          post_now: data.postNow,
          is_thread: data.isThread,
          interval: data.interval,
          threads: data.threads,
        };
      }
    }

    return null;
  };
}

export default PostNowAdapter;
