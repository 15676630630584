import { createContext, useEffect } from "react";
import { useScriptLoader } from "hooks/common";
import { links } from "config/common";

export const CustomFitAiContext = createContext(null);

interface ICustomFitAiProviderProps {}

const CustomFitAiProvider = ({
  children,
}: React.PropsWithChildren<ICustomFitAiProviderProps>) => {
  const scriptLoader = useScriptLoader();

  useEffect(() => {
    scriptLoader.load(links.CUSTOMFITAI_SCRIPT_URL, {
      additionalAttributes: {
        id: "customfitinit",
        type: "text/javascript",
      },
      location: "head",
    });
  }, [scriptLoader]);

  return (
    <CustomFitAiContext.Provider value={null}>
      {children}
    </CustomFitAiContext.Provider>
  );
};

export default CustomFitAiProvider;
