import React, { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { personalDetailsApi } from "api/Profile";
import { setPersonalDetails } from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TPersonalDetails } from "adapters/Profile/types";

type TUpdateOptions = {
  onSuccess?: () => void;
};

type TPersonalDetailsContext = {
  isLoading: boolean;
  handleUpdate: (
    data: Partial<TPersonalDetails>,
    options?: TUpdateOptions | undefined
  ) => void;
  handleUpdateAsync: (
    data: Partial<TPersonalDetails>
  ) => Promise<TPersonalDetails | undefined>;
  fetch: () => void;
  details: TPersonalDetails;
};

export const PersonalDetailsContext =
  createContext<null | TPersonalDetailsContext>(null);

const PersonalDetailsProvider = (props: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();
  const details = useAppSelector((state) => state.profile.personalDetails);

  const { execute: fetch, isLoading: fetching } = useReactQuery(
    personalDetailsApi.get,
    {
      onSuccess: (data) => {
        dispatch(setPersonalDetails(data));
      },
    }
  );

  const {
    execute: handleUpdate,
    executeAsync: handleUpdateAsync,
    isLoading: updating,
  } = useReactQuery(personalDetailsApi.patch, {
    onSuccess: (data) => {
      dispatch(setPersonalDetails(data));
    },
  });

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  const isLoading = fetching || updating;

  return (
    <PersonalDetailsContext.Provider
      value={{ fetch, handleUpdate, handleUpdateAsync, details, isLoading }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </PersonalDetailsContext.Provider>
  );
};

export default PersonalDetailsProvider;
