import { TwitterService } from "services/Auth";
import { TFileObject } from "./files";
import { TThread } from "./types";

type TPutObject = {
  datetime: Date;
  timezone?: string;
  interval?: number;
  sequenceNo: number;
  message: string;
  files: TFileObject[];
};

type TPutPayload = {
  token_id: string;
  datetime: string;
  timezone: string;
  interval?: number;
  sequence_no: number;
  message: string;
  files: TFileObject[];
};

type TPostPayload = {
  token_id: string;
  datetime: string;
  timezone: string;
  account_id: string;
  account_name: string;
  retweet_schedule_datetime: string;
  interval: 5;
  threads: {
    message: string;
    files: any[];
  }[];
};

type TPostObject = {
  datetime: Date;
  retweetTime: Date;
  retweet: boolean;
  timezone?: string;
  interval: number;
  threads: {
    message: string;
    files: any[];
  }[];
};

type TAPIResponse = {
  posts: {
    threads: TThread[];
  }[];
};

type TAPIDeleteResponse = {
  message: string;
};

type TDeleteParams = {
  sequenceNo: number;
};

class ScheduleThreadAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      posts: apiResponse.posts,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPutPayload = (data: TPutObject) => {
    const account = this.twitter.getSelectedAccount();
    if (data.interval && account) {
      return {
        token_id: account.tokenId,
        datetime: data.datetime.toISOString(),
        timezone: data.timezone,
        sequence_no: data.sequenceNo,
        message: data.message,
        files: data.files,
      } as TPutPayload;
    }
    return {
      token_id: account.tokenId,
      datetime: data.datetime.toISOString(),
      timezone: data.timezone,
      interval: data.interval,
      sequence_no: data.sequenceNo,
      message: data.message,
      files: data.files,
    } as TPutPayload;
  };

  getPostPayload = (data: TPostObject) => {
    const account = this.twitter.getSelectedAccount();
    const TIME = new Date();
    if (data.retweetTime && data.retweetTime > TIME) {
      if (account) {
        return {
          token_id: account.tokenId,
          datetime: data.datetime.toISOString(),
          retweet_schedule_datetime: data.retweetTime.toISOString(),
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          interval: data.interval,
          threads: data.threads,
        } as TPostPayload;
      }
    }
    if (account) {
      return {
        token_id: account.tokenId,
        datetime: data.datetime.toISOString(),
        timezone: data.timezone,
        account_id: account.userId,
        account_name: account.userName,
        interval: data.interval,
        threads: data.threads,
      } as TPostPayload;
    }
  };

  getDeleteParams = (data: TDeleteParams) => {
    return {
      sequence_no: data.sequenceNo,
    };
  };

  parseDeleteResponse = (apiResponse: TAPIDeleteResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  parsePutResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);

  parsePostResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default ScheduleThreadAdapter;
