import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPlanCard } from "adapters/Pricing/types";

const initialState = {
  plans: [] as TPlanCard[],
};

export const plans = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setPlans: (state, action: PayloadAction<TPlanCard[]>) => {
      return { ...state, plans: action.payload };
    },
  },
});

export const { setPlans } = plans.actions;

export default plans.reducer;
