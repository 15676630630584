import { Button } from "components/core";
import {
  usePlatformWordLimit,
  useScheduleFormContext,
  useScheduler,
} from "hooks/SocialMediaPost";
import SelectPlatform from "./SelectPlatform";
import PostFile from "./PostFile";
import PostText from "./PostText";
import PostDateTime from "./PostDateTime";
import CampaignBuilder from "./CampaignBuilder";

const PostForm = () => {
  const scheduler = useScheduler();

  const { handleSubmit, setValues, values } = useScheduleFormContext();

  const wordLimit = usePlatformWordLimit();

  return (
    <form onSubmit={handleSubmit}>
      <SelectPlatform
        fieldNames={{
          facebook: "facebook",
          twitter: "twitter",
          linkedin: "linkedin",
          instagram: "instagram",
        }}
        onSelectAll={() => {
          setValues(scheduler.getAllPlatformsFormValues(values));
        }}
        onDeSelectAll={() => {
          setValues(scheduler.getNoPlatformsFormValues(values));
        }}
        allSelected={scheduler.getAllPlatformsSelected(values)}
      />
      <PostFile name="files" />
      <PostText
        name="text"
        wordLimit={wordLimit.getLimit({
          facebook: values.facebook.length > 0,
          instagram: values.instagram.length > 0,
          twitter: values.twitter.length > 0,
          linkedin: values.linkedin.length > 0,
        })}
      >
        <CampaignBuilder />
      </PostText>
      <PostDateTime name="datetime" />

      <Button variant="alpha" content="Submit" type="submit" mb="small" />
    </form>
  );
};

export default PostForm;
