import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.beta};
`;

const SubContainer = styled.div`
  ${helper.containerize(90)}
  min-height: 100vh;
  display: grid;
  grid-template-rows: 70px 7fr;

  @media screen and (max-width: 768px) {
    ${helper.containerize(95)}
  }
`;

const TopSection = styled.div`
  padding: ${helper.getSpacing("extraSmall")} 0;
`;

const Body = styled.main`
  height: 100%;
  width: 100%;
`;

export { Container, TopSection, SubContainer, Body };
