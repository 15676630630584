// Dolphyn main credentials cookies
export const DOLPHYN_ACCESS_COOKIE = "dolphynAccess";
export const DOLPHYN_REFRESH_COOKIE = "dolphynRefresh";
export const DOLPHYN_ACCESS_COOKIE_EXPIRY = 7;
export const DOLPHYN_REFRESH_COOKIE_EXPIRY = 15;

// Web analytics google accounts cookies
export const GOOGLE_ANALYTICS_COOKIE = "gaDolphynCred";
export const GOOGLE_SEARCH_CONSOLE_COOKIE = "gscDolphynCred";

// Social Media accounts cookies
export const TWITTER_COOKIE = "twDolphynCred";
export const FACEBOOK_COOKIE = "fbDolphynCred";
export const INSTAGRAM_COOKIE = "igDolphynCred";
export const LINKEDIN_COOKIE = "lkDOlphynCred";

// Social Media accounts storages

export const FACEBOOK_LOCAL_STORAGE = "fb_acc_storage";
export const INSTAGRAM_LOCAL_STORAGE = "insta_acc_storage";
export const LINKEDIN_LOCAL_STORAGE = "linkedin_acc_storage";
export const TWITTER_LOCAL_STORAGE = "twitter_acc_storage";

export const {
  REACT_APP_FACEBOOK_APP_ID: FACEBOOK_APP_ID,
  REACT_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID,
} = process.env;

export const DEFAULT_API_ERROR =
  "Something went wrong! Unable to authenticate!";
