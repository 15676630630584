import { useField } from "formik";
import { QuestionCard } from "../common";
import { useRegistrationSteps } from "hooks/Profile";
import ContentContainer from "../common/ContentContainer";
import productIllustration from "assets/images/Profile/product-page-illustration.png";
import { productFormData } from "config/Profile";
import { TCompleteRegistrationFormValues } from "./types";
import styled from "styled-components";

const Illustration = styled(ContentContainer.Illustration)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

interface IProductForm {
  name: string;
}

const ProductForm = (props: IProductForm) => {
  const [field, , helpers] = useField<
    TCompleteRegistrationFormValues["product"]
  >(props.name);
  const steps = useRegistrationSteps();

  const handleUpdate = (product: string) => {
    helpers.setValue(product);
    steps.handleGoToNextStep();
  };

  return (
    <ContentContainer>
      <QuestionCard>
        <QuestionCard.Header
          as="h1"
          size="medium"
          font="poppins"
          fontWeight="high"
          variant="darkGrey"
          textAlign="center"
          content="What are you selling?"
        />
        <QuestionCard.Options>
          {productFormData.map(({ id, value, icon, text }) => (
            <QuestionCard.Option
              key={id}
              icon={icon}
              text={text}
              active={field.value === value}
              onClick={() => handleUpdate(value)}
            />
          ))}
        </QuestionCard.Options>
        <QuestionCard.OtherOption
          value={field.value}
          onSubmit={(value) => handleUpdate(value)}
        />
      </QuestionCard>
      <Illustration
        src={productIllustration}
        alt="product-illustration"
        style={{
          position: "absolute",
          bottom: "0",
          left: "5%",
          width: "250px",
          height: "250px",
        }}
      />
    </ContentContainer>
  );
};

export default ProductForm;
