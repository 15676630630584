import { UserTokensAdapter } from "adapters/Auth";
import { dolphynAPI } from "api/instances";
import { links } from "config/Auth";
import { APIError } from "errors";

const error = new APIError();
const URL = links.BASE_AUTHENTICATION_API_URL.append("/user_tokens");
const adapter = new UserTokensAdapter();

const userTokens = async () => {
  try {
    const { data } = await dolphynAPI(URL);
    return adapter.getResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default userTokens;
