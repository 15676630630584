import { useState, useContext } from "react";
import {
  Container,
  Content,
  Title,
  SubHeader,
  Header,
  Image,
  Example,
  ImageContainer,
  ErrorText,
} from "./Hero.styles";
import { InputButton } from "../Common";
import heroBackground from "assets/images/Home/hero-background.png";
import CheckKeyword from "./CheckKeyword";
import { SearchKeywordsContext } from "context/Home";
import { Button } from "components/core";
import { useNavigation } from "hooks";

const Hero = () => {
  const nav = useNavigation();

  const [showCheckKeyword, setShowCheckKeyword] = useState(false);
  const [showError, setShowError] = useState(false);
  const { handleSearch, handleKeywordChange, keyword } = useContext(
    SearchKeywordsContext
  );

  const searchHandler = () => {
    if (keyword.length >= 3) {
      setShowCheckKeyword(true);
      handleSearch();
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  const renderImage = () => {
    if (showCheckKeyword)
      return <CheckKeyword onClose={() => setShowCheckKeyword(false)} />;
    return <Image alt="dolphyn-illustration" src={heroBackground} />;
  };

  return (
    <Container>
      <Content>
        <Title>CONNECT . COMMUNICATE . CONVERT . COMFORT</Title>
        <Header>
          Everything to make Twitter <br /> your <span>Lead Generation </span>
          and <br />
          <span>User Growth Tool</span>
        </Header>
        <Button
          variant="alpha"
          content="Start free trial Now"
          size="large"
          onClick={() => nav.navigate((routes) => routes.rootRoutes.signUp)}
        />
        <SubHeader>
          Check Potential <span>Leads</span>
        </SubHeader>
        <InputButton>
          <InputButton.Input
            name="keyword"
            placeholder="Enter keywords your customers use."
            type="text"
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
          />
          <InputButton.Button variant="alpha" onClick={searchHandler}>
            Search
          </InputButton.Button>
        </InputButton>
        {showError && <ErrorText>At least 3 characters are required</ErrorText>}
        <Example>E.g. CEO, Green Energy, CupCake etc.</Example>
      </Content>
      <ImageContainer>{renderImage()}</ImageContainer>
    </Container>
  );
};

export default Hero;
