import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TStyledTextProps } from "./types";

const helper = new ThemeHelper();

const StyledText = styled.span<TStyledTextProps>`
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${({ size }) => helper.getFontSize(size)};
  ${helper.getFontWeight("medium")};
  margin: 0 ${helper.getSpacing("extraSmall")};
  color: ${({ theme, variant }) => {
    if (variant) return theme.colors[variant];
    return theme.colors.white;
  }};
  ${({ onClick }) => {
    if (onClick)
      return css`
        cursor: pointer;
      `;
  }}
`;

export { StyledText };
