import { createContext, useEffect } from "react";
import { IKeywordsComparisonData } from "adapters/ProductDemand/types";
import { useReactQuery, useBoolean } from "hooks/common";
import { fetchKeywordsComparison } from "api/ProductDemand";
import { usePreferences, useRecommendation } from "hooks/ProductDemand";
import { IMultiKeywordSearchFormikValues } from "./types";
import { Loader } from "components/common";
import { keywordComparison } from "config/ProductDemand";

type TKeywordsComparisonContext = {
  data: IKeywordsComparisonData;
  handleSearch: (payload: IMultiKeywordSearchFormikValues) => void;
};

export const KeywordsComparisonContext =
  createContext<TKeywordsComparisonContext | null>(null);

const initialData: IKeywordsComparisonData = {
  popularityChart: [],
  recommendation: [],
  trendingKeywordsTable: {
    google: [],
    twitter: [],
    youtube: [],
  },
};

const KeywordsComparisonProvider = (props: React.PropsWithChildren<{}>) => {
  const [initialized, setInitializedTrue] = useBoolean();
  const preferences = usePreferences();
  const recommendation = useRecommendation();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchKeywordsComparison, {
    onSuccess: (data) => recommendation.setRecommendation(data.recommendation),
  });

  const handleSearch = (payload: IMultiKeywordSearchFormikValues) => {
    execute(payload);
  };

  useEffect(() => {
    if (
      preferences.data.currentCountry &&
      preferences.data.comparisonKeywords[0] !== "" &&
      !initialized
    ) {
      execute({
        country: preferences.data.currentCountry,
        keywords: preferences.data.comparisonKeywords,
      });
      setInitializedTrue();
    }
  }, [
    execute,
    preferences.data.comparisonKeywords,
    preferences.data.currentCountry,
    initialized,
    setInitializedTrue,
  ]);

  return (
    <KeywordsComparisonContext.Provider value={{ data, handleSearch }}>
      <Loader textData={keywordComparison} hidden={!isLoading} />
      {props.children}
    </KeywordsComparisonContext.Provider>
  );
};

export default KeywordsComparisonProvider;
