import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { Button, Text } from "components/core";

const helper = new ThemeHelper();

const CardContainer = styled.div`
  ${helper.centerContent()};
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  min-width: 180px;
  border: 1px solid ${({ theme }) => theme.colors.success};
  ${helper.getBoxShadow("darkGrey", { intensity: "high" })}
  border-radius: 10px;
  padding: ${helper.getSpacing("small")};
  text-align: center;
`;

interface ModalMessageCardProps {
  onDelete: () => void;
}

const InfoMessage = ({ onDelete }: ModalMessageCardProps) => {
  return (
    <CardContainer>
      <Text variant="alpha" font="poppins">
        Do you really want to delete? It will delete all your messages and
        behaviour.
      </Text>
      <Button variant="alpha" content="Delete" onClick={onDelete} />
    </CardContainer>
  );
};

export default InfoMessage;
