import { generalValidationSchema } from "config/common";
import * as Yup from "yup";

const createPasswordValidation = {
  schema: Yup.object({
    password: generalValidationSchema.password,
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match!"
    ),
  }),
};

export { createPasswordValidation };
