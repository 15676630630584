import React, { createContext } from "react";
import { useReactQuery, useWindowRedirect } from "hooks/common";
import { subscribe as api } from "api/Pricing";
import { useTrial } from "hooks/Pricing";
import { Spinner } from "components/common";
import { TActiateTrialFormValues, TCreateSessionFormValues } from "./types";

export const SubscriptionContext = createContext({
  handleCreateSession: (values: TCreateSessionFormValues) => {},
  handleActivateTrial: (values: TActiateTrialFormValues) => {},
});
const SubscriptionProvider = (props: React.PropsWithChildren<{}>) => {
  const windowRedirect = useWindowRedirect();
  const trial = useTrial();
  const subscription = useReactQuery(api.post, {
    onSuccess: (data) => windowRedirect.redirectExternal(data.redirectUrl),
  });

  const handleCreateSession = (values: TCreateSessionFormValues) =>
    subscription.execute(values);

  const handleActivateTrial = (values: TActiateTrialFormValues) =>
    trial.execute(values);

  return (
    <SubscriptionContext.Provider
      value={{ handleCreateSession, handleActivateTrial }}
    >
      <Spinner fullscreen hidden={!subscription.isLoading} />
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
