import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchConsoleService } from "services/WebAnalytics";

export interface IGoogleSearchConsoleSelectionState {
  site: string;
}

const service = new SearchConsoleService();

const initialState: IGoogleSearchConsoleSelectionState =
  service.getSelections();

export const googleSearchConsoleSelection = createSlice({
  name: "googleSearchConsoleSelection",
  initialState,
  reducers: {
    selectGoogleSearchConsoleSite: (state, action: PayloadAction<string>) => {
      service.setSelections({
        ...state,
        site: action.payload,
      });

      state.site = action.payload;
    },
    clearSearchConsoleSelection: () => ({
      site: "",
    }),
  },
});

export const { selectGoogleSearchConsoleSite, clearSearchConsoleSelection } =
  googleSearchConsoleSelection.actions;

export default googleSearchConsoleSelection.reducer;
