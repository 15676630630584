import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/SocialMediaPost";
import {
  TScheduleDeletePayload,
  TScheduleCreatePayload,
  TScheduleUpdatePayload,
  TActivityFetchPayload,
  TCalendarFetchPayload,
} from "./types";
import { ScheduleTWAdapter } from "adapters/SocialMediaPost";

const URL = links.scheduleBaseUrl.append("/twitter");
const adapter = new ScheduleTWAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR, {
  accountNotFound: "Account not found",
});

const scheduleTwitter = {
  fetchCalendar: async ({ uid, ...params }: TCalendarFetchPayload) => {
    try {
      const headers = adapter.getHeaders(uid);

      if (headers) {
        const { data } = await dolphynAPI(URL, {
          headers,
          params,
        });
        return adapter.parseCalendarResponse(data);
      }
      error.throwError("accountNotFound");
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  fetchActivity: async ({ uid, ...params }: TActivityFetchPayload) => {
    try {
      const headers = adapter.getHeaders(uid);

      if (headers) {
        const { data } = await dolphynAPI(URL, {
          headers,
          params,
        });
        return adapter.parseActivityResponse(data);
      }
      error.throwError("accountNotFound");
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  post: async (values: TScheduleCreatePayload) => {
    try {
      const payload = adapter.getPostPayload(values);

      if (payload) {
        const { data } = await dolphynAPI.post(URL, payload);
        return adapter.parsePostResponse(data);
      }
      error.throwError("accountNotFound");
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  put: async (values: TScheduleUpdatePayload) => {
    try {
      const payload = adapter.getPutPayload(values);

      if (payload) {
        const { data } = await dolphynAPI.put(`${URL}/${values.id}`, payload);
        return adapter.parsePutResponse(data);
      }
      error.throwError("accountNotFound");
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  delete: async ({ id }: TScheduleDeletePayload) => {
    try {
      await dolphynAPI.delete(`${URL}/${id}`);

      return id;
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default scheduleTwitter;
