import { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { generalPreferencesApi } from "api/Profile";
import { setGeneralPreferences } from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TGeneralPreferences } from "adapters/Profile/types";

type TGeneralPreferencesContext = {
  isLoading: boolean;
  handleUpdate: (data: Partial<TGeneralPreferences>) => void;
  handleUpdateAsync: (
    data: Partial<TGeneralPreferences>
  ) => Promise<TGeneralPreferences | undefined>;
  fetch: () => void;
  details: TGeneralPreferences;
};

export const GeneralPreferencesContext =
  createContext<null | TGeneralPreferencesContext>(null);

const GeneralPreferencesProvider = (props: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();
  const details = useAppSelector((state) => state.profile.generalPreferences);

  const { execute: fetch, isLoading: fetching } = useReactQuery(
    generalPreferencesApi.get,
    {
      onSuccess: (data) => {
        dispatch(setGeneralPreferences(data));
      },
    }
  );

  const {
    execute: handleUpdate,
    executeAsync,
    isLoading: updating,
  } = useReactQuery(generalPreferencesApi.patch, {
    onSuccess: (data) => {
      dispatch(setGeneralPreferences(data));
    },
  });

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  const isLoading = fetching || updating;

  return (
    <GeneralPreferencesContext.Provider
      value={{
        handleUpdate,
        handleUpdateAsync: executeAsync,
        fetch,
        isLoading,
        details,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </GeneralPreferencesContext.Provider>
  );
};

export default GeneralPreferencesProvider;
