import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { IGraphBodyStyleProps } from "./types";

const helper = new ThemeHelper();

const OuterContainer = styled.div`
  overflow: auto;
  ${helper.getStyledScrollbar()};
`;

const InnerContainer = styled.div<IGraphBodyStyleProps>`
  ${({ enteriesCount, layout }) => {
    switch (layout) {
      case "vertical":
        return css`
          height: ${enteriesCount * 60 + "px"};
        `;
      case "horizontal":
        return css`
          width: ${enteriesCount * 60 + "px"};
        `;
      default:
        break;
    }
  }};
`;

export { OuterContainer, InnerContainer };
