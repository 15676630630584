export const topParagraph =
  "We will analysis new tweet from potential customers and match with your keywords. We will do like &/Or reply on your behalf";

export const matchNumberList = [
  { key: "one", value: "1", text: "1" },
  { key: "two", value: "2", text: "2" },
  { key: "three", value: "3", text: "3" },
  { key: "four", value: "4", text: "4" },
  { key: "five", value: "5", text: "5" },
  { key: "six", value: "6", text: "6" },
  { key: "seven", value: "7", text: "7" },
  { key: "eight", value: "8", text: "8" },
  { key: "nine", value: "9", text: "9" },
  { key: "ten", value: "10", text: "10" },
];
