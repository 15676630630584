import { TMultiCheckDropdownOption } from "components/common/MultiCheckDropdown/types";

const usePlatformDropdown = () => {
  const getOnChangeFieldValues = (
    currentPages: Required<TMultiCheckDropdownOption<string>>[],
    selectedPage: Required<TMultiCheckDropdownOption<string>>
  ) => {
    const itemPresent = currentPages.find(
      (page) => page.id === selectedPage.id
    );
    if (!itemPresent) {
      const pages = [...currentPages, selectedPage];
      return pages;
    }
    const pages = currentPages.filter((page) => page.id !== selectedPage.id);
    return pages;
  };

  return {
    getOnChangeFieldValues,
  };
};

export default usePlatformDropdown;
