import { TwitterService } from "services/Auth";
import { TConversation } from "./types";

type TAPIPayload = {
  like: boolean;
};

type TAPIResponse = {
  like: boolean;
};

class LikeReplyAdapter {
  private twitter = new TwitterService();
  private parseResponse = (
    tweets: TConversation,
    apiResponse: TAPIResponse
  ): TConversation => {
    if (apiResponse.like) {
      return {
        ...tweets,
        likeStatus: true,
        likeCount: tweets.likeCount + 1,
      };
    }
    return {
      ...tweets,
      likeStatus: false,
      likeCount: tweets.likeCount - 1,
    };
  };

  getPostUrl = (url: string, tweets: TConversation) =>
    `${url}/${tweets.tweetId}`;

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TConversation): TAPIPayload => {
    return {
      like: !data.likeStatus,
    };
  };

  parsePostResponse = this.parseResponse;
}

export default LikeReplyAdapter;
