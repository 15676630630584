import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  connectTwitter: false,
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setConnectTwitter: (state, action: PayloadAction<boolean>) => {
      return { ...state, connectTwitter: action.payload };
    },
  },
});

export const { setConnectTwitter } = common.actions;

export default common.reducer;
