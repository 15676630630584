import styled from "styled-components";
import { styles } from "../Common";

const Menu = styled.div`
  display: flex;
  padding: 0 ${styles.helper.getSpacing("small")}
    ${styles.helper.getSpacing("medium")} ${styles.helper.getSpacing("medium")};
  justify-content: space-around;
  flex-wrap: wrap;
`;

export default Menu;
