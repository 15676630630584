import React, { createContext, useState } from "react";
import {
  useDeleteThread,
  usePostNow,
  useScheduledPost,
  useSchedulePost,
  useScheduleThread,
  useSentPost,
  useUpdateThread,
} from "hooks/Twitter";
import {
  IDeleteThreadFormValues,
  IPostNowFormValues,
  ISchedulePostFormValues,
  IScheduleThreadFormValues,
  ITweetThreadFormValues,
  IUpdateThreadFormValues,
} from "./types";
import { useNotification, useTwitterAuth } from "hooks";
import { usePersonalDetails } from "hooks/Profile";
import { TPosts } from "adapters/Twitter/types";
import { useAppSelector } from "redux/store/hooks";

type TPostScheduleContext = {
  scheduledList: TPosts[];
  sentList: TPosts[];
  isScheduling: boolean;
  isPosting: boolean;
  handleSchedulePost: (formValues: ISchedulePostFormValues) => void;
  handleScheduleThread: (formValues: IScheduleThreadFormValues) => void;
  handleDeleteThread: (formValues: IDeleteThreadFormValues) => void;
  handleUpdateThread: (formValues: IUpdateThreadFormValues) => void;
  handleTweetThread: (formValues: ITweetThreadFormValues) => void;
  handlePostNow: (formValues: IPostNowFormValues) => void;
  getScheduledPost: () => void;
  getSentPost: () => void;
};

export const PostSchedulerContext = createContext<TPostScheduleContext | null>(
  null
);

const PostSchedulerProvider = (props: React.PropsWithChildren<{}>) => {
  const [scheduledList, setScheduledList] = useState<TPosts[]>([]);
  const [sentList, setSentList] = useState<TPosts[]>([]);
  const notification = useNotification();
  const postNow = usePostNow();
  const schedulePost = useSchedulePost();
  const scheduleThread = useScheduleThread();
  const updateThread = useUpdateThread();
  const deleteThread = useDeleteThread();
  const scheduledPost = useScheduledPost();
  const sentPost = useSentPost();
  const twitterUser = useTwitterAuth();
  const personalDetails = usePersonalDetails();

  const uid = twitterUser.selectedAccount.uid;
  const timezone = personalDetails.details.timezone;

  const retweetTime = useAppSelector(
    (state) => state.twitter.thread.retweetDateTime
  );
  const retweet = useAppSelector((state) => state.twitter.thread.autoRetweet);

  const handleSchedulePost = (formValues: ISchedulePostFormValues) => {
    schedulePost.execute({
      ...formValues,
      timezone,
      retweetTime,
      retweet,
    });
  };

  const handleScheduleThread = (formValues: IScheduleThreadFormValues) => {
    scheduleThread.execute({ ...formValues, timezone, retweetTime, retweet });
  };

  const handleDeleteThread = (formValues: IDeleteThreadFormValues) => {
    deleteThread.execute(formValues, {
      onSuccess: (data) => {
        if (data) {
          notification.notifySuccess(data.message);
          getSentPost();
          getScheduledPost();
        }
      },
    });
  };

  const handleUpdateThread = (formValues: IUpdateThreadFormValues) => {
    updateThread.execute(
      {
        ...formValues,
        timezone,
      },
      {
        onSuccess: (data) => {
          getScheduledPost();
        },
      }
    );
  };

  const handlePostNow = (formValues: IPostNowFormValues) => {
    postNow.execute({ ...formValues, uid, timezone, retweetTime, retweet });
  };

  const handleTweetThread = (formValues: ITweetThreadFormValues) => {
    postNow.execute({ ...formValues, uid, timezone, retweetTime, retweet });
  };

  const getScheduledPost = () => {
    scheduledPost.execute(
      { timezone, uid },
      {
        onSuccess: (data) => {
          if (data) {
            setScheduledList(data.posts);
          }
        },
      }
    );
  };

  const getSentPost = () => {
    sentPost.execute(
      { timezone, uid },
      {
        onSuccess: (data) => {
          if (data) {
            setSentList(data.posts);
          }
        },
      }
    );
  };

  return (
    <PostSchedulerContext.Provider
      value={{
        scheduledList,
        sentList,
        handlePostNow,
        handleTweetThread,
        handleSchedulePost,
        handleScheduleThread,
        handleDeleteThread,
        handleUpdateThread,
        getScheduledPost,
        getSentPost,
        isPosting: postNow.isLoading,
        isScheduling: schedulePost.isLoading || scheduleThread.isLoading,
      }}
    >
      {props.children}
    </PostSchedulerContext.Provider>
  );
};

export default PostSchedulerProvider;
