import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Profile";
import { PlanDetailsAdapter } from "adapters/Profile";

const URL = links.BASE_PROFILE_API_URL.append("/plan");
const adapter = new PlanDetailsAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR);

const planDetails = {
  get: async () => {
    try {
      const { data } = await dolphynAPI(URL);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default planDetails;
