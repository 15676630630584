import { createContext, useEffect } from "react";
import { ISimilarKeywordData } from "adapters/ProductDemand/types";
import { useReactQuery, useBoolean } from "hooks/common";
import { fetchSimilarKeyword } from "api/ProductDemand";
import { usePreferences, useRecommendation } from "hooks/ProductDemand";
import { ISingleKeywordSearchFormikValues } from "./types";
import { Loader } from "components/common";
import { similarKeyword } from "config/ProductDemand";

type TSimilarKeywordContext = {
  data: ISimilarKeywordData;
  handleSearch: (payload: ISingleKeywordSearchFormikValues) => void;
};

export const SimilarKeywordContext =
  createContext<TSimilarKeywordContext | null>(null);

const initialData: ISimilarKeywordData = {
  cards: {
    questions: [],
    risingKeywords: [],
    topKeywords: [],
  },
  recommendation: [],
};

const SimilarKeywordProvider = (props: React.PropsWithChildren<{}>) => {
  const [initialized, setInitializedTrue] = useBoolean();
  const preferences = usePreferences();
  const recommendation = useRecommendation();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchSimilarKeyword, {
    onSuccess: (data) => recommendation.setRecommendation(data.recommendation),
  });

  const handleSearch = (payload: ISingleKeywordSearchFormikValues) => {
    execute(payload);
  };

  useEffect(() => {
    if (
      preferences.data.currentCountry &&
      preferences.data.endDate &&
      preferences.data.startDate &&
      preferences.data.keyword &&
      !initialized
    ) {
      execute({
        country: preferences.data.currentCountry,
        keyword: preferences.data.keyword,
        startDate: preferences.data.startDate,
        endDate: preferences.data.endDate,
      });
      setInitializedTrue();
    }
  }, [
    execute,
    preferences.data.keyword,
    preferences.data.currentCountry,
    preferences.data.endDate,
    preferences.data.startDate,
    initialized,
    setInitializedTrue,
  ]);

  return (
    <SimilarKeywordContext.Provider value={{ data, handleSearch }}>
      <Loader textData={similarKeyword} hidden={!isLoading} />
      {props.children}
    </SimilarKeywordContext.Provider>
  );
};

export default SimilarKeywordProvider;
