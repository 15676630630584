import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Profile";
import { PersonalDetailsAdapter } from "adapters/Profile";
import { TPersonalDetails } from "adapters/Profile/types";

const URL = links.BASE_PROFILE_API_URL.append("/personal_details");
const adapter = new PersonalDetailsAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR);

const personalDetails = {
  get: async () => {
    try {
      const { data } = await dolphynAPI(URL);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  patch: async (values: Partial<TPersonalDetails>) => {
    const payload = adapter.getPatchPayload(values);
    try {
      const response = await dolphynAPI.patch(URL, payload);

      return adapter.parsePatchResponse(response.data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default personalDetails;
