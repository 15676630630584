import { Graph } from "components/common";
import { Header } from "components/core";
import { useGraphHelper } from "hooks";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis } from "recharts";
import { useTheme } from "styled-components";
import { GraphContainer } from "./TwitterAuditGraph.styles";

type TTwitterAudit = {
  auditData: string;
  value: number;
};

interface ITwitterAuditGraphProps {
  data: TTwitterAudit[];
}

const TwitterAuditGraph = (props: ITwitterAuditGraphProps) => {
  const theme = useTheme();
  const graph = useGraphHelper(props.data);

  return (
    <div>
      <Header
        size="small"
        font="poppins"
        variant="black"
        content="Check Twitter audit for this week"
        mb="small"
      />
      <GraphContainer>
        <Graph.Body>
          <BarChart data={props.data}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="7 7"
              stroke={theme.colors.extraLightGrey}
            />
            <XAxis
              dataKey={graph.getDataKeysMap().auditData}
              tickFormatter={graph.capitalizeTick}
            />
            <Tooltip labelStyle={{ textTransform: "capitalize" }} />
            <Bar
              dataKey={graph.getDataKeysMap().value}
              barSize={40}
              label={{
                position: "insideTop",
                fontFamily: theme.fonts.poppins,
                strokeWidth: 0.5,
                fill: theme.colors.white,
              }}
              radius={[10, 10, 0, 0]}
              fill={theme.colors.alpha}
            />
          </BarChart>
        </Graph.Body>
      </GraphContainer>
    </div>
  );
};

export default TwitterAuditGraph;
