import { Dropdown, Header, Input } from "components/core";
import OtherOption from "./OtherOption";
import Option from "./Option";
import { Container, OptionContainer, Content } from "./QuestionCard.styles";

const QuestionCard = ({
  children,
  ...props
}: React.PropsWithChildren<React.ComponentPropsWithoutRef<"div">>) => {
  return <Container {...props}>{children}</Container>;
};

QuestionCard.Header = Header;
QuestionCard.Options = OptionContainer;
QuestionCard.Option = Option;
QuestionCard.OtherOption = OtherOption;
QuestionCard.Dropdown = Dropdown;
QuestionCard.Content = Content;
QuestionCard.Input = Input;

export default QuestionCard;
