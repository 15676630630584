import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import InstagramService, { TUid } from "services/Auth/instagramService";

const service = new InstagramService();

const initialState = {
  isSignedIn: service.isAuthenticated(),
  accounts: service.getAccounts(),
};

export const instagram = createSlice({
  name: "instagram",
  initialState,
  reducers: {
    reInitialize: (state) => {
      state.accounts = service.getAccounts();
      state.isSignedIn = service.isAuthenticated();
    },
    removeAccount: (state, action: PayloadAction<TUid>) => {
      /* 
      Removes single account or removes complete cookie if having single account
      */
      service.removeAccount(action.payload);
      state.accounts = service.getAccounts();
      state.isSignedIn = service.isAuthenticated();
    },
    clearData: (state) => {
      /* 
      Clear all accounts & pages, should be used in signout process.
      */
      service.clearData();
      state.accounts = service.getAccounts();
      state.isSignedIn = false;
    },
  },
});

export const { reInitialize, removeAccount, clearData } = instagram.actions;

export type { TUid } from "services/Auth/facebookService";

export default instagram.reducer;
