import { TwitterService } from "services/Auth";
import { TThread } from "./types";

type TAPIObject = {
  datetime: Date;
  retweetTime: Date;
  retweet: boolean;
  timezone: string;
  message: string;
  files?: any[];
};

type TAPIPayload = {
  token_id: string;
  datetime: string;
  retweet_schedule_datetime: string;
  timezone: string;
  account_id: string;
  account_name: string;
  message: string;
  files: any[];
};

type TAPIResponse = {
  threads: TThread[];
};

class SchedulePostAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      threads: apiResponse.threads,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TAPIObject) => {
    const account = this.twitter.getSelectedAccount();

    if (data.retweet) {
      if (account) {
        return {
          token_id: account.tokenId,
          datetime: data.datetime.toISOString(),
          retweet_schedule_datetime: data.retweetTime.toISOString(),
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          message: data.message,
          files: data.files,
        } as TAPIPayload;
      }
    } else {
      if (account) {
        return {
          token_id: account.tokenId,
          datetime: data.datetime.toISOString(),
          timezone: data.timezone,
          account_id: account.userId,
          account_name: account.userName,
          message: data.message,
          files: data.files,
        } as TAPIPayload;
      }
    }
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default SchedulePostAdapter;
