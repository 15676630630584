import { IInstantReplies, TInstantReplies } from "adapters/Twitter/types";
import { brandMonitoring } from "api/Twitter";
import { Loader, Spinner } from "components/common";
import { instantReply, likeMessage } from "config/Twitter";
import { useTwitterAuth } from "hooks";
import { useNotification, useReactQuery } from "hooks/common";
import {
  useLikeInstant,
  useRecommendation,
  useReplyTweet,
  useRetweetTweet,
  useTweetHelper,
  useReplyHelper,
} from "hooks/Twitter";
import { createContext, useEffect, useState } from "react";
import { IInstantReplyFormValues } from "./types";

type TBrandMonitoringContext = {
  replies: IInstantReplies["instantReplies"];
  tweetActions: IInstantReplies["tweetActions"];
  handleLikeTweet: (data: TInstantReplies) => void;
  handleReply: (values: IInstantReplyFormValues) => void;
  handleRetweet: (tweetId: string) => void;
};

export const BrandMonitoringContext =
  createContext<TBrandMonitoringContext | null>(null);

const BrandMonitoringProvider = (props: React.PropsWithChildren<{}>) => {
  const [replies, setReplies] = useState<IInstantReplies["instantReplies"]>([]);
  const [tweetActions, setTweetActions] =
    useState<IInstantReplies["tweetActions"]>(false);
  const recommendation = useRecommendation();
  const likeInstant = useLikeInstant();
  const tweetHelper = useTweetHelper();
  const replyTweet = useReplyTweet();
  const replyHelper = useReplyHelper();
  const retweetTweet = useRetweetTweet();
  const notification = useNotification();
  const { isSignedIn, selectedAccount } = useTwitterAuth();

  const { execute, isLoading } = useReactQuery(brandMonitoring, {
    onSuccess: (data) => {
      recommendation.setRecommendation(data.recommendations);
      setReplies(data.instantReplies);
      setTweetActions(data.tweetActions);
    },
  });

  useEffect(() => {
    execute({
      isAuthenticated: isSignedIn,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, isSignedIn, selectedAccount.userName]);

  const handleLikeTweet = (data: TInstantReplies) =>
    likeInstant.execute(data, {
      onSuccess: (tweet) => {
        setReplies((current) => tweetHelper.findAndReplace(current, tweet));
      },
    });

  const handleRetweet = (tweetId: string) => {
    retweetTweet.execute(tweetId);
    notification.notifySuccess("Retweeted Successfully");
  };

  const handleReply = (values: IInstantReplyFormValues) => {
    replyHelper.handleReply(values);
  };

  return (
    <BrandMonitoringContext.Provider
      value={{
        replies,
        tweetActions,
        handleLikeTweet,
        handleReply,
        handleRetweet,
      }}
    >
      <Loader textData={instantReply} hidden={!isLoading} />
      <Loader textData={likeMessage} hidden={!likeInstant.isLoading} />
      <Spinner
        fullscreen
        hidden={!replyTweet.isLoading && !retweetTweet.isLoading}
      />
      {props.children}
    </BrandMonitoringContext.Provider>
  );
};

export default BrandMonitoringProvider;
