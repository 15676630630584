import styled from "styled-components";
import { styles } from "../Common";

const Container = styled.section`
  ${styles.helper.containerize(80)}
  ${styles.marginBottom}
  border: 2px solid ${({ theme }) => theme.colors.alpha};
  border-radius: ${styles.helper.getSpacing("small")};
  overflow: hidden;
  &,
  * {
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    ${styles.helper.containerize(90)};
  }
`;

const TopBar = styled.div`
  background-color: ${({ theme }) => theme.colors.ghostWhite};
  padding: 0 ${styles.helper.getSpacing("small")};
  border-bottom: 2px solid ${({ theme }) => theme.colors.alpha};
`;

const InnerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0px 0px 10px 10px;
  padding: ${styles.helper.getSpacing("small")};

  @media screen and (max-width: 768px) {
    padding-left: ${styles.helper.getSpacing("tiny")};
    padding-right: ${styles.helper.getSpacing("tiny")};
  }
`;

const Body = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.alpha};
  border-radius: 10px;
  max-width: 95%;
  margin: auto;
  padding: ${styles.helper.getSpacing("small")};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 500px;
`;

export { Container, TopBar, InnerContainer, Body, Content, Image };
