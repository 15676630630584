import styled from "styled-components";

import { ReactComponent as ReplyIcon } from "assets/icons/Twitter/reply.svg";
import { ReactComponent as RetweetIcons } from "assets/icons/Twitter/retweet.svg";
import { ReactComponent as HeartIcon } from "assets/icons/Twitter/heart.svg";
import { ReactComponent as ShareIcon } from "assets/icons/Twitter/share.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  svg {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const TweetAction = () => {
  return (
    <Wrapper>
      <span>
        <ReplyIcon /> 456
      </span>
      <span>
        <RetweetIcons /> 675
      </span>
      <span>
        <HeartIcon /> 987
      </span>
      <span>
        <ShareIcon />
      </span>
    </Wrapper>
  );
};

export default TweetAction;
