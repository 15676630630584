import { StyledForm } from "./Form.styles";
import { Button, Header, Text } from "components/core";
import Input from "./InputField";
import Checkbox from "./CheckboxField";
import Label from "./Label";
import Field from "./Field";
import Toggle from "./ToggleField";
import Box from "../Box";
import DropdownField from "./DropdownField";
import MultiSelectDropdownField from "./MultiSelectDropdownField";

interface IFormProps extends React.ComponentPropsWithoutRef<"div"> {
  boxed?: boolean;
}

const Form = ({
  boxed,
  children,
  ...props
}: React.PropsWithChildren<IFormProps>) => {
  if (boxed) {
    return <Box {...props}>{children}</Box>;
  }
  return <>{children}</>;
};

Form.Header = Header;
Form.Or = () => (
  <Text
    variant="lightGrey"
    style={{ textAlign: "center" }}
    content={"- OR -"}
    size="small"
  />
);
Form.Text = Text;
Form.Form = StyledForm;
Form.Input = Input;
Form.Button = Button;
Form.Checkbox = Checkbox;
Form.Label = Label;
Form.Field = Field;
Form.Toggle = Toggle;
Form.DropdownField = DropdownField;
Form.MultiSelectDropdownField = MultiSelectDropdownField;

export default Form;
