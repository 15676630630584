import { Button as SemanticButton, ButtonProps } from "semantic-ui-react";
import styled, { css, DefaultColors } from "styled-components";
import { lighten } from "polished";
import { getTextColorFromBg } from "helpers";
import ThemeHelper from "theme/themeHelper";
import { TMarginProps } from "theme/types";

const helper = new ThemeHelper();

interface IProps extends ButtonProps, TMarginProps {
  variant: keyof DefaultColors;
  outline?: boolean;
  borderless?: boolean;
  style?: React.CSSProperties;
}

const StyledButton = styled(SemanticButton)<IProps>`
  &&& {
    font-family: ${({ theme }) => theme.fonts.poppins};
    margin: unset;
    ${helper.getFontWeight("low")}
    border-radius: 10px;
    ${({ variant, theme }) => {
      const { colors } = theme;
      return css`
        background-color: ${colors[variant]};
        color: ${lighten(0.5, colors[variant])};
        &:hover {
          background-color: ${lighten(0.05, colors[variant])};
        }
        &:disabled {
          background-color: ${lighten(0.4, colors[variant])};
          color: ${colors[variant]};
        }
      `;
    }}
    ${({ mb, mt, ms, me }) =>
      helper.getMargins({ top: mt, bottom: mb, left: ms, right: me })}
  }
  ${({ variant, outline, theme }) => {
    const { colors } = theme;
    if (outline) {
      return css`
        &&&& {
          background-color: unset;
          color: ${colors[variant]};
          border: 1px solid ${colors[variant]};
          &:hover {
            background-color: ${lighten(0.54, colors[variant])};
            color: ${colors[variant]};
          }
          &:disabled {
            background-color: ${lighten(0.4, colors[variant])};
            color: ${colors[variant]};
          }
        }
      `;
    }
  }}
  ${({ variant, active, theme }) => {
    const { colors } = theme;
    if (active) {
      return css`
        &&&& {
          background-color: ${colors[variant]};
          color: ${lighten(0.5, colors[variant])};
          border: 1px solid ${colors[variant]};
          &:hover {
            background-color: ${lighten(0.05, colors[variant])};
            color: ${getTextColorFromBg(colors[variant])};
          }
          &:disabled {
            background-color: ${lighten(0.4, colors[variant])};
            color: ${colors[variant]};
          }
        }
      `;
    }
  }}
  ${({ borderless, theme, variant }) => {
    const { colors } = theme;
    if (borderless) {
      return css`
        &&&& {
          background-color: unset;
          color: ${colors[variant]};
          border: unset;
          &:hover {
            background-color: ${lighten(0.05, colors[variant])};
            color: ${getTextColorFromBg(colors[variant])};
          }
          &:disabled {
            background-color: ${lighten(0.4, colors[variant])};
            color: ${colors[variant]};
          }
        }
      `;
    }
  }}
`;

const Button = (props: IProps) => <StyledButton {...props} />;

export default Button;
