import { Context, useContext } from "react";

const useContextOrError = <T>(payload: Context<T | null>): T => {
  const context = useContext(payload);
  if (!context)
    throw new Error(
      `${payload.displayName} not mounted above where you called it.`
    );

  return context;
};

export default useContextOrError;
