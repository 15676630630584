import { createContext, useEffect, useState } from "react";
import { useReactQuery } from "hooks/common";
import { preferences as api } from "api/ProductDemand";
import { IPreferencesData as IBasePreferencesData } from "adapters/ProductDemand/types";
import { Spinner } from "components/common";
import { useKeywordsDetails } from "hooks/Profile";

interface IPreferencesData extends IBasePreferencesData {
  /* Extending preferences data provided by API to include more data like primary keyword from Profile Data using useKeywordsDetails. This maintains search form state across various sections by reinitializing formik */
  keyword: string;
  comparisonKeywords: string[];
}

type TPreferencesContext = {
  data: IPreferencesData;
  handleUpdatePreferences: (payload: Partial<IPreferencesData>) => void;
};

const initialPreferences: IPreferencesData = {
  startDate: new Date(),
  endDate: new Date(),
  countryList: [],
  currentCountry: "",
  keyword: "",
  comparisonKeywords: [],
};

export const PreferencesContext = createContext<null | TPreferencesContext>(
  null
);

const PreferencesProvider = (props: React.PropsWithChildren<{}>) => {
  const [data, setData] = useState<IPreferencesData>(initialPreferences);
  const keywordDetails = useKeywordsDetails();

  const { execute, isLoading } = useReactQuery(api, {
    onSuccess: (data) =>
      setData({
        ...data,
        keyword: keywordDetails.details.primaryKeyword,
        comparisonKeywords: keywordDetails.details.secondaryKeywords,
      }),
  });

  const handleUpdatePreferences = (payload: Partial<IPreferencesData>) =>
    setData((current) => ({ ...current, ...payload }));

  useEffect(() => {
    execute();
  }, [execute]);

  return (
    <PreferencesContext.Provider value={{ data, handleUpdatePreferences }}>
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </PreferencesContext.Provider>
  );
};

export default PreferencesProvider;
