import { Button } from "components/core";
import Item from "./Item";
import { Container, ButtonContainer } from "./Menu.styles";

interface IMenuProps {
  onSubmit: () => void;
}

const Menu = ({ children, onSubmit }: React.PropsWithChildren<IMenuProps>) => {
  return (
    <Container>
      {children}
      <ButtonContainer>
        <Button variant="alpha" content="Done" onClick={onSubmit} fluid />
      </ButtonContainer>
    </Container>
  );
};

Menu.Item = Item;

export default Menu;
