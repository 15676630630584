import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { LeadNurturingAdapter } from "adapters/Twitter";
import { TEngagementPostPayload } from "adapters/Twitter/types";
import { dolphynAPI } from "api/instances";

const URL = links.baseTwitterApiUrl.append("/engagement");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new LeadNurturingAdapter();

export type TEngagementPayload = {
  isAuthenticated?: boolean;
};

const leadNurturing = {
  get: async ({ isAuthenticated }: TEngagementPayload) => {
    try {
      if (isAuthenticated) {
        const headers = adapter.getConfig();
        const { data } = await dolphynAPI(URL, headers);

        return adapter.getResponse(data);
      }
      const { data } = await dolphynAPI(URL);

      return adapter.getResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  post: async (values: TEngagementPostPayload) => {
    try {
      const headers = adapter.getConfig();
      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload, headers);

      return adapter.postResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  delete: async ({ isAuthenticated }: TEngagementPayload) => {
    try {
      if (isAuthenticated) {
        const headers = adapter.getConfig();
        const { data } = await dolphynAPI.delete(URL, headers);

        return adapter.deleteResponse(data);
      }

      const { data } = await dolphynAPI.delete(URL);

      return adapter.deleteResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default leadNurturing;
