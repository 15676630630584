import { DefaultColors } from "styled-components";
import { TIconProps } from "../Icon";
import Backward from "./Backward";
import Forward from "./Forward";

interface IForwardBackwardProps {
  onForwardClick?: () => void;
  onBackwardClick?: () => void;
  size?: TIconProps["size"];
  variant?: keyof DefaultColors;
}

const ForwardBackward = ({
  children,
  onBackwardClick,
  onForwardClick,
  ...props
}: React.PropsWithChildren<IForwardBackwardProps>) => {
  return (
    <>
      <Backward onClick={onBackwardClick} {...props} />
      {children}
      <Forward onClick={onForwardClick} {...props} />
    </>
  );
};
ForwardBackward.Forward = Forward;
ForwardBackward.Backward = Backward;

export default ForwardBackward;
