import { LinkedInPostHeader, LinkedInPostAction } from "./LinkedIn";
import { PostCard } from "./LinkedInPreview.styles";
import { SelectInfo } from "./Common.styles";
import PostContent from "./PostContent";

type TDefaultProps = {
  linkedin: any;
  text: string;
  files: File[];
};

const LinkedInPreview = ({ linkedin, text, files }: TDefaultProps) => {
  if (!linkedin.length)
    return <SelectInfo>Select LinkedIn for LinkedIn Post Preview</SelectInfo>;

  return (
    <PostCard>
      <LinkedInPostHeader
        profileImage={linkedin[0].image}
        name={linkedin[0].title}
        position="Co-Founder of Disney+"
      />
      <PostContent postText={text} postFiles={files} />
      <LinkedInPostAction />
    </PostCard>
  );
};

export default LinkedInPreview;
