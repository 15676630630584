import { useRef, useState } from "react";
import { Text } from "components/core";
import { useNotification } from "hooks/common";
import useFile, { TPreviewUrl, TFileType, TConfig } from "hooks/common/useFile";
import Preview from "./Preview";
import { Container } from "./FileDrop.styles";

interface IFileDropOwnProps {
  acceptedTypes: TFileType;
  value: File[];
  onChange: (files: File[]) => void;
  onDelete: (files: File[]) => void;
  customValidation?: (files: File[]) => [isValided: boolean, error: string];
  config?: TConfig;
}

interface IFileDropProps
  extends IFileDropOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof IFileDropOwnProps> {}

const FileDrop = ({
  value,
  onChange,
  onDelete,
  customValidation,
  acceptedTypes,
  config,
  ...props
}: IFileDropProps) => {
  const notification = useNotification();
  const [dragging, setDragging] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const fileHandler = useFile(acceptedTypes, config);

  const handleChangeFiles = (files: File[]) => {
    if (customValidation) {
      const [validated, error] = customValidation(files);
      validated ? onChange(files) : notification.notifyDanger(error);
      return;
    }

    /* 
      Custom validation bypasses default validation provided as config
    */
    const [validated, error] = fileHandler.validateFiles(files);

    validated ? onChange(files) : notification.notifyDanger(error);
  };

  const handleDeleteFile = (url: TPreviewUrl) => {
    const newData = fileHandler.removeFile(value, url);
    onDelete(newData);
  };

  const handleClickUploadText = () => hiddenInputRef.current?.click();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = fileHandler.getDataTransferFiles(e);
    handleChangeFiles(files);
    setDragging(false);
  };

  const handleInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = fileHandler.getInputFiles(e);
    handleChangeFiles(files);
  };

  const renderText = () => {
    if (dragging) return <Text variant="alpha" content="Drop your files now" />;

    return (
      <div>
        <Text
          as="span"
          variant="alpha"
          content="Upload your files"
          style={{ cursor: "pointer" }}
          onClick={handleClickUploadText}
        />{" "}
        <Text as="span" variant="darkGrey" content="or drag and drop" />
      </div>
    );
  };

  const renderHiddenInput = () => {
    return (
      <input
        ref={hiddenInputRef}
        hidden
        multiple
        type="file"
        onChange={handleInputFile}
      />
    );
  };

  if (value && value.length > 0)
    return (
      <Preview
        previewUrls={fileHandler.getPreviewUrls(value)}
        onDelete={handleDeleteFile}
        {...props}
      />
    );

  return (
    <Container
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleFileDrop}
      dragging={dragging}
      {...props}
    >
      {renderHiddenInput()}
      {renderText()}
    </Container>
  );
};

export default FileDrop;
