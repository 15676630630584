import { useState } from "react";
import { Button, Modal } from "components/core";
import ChangeAccountCards from "./ChangeAccountCards";
import styled from "styled-components";

const AccountsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 20%);
  justify-content: space-evenly;
  gap: 1rem;
`;

const ChangeAccountButton = () => {
  const [showChangeAccount, setShowChangeAccount] = useState(false);

  return (
    <>
      <Button
        variant="alpha"
        content="Change Account"
        onClick={() => setShowChangeAccount(true)}
      />
      <Modal
        open={showChangeAccount}
        onClose={() => setShowChangeAccount(false)}
        onOutsideClick={() => setShowChangeAccount(false)}
        style={{ width: "1040px" }}
      >
        <AccountsGrid>
          <ChangeAccountCards />
        </AccountsGrid>
      </Modal>
    </>
  );
};

export default ChangeAccountButton;
