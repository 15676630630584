import { GraphHelper } from "helpers";
import {
  ISearchDemandData,
  TSearchDemandPayload,
  TRecommendation,
  TGraphMeta,
} from "./types";

type TAPIResponse = {
  demand_graph: {
    date: string;
    scale: number;
  }[];
  recommendations: TRecommendation[];
};

type TAPIPayload = {
  country: string;
  keyword: string;
  start_date: string;
  end_date: string;
};

class SearchDemandAdapter {
  private graphHelper = new GraphHelper();
  private getGraphMetaData = (payload: Record<string, any>[]) => {
    const maxValue = this.graphHelper.findHighestValue(payload);
    const maxAxisValue = this.graphHelper.converToMultiple(maxValue);
    const data: TGraphMeta = {
      maxAxisValue,
    };
    return data;
  };
  private parseResponse = (apiResponse: TAPIResponse): ISearchDemandData => {
    return {
      graph: apiResponse.demand_graph,
      graphMeta: this.getGraphMetaData(apiResponse.demand_graph),
      recommendation: apiResponse.recommendations,
    };
  };

  getGetPayload = (data: TSearchDemandPayload): TAPIPayload => {
    return {
      country: data.country,
      keyword: data.keyword,
      start_date: data.startDate.toISOString(),
      end_date: data.endDate.toISOString(),
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default SearchDemandAdapter;
