import { ReactComponent as FacebookIcon } from "assets/icons/SocialMediaPost/social-media-post-facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/SocialMediaPost/social-media-post-instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/SocialMediaPost/social-media-post-twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "assets/icons/SocialMediaPost/social-media-post-linkedin-icon.svg";

export const platforms = {
  facebook: "facebook",
  instagram: "instagram",
  twitter: "twitter",
  linkedin: "linkedin",
} as const;
export type TPlatform = keyof typeof platforms;
type IPlatformsMap = {
  [key in TPlatform]: {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
  };
};

export const platformsIconMap: IPlatformsMap = {
  facebook: { icon: FacebookIcon },
  instagram: {
    icon: InstagramIcon,
  },
  linkedin: {
    icon: LinkedinIcon,
  },
  twitter: {
    icon: TwitterIcon,
  },
};
