import { createSlice } from "@reduxjs/toolkit";
import { AuthService } from "services/Auth";

const authService = new AuthService();

const initialState = {
  isSignedIn: authService.isAuthenticated(),
};

export const dolphyn = createSlice({
  name: "dolphyn",
  initialState,
  reducers: {
    setDolphynSignedIn: (state) => {
      state.isSignedIn = true;
    },
    setDolphynSignedOut: (state) => {
      state.isSignedIn = false;
    },
    clearData: (state) => {
      authService.clearData();
      state.isSignedIn = false;
    },
  },
});

export const { setDolphynSignedIn, setDolphynSignedOut, clearData } =
  dolphyn.actions;

export default dolphyn.reducer;
