import { useState } from "react";

import { Flex, Modal, Text, Toggle } from "components/core";
import { useAutoMessage, useFileUpload } from "hooks/Twitter";
import { useBoolean, useTwitterAuth } from "hooks";
import { AutoWelcomeContainer } from "./AutoWelcome.styles";
import AutoWelcomeTextbox from "./AutoWelcomeTextbox";
import InfoMessage from "./InfoMessage";

const AutoWelcome = () => {
  const autoMessage = useAutoMessage();
  const fileUpload = useFileUpload();
  const { isSignedIn: isAuthenticated } = useTwitterAuth();

  const [showModal, setShowModalTrue, setShowModalFalse] = useBoolean();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");
  const [checked, setChecked] = useState(autoMessage.data.enabled);

  const handleToggle = () => {
    setChecked(true);
    if (checked) {
      setShowModalTrue();
    }
  };

  const handleDelete = () => {
    autoMessage.handleDelete({ isAuthenticated });
    setChecked(false);
    setShowModalFalse();
  };

  return (
    <AutoWelcomeContainer>
      <Flex gap="small">
        <Text
          font="poppins"
          variant="lightGrey"
          style={{ maxWidth: "80ch", fontSize: "1rem" }}
        >
          Auto Send A Direct Message To A Follower:
        </Text>
        <Toggle
          data-tip={checked ? "" : "Enable"}
          checked={checked}
          onClick={handleToggle}
        />
        <Modal
          open={showModal}
          onOutsideClick={setShowModalFalse}
          onClose={setShowModalFalse}
        >
          <InfoMessage onDelete={handleDelete} />
        </Modal>
      </Flex>
      {checked && (
        <AutoWelcomeTextbox
          placeholder="Type your reply here"
          files={files}
          onFilesChange={(files) => setFiles(files)}
          text={text}
          onTextChange={(value) => setText(value)}
          onSave={() => {
            if (files && Array.from(files).length > 0) {
              return fileUpload.upload(files, {
                onSuccess: async (files) => {
                  autoMessage.handleUpdate({
                    reply_text: text,
                    files: files,
                  });
                },
              });
            }
            autoMessage.handleUpdate({
              reply_text: text,
              files: autoMessage.data.files,
              isAuthenticated,
            });
            setFiles([]);
          }}
        />
      )}
    </AutoWelcomeContainer>
  );
};

export default AutoWelcome;
