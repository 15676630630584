import { Flex } from "components/core";
import { BaseModuleLayout } from "layout/common";
import AuditLayoutHeader from "./AuditLayoutHeader";

const AuditLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <AuditLayoutHeader header="Audit" />
      <BaseModuleLayout.Body>{props.children}</BaseModuleLayout.Body>
    </Flex>
  );
};

export default AuditLayout;
