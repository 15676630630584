import { Container, Input, Button } from "./InputButton.styles";

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const InputButton = ({ className, children }: IProps) => {
  return <Container className={className}>{children}</Container>;
};

InputButton.Input = Input;
InputButton.Button = Button;

export default InputButton;
