import { EmojiPicker, Textbox } from "components/common";
import { Button, LinkButton } from "components/core";
import { socialMediaCharactersLimit } from "config/common";
import { theme } from "theme";
import { Toolbar } from "./DMCard.styles";

type TDefaultProps = {
  open?: boolean;
  onCancel: () => void;
  onSave: () => void;
  text: string;
  onTextChange: (value: string) => void;
  placeholder?: string;
};

const MessageEditor = ({
  open,
  text,
  onTextChange,
  placeholder,
  onSave,
  onCancel,
  ...styles
}: TDefaultProps) => {
  if (!open) return null;

  return (
    <Textbox
      placeholder={placeholder}
      value={text}
      onChange={(e) => {
        if (e.target.value.length - 1 < socialMediaCharactersLimit.twitter) {
          onTextChange(e.target.value);
        }
      }}
      style={{
        zIndex: 2,
        position: "absolute",
        bottom: "25%",
        left: "5%",
        background: theme.colors.white,
        width: "475px",
        ...styles,
      }}
    >
      <Textbox.WordLimit
        remaining={socialMediaCharactersLimit.twitter - text.length}
      />
      <Textbox.Toolbar
        style={{ justifyContent: "space-between", background: "unset" }}
      >
        <Toolbar>
          <EmojiPicker onSelect={(emoji) => onTextChange(text + emoji)} />
        </Toolbar>
        <Toolbar>
          <Button
            type="submit"
            variant="alpha"
            content="Save"
            size="tiny"
            onClick={onSave}
          />
          <LinkButton content="Cancel" onClick={onCancel} />
        </Toolbar>
      </Textbox.Toolbar>
    </Textbox>
  );
};

export default MessageEditor;
