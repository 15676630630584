import styled from "styled-components";
import { ILoaderStyleProps } from "./types";

const BarWrapper = styled.div<ILoaderStyleProps>`
  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;

  &::before,
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
  }

  &::before {
    height: 2px;
    width: 6vmin;
    background-color: ${({ theme, variant = "alpha" }) =>
      theme.colors[variant]};
    animation: loader 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
  }

  @keyframes loader {
    0%,
    44%,
    88.1%,
    100% {
      transform-origin: left;
    }

    0%,
    100%,
    88% {
      transform: scaleX(0);
    }

    44.1%,
    88% {
      transform-origin: right;
    }

    33%,
    44% {
      transform: scaleX(1);
    }
  }
`;

export { BarWrapper };
