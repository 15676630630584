import styled from "styled-components";
import { Icon } from "components/core";

type TButtonProps = {
  visible?: boolean;
};

const Container = styled.div`
  position: fixed;
  bottom: 2%;
  right: 2%;
`;

const ArrowUpIcon = styled(Icon).attrs({
  name: "arrow circle up",
})``;

const Button = styled.button<TButtonProps>`
  color: #fff;
  font-family: 900;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: #355fa3;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  cursor: pointer;
`;

export { Container, Button, ArrowUpIcon };
