import styled from "styled-components";
import { styles } from "../Common";

const Section = styled.section`
  padding: ${styles.helper.getSpacing("extraLarge")};
  background-color: ${({ theme }) => theme.colors.aliceBlue};
  text-align: center;
  margin-bottom: ${styles.helper.getSpacing("extraLarge")};

  @media screen and (max-width: 768px) {
    padding: ${styles.helper.getSpacing("large")};
  }
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  ${styles.helper.getFontSize("medium")};
  ${styles.helper.getFontWeight("medium")};

  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 35 + 5px);
  }

  span {
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

export { Text, Section };
