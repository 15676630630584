import { useState } from "react";
import { useBrandMonitoring } from "hooks/Twitter";
import { Text } from "components/core";
import InstantRepliesCard from "./InstantRepliesCard";
import InstantReplyCard from "./InstantReplyCard";
import { ConnectTwitter } from "../common";
import { useTwitterAuth } from "hooks";
import { TInstantReplies } from "adapters/Twitter/types";
import {
  InstantReplyGrid,
  TweetCardContainer,
} from "./InstantReplyCards.styles";

const BrandMonitoringCards = () => {
  const { replies, tweetActions } = useBrandMonitoring();
  const { isSignedIn } = useTwitterAuth();
  const [currentTweet, setCurrentTweet] = useState<TInstantReplies>(replies[0]);

  if (!tweetActions && !isSignedIn) {
    return <ConnectTwitter connect />;
  }

  return (
    <InstantReplyGrid>
      <TweetCardContainer>
        {replies.length === 0 && (
          <Text
            style={{ alignSelf: "center" }}
            variant="alpha"
            content="No tweets found that mentions you."
          />
        )}
        {replies.map((tweet) => (
          <InstantReplyCard
            key={tweet.tweetId}
            tweet={tweet}
            setCurrentTweet={setCurrentTweet}
          />
        ))}
      </TweetCardContainer>
      {currentTweet && (
        <InstantRepliesCard
          currentTweet={currentTweet}
          setCurrentTweet={setCurrentTweet}
        />
      )}
    </InstantReplyGrid>
  );
};

export default BrandMonitoringCards;
