const scheduleRetweet = [
  "Collecting scheduled tweets, criteria and influencers list",
  "Get ready to schedule new retweets",
];

export const scheduleRetweetBtnMsg = [
  "Bravo! Expert says schedule minimum 3 retweet per day.",
];

export default scheduleRetweet;
