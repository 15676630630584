import { createContext, useState } from "react";
import { getMonth, getYear, set, getDate } from "date-fns";
import { useCalendarDays, useCalendarMonths, useCalendarWeeks } from "hooks";
import { TCalendarView, TCalendarViewMap } from "./types";
import { calendarViewsMap } from "config/common";

type TEventCalendarContext = {
  view: TCalendarView;
  dates: Date[];
  startDate: Date;
  endDate: Date;
  changeView: (newView: TCalendarView) => void;
  currentMonth: Date;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
  goToNextWeek: () => void;
  goToPreviousWeek: () => void;
  goToNextDay: () => void;
  goToPreviousDay: () => void;
  getOffsetDatetime: (payloadDate: Date, hour: number) => Date;
  viewMap: TCalendarViewMap;
};

export const EventCalendarContext = createContext<TEventCalendarContext | null>(
  null
);

const EventCalendarProvider = (props: React.PropsWithChildren<{}>) => {
  const monthView = useCalendarMonths();
  const weekView = useCalendarWeeks();
  const dayView = useCalendarDays();
  const [view, setView] = useState<TCalendarView>(calendarViewsMap.month);
  const [dates, setDates] = useState<Date[]>(
    monthView.getCurrentMonthViewDates()
  );

  const goToPreviousMonth = () => {
    setDates((current) => monthView.getPreviousMonthDates(current));
  };

  const goToNextMonth = () => {
    setDates((current) => monthView.getNextMonthDates(current));
  };

  const goToNextWeek = () => {
    setDates((current) => weekView.getNextWeekDates(current));
  };

  const goToPreviousWeek = () => {
    setDates((current) => weekView.getPreviousWeekDates(current));
  };

  const goToNextDay = () => {
    setDates((current) => dayView.getNextDayDate(current));
  };

  const goToPreviousDay = () => {
    setDates((current) => dayView.getPreviousDayDate(current));
  };

  const changeView = (newView: TCalendarView) => {
    setView(newView);
    switch (newView) {
      case calendarViewsMap.month:
        setDates((current) => monthView.getViewDates(current));
        break;

      case calendarViewsMap.week:
        setDates((current) => weekView.getViewDates(current));
        break;

      case calendarViewsMap.day:
        setDates(() => dayView.getViewDate());
        break;

      default:
        break;
    }
  };

  const getOffsetDatetime = (datetime: Date, hour: number) => {
    const result = set(new Date(), {
      date: getDate(datetime),
      year: getYear(datetime),
      month: getMonth(datetime),
      hours: hour,
      minutes: 0,
      seconds: 0,
    });
    return result;
  };

  const currentMonth = monthView.getCurrentMonth(dates);
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];

  return (
    <EventCalendarContext.Provider
      value={{
        viewMap: calendarViewsMap,
        view,
        dates,
        getOffsetDatetime,
        goToPreviousMonth,
        goToNextMonth,
        changeView,
        currentMonth,
        startDate,
        endDate,
        goToNextWeek,
        goToPreviousWeek,
        goToNextDay,
        goToPreviousDay,
      }}
    >
      {props.children}
    </EventCalendarContext.Provider>
  );
};

export default EventCalendarProvider;
