export type TFileAPIPayload = {
  files: File[];
};
export type TFileAPIResponse = {
  attachmentId: string;
  user: string;
  fileUrl: string;
}[];

export type TFileObject = {
  attachment_id: string;
  user: string;
  file_url: string;
};

class FileAdapter {
  getPostPayload = (values: TFileAPIPayload) => {
    const formData = new FormData();

    values.files.forEach((file) => formData.append("files", file));
    return formData;
  };

  parsePostResponse = (data: TFileAPIResponse) => {
    return data.map((file) => ({
      attachment_id: file.attachmentId,
      user: file.user,
      file_url: file.fileUrl,
    })) as TFileObject[];
  };

  parsePostValues = (data: TFileObject[]) => {
    return data.map((file) => ({
      attachment_id: file.attachment_id,
      user: file.user,
      file_url: file.file_url,
    }));
  };
}

export default FileAdapter;
