import { Icon } from "components/core";
import ArrowContainer from "./ArrowContainer";
import { TForwardBackwardStyles } from "./types";

interface IForwardProps extends TForwardBackwardStyles {
  onClick?: () => void;
}

const Forward = ({
  variant,
  disabled,
  onClick,
  round,
  ...props
}: React.PropsWithChildren<IForwardProps>) => {
  if (round) {
    return (
      <ArrowContainer
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        variant={disabled ? "lightGrey" : variant}
      >
        <Icon name="caret left" style={{ cursor: "pointer" }} {...props} />
      </ArrowContainer>
    );
  }
  return (
    <Icon
      name="caret left"
      style={{ cursor: "pointer" }}
      variant={disabled ? "lightGrey" : variant}
      onClick={disabled ? undefined : onClick}
      {...props}
    />
  );
};

export default Forward;
