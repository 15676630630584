import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const DatetimeContainer = styled.div`
  display: flex;
  gap: ${helper.getSpacing("small")};
  &&& .picker div {
    height: unset;
  }
`;
export { DatetimeContainer };
