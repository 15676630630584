import SimpleRoute, { ISimpleRouteProps } from "controllers/common/SimpleRoute";

export interface IProductDemandRouteProps extends ISimpleRouteProps {}

const ProductDemandRoute = ({
  children,
  ...props
}: IProductDemandRouteProps) => {
  return <SimpleRoute {...props}>{children}</SimpleRoute>;
};

export default ProductDemandRoute;
