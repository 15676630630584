import { Form, IconButton } from "components/core";
import { Formik } from "formik";
import { IMultiKeywordSearchBarValues } from "./types";
import { Container } from "./MultiKeywordSearchBar.styles";
import { useGeneralPreferences, useKeywordsDetails } from "hooks/Profile";
import { multiKeywordsSearchValidation } from "config/Twitter";
import { SearchIcon } from "./icons";

interface IMultiKeywordSearchBarProps {
  onSubmit: (data: IMultiKeywordSearchBarValues) => void;
}

const MultiKeywordSearchBar = (props: IMultiKeywordSearchBarProps) => {
  const keywordsDetails = useKeywordsDetails();
  const generalPreferences = useGeneralPreferences();
  const handleFormikSubmit = (values: IMultiKeywordSearchBarValues) =>
    props.onSubmit(values);

  const initialValues = {
    keywords: [
      keywordsDetails.details.primaryKeyword,
      ...keywordsDetails.details.secondaryKeywords,
    ],
    language: generalPreferences.details.language,
    offset: {},
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={multiKeywordsSearchValidation}
      onSubmit={handleFormikSubmit}
    >
      {(formik) => (
        <Form.Form padding="none" onSubmit={formik.handleSubmit}>
          <Container>
            <Form.Field>
              <Form.MultiSelectDropdownField
                name="keywords"
                filter
                style={{
                  borderLeft: "unset",
                  borderRight: "unset",
                  borderTop: "unset",
                  marginBottom: "unset",
                }}
              />
            </Form.Field>
            <Form.Field>
              <IconButton
                icon={SearchIcon}
                variant="transparent"
                size="small"
                type="submit"
              />
            </Form.Field>
          </Container>
        </Form.Form>
      )}
    </Formik>
  );
};

export default MultiKeywordSearchBar;
