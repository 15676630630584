import { useField } from "formik";
import { Header } from "components/core";
import { Textbox, EmojiPicker } from "components/common";
import { IScheduleCreateFormValues } from "./types";
import { useThemeHelper } from "hooks";

interface IPostTextProps {
  name: string;
  wordLimit: number;
}

const PostText = (props: React.PropsWithChildren<IPostTextProps>) => {
  const [field, , helper] = useField<IScheduleCreateFormValues["text"]>(
    props.name
  );
  const theme = useThemeHelper();

  const handleEmojiSelect = (emoji: string) => {
    if (field.value.length < props.wordLimit - 1) {
      /* This extra check is required as concating emoji bypasses the native maxLength attribute validation. Each emoji uses 2 characters. */
      helper.setValue(field.value.concat(emoji));
    }
  };

  return (
    <>
      <Header
        as="div"
        font="poppins"
        size="small"
        mb="small"
        content="Type Text Here"
      />
      <Textbox
        style={{ marginBottom: theme.helper.getSpacing("small") }}
        maxLength={props.wordLimit}
        {...field}
      >
        <Textbox.WordLimit remaining={props.wordLimit - field.value.length} />
        <Textbox.Toolbar>
          <EmojiPicker onSelect={handleEmojiSelect} />
          {props.children}
        </Textbox.Toolbar>
      </Textbox>
    </>
  );
};

export default PostText;
