import TwitterAccountCard from "./TwitterAccountCard";
import InstagramAccountCard from "./InstagramAccountCard";
import FacebookAccountCard from "./FacebookAccountCard";
import LinkedinAccountCard from "./LinkedinAccountCard";

const ChangeAccountCards = () => {
  return (
    <>
      <TwitterAccountCard />
      <LinkedinAccountCard />
      <FacebookAccountCard />
      <InstagramAccountCard />
    </>
  );
};

export default ChangeAccountCards;
