import { dolphynAPI, dolphynPublicAPI } from "api/instances";
import { links, constants } from "config/Pricing";
import { PricingAdapter } from "adapters/Pricing";
import { APIError } from "errors";
import { TPricingAPIOptions } from "./types";

const URL = links.BASE_PRODUCT_URL.append("/pricing");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new PricingAdapter();

const pricing = {
  get: async (options?: TPricingAPIOptions) => {
    try {
      const { isPublic = false } = options || {};
      if (isPublic) {
        const { data } = await dolphynPublicAPI(URL);

        return adapter.parseGetResponse(data);
      }
      const { data } = await dolphynAPI(URL);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default pricing;
