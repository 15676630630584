import styled from "styled-components";
import { DateTimePicker } from "components/common";
import { Dropdown } from "components/core";
import { TPostFilterFormValues } from "../types";
import { useField } from "formik";

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.5rem;
`;

interface IPostDatetimeFilterProps {
  name: string;
}

const PostDatetimeFilter = ({ name }: IPostDatetimeFilterProps) => {
  const [field, , helpers] = useField<TPostFilterFormValues["dateRange"]>(name);
  const { startDate, endDate } = field.value;

  return (
    <Dropdown text="Date range" floating>
      <Dropdown.Menu>
        <Dropdown.Header content="Start date" />
        <Dropdown.Divider />
        <Content>
          <DateTimePicker.DatePicker
            style={{ maxWidth: "200px" }}
            value={startDate ?? new Date()}
            onChange={(value) =>
              helpers.setValue({
                startDate: value,
                endDate: endDate,
              })
            }
          />
          <DateTimePicker.TimePicker
            style={{ maxWidth: "200px" }}
            value={startDate ?? new Date()}
            onChange={(value) =>
              helpers.setValue({
                startDate: value,
                endDate: endDate,
              })
            }
          />
        </Content>
        <Dropdown.Header content="End date" />
        <Dropdown.Divider />
        <Content>
          <DateTimePicker.DatePicker
            style={{ maxWidth: "200px" }}
            value={endDate ?? new Date()}
            onChange={(value) =>
              helpers.setValue({
                startDate: startDate,
                endDate: value,
              })
            }
          />
          <DateTimePicker.TimePicker
            style={{ maxWidth: "200px" }}
            value={endDate ?? new Date()}
            onChange={(value) =>
              helpers.setValue({
                startDate: startDate,
                endDate: value,
              })
            }
          />
        </Content>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PostDatetimeFilter;
