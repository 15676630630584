import { TwitterService } from "services/Auth";
import { IInfluencerPayload, IInfluencers, TInfluencer } from "./types";

type TAPIResponse = {
  influencers: TInfluencer[];
  followersLimit: number;
  isTwitterConnected: boolean;
  tweetActions: boolean;
  message: string;
};

type TAPIPayload = {
  followers_limit?: number;
  filter_keywords?: string;
  location?: string;
  bio_includes?: string;
  bio_excludes?: string;
};

class InfluencersAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IInfluencers => {
    const influencersTweet = apiResponse.influencers.map((tweet) => ({
      name: tweet.name,
      leadUsername: tweet.leadUsername,
      twitterUsername: tweet.twitterUsername,
      bio: tweet.bio,
      location: tweet.location,
      website: tweet.website,
      influencer: tweet.influencer,
      followers: tweet.followers,
      followings: tweet.followings,
      tweets: tweet.tweets,
      keyword: tweet.keyword,
      profilePicUrl: tweet.profilePicUrl,
      joinedSince: tweet.joinedSince,
      potentialCustomer: tweet.potentialCustomer,
      tweetsLiked: tweet.tweetsLiked,
      tweetsReplied: tweet.tweetsReplied,
      followingSince: tweet.followingSince,
      createdAt: tweet.createdAt,
      follow: tweet.follow,
      twitterProfileUrl: tweet.twitterProfileUrl,
      frequentKeywords: [],
    }));
    return {
      influencers: influencersTweet,
      followersLimit: apiResponse.followersLimit,
      isTwitterConnected: apiResponse.isTwitterConnected,
      tweetActions: apiResponse.tweetActions,
      message: apiResponse.message,
    };
  };

  getGetPayload = (data: IInfluencerPayload) => {
    const payload: TAPIPayload = {
      followers_limit: data.followersLimit,
      bio_includes: data.bioIncludes?.filter((word) => word !== "").join(),
      bio_excludes: data.bioExcludes?.filter((word) => word !== "").join(),
      filter_keywords: data.filterKeywords
        ?.filter((word) => word !== "")
        .join(),
      location: data.location?.filter((word) => word !== "").join(),
    };
    return payload;
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default InfluencersAdapter;
