import { useField } from "formik";
import { Form, Input, Field, FormError } from "components/core";
import { useKeywordInputArray } from "hooks/common";
import { range } from "lodash";

const SECONDARY_KEYWORD_ARRAY_SIZE = 9;

interface IKeywordsDetailsFormProps {
  editable: boolean;
  formId: string;
  name: {
    primary: string;
    secondary: string;
  };
  onSubmit: () => void;
}

const KeywordsDetailsForm = ({
  editable,
  formId,
  name,
  onSubmit,
}: IKeywordsDetailsFormProps) => {
  const [secondaryField, secondaryMeta, secondaryHelpers] = useField<string[]>(
    name.secondary
  );
  const [, , keywordsArrayHelper] = useKeywordInputArray(
    SECONDARY_KEYWORD_ARRAY_SIZE
  );

  const renderInputArray = () => {
    return range(0, SECONDARY_KEYWORD_ARRAY_SIZE).map((inputIndex) => (
      <Field key={inputIndex}>
        <Input
          focused
          value={secondaryField.value[inputIndex]}
          onChange={(e) => {
            const newValues = keywordsArrayHelper.getChangedKeywordsArray(
              secondaryField.value,
              inputIndex,
              e.target.value
            );
            secondaryHelpers.setValue(newValues);
          }}
          readOnly={!editable}
          onFocus={() => secondaryHelpers.setTouched(true)}
        />
      </Field>
    ));
  };

  if (secondaryField.value.length !== SECONDARY_KEYWORD_ARRAY_SIZE) return null;

  return (
    <Form>
      <Form.Form id={formId} onSubmit={onSubmit}>
        <Form.Field>
          <Form.Label content="Business Keywords" />
          <Form.Input focused name={name.primary} readOnly={!editable} />
        </Form.Field>
        <Form.Field>
          {renderInputArray()}
          {secondaryMeta.touched && secondaryMeta.error && (
            <FormError>{secondaryMeta.error}</FormError>
          )}
        </Form.Field>
      </Form.Form>
    </Form>
  );
};

export default KeywordsDetailsForm;
