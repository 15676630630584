import { QuestionCard } from "../common";
import { Button } from "components/core";
import ContentContainer from "../common/ContentContainer";
import illustration from "assets/images/Profile/timezone-country-page-illustration.png";
import Timezone from "./Timezone";
import Language from "./Language";
import styled from "styled-components";
import Email from "./Email";
import { useAppSelector } from "redux/store/hooks";
import { useRegistrationSteps } from "hooks/Profile";

const Illustration = styled(ContentContainer.Illustration)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

interface ITimezoneLanguageForm {
  currentCountry: string;
  name: {
    timezone: string;
    language: string;
    email: string;
  };
}

const TimezoneLanguageForm = ({
  name,
  currentCountry,
}: ITimezoneLanguageForm) => {
  const email = useAppSelector(
    (state) => state.profile.registrationStatus.email
  );

  const steps = useRegistrationSteps();

  const renderNextStep = () => {
    return (
      <Button
        variant="alpha"
        type="button"
        content="Next Step"
        onClick={() => steps.handleGoToNextStep()}
      />
    );
  };

  return (
    <ContentContainer>
      <QuestionCard>
        <QuestionCard.Content>
          {!email && <Email name={name.email} />}
          <Timezone currentCountry={currentCountry} name={name.timezone} />
          <Language name={name.language} />
          {renderNextStep()}
        </QuestionCard.Content>
      </QuestionCard>
      <Illustration
        src={illustration}
        alt="product-illustration"
        style={{
          position: "absolute",
          bottom: "0",
          left: "5%",
          width: "250px",
          height: "250px",
        }}
      />
    </ContentContainer>
  );
};

export default TimezoneLanguageForm;
