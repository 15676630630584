import { Formik } from "formik";
import { useBoolean } from "hooks/common";
import { usePersonalDetails } from "hooks/Profile";
import ProfileSection from "./ProfileSection";
import PersonalDetailsForm from "./PersonalDetailsForm";
import { personalDetailsValidation } from "config/Profile";

const FORM_ID = "PersonalDetails";

const PersonalDetails = () => {
  const [editable, setEditableTrue, setEditableFalse] = useBoolean();
  const personalDetails = usePersonalDetails();

  return (
    <ProfileSection>
      <Formik
        enableReinitialize
        initialValues={personalDetails.details}
        validationSchema={personalDetailsValidation}
        onSubmit={(values) => {
          personalDetails.handleUpdate(values);
          setEditableFalse();
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <ProfileSection.Actions
              editable={editable}
              onCancel={() => {
                resetForm();
                setEditableFalse();
              }}
              onEdit={setEditableTrue}
              formId={FORM_ID}
            />
            <ProfileSection.Content>
              <PersonalDetailsForm
                editable={editable}
                formId={FORM_ID}
                onSubmit={handleSubmit}
              />
            </ProfileSection.Content>
          </>
        )}
      </Formik>
    </ProfileSection>
  );
};

export default PersonalDetails;
