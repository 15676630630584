import { periodTypes } from "./trackPerformanceMenu.config";

export const GOOGLE_ANALYTICS_ACCOUNTS_DATA_LOCALSTORAGE = "_ga_dolphyn_acc";
export const GOOGLE_ANALYTICS_SELECTED_ACCOUNT_LOCALSTORAGE =
  "_ga_selected_account";

export const GOOGLE_SEARCH_CONSOLE_SITES_DATA_LOCALSTORAGE =
  "_gsc_dolphhyn_sites";
export const GOOGLE_SEARCH_CONSOLE_SELECTED_SITE_LOCALSTORAGE =
  "_gsc_selected_site";

export const DEFAULT_TRACK_PERFORMANCE_MENU_PERIOD = 5;
export const DEFAULT_TRACK_PERFORMANCE_MENU_PERIOD_TYPE = periodTypes.month;
export const TRACK_PERFORMANCE_SELECTED_DATA_LOCALSTORAGE =
  "_wa_tp_selected_data";
