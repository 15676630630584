import {
  TTrendingKeywordTableEntry,
  TRecommendation,
  TKeywordPopularityChartEntry,
  IKeywordsComparisonData,
  TKeywordComparisonPayload,
} from "./types";

type TAPIResponse = {
  trending_keywords: {
    youtube: TTrendingKeywordTableEntry[];
    google: TTrendingKeywordTableEntry[];
    twitter: TTrendingKeywordTableEntry[];
  };
  popularity_chart: TKeywordPopularityChartEntry[];
  recommendations: TRecommendation[];
};

type TAPIPayload = {
  country: string;
  keyword: string;
};

class KeywordComparisonAdapter {
  private parseResponse = (
    apiResponse: TAPIResponse
  ): IKeywordsComparisonData => {
    return {
      popularityChart: apiResponse.popularity_chart,
      trendingKeywordsTable: apiResponse.trending_keywords,
      recommendation: apiResponse.recommendations,
    };
  };

  getGetPayload = (data: TKeywordComparisonPayload): TAPIPayload => {
    return {
      country: data.country,
      keyword: data.keywords.filter((word) => word !== "").join(),
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default KeywordComparisonAdapter;
