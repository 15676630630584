import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { setShowRecommendation } from "redux/slices/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import { IconContainer, InfoIcon } from "./ModalIcon.styles";

const OpenRecommendation = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onOpen = () => {
    dispatch(setShowRecommendation(true));
  };

  return (
    <IconContainer onClick={onOpen}>
      <InfoIcon data-tip="See Recommendations!" />
    </IconContainer>
  );
};

export default OpenRecommendation;
