import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TInstantReplies } from "adapters/Twitter/types";

const initialState = {
  currentTweet: {} as TInstantReplies,
};

export const instantReply = createSlice({
  name: "instantReply",
  initialState,
  reducers: {
    setCurrentTweet: (state, action: PayloadAction<TInstantReplies>) => {
      return {
        ...state,
        currentTweet: action.payload,
      };
    },
  },
});

export const { setCurrentTweet } = instantReply.actions;

export default instantReply.reducer;
