import styled from "styled-components";

const Container = styled.div`
  padding: 15px;
  background: #fff;
  &:active {
    background: #eee;
  }
  display: grid;
  place-items: center;
  svg {
    width: 25px;
    height: 25px;
  }
`;

export { Container };
