import { singleKeywordSearchValidation } from "config/ProductDemand";
import { Formik } from "formik";
import { usePreferences } from "hooks/ProductDemand";
import { ISingleKeywordSearchFormikValues } from "./types";

interface ISingleKeywordSearchProvider {
  onSubmit: (values: ISingleKeywordSearchFormikValues) => void;
}

const SingleKeywordSearchProvider = (
  props: React.PropsWithChildren<ISingleKeywordSearchProvider>
) => {
  const preferences = usePreferences();

  const handleFormikSubmit = (values: ISingleKeywordSearchFormikValues) => {
    props.onSubmit(values);
    preferences.handleUpdatePreferences(values);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        keyword: preferences.data.keyword,
        startDate: preferences.data.startDate,
        endDate: preferences.data.endDate,
        country: preferences.data.currentCountry,
      }}
      onSubmit={handleFormikSubmit}
      validationSchema={singleKeywordSearchValidation}
    >
      {() => props.children}
    </Formik>
  );
};

export default SingleKeywordSearchProvider;
