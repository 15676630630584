import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { AnalyticsTweet } from "components/Twitter/Analytics";
import { AnalyticsProvider } from "context/Twitter";

const Analytics = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnalyticsProvider>
      <AnalyticsTweet />
    </AnalyticsProvider>
  );
};

export default Analytics;
