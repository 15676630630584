import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const BestPracticesGrid = styled.div`
  display: grid;
  row-gap: ${helper.getSpacing("small")};
  column-gap: ${helper.getSpacing("small")};
  grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
  grid-template-rows: auto;
`;

export { BestPracticesGrid };
