import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TPositionObject } from "theme/types";

const helper = new ThemeHelper();

type TContainerProps = {
  position: TPositionObject;
};
const Container = styled.div<TContainerProps>`
  position: absolute;
  z-index: 2;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  box-sizing: border-box;
  ${helper.getBoxShadow("extraLightGrey", { intensity: "medium" })};
  border-radius: 15px;
  ${({ position }) => helper.getPosition(position)}
`;

export default Container;
