import { limitsMap } from "config/SocialMediaPost/platformCharacterLimit";
interface IUsePlatformWordLimit {
  facebook: boolean;
  instagram: boolean;
  twitter: boolean;
  linkedin: boolean;
}

const usePlatformWordLimit = () => {
  const getLimit = (values: IUsePlatformWordLimit) => {
    let wordLimit: number = Math.max(...Object.values(limitsMap));
    Object.keys(limitsMap).forEach((item) => {
      const platform = item as keyof typeof limitsMap;
      if (platform in values && values[platform]) {
        wordLimit =
          wordLimit > limitsMap[platform] ? limitsMap[platform] : wordLimit;
      }
    });

    return wordLimit;
  };
  return { getLimit };
};

export default usePlatformWordLimit;
