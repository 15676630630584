import ThreadDelay from "./ThreadDelay";
import AutoRetweet from "./AutoRetweet";

const Options = () => {
  return (
    <>
      <ThreadDelay />
      <AutoRetweet />
    </>
  );
};

export default Options;
