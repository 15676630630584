import Menu from "./Menu";
import MonthView from "./MonthView";
import MonthTools from "./MonthTools";
import WeekView from "./WeekView";
import WeekTools from "./WeekTools";
import DayView from "./DayView";
import DayTools from "./DayTools";
import Event from "./Event";
import { Body } from "./Calendar.styles";
import { useEventCalendar } from "hooks/common";

type TId = number | string;
type TIcon = React.FC<React.SVGProps<SVGSVGElement>>;
export type TEventData = {
  id: TId;
  icon?: TIcon;
  text: string;
  datetime: Date;
  dayViewColId: TId;
  [prop: string]: any;
};
export type TDayViewCol = {
  dayViewColId: TId;
  icon: TIcon;
  [prop: string]: any;
};

export interface IBaseCalendarProps<
  T extends TEventData,
  U extends TDayViewCol
> {
  events: T[];
  renderEvents: (events: T[]) => React.ReactNode;
  dayViewCols: U[];
  timeGridOffset?: number;
}

const BaseCalendar = <T extends TEventData, U extends TDayViewCol>(
  props: IBaseCalendarProps<T, U>
) => {
  const eventCalendar = useEventCalendar();

  const renderView = () => {
    switch (eventCalendar.view) {
      case eventCalendar.viewMap.month:
        return (
          <>
            <MonthTools />
            <MonthView {...props} />
          </>
        );
      case eventCalendar.viewMap.week:
        return (
          <>
            <WeekTools />
            <WeekView {...props} />
          </>
        );
      case eventCalendar.viewMap.day:
        return (
          <>
            <DayTools />
            <DayView {...props} />
          </>
        );
      default:
        return null;
    }
  };

  return <>{renderView()}</>;
};

const Calendar = <T extends TEventData, U extends TDayViewCol>(
  props: React.PropsWithChildren<IBaseCalendarProps<T, U>>
) => {
  if (props.children) return <>{props.children}</>;
  return (
    <>
      <Menu />
      <Body>
        <BaseCalendar {...props} />
      </Body>
    </>
  );
};

Calendar.Menu = Menu;
Calendar.Body = Body;
Calendar.View = BaseCalendar;
Calendar.Event = Event;

export default Calendar;
