import { range } from "lodash";
import { Flex, ForwardBackward } from "components/core";
import { TStepsStyleProps } from "./types";
import Container from "./Container";
import Step from "./Step";
import Track from "./Track";

interface IStepsProps extends TStepsStyleProps {
  total: number;
  current: number;
  onNext?: () => void;
  onPrev?: () => void;
  disableNext?: boolean;
  disablePrev?: boolean;
}

const Steps = ({
  total,
  current,
  onNext,
  onPrev,
  disableNext,
  disablePrev,
  variant,
  activeVariant,
}: IStepsProps) => {
  const renderSteps = () => {
    return range(1, total + 1).map((step) => (
      <Step
        variant={variant}
        activeVariant={activeVariant}
        key={step}
        stepNumber={step}
        active={current >= step}
      />
    ));
  };

  const renderForward = () => {
    if (onNext)
      return (
        <ForwardBackward.Forward
          variant={variant}
          onClick={onNext}
          disabled={disableNext}
        />
      );
  };

  const renderBackward = () => {
    if (onPrev)
      return (
        <ForwardBackward.Backward
          variant={variant}
          onClick={onPrev}
          disabled={disablePrev}
        />
      );
  };

  return (
    <Flex gap="small">
      {renderBackward()}
      <Container>
        <Track filledRatio={(current - 1) / (total - 1)} />
        {renderSteps()}
      </Container>
      {renderForward()}
    </Flex>
  );
};

Steps.Forward = ForwardBackward.Forward;
Steps.Backward = ForwardBackward.Backward;

export default Steps;
