import { List } from "./Legend.styles";

interface ILegendList {
  data: {
    color: string;
    text: string;
  }[];
}

const Legend = ({ data }: ILegendList) => {
  return (
    <List>
      {data.map(({ color, text }, key) => (
        <li key={key}>
          <span style={{ background: color }}></span> {text}
        </li>
      ))}
    </List>
  );
};

export default Legend;
