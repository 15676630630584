import styled, { DefaultColors } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TMarginProps, TPaddingProps } from "theme/types";

const helper = new ThemeHelper();

interface IBoxProps extends TMarginProps, TPaddingProps {
  variant?: keyof DefaultColors;
  shadow?: boolean;
}

const Box = styled.div<IBoxProps>`
  background-color: ${({ theme, variant = "white" }) => theme.colors[variant]};
  border-radius: 5px;
  ${({ shadow = true }) =>
    shadow && helper.getBoxShadow("darkGrey", { intensity: "high" })}
  ${({ mt, mb, ms, me }) =>
    helper.getMargins({ top: mt, bottom: mb, left: ms, right: me })}
  ${({ pt, pb, ps, pe }) =>
    helper.getPaddings({ top: pt, bottom: pb, left: ps, right: pe })}
`;

export default Box;
