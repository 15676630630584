import { Container } from "./BrandLogo.styles";
import Icon from "./Icon";
import Text from "./Text";
import { IBrandLogoProps } from "./types";

const BrandLogo = (props: IBrandLogoProps) => {
  const {
    icon,
    text,
    variant,
    size,
    onIconClick,
    onTextClick,
    children,
    ...additionalProps
  } = props;
  const commonProps = { variant, size };

  if (children) return <Container>{children}</Container>;

  return (
    <Container {...additionalProps}>
      <Icon icon={icon} onClick={onIconClick} {...commonProps} />
      {text && <Text text={text} onClick={onTextClick} {...commonProps} />}
    </Container>
  );
};

BrandLogo.Icon = Icon;
BrandLogo.Text = Text;

export default BrandLogo;
