import styled from "styled-components";
import { Menu as BaseMenu } from "semantic-ui-react";
import { styles } from "../Common";

const Menu = styled(BaseMenu)`
  &&&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    margin: unset;
    ${styles.marginBottom}
    margin-bottom: ${styles.helper.getSpacing("extraLarge")};
    border-radius: unset;
    border: unset;
    box-shadow: unset;
    .item {
      font-family: ${({ theme }) => theme.fonts.montserrat};
      ${styles.helper.getFontSize("small")};
      &:hover {
        background-color: unset;
      }
      &.active {
        color: ${({ theme }) => theme.colors.alpha};
        background-color: unset;
        position: relative;
        ::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 2px;
          background-color: ${({ theme }) => theme.colors.alpha};
        }
      }
    }
    @media screen and (max-width: 768px) {
      &&& {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        .left.item,
        .right.menu {
          margin-left: unset !important;
          margin-right: unset !important;
        }
      }
    }
  }
`;

export { Menu };
