import React, { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { planDetails as api } from "api/Profile";
import { setPlanDetails } from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TPlanDetails } from "adapters/Profile/types";
import { plansMap } from "config/Profile/plansMap";

export const PlanDetailsContext = createContext({
  isLoading: false,
  fetch: () => {},
  details: {
    plan: plansMap.free,
    currency: "",
    expiry: null,
    paymentMethod: "",
    paymentDetails: [],
  } as TPlanDetails,
});

const PlanDetailsProvider = (props: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();
  const details = useAppSelector((state) => state.profile.planDetails);

  const { execute: fetch, isLoading } = useReactQuery(api.get, {
    onSuccess: (data) => {
      dispatch(setPlanDetails(data));
    },
  });

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  return (
    <PlanDetailsContext.Provider value={{ fetch, details, isLoading }}>
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </PlanDetailsContext.Provider>
  );
};

export default PlanDetailsProvider;
