import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

export const SelectInfo = styled.p`
  margin: ${helper.getSpacing("small")};
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.alpha};
  text-align: center;
`;
