import overviewMenuMap from "./overviewMenuMap";
import { ReactComponent as ProductDemandIcon } from "assets/icons/SideBar/product-demand-icon.svg";
import { ReactComponent as WebAnalyticsIcon } from "assets/icons/SideBar/web-analytics-icon.svg";
import { ReactComponent as SocialMediaPostIcon } from "assets/icons/SideBar/social-media-icon.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/SideBar/twitter-icon.svg";

const data = [
  {
    title: "Social Media Post Scheduler",
    icon: TwitterIcon,
    id: overviewMenuMap.socialMediaScheduler,
  },
  {
    title: "Lead Generation",
    icon: WebAnalyticsIcon,
    id: overviewMenuMap.leadGeneration,
  },
  {
    title: "Instant Reply",
    icon: SocialMediaPostIcon,
    id: overviewMenuMap.instantReply,
  },
  {
    title: "Engagement Booster",
    icon: ProductDemandIcon,
    id: overviewMenuMap.engagementBooster,
  },
];

export default data;
