import dolphynPublicRoot from "api/instances/dolphynPublicRoot";
import { BASIC_AUTH_HEADER_VALUE } from "config/common/constants";
import { format } from "date-fns";
import { throwApiError } from "errors";

const URL = "/api/trends/v1/public/forecast_graph_basic_auth";

const defaultError = "Unable to process the request!";

const headers = {
  Authorization: BASIC_AUTH_HEADER_VALUE,
};

export type TForecastGraph = {
  date: Date;
  scale: number;
};

export type IKeywordsForecastData = TForecastGraph[];

export interface ISearchKeywords {
  country: string;
  keyword: string;
}

const fetchKeywordsForecast = async ({ country, keyword }: ISearchKeywords) => {
  const params = {
    country,
    keyword,
  };

  try {
    const { data: forecastGraphData } = await dolphynPublicRoot(URL, {
      headers,
      params,
    });

    const forecastGraph = forecastGraphData.forecast_data
      .map(({ date, scale }: TForecastGraph) => ({
        date: format(new Date(date), "MMM yy"),
        scale,
      }))
      .slice(1, 20);

    return forecastGraph as IKeywordsForecastData;
  } catch (e) {
    throwApiError(e, defaultError);
  }
};

export default fetchKeywordsForecast;
