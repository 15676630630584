import {
  BannerWrapper,
  SearchLink,
  LeftIcons,
  RightIcons,
} from "./Banner.styles";
import { ReactComponent as RefreshIcon } from "assets/icons/Home/refresh.svg";
import { ReactComponent as CloseIcon } from "assets/icons/Home/close.svg";
import { ReactComponent as ExpandIcon } from "assets/icons/Home/expand.svg";
import { ReactComponent as MinimizeIcon } from "assets/icons/Home/minimize.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/Home/download.svg";
import { ReactComponent as NewTabIcon } from "assets/icons/Home/new-tab.svg";

type TBannerProps = {
  url: string;
};

const Banner = ({ url }: TBannerProps) => {
  return (
    <BannerWrapper>
      <LeftIcons>
        <CloseIcon />
        <ExpandIcon />
        <MinimizeIcon />
      </LeftIcons>
      <SearchLink>
        <span>{url}</span>
        <RefreshIcon />
      </SearchLink>
      <RightIcons>
        <DownloadIcon />
        <NewTabIcon />
      </RightIcons>
    </BannerWrapper>
  );
};

export default Banner;
