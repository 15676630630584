import Masonry from "react-masonry-css";
import { useAppSelector } from "redux/store/hooks";
import styled from "styled-components";
import ContentCard from "./ContentCard";
import ScheduleForMonth from "./ScheduleForMonth";

const Container = styled.section`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -0.5rem; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 0.5rem; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 0.5rem;
  }
`;

const GeneratedContent: React.FC = () => {
  const contents = useAppSelector(
    (state) => state.contentCreation.aiContent.contents
  );

  const scheduledPosts = useAppSelector(
    (state) => state.contentCreation.aiContent.scheduledPosts
  );

  return (
    <Container>
      {contents.length ? <ScheduleForMonth /> : null}
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {scheduledPosts.length
          ? scheduledPosts.map(({ message, postId, datetime }) => (
              <ContentCard key={postId} content={message} datetime={datetime} />
            ))
          : contents.map((content) => (
              <ContentCard key={content} content={content} />
            ))}
      </Masonry>
    </Container>
  );
};

export default GeneratedContent;
