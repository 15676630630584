import moment from "moment";
import { Fragment, useState } from "react";
import { EmojiPicker, FileAttacher, Linkify, Textbox } from "components/common";
import { Button, Flex, Modal, Text } from "components/core";
import { DateTimeHelper } from "helpers";
import { TwitterService } from "services/Auth";
import {
  StyledImage,
  Bottom,
  RemoveIcon,
  UpdateIcon,
  FileContainer,
  Image,
  FilesPreview,
  Line,
  ImageContainer,
  TweetText,
  User,
  Header,
  Content,
  Container,
} from "./ScheduleTweetThread.styles";
import { socialMediaCharactersLimit } from "config/common";
import ThreadDelay from "./ThreadDelay";
import SchedulePanel from "./SchedulePanel";
import { usePostScheduler, useFileUpload } from "hooks/Twitter";
import { useBoolean } from "hooks";
import { useAppDispatch } from "redux/store/hooks";
import { setDatetime } from "redux/slices/Twitter";

const twitterService = new TwitterService();
const datetimeHelper = new DateTimeHelper();

type TScheduleTweetThreadProps = {
  thread: any;
  showLine: boolean;
};

const ScheduleTweetThread = ({
  thread,
  showLine,
}: TScheduleTweetThreadProps) => {
  const fileUpload = useFileUpload();
  const { handleDeleteThread, handleUpdateThread } = usePostScheduler();
  const [isOpen, onOpen, onClose] = useBoolean();

  const dispatch = useAppDispatch();

  const [text, setText] = useState("");
  const [files, setFiles] = useState<File[]>([]);

  const selectedAccount = twitterService.getSelectedAccount();

  const onDeleteThreadTweet = () => {
    handleDeleteThread({
      postId: thread.post_id,
      sequenceNo: thread.sequence_no,
    });
  };

  const onUpdateThreadTweet = (datetime: Date) => {
    if (files.length) {
      return fileUpload.upload(files, {
        onSuccess: (data) => {
          handleUpdateThread({
            threadId: thread.post_id,
            sequenceNo: thread.sequence_no,
            datetime: datetime,
            message: text,
            files: data,
          });
          onClose();
          setFiles([]);
          setText("");
        },
      });
    }
    handleUpdateThread({
      threadId: thread.post_id,
      sequenceNo: thread.sequence_no,
      datetime: datetime,
      message: text,
      files: [],
    });
    onClose();
    setText("");
  };

  const renderPreview = (file: any) => {
    switch (file.file_type) {
      case "image":
        return (
          <FileContainer>
            <StyledImage
              src={file.file_url}
              alt={file.user}
              style={{ borderRadius: "8px" }}
            />
          </FileContainer>
        );
      case "video":
        return (
          <FileContainer>
            <video controls>
              <source src={file.file_url} type="video/mp4" />
            </video>
          </FileContainer>
        );
      default:
        return null;
    }
  };

  const renderFiles = () => {
    if (thread.files) {
      return (
        <FilesPreview>
          {thread.files.map((file: any) => (
            <Fragment key={file.file_url}>{renderPreview(file)}</Fragment>
          ))}
        </FilesPreview>
      );
    }
  };

  const renderUpdateThreadTweetForm = () => {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Textbox
          value={text}
          onChange={(e) => {
            if (e.target.value.length - socialMediaCharactersLimit.twitter) {
              setText(e.target.value);
            }
          }}
        >
          <Textbox.WordLimit
            remaining={socialMediaCharactersLimit.twitter - text.length}
          />
          <Textbox.Toolbar style={{ gap: "12px" }}>
            <EmojiPicker onSelect={(emoji) => setText(text + emoji)} />
            <FileAttacher onChange={(files) => setFiles(files)} />
          </Textbox.Toolbar>
        </Textbox>
        {thread.sequence_no === 0 && (
          <Flex gap="small" direction="column" mt="small">
            <ThreadDelay />
            <SchedulePanel
              onSubmit={(datetime) => {
                onUpdateThreadTweet(datetime);
              }}
              setPanelClose={onClose}
            />
          </Flex>
        )}
        {thread.sequence_no !== 0 && (
          <Button
            variant="alpha"
            content="Post"
            mt="small"
            onClick={() =>
              onUpdateThreadTweet(datetimeHelper.getUTCAsLocal(thread.datetime))
            }
          />
        )}
      </Modal>
    );
  };

  if (!selectedAccount.userName) return null;

  return (
    <Container>
      <ImageContainer>
        <Image
          src={selectedAccount.profileImageUrl}
          alt={selectedAccount.name}
        />
        {showLine && <Line />}
      </ImageContainer>
      <Content>
        {renderUpdateThreadTweetForm()}
        <Header>
          <User>
            <Text className="name" content={selectedAccount.name}></Text>
            <Text className="username">@{selectedAccount.userName}</Text>
          </User>
          <UpdateIcon
            onClick={() => {
              setText(thread.message);
              onOpen();
              dispatch(
                setDatetime(datetimeHelper.getUTCAsLocal(thread.datetime))
              );
            }}
          />
          <RemoveIcon onClick={onDeleteThreadTweet} />
        </Header>
        <TweetText>
          <Linkify>{thread.message}</Linkify>
        </TweetText>
        {thread.files && renderFiles()}
        <Bottom>
          <Text size="extraSmall">
            {moment(
              datetimeHelper.getUTCAsLocal(new Date(thread.datetime))
            ).format("MMM Do YYYY, h:mm:ss A")}
          </Text>
        </Bottom>
      </Content>
    </Container>
  );
};

export default ScheduleTweetThread;
