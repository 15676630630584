import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 180px;
  ${({ onClick }) => {
    if (onClick)
      return css`
        cursor: pointer;
      `;
  }}
`;

export { Container };
