import styled from "styled-components";
import { useBoolean, useOnClickOutside, useTwitterAuth } from "hooks";
import { setConnectTwitter } from "redux/slices/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import TweetCard from "./TweetCard";

import { ReactComponent as DotsIcon } from "assets/icons/SocialMediaPost/dots-three.svg";
import { AddFollowerIcon, DMIcon, RemoveIcon } from "./icons";

const Container = styled.div`
  position: relative;
  margin-left: auto;
  justify-self: flex-end;
  align-self: flex-start;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const MenuContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 0.75rem;
  box-shadow: 0 1px 1px 2px ${({ theme }) => theme.colors.extraLightGrey};
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
  white-space: nowrap;
`;

type TMenuIconProps = {
  influencer?: boolean;
  follow?: boolean;
  handleFollow: () => void;
  toggleDirectMessage: () => void;
  handleRemove: () => void;
};

const MenuIcon = ({
  influencer,
  handleFollow,
  handleRemove,
  toggleDirectMessage,
}: TMenuIconProps) => {
  const [open, setOpen, setClose] = useBoolean();
  const { isSignedIn } = useTwitterAuth();
  const dispatch = useAppDispatch();
  const menuRef = useOnClickOutside<HTMLDivElement>(setClose);

  const renderMenu = () => {
    if (open)
      return (
        <MenuContainer>
          <IconContainer>
            <AddFollowerIcon />
            <TweetCard.LinkButton
              content="Follow on Twitter"
              style={{ fontSize: "14px" }}
              onClick={() => {
                if (isSignedIn) {
                  handleFollow();
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
            />
          </IconContainer>
          <IconContainer>
            <DMIcon />
            <TweetCard.LinkButton
              content="Direct Message"
              style={{ fontSize: "14px" }}
              onClick={() => {
                if (isSignedIn) {
                  toggleDirectMessage();
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
            />
          </IconContainer>
          <IconContainer>
            <RemoveIcon />
            <TweetCard.LinkButton
              content="Remove It"
              style={{ fontSize: "14px" }}
              onClick={() => {
                if (isSignedIn) {
                  handleRemove();
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
            />
          </IconContainer>
        </MenuContainer>
      );
  };

  return (
    <Container onClick={setOpen} ref={menuRef}>
      <DotsIcon />
      {renderMenu()}
    </Container>
  );
};

export default MenuIcon;
