import { ILikeTweetPayload } from "api/Twitter/types";
import { TwitterService } from "services/Auth";
import { ITweetLikeData } from "./types";

type TAPIPayload = {
  like: boolean;
};

type TAPIResponse = {
  like: boolean;
};

class LikeTweetAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): ITweetLikeData => {
    return {
      isLiked: apiResponse.like,
    };
  };

  getPostUrl = (url: string, data: ILikeTweetPayload) =>
    `${url}/${data.tweetId}`;

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: ILikeTweetPayload): TAPIPayload => {
    return {
      like: !data.isLiked,
    };
  };

  parsePostResponse = this.parseResponse;
}

export default LikeTweetAdapter;
