import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const InputContainer = styled.div`
  height: 50px;
  padding: ${helper.getSpacing("extraSmall")} ${helper.getSpacing("small")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  input {
    cursor: pointer;
  }
  label {
    margin: unset;
  }
`;

export { InputContainer };
