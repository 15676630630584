// Wraps axios Error object and checks if Api returned error_message in response, if not adds the message.

import CreateError from "./createError";

const throwApiError = (e: unknown, message = "Something went wrong!") => {
  if (e && typeof e === "object") {
    const errorObject = e as { [prop: string]: any };
    if (
      errorObject?.response?.data?.error_message &&
      typeof errorObject?.response?.data?.error_message === "string"
    ) {
      const message = errorObject.response.data.error_message;
      throw new CreateError(message);
    }
  }
  throw new CreateError(message);
};

export default throwApiError;
