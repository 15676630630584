import React, { createContext } from "react";
import { TConversation, TRecentTweets } from "adapters/Twitter/types";
import { IRecentTweetsReplyFormValues } from "./types";
import {
  useReplyHelper,
  useRetweetTweet,
  useRecentTweetRedux,
  useLikeRecent,
  useLikeReply,
} from "hooks/Twitter";

type TRecentTweetsContext = {
  handleLike: (values: TRecentTweets) => void;
  handleReply: (values: IRecentTweetsReplyFormValues) => void;
  handleRetweet: (tweetId: string) => void;
  handleReplyLikes: (reply: TConversation, tweet: TRecentTweets) => void;
};

export const RecentTweetsContext = createContext<TRecentTweetsContext | null>(
  null
);

const RecentTweetsProvider = (props: React.PropsWithChildren<{}>) => {
  const likeRecent = useLikeRecent();
  const likeReply = useLikeReply();
  const replyHelper = useReplyHelper();
  const retweetTweet = useRetweetTweet();
  const recentTweetRedux = useRecentTweetRedux();

  const handleLike = (values: TRecentTweets) => {
    likeRecent.execute(values);
    const updatedTweet = {
      ...values,
      likeCount: values.likeStatus
        ? values.likeCount - 1
        : values.likeCount + 1,
      likeStatus: !values.likeStatus,
    };
    recentTweetRedux.replaceTweet(updatedTweet);
  };

  const handleReplyLikes = (reply: TConversation, tweet: TRecentTweets) => {
    likeReply.execute(reply, {
      onSuccess: (reply) => {
        if (reply) {
          const newTweet = {
            ...tweet,
            conversation: tweet.conversation.map((conversation) =>
              reply.tweetId === conversation.tweetId ? reply : conversation
            ),
          };
          recentTweetRedux.replaceTweet(newTweet);
        }
      },
    });
  };

  const handleRetweet = (tweetId: string) => retweetTweet.execute(tweetId);

  const handleReply = (values: IRecentTweetsReplyFormValues) =>
    replyHelper.handleReply(values);

  return (
    <RecentTweetsContext.Provider
      value={{ handleLike, handleReply, handleRetweet, handleReplyLikes }}
    >
      {props.children}
    </RecentTweetsContext.Provider>
  );
};

export default RecentTweetsProvider;
