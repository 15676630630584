import { AllPlansAdapter } from "adapters/Pricing";
import { dolphynAPI } from "api/instances";
import { links } from "config/Pricing";
import { APIError } from "errors";

const error = new APIError();
const URL = links.BASE_PRODUCT_URL.append("/plans");
const adapter = new AllPlansAdapter();

const allPlans = async () => {
  try {
    const { data } = await dolphynAPI(URL);
    return adapter.getResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default allPlans;
