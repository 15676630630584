import { useState } from "react";
import styled from "styled-components";
import { Portal } from "components/core";
import { Icon } from "semantic-ui-react";
import { profileNavItemsMap } from "config/Profile/profileNavItemsMap";
import { TProfileNavItem } from "./types";
import Nav from "./Nav";
import PersonalDetails from "./PersonalDetails";
import KeywordsDetails from "./KeywordsDetails";
import Notifications from "./Notifications";
import SecurityDetails from "./SecurityDetails";
import PaymentDetails from "./PaymentDetails";
import Enterprise from "./Enterprise";
import ThemeHelper from "theme/themeHelper";
import GenerateCoupon from "./GenerateCoupon";
import RedeemCoupon from "./RedeemCoupon";

const helper = new ThemeHelper();

const Container = styled.div`
  position: fixed;
  left: 3.5rem;
  bottom: 5px;
  height: 76vh;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: ${helper.getSpacing("medium")};
  padding-top: ${helper.getSpacing("large")};
  display: flex;
  gap: ${helper.getSpacing("medium")};
  border-radius: 0 15px 15px 0;
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
})`
  &&& {
    position: absolute;
    right: 10px;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

interface IProfileNavProps {
  open: boolean;
  onClose: () => void;
}

const ProfileSideBar = ({ open, onClose }: IProfileNavProps) => {
  const [activeItem, setActiveItem] = useState<TProfileNavItem>(
    profileNavItemsMap.personalDetails
  );

  const renderModule = () => {
    switch (activeItem) {
      case profileNavItemsMap.personalDetails:
        return <PersonalDetails />;
      case profileNavItemsMap.securityDetails:
        return <SecurityDetails />;
      case profileNavItemsMap.keywordsDetails:
        return <KeywordsDetails />;
      case profileNavItemsMap.paymentDetails:
        return <PaymentDetails />;
      case profileNavItemsMap.notifications:
        return <Notifications />;
      case profileNavItemsMap.inviteEnterprises:
        return <Enterprise />;
      case profileNavItemsMap.generateCoupon:
        return <GenerateCoupon />;
      case profileNavItemsMap.redeemCoupon:
        return <RedeemCoupon />;

      default:
        return null;
    }
  };

  if (!open) return null;

  return (
    <Portal>
      <Container>
        <CloseIcon onClick={onClose} />
        <Nav activeItem={activeItem} onChange={(item) => setActiveItem(item)} />
        {renderModule()}
      </Container>
    </Portal>
  );
};

export default ProfileSideBar;
