import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 4px 0 12px 0 ${({ theme }) => theme.colors.extraLightGrey};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 232px;
`;

const TopContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;

  svg {
    width: 40px;
    height: 40px;
  }
`;

const HamburgerIcon = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  position: absolute;
  top: 0.5rem;
  padding: 4px 8px;
  border-radius: 2px;
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.colors.extraLightGrey};
  cursor: pointer;
  svg {
    width: 40px;
    height: 40px;
  }
`;

export { Container, TopContainer, HamburgerIcon };
