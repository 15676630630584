import BaseLinkify from "linkify-react";
import "linkify-plugin-hashtag";
import "linkify-plugin-mention";

const Linkify = (props: React.PropsWithChildren<{}>) => {
  const options = {
    target: "_blank",
    formatHref: {
      mention: (href: string) => `https://twitter.com${href}`,
      hashtag: (href: string) =>
        `https://twitter.com/hashtag/${href.substring(1)}`,
    },
  };

  return <BaseLinkify options={options}>{props.children}</BaseLinkify>;
};

export default Linkify;
