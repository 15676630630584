import { IParaphrasingPayload } from "api/Twitter/types";

type TAPIResponse = {
  language: string;
  predictedLanguage: string;
  rewrite: string;
  similarity: number;
};

type TAPIPayload = {
  language: string;
  strength: number;
  text: string;
};

class Paraphrasing {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      phrasedMessage: apiResponse.rewrite,
    };
  };

  getPostPayload = (values: IParaphrasingPayload): TAPIPayload => {
    return {
      language: "en",
      strength: 3,
      text: values.text,
    };
  };

  getParseResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default Paraphrasing;
