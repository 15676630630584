import styled from "styled-components";

const Container = styled.div`
  width: min(1440px, 90%);
  margin: auto;
`;

const TopSection = styled.div`
  padding-top: 2rem;
`;

const SubContainer = styled.div`
  overflow: hidden;
`;

export { Container, TopSection, SubContainer };
