import { useField } from "formik";
import { MultiCheckDropdown } from "components/common";
import { useMultiCheckDropdown, useToggle } from "hooks/common";
import { TPostFilterFormValues } from "../types";
import { TPlatform } from "config/SocialMediaPost/platforms";

type TPlatformFilterPlatform = {
  id: number;
  title: string;
  value: TPlatform;
};

const PLATFORMS: TPlatformFilterPlatform[] = [
  { id: 1, title: "Facebook", value: "facebook" },
  { id: 2, title: "Instagram", value: "instagram" },
  { id: 3, title: "Twitter", value: "twitter" },
  { id: 4, title: "Linkedin", value: "linkedin" },
];

interface IPlatformFilterProps {
  name: string;
}

const PlatformFilter = ({ name }: IPlatformFilterProps) => {
  const [, , helpers] = useField<TPostFilterFormValues["platforms"]>(name);

  const [open, handleOpen] = useToggle();
  const dropdown = useMultiCheckDropdown(PLATFORMS, {
    selectAllOnIntialize: true,
  });
  return (
    <MultiCheckDropdown
      placeholder="Platform"
      options={dropdown.options}
      open={open}
      handleToggle={handleOpen}
      value={dropdown.selection}
      onChange={(platform) => dropdown.handleSelectItem(platform.id)}
      onSubmit={(values) => {
        handleOpen();
        const platforms: TPlatform[] = values.map((platform) => platform.value);
        helpers.setValue(platforms);
      }}
    />
  );
};

export default PlatformFilter;
