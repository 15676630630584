import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoogleAnalyticsService } from "services/WebAnalytics";

export interface IGoogleAnalyticsSelectionState {
  account: string;
  view: string;
  property: string;
}

const webAnalyticsService = new GoogleAnalyticsService();

const initialState: IGoogleAnalyticsSelectionState =
  webAnalyticsService.getSelections();

export const googleAnalyticsSelection = createSlice({
  name: "googleAnalyticsSelection",
  initialState,
  reducers: {
    selectGoogleAnalyticsAccount: (state, action: PayloadAction<string>) => {
      webAnalyticsService.setSelections({
        ...state,
        account: action.payload,
      });
      state.account = action.payload;
    },
    selectGoogleAnalyticsProperty: (state, action: PayloadAction<string>) => {
      webAnalyticsService.setSelections({
        ...state,
        property: action.payload,
      });
      state.property = action.payload;
    },
    selectGoogleAnalyticsView: (state, action: PayloadAction<string>) => {
      webAnalyticsService.setSelections({
        ...state,
        view: action.payload,
      });
      state.view = action.payload;
    },
    clearGoogleAnalyticsSelections: () => ({
      account: "",
      view: "",
      property: "",
    }),
  },
});

export const {
  selectGoogleAnalyticsAccount,
  selectGoogleAnalyticsProperty,
  selectGoogleAnalyticsView,
  clearGoogleAnalyticsSelections,
} = googleAnalyticsSelection.actions;

export default googleAnalyticsSelection.reducer;
