import { throwApiError } from "errors";
import { dolphynPublicRoot } from "api/instances";
import { links, constants } from "config/Auth";
import { AuthService } from "services/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/token/");

export interface ILoginDolphyn {
  email: string;
  password: string;
}

const authService = new AuthService();

const loginDolphyn = async ({ email, password }: ILoginDolphyn) => {
  try {
    const { data: credentials } = await dolphynPublicRoot.post(URL, {
      email,
      password,
    });

    if (!("access" in credentials)) {
      throw new Error();
    }

    const { access, refresh } = credentials;

    authService.setCookies(access, refresh);

    return credentials;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default loginDolphyn;
