import { DateTimePicker } from "components/common";
import { IconButton } from "components/core";
import { isToday } from "date-fns";
import { useState } from "react";
import { useAppSelector } from "redux/store/hooks";
import { DatetimeContainer } from "./SchedulePanel.styles";

interface ISchedulePanel {
  onSubmit: (datetime: Date) => void;
  setPanelClose: () => void;
}

const SchedulePanel = ({ onSubmit, setPanelClose }: ISchedulePanel) => {
  const DATE = useAppSelector((state) => state.twitter.thread.datetime);
  const [datetime, setDatetime] = useState(DATE);

  return (
    <DatetimeContainer>
      <DateTimePicker.DatePicker
        className="picker"
        value={datetime}
        minDate={new Date()}
        onChange={(val) => setDatetime(val)}
        style={{ boxShadow: "unset", height: "40px" }}
      />
      <DateTimePicker.TimePicker
        className="picker"
        value={datetime}
        minTime={isToday(datetime) ? new Date() : undefined}
        onChange={(val) => setDatetime(val)}
        style={{ boxShadow: "unset", height: "40px" }}
      />
      <IconButton
        variant="alpha"
        invert
        name="check"
        onClick={() => {
          onSubmit(datetime);
          setPanelClose();
        }}
      />
    </DatetimeContainer>
  );
};

export default SchedulePanel;
