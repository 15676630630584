import React from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.scss";
import DatePickerHeader from "./DatePickerHeader";
import { setMonth } from "date-fns";
const today = new Date();

const CustomDatePicker = ({ variant, monthOptions, range = {}, ...rest }) => {
  const start = Boolean(range.start) ? range.start : today;
  const end = Boolean(range.end) ? range.end : setMonth(today, 11);

  return (
    <DatePicker
      renderCustomHeader={(props) => (
        <DatePickerHeader start={start} end={end} {...props} />
      )}
      className={classNames(variant, "datepicker")}
      timeClassName="time"
      minDate={start}
      maxDate={end}
      {...rest}
    />
  );
};

export default CustomDatePicker;
