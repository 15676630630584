import { userProfilesBaseCards } from "config/WebAnalytics";
import { GoogleAnalyticsService } from "services/WebAnalytics";
import {
  IUserProfileBaseSectionData,
  TUserProfileBaseTable,
  TUserProfilePayload,
} from "./types";

type TAPIResponse = {
  pageview: TUserProfileBaseTable;
  city: TUserProfileBaseTable;
  landing_page: TUserProfileBaseTable;
  exit_page: TUserProfileBaseTable;
  medium: TUserProfileBaseTable;
  source: TUserProfileBaseTable;
};

type TAPIGETPayload = {
  period: number;
  period_type: string;
  google_analytics_view: string;
};

class UserProfilesAdapter {
  private parseResponse = (
    apiData: TAPIResponse
  ): IUserProfileBaseSectionData => {
    const parsedData: IUserProfileBaseSectionData = {
      pageView: {
        title: userProfilesBaseCards.pageView.title,
        table: apiData.pageview,
      },
      city: {
        title: userProfilesBaseCards.city.title,
        table: apiData.city,
      },
      landingPage: {
        title: userProfilesBaseCards.landingPage.title,
        table: apiData.landing_page,
      },
      exitPage: {
        title: userProfilesBaseCards.exitPage.title,
        table: apiData.exit_page,
      },
      medium: {
        title: userProfilesBaseCards.medium.title,
        table: apiData.medium,
      },
      source: {
        title: userProfilesBaseCards.source.title,
        table: apiData.source,
      },
    };

    return parsedData;
  };

  parseGetResponse = (data: TAPIResponse): IUserProfileBaseSectionData => {
    return this.parseResponse(data);
  };

  getGetPayload = async ({ period, periodType, view }: TUserProfilePayload) => {
    const googleAnalyticsService = new GoogleAnalyticsService();
    const headers = await googleAnalyticsService.getHeadersOrError();
    const params: TAPIGETPayload = {
      period,
      period_type: periodType,
      google_analytics_view: view,
    };

    return {
      params,
      headers,
    };
  };
}

export default UserProfilesAdapter;
