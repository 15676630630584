import { useRef } from "react";

const useCheckboxToggleRef = (): [
  ref: React.RefObject<HTMLInputElement>,
  toggle: (value?: boolean) => void
] => {
  const ref = useRef<HTMLInputElement>(null);

  const toggle = (value?: boolean) => {
    if (value !== undefined && ref.current) {
      ref.current.checked = value;
      return;
    }
    if (ref.current?.checked === true) {
      ref.current.checked = false;
    } else if (ref.current?.checked === false) {
      ref.current.checked = true;
    }
  };

  return [ref, toggle];
};

export default useCheckboxToggleRef;
