import { ScheduleForMonthAdapter } from "adapters/ContentGeneration";
import { dolphynAPI } from "api/instances";
import { links } from "config/ContentGeneration";
import { APIError } from "errors";
import { IScheduleForMonthPayload } from "./types";

const error = new APIError();
const URL = links.scheduleBaseUrl.append("/schedule/for_month");
const adapter = new ScheduleForMonthAdapter();

const scheduleForMonth = async (values: IScheduleForMonthPayload) => {
  try {
    const headers = adapter.getConfig();
    const apiPayload = adapter.getPostPayload(values);
    const { data } = await dolphynAPI.post(URL, apiPayload, headers);
    return adapter.getResonse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default scheduleForMonth;
