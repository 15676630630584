import { socialMedia } from "config/SocialMediaPost";
import { AccountCard, Loader } from "components/common";
import { ReactComponent as FacebookIcon } from "assets/icons/SocialMediaPost/social-media-post-facebook-icon.svg";
import { useFacebookAuth } from "hooks/Auth";

const FacebookAccountCard = () => {
  const facebook = useFacebookAuth();

  return (
    <>
      <Loader textData={socialMedia} hidden={!facebook.isLoading} />
      <AccountCard>
        <AccountCard.Logo icon={<FacebookIcon />} />
        {facebook.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.name}
            image={account.profileImageUrl}
            onDelete={() => facebook.removeAccount(account.uid)}
            active={facebook.isAccountSelected(account.uid)}
            onClick={() => facebook.selectAccount(account.uid)}
          />
        ))}
      </AccountCard>
    </>
  );
};

export default FacebookAccountCard;
