import { ProfileSideBar } from "components/Profile";
import { useBoolean } from "hooks";
import { useAppSelector } from "redux/store/hooks";
import { NameUser, ProfileContainer, ProfileNames } from "./Profile.styles";
import ProfileIcon from "./ProfileIcon.styles";

const Profile = () => {
  const [profileMenuOpen, setProfileMenuOpen, setProfileMenuClose] =
    useBoolean();
  const firstName = useAppSelector(
    ({ profile }) => profile.personalDetails.firstName
  );
  const lastName = useAppSelector(
    ({ profile }) => profile.personalDetails.lastName
  );
  const username = useAppSelector(
    ({ profile }) => profile.personalDetails.username
  );

  return (
    <ProfileContainer>
      <ProfileIcon onClick={setProfileMenuOpen}>
        {firstName[0]}
        {lastName[0]}
      </ProfileIcon>
      <ProfileSideBar open={profileMenuOpen} onClose={setProfileMenuClose} />
      <ProfileNames onClick={setProfileMenuOpen}>
        <NameUser>
          {firstName} {lastName}
        </NameUser>
        <NameUser>{`@${username}`}</NameUser>
      </ProfileNames>
    </ProfileContainer>
  );
};

export default Profile;
