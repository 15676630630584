import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  gap: ${helper.getSpacing("extraSmall")};
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("extraSmall")}
  span {
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

export { Container };
