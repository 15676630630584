import { useField } from "formik";
import { Header } from "components/core";
import { FileDrop } from "components/common";
import { useThemeHelper } from "hooks";

interface IPostFileProps {
  name: string;
}

const PostFile = (props: IPostFileProps) => {
  const [field, , helpers] = useField<File[]>(props.name);
  const theme = useThemeHelper();

  return (
    <>
      <Header
        as="div"
        font="poppins"
        size="small"
        mb="small"
        content="Attach File"
      />
      <FileDrop
        value={field.value}
        onChange={(newFiles) => helpers.setValue(newFiles)}
        acceptedTypes="images-videos"
        onDelete={(newFiles) => helpers.setValue(newFiles)}
        style={{ marginBottom: theme.helper.getSpacing("small") }}
        config={{
          maxContent: 5,
        }}
      />
    </>
  );
};

export default PostFile;
