import { ModuleHeader } from "components/common";
import {
  Container,
  TopSection,
  Body,
  MenuBar,
} from "./SocialMediaLayout.styles";

const SocialMediaLayout = (props: React.PropsWithChildren<{}>) => {
  return <Container>{props.children}</Container>;
};

SocialMediaLayout.TopSection = TopSection;
SocialMediaLayout.Body = Body;
SocialMediaLayout.MenuBar = MenuBar;
SocialMediaLayout.ModuleHeader = ModuleHeader;

export default SocialMediaLayout;
