import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const TwitterAuditContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 6fr 4fr;
  grid-template-columns: 6fr 4fr;
  row-gap: ${helper.getSpacing("small")};
  column-gap: ${helper.getSpacing("small")};
  padding-bottom: ${helper.getSpacing("small")};
  ${helper.getStyledScrollbar()};

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const RecommendationContainer = styled.div`
  height: ${helper.getSpacing("medium")};
  #audit {
    ${helper.getStyledScrollbar()};
  }
`;

export { TwitterAuditContainer, RecommendationContainer };
