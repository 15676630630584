import { Container, Title, Actions, Footer } from "./Graph.styles";
import Legend from "./Legend";
import GraphInfo from "./GraphInfo";
import AxisInfo from "./AxisInfo";
import Sort from "./Sort";
import Body from "./Body";

interface IGraph {
  children?: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
  body?: React.ReactNode;
}

const Graph = ({ title, actions, body, children }: IGraph) => {
  if (children) return <Container>{children}</Container>;

  return (
    <Container>
      <Title>{title}</Title>
      <Actions>{actions}</Actions>
      <Body>{body}</Body>
    </Container>
  );
};

Graph.Title = Title;
Graph.Actions = Actions;
Graph.Body = Body;
Graph.Footer = Footer;
Graph.Legend = Legend;
Graph.GraphInfo = GraphInfo;
Graph.AxisInfo = AxisInfo;
Graph.Sort = Sort;

export default Graph;
