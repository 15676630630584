import { Icon, SemanticICONS } from "semantic-ui-react";
import { Container, TIconButtonContainerProps } from "./IconButton.styles";

interface IIconButtonProps
  extends TIconButtonContainerProps,
    React.ComponentPropsWithoutRef<"button"> {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name?: SemanticICONS;
}

const IconButton = ({ icon: PropIcon, name, ...props }: IIconButtonProps) => {
  if (PropIcon) {
    return <Container {...props}>{<PropIcon />}</Container>;
  }

  if (name) {
    return <Container {...props}>{<Icon name={name} />}</Container>;
  }

  return null;
};

export default IconButton;
