import { useEffect } from "react";
import { Grid, Body } from "./Activity.styles";
import { ActivityProvider, SchedulerProvider } from "context/SocialMediaPost";
import { usePendo, useSubHeading } from "hooks/common";
import { subHeadingMap } from "config/SocialMediaPost";
import { CardList, Menu } from "components/SocialMediaPost/Activity";

const Activity = () => {
  const [, setHeading] = useSubHeading();
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeading(subHeadingMap.activity);
  }, [setHeading]);

  return (
    <SchedulerProvider>
      <ActivityProvider>
        <Grid>
          <Menu />
          <Body>
            <CardList />
          </Body>
        </Grid>
      </ActivityProvider>
    </SchedulerProvider>
  );
};

export default Activity;
