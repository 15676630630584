const languagesListConfig = [
  {
    id: "aa",
    key: "aa",
    text: "Afar",
    value: "Afar",
  },
  {
    id: "ab",
    key: "ab",
    text: "Abkhazian",
    value: "Abkhazian",
  },
  {
    id: "ae",
    key: "ae",
    text: "Avestan",
    value: "Avestan",
  },
  {
    id: "af",
    key: "af",
    text: "Afrikaans",
    value: "Afrikaans",
  },
  {
    id: "ak",
    key: "ak",
    text: "Akan",
    value: "Akan",
  },
  {
    id: "am",
    key: "am",
    text: "Amharic",
    value: "Amharic",
  },
  {
    id: "an",
    key: "an",
    text: "Aragonese",
    value: "Aragonese",
  },
  {
    id: "ar",
    key: "ar",
    text: "Arabic",
    value: "Arabic",
  },
  {
    id: "as",
    key: "as",
    text: "Assamese",
    value: "Assamese",
  },
  {
    id: "av",
    key: "av",
    text: "Avaric",
    value: "Avaric",
  },
  {
    id: "ay",
    key: "ay",
    text: "Aymara",
    value: "Aymara",
  },
  {
    id: "az",
    key: "az",
    text: "Azerbaijani",
    value: "Azerbaijani",
  },
  {
    id: "ba",
    key: "ba",
    text: "Bashkir",
    value: "Bashkir",
  },
  {
    id: "be",
    key: "be",
    text: "Belarusian",
    value: "Belarusian",
  },
  {
    id: "bg",
    key: "bg",
    text: "Bulgarian",
    value: "Bulgarian",
  },
  {
    id: "bh",
    key: "bh",
    text: "Bihari languages",
    value: "Bihari languages",
  },
  {
    id: "bi",
    key: "bi",
    text: "Bislama",
    value: "Bislama",
  },
  {
    id: "bm",
    key: "bm",
    text: "Bambara",
    value: "Bambara",
  },
  {
    id: "bn",
    key: "bn",
    text: "Bengali",
    value: "Bengali",
  },
  {
    id: "bo",
    key: "bo",
    text: "Tibetan",
    value: "Tibetan",
  },
  {
    id: "br",
    key: "br",
    text: "Breton",
    value: "Breton",
  },
  {
    id: "bs",
    key: "bs",
    text: "Bosnian",
    value: "Bosnian",
  },
  {
    id: "ca",
    key: "ca",
    text: "Catalan",
    value: "Catalan; Valencian",
  },
  {
    id: "ce",
    key: "ce",
    text: "Chechen",
    value: "Chechen",
  },
  {
    id: "ch",
    key: "ch",
    text: "Chamorro",
    value: "Chamorro",
  },
  {
    id: "co",
    key: "co",
    text: "Corsican",
    value: "Corsican",
  },
  {
    id: "cr",
    key: "cr",
    text: "Cree",
    value: "Cree",
  },
  {
    id: "cs",
    key: "cs",
    text: "Czech",
    value: "Czech",
  },
  {
    id: "cu",
    key: "cu",
    text: "Church Slavic",
    value:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
  },
  {
    id: "cv",
    key: "cv",
    text: "Chuvash",
    value: "Chuvash",
  },
  {
    id: "cy",
    key: "cy",
    text: "Welsh",
    value: "Welsh",
  },
  {
    id: "da",
    key: "da",
    text: "Danish",
    value: "Danish",
  },
  {
    id: "de",
    key: "de",
    text: "German",
    value: "German",
  },
  {
    id: "dv",
    key: "dv",
    text: "Divehi",
    value: "Divehi; Dhivehi; Maldivian",
  },
  {
    id: "dz",
    key: "dz",
    text: "Dzongkha",
    value: "Dzongkha",
  },
  {
    id: "ee",
    key: "ee",
    text: "Ewe",
    value: "Ewe",
  },
  {
    id: "el",
    key: "el",
    text: "Greek, Modern (1453-)",
    value: "Greek, Modern (1453-)",
  },
  {
    id: "en",
    key: "en",
    text: "English",
    value: "English",
  },
  {
    id: "eo",
    key: "eo",
    text: "Esperanto",
    value: "Esperanto",
  },
  {
    id: "es",
    key: "es",
    text: "Spanish",
    value: "Spanish; Castilian",
  },
  {
    id: "et",
    key: "et",
    text: "Estonian",
    value: "Estonian",
  },
  {
    id: "eu",
    key: "eu",
    text: "Basque",
    value: "Basque",
  },
  {
    id: "fa",
    key: "fa",
    text: "Persian",
    value: "Persian",
  },
  {
    id: "ff",
    key: "ff",
    text: "Fulah",
    value: "Fulah",
  },
  {
    id: "fi",
    key: "fi",
    text: "Finnish",
    value: "Finnish",
  },
  {
    id: "fj",
    key: "fj",
    text: "Fijian",
    value: "Fijian",
  },
  {
    id: "fo",
    key: "fo",
    text: "Faroese",
    value: "Faroese",
  },
  {
    id: "fr",
    key: "fr",
    text: "French",
    value: "French",
  },
  {
    id: "fy",
    key: "fy",
    text: "Western Frisian",
    value: "Western Frisian",
  },
  {
    id: "ga",
    key: "ga",
    text: "Irish",
    value: "Irish",
  },
  {
    id: "gd",
    key: "gd",
    text: "Gaelic",
    value: "Gaelic; Scottish Gaelic",
  },
  {
    id: "gl",
    key: "gl",
    text: "Galician",
    value: "Galician",
  },
  {
    id: "gn",
    key: "gn",
    text: "Guarani",
    value: "Guarani",
  },
  {
    id: "gu",
    key: "gu",
    text: "Gujarati",
    value: "Gujarati",
  },
  {
    id: "gv",
    key: "gv",
    text: "Manx",
    value: "Manx",
  },
  {
    id: "ha",
    key: "ha",
    text: "Hausa",
    value: "Hausa",
  },
  {
    id: "he",
    key: "he",
    text: "Hebrew",
    value: "Hebrew",
  },
  {
    id: "hi",
    key: "hi",
    text: "Hindi",
    value: "Hindi",
  },
  {
    id: "ho",
    key: "ho",
    text: "Hiri Motu",
    value: "Hiri Motu",
  },
  {
    id: "hr",
    key: "hr",
    text: "Croatian",
    value: "Croatian",
  },
  {
    id: "ht",
    key: "ht",
    text: "Haitian",
    value: "Haitian; Haitian Creole",
  },
  {
    id: "hu",
    key: "hu",
    text: "Hungarian",
    value: "Hungarian",
  },
  {
    id: "hy",
    key: "hy",
    text: "Armenian",
    value: "Armenian",
  },
  {
    id: "hz",
    key: "hz",
    text: "Herero",
    value: "Herero",
  },
  {
    id: "ia",
    key: "ia",
    text: "Interlingua (International Auxiliary Language Association)",
    value: "Interlingua (International Auxiliary Language Association)",
  },
  {
    id: "id",
    key: "id",
    text: "Indonesian",
    value: "Indonesian",
  },
  {
    id: "ie",
    key: "ie",
    text: "Interlingue",
    value: "Interlingue; Occidental",
  },
  {
    id: "ig",
    key: "ig",
    text: "Igbo",
    value: "Igbo",
  },
  {
    id: "ii",
    key: "ii",
    text: "Sichuan Yi",
    value: "Sichuan Yi; Nuosu",
  },
  {
    id: "ik",
    key: "ik",
    text: "Inupiaq",
    value: "Inupiaq",
  },
  {
    id: "io",
    key: "io",
    text: "Ido",
    value: "Ido",
  },
  {
    id: "is",
    key: "is",
    text: "Icelandic",
    value: "Icelandic",
  },
  {
    id: "it",
    key: "it",
    text: "Italian",
    value: "Italian",
  },
  {
    id: "iu",
    key: "iu",
    text: "Inuktitut",
    value: "Inuktitut",
  },
  {
    id: "ja",
    key: "ja",
    text: "Japanese",
    value: "Japanese",
  },
  {
    id: "jv",
    key: "jv",
    text: "Javanese",
    value: "Javanese",
  },
  {
    id: "ka",
    key: "ka",
    text: "Georgian",
    value: "Georgian",
  },
  {
    id: "kg",
    key: "kg",
    text: "Kongo",
    value: "Kongo",
  },
  {
    id: "ki",
    key: "ki",
    text: "Kikuyu",
    value: "Kikuyu; Gikuyu",
  },
  {
    id: "kj",
    key: "kj",
    text: "Kuanyama",
    value: "Kuanyama; Kwanyama",
  },
  {
    id: "kk",
    key: "kk",
    text: "Kazakh",
    value: "Kazakh",
  },
  {
    id: "kl",
    key: "kl",
    text: "Kalaallisut",
    value: "Kalaallisut; Greenlandic",
  },
  {
    id: "km",
    key: "km",
    text: "Central Khmer",
    value: "Central Khmer",
  },
  {
    id: "kn",
    key: "kn",
    text: "Kannada",
    value: "Kannada",
  },
  {
    id: "ko",
    key: "ko",
    text: "Korean",
    value: "Korean",
  },
  {
    id: "kr",
    key: "kr",
    text: "Kanuri",
    value: "Kanuri",
  },
  {
    id: "ks",
    key: "ks",
    text: "Kashmiri",
    value: "Kashmiri",
  },
  {
    id: "ku",
    key: "ku",
    text: "Kurdish",
    value: "Kurdish",
  },
  {
    id: "kv",
    key: "kv",
    text: "Komi",
    value: "Komi",
  },
  {
    id: "kw",
    key: "kw",
    text: "Cornish",
    value: "Cornish",
  },
  {
    id: "ky",
    key: "ky",
    text: "Kirghiz",
    value: "Kirghiz; Kyrgyz",
  },
  {
    id: "la",
    key: "la",
    text: "Latin",
    value: "Latin",
  },
  {
    id: "lb",
    key: "lb",
    text: "Luxembourgish",
    value: "Luxembourgish; Letzeburgesch",
  },
  {
    id: "lg",
    key: "lg",
    text: "Ganda",
    value: "Ganda",
  },
  {
    id: "li",
    key: "li",
    text: "Limburgan",
    value: "Limburgan; Limburger; Limburgish",
  },
  {
    id: "ln",
    key: "ln",
    text: "Lingala",
    value: "Lingala",
  },
  {
    id: "lo",
    key: "lo",
    text: "Lao",
    value: "Lao",
  },
  {
    id: "lt",
    key: "lt",
    text: "Lithuanian",
    value: "Lithuanian",
  },
  {
    id: "lu",
    key: "lu",
    text: "Luba-Katanga",
    value: "Luba-Katanga",
  },
  {
    id: "lv",
    key: "lv",
    text: "Latvian",
    value: "Latvian",
  },
  {
    id: "mg",
    key: "mg",
    text: "Malagasy",
    value: "Malagasy",
  },
  {
    id: "mh",
    key: "mh",
    text: "Marshallese",
    value: "Marshallese",
  },
  {
    id: "mi",
    key: "mi",
    text: "Maori",
    value: "Maori",
  },
  {
    id: "mk",
    key: "mk",
    text: "Macedonian",
    value: "Macedonian",
  },
  {
    id: "ml",
    key: "ml",
    text: "Malayalam",
    value: "Malayalam",
  },
  {
    id: "mn",
    key: "mn",
    text: "Mongolian",
    value: "Mongolian",
  },
  {
    id: "mr",
    key: "mr",
    text: "Marathi",
    value: "Marathi",
  },
  {
    id: "ms",
    key: "ms",
    text: "Malay",
    value: "Malay",
  },
  {
    id: "mt",
    key: "mt",
    text: "Maltese",
    value: "Maltese",
  },
  {
    id: "my",
    key: "my",
    text: "Burmese",
    value: "Burmese",
  },
  {
    id: "na",
    key: "na",
    text: "Nauru",
    value: "Nauru",
  },
  {
    id: "nb",
    key: "nb",
    text: "Bokmål",
    value: "Bokmål, Norwegian; Norwegian Bokmål",
  },
  {
    id: "nd",
    key: "nd",
    text: "Ndebele",
    value: "Ndebele, North; North Ndebele",
  },
  {
    id: "ne",
    key: "ne",
    text: "Nepali",
    value: "Nepali",
  },
  {
    id: "ng",
    key: "ng",
    text: "Ndonga",
    value: "Ndonga",
  },
  {
    id: "nl",
    key: "nl",
    text: "Dutch",
    value: "Dutch; Flemish",
  },
  {
    id: "nn",
    key: "nn",
    text: "Norwegian Nynorsk",
    value: "Norwegian Nynorsk; Nynorsk, Norwegian",
  },
  {
    id: "no",
    key: "no",
    text: "Norwegian",
    value: "Norwegian",
  },
  {
    id: "nr",
    key: "nr",
    text: "Ndebele",
    value: "Ndebele, South; South Ndebele",
  },
  {
    id: "nv",
    key: "nv",
    text: "Navajo",
    value: "Navajo; Navaho",
  },
  {
    id: "ny",
    key: "ny",
    text: "Chichewa",
    value: "Chichewa; Chewa; Nyanja",
  },
  {
    id: "oc",
    key: "oc",
    text: "Occitan (post 1500)",
    value: "Occitan (post 1500)",
  },
  {
    id: "oj",
    key: "oj",
    text: "Ojibwa",
    value: "Ojibwa",
  },
  {
    id: "om",
    key: "om",
    text: "Oromo",
    value: "Oromo",
  },
  {
    id: "or",
    key: "or",
    text: "Oriya",
    value: "Oriya",
  },
  {
    id: "os",
    key: "os",
    text: "Ossetian",
    value: "Ossetian; Ossetic",
  },
  {
    id: "pa",
    key: "pa",
    text: "Panjabi",
    value: "Panjabi; Punjabi",
  },
  {
    id: "pi",
    key: "pi",
    text: "Pali",
    value: "Pali",
  },
  {
    id: "pl",
    key: "pl",
    text: "Polish",
    value: "Polish",
  },
  {
    id: "ps",
    key: "ps",
    text: "Pushto",
    value: "Pushto; Pashto",
  },
  {
    id: "pt",
    key: "pt",
    text: "Portuguese",
    value: "Portuguese",
  },
  {
    id: "qu",
    key: "qu",
    text: "Quechua",
    value: "Quechua",
  },
  {
    id: "rm",
    key: "rm",
    text: "Romansh",
    value: "Romansh",
  },
  {
    id: "rn",
    key: "rn",
    text: "Rundi",
    value: "Rundi",
  },
  {
    id: "ro",
    key: "ro",
    text: "Romanian",
    value: "Romanian; Moldavian; Moldovan",
  },
  {
    id: "ru",
    key: "ru",
    text: "Russian",
    value: "Russian",
  },
  {
    id: "rw",
    key: "rw",
    text: "Kinyarwanda",
    value: "Kinyarwanda",
  },
  {
    id: "sa",
    key: "sa",
    text: "Sanskrit",
    value: "Sanskrit",
  },
  {
    id: "sc",
    key: "sc",
    text: "Sardinian",
    value: "Sardinian",
  },
  {
    id: "sd",
    key: "sd",
    text: "Sindhi",
    value: "Sindhi",
  },
  {
    id: "se",
    key: "se",
    text: "Northern Sami",
    value: "Northern Sami",
  },
  {
    id: "sg",
    key: "sg",
    text: "Sango",
    value: "Sango",
  },
  {
    id: "si",
    key: "si",
    text: "Sinhala",
    value: "Sinhala; Sinhalese",
  },
  {
    id: "sk",
    key: "sk",
    text: "Slovak",
    value: "Slovak",
  },
  {
    id: "sl",
    key: "sl",
    text: "Slovenian",
    value: "Slovenian",
  },
  {
    id: "sm",
    key: "sm",
    text: "Samoan",
    value: "Samoan",
  },
  {
    id: "sn",
    key: "sn",
    text: "Shona",
    value: "Shona",
  },
  {
    id: "so",
    key: "so",
    text: "Somali",
    value: "Somali",
  },
  {
    id: "sq",
    key: "sq",
    text: "Albanian",
    value: "Albanian",
  },
  {
    id: "sr",
    key: "sr",
    text: "Serbian",
    value: "Serbian",
  },
  {
    id: "ss",
    key: "ss",
    text: "Swati",
    value: "Swati",
  },
  {
    id: "st",
    key: "st",
    text: "Sotho, Southern",
    value: "Sotho, Southern",
  },
  {
    id: "su",
    key: "su",
    text: "Sundanese",
    value: "Sundanese",
  },
  {
    id: "sv",
    key: "sv",
    text: "Swedish",
    value: "Swedish",
  },
  {
    id: "sw",
    key: "sw",
    text: "Swahili",
    value: "Swahili",
  },
  {
    id: "ta",
    key: "ta",
    text: "Tamil",
    value: "Tamil",
  },
  {
    id: "te",
    key: "te",
    text: "Telugu",
    value: "Telugu",
  },
  {
    id: "tg",
    key: "tg",
    text: "Tajik",
    value: "Tajik",
  },
  {
    id: "th",
    key: "th",
    text: "Thai",
    value: "Thai",
  },
  {
    id: "ti",
    key: "ti",
    text: "Tigrinya",
    value: "Tigrinya",
  },
  {
    id: "tk",
    key: "tk",
    text: "Turkmen",
    value: "Turkmen",
  },
  {
    id: "tl",
    key: "tl",
    text: "Tagalog",
    value: "Tagalog",
  },
  {
    id: "tn",
    key: "tn",
    text: "Tswana",
    value: "Tswana",
  },
  {
    id: "to",
    key: "to",
    text: "Tonga (Tonga Islands)",
    value: "Tonga (Tonga Islands)",
  },
  {
    id: "tr",
    key: "tr",
    text: "Turkish",
    value: "Turkish",
  },
  {
    id: "ts",
    key: "ts",
    text: "Tsonga",
    value: "Tsonga",
  },
  {
    id: "tt",
    key: "tt",
    text: "Tatar",
    value: "Tatar",
  },
  {
    id: "tw",
    key: "tw",
    text: "Twi",
    value: "Twi",
  },
  {
    id: "ty",
    key: "ty",
    text: "Tahitian",
    value: "Tahitian",
  },
  {
    id: "ug",
    key: "ug",
    text: "Uighur",
    value: "Uighur; Uyghur",
  },
  {
    id: "uk",
    key: "uk",
    text: "Ukrainian",
    value: "Ukrainian",
  },
  {
    id: "ur",
    key: "ur",
    text: "Urdu",
    value: "Urdu",
  },
  {
    id: "uz",
    key: "uz",
    text: "Uzbek",
    value: "Uzbek",
  },
  {
    id: "ve",
    key: "ve",
    text: "Venda",
    value: "Venda",
  },
  {
    id: "vi",
    key: "vi",
    text: "Vietnamese",
    value: "Vietnamese",
  },
  {
    id: "vo",
    key: "vo",
    text: "Volapük",
    value: "Volapük",
  },
  {
    id: "wa",
    key: "wa",
    text: "Walloon",
    value: "Walloon",
  },
  {
    id: "wo",
    key: "wo",
    text: "Wolof",
    value: "Wolof",
  },
  {
    id: "xh",
    key: "xh",
    text: "Xhosa",
    value: "Xhosa",
  },
  {
    id: "yi",
    key: "yi",
    text: "Yiddish",
    value: "Yiddish",
  },
  {
    id: "yo",
    key: "yo",
    text: "Yoruba",
    value: "Yoruba",
  },
  {
    id: "za",
    key: "za",
    text: "Zhuang",
    value: "Zhuang; Chuang",
  },
  {
    id: "zh",
    key: "zh",
    text: "Chinese",
    value: "Chinese",
  },
  {
    id: "zu",
    key: "zu",
    text: "Zulu",
    value: "Zulu",
  },
];

export { languagesListConfig };
