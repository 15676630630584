import { createContext, useEffect } from "react";
import { fetchAudit } from "api/Twitter";
import { IAuditData } from "adapters/Twitter/types";
import { Loader } from "components/common";
import { useRecommendation } from "hooks/Twitter";
import { audit } from "config/Twitter";
import { useReactQuery, useTwitterAuth } from "hooks";

type TAuditContext = {
  data: IAuditData;
};

export const AuditContext = createContext<TAuditContext | null>(null);

const initialData: IAuditData = {
  twitterAudit: [],
  bestPractices: [],
  actions: [],
  recommendation: [],
  tweetActions: false,
};

const AuditProvider = (props: React.PropsWithChildren<{}>) => {
  const recommendation = useRecommendation();
  const { isSignedIn, selectedAccount } = useTwitterAuth();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchAudit, {
    onSuccess: (data) => {
      if (data) {
        recommendation.setRecommendation(data.recommendation);
      }
    },
  });

  useEffect(() => {
    execute({ isAuthenticated: isSignedIn });
  }, [execute, isSignedIn, selectedAccount.userName]);

  return (
    <AuditContext.Provider value={{ data }}>
      <Loader textData={audit} hidden={!isLoading} />
      {props.children}
    </AuditContext.Provider>
  );
};

export default AuditProvider;
