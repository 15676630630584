enum periodTypes {
  month = "month",
  year = "year",
  day = "day",
  week = "week",
}

const maxPeriods: {
  [key in TPeriodType]: number;
} = {
  year: 5,
  month: 12,
  week: 26,
  day: 31,
};

export type TPeriodType = periodTypes;
// eslint-disable-next-line prettier/prettier
export type TPeriod = typeof maxPeriods[TPeriodType];

export { periodTypes, maxPeriods };
