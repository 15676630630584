import { createContext } from "react";
import { IScheduleCreateFormValues } from "./types";
import { useFileUpload, useNotification } from "hooks/common";
import {
  useFacebookPosts,
  useInstagramPosts,
  useLinkedinPosts,
  useTwitterPosts,
  useFacebookPages,
  useInstagramPages,
  useLinkedinPages,
  useTwitterAccounts,
} from "hooks/SocialMediaPost";
import { TScheduleCreatePayload, TServerFile } from "api/SocialMediaPost/types";
import { Spinner } from "components/common";

export const SchedulerContext = createContext({
  handleSchedule: (data: IScheduleCreateFormValues) => {},
  getAllPlatformsFormValues: (
    currentValues: IScheduleCreateFormValues
  ): IScheduleCreateFormValues => currentValues,
  getNoPlatformsFormValues: (
    currentValues: IScheduleCreateFormValues
  ): IScheduleCreateFormValues => currentValues,
  getAllPlatformsSelected: (
    currentValues: IScheduleCreateFormValues
  ): boolean => false,
  getInitialValues: (): IScheduleCreateFormValues => ({
    files: [],
    text: "",
    datetime: new Date(),
    facebook: [],
    linkedin: [],
    instagram: [],
    twitter: [],
  }),
});

const SchedulerProvider = (props: React.PropsWithChildren<{}>) => {
  const fileUploadHandler = useFileUpload();
  const facebook = useFacebookPosts();
  const instagram = useInstagramPosts();
  const linkedin = useLinkedinPosts();
  const twitter = useTwitterPosts();

  const facebookPages = useFacebookPages();
  const instagramPages = useInstagramPages();
  const linkedinPages = useLinkedinPages();
  const twitterAccounts = useTwitterAccounts();

  const notification = useNotification();

  const handleSchedule = (formValues: IScheduleCreateFormValues) => {
    const getCreatePayload = (
      formValues: IScheduleCreateFormValues,
      files: TServerFile[]
    ) => {
      const { text, facebook, twitter, linkedin, instagram, datetime } =
        formValues;
      const payload: TScheduleCreatePayload = {
        text,
        datetime,
        facebook: {
          uid: facebookPages.accountUid,
          isSelected: facebook.length > 0,
          pages: facebook.map((pages) => pages.id.toString()),
        },
        instagram: {
          uid: instagramPages.accountUid,
          isSelected: instagram.length > 0,
          pages: instagram.map((pages) => pages.id.toString()),
        },
        linkedin: {
          uid: linkedinPages.accountUid,
          isSelected: linkedin.length > 0,
          pages: linkedin.map((pages) => pages.id.toString()),
        },
        twitter: {
          uid: twitter.length > 0 ? twitterAccounts.accountUid : "",
          isSelected: twitter.length > 0,
        },
        serverFiles: files,
      };

      return payload;
    };

    const createSchedule = async (data: TScheduleCreatePayload) => {
      try {
        await facebook.create(data);
        await instagram.create(data);
        await linkedin.create(data);
        await twitter.create(data);
        notification.notifySuccess("Posts are scheduled!");
      } catch (error) {
        /* 
          Error already being handled by upper hooks
        */
      }
    };

    if (formValues.files.length) {
      fileUploadHandler.upload(formValues.files, {
        onSuccess: async (serverFiles) => {
          await createSchedule(getCreatePayload(formValues, serverFiles));
        },
      });
      return;
    }

    createSchedule(getCreatePayload(formValues, []));
  };

  const getAllPlatformsFormValues = (
    currentValues: IScheduleCreateFormValues
  ): IScheduleCreateFormValues => {
    return {
      ...currentValues,
      facebook: facebookPages.options,
      instagram: instagramPages.options,
      linkedin: linkedinPages.options,
      twitter: twitterAccounts.options,
    };
  };

  const getAllPlatformsSelected = (
    currentValues: IScheduleCreateFormValues
  ) => {
    const { facebook, instagram, twitter, linkedin } = currentValues;
    return (
      facebook.length === facebookPages.options.length &&
      instagram.length === instagramPages.options.length &&
      linkedin.length === linkedinPages.options.length &&
      twitter.length === twitterAccounts.options.length
    );
  };

  const getNoPlatformsFormValues = (
    currentValues: IScheduleCreateFormValues
  ): IScheduleCreateFormValues => {
    return {
      ...currentValues,
      facebook: [],
      instagram: [],
      linkedin: [],
      twitter: [],
    };
  };

  const getInitialValues = (): IScheduleCreateFormValues => ({
    files: [],
    text: "",
    datetime: new Date(),
    facebook: [],
    instagram: [],
    linkedin: [],
    twitter: [],
  });

  return (
    <SchedulerContext.Provider
      value={{
        handleSchedule,
        getAllPlatformsFormValues,
        getNoPlatformsFormValues,
        getAllPlatformsSelected,
        getInitialValues,
      }}
    >
      <Spinner
        fullscreen
        hidden={
          !facebook.isLoading &&
          !instagram.isLoading &&
          !twitter.isLoading &&
          !linkedin.isLoading &&
          !fileUploadHandler.uploading
        }
      />
      {props.children}
    </SchedulerContext.Provider>
  );
};

export default SchedulerProvider;
