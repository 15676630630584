import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const GraphContainer = styled.div`
  padding: ${helper.getSpacing("extraSmall")} 0;
  display: grid;
  grid-template-rows: 1fr 6fr;
  row-gap: ${helper.getSpacing("small")};
  border: 1px solid ${({ theme }) => theme.colors.pictonBlue};
  border-radius: ${helper.getSpacing("extraSmall")};
  height: 72vh;
`;

const GraphAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${helper.getSpacing("small")};
`;

export { GraphContainer, GraphAction };
