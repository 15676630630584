import { createContext, useEffect, useState } from "react";
import { adminEngagement } from "api";
import { Loader } from "components/common";
import { leadNurturingLoader } from "config/Twitter";
import { useNotification, useReactQuery } from "hooks";
import { IAdminEngagementResult } from "adapters/Twitter/types";
import { IAdminEngagementFormValues } from "./types";
import {
  useCreateAdminEngagement,
  useDeleteAdminEngagement,
} from "hooks/Twitter";

type TAdminEngagementContext = {
  data: IAdminEngagementResult;
  handleCreate: (values: IAdminEngagementFormValues) => void;
  handleUpdate: (values: IAdminEngagementFormValues) => void;
  handleDelete: () => void;
};

const initialData: IAdminEngagementResult = {
  id: "",
  twitterUsername: "",
  name: "",
  imageUrl: "",
  keywords: ["", "", "", "", ""],
  matchNumber: 0,
  like: false,
  reply: false,
  replyText: "",
  files: [],
  enable: false,
  tweetActions: "",
};

export const AdminEngagementContext =
  createContext<TAdminEngagementContext | null>(null);

const AdminEngagementProvider = (props: React.PropsWithChildren<{}>) => {
  const [data, setData] = useState<IAdminEngagementResult>(initialData);
  const createAdmin = useCreateAdminEngagement();
  const deleteAdmin = useDeleteAdminEngagement();
  const notification = useNotification();

  const { execute, isLoading } = useReactQuery(adminEngagement.get, {
    onSuccess: (data) => setData(data),
  });

  useEffect(() => {
    execute();
  }, [execute]);

  const handleCreate = (formValues: IAdminEngagementFormValues) => {
    createAdmin.execute(formValues, {
      onSuccess: (data) => {
        if (data) notification.notifySuccess(data.tweetActions);
      },
    });
  };

  const handleUpdate = (formValues: IAdminEngagementFormValues) =>
    createAdmin.execute(formValues, {
      onSuccess: (data) => {
        if (data) notification.notifySuccess(data.tweetActions);
      },
    });

  const handleDelete = () =>
    deleteAdmin.execute(
      {},
      {
        onSuccess: (data) => {
          if (data) notification.notifySuccess(data.message);
        },
      }
    );

  return (
    <AdminEngagementContext.Provider
      value={{ data, handleCreate, handleUpdate, handleDelete }}
    >
      <Loader textData={leadNurturingLoader} hidden={!isLoading} />
      {props.children}
    </AdminEngagementContext.Provider>
  );
};

export default AdminEngagementProvider;
