import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  postNow: true,
  advancedSearchIsOpen: false,
  currentMessage: "",
  liked: true,
  retweet: false,
};

export const tweetSearch = createSlice({
  name: "tweetSearch",
  initialState,
  reducers: {
    setPostNow: (state, action: PayloadAction<boolean>) => {
      return { ...state, postNow: action.payload };
    },
    setAdvancedSearchIsOpen: (state, action: PayloadAction<boolean>) => {
      return { ...state, advancedSearchIsOpen: action.payload };
    },
    setCurrentMessage: (state, action: PayloadAction<string>) => {
      return { ...state, currentMessage: action.payload };
    },
    setLiked: (state) => {
      return {
        ...state,
        liked: true,
        retweet: false,
      };
    },
    setRetweeted: (state) => {
      return {
        ...state,
        retweet: true,
        liked: false,
      };
    },
  },
});

export const {
  setPostNow,
  setAdvancedSearchIsOpen,
  setCurrentMessage,
  setLiked,
  setRetweeted,
} = tweetSearch.actions;

export default tweetSearch.reducer;
