import styled, { css } from "styled-components";
import { Formik } from "formik";
import { Button, Flex } from "components/core";
import PostStatusFilter from "./PostStatusFilter";
import PlatformFilter from "./PlatformFilter";
import PostDatetimeFilter from "./PostDatetimeFilter";
import { TPostFilterFormValues } from "../types";
import { postStatusFilterMap } from "config/SocialMediaPost";

type TContainerProps = {
  bordered?: boolean;
};

const Container = styled.div<TContainerProps>`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme, bordered }) => {
    return (
      bordered &&
      css`
        border-bottom: 1px solid ${theme.colors.extraLightGrey};
      `
    );
  }};
`;

interface IFilterMenuProps extends TContainerProps {
  onSubmit: (values: TPostFilterFormValues) => void;
}

const FilterMenu = ({
  children,
  onSubmit: onFormikSubmit,
  ...props
}: React.PropsWithChildren<IFilterMenuProps>) => {
  const initialValues: TPostFilterFormValues = {
    postStatus: postStatusFilterMap.all,
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
    platforms: ["facebook", "instagram", "linkedin", "twitter"],
  };

  return (
    <Container {...props}>
      <Formik initialValues={initialValues} onSubmit={onFormikSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Flex align="center">
              {children}
              <Button type="submit" content="Apply" variant="alpha" />
            </Flex>
          </form>
        )}
      </Formik>
    </Container>
  );
};

const PostStatus = () => <PostStatusFilter name="postStatus" />;
const Platform = () => <PlatformFilter name="platforms" />;
const DateRange = () => <PostDatetimeFilter name="dateRange" />;

FilterMenu.PostStatus = PostStatus;
FilterMenu.Platform = Platform;
FilterMenu.DateRange = DateRange;

export default FilterMenu;
