import { CookieService, LocalStorageService } from "services/common";
import { constants } from "config/Auth";
import { constants as webAnalyticsConstants } from "config/WebAnalytics";
import { IGoogleAnalyticsAccountData } from "api/Auth";
import { IGoogleAnalyticsSelectionState } from "redux/slices";

export type TGoogleAnalyticsCookie = {
  token: string;
  refresh: string;
};

class GoogleAnalyticsService {
  private cookie: CookieService<TGoogleAnalyticsCookie> = new CookieService(
    constants.GOOGLE_ANALYTICS_COOKIE
  );

  private storage: LocalStorageService<IGoogleAnalyticsAccountData> =
    new LocalStorageService(
      webAnalyticsConstants.GOOGLE_ANALYTICS_ACCOUNTS_DATA_LOCALSTORAGE
    );

  private selection: LocalStorageService<IGoogleAnalyticsSelectionState> =
    new LocalStorageService(
      webAnalyticsConstants.GOOGLE_ANALYTICS_SELECTED_ACCOUNT_LOCALSTORAGE
    );

  private accountsInitialValue = {
    accounts: [{ text: "", value: "" }],
    properties: [{ text: "", value: "", account: "" }],
    views: [{ text: "", value: "", account: "", property: "" }],
    userEmail: "",
    profilePicUrl: "",
  };

  private selectionInitialValue = {
    account: "",
    property: "",
    view: "",
  };

  setAuthCookie = (cookie: TGoogleAnalyticsCookie) => {
    this.cookie.setCookie(cookie);
  };

  removeAuthCookie = () => {
    this.cookie.removeCookie();
  };

  isAuthenticated = () => {
    return this.cookie.isCookiePresent();
  };

  getHeadersOrError = async () => {
    const cred = await this.cookie.getAsyncCookieOrError();
    return {
      "Token-GA": cred.token,
      "Refresh-GA": cred.refresh,
    };
  };

  setAccounts = (data: IGoogleAnalyticsAccountData) => {
    this.storage.setItem(data);
  };

  getAccounts = () => {
    return this.storage.getOrCreate(this.accountsInitialValue);
  };

  removeAccounts = () => {
    this.storage.setItem(this.accountsInitialValue);
  };

  setSelections = (data: IGoogleAnalyticsSelectionState) => {
    this.selection.setItem(data);
  };

  getSelections = () => {
    return this.selection.getOrCreate(this.selectionInitialValue);
  };

  removeSelections = () => {
    return this.selection.setItem(this.selectionInitialValue);
  };

  clearData = () => {
    this.removeAccounts();
    this.removeAuthCookie();
    this.removeSelections();
  };
}

export default GoogleAnalyticsService;
