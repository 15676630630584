import styled from "styled-components";

import ScheduleTweetThrad from "./ScheduleTweetThread";

const Container = styled.div``;

type IScheduleThreadProps = {
  threads: any[];
};

const ScheduleThread = ({ threads }: IScheduleThreadProps) => {
  return (
    <Container>
      {threads.map((thread) => (
        <ScheduleTweetThrad
          key={thread.post_id}
          thread={thread}
          showLine={thread !== threads[threads.length - 1]}
        />
      ))}
    </Container>
  );
};

export default ScheduleThread;
