import styled from "styled-components";

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
`;

const ProfileNames = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NameUser = styled.p`
  margin: unset;
`;

export { ProfileContainer, ProfileNames, NameUser };
