type TAPIResponse = {
  timezone_list: string[];
};

export type TTimezoneData = {
  key: string;
  text: string;
  value: string;
}[];

class TimezonesAdapter {
  parseGetResponse = ({ timezone_list }: TAPIResponse) => {
    const data: TTimezoneData = timezone_list.map((timezone) => ({
      key: timezone,
      text: timezone,
      value: timezone,
    }));
    return data;
  };
}

export default TimezonesAdapter;
