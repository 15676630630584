import { dolphynPublicRoot } from "api/instances";
import { AxiosRequestConfig } from "axios";
import { AuthService } from "services/Auth";

const authService = new AuthService();

const configureAuthHeaders = (config: AxiosRequestConfig) => {
  const accessCookie = authService.getAccessCookie();
  const refreshCookie = authService.getRefreshCookie();

  config.headers["Content-Type"] = "application/json";

  if (accessCookie) {
    config.headers["Authorization"] = `Bearer ${accessCookie}`;
    return config;
  }

  return new Promise((res, rej) => {
    dolphynPublicRoot
      .post(`api/profile/v1/public/refresh/`, {
        refresh: refreshCookie,
      })
      .then(({ data }) => {
        authService.setAccessCookie(data.access);
        config.headers["Authorization"] = `Bearer ${data.access}`;
        res(config);
      })
      .catch(() => {
        authService.removeRefreshCookie();
        rej();
      });
  });
};

export default configureAuthHeaders;
