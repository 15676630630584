import styled, { css, DefaultColors, DefaultFonts } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import {
  TFontSizes,
  TMarginProps,
  TPaddingProps,
  TSpacings,
} from "theme/types";

interface IFontProps {
  font?: keyof DefaultFonts;
  fontColor?: keyof DefaultColors;
  fontSize?: keyof TFontSizes;
}

interface IBackgroundProps {
  background?: keyof DefaultColors;
}

interface IFlexProps
  extends IFontProps,
    IBackgroundProps,
    TMarginProps,
    TPaddingProps {
  align?: React.CSSProperties["alignItems"];
  justify?: React.CSSProperties["justifyContent"];
  wrap?: React.CSSProperties["flexWrap"];
  direction?: React.CSSProperties["flexDirection"];
  gap?: keyof TSpacings;
}

const themeHelper = new ThemeHelper();

const Flex = styled.div<IFlexProps>`
  ${({
    align,
    justify,
    wrap,
    direction,
    font,
    background,
    fontColor,
    theme,
    fontSize,
    gap,
  }) => {
    return css`
      display: flex;
      justify-content: ${justify || "stretch"};
      align-items: ${align || "stretch"};
      flex-wrap: ${wrap || "nowrap"};
      flex-direction: ${direction || "row"};
      font-family: ${font ? theme.fonts[font] : theme.fonts.playfair};
      background-color: ${background
        ? theme.colors[background]
        : "transparent"};
      color: ${fontColor ? theme.colors[fontColor] : theme.colors.black};
      ${themeHelper.getFontSize(fontSize)};
      gap: ${themeHelper.getSpacing(gap)};
    `;
  }}
  ${({ mb, mt, ms, me }) =>
    themeHelper.getMargins({ top: mt, bottom: mb, left: ms, right: me })}
  ${({ pb, pt, ps, pe }) =>
    themeHelper.getPaddings({ top: pt, bottom: pb, left: ps, right: pe })}
`;

export default Flex;
