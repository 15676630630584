import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { APIError } from "errors";
import { OptimizerAdapter } from "adapters/WebAnalytics";
import { TOptimizerPayload } from "adapters/WebAnalytics/types";
import { links } from "config/WebAnalytics";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseWebAnalyticsApiUrl.append("/optimizer");
const adapter = new OptimizerAdapter();

const fetchOptimizer = async (payload: TOptimizerPayload) => {
  try {
    const { headers, params } = await adapter.getGetPayload(payload);
    const { data } = await dolphynAPI(URL, {
      params,
      headers,
    });

    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchOptimizer;
