import { createContext } from "react";
import {
  useFacebookAuth,
  useTwitterAuth,
  useInstagramAuth,
  useLinkedinAuth,
} from "hooks/Auth";

export const SelectPlatformContext = createContext({
  isAtLeastOneConnected: false as boolean,
});

const SelectPtatformProvider = (props: React.PropsWithChildren<{}>) => {
  const fbAuth = useFacebookAuth();
  const twitterAuth = useTwitterAuth();
  const instagramAuth = useInstagramAuth();
  const lkAuth = useLinkedinAuth();

  const isAtLeastOneConnected =
    fbAuth.isSignedIn ||
    twitterAuth.isSignedIn ||
    instagramAuth.isSignedIn ||
    lkAuth.isSignedIn;

  return (
    <SelectPlatformContext.Provider
      value={{
        isAtLeastOneConnected,
      }}
    >
      {props.children}
    </SelectPlatformContext.Provider>
  );
};

export default SelectPtatformProvider;
