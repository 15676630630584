import { useEffect } from "react";
import { Grid, Body } from "./Calendar.styles";
import { usePendo, useSubHeading } from "hooks/common";
import { subHeadingMap } from "config/SocialMediaPost";
import {
  SocialCalendar,
  Menu,
} from "components/SocialMediaPost/SocialCalendar";
import {
  SchedulerProvider,
  SocialCalendarProvider,
} from "context/SocialMediaPost";
import { EventCalendarProvider } from "context/common";

const Calender = () => {
  const [, setHeading] = useSubHeading();
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeading(subHeadingMap.calender);
  }, [setHeading]);

  return (
    <EventCalendarProvider>
      <SchedulerProvider>
        <SocialCalendarProvider>
          <Grid>
            <Menu />
            <Body>
              <SocialCalendar />
            </Body>
          </Grid>
        </SocialCalendarProvider>
      </SchedulerProvider>
    </EventCalendarProvider>
  );
};

export default Calender;
