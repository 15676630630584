import { useEffect } from "react";
import { scheduleInstagram as api } from "api/SocialMediaPost";
import useInstagramPosts from "./useInstagramPosts";
import { useInstagramAuth, useReactQuery } from "hooks";
import { constants } from "config/SocialMediaPost";
import { TInstagramPostObject } from "adapters/SocialMediaPost/types";

type TOptions = {
  onSuccess?: (data: TInstagramPostObject[]) => void;
};

const useInstagramActivity = (options?: TOptions) => {
  const { execute: fetch, isLoading: fetching } = useReactQuery(
    api.fetchActivity,
    {
      onSuccess: ({ data }) => {
        if (data && options?.onSuccess) {
          options?.onSuccess(data);
        }
      },
    }
  );
  const auth = useInstagramAuth();
  const { create, update, remove, isLoading } = useInstagramPosts();
  const uid = auth.selectedAccount.uid;

  useEffect(() => {
    if (uid) {
      fetch({
        uid,
        page: 1,
        limit: constants.DEFAULT_ACTIVITY_PAGE_LIMIT,
      });
    }
  }, [fetch, uid]);

  return {
    fetch: () => {
      if (uid) {
        fetch({
          uid,
          page: 1,
          limit: constants.DEFAULT_ACTIVITY_PAGE_LIMIT,
        });
      }
    },
    create,
    update,
    remove,
    isLoading: fetching || isLoading,
  };
};

export default useInstagramActivity;
