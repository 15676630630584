import { Text } from "components/core";
import { StyledOption, Icon } from "./Option.styles";

interface IOptionProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: string;
  text: string;
  active: boolean;
}

const Option = ({ icon, text, ...props }: IOptionProps) => {
  return (
    <StyledOption {...props}>
      <Icon alt={`${text}-icon`} src={icon} />
      <Text variant="darkGrey" content={text} />
    </StyledOption>
  );
};

export default Option;
