import { Container } from "./Bio.styles";
import StringHelper from "helpers/StringHelper";
import { useToggle } from "hooks/common";
import { ReadMore } from "components/core";
const helper = new StringHelper();

export interface IBioProps {
  text?: string;
  truncateLength?: number;
}

// TODO: Here this Bio Component when I am using it showing blank window on screen.

const Bio = ({
  text,
  truncateLength = 100,
  children,
  ...props
}: React.PropsWithChildren<IBioProps>) => {
  const [showReadMore, toggleShowReadMore] = useToggle();

  const renderShowReadMore = () => {
    return (
      <ReadMore
        showReadMore={showReadMore}
        toggleShowReadMore={toggleShowReadMore}
      />
    );
  };

  const renderText = (text?: any) => {
    if (text && typeof text === "string") {
      const renderableText = !showReadMore
        ? helper.truncate(text, truncateLength)
        : text;
      return (
        <>
          {renderableText}{" "}
          {text.length >= truncateLength && renderShowReadMore()}
        </>
      );
    }
    return null;
  };

  if (children) {
    return <Container {...props}>{renderText(children)}</Container>;
  }

  return <Container {...props}>{renderText(text)}</Container>;
};

export default Bio;
