import { useReducer } from "react";
import { SortType } from "components/common/Graph/types";
import { sortBy } from "lodash";

type dataKey<T> = keyof T;

type SortTypeMap<T> = {
  [key in keyof T]: SortType;
};

type TSortState<T> = {
  data: T[];
  type: SortTypeMap<T>;
};

type TSortAction<T> = {
  type: SortType;
  dataKey: dataKey<T>;
};

const useGraphSort = <T>(initialData: T[]) => {
  let initialTypes = {} as SortTypeMap<T>;
  Object.keys(initialData[0] || {}).forEach(
    (props) => (initialTypes = { ...initialTypes, [props]: SortType.none })
  );

  const initialState: TSortState<T> = {
    data: initialData,
    type: initialTypes,
  };

  const reducer = (state: TSortState<T>, action: TSortAction<T>) => {
    switch (action.type) {
      case SortType.ascending: {
        const sortedData = sortBy(state.data, (obj) => obj[action.dataKey]);
        return {
          ...state,
          type: { [action.dataKey]: SortType.ascending } as SortTypeMap<T>,
          data: sortedData,
        };
      }

      case SortType.descending: {
        const sortedData = sortBy(state.data, (obj) => -obj[action.dataKey]);
        return {
          ...state,
          type: { [action.dataKey]: SortType.descending } as SortTypeMap<T>,
          data: sortedData,
        };
      }

      case SortType.none: {
        return { ...state, ...initialState };
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSortClick = (dataKey: dataKey<T>) => {
    switch (state.type[dataKey]) {
      case SortType.ascending:
        dispatch({ type: SortType.descending, dataKey });
        break;
      case SortType.descending:
        dispatch({ type: SortType.none, dataKey });
        break;
      case SortType.none:
        dispatch({ type: SortType.ascending, dataKey });
        break;
      default:
        break;
    }
  };

  return { state, handleSortClick };
};

export default useGraphSort;
