export const timeSince = (timeStamp: any) => {
  var now = new Date(),
    secondsPast = (now.getTime() - timeStamp) / 1000;
  if (secondsPast < 60) {
    return parseInt(secondsPast.toString()) + "s";
  }
  if (secondsPast < 3600) {
    return parseInt((secondsPast / 60).toString()) + "m";
  }
  if (secondsPast <= 86400) {
    return parseInt((secondsPast / 3600).toString()) + "h";
  }
  if (secondsPast > 86400) {
    const day = timeStamp.getDate();
    const month = timeStamp
      .toDateString()
      .match(/ [a-zA-Z]*/)[0]
      .replace(" ", "");
    const year =
      timeStamp.getFullYear() === now.getFullYear()
        ? ""
        : " " + timeStamp.getFullYear();
    return day + " " + month + year;
  }
};

export const getPastTime = (timestamp: string) => {
  const currentTime = new Date();
  const givenTime = new Date(timestamp);
  let result = "";

  if (currentTime.getFullYear() === givenTime.getFullYear()) {
    result = `${currentTime.getMonth() - givenTime.getMonth()} Months`;
  } else {
    result = `${currentTime.getFullYear() - givenTime.getFullYear()} Years`;
  }

  return result;
};
