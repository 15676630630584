import { useField } from "formik";
import { MultiCheckDropdown } from "components/common";
import { useToggle } from "hooks/common";
import { IScheduleCreateFormValues } from "./types";
import { useTwitterAccounts, usePlatformDropdown } from "hooks/SocialMediaPost";

interface ISelectTwitterProps {
  name: string;
}

const SelectTwitter = (props: ISelectTwitterProps) => {
  const [field, , helpers] = useField<IScheduleCreateFormValues["twitter"]>(
    props.name
  );

  const [open, setOpen] = useToggle();
  const twitter = useTwitterAccounts();
  const dropdown = usePlatformDropdown();

  return (
    <MultiCheckDropdown
      open={open}
      handleToggle={() => setOpen(true)}
      onOutsideClick={() => setOpen(false)}
      placeholder="Twitter"
      options={twitter.options}
      value={field.value}
      onChange={(selectedPage) => {
        const pages = dropdown.getOnChangeFieldValues(
          field.value,
          selectedPage
        );
        helpers.setValue(pages);
      }}
      onSubmit={() => setOpen(false)}
    />
  );
};

export default SelectTwitter;
