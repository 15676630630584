import { hubSpot } from "../instances";

const URL =
  "submissions/v3/integration/submit/19996306/513da3eb-435c-4436-8cf5-007ebe5f5800";

const defaultError =
  "Unable to process the request! Please try again or enter a different email.";

export interface IResponse {
  message: string;
}

export interface IError {
  message: string;
}

const subscribe = async (email: string) => {
  const payload = {
    fields: [
      {
        name: "email",
        value: email,
      },
    ],
  };
  try {
    const { data } = await hubSpot.post<{ inlineMessage: string }>(
      URL,
      payload
    );
    if ("inlineMessage" in data) {
      return { message: data.inlineMessage };
    }
    throw new Error();
  } catch (e) {
    const error = new Error(defaultError);
    throw error;
  }
};

export default subscribe;
