import React, { createContext } from "react";
import { setDolphynSignedIn, setDolphynSignedOut } from "redux/slices/Auth";
import { useAppDispatch } from "redux/store/hooks";
import { Spinner } from "components/common";
import { useMutation } from "react-query";
import {
  socialLoginTwitter,
  socialLoginGoogle,
  socialLoginFacebook,
} from "api/Auth";
import { ISocialLoginGoogle } from "api/Auth/socialLoginGoogle";
import { ISocialLoginFacebook } from "api/Auth/socialLoginFacebook";
import { useNotification, useNavigation } from "hooks/common";

type TContext = {
  handleGoogleAuth: (data: ISocialLoginGoogle) => void;
  handleFacebookAuth: (data: ISocialLoginFacebook) => void;
  handleTwitterAuth: (data: string) => void;
};

export const SocialLoginContext = createContext<TContext | null>(null);

const SocialLoginProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const nav = useNavigation();
  const notification = useNotification();

  const handleLoginSuccess = () => {
    dispatch(setDolphynSignedIn());
    nav.navigate((routes) => routes.rootRoutes.contentCreation);
  };

  const handleLoginFailure = ({
    displayMessage,
  }: {
    displayMessage: string;
  }) => {
    notification.notifyDanger(displayMessage);
    dispatch(setDolphynSignedOut());
  };

  const twitter = useMutation(socialLoginTwitter, {
    onSuccess: handleLoginSuccess,
    onError: handleLoginFailure,
  });

  const google = useMutation(socialLoginGoogle, {
    onSuccess: handleLoginSuccess,
    onError: handleLoginFailure,
  });

  const facebook = useMutation(socialLoginFacebook, {
    onSuccess: handleLoginSuccess,
    onError: handleLoginFailure,
  });

  const handleGoogleAuth = (data: ISocialLoginGoogle) => google.mutate(data);
  const handleTwitterAuth = (data: string) => twitter.mutate(data);
  const handleFacebookAuth = (data: ISocialLoginFacebook) =>
    facebook.mutate(data);

  const isLoading = twitter.isLoading || google.isLoading || facebook.isLoading;

  return (
    <SocialLoginContext.Provider
      value={{
        handleGoogleAuth,
        handleFacebookAuth,
        handleTwitterAuth,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {children}
    </SocialLoginContext.Provider>
  );
};

export default SocialLoginProvider;
