import styled from "styled-components";

const ListContianer = styled.div`
  max-height: fit-content;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.2rem;
  }
  ::-webkit-scrollbar-track {
    background-color: unset;
  }
`;

export { ListContianer };
