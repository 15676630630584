import { GoogleAnalyticsService } from "services/WebAnalytics";
import { IOptimizerData, TOptimizerPayload } from "./types";

type TAPIResponse = {
  custom_dimensions: IOptimizerData["customDimension"];
  goals: IOptimizerData["goal"];
  channel_grouping: IOptimizerData["channelGrouping"];
  group_content: IOptimizerData["groupContent"];
  content_performance: IOptimizerData["contentPerformance"];
  e_commerce_tracking: IOptimizerData["eCommerceTracking"];
};

type TAPIGETPayload = {
  period: number;
  period_type: string;
  google_analytics_view: string;
  google_analytics_account: string;
  google_analytics_property: string;
};

class OptimizerAdapter {
  private parseResponse = (apiData: TAPIResponse): IOptimizerData => {
    const parsedData: IOptimizerData = {
      customDimension: apiData.custom_dimensions,
      goal: apiData.goals,
      channelGrouping: apiData.channel_grouping,
      groupContent: apiData.group_content,
      contentPerformance: apiData.content_performance,
      eCommerceTracking: apiData.e_commerce_tracking,
    };

    return parsedData;
  };

  parseGetResponse = (data: TAPIResponse): IOptimizerData => {
    return this.parseResponse(data);
  };

  getGetPayload = async ({
    period,
    periodType,
    view,
    account,
    property,
  }: TOptimizerPayload) => {
    const googleAnalyticsService = new GoogleAnalyticsService();
    const headers = await googleAnalyticsService.getHeadersOrError();
    const params: TAPIGETPayload = {
      period,
      period_type: periodType,
      google_analytics_view: view,
      google_analytics_account: account,
      google_analytics_property: property,
    };

    return {
      params,
      headers,
    };
  };
}

export default OptimizerAdapter;
