import styled, { css, DefaultColors, DefaultFonts } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TFontSizes, TSpacings } from "theme/types";
import Item from "./Item";

const helper = new ThemeHelper();

type TStyledListProps = {
  variant?: keyof DefaultColors;
  bulleted?: boolean;
  bulletVariant?: keyof DefaultColors;
  font?: keyof DefaultFonts;
  size?: keyof TFontSizes;
  spacing?: keyof TSpacings;
  padding?: keyof TSpacings;
};

const StyledList = styled.ul<TStyledListProps>`
  color: ${({ theme, variant = "black" }) => theme.colors[variant]};
  list-style: none;
  padding: unset;
  margin: unset;
  font-family: ${({ font = "poppins", theme }) => theme.fonts[font]};
  ${({ size }) => helper.getFontSize(size)}
  ${({
    bulleted,
    theme,
    bulletVariant = "black",
    spacing = "small",
    padding = "tiny",
  }) => {
    if (bulleted) {
      return css`
        padding-left: ${helper.getSpacing("small")};
        ${Item} {
          padding: ${helper.getSpacing(padding)};
          margin-bottom: ${helper.getSpacing(spacing)};
          &::before {
            color: ${theme.colors[bulletVariant]};
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
            content: "•";
          }
        }
      `;
    }
  }};
`;

interface IListProps extends React.PropsWithChildren<TStyledListProps> {
  as?: React.ElementType;
}

const List = ({ children, ...props }: IListProps) => {
  return <StyledList {...props}>{children}</StyledList>;
};

List.Item = Item;

export default List;
