import {
  Container,
  Header,
  CardsContainer,
  ReviewCard,
  Review,
  Customer,
  Image,
  Detail,
  Name,
  Role,
} from "./Reviews.styles";
import designnation from "assets/images/Home/Reviews/designnation.png";
import posedge from "assets/images/Home/Reviews/posedge.png";
import leeoteck from "assets/images/Home/Reviews/leeoteck.png";
import righteoustech from "assets/images/Home/Reviews/righteoustech.png";
import microfinance from "assets/images/Home/Reviews/microfinance.png";

const data = [
  {
    review: `We use Twitter extensively and Dolphy is a one stop solution for our all our needs. Dolphy is easy to use, smart and inherent intelligent tool.`,
    pic: designnation,
    name: "Chandrakanth",
    role: "Designnation.in ",
  },
  {
    review: `Best tool for small team. No need to go through long educational videos. It's recommendation system helped us everywhere.`,
    pic: posedge,
    name: " Mohan Krishna",
    role: "Posedge.in ",
  },
  {
    review: `Dolphy helped us moved from paid to organic website visitors increase. One dashboard to understand what is going on website and apply recommendation provided by Dolphy.`,
    pic: leeoteck,
    name: "Sravan Chaitanya",
    role: "Leeoteckindia.com ",
  },
  {
    review: `Do not know Twitter could be so effective in lead generation. We have significantly increased our Twitter presence and engagement through Dolphy's EasyTwitter. Saved my significant time. I recommend`,
    pic: righteoustech,
    name: "Rohit Shetty",
    role: "Righteoustech.in ",
  },
  {
    review: `Automated Social Lead Generation concept I have never seen earlier, which reduced my cost per lead significantly low. That is what I aspired for and the same I received. `,
    pic: microfinance,
    name: "Kapil Patil",
    role: " Microfinance.ai ",
  },
  {
    review: `We will also facilitate the business marketing of these products with our SEO experts so that they become a ready-to-use website and help sell a product from the company`,
    pic: "https://i.pravatar.cc/300",
    name: "Guy Hawkins",
    role: "UX Designer ",
  },
];

const Reviews = () => {
  return (
    <Container>
      <Header>Reviews</Header>
      <CardsContainer>
        {data.map(({ review, pic, name, role }, key) => (
          <ReviewCard key={key}>
            <Review>{review}</Review>
            <Customer>
              <Image src={pic} alt="customer-picture" />
              <Detail>
                <Name>{name}</Name>
                <Role>{role}</Role>
              </Detail>
            </Customer>
          </ReviewCard>
        ))}
      </CardsContainer>
    </Container>
  );
};

export default Reviews;
