import * as Yup from "yup";

const schema = {
  email: Yup.string()
    .required("Email is Required!")
    .email("Invalid Email address!"),
  countryCode: Yup.string().required("A country code cannot be empty."),
  phoneNumber: Yup.string()
    .required("Phone number cannot be empty.")
    .matches(/^[0-9]*$/, "Phone numbers must be numeric.")
    .min(10, "A phone number should be minimum of 10 digits.")
    .max(15, "A phone number should be maximum of 15 digits."),
  password: Yup.string()
    .required("Password is Required!")
    .min(8, "Password should be at least 8 characters!"),
  keywords: Yup.array()
    .required("Keywords cannot be empty.")
    .max(5, "Maximum Keywords allowed are 5.")
    .test({
      name: "required",
      message: "Keywords cannot be empty.",
      test: (val) => {
        const keywords = val?.filter((keyword) => keyword !== "");
        return Array.isArray(keywords) && keywords.length > 0;
      },
    }),
};
export default schema;
