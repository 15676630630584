import { ReportsAdapter } from "adapters/Twitter";
import { IReportsPayload } from "adapters/Twitter/types";
import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Twitter";
import { APIError } from "errors";

const adapters = new ReportsAdapter();
const URL = links.baseTwitterApiUrl.append("/leads/reports");
const error = new APIError(constants.DEFAULT_API_ERROR);

const reports = {
  post: async (payload: IReportsPayload) => {
    try {
      const config = adapters.getConfig();
      const apiPayload = adapters.getPostPayload(payload);
      const { data } = await dolphynAPI.post(URL, apiPayload, config);
      return adapters.postParseResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default reports;
