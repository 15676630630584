import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { IDesignSystemProps } from "theme/types";

const helper = new ThemeHelper();

interface IStyledTextProps extends IDesignSystemProps {
  style?: React.CSSProperties;
}

const StyledText = styled.p<IStyledTextProps>`
  margin: unset;
  outline: unset;
  border: unset;
  background: unset;
  ${({ theme, font = "poppins" }) => {
    return css`
      font-family: ${theme.fonts[font]};
    `;
  }};
  ${({ theme, variant = "lightGrey" }) => {
    return css`
      color: ${theme.colors[variant]};
    `;
  }};
  ${({ onClick }) => {
    if (onClick)
      return css`
        cursor: pointer;
      `;
  }};
  ${({ size = "small" }) => helper.getFontSize(size)};
  ${({ fontWeight = "low" }) => helper.getFontWeight(fontWeight)};
  ${({ mt, ms, mb, me }) =>
    helper.getMargins({ top: mt, bottom: mb, left: ms, right: me })};
  ${({ pt, ps, pb, pe }) =>
    helper.getPaddings({ top: pt, bottom: pb, left: ps, right: pe })};
`;

type TTextOwnProps<T extends React.ElementType> = {
  children?: React.ReactNode;
  content?: string;
  as?: T;
} & IStyledTextProps;

type TTextProps<T extends React.ElementType> = TTextOwnProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof TTextOwnProps<T>>;

const Text = <T extends React.ElementType>({
  content,
  children,
  ...props
}: TTextProps<T>) => {
  return <StyledText {...props}>{content || children}</StyledText>;
};

export default Text;
