import { CookieService, LocalStorageService } from "services/common";
import { constants } from "config/Auth";
import { constants as webAnalyticsConstants } from "config/WebAnalytics";
import { ILoginSearchConsoleData } from "api/Auth";
import { IGoogleSearchConsoleSelectionState } from "redux/slices";

export type TGoogleSearchConsoleCookie = {
  token: string;
  refresh: string;
};

class SearchConsoleService {
  private cookie: CookieService<TGoogleSearchConsoleCookie> = new CookieService(
    constants.GOOGLE_SEARCH_CONSOLE_COOKIE
  );

  private storage: LocalStorageService<ILoginSearchConsoleData> =
    new LocalStorageService(
      webAnalyticsConstants.GOOGLE_SEARCH_CONSOLE_SITES_DATA_LOCALSTORAGE
    );

  private selection: LocalStorageService<IGoogleSearchConsoleSelectionState> =
    new LocalStorageService(
      webAnalyticsConstants.GOOGLE_SEARCH_CONSOLE_SELECTED_SITE_LOCALSTORAGE
    );

  private storageInitialValue = {
    sites: [{ text: "", value: "" }],
    userEmail: "",
    profilePicUrl: "",
  };

  private selectionInitialValue = {
    site: "",
  };

  setAuthCookie = (cookie: TGoogleSearchConsoleCookie) => {
    this.cookie.setCookie(cookie);
  };

  removeAuthCookie = () => {
    this.cookie.removeCookie();
  };

  isAuthenticated = () => {
    return this.cookie.isCookiePresent();
  };

  getHeadersOrError = async () => {
    const cred = await this.cookie.getAsyncCookieOrError();
    return {
      "Token-GSC": cred.token,
      "Refresh-GSC": cred.refresh,
    };
  };

  setSites = (data: ILoginSearchConsoleData) => {
    this.storage.setItem(data);
  };

  getSites = () => {
    return this.storage.getOrCreate(this.storageInitialValue);
  };

  removeSites = () => {
    this.storage.setItem(this.storageInitialValue);
  };

  setSelections = (data: IGoogleSearchConsoleSelectionState) => {
    this.selection.setItem(data);
  };

  getSelections = () => {
    return this.selection.getOrCreate(this.selectionInitialValue);
  };

  removeSelections = () => {
    return this.selection.setItem(this.selectionInitialValue);
  };

  clearData = () => {
    this.removeSites();
    this.removeAuthCookie();
    this.removeSelections();
  };
}

export default SearchConsoleService;
