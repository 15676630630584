import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAutoDm } from "adapters/Twitter/types";

const initialState = {
  data: {} as IAutoDm,
};

export const autodm = createSlice({
  name: "autodm",
  initialState,
  reducers: {
    setAutodm: (state, action: PayloadAction<IAutoDm>) => {
      return { ...state, data: action.payload };
    },
  },
});

export const { setAutodm } = autodm.actions;

export default autodm.reducer;
