import { Header, Checkbox, Flex, Text, Label } from "components/core";
import SelectFacebook from "./SelectFacebook";
import SelectInstagram from "./SelectInstagram";
import SelectLinkedin from "./SelectLinkedin";
import SelectTwitter from "./SelectTwitter";

interface ISelectPlatforms {
  fieldNames: {
    facebook: string;
    instagram: string;
    linkedin: string;
    twitter: string;
  };
  onSelectAll: () => void;
  onDeSelectAll: () => void;
  allSelected: boolean;
}

const SelectPlatform = ({
  fieldNames,
  onSelectAll,
  onDeSelectAll,
  allSelected,
}: ISelectPlatforms) => {
  return (
    <>
      <Label style={{ cursor: "pointer" }}>
        <Flex justify="flex-start" gap="small" align="center" mb="small">
          <Header
            as="div"
            font="poppins"
            size="small"
            content="Select Platform"
          />
          <Checkbox
            style={{ borderStyle: "dashed", cursor: "pointer" }}
            variant="success"
            checked={allSelected}
            onChange={(e) => {
              if (e.target.checked) {
                onSelectAll();
                return;
              }
              onDeSelectAll();
            }}
          />
          <Text content="All" />
        </Flex>
      </Label>
      <Flex justify="flex-start" gap="small" align="center" mb="small">
        <SelectFacebook name={fieldNames.facebook} />
        <SelectInstagram name={fieldNames.instagram} />
        <SelectLinkedin name={fieldNames.linkedin} />
        <SelectTwitter name={fieldNames.twitter} />
      </Flex>
    </>
  );
};

export default SelectPlatform;
