import { createContext } from "react";
import { TEnterpriseSignUp } from "adapters/Auth/types";
import { useNotification } from "hooks";
import { useMutation } from "react-query";
import { loginEnterprise } from "api";
import { Spinner } from "components/common";

type TContext = {
  handleSignup: (values: TEnterpriseSignUp) => void;
};

export const LoginEnterpriseContext = createContext<TContext | null>(null);

const LoginEnterpriseProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const notification = useNotification();

  const { mutate: login, isLoading } = useMutation(loginEnterprise, {
    onSuccess: (data) => {
      if (data) {
        notification.notifyInfo(data.message);
      }
    },
    onError: ({ displayMessage }) => {
      notification.notifyDanger(displayMessage);
    },
  });

  const handleSignup = (values: TEnterpriseSignUp) => login(values);

  return (
    <LoginEnterpriseContext.Provider
      value={{
        handleSignup,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {children}
    </LoginEnterpriseContext.Provider>
  );
};

export default LoginEnterpriseProvider;
