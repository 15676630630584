import { useCollection } from "hooks/common";
import BaseMultiCheckDropdown from "./BaseDropdown";
import Menu from "./Menu";
import {
  TId,
  TMultiCheckDropdownOption,
  IBaseMultiCheckDropdownProps,
} from "./types";

interface IMultiCheckDropdownProps<
  U extends TId,
  T extends TMultiCheckDropdownOption<U>
> extends Partial<React.PropsWithChildren<IBaseMultiCheckDropdownProps>> {
  open: boolean;
  handleToggle: () => void;
  options?: T[];
  value?: T[];
  onChange?: (data: T) => void;
  onSubmit?: (data: T[]) => void;
}

const MultiCheckDropdown = <
  U extends TId,
  T extends TMultiCheckDropdownOption<U>
>({
  open,
  handleToggle,
  placeholder,
  options = [],
  value,
  onChange,
  onSubmit,
  onOutsideClick,
  children,
}: IMultiCheckDropdownProps<U, T>) => {
  const valueBag = useCollection(value || []);

  const renderOptions = () => {
    if (options)
      return (
        <>
          {options.map((option, key) => (
            <Menu.Item
              {...option}
              key={key}
              checked={valueBag.isItemPresent(option.id)}
              onClick={() => onChange && onChange(option)}
            />
          ))}
        </>
      );
  };

  const renderMenu = () => {
    if (children && open) return children;
    if (open)
      return (
        <Menu onSubmit={() => onSubmit && onSubmit(value || [])}>
          {renderOptions()}
        </Menu>
      );
    return null;
  };

  return (
    <BaseMultiCheckDropdown
      onOutsideClick={onOutsideClick || (() => {})}
      placeholder={placeholder}
      areAllSelected={options.length !== 0 && options.length === value?.length}
      handleToggle={handleToggle}
    >
      {renderMenu()}
    </BaseMultiCheckDropdown>
  );
};

export default MultiCheckDropdown;
