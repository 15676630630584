import { css } from "styled-components";
import { TPositionObject } from "./types";

class Position {
  getPosition = (position: TPositionObject) => {
    const addPixelUnit = (value: number) => (value ? value + "px" : "unset");
    const { top = 0, bottom = 0, left = 0, right = 0 } = position;
    return css`
      top: ${addPixelUnit(top)};
      left: ${addPixelUnit(left)};
      right: ${addPixelUnit(right)};
      bottom: ${addPixelUnit(bottom)};
    `;
  };
}

export default Position;
