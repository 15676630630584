import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { RetweetTweetAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";

const URL = links.baseTwitterApiUrl.append("/tweet/retweet");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new RetweetTweetAdapter();

const retweetTweet = async (tweetId: string) => {
  try {
    const config = adapter.getConfig();
    await dolphynAPI.post(`${URL}/${tweetId}`, [], config);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default retweetTweet;
