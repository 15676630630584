import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 15% 10% 75%;
`;

const TopSection = styled.header`
  display: flex;
  align-items: center;
  padding-inline: ${helper.getSpacing("extraLarge")};

  @media screen and (max-width: 1024px) {
    padding-inline: ${helper.getSpacing("medium")};
  }

  @media screen and (max-width: 768px) {
    padding-inline: ${helper.getSpacing("small")};
  }
`;

const MenuBar = styled.div`
  padding-inline: ${helper.getSpacing("extraLarge")};

  @media screen and (max-width: 1024px) {
    padding-inline: ${helper.getSpacing("medium")};
  }

  @media screen and (max-width: 768px) {
    padding-inline: ${helper.getSpacing("small")};
  }
`;

const Body = styled.div`
  padding-inline: ${helper.getSpacing("extraLarge")};
  padding-top: ${helper.getSpacing("small")};

  @media screen and (max-width: 1024px) {
    padding-inline: ${helper.getSpacing("medium")};
  }

  @media screen and (max-width: 768px) {
    padding-inline: ${helper.getSpacing("small")};
  }
`;

export { Container, TopSection, MenuBar, Body };
