import { useState } from "react";
import { v4 as uuid } from "uuid";
import {
  Container,
  TopBar,
  InnerContainer,
  Body,
  Content,
  Image,
} from "./Overview.styles";
import { overviewData, overviewMenuData, overviewMenuMap } from "config/Home";
import { TOverviewMenuMap } from "./types";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import Banner from "./Banner";

const Overview = () => {
  const [activeItem, setActiveItem] = useState<TOverviewMenuMap>(
    overviewMenuMap.socialMediaScheduler
  );

  const renderMenuItems = () => {
    return overviewMenuData.map(({ id, title, icon }) => (
      <MenuItem
        key={uuid()}
        title={title}
        icon={icon}
        active={activeItem === id}
        onClick={() => setActiveItem(id)}
      />
    ));
  };

  return (
    <Container>
      <TopBar>
        <Banner url={overviewData[activeItem].url} />
      </TopBar>
      <InnerContainer>
        <Menu>{renderMenuItems()}</Menu>
        <Body>
          <Content>
            <Image
              src={overviewData[activeItem].image}
              alt={`${activeItem}-screen`}
            />
          </Content>
        </Body>
      </InnerContainer>
    </Container>
  );
};

export default Overview;
