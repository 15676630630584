const {
  REACT_APP_BACKEND_ROOT_URL: dolphynBackendRoot,
  REACT_APP_DOLPHY_ROOT_URL: dolphynRoot,
  REACT_APP_NULL_BACKEND_ROOT_URL: nullBackendRoot,
  REACT_APP_NULL_ROOT_URL: nullRoot,
  REACT_APP_DOLPHY_HOME_URL: dolphyHome,
} = process.env;

const navbar = {
  blogs: `${dolphynBackendRoot}/blog`,
  nullInnovation: nullRoot || "",
  dolphy: dolphyHome || "",
};

const footer = {
  dolphy: dolphynRoot as string,
  survivor: `${nullBackendRoot}/Survivr:A-Life-Saver-Band`,
  aboutUs: `${nullBackendRoot}/about-us`,
  contactUs: `${nullBackendRoot}/contact-us`,
  framegen: `${nullBackendRoot}/Framegen:You-Think-It-We'll-Build-It`,
  blogs: `${dolphynBackendRoot}/blog`,
  career: `${nullBackendRoot}/Career-at-null-innovation`,
  home: nullRoot as string,
  whatWeDo: `${nullBackendRoot}/what-we-do`,
  refund: `${nullBackendRoot}/refund-and-cancellation-policy`,
  terms: `${nullBackendRoot}/terms-and-condition`,
  privacy: `${nullBackendRoot}/Data-Privacy-Policy`,
};

export { navbar, footer };
