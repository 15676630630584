import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const GraphContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("small")} 0;
  height: 90%;
  width: 100%;

  .recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    stroke-opacity: 0;
  }
`;

export { GraphContainer };
