export type TPreviewUrl = {
  id: number | string;
  type: string;
  url: string;
};

class FileHelper {
  acceptedTypes: string[];
  config?: {
    maxContent?: number;
  };

  constructor(acceptedTypes: string[], config?: FileHelper["config"]) {
    this.acceptedTypes = acceptedTypes;
    if (config) {
      this.config = config;
    }
  }

  getFileList = (files: FileList) => Array.from(files);

  validateFiles = (files: File[]): [boolean, string] => {
    let error: string = "";
    let result: boolean = true;
    files.forEach((file) => {
      const isValidFileType = this.acceptedTypes.includes(
        this.getFileExtension(file)
      );
      if (!isValidFileType) {
        result = false;
      }
    });
    if (!result) {
      error = `Please select valid file types: ${this.acceptedTypes.join(
        ", "
      )} are allowed.`;
      return [result, error];
    }

    if (this.config) {
      if (this.config.maxContent) {
        result = files.length <= this.config.maxContent;
        error = `Max. ${this.config.maxContent} are allowed.`;
        return [result, error];
      }
      /* 
      Further default validations goes here per config object
      */
    }
    return [result, error];
  };

  getFileType = (file: File) => {
    return file.type.split("/")[0];
  };

  getFileExtension = (file: File) => {
    return file.type.split("/")[1];
  };

  getObjectUrls = (files: File[]): TPreviewUrl[] => {
    return files.map((file, id) => ({
      id,
      type: this.getFileType(file),
      url: URL.createObjectURL(file),
    }));
  };

  removeFile = (files: File[], url: TPreviewUrl) => {
    return files.filter((file, id) => id !== url.id);
  };
}

export default FileHelper;
