import { TInviteEnterprisePayload } from "adapters/Profile/types";
import { useNotification } from "hooks";
import { useInviteEnterprise } from "hooks/Profile";

import { createContext } from "react";

type TEnterpriseContext = {
  handleInvite: (values: TInviteEnterprisePayload) => void;
};

export const EnterpriseContext = createContext<TEnterpriseContext | null>(null);

const EnterpriseProvider = (props: React.PropsWithChildren<{}>) => {
  const invite = useInviteEnterprise();
  const notification = useNotification();

  const handleInvite = (formValues: TInviteEnterprisePayload) => {
    invite.execute(formValues, {
      onSuccess: (data) => {
        if (data) notification.notifySuccess(data.message);
      },
    });
  };

  return (
    <EnterpriseContext.Provider value={{ handleInvite }}>
      {props.children}
    </EnterpriseContext.Provider>
  );
};

export default EnterpriseProvider;
