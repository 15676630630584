import { TwitterService } from "services/Auth";
import {
  IEngagementPostResponse,
  IEngagementResponse,
  TEngagementPostPayload,
} from "./types";

type TAPIPayload = {
  keywords: string[];
  match_number: number;
};

interface TAPIResponse {
  tweets: {
    id: number;
    text: string;
    created_at: string;
    likes_count: number;
    retweet_count: number;
    like_status: boolean;
    matched_keywords: string[];
    name: string;
    username: string;
    profile_image_url: string;
  }[];
  keywords: string[];
}

interface TAPIPostResponse extends TAPIResponse {
  message: string;
}

type TAPIDeleteResponse = {
  message: string;
};

class LeadNurturingAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse): IEngagementResponse => {
    return {
      tweets: apiResponse.tweets.map((tweet) => ({
        tweetId: tweet.id.toString(),
        createdAt: tweet.created_at,
        likes: tweet.likes_count,
        retweets: tweet.retweet_count,
        isLiked: tweet.like_status,
        matchedKeywords: tweet.matched_keywords,
        text: tweet.text,
        name: tweet.name,
        username: tweet.username,
        profileImageUrl: tweet.profile_image_url,
      })),
      keywords: apiResponse.keywords,
    };
  };

  private parsePostResponse = (
    apiResponse: TAPIPostResponse
  ): IEngagementPostResponse => {
    return {
      tweets: apiResponse.tweets.map((tweet) => ({
        tweetId: tweet.id.toString(),
        createdAt: tweet.created_at,
        likes: tweet.likes_count,
        retweets: tweet.retweet_count,
        isLiked: tweet.like_status,
        matchedKeywords: tweet.matched_keywords,
        text: tweet.text,
        name: tweet.name,
        username: tweet.username,
        profileImageUrl: tweet.profile_image_url,
      })),
      keywords: apiResponse.keywords,
      message: apiResponse.message,
    };
  };

  private parseDeleteResponse = (apiResponse: TAPIDeleteResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TEngagementPostPayload): TAPIPayload => {
    const parseKeywords = (keywords: TEngagementPostPayload["keywords"]) => {
      return keywords.filter((keyword) => keyword !== "");
    };
    return {
      keywords: parseKeywords(data.keywords),
      match_number: Number(data.matchNumber),
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);

  postResponse = (apiResponse: TAPIPostResponse) =>
    this.parsePostResponse(apiResponse);

  deleteResponse = (apiResponse: TAPIDeleteResponse) =>
    this.parseDeleteResponse(apiResponse);
}

export default LeadNurturingAdapter;
