import { Dropdown, Flex, Text, Toggle } from "components/core";
import { delayTimes } from "config/Twitter";
import { useToggle } from "hooks";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { setInterval } from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { QuestionIcon } from "./common.styles";

const ThreadDelay = () => {
  const [delay, toggleDelay] = useToggle();
  const dispatch = useAppDispatch();
  const interval = useAppSelector((state) => state.twitter.thread.interval);

  const handleThreadDelay = (interval: number) => {
    dispatch(setInterval(interval));
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Flex align="center" gap="small">
        <QuestionIcon data-tip="Thread Delay - Set delay time here!" />
        <Text variant="darkGrey" fontWeight="medium" content="Thread Delay" />
        <Toggle checked={delay} onChange={() => toggleDelay()} />
      </Flex>
      {delay && (
        <Dropdown
          placeholder="delay time"
          options={delayTimes}
          value={interval}
          onChange={(e, { value }) =>
            typeof value === "number" && handleThreadDelay(value)
          }
        />
      )}
    </>
  );
};

export default ThreadDelay;
