import { createContext, useEffect } from "react";
import { Loader, Spinner } from "components/common";
import { IAutoDm } from "adapters/Twitter/types";
import { autoWelcome } from "api/Twitter";
import { useNotification, useReactQuery, useTwitterAuth } from "hooks";
import { useAutodm, useFileUpload } from "hooks/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import { setAutodm } from "redux/slices/Twitter";
import {
  IAutoDMDeleteFormValues,
  IAutodmUpdateFormValues,
  IFileUploadFormValues,
} from "./types";
import { autoWelcomeLoader } from "config/Twitter";

type TAutoDmContext = {
  data: IAutoDm;
  handleUpdate: (data: IAutodmUpdateFormValues) => void;
  handleFileUpload: (values: IFileUploadFormValues) => void;
  handleDelete: (values: IAutoDMDeleteFormValues) => void;
};

const initialData: IAutoDm = {
  twitterUsername: "",
  replyText: "",
  files: [],
  enabled: false,
  tweetActions: false,
};

export const AutoDmContext = createContext<TAutoDmContext | null>(null);

const AutoDmProvider = (props: React.PropsWithChildren<{}>) => {
  const autodm = useAutodm();
  const dispatch = useAppDispatch();
  const fileUpload = useFileUpload();
  const notification = useNotification();
  const { isSignedIn, selectedAccount } = useTwitterAuth();

  const {
    data = initialData,
    execute,
    isLoading: fetching,
  } = useReactQuery(autoWelcome.fetchAutoDm, {
    onSuccess: (data) => autodm.setAutodm(data),
  });

  const handleFileUpload = (values: IFileUploadFormValues) => {
    if (values.files && Array.from(values.files).length > 0) {
      return fileUpload.upload(values.files, {
        onSuccess: async (files) => {
          dispatch(
            setAutodm({
              twitterUsername: data.twitterUsername,
              enabled: data.enabled,
              replyText: data.replyText,
              files: files,
              tweetActions: data.tweetActions,
            })
          );
        },
      });
    }
  };

  const { execute: handleUpdate, isLoading: updating } = useReactQuery(
    autoWelcome.postAutoDm,
    {
      onSuccess: (data) => {
        dispatch(setAutodm(data));
        if (data.message) {
          notification.notifySuccess(data.message);
        }
      },
    }
  );

  const { execute: handleDelete, isLoading: deleting } = useReactQuery(
    autoWelcome.deleteAutoDm,
    {
      onSuccess: (data) => {
        if (data.message) {
          dispatch(
            setAutodm({
              twitterUsername: "",
              replyText: "",
              enabled: false,
              files: [],
              tweetActions: false,
            })
          );
          notification.notifySuccess(data.message);
        }
      },
    }
  );

  useEffect(() => {
    execute({ isAuthenticated: isSignedIn });
  }, [execute, isSignedIn, selectedAccount.userName]);

  const isLoading = fetching || updating;

  return (
    <AutoDmContext.Provider
      value={{ data, handleUpdate, handleFileUpload, handleDelete }}
    >
      <Loader textData={autoWelcomeLoader} hidden={!isLoading} />
      <Spinner fullscreen hidden={!deleting} />
      {props.children}
    </AutoDmContext.Provider>
  );
};

export default AutoDmProvider;
