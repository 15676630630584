import { createContext } from "react";
import { TTrend } from "api/common/types";
import { liveTrends as api } from "api/common";
import { Loader } from "components/common";
import { liveTrends } from "config/SocialMediaPost";
import { useQuery } from "react-query";

export const LiveTrendsContext = createContext({
  google: [] as TTrend[],
  youtube: [] as TTrend[],
  twitter: [] as TTrend[],
});

const LiveTrendsProvider = (props: React.PropsWithChildren<{}>) => {
  const { data, isLoading } = useQuery(
    ["socialMediaPost", "liveTrends"],
    api.get,
    {
      staleTime: 900000, // 15 minutes
      cacheTime: 900000, // 15 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const { google = [], youtube = [], twitter = [] } = data || {};
  return (
    <LiveTrendsContext.Provider
      value={{
        google,
        youtube,
        twitter,
      }}
    >
      <Loader textData={liveTrends} hidden={!isLoading} />
      {props.children}
    </LiveTrendsContext.Provider>
  );
};

export default LiveTrendsProvider;
