import styled from "styled-components";
import { FilledHeartIcon, ReplyIcon } from "./icons";

const Container = styled.div`
  display: flex;
  gap: 0.5rem;

  svg {
    width: 16px;
    height: 16px;
  }
`;

type TDolphyEngagementTypes = {
  likes: number;
  replies: number;
};

const DolphyEngagement = ({ likes, replies }: TDolphyEngagementTypes) => {
  return (
    <Container>
      <FilledHeartIcon />
      <ReplyIcon />
    </Container>
  );
};

export default DolphyEngagement;
