import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const OuterContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-rows: 1fr 80px;
  gap: ${helper.getSpacing("small")};
  height: 100%;
`;

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${helper.getSpacing("small")};
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${helper.getSpacing("small")};
`;

export { InnerContainer, OuterContainer, Footer };
