import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { DMLeadAdapter } from "adapters/Twitter";
import { TLeadDMPayload } from "adapters/Twitter/types";
import { dolphynAPI } from "api/instances";

const URL = links.baseTwitterApiUrl.append("/tweet/dm");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new DMLeadAdapter();

const dmLead = async (values: TLeadDMPayload) => {
  try {
    if (values.isAuthenticated) {
      const payload = adapter.getPostPayload(values);
      const config = adapter.getConfig();
      const postUrl = adapter.getPostUrl(URL, values);
      const { data } = await dolphynAPI.post(postUrl, payload, config);
      return adapter.parsePostResponse(data);
    }
    const payload = adapter.getPostPayload(values);
    const postUrl = adapter.getPostUrl(URL, values);
    const { data } = await dolphynAPI.post(postUrl, payload);
    return adapter.parsePostResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default dmLead;
