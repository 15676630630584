import {
  TFollowerFilterObject,
  TFollowingFilterObject,
  TLikedFilterObject,
  TLikeFilterObject,
  TReplyFilterObject,
  TRetweetFilterObject,
  TScheduleRetweetObject,
  TTweetFilterObject,
} from "./types";

const useFilterHelper = () => {
  const sortByLikes = <T extends TLikeFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.likes < b.likes ? 1 : -1));
    return [...newArr];
  };
  const sortByRetweets = <T extends TRetweetFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.retweets < b.retweets ? 1 : -1));
    return [...newArr];
  };

  const sortByReplied = <T extends TReplyFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) =>
      a.tweetsReplied < b.tweetsReplied ? 1 : -1
    );
    return [...newArr];
  };

  const sortByLiked = <T extends TLikedFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) =>
      a.tweetsLiked < b.tweetsLiked ? 1 : -1
    );
    return [...newArr];
  };

  const sortByTweets = <T extends TTweetFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.tweets < b.tweets ? 1 : -1));
    return [...newArr];
  };

  const sortByFollowers = <T extends TFollowerFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.followers < b.followers ? 1 : -1));
    return [...newArr];
  };

  const sortByFollowings = <T extends TFollowingFilterObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.followings < b.followings ? 1 : -1));
    return [...newArr];
  };

  const posted = <T extends TScheduleRetweetObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.status > b.status ? 1 : -1));
    return [...newArr];
  };

  const scheduled = <T extends TScheduleRetweetObject>(data: T[]) => {
    const newArr = data.sort((a, b) => (a.status < b.status ? 1 : -1));
    return [...newArr];
  };

  return {
    sortByLikes,
    sortByRetweets,
    sortByLiked,
    sortByReplied,
    sortByTweets,
    sortByFollowers,
    sortByFollowings,
    posted,
    scheduled,
  };
};

export default useFilterHelper;
