import { AuthPopUpWindow } from "components/Auth";
import { links, socialMedia } from "config/SocialMediaPost";
import { AccountCard, Loader } from "components/common";
import { ReactComponent as LinkedinIcon } from "assets/icons/SocialMediaPost/social-media-post-linkedin-icon.svg";
import { useLinkedinAuth } from "hooks/Auth";

const LinkedinAccountCard = () => {
  const linkedin = useLinkedinAuth();

  return (
    <>
      <Loader textData={socialMedia} hidden={!linkedin.isLoading} />
      <AccountCard>
        <AccountCard.Logo icon={<LinkedinIcon />} />
        {linkedin.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.name}
            image={account.profileImageUrl}
            onDelete={() => linkedin.removeAccount(account.uid)}
            active={linkedin.isAccountSelected(account.uid)}
            onClick={() => linkedin.selectAccount(account.uid)}
          />
        ))}
        <AuthPopUpWindow
          trigger={
            <AccountCard.Button content="Connect" variant="success" outline />
          }
          url={links.SOCIAL_ACCOUNT_LINKEDIN_AUTH_LINK}
          expectedParam="code"
          onSuccess={(params, redirectUrl) =>
            redirectUrl && linkedin.login(redirectUrl)
          }
        />
      </AccountCard>
    </>
  );
};

export default LinkedinAccountCard;
