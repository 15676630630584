import { Button, Flex } from "components/core";
import { useAnalytics } from "hooks/Twitter";

const ExportMenu = () => {
  const { data } = useAnalytics();

  return (
    <Flex align="end">
      <Button
        size="mini"
        variant="alpha"
        content="Export as Excel"
        onClick={() => (window.location.href = `${data.filePathExcel}`)}
      />
      <Button
        size="mini"
        variant="alpha"
        content="Export as CSV"
        onClick={() => (window.location.href = `${data.filePathCsv}`)}
      />
    </Flex>
  );
};

export default ExportMenu;
