import { TPaymentPayload } from "./types";

type TAPIResponse = {
  redirect_url: string;
};

type TAPIPayload = {
  plan_id: string;
  success_url: string;
  cancel_url: string;
};

class PaymentAdapter {
  private parseResponse = (data: TAPIResponse) => ({
    redirectUrl: data.redirect_url,
  });

  getPostPayload = (values: TPaymentPayload): TAPIPayload => {
    return {
      plan_id: values.planId,
      success_url: values.successUrl,
      cancel_url: values.cancelUrl,
    };
  };

  parsePostResponse = (data: TAPIResponse) => this.parseResponse(data);
}

export default PaymentAdapter;
