import { Container, Header, Description } from "./TextSection.styles";

interface IProps
  extends React.PropsWithChildren<{
    header?: React.ReactNode;
    description?: React.ReactNode;
    [prop: string]: any;
  }> {}

const TextSection = ({ header, description, children, ...rest }: IProps) => {
  if (children) return <Container {...rest}>{children}</Container>;

  return (
    <Container {...rest}>
      <Header>{header}</Header>
      <Description>{description}</Description>
    </Container>
  );
};
TextSection.Header = Header;
TextSection.Description = Description;

export default TextSection;
