import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TMarginProps } from "theme/types";

interface IFieldProps extends TMarginProps {}

const helper = new ThemeHelper();

const Field = styled.div<IFieldProps>`
  margin-bottom: ${helper.getSpacing("small")};
  ${({ mt, ms, me, mb = "small", mx, my, margin }) =>
    helper.getMargins({
      top: mt,
      bottom: mb,
      left: ms,
      right: me,
      inline: mx,
      block: my,
      all: margin,
    })};
`;

export default Field;
