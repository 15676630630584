import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { TWebMatrixPayload } from "adapters/WebAnalytics/types";
import { WebMatrixAdapter } from "adapters/WebAnalytics";
import { constants } from "config/common";
import { links } from "config/WebAnalytics";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseWebAnalyticsApiUrl.append("/web_performance_matrix");
const adapter = new WebMatrixAdapter();

const fetchWebMatrix = async (payload: TWebMatrixPayload) => {
  try {
    const { params, headers } = await adapter.getGetPayload(payload);
    const { data } = await dolphynAPI(URL, {
      params,
      headers,
    });

    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchWebMatrix;
