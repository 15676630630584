import { throwApiError } from "errors";
import { dolphynRoot } from "api/instances";
import { links, constants } from "config/Auth";
import { AuthService } from "services/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/refresh");

const refreshDolphynToken = async () => {
  try {
    const authService = new AuthService();
    const cookies = await authService.getAsyncCookies();
    const { data } = await dolphynRoot.post(URL, {
      refresh: cookies?.refresh,
    });

    if (!("access" in data)) {
      throw new Error();
    }

    const { access } = data;

    authService.setAccessCookie(access);

    return data;
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default refreshDolphynToken;
