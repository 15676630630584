import { ParaphrasingAdapter } from "adapters/Twitter";
import { rapidAPI } from "api/instances";
import { APIError } from "errors";
import { IParaphrasingPayload } from "./types";

const error = new APIError();
const adapter = new ParaphrasingAdapter();

const paraphrasing = async (values: IParaphrasingPayload) => {
  try {
    const payload = adapter.getPostPayload(values);
    const { data } = await rapidAPI.post("/rewrite", payload);
    return adapter.getParseResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default paraphrasing;
