import { AuthFormIllustration } from "./common";
import illustration from "assets/images/Auth/signup-illustration.png";

const SignupIllustration = () => {
  return (
    <AuthFormIllustration
      header="Hello, Friend!"
      subHeader="Enter your details to dive-in to the dashboard"
      illustration={illustration}
    />
  );
};

export default SignupIllustration;
