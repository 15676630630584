import { useKeywordsComparison } from "hooks/ProductDemand";
import MultiKeywordsSearchProvider from "./MultiKeywordsSearchProvider";

const DemandByCountryFormProvider = (props: React.PropsWithChildren<{}>) => {
  const keywordsComparison = useKeywordsComparison();
  return (
    <MultiKeywordsSearchProvider onSubmit={keywordsComparison.handleSearch}>
      {props.children}
    </MultiKeywordsSearchProvider>
  );
};

export default DemandByCountryFormProvider;
