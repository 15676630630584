import { RegistrationCompletedAdapter } from "adapters/Profile";
import { dolphynAPI } from "api/instances";
import { constants, links } from "config/Profile";
import { APIError } from "errors";

const URL = links.BASE_PROFILE_API_URL.append("/registration/complete");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new RegistrationCompletedAdapter();

const registrationCompleted = async () => {
  try {
    const { data } = await dolphynAPI(URL);
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default registrationCompleted;
