import styled, { css, DefaultColors } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TSpacings } from "theme/types";

export type TContainerProps = {
  background?: keyof DefaultColors;
  padding?: keyof TSpacings;
};

const helper = new ThemeHelper();

const Container = styled.div<TContainerProps>`
  display: flex;
  align-items: center;
  gap: ${helper.getSpacing("small")};
  ${({ background }) =>
    background &&
    css`
      background-color: ${({ theme }) => theme.colors[background]};
    `}
  padding: ${({ padding }) => helper.getSpacing(padding)};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: ${helper.getSpacing("medium")};
  }
`;

const MediaContainer = styled.div`
  ${({ theme, children }) => {
    if (!children) {
      return css`
        background-color: ${theme.colors.lightGrey};
      `;
    }
  }};
  min-height: 200px;
  width: 300px;
  img {
    width: 100%;
    max-height: 400px;
  }
  iframe {
    width: 425px;
    height: 275px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    iframe {
      width: 100%;
      height: 275px;
    }
  }

  @media screen and (max-width: 425px) {
    iframe {
      width: 100%;
      height: 125px;
    }

    img {
      display: none;
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${helper.getSpacing("medium")};
  flex: 0 0 60ch;

  @media screen and (max-width: 768px) {
    flex: unset;
  }
`;

export { Container, MediaContainer, Content };
