import socialMediaSchedulerScreen from "assets/images/Home/social-media-scheduler-screen.png";
import leadGenerationScreen from "assets/images/Home/lead-generation-screen.png";
import instantReplyScreen from "assets/images/Home/instant-reply-screen.png";
import engagementScreen from "assets/images/Home/engagement-screen.png";
import { TOverviewScreensData } from "components/Home/Overview/types";

const data: TOverviewScreensData = {
  socialMediaScheduler: {
    image: socialMediaSchedulerScreen,
    url: "dolphy.io/social-media-post/calendar",
  },
  leadGeneration: {
    image: leadGenerationScreen,
    url: "dolphy.io/easy-twitter/lead-generation",
  },
  instantReply: {
    image: instantReplyScreen,
    url: "dolphy.io/easy-twitter/instant-reply",
  },
  engagementBooster: {
    image: engagementScreen,
    url: "dolphy.io/easy-twitter/engagement",
  },
};

export default data;
