import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("extraSmall")};
  margin-top: ${helper.getSpacing("extraSmall")};
  &:first-child {
    align-items: flex-start;
  }
  &:last-child {
    align-items: flex-end;
  }
`;

const Header = styled.p`
  font-family: ${({ theme }) => theme.fonts.roboto};
  ${helper.getFontSize("small")}
  color: ${({ theme }) => theme.colors.darkGrey};
  margin: unset;
`;

type TStatVariant = { variant?: "alpha" | "success" | "danger" };

const Body = styled.div<TStatVariant>`
  font-family: ${({ theme }) => theme.fonts.roboto};
  ${helper.getFontWeight("high")}
  ${helper.getFontSize("small")}
  ${({ theme, variant }) =>
    variant &&
    css`
      color: ${theme.colors[variant]};
    `};
`;

interface IStatProps extends TStatVariant {
  header?: string;
  value?: string;
}

const Stat = ({ header, variant, value, ...rest }: IStatProps) => {
  return (
    <Container {...rest}>
      <Header>{header}</Header>
      <Body variant={variant}>{value}</Body>
    </Container>
  );
};

export default Stat;
