import { TGeneralPreferences } from "./types";

type TAPIResponse = {
  user_name: string;
  occupation: string;
  referral: string;
  product_type: string;
  language: string;
  registration_status: boolean;
  visitor_id: string;
};

class GeneralPreferencesAdapter {
  private parseResponse = (data: TAPIResponse): TGeneralPreferences => {
    const generalPreferences: TGeneralPreferences = {
      occupation: data.occupation || "",
      product: data.product_type || "",
      language: data.language || "",
      referral: data.referral || "",
      visitorId: data.visitor_id,
    };

    return generalPreferences;
  };

  parseGetResponse = (data: TAPIResponse): TGeneralPreferences => {
    return this.parseResponse(data);
  };

  getPatchPayload = (payload: Partial<TGeneralPreferences>) => {
    const apiPayload: Partial<TAPIResponse> = {
      occupation: payload.occupation,
      referral: payload.referral,
      product_type: payload.product,
      language: payload.language,
    };

    return apiPayload;
  };

  parsePatchResponse = (data: TAPIResponse): TGeneralPreferences => {
    return this.parseResponse(data);
  };
}

export default GeneralPreferencesAdapter;
