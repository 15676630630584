import { useTheme } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const useThemeHelper = () => {
  const helper = new ThemeHelper();
  const theme = useTheme();
  return { theme, helper };
};

export default useThemeHelper;
