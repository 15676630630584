import { AccountCard, Spinner } from "components/common";
import { ReactComponent as TwitterIcon } from "assets/icons/SocialMediaPost/social-media-post-twitter-icon.svg";
import { useTwitterAuth } from "hooks/Auth";
import TwitterAuthPopup from "./TwitterAuthPopup";

const TwitterAccountCard = () => {
  const twitter = useTwitterAuth();

  return (
    <>
      <Spinner fullscreen hidden={!twitter.isLoading} />
      <AccountCard>
        <AccountCard.Logo icon={<TwitterIcon />} />
        {twitter.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.userName}
            image={account.profileImageUrl}
            onDelete={() => twitter.removeAccount(account.uid)}
            active={twitter.isAccountSelected(account.uid)}
            onClick={() => twitter.selectAccount(account.uid)}
          />
        ))}
        <TwitterAuthPopup>
          <AccountCard.Button content="Connect" variant="success" outline />
        </TwitterAuthPopup>
      </AccountCard>
    </>
  );
};

export default TwitterAccountCard;
