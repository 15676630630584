import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
const helper = new ThemeHelper();

const Grid = styled.div`
  display: grid;
  grid-template-rows: 50px 10fr;
  height: 100%;
`;

const Body = styled.div`
  padding: ${helper.getSpacing("small")} 0;
  overflow: auto;
  ${helper.getStyledScrollbar()}
`;

export { Grid, Body };
