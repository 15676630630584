import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${helper.getSpacing("extraSmall")};
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

export { Body, PaginationContainer };
