import { useField } from "formik";
import { useKeywordInputArray } from "hooks/common";
import { Button, Flex, KeywordInput, List } from "components/core";
import { QuestionCard } from "../common";
import ContentContainer from "../common/ContentContainer";
import illustration from "assets/images/Profile/keywords-page-illustration.png";
import { TCompleteRegistrationFormValues } from "./types";
import { businessKeywords } from "config/Profile";
import { useRegistrationSteps } from "hooks/Profile";

interface IKeywordsForm {
  name: {
    primaryKeyword: string;
    secondaryKeywords: string;
  };
}

const KeywordsForm = (props: IKeywordsForm) => {
  const [primary, , primaryHelper] = useField<
    TCompleteRegistrationFormValues["primaryKeyword"]
  >(props.name.primaryKeyword);

  const [secondary, , secondaryHelper] = useField<
    TCompleteRegistrationFormValues["secondaryKeywords"]
  >(props.name.secondaryKeywords);

  const [, , keywordsArray] = useKeywordInputArray(9);

  const steps = useRegistrationSteps();

  const renderNextStep = () => {
    if (primary.value && secondary.value.length > 0 && secondary.value[0])
      return (
        <Button
          variant="alpha"
          type="button"
          content="Next Step"
          onClick={() => steps.handleGoToNextStep()}
        />
      );
  };

  return (
    <ContentContainer>
      <ContentContainer.Header
        image={illustration}
        style={{ justifyContent: "flex-start", marginLeft: "1rem" }}
      >
        We need keywords to do Twitter Listening and Lead Generation.
      </ContentContainer.Header>
      <QuestionCard>
        <QuestionCard.Content>
          <QuestionCard.Header
            as="h1"
            size="medium"
            font="poppins"
            fontWeight="high"
            variant="darkGrey"
            textAlign="center"
            content="Business Keywords"
          />
          <Flex justify="center">
            <List
              variant="darkGrey"
              bulletVariant="darkGrey"
              spacing="none"
              size="extraSmall"
              bulleted
            >
              {businessKeywords.map((instruction) => (
                <List.Item key={instruction}>{instruction}</List.Item>
              ))}
            </List>
          </Flex>
          <KeywordInput style={{ width: "56%" }}>
            <KeywordInput.Input
              placeholder="e.g. NFT, Digital Marketing, Online Gaming, Ola, Uber, etc (✱)"
              inputNumber="1"
              value={primary.value}
              onChange={(e) => primaryHelper.setValue(e.target.value)}
            />
            <KeywordInput.InputArray
              value={secondary.value}
              numberingOffset={1}
              onChange={(e, index) => {
                const value = keywordsArray.getChangedKeywordsArray(
                  secondary.value,
                  index,
                  e.target.value
                );
                secondaryHelper.setValue(
                  value as TCompleteRegistrationFormValues["secondaryKeywords"]
                );
              }}
              length={9}
              inputProps={(inputIndex) => {
                if (inputIndex === 1) {
                  return {
                    placeholder:
                      "e.g. Old Cars, Survey Sending, Stocks buying, Pre-sale NFT, etc (✱)",
                  };
                } else if (inputIndex === 2) {
                  return {
                    placeholder:
                      "e.g. Shipment Tracking, Shopify Developer, Watched movies, etc",
                  };
                } else if (inputIndex === 3) {
                  return {
                    placeholder:
                      "e.g. Amazon complaints, working tirelessly, Work Stress, etc",
                  };
                } else if (inputIndex === 4) {
                  return {
                    placeholder:
                      "e.g. Old Cars, Survey Sending, Stocks buying, Pre-sale NFT, etc",
                  };
                } else if (inputIndex === 5) {
                  return {
                    placeholder:
                      "e.g. Shipment Tracking, Shopify Developer, Watched movies, etc",
                  };
                } else if (inputIndex === 6) {
                  return {
                    placeholder:
                      "e.g. Amazon complaints, working tirelessly, Work Stress, etc",
                  };
                } else if (inputIndex === 4) {
                  return {
                    placeholder:
                      "e.g. Old Cars, Survey Sending, Stocks buying, Pre-sale NFT, etc",
                  };
                } else if (inputIndex === 8) {
                  return {
                    placeholder:
                      "e.g. Shipment Tracking, Shopify Developer, Watched movies, etc",
                  };
                } else {
                  return {
                    placeholder:
                      "e.g. Semrush, Hubspot, Salesforce, Amul, Rail Sewa, etc",
                  };
                }
              }}
            />
          </KeywordInput>
          {renderNextStep()}
        </QuestionCard.Content>
      </QuestionCard>
    </ContentContainer>
  );
};

export default KeywordsForm;
