import styled from "styled-components";

const Container = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export { Container };
