import { Container as BaseContainer } from "semantic-ui-react";
import styled from "styled-components";

const Container = styled(BaseContainer)`
  &&& {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 2rem 0;
    border-right: 1px solid;
    border-color: ${({ theme }) => theme.colors.alpha};
  }
`;

export default Container;
