import { useField } from "formik";
import { MultiCheckDropdown } from "components/common";
import { useToggle } from "hooks/common";
import { IScheduleCreateFormValues } from "./types";
import { useInstagramPages, usePlatformDropdown } from "hooks/SocialMediaPost";

interface ISelectInstagramProps {
  name: string;
}

const SelectInstagram = (props: ISelectInstagramProps) => {
  const [field, , helpers] = useField<IScheduleCreateFormValues["instagram"]>(
    props.name
  );

  const [open, setOpen] = useToggle();
  const instagram = useInstagramPages();
  const dropdown = usePlatformDropdown();

  return (
    <MultiCheckDropdown
      open={open}
      handleToggle={() => setOpen(true)}
      onOutsideClick={() => setOpen(false)}
      placeholder="Instagram"
      options={instagram.options}
      value={field.value}
      onChange={(selectedPage) => {
        const pages = dropdown.getOnChangeFieldValues(
          field.value,
          selectedPage
        );
        helpers.setValue(pages);
      }}
      onSubmit={() => setOpen(false)}
    />
  );
};

export default SelectInstagram;
