import { Header } from "components/core";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div``;

const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  height: 65vh;
  max-height: 500px;
  overflow: auto;
  padding: ${helper.getSpacing("small")};
  ${helper.getStyledScrollbar()}
`;

type TCardObject = {
  id: string | number;
  title: string;
  value?: string;
};

interface ICardProps {
  header: string;
  data: TCardObject[];
}

const Card = ({ header, data }: ICardProps) => {
  return (
    <Container>
      <Header
        as="div"
        font="poppins"
        variant="darkGrey"
        content={header}
        mb="small"
      />
      <CardContainer>
        <Table basic="very" celled>
          <Table.Body>
            {data.map((row, key) => {
              return (
                <Table.Row key={key}>
                  <Table.Cell>{row.title}</Table.Cell>
                  {row?.value && <Table.Cell>{row.value}</Table.Cell>}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </CardContainer>
    </Container>
  );
};

export default Card;
