import { loginFacebook as authApi } from "api/Auth";
import * as facebook from "redux/slices/Auth/facebook";
import * as facebookSelection from "redux/slices/Auth/facebookSelection";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { useReactQuery } from "hooks/common";

const useFacebookAuth = () => {
  const dispatch = useAppDispatch();
  const reInitialize = () => {
    dispatch(facebook.reInitialize());
    dispatch(facebookSelection.reInitialize());
  };
  const { execute: login, isLoading } = useReactQuery(authApi, {
    onSuccess: () => {
      reInitialize();
    },
  });

  /* 
    States
  */

  const isSignedIn = useAppSelector((state) => state.auth.facebook.isSignedIn);
  const accounts = useAppSelector((state) => state.auth.facebook.accounts);
  const selectedAccount = useAppSelector(
    (state) => state.auth.facebookSelection.selectedAccount
  );
  const isAccountSelected = (uid: facebook.TUid) => selectedAccount.uid === uid;

  /* 
    Handlers
  */

  const removeAccount = (uid: facebook.TUid) => {
    dispatch(facebook.removeAccount(uid));
    dispatch(facebookSelection.resetSelection());
  };

  const selectAccount = (uid: string) => {
    dispatch(facebookSelection.selectAccount(uid));
  };

  const clearData = () => {
    dispatch(facebook.clearData());
    dispatch(facebookSelection.resetSelection());
  };

  return {
    isSignedIn,
    isLoading,
    login,
    accounts,
    selectedAccount,
    isAccountSelected,
    removeAccount,
    selectAccount,
    clearData,
  };
};

export default useFacebookAuth;
