import { dolphynAPI } from "api/instances";
import { APIError } from "errors";
import { constants } from "config/common";
import { TPageMatrixPagesPayload } from "adapters/WebAnalytics/types";
import { PageMatrixPagesAdapter } from "adapters/WebAnalytics";
import { links } from "config/WebAnalytics";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseWebAnalyticsApiUrl.append("/page_list");
const adapter = new PageMatrixPagesAdapter();

const fetchPageMatrixPages = async (payload: TPageMatrixPagesPayload) => {
  try {
    const { params, headers } = await adapter.getGetPayload(payload);

    const { data } = await dolphynAPI(URL, {
      params,
      headers,
    });
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchPageMatrixPages;
