import axios from "axios";
import { configureAuthHeaders } from "helpers";

const { REACT_APP_BACKEND_API_URL } = process.env;

const dolphyn = axios.create({
  baseURL: REACT_APP_BACKEND_API_URL,
});

dolphyn.interceptors.request.use(
  (config) => configureAuthHeaders(config),
  (error) => {
    return Promise.reject(error);
  }
);

export default dolphyn;
