import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: ${helper.getSpacing("small")};
`;

const Image = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export { Container, Image, Details };
