import CollectionHelper, { TBaseCollection } from "helpers/collectionHelper";

const useCollection = <T extends TBaseCollection>(options: T[]) => {
  const helper = new CollectionHelper<T>();

  const getItem = (id: T["id"]) => helper.getItem(options, id);
  const isItemPresent = (id: T["id"]) => helper.isItemPresent(options, id);
  const addItem = (id: T) => helper.addItem(options, id);
  const removeItem = (id: T["id"]) => helper.removeItem(options, id);
  const updateItem = (id: T) => helper.updateItem(options, id);

  return {
    getItem,
    isItemPresent,
    addItem,
    removeItem,
    updateItem,
  };
};

export default useCollection;
