import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import { BotIcon } from "../common/icons";
import { IconContainer } from "./Phrase.styles";
import { useParaphrasing } from "hooks/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setTweetText } from "redux/slices/Twitter";
import { CustomSpinner } from "components/common";

const Phrase = () => {
  const { isLoading, execute } = useParaphrasing();
  const dispatch = useAppDispatch();
  const currentMessage = useAppSelector(
    (state) => state.twitter.thread.tweetText
  );

  const handleParaphrasing = () => {
    execute(
      { text: currentMessage },
      {
        onSuccess: (data) => {
          if (data) dispatch(setTweetText(data.phrasedMessage));
        },
      }
    );
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <IconContainer
      data-tip="The AI will try to re-write whatever you've written so far."
      onClick={handleParaphrasing}
    >
      {isLoading ? (
        <CustomSpinner size={16} variant="alpha" />
      ) : (
        <BotIcon width={24} height={24} />
      )}
    </IconContainer>
  );
};

export default Phrase;
