import { useReducer } from "react";
import { postReducer } from "reducers/SocialMediaPost";
import { TBaseSocialMediaPost } from "adapters/SocialMediaPost/types";

const usePostReducer = <T extends TBaseSocialMediaPost>(
  initialStateData: T[]
) => {
  const initialState: postReducer.TState<T> = {
    data: initialStateData,
  };
  const [state, dispatch] = useReducer(
    (state: postReducer.TState<T>, action: postReducer.TAction<T>) =>
      postReducer.reducer<T>(state, action),
    initialState
  );
  return {
    actions: postReducer.Actions,
    state,
    dispatch,
  };
};

export default usePostReducer;
