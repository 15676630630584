import styled from "styled-components";

const BannerWrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

const SearchLink = styled.div`
  background-color: ${({ theme }) => theme.colors.extraLightGrey};
  padding: 0.5rem 1rem;
  border-radius: 12px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: ltr;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem 1rem;
    border-radius: 16px;
    width: 100%;

    span {
      font-size: 12px;
    }
  }
`;

const LeftIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const RightIcons = styled(LeftIcons)`
  gap: 1rem;
`;

export { BannerWrapper, SearchLink, LeftIcons, RightIcons };
