import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div``;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${helper.getSpacing("large")};
  margin-top: ${helper.getSpacing("small")};
`;

const Body = styled.div`
  padding-inline: ${helper.getSpacing("large")};
  padding-top: ${helper.getSpacing("small")};
`;

export { TopSection, Body, Container };
