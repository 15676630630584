import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import ProfileActions from "./ProfileActions";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: 0 2rem;
`;

const Content = styled.div`
  padding: 1rem;
  height: 80%;
  overflow: auto;
  ${helper.getStyledScrollbar()}
  &&& form {
    padding: unset;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  &&& .ui.dropdown {
    height: 50px;
    border: unset;
  }
`;

const ProfileSection = (props: React.PropsWithChildren<{}>) => {
  return <Container>{props.children}</Container>;
};

ProfileSection.Actions = ProfileActions;
ProfileSection.Content = Content;

export default ProfileSection;
