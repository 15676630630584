import dolphynPublicRoot from "api/instances/dolphynPublicRoot";
import { BASIC_AUTH_HEADER_VALUE } from "config/common/constants";
import { format } from "date-fns";
import { throwApiError } from "errors";

const URL = "/api/trends/v1/public/product_search_demand_basic_auth";

const defaultError = "Unable to process the request!";

const headers = {
  Authorization: BASIC_AUTH_HEADER_VALUE,
};

export type TDemandGraph = {
  date: Date;
  scale: number;
};

export type ISearchDemandData = TDemandGraph[];

export interface ISearchKeywords {
  country: string;
  keyword: string;
}

const fetchSearchDemand = async ({ country, keyword }: ISearchKeywords) => {
  const params = {
    country,
    keyword,
  };

  try {
    const { data: searchDemandGraphData } = await dolphynPublicRoot(URL, {
      headers,
      params,
    });

    const searchDemandGraph = searchDemandGraphData.demand_graph
      .map(({ date, scale }: TDemandGraph) => ({
        date: format(new Date(date), "MMM yy"),
        scale,
      }))
      .slice(1, 20);

    return searchDemandGraph as ISearchDemandData;
  } catch (e) {
    throwApiError(e, defaultError);
  }
};

export default fetchSearchDemand;
