import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Button } from "components/core";
import { applicationRoutes as routes } from "config/common";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => helper.transparentize(theme.colors.beta)};
`;

interface ITrialButton {
  hidden?: boolean;
}

const TrialButton = (props: ITrialButton) => {
  const history = useHistory();

  if (props.hidden) return null;

  return (
    <Container>
      <Button
        variant="alpha"
        content="Sign up for Free Trial"
        onClick={() => {
          history.push(routes.rootRoutes.signUp);
        }}
      />
    </Container>
  );
};

export default TrialButton;
