import { textKeys } from "config/Twitter";
import { createLocalStorage, getLocalStorage } from "helpers";

type TDefaultReturns = {
  text1Message: string;
  text2Message: string;
  text3Message: string;
  text4Message: string;
  setTextMessage: <T>(key: string, value: T) => void;
};

const useTextHelper = () => {
  const text1Message = getLocalStorage(textKeys.Text1) || "";
  const text2Message = getLocalStorage(textKeys.Text2) || "";
  const text3Message = getLocalStorage(textKeys.Text3) || "";
  const text4Message = getLocalStorage(textKeys.Text4) || "";

  const setTextMessage = <T>(key: string, value: T) => {
    createLocalStorage(key, value);
  };

  return {
    text1Message,
    text2Message,
    text3Message,
    text4Message,
    setTextMessage,
  } as TDefaultReturns;
};

export default useTextHelper;
