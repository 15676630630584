import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TwitterService, { TUid } from "services/Auth/twitterService";

const service = new TwitterService();

const selectedAccount = service.getSelectedAccount();

const initialState = {
  uid: selectedAccount.uid,
  selectedAccount: selectedAccount,
};

export const twitterSelection = createSlice({
  name: "twitterSelection",
  initialState,
  reducers: {
    reInitialize: (state) => {
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
    },
    selectAccount: (state, action: PayloadAction<TUid>) => {
      service.setSelectedAccount(action.payload);
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
    },
    resetSelection: (state) => {
      service.removeSelectedAccount();
      const selectedAccount = service.getSelectedAccount();
      state.selectedAccount = selectedAccount;
      state.uid = selectedAccount.uid;
    },
  },
});

export const { reInitialize, selectAccount, resetSelection } =
  twitterSelection.actions;

export type { TUid } from "services/Auth/twitterService";
export default twitterSelection.reducer;
