import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAllPlans } from "adapters/Pricing/types";

const initialState = {
  allPlans: [] as TAllPlans[],
};

export const couponSystem = createSlice({
  name: "couponSystem",
  initialState,
  reducers: {
    setAllPlans: (state, action: PayloadAction<TAllPlans[]>) => {
      return {
        allPlans: action.payload,
      };
    },
  },
});

export const { setAllPlans } = couponSystem.actions;

export default couponSystem.reducer;
