import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  height: 80%;
  overflow: auto;
  ${helper.getStyledScrollbar()}
`;

const Body = styled.div`
  height: 85%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
`;

const WeekContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  position: sticky;
  top: 15px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const WeekBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-collapse: none;
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: center;
  ${helper.getFontSize("small")}
  ${helper.getFontWeight("medium")}
`;

const TimeBox = styled.div`
  height: calc(50px + (5vh + 5vw) / 10);
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-collapse: none;
  overflow: auto;
  color: ${({ theme }) => theme.colors.darkGrey};
  ${helper.getFontSize("small")}
  ${helper.getStyledScrollbar()}
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

const HeaderBox = styled.div`
  text-align: center;
  ${helper.getFontWeight("medium")}
  ${helper.getFontSize("small")}
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export {
  Container,
  Body,
  WeekContainer,
  WeekBox,
  TimeBox,
  HeaderContainer,
  HeaderBox,
};
