import { AdminEngagementAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/Twitter";
import { APIError } from "errors";
import { IAdminEngagementPayload } from "./types";

const URL = links.baseTwitterAdminApiUrl.append("/engagement");
const error = new APIError();
const adapter = new AdminEngagementAdapter();

const adminEngagement = {
  get: async () => {
    try {
      const headers = adapter.getConfig();
      const { data } = await dolphynAPI(URL, headers);
      return adapter.getParseResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  post: async (values: IAdminEngagementPayload) => {
    try {
      const headers = adapter.getConfig();
      const apiPayload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, apiPayload, headers);
      return adapter.getParseResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  delete: async () => {
    try {
      const headers = adapter.getConfig();
      const { data } = await dolphynAPI.delete(URL, headers);
      return adapter.getDeleteResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default adminEngagement;
