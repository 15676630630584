import { TwitterService } from "services/Auth";
import { TInfluencer } from "./types";

type TAPIPayload = {
  potential_customer: boolean;
  potential_lead: boolean;
  influencer: boolean;
};

type TAPIResponse = {
  message: string;
};

class RemoveInfluencerAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    if (apiResponse.message) {
      return {
        message: apiResponse.message,
      };
    }
  };

  getPostUrl = (url: string, tweetCard: TInfluencer) =>
    `${url}/${tweetCard.leadUsername}`;

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TInfluencer): TAPIPayload => {
    return {
      potential_customer: data.potentialCustomer,
      potential_lead: false,
      influencer: true,
    };
  };

  parseRemoveResponse = this.parseResponse;
}

export default RemoveInfluencerAdapter;
