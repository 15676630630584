import { dolphynAPI } from "api/instances";
import { links } from "config/Profile";
import { APIError } from "errors";
import { TInviteEnterprisePayload } from "adapters/Profile/types";
import { InviteEnterpriseAdapter } from "adapters/Profile";

const error = new APIError();
const URL = links.BASE_PROFILE_API_URL.append("/enterprise/invite");
const adapter = new InviteEnterpriseAdapter();

const inviteEnterprise = async (payload: TInviteEnterprisePayload) => {
  try {
    const apiPayload = adapter.getPostPayload(payload);
    const { data } = await dolphynAPI.post(URL, apiPayload);
    return adapter.getResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default inviteEnterprise;
