import { Redirect } from "react-router";
import { applicationRoutes as routes } from "config/common";
import SimpleRoute, { ISimpleRouteProps } from "controllers/common/SimpleRoute";
import { useAppSelector } from "redux/store/hooks";

interface ICompleteRegistrationRouteProps extends ISimpleRouteProps {}

const CompleteRegistrationRoute = ({
  children,
  ...props
}: ICompleteRegistrationRouteProps) => {
  const registered = useAppSelector(
    (state) => state.profile.registrationStatus.completed
  );

  if (registered) {
    return <Redirect to={routes.rootRoutes.contentCreation} />;
  }

  return <SimpleRoute {...props}>{children}</SimpleRoute>;
};

export default CompleteRegistrationRoute;
