class CreateError extends Error {
  displayMessage: string;
  constructor(message: string) {
    super(message);
    this.displayMessage = message;
  }
  update = (message: string) => {
    this.displayMessage = message;
  };
}

export default CreateError;
