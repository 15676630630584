import { Header } from "components/core";
import { MultipleAccounts } from "components/Twitter/common";
import ContentCreationLayout from "./ContentCreationLayout";

interface ILayoutHeaderProps {
  header: string;
}

const LayoutHeader: React.FC<ILayoutHeaderProps> = (props) => {
  return (
    <ContentCreationLayout.TopSection>
      <Header
        font="playfair"
        variant="darkGrey"
        size="medium"
        content={props.header}
      />
      <MultipleAccounts />
    </ContentCreationLayout.TopSection>
  );
};

export default LayoutHeader;
