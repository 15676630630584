import { TInviteEnterprisePayload } from "./types";

type TAPIResponse = {
  message: string;
};

class InviteEnterpriseAdapter {
  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      message: apiResponse.message,
    };
  };

  getPostPayload = (data: TInviteEnterprisePayload) => {
    const users = [
      {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
      },
    ];
    return {
      users,
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default InviteEnterpriseAdapter;
