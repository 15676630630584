import { Form } from "components/core";
import { TSecurityDetails } from "adapters/Profile/types";
import { telephoneCodeList } from "config/common";

interface ITwoFactorAuthFormProps {
  editable: boolean;
  formId: string;
  values: TSecurityDetails;
  onSubmit: () => void;
}

const TwoFactorAuthForm = ({
  editable,
  formId,
  onSubmit,
  values,
}: ITwoFactorAuthFormProps) => {
  return (
    <Form>
      <Form.Form id={formId} onSubmit={onSubmit}>
        <Form.Field>
          <Form.Label content="Two-Factor Authentication" />
          <Form.Toggle
            variant="alpha"
            name="twoFactorAuth"
            readOnly={!editable}
          />
        </Form.Field>
        {values.twoFactorAuth && (
          <>
            <Form.Field>
              <Form.Label content="Country Code" />
              <Form.DropdownField
                glow
                placeholder="Country Code"
                name="countryCode"
                options={telephoneCodeList}
              />
            </Form.Field>
            <Form.Field>
              <Form.Label content="Phone number" />
              <Form.Input
                focused
                name="phoneNumber"
                type="text"
                readOnly={!editable}
              />
            </Form.Field>
          </>
        )}
      </Form.Form>
    </Form>
  );
};

export default TwoFactorAuthForm;
