import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Profile";
import { GeneralPreferencesAdapter } from "adapters/Profile";
import { TGeneralPreferences } from "adapters/Profile/types";

const URL = links.BASE_PROFILE_API_URL.append("/user_details");
const adapter = new GeneralPreferencesAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR);

const generalPreferences = {
  get: async () => {
    try {
      const { data } = await dolphynAPI(URL);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  patch: async (values: Partial<TGeneralPreferences>) => {
    const payload = adapter.getPatchPayload(values);
    try {
      const response = await dolphynAPI.patch(URL, payload);

      return adapter.parsePatchResponse(response.data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default generalPreferences;
