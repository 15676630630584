import { v4 as uuid } from "uuid";
import { Text } from "components/core";
import { usePostScheduler } from "hooks/Twitter";
import { ListContianer } from "./ScheduledList.styles";
import ScheduleThread from "./ScheduleThread";

const ScheduledList = () => {
  const { scheduledList } = usePostScheduler();

  if (!scheduledList.length)
    return (
      <Text
        variant="alpha"
        content="No Scheduled Tweets or Threads"
        style={{ textAlign: "center" }}
      />
    );

  return (
    <ListContianer>
      {scheduledList.map((tweet) => (
        <ScheduleThread key={uuid()} threads={tweet.threads} />
      ))}
    </ListContianer>
  );
};

export default ScheduledList;
