import { createContext, useEffect, useState } from "react";
import { Loader } from "components/common";
import { useReactQuery, useNotification } from "hooks/common";
import { useGeneralPreferences, useKeywordsDetails } from "hooks/Profile";
import {
  useAddInfluencer,
  useAddPotential,
  useFilterHelper,
  useLikeTweet,
  useRecentTweetRedux,
  useRecentTweets,
  useRecommendation,
  useReplyHelper,
  useRetweet,
  useTweetHelper,
  useKeywordListeningScheduleRetweet,
} from "hooks/Twitter";
import { keywordListening } from "api/Twitter";
import {
  Dictionary,
  IRecentTweetsPayload,
  ITweetSearchData,
  ITweetSearchPayload,
  TTweetSearchCard,
} from "adapters/Twitter/types";
import {
  IAdvancedSearchFormValues,
  IRetweetFormValues,
  IScheduleRetwetFormValues,
  ITweetReplyFormValues,
  ITweetSearchDMFormValues,
} from "./types";
import { keywordsSearch, recentTweetMessage } from "config/Twitter";
import { getWithExpiry, setWithExpiry } from "helpers";
import { useBoolean, useTwitterAuth } from "hooks";

type TKeywordListeningContext = {
  cards: ITweetSearchData["cards"];
  tweetActions: ITweetSearchData["tweetActions"];
  isTwitterConnected: ITweetSearchData["isTwitterConnected"];
  handleSearch: (data: ITweetSearchPayload) => void;
  handleInfluencer: (values: TTweetSearchCard) => void;
  handlePotential: (values: TTweetSearchCard) => void;
  handleLikeTweet: (data: TTweetSearchCard) => void;
  handleReply: (values: ITweetReplyFormValues) => void;
  handleRetweet: (values: IRetweetFormValues) => void;
  handleScheduleRetweet: (values: IScheduleRetwetFormValues) => void;
  handleFilter: (value: string) => void;
  handleRemove: (data: TTweetSearchCard) => void;
  handleLoadMore: () => void;
  handleDM: (values: ITweetSearchDMFormValues) => void;
  handleLeadTweets: (data: IRecentTweetsPayload) => void;
  handleConnectTwitter: (value: boolean) => void;
  handleAdvancedSearch: (formValues: IAdvancedSearchFormValues) => void;
};

export const KeywordListeningContext =
  createContext<TKeywordListeningContext | null>(null);

const RETWEET_SUCCESS_MESSAGE = "Retweeted Successfully";

const KeywordListeningProvider = (props: React.PropsWithChildren<{}>) => {
  const [initialized, setInitializedTrue, setInitializedFalse] = useBoolean();
  const [cards, setCards] = useState<ITweetSearchData["cards"]>([]);
  const [tweetActions, setTweetActions] =
    useState<ITweetSearchData["tweetActions"]>(false);
  const [isTwitterConnected, setTwitterConnected] =
    useState<ITweetSearchData["isTwitterConnected"]>(false);
  const recommendation = useRecommendation();
  const { isSignedIn, selectedAccount } = useTwitterAuth();
  const keywordsDetails = useKeywordsDetails();
  const generalPreferences = useGeneralPreferences();
  const likeTweet = useLikeTweet();
  const addInfluecer = useAddInfluencer();
  const addPotential = useAddPotential();
  const tweetHelper = useTweetHelper();
  const replyHelper = useReplyHelper();
  const retweet = useRetweet();
  const tweetSearchScheduleRetweet = useKeywordListeningScheduleRetweet();
  const filter = useFilterHelper();
  const recentTweets = useRecentTweets();
  const recentTweetRedux = useRecentTweetRedux();
  const notification = useNotification();

  const [currentKeywords, setKewords] = useState([
    keywordsDetails.details.primaryKeyword,
    ...keywordsDetails.details.secondaryKeywords,
  ]);
  const [currentLanguage, setLanguage] = useState(
    generalPreferences.details.language
  );

  const { execute, isLoading } = useReactQuery(keywordListening, {
    onSuccess: (data) => {
      recommendation.setRecommendation(data.recommendation);
      setCards((current) =>
        filter.sortByLikes([...new Set([...current, ...data.cards])])
      );
      setTweetActions(data.tweetActions);
      setTwitterConnected(data.isTwitterConnected);
      Object.entries(data.offset).forEach(([key, value]) => {
        setWithExpiry(key, value, 86436000);
      });
    },
  });

  useEffect(() => {
    var searchOffest: Dictionary<number> = {};
    currentKeywords.forEach((keyword) => {
      const item = getWithExpiry<number>(keyword.toLowerCase());
      if (item) {
        searchOffest[keyword] = item;
      }
    });

    if (!initialized) {
      execute({
        keywords: currentKeywords,
        language: currentLanguage,
        offset: searchOffest,
        isAuthenticated: isSignedIn,
      });
      setInitializedTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, selectedAccount.userName, initialized]);

  const handleSearch = (data: ITweetSearchPayload) => {
    var searchOffest: Dictionary<number> = {};
    data.keywords.forEach((keyword) => {
      const item = getWithExpiry<number>(keyword.toLowerCase());
      if (item) {
        searchOffest[keyword] = item;
      }
    });
    setCards([]);
    if (
      currentKeywords === data.keywords &&
      currentLanguage === data.language
    ) {
      execute(
        {
          keywords: currentKeywords,
          language: currentLanguage,
          offset: searchOffest,
          isAuthenticated: isSignedIn,
        },
        {
          onSuccess: (data) => {
            if (data) {
              setCards(data.cards);
            }
          },
        }
      );
    } else {
      setKewords(data.keywords);
      setLanguage(data.language);
      setInitializedFalse();
    }
  };

  const handleAdvancedSearch = (filterValues: IAdvancedSearchFormValues) => {
    var searchOffest: Dictionary<number> = {};
    currentKeywords.forEach((keyword) => {
      const item = getWithExpiry<number>(keyword.toLowerCase());
      if (item) {
        searchOffest[keyword] = item;
      }
    });
    execute({
      keywords: currentKeywords,
      language: currentLanguage,
      offset: searchOffest,
      isAuthenticated: isSignedIn,
      filters: filterValues,
    });
  };

  const handleRemove = (data: TTweetSearchCard) => {
    setCards((current) => tweetHelper.removeFromList(current, data));
  };

  const handleFilter = (value: string) => {
    if (value === "likes") setCards((current) => filter.sortByLikes(current));
    if (value === "retweets")
      setCards((current) => filter.sortByRetweets(current));
  };

  const handleLikeTweet = (tweet: TTweetSearchCard) => {
    likeTweet.execute({ tweetId: tweet.tweetId, isLiked: tweet.isLiked });
    const updatedTweet = {
      ...tweet,
      likes: tweet.isLiked ? tweet.likes - 1 : tweet.likes + 1,
      isLiked: !tweet.isLiked,
    };
    setCards((current) => tweetHelper.findAndReplace(current, updatedTweet));
  };

  const handleReply = (values: ITweetReplyFormValues) => {
    replyHelper.handleReply(values);
  };

  const handleRetweet = (values: IRetweetFormValues) => {
    retweet.execute(values, {
      onSuccess: (data) => {
        if (data?.retweet) {
          const tweet = cards.find((tweet) => tweet.tweetId === values.tweetId);
          if (tweet) {
            const updatedTweet = { ...tweet, retweets: tweet?.retweets + 1 };
            setCards((current) =>
              tweetHelper.findAndReplace(current, updatedTweet)
            );
          }
        }
        notification.notifySuccess(RETWEET_SUCCESS_MESSAGE);
      },
    });
  };

  const handleScheduleRetweet = (values: IScheduleRetwetFormValues) =>
    tweetSearchScheduleRetweet.execute(values, {
      onSuccess: (data) => {
        if (data) {
          notification.notifySuccess(data.message);
        }
      },
    });

  const handleLoadMore = () => setInitializedFalse();

  const handleInfluencer = (values: TTweetSearchCard) =>
    addInfluecer.execute(
      {
        leadUsername: values.username,
        isAuthenticated: isSignedIn,
        word: values.keywords[0],
      },
      {
        onSuccess: (message) => {
          if (message) {
            notification.notifySuccess(message.message);
          }
        },
      }
    );

  const handlePotential = (values: TTweetSearchCard) =>
    addPotential.execute(
      {
        leadUsername: values.username,
        isAuthenticated: isSignedIn,
        word: values.keywords[0],
      },
      {
        onSuccess: (message) => {
          if (message) {
            notification.notifySuccess(message.message);
          }
        },
      }
    );

  const handleLeadTweets = (data: IRecentTweetsPayload) => {
    recentTweets.execute(data, {
      onSuccess: (response) => {
        if (response?.leadTweets) {
          recentTweetRedux.setRecentTweets(response.leadTweets);
        } else {
          notification.notifyInfo(response!.message);
        }
      },
    });
  };

  const handleDM = (values: ITweetSearchDMFormValues) => {
    replyHelper.handleDM({ leadUsername: values.username, ...values });
  };

  const handleConnectTwitter = (value: boolean) => {
    setTwitterConnected(value);
  };

  return (
    <KeywordListeningContext.Provider
      value={{
        cards,
        handleDM,
        tweetActions,
        isTwitterConnected,
        handleSearch,
        handleLikeTweet,
        handleReply,
        handleRetweet,
        handleFilter,
        handleRemove,
        handleLoadMore,
        handleScheduleRetweet,
        handleInfluencer,
        handlePotential,
        handleLeadTweets,
        handleConnectTwitter,
        handleAdvancedSearch,
      }}
    >
      <Loader textData={keywordsSearch} hidden={!isLoading} />
      <Loader textData={recentTweetMessage} hidden={!recentTweets.isLoading} />
      {props.children}
    </KeywordListeningContext.Provider>
  );
};

export default KeywordListeningProvider;
