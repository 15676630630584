import { useState } from "react";
import { useCollection } from "hooks/common";
import { TBaseCollection } from "helpers/collectionHelper";

type TConfig = {
  selectAllOnIntialize?: boolean;
};

const useMultiCheckDropdown = <T extends TBaseCollection>(
  options: T[],
  config?: TConfig
) => {
  const initializeSelection = () => {
    if (config?.selectAllOnIntialize) return options;
    return [];
  };
  const [selection, setSelection] = useState<T[]>(initializeSelection());
  const optionsBag = useCollection(options);
  const selectionBag = useCollection(selection);
  const areAllSelected =
    selection.length !== 0 && options.length === selection.length;

  const handleToggleSelectAll = () => {
    areAllSelected ? setSelection([]) : setSelection(options);
  };

  const handleSelectAll = () => {
    setSelection(options);
  };

  const handleDeselectAll = () => {
    setSelection([]);
  };

  const handleSelectItem = (id: T["id"]) => {
    setSelection((current) => {
      const currentOption = optionsBag.getItem(id);
      const isOptionInCurrent = selectionBag.isItemPresent(id);
      if (isOptionInCurrent) return selectionBag.removeItem(id);
      if (currentOption) return selectionBag.addItem(currentOption);
      return current;
    });
  };

  const isItemSelected = (id: T["id"]) => selectionBag.isItemPresent(id);

  return {
    options,
    areAllSelected,
    selection,
    handleToggleSelectAll,
    handleSelectAll,
    handleDeselectAll,
    handleSelectItem,
    isItemSelected,
  };
};

export default useMultiCheckDropdown;
