import { GraphHelper } from "helpers";
import {
  ISearchDemandForecastData,
  TGraphMeta,
  TSearchDemandForecastPayload,
} from "./types";

type TAPIResponse = {
  forecast_data: {
    date: string;
    scale: number;
  }[];
};

type TAPIPayload = {
  country: string;
  keyword: string;
};

class SearchDemandForecastAdapter {
  private graphHelper = new GraphHelper();
  private getGraphMetaData = (payload: Record<string, any>[]) => {
    const maxValue = this.graphHelper.findHighestValue(payload);
    const maxAxisValue = this.graphHelper.converToMultiple(maxValue);
    const data: TGraphMeta = {
      maxAxisValue,
    };
    return data;
  };

  private parseResponse = (
    apiResponse: TAPIResponse
  ): ISearchDemandForecastData => {
    return {
      graph: apiResponse.forecast_data,
      graphMeta: this.getGraphMetaData(apiResponse.forecast_data),
    };
  };

  getGetPayload = (data: TSearchDemandForecastPayload): TAPIPayload => {
    return {
      country: data.country,
      keyword: data.keyword,
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default SearchDemandForecastAdapter;
