import {
  IDemandByCountryData,
  TRecommendation,
  TDemandByCountryPayload,
} from "./types";

type TAPIResponse = {
  country_graph: {
    country_name: string;
    country: string;
    value: number;
  }[];
  recommendations: TRecommendation[];
};

type TAPIPayload = {
  start_date: string;
  end_date: string;
  keyword: string;
};

class DemandByCountryAdapter {
  private parseResponse = (apiResponse: TAPIResponse): IDemandByCountryData => {
    const worldMap = apiResponse.country_graph.map((entry) => ({
      country: entry.country_name,
      countryCode: entry.country,
      value: entry.value,
    }));
    return {
      worldMap,
      recommendation: apiResponse.recommendations,
    };
  };

  getGetPayload = (data: TDemandByCountryPayload): TAPIPayload => {
    return {
      keyword: data.keyword,
      start_date: data.startDate.toISOString(),
      end_date: data.endDate.toISOString(),
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default DemandByCountryAdapter;
