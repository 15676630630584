import moment from "moment";
import { Fragment } from "react";
import { Linkify } from "components/common";
import { Text } from "components/core";
import { DateTimeHelper } from "helpers";
import { TwitterService } from "services/Auth";
import {
  Bottom,
  RemoveIcon,
  StyledImage,
  FileContainer,
  FilesPreview,
  ImageContainer,
  TweetText,
  User,
  Header,
  Content,
  Container,
  Line,
  Image,
} from "./SentTweetThread.styles";
import { usePostScheduler } from "hooks/Twitter";

type TSentTweetThreadProps = {
  thread: any;
  showLine: boolean;
};

const twitterService = new TwitterService();
const datetimeHelper = new DateTimeHelper();

const SentTweetThread = ({ thread, showLine }: TSentTweetThreadProps) => {
  const selectedAccount = twitterService.getSelectedAccount();
  const { handleDeleteThread } = usePostScheduler();

  const onDeleteThreadTweet = () => {
    handleDeleteThread({
      postId: thread.post_id,
      sequenceNo: thread.sequence_no,
    });
  };

  const renderPreview = (file: any) => {
    switch (file.file_type) {
      case "image":
        return (
          <FileContainer>
            <StyledImage
              src={file.file_url}
              alt={file.user}
              style={{ borderRadius: "8px" }}
            />
          </FileContainer>
        );
      case "video":
        return (
          <FileContainer>
            <video controls>
              <source src={file.file_url} type="video/mp4" />
            </video>
          </FileContainer>
        );
      default:
        return null;
    }
  };

  const renderFiles = () => {
    if (thread.files) {
      return (
        <FilesPreview>
          {thread.files.map((file: any) => (
            <Fragment key={file.file_url}>{renderPreview(file)}</Fragment>
          ))}
        </FilesPreview>
      );
    }
  };

  if (!selectedAccount.userName) return null;

  return (
    <Container>
      <ImageContainer>
        <Image
          src={selectedAccount.profileImageUrl}
          alt={selectedAccount.name}
        />
        {showLine && <Line />}
      </ImageContainer>
      <Content>
        <Header>
          <User>
            <Text className="name" content={selectedAccount.name}></Text>
            <Text
              className="username"
              content={`@${selectedAccount.userName}`}
            />
          </User>
          <RemoveIcon onClick={onDeleteThreadTweet} />
        </Header>
        <TweetText>
          <Linkify>{thread.message}</Linkify>
        </TweetText>
        {thread.files && renderFiles()}
        <Bottom>
          <Text size="extraSmall">
            {moment(
              datetimeHelper.getUTCAsLocal(new Date(thread.datetime))
            ).format("MMM Do YYYY, h:mm:ss A")}
          </Text>
        </Bottom>
      </Content>
    </Container>
  );
};

export default SentTweetThread;
