import { dolphynPublicRoot } from "api/instances";
import { throwApiError } from "errors";
import { constants, links } from "config/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/signup");

export interface ISignupDolphyn {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  termsConditions: boolean;
}

export interface ISignupDolphynData {
  message: string;
}

const signupDolphyn = async ({
  firstName,
  lastName,
  username,
  email,
  password,
}: ISignupDolphyn) => {
  try {
    const { data: apiData } = await dolphynPublicRoot.post(URL, {
      user_name: username,
      password,
      email,
      first_name: firstName,
      last_name: lastName,
    });
    const message = apiData.message;
    return { message } as ISignupDolphynData;
  } catch (error) {
    throwApiError(error, constants.DEFAULT_API_ERROR);
  }
};

export default signupDolphyn;
