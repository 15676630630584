import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { Portal } from "components/core";
import { usePersonalDetails } from "hooks/Profile";
import { useOnClickOutside, useToggle } from "hooks";
import { discordConfig, links } from "config/SideBar";

import { ReactComponent as DiscordIcon } from "assets/icons/SideBar/discord-icon.svg";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: 16px 24px;
  margin: auto 0 8px;
`;

const WidgetContainer = styled.div`
  position: fixed;
  left: 16px;
  bottom: 72px;
  width: 400px;
  height: 400px;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  ${helper.getBoxShadow("extraLightGrey")};
  iframe {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 432px) {
    width: 96vw;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${helper.getSpacing("small")};
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.alpha};
      }
    }

    p {
      color: ${({ theme }) => theme.colors.alpha};
    }
  }
`;

const TextButton = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
`;

const Community = () => {
  const [open, toggleOpen] = useToggle();
  const clickOutsideRef = useOnClickOutside<HTMLDivElement>(() =>
    toggleOpen(false)
  );

  const personalDetails = usePersonalDetails();

  const renderWidget = () => {
    const widgetUrl = links.DISCORD_BASE_URL.addSearchParamsToUrl({
      id: discordConfig.widgetId,
      theme: discordConfig.theme,
      username: personalDetails.details.username,
    });

    if (open) {
      return (
        <Portal>
          <WidgetContainer ref={clickOutsideRef}>
            <iframe
              title="Join Discord"
              src={widgetUrl}
              allowTransparency
              style={{ border: "0px" }}
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            />
          </WidgetContainer>
        </Portal>
      );
    }
  };

  return (
    <Container>
      <IconText onClick={() => toggleOpen()}>
        <DiscordIcon /> <TextButton>Join Community</TextButton>
      </IconText>
      {renderWidget()}
    </Container>
  );
};

export default Community;
