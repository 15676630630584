import { createContext } from "react";
import { ISearchDemandForecastData } from "adapters/ProductDemand/types";
import { useReactQuery, useBoolean } from "hooks/common";
import { fetchSearchDemandForecast } from "api/ProductDemand";
import { usePreferences } from "hooks/ProductDemand";
import { Loader } from "components/common";
import { forecast } from "config/ProductDemand";

type TSearchDemandForecastContext = {
  data: ISearchDemandForecastData;
  handleSearch: () => void;
  showGraph: boolean;
  setShowGraphTrue: () => void;
  setShowGraphFalse: () => void;
};

export const SearchDemandForecastContext =
  createContext<TSearchDemandForecastContext | null>(null);

const initialData: ISearchDemandForecastData = {
  graph: [],
  graphMeta: {
    maxAxisValue: 0,
  },
};

const SearchDemandForecastProvider = (props: React.PropsWithChildren<{}>) => {
  const [showGraph, setShowGraphTrue, setShowGraphFalse] = useBoolean();
  const preferences = usePreferences();
  const {
    data = initialData,
    execute,
    isLoading,
  } = useReactQuery(fetchSearchDemandForecast);

  const handleSearch = () =>
    execute({
      keyword: preferences.data.keyword,
      country: preferences.data.currentCountry,
    });

  return (
    <SearchDemandForecastContext.Provider
      value={{
        data,
        handleSearch,
        showGraph,
        setShowGraphTrue,
        setShowGraphFalse,
      }}
    >
      <Loader textData={forecast} hidden={!isLoading} />
      {props.children}
    </SearchDemandForecastContext.Provider>
  );
};

export default SearchDemandForecastProvider;
