import { Fragment, useState } from "react";
import { Button, Flex, Form, Header, LinkButton, Text } from "components/core";
import { Formik } from "formik";
import { useScheduleSecondaryPane } from "hooks/SocialMediaPost";
import { Body, Container } from "./CampaignBuilderForm.styles";
import ObjectHelper from "helpers/ObjectHelper";
import UrlHelper from "helpers/UrlHelper";
import { ICampaignBuilderFormValues, TUtmSearchParams } from "./types";
import CampaignLink from "./CampaignLink";
import {
  campaignFieldsData,
  campaignFormInitalValues,
  campaignFormValidation,
} from "config/SocialMediaPost";

const CampaignBuilderForm = () => {
  const [currentLink, setCurrentLink] = useState<string>("");

  const seconaryPane = useScheduleSecondaryPane();

  const handleCreateLink = (values: ICampaignBuilderFormValues) => {
    const urlInstance = new UrlHelper(values.websiteUrl);
    const objHelper = new ObjectHelper();
    const params: TUtmSearchParams = {
      utm_source: values.campaignSource,
      utm_medium: values.campaignMedium,
      utm_campaign: values.campaignName,
      utm_id: values.campaignId,
    };
    const url = urlInstance.addSearchParamsToUrl(
      objHelper.removeEmptyStringValues(params)
    );
    setCurrentLink(url);
  };

  const handleCancel = () => {
    seconaryPane.changePane((pane) => pane.preview);
    setCurrentLink("");
  };

  const renderFields = () => {
    return campaignFieldsData.map((data) => (
      <Fragment key={data.name}>
        <Form.Field>
          <Form.Input name={data.name} placeholder={data.placeholder} />
        </Form.Field>
        <Form.Field>
          <Text
            content={data.description}
            variant="lightGrey"
            font="poppins"
            size="extraSmall"
          />
        </Form.Field>
      </Fragment>
    ));
  };

  const renderBody = () => {
    if (!!currentLink) {
      return <CampaignLink link={currentLink} />;
    }

    return (
      <Formik
        initialValues={campaignFormInitalValues}
        onSubmit={handleCreateLink}
        validationSchema={campaignFormValidation}
      >
        {({ handleSubmit }) => (
          <Form.Form onSubmit={handleSubmit}>
            <Form.Field>
              <Text
                style={{ textAlign: "center" }}
                variant="lightGrey"
                content="Enter the website URL and campaign information"
                font="poppins"
                size="small"
              />
            </Form.Field>

            {renderFields()}

            <Flex justify="flex-end">
              <Button type="submit" content="Create Link" variant="alpha" />
              <LinkButton
                type="button"
                content="Cancel"
                onClick={() => handleCancel()}
              />
            </Flex>
          </Form.Form>
        )}
      </Formik>
    );
  };

  return (
    <Container>
      <Header content="UTM parameter" mb="small" font="poppins" />
      <Body>{renderBody()}</Body>
    </Container>
  );
};

export default CampaignBuilderForm;
