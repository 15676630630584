import { ScheduleThreadAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import { links } from "config/SocialMediaPost";
import { APIError } from "errors";
import {
  IDeleteThreadPayload,
  IScheduleThreadPayload,
  IUpdateThreadPayload,
} from "./types";

const error = new APIError();
const URL = links.scheduleBaseUrl.append("/auth/twitter/thread");
const adapter = new ScheduleThreadAdapter();

const ScheduleThread = {
  post: async (values: IScheduleThreadPayload) => {
    try {
      const headers = adapter.getConfig();
      const apiPayload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, apiPayload, headers);
      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  put: async (values: IUpdateThreadPayload) => {
    try {
      const headers = adapter.getConfig();
      const apiPayload = adapter.getPutPayload(values);
      const { data } = await dolphynAPI.put(
        `${URL}/${values.threadId}`,
        apiPayload,
        headers
      );
      return adapter.parsePutResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  delete: async (values: IDeleteThreadPayload) => {
    try {
      const headers = adapter.getConfig();
      const params = adapter.getDeleteParams(values);
      const { data } = await dolphynAPI.delete(`${URL}/${values.postId}`, {
        params,
        headers,
      });
      return adapter.parseDeleteResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default ScheduleThread;
