import { applicationRoutes as routes } from "config/common";

const items = [
  {
    title: "Product Search Demand",
    route: routes.productDemand.searchDemandPath,
  },
  {
    title: "Demand By Country",
    route: routes.productDemand.demandByCountryPath,
  },
  {
    title: "Similar Keyword",
    route: routes.productDemand.similarKeywordPath,
  },
  {
    title: "Keyword Comparison",
    route: routes.productDemand.keywordComparisonPath,
  },
];

export default items;
