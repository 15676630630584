import { Flex } from "components/core";
import { BaseModuleLayout } from "layout/common";
import TwitterLayoutHeader from "./LayoutHeader";

const ScheduleRetweet = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <TwitterLayoutHeader header="Schedule Retweets" />
      <BaseModuleLayout.Body>{props.children}</BaseModuleLayout.Body>
    </Flex>
  );
};

export default ScheduleRetweet;
