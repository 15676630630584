import styled, { css } from "styled-components";

interface IContainerProps {
  width?: React.CSSProperties["width"];
  minWidth?: React.CSSProperties["minWidth"];
  maxWidth?: React.CSSProperties["maxWidth"];
  height?: React.CSSProperties["height"];
  minHeight?: React.CSSProperties["minHeight"];
  maxHeight?: React.CSSProperties["maxHeight"];
}

const Container = styled.div<IContainerProps>`
  margin-left: auto;
  margin-right: auto;
  ${({
    width = "min(80%, 1440px)",
    height,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
  }) => {
    return css`
      width: ${width};
      height: ${height};
      min-height: ${minHeight};
      max-height: ${maxHeight};
      min-width: ${minWidth};
      max-width: ${maxWidth};
    `;
  }};
`;

export default Container;
