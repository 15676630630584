import { Container } from "./Step.styles";
import { TStepsStyleProps } from "./types";

interface IStepProps extends TStepsStyleProps {
  stepNumber: number;
  active?: boolean;
}
const Step = ({ stepNumber, ...props }: IStepProps) => {
  return <Container {...props} />;
};

export default Step;
