import { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { registrationStatusApi } from "api/Profile";
import {
  setRegistration,
  setEmail,
  setCountry,
  setKeywords,
  setOccupation,
  setProduct,
  setReferral,
  setTimezoneLanguage,
} from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { TRegistrationStatus } from "adapters/Profile/types";

type TRegistrationStatusContext = {
  isLoading: boolean;
  isRegistrationComplete: boolean;
  toBeFetched: boolean;
  fetch: () => void;
  fetchAsync: () => Promise<TRegistrationStatus | undefined>;
};

export const RegistrationStatusContext =
  createContext<null | TRegistrationStatusContext>(null);

const RegistrationStatusProvider = (props: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();

  const {
    execute: fetch,
    executeAsync: fetchAsync,
    isLoading,
  } = useReactQuery(registrationStatusApi.get, {
    onSuccess: (data) => {
      dispatch(
        setRegistration(
          data.isCountry &&
            data.isKeywords &&
            data.isOccupation &&
            data.isProduct &&
            data.isReferral &&
            data.isTimezoneLanguage
        )
      );
      dispatch(setEmail(data.isEmail));
      dispatch(setCountry(data.isCountry));
      dispatch(setKeywords(data.isKeywords));
      dispatch(setOccupation(data.isOccupation));
      dispatch(setProduct(data.isProduct));
      dispatch(setReferral(data.isReferral));
      dispatch(setTimezoneLanguage(data.isTimezoneLanguage));
    },
    onError: () => {
      dispatch(setRegistration(false));
    },
  });

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  const isRegistrationComplete = useAppSelector(
    (state) => state.profile.registrationStatus.completed
  );

  const toBeFetched = useAppSelector(
    (state) => state.profile.registrationStatus.toBeFetched
  );

  return (
    <RegistrationStatusContext.Provider
      value={{
        fetch,
        fetchAsync,
        isLoading,
        isRegistrationComplete,
        toBeFetched,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </RegistrationStatusContext.Provider>
  );
};

export default RegistrationStatusProvider;
