import InfluencerTarget from "./InfluencerTarget";
import RemoveTarget from "./RemoveTarget";
import { Container } from "./DropTarget.styles";
import FollowTarget from "./FollowTarget";

const PotentialDropTarget = () => {
  return (
    <Container>
      <RemoveTarget />
      <InfluencerTarget />
      <FollowTarget />
    </Container>
  );
};

export default PotentialDropTarget;
