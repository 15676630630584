import { range } from "lodash";
import { format, isSameDay } from "date-fns";
import { useEventCalendar } from "hooks/common";
import {
  Container,
  Monthbox,
  HeaderContainer,
  HeaderBox,
  DateContainer,
} from "./MonthView.styles";
import { IBaseCalendarProps, TDayViewCol, TEventData } from "./types";

const weekNames = ["S", "M", "T", "W", "Th", "F", "Sa"];

const Header = () => {
  return (
    <HeaderContainer>
      {range(0, 7).map((key) => (
        <HeaderBox key={key}>{weekNames[key]}</HeaderBox>
      ))}
    </HeaderContainer>
  );
};

interface IMonthViewProps<T extends TEventData, U extends TDayViewCol>
  extends IBaseCalendarProps<T, U> {}

const MonthView = <T extends TEventData, U extends TDayViewCol>(
  props: IMonthViewProps<T, U>
) => {
  const eventCalendar = useEventCalendar();

  const renderMonthDate = (date: Date) => {
    return format(date, "dd");
  };

  const renderEvents = (date: Date) => {
    const events = props.events.filter((event) => {
      return isSameDay(event.datetime, date);
    });
    return props.renderEvents(events);
  };

  const renderMonths = () => {
    return eventCalendar.dates.map((date, key) => (
      <Monthbox key={key}>
        <DateContainer>{renderMonthDate(date)}</DateContainer>
        {renderEvents(date)}
      </Monthbox>
    ));
  };
  return (
    <>
      <Header />
      <Container>{renderMonths()}</Container>
    </>
  );
};

export default MonthView;
