import styled, { css, DefaultColors } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  gap: ${helper.getSpacing("small")};
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ILineProps {
  variant: keyof DefaultColors;
}

const Line = styled.div<ILineProps>`
  margin-top: ${helper.getSpacing("tiny")};
  width: 2px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  ${({ variant, theme }) => {
    const { colors } = theme;
    return css`
      background-color: ${colors[variant]};
    `;
  }}
`;

export { Container, Line, Sidebar };
