import styled from "styled-components";
import { Menu as BaseMenu } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Menu = styled(BaseMenu)`
  &&& {
    gap: ${helper.getSpacing("extraSmall")};
    height: 32px;
    min-height: 24px;
    margin: unset;
  }
`;

const Item = styled(BaseMenu.Item)`
  &&&&&& {
    font-family: ${({ theme }) => theme.fonts.poppins};
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 14px;
    &.active {
      ${helper.getFontWeight("medium")}
      border-radius: ${helper.getSpacing("small")};
      color: ${({ theme }) => theme.colors.alpha};
    }
    &:hover {
      border-radius: ${helper.getSpacing("small")};
    }
  }
`;

export { Menu, Item };
