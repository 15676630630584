import { TBaseTweetObject } from "./types";

const useTweetHelper = () => {
  const findAndReplace = <T extends TBaseTweetObject>(
    data: T[],
    replactWith?: T
  ) => {
    if (replactWith) {
      const newData = data.map((tweetCard) =>
        replactWith.tweetId === tweetCard.tweetId ? replactWith : tweetCard
      );

      return [...newData];
    }
    return data;
  };

  const removeFromList = <T extends TBaseTweetObject>(
    data: T[],
    replactWith?: T
  ) => {
    if (replactWith) {
      const newList = data.filter(
        ({ tweetId }) => tweetId !== replactWith.tweetId
      );

      return newList;
    }
    return data;
  };
  return { findAndReplace, removeFromList };
};

export default useTweetHelper;
