import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 70%;
  overflow: auto;
  ${helper.getStyledScrollbar()}
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  svg {
    justify-self: center;
    padding: 1rem 0;
  }
`;

const TimeBox = styled.div`
  height: calc(50px + (5vh + 5vw) / 10);
  border: 1px solid
    ${({ theme }) => helper.lighten(theme.colors.lightGrey, "medium")};
  border-collapse: none;
  color: ${({ theme }) => theme.colors.darkGrey};
  overflow: auto;
  ${helper.getFontSize("small")}
  ${helper.getStyledScrollbar()}
`;

export { HeaderContainer, TimeBox, Body };
