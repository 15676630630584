import { createContext, useEffect, useState } from "react";
import {
  IFacebookUsers,
  IInstagramUsers,
  ILinkedinUsers,
} from "adapters/Auth/types";
import { useDolphynAuth, useReactQuery } from "hooks";
import { userTokens } from "api";
import { Spinner } from "components/common";
import TwitterService, { TTwitterAccount } from "services/Auth/twitterService";
import {
  FacebookService,
  InstagramService,
  LinkedinService,
} from "services/Auth";

type TUserTokensContext = {
  facebook: IFacebookUsers[];
  instagram: IInstagramUsers[];
  twitter: TTwitterAccount[];
  linkedin: ILinkedinUsers[];
};

const twitterService = new TwitterService();
const facebookService = new FacebookService();
const linkedinService = new LinkedinService();
const instagramService = new InstagramService();

export const UserTokensContext = createContext<TUserTokensContext | null>(null);

const UserTokensProvider = (props: React.PropsWithChildren<{}>) => {
  const { isSignedIn } = useDolphynAuth();
  const [facebook, setFacebook] = useState<IFacebookUsers[]>([]);
  const [instagram, setInstagram] = useState<IInstagramUsers[]>([]);
  const [twitter, setTwitter] = useState<TTwitterAccount[]>([]);
  const [linkedin, setLinkedin] = useState<ILinkedinUsers[]>([]);

  const { execute, isLoading } = useReactQuery(userTokens, {
    onSuccess: (data) => {
      if (data) {
        setFacebook(data.facebook);
        setInstagram(data.instagram);
        setTwitter(data.twitter);
        setLinkedin(data.linkedin);
        data.twitter.map((account) => twitterService.setAuthCookie(account));
        data.facebook.map((account) => facebookService.setAuthCookie(account));
        data.linkedin.map((account) => linkedinService.setAuthCookie(account));
        data.instagram.map((account) =>
          instagramService.setAuthCookie(account)
        );
        data.facebook.map((account) =>
          facebookService.setPages({
            uid: account.uid,
            pages: account.pageList,
          })
        );
        data.instagram.map((account) =>
          instagramService.setPages({
            uid: account.uid,
            pages: account.pageList,
          })
        );
        data.linkedin.map((account) =>
          linkedinService.setPages({
            uid: account.uid,
            pages: account.pageList,
          })
        );
      }
    },
  });

  useEffect(() => {
    if (isSignedIn) execute();
  }, [execute, isSignedIn]);

  return (
    <UserTokensContext.Provider
      value={{
        facebook,
        instagram,
        twitter,
        linkedin,
      }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </UserTokensContext.Provider>
  );
};

export default UserTokensProvider;
