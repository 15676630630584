import styled from "styled-components";
import { range } from "lodash";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

type TKeywordNumber = {
  inputNumber: string;
};

const Container = styled.div`
  width: 80%;
`;

const InnerContainer = styled.div<TKeywordNumber>`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("small")};
  color: ${({ theme }) => theme.colors.darkGrey};
  height: 40px;
  ${helper.getBorderRadius("small")};
  border-bottom: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  &::before {
    position: absolute;
    display: grid;
    content: ${({ inputNumber }) => `"${inputNumber}"`};
    width: 50px;
    height: 100%;
    place-items: center;
    background-color: ${({ theme }) => theme.colors.alpha};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 5px 0px 0px 5px;
  }
`;

const StyledInput = styled.input`
  display: block;
  border: unset;
  outline: unset;
  width: 100%;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.poppins};
  background-color: ${({ theme }) => theme.colors.beta};
  padding: ${helper.getSpacing("extraSmall")};
  padding-left: 5rem;

  &::placeholder {
    opacity: 0.3;
    font-size: 14px;
  }
`;

interface IKeywordInputProps
  extends TKeywordNumber,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {}

const Input = ({ inputNumber, style, ...props }: IKeywordInputProps) => {
  return (
    <InnerContainer inputNumber={inputNumber} style={style}>
      <StyledInput {...(props as any)} />
    </InnerContainer>
  );
};

interface IInputArrayProps
  extends Omit<IKeywordInputProps, "onChange" | "inputNumber"> {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    inputIndex: number
  ) => void;
  value: string[];
  length: number;
  numberingOffset?: number;
  inputProps?: (
    inputNumber?: number
  ) => React.ComponentPropsWithoutRef<"input">;
}

const InputArray = ({
  onChange,
  value,
  length,
  numberingOffset = 0,
  inputProps,
  style,
}: IInputArrayProps) => {
  const getInputProps = (inputNumber: number) =>
    inputProps ? inputProps(inputNumber) : {};
  return (
    <>
      {range(0, length).map((inputIndex) => (
        <InnerContainer
          key={inputIndex}
          inputNumber={(inputIndex + 1 + numberingOffset).toString()}
          style={style}
        >
          <StyledInput
            value={value[inputIndex]}
            onChange={(e) => onChange(e, inputIndex)}
            {...getInputProps(inputIndex + 1)}
          />
        </InnerContainer>
      ))}
    </>
  );
};

const KeywordInput = ({
  children,
  ...props
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => (
  <Container {...props}>{children}</Container>
);

KeywordInput.Input = Input;
KeywordInput.InputArray = InputArray;

export default KeywordInput;
