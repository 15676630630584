import styled from "styled-components";
import { Icon } from "components/core";

const IconContainer = styled.div`
  padding: 4px;

  svg {
  }
`;

const EyeIcon = styled(Icon).attrs({
  name: "info",
})`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export { IconContainer, EyeIcon };
