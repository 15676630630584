import { useField } from "formik";
import { mediaFormData } from "config/Profile";
import { useRegistrationSteps } from "hooks/Profile";
import { QuestionCard } from "../common";
import ContentContainer from "../common/ContentContainer";
import { TCompleteRegistrationFormValues } from "./types";
import { Button } from "components/core";

interface IReferralForm {
  name: string;
  onGetStarted: () => void;
}

const ReferralForm = (props: IReferralForm) => {
  const [field, , helpers] = useField<
    TCompleteRegistrationFormValues["referral"]
  >(props.name);
  const steps = useRegistrationSteps();

  const handleUpdate = (referral: string) => {
    helpers.setValue(referral);
    steps.handleGoToNextStep();
  };

  const renderGetStarted = () => {
    if (field.value)
      return (
        <Button
          variant="alpha"
          type="button"
          content="Get Started"
          onClick={props.onGetStarted}
        />
      );
    return null;
  };

  return (
    <ContentContainer>
      <QuestionCard>
        <QuestionCard.Content>
          <QuestionCard.Header
            as="h1"
            size="medium"
            font="poppins"
            fontWeight="high"
            variant="darkGrey"
            textAlign="center"
            content="How did you hear about Dolphy.io?"
          />
          <QuestionCard.Options>
            {mediaFormData.map(({ id, icon, text, value }) => (
              <QuestionCard.Option
                key={id}
                icon={icon}
                text={text}
                active={field.value === value}
                onClick={() => handleUpdate(value)}
              />
            ))}
          </QuestionCard.Options>
          <QuestionCard.OtherOption
            value={field.value}
            onSubmit={(value) => handleUpdate(value)}
          />
          {renderGetStarted()}
        </QuestionCard.Content>
      </QuestionCard>
    </ContentContainer>
  );
};

export default ReferralForm;
