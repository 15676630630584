import styled from "styled-components";
import { Button as BaseButton } from "components/core";
import { styles } from "../Common";

const Container = styled.section`
  background-color: ${({ theme }) =>
    styles.helper.lighten(theme.colors.alpha, "high")};
  padding: ${styles.helper.getSpacing("large")} 0;
  ${styles.marginBottom}
`;

const InnerContainer = styled.div`
  ${styles.helper.containerize(80)}
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media screen and (max-width: 878px) {
    justify-content: center;
    gap: ${styles.helper.getSpacing("small")};
  }
`;
const IllutstationContainer = styled.div`
  @media screen and (min-width: 878px) {
    position: relative;
    width: max(200px, 40%);
    svg {
      position: absolute;
      bottom: -15%;
      width: 100%;
      height: 100%;
    }
  }
`;

const Header = styled.h2`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getFontSize("large")}
  text-align: center;
  color: ${({ theme }) => theme.colors.alpha};
  margin: 0 0 ${styles.helper.getSpacing("extraLarge")} 0;
  ::after {
    content: "";
    width: 50px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.extraLightGrey};
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Description = styled.p`
  &,
  span {
    font-family: ${({ theme }) => theme.fonts.poppins};
    ${styles.helper.getFontWeight("medium")}
    ${styles.helper.getFontSize("medium")}
    ${styles.helper.getLineHeight("large")}
    text-align: center;
  }
  span {
    color: ${({ theme }) => theme.colors.alpha};

    @media screen and (max-width: 768px) {
      font-size: calc((50vw + 50vh) / 40 + 5px);
    }
  }

  @media screen and (max-width: 768px) {
    font-size: calc((50vw + 50vh) / 40 + 5px);
  }
`;

const Content = styled.div``;

const Button = styled(BaseButton)`
  &&&& {
    padding: ${styles.helper.getSpacing("small")};
    font-family: ${({ theme }) => theme.fonts.montserrat};
    ${styles.helper.getFontWeight("medium")}
    ${styles.helper.getFontSize("medium")}
    border-radius: 8px;
  }
`;

export {
  Container,
  InnerContainer,
  Header,
  Description,
  Content,
  Button,
  IllutstationContainer,
};
