import { useState } from "react";

const useSteps = (totalSteps: number) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleGotoStep = (payload: number) => {
    setCurrentStep((current) => (payload < totalSteps ? payload : current));
  };

  const handleGoToNextStep = () => {
    setCurrentStep((current) => (current < totalSteps ? current + 1 : current));
  };

  const handleGoToPrevStep = () => {
    setCurrentStep((current) => (current > 1 ? current - 1 : current));
  };
  return {
    currentStep,
    handleGoToNextStep,
    handleGoToPrevStep,
    handleGotoStep,
  };
};

export default useSteps;
