import { BaseModuleLayout } from "layout/common";
import { Flex } from "components/core";
import { ExportMenu, FilterBy } from "components/Twitter/Analytics";
import { AnalyticsProvider } from "context";
import TwitterLayoutHeader from "./LayoutHeader";

const AnalyticsLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <AnalyticsProvider>
      <Flex direction="column">
        <TwitterLayoutHeader header="Analytics" />
        <BaseModuleLayout.MenuBar
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FilterBy />
          <ExportMenu />
        </BaseModuleLayout.MenuBar>
        <BaseModuleLayout.Body>{props.children}</BaseModuleLayout.Body>
      </Flex>
    </AnalyticsProvider>
  );
};

export default AnalyticsLayout;
