import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPlanSelectionDetail } from "adapters/Pricing/types";

const initialState = null as TPlanSelectionDetail | null;

export const selectedPlan = createSlice({
  name: "selectedPlan",
  initialState,
  reducers: {
    setSelectedPlan: (state, action: PayloadAction<TPlanSelectionDetail>) => {
      return action.payload;
    },
  },
});

export const { setSelectedPlan } = selectedPlan.actions;

export default selectedPlan.reducer;
