import { TEventData } from "./types";
import { EventBox, Text } from "./Event.styles";

interface IEventProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onClick"> {
  event: TEventData;
  onClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    calendarEvent: TEventData
  ) => void;
}

const Event = ({ event, onClick, ...props }: IEventProps) => {
  const renderIcon = (Icon: TEventData["icon"]) => {
    if (Icon) return <Icon />;
  };
  return (
    <EventBox key={event.id} onClick={(e) => onClick(e, event)} {...props}>
      {renderIcon(event.icon)}
      <Text> {event.text} </Text>
    </EventBox>
  );
};

export default Event;
