import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

interface IItemProps {
  active?: boolean;
}

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: ${helper.getSpacing("extraSmall")};
`;

const Item = styled.div<IItemProps>`
  padding: ${helper.getSpacing("small")};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("extraSmall")};
  ${({ active, theme }) => {
    if (active) {
      return css`
        color: ${theme.colors.alpha};
        ${helper.getFontWeight("high")};
        background-color: ${theme.colors.beta};
      `;
    }
    return css`
      color: ${theme.colors.lightGrey};
      &:hover {
        background-color: ${theme.colors.beta};
      }
    `;
  }}
`;

export { Container, Item };
