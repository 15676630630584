type TAPIPostResponse = {
  sender_email: string;
  sender_name: string;
  subject: string;
  email_body: string;
  templates: string[];
  message: string;
};

type TAPIGetResponse = {
  sender_email: string;
  sender_name: string;
  subject: string;
  email_body: string;
  templates: string[];
};

type TAPIPostPayload = {
  senderEmail: string;
  senderName: string;
  subject: string;
  emailBody: string;
  template: string;
  files: any[];
};

class EmailAdapter {
  parseGetResponse = (apiResponse: TAPIGetResponse) => {
    return {
      sender_email: apiResponse.sender_email || "",
      sender_name: apiResponse.sender_name || "",
      subject: apiResponse.subject || "",
      email_body: apiResponse.email_body || "",
      templates: apiResponse.templates || [],
    };
  };

  postPayload = (apiPayload: TAPIPostPayload) => {
    return {
      sender_email: apiPayload.senderEmail,
      sender_name: apiPayload.senderName,
      subject: apiPayload.subject,
      email_body: apiPayload.emailBody,
      template: apiPayload.template,
      files: apiPayload.files,
    };
  };

  parsePostResponse = (apiResponse: TAPIPostResponse) => {
    return {
      sender_email: apiResponse.sender_email,
      sender_name: apiResponse.sender_name,
      subject: apiResponse.subject,
      email_body: apiResponse.email_body,
      templates: apiResponse.templates,
      message: apiResponse.message,
    };
  };
}

export default EmailAdapter;
