import { Flex } from "components/core";
import AccountPic, { IAccountPicProps } from "./AccountPic";
import Actions, { IActionProps } from "./Actions";

export interface IToolbarProps extends IAccountPicProps, IActionProps {}

const Toolbar = ({ image, title, onEdit, onDelete }: IToolbarProps) => {
  return (
    <Flex justify="space-between">
      <AccountPic image={image} title={title} />
      <Actions onEdit={onEdit} onDelete={onDelete} />
    </Flex>
  );
};

export default Toolbar;
