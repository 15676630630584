import styled from "styled-components";
import { InputButton as BaseInputButton } from "../Common";
import { styles } from "../Common";

const Container = styled.section`
  ${styles.marginBottom}
`;

const InnerContainer = styled.div`
  ${styles.helper.containerize(80)}
  min-height: 400px;
  position: relative;
  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;
const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const Form = styled.form`
  position: relative;
  width: 40%;
  padding: ${styles.helper.getSpacing("large")} 0;
  margin-left: ${styles.helper.getSpacing("large")};
  @media screen and (max-width: 979px) {
    width: 90%;
    margin: auto;
  }
`;

const Header = styled.h2`
  color: ${({ theme }) => theme.colors.alpha};
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${styles.helper.getFontSize("medium")}
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getLineHeight("medium")}
  text-transform: uppercase;
`;

const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.white};
  ${styles.helper.getFontSize("large")}
  ${styles.helper.getFontWeight("medium")}
  ${styles.helper.getLineHeight("medium")}
`;

const InputButton = styled(BaseInputButton)`
  &&&& {
    background-color: ${({ theme }) => theme.colors.white};
    input {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export {
  Container,
  InnerContainer,
  Background,
  Form,
  Header,
  Description,
  InputButton,
};
