import { backendApiUrl, originUrl } from "helpers/UrlInstances";
import { twitterAuthApi } from "config/Auth/links";
import UrlHelper from "helpers/UrlHelper";

export const TWITTER_MODULE_AUTH_URL = twitterAuthApi.addSearchParamsToUrl(
  (routes) => ({
    redirectUri: originUrl.append(routes.leadGeneration.keywordListening),
  })
);

// Base Twitter API Url
export const baseTwitterApiUrl = new UrlHelper(
  backendApiUrl.append("/twitter/v1/public")
);

// Base Twitter Admin API Url
export const baseTwitterAdminApiUrl = new UrlHelper(
  backendApiUrl.append("/twitter/v1/admin")
);

// Link Unlike Endpoint
export const likeUnlikeApiUrl = "/tweet/like_unlike";
