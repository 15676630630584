import { constants } from "config/common";
import { dolphynAPI } from "api/instances";
import { links } from "config/Twitter";
import { APIError } from "errors";
import { IPotentialLeadsPayload } from "adapters/Twitter/types";
import { PotentialLeadsAdapter } from "adapters/Twitter";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/potential_leads");
const adapter = new PotentialLeadsAdapter();

const fetchPotentialLeads = async (payload: IPotentialLeadsPayload) => {
  try {
    if (payload.isAuthenticated) {
      const apiPayload = adapter.getGetPayload(payload);
      const { headers } = adapter.getConfig();
      const { data } = await dolphynAPI(URL, {
        params: apiPayload,
        headers,
      });
      return adapter.parseGetResponse(data);
    }
    const apiPayload = adapter.getGetPayload(payload);
    const { data } = await dolphynAPI(URL, {
      params: apiPayload,
    });
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchPotentialLeads;
