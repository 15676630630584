import { Form } from "components/core";
import { promptFormat } from "config/ContentCreation";
import { TScheduleForMonthFormValues } from "context/ContentCreation/types";
import { Formik } from "formik";
import { useScheduleForMonth } from "hooks/ContentGeneration";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useAppSelector } from "redux/store/hooks";
import { FormContainer, InfoIcon } from "./ScheduleForMonth.styles";

const ScheduleForMonth: React.FC = () => {
  const { handleScheduleForMonth, isSchedulingForMonth } =
    useScheduleForMonth();
  const timezone = useAppSelector(
    (state) => state.profile.personalDetails.timezone
  );

  const initialFormValues: TScheduleForMonthFormValues = {
    prompt: "",
    timezone: timezone,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleScheduleForMonth}>
      {({ handleSubmit }) => (
        <Form.Form onSubmit={handleSubmit} style={{ padding: "1rem 0" }}>
          <FormContainer>
            <InfoIcon data-tip={promptFormat} />
            <Form.Input
              type="text"
              name="prompt"
              placeholder="Schedule tweets daily at 10 AM"
              style={{ boxShadow: "unset", width: "32rem", fontSize: "14px" }}
            />
            <Form.Button
              loading={isSchedulingForMonth}
              icon="check"
              variant="alpha"
              type="submit"
              size="small"
            />
          </FormContainer>
        </Form.Form>
      )}
    </Formik>
  );
};

export default ScheduleForMonth;
