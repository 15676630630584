import { BrandLogo } from "components/common";
import { useToggle } from "hooks";
import Community from "./Community";
import MainNavigation from "./MainNavigation";
import Profile from "./Profile";
import { Container, TopContainer, HamburgerIcon } from "./Hamburger.styles";

const variants = {
  open: { display: "flex", x: 0 },
  close: { display: "none", x: "-100%" },
};

const Hamburger = () => {
  const [open, handleOpen] = useToggle();

  return (
    <>
      <HamburgerIcon
        onHoverStart={() => handleOpen(true)}
        animate={open ? "close" : "open"}
        variants={variants}
        transition={{ duration: 0.3, delay: 0.5 }}
      >
        <BrandLogo.Icon variant="alpha" size="extraSmall" />
      </HamburgerIcon>
      <Container
        onHoverEnd={() => handleOpen(false)}
        animate={open ? "open" : "close"}
        variants={variants}
        transition={{ duration: 0.5 }}
      >
        <TopContainer>
          <BrandLogo>
            <BrandLogo.Icon variant="alpha" />
            <BrandLogo.Text variant="alpha" text="Dolphy" size="medium" />
          </BrandLogo>
          <Profile />
        </TopContainer>
        <MainNavigation />
        <Community />
      </Container>
    </>
  );
};

export default Hamburger;
