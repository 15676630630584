import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const CalendarIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.alpha};
  padding: ${helper.getSpacing("extraSmall")};
  display: grid;
  place-items: center;
  border-radius: 0 ${helper.border.getBorderRadiusValue("medium")}
    ${helper.border.getBorderRadiusValue("medium")} 0;
  svg {
    height: 25px;
    width: 25px;
  }
  svg path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

interface IIconProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Icon = ({ icon: PropIcon }: IIconProps) => (
  <CalendarIconContainer>
    <PropIcon />
  </CalendarIconContainer>
);

export default Icon;
