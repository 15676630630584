import { useState } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Button } from "components/core";
import EasyTwitter from "./EasyTwitter";

const Container = styled.div`
  min-width: 400px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.alpha};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.beta};
`;

const TopSection = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.alpha};
  border-radius: 10px 10px 0px 0px;
  padding: 1rem;
  min-height: 50px;
`;

const CloseIcon = styled(Icon)`
  &&& {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`;

const Content = styled.div`
  height: 75%;
  max-width: 90%;
  margin: auto;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.alpha};
  box-sizing: border-box;
  border-radius: 10px;
`;

const CopyRight = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: 1.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.alpha};
  padding: 1rem;
`;

enum CheckKeywordModules {
  easyTwitter = "easyTwitter",
}

interface ICheckKeywordProps {
  onClose: () => void;
}

const CheckKeyword = ({ onClose }: ICheckKeywordProps) => {
  const [activeModule, setActiveModule] = useState<CheckKeywordModules>(
    CheckKeywordModules.easyTwitter
  );

  const renderContent = () => {
    switch (activeModule) {
      case CheckKeywordModules.easyTwitter:
        return <EasyTwitter />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <TopSection>
        <CloseIcon name="close" onClick={onClose} />
      </TopSection>
      <Menu>
        <Button
          style={{ borderRadius: "15px" }}
          variant="alpha"
          content="Twitter Leads"
          outline
          active={activeModule === CheckKeywordModules.easyTwitter}
          onClick={() => setActiveModule(CheckKeywordModules.easyTwitter)}
        />
      </Menu>
      <Content>{renderContent()}</Content>
      <CopyRight>© NULL Innovation. All Rights Reserved.</CopyRight>
    </Container>
  );
};

export default CheckKeyword;
