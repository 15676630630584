import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Button, Dropdown } from "components/core";
import ThemeHelper from "theme/themeHelper";
import Account from "./Account";

const helper = new ThemeHelper();

const contentCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  ${helper.centerContent()};
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  min-width: 180px;
  border: 1px solid ${({ theme }) => theme.colors.success};
  ${helper.getBoxShadow("darkGrey", { intensity: "high" })}
  border-radius: 10px;
  padding: ${helper.getSpacing("small")};
`;

const LogoContainer = styled.div`
  ${contentCenter};
  svg,
  img {
    max-height: 50px;
  }
`;

interface ILogoProps extends HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
}

const Logo = ({ icon, ...rest }: ILogoProps) => (
  <LogoContainer {...rest}>{icon}</LogoContainer>
);

interface IAccountCardProps {
  children?: React.ReactNode;
}

const AccountCard = ({ children }: IAccountCardProps) => {
  return <Container>{children}</Container>;
};

AccountCard.Logo = Logo;
AccountCard.Button = Button;
AccountCard.Dropdown = Dropdown;
AccountCard.Account = Account;

export default AccountCard;
