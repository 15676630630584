import { Flex } from "components/core";
import ContentCreationLayout from "./ContentCreationLayout";
import LayoutHeader from "./LayoutHeader";

const Calendar = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <LayoutHeader header="Calendar" />
      <ContentCreationLayout.Body>{props.children}</ContentCreationLayout.Body>
    </Flex>
  );
};

export default Calendar;
