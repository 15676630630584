import { TwitterService } from "services/Auth";

type TAPIResponse = {
  generated_content: string[];
};

type TAPIPayload = {
  keywords: string[];
};

class ContentGenerationByKeywordsAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      generatedContent: apiResponse.generated_content,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TAPIPayload) => {
    return {
      keywords: data.keywords.filter((word) => word !== "").join(),
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default ContentGenerationByKeywordsAdapter;
