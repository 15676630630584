import {
  Container,
  Grid,
  Card,
  IconContainer,
  Header,
  Description,
} from "./Features.styles";
import data from "./Features.data";

const Features = () => {
  return (
    <Container>
      <Grid>
        {data.map(({ icon: Icon, header, description }, key) => (
          <Card key={key}>
            <IconContainer>
              <Icon />
            </IconContainer>
            <Header>{header}</Header>
            <Description>{description}</Description>
          </Card>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;
