import { AnalyticsAdapter } from "adapters/Twitter";
import { IAnalyticsPayload } from "adapters/Twitter/types";
import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Twitter";
import { APIError } from "errors";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseTwitterApiUrl.append("/analytics");
const adapter = new AnalyticsAdapter();

const fetchAnalytics = async (values: IAnalyticsPayload) => {
  try {
    if (values.isAuthenticated) {
      const payload = adapter.getGetPayload(values);
      const { headers } = adapter.getConfig();
      const { data } = await dolphynAPI(URL, { params: payload, headers });
      return adapter.parseGetResponse(data);
    }
    const payload = adapter.getGetPayload(values);
    const { data } = await dolphynAPI(URL, { params: payload });
    return adapter.parseGetResponse(data);
  } catch (e) {
    error.throwAxiosError(e);
  }
};

export default fetchAnalytics;
