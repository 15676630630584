import * as Yup from "yup";

const campaignFormValidation = Yup.object({
  websiteUrl: Yup.string()
    .url("Please provide a valid URL")
    .required("Please provide a website URL"),
  campaignId: Yup.string(),
  campaignSource: Yup.string().required("Please provide a Campaign Source"),
  campaignMedium: Yup.string().required("Please provide a Campaign Medium"),
  campaignName: Yup.string().required("Please provide a Campaign Name"),
});

export default campaignFormValidation;
