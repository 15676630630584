import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { Icon } from "components/core";
import { TwitterIcon } from "./icons";

const helper = new ThemeHelper();

const ExportMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${helper.getSpacing("small")};
  position: relative;
`;

const PreviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.poppins};
  box-shadow: 1px 1px 3px 0 ${({ theme }) => theme.colors.extraLightGrey},
    -1px -1px 3px 0 ${({ theme }) => theme.colors.extraLightGrey};
  position: absolute;
  top: 100%;
  right: 100%;
  z-index: 2;
  width: 200px;
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
})`
  &&& {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  &&& {
    height: 16px;
    width: 16px;
  }
`;

const AccountsGrid = styled.div`
  display: grid;
  height: 100%;
  place-items: center;
  gap: ${helper.getSpacing("small")};
`;

const Toolbar = styled.div`
  border-radius: 0 0 10px 10px;
  min-height: 50px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 0.5rem;

  .file-icon {
    color: ${({ theme }) => theme.colors.lightGrey};
    cursor: pointer;
  }
`;

export {
  ExportMenuWrapper,
  PreviewWrapper,
  CloseIcon,
  StyledTwitterIcon,
  AccountsGrid,
  Toolbar,
};
