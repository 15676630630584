import styled from "styled-components";
import { Icon } from "components/core";
import { facebookPostTime } from "helpers";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 12px;
`;

const GlobeIcon = styled(Icon).attrs({
  name: "globe",
})`
  &&& {
    height: 14px;
    width: 14px;
    color: ${({ theme }) => theme.colors.alpha};
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  font-size: 14px;
`;

const TimeStamp = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  font-size: 14px;
`;

type TDefaultProps = {
  profileImage: string;
  name: string;
  time: string;
};

const PostHeader = ({ profileImage, name, time }: TDefaultProps) => {
  return (
    <Wrapper>
      <ProfileImage src={profileImage} alt={name} />
      <InfoContainer>
        <Name>{name}</Name>
        <TimeStamp>
          {facebookPostTime(time)} <GlobeIcon />
        </TimeStamp>
      </InfoContainer>
    </Wrapper>
  );
};

export default PostHeader;
