import { Formik } from "formik";
import { useBoolean } from "hooks/common";
import { useSecurityDetails } from "hooks/Profile";
import { Button, IconButton, LinkButton } from "components/core";
import { securityDetailsViews } from "config/Profile/securityDetailsViews";
import { TSecurityDetailsViewsMap } from "./types";
import ProfileSection from "./ProfileSection";
import TwoFactorAuthForm from "./TwoFactorAuthForm";
import { twoFactorAuthValidation } from "config/Profile";

const FORM_ID = "SecurityDetails";

interface ITwoFactorAuthProps {
  onChangeView: (view: TSecurityDetailsViewsMap) => void;
}
const TwoFactorAuth = (props: ITwoFactorAuthProps) => {
  const securityDetails = useSecurityDetails();
  const [editable, setEditableTrue, setEditableFalse] = useBoolean();

  const renderActions = (resetForm: () => void) => {
    if (editable)
      return (
        <>
          <Button
            type="submit"
            variant="alpha"
            size="small"
            content="Save"
            form={FORM_ID}
          />
          <LinkButton
            as="button"
            content="Cancel"
            onClick={() => {
              setEditableFalse();
              resetForm();
            }}
          />
        </>
      );
    return (
      <IconButton
        size="small"
        variant="alpha"
        name="pencil"
        onClick={setEditableTrue}
      />
    );
  };

  return (
    <ProfileSection>
      <Formik
        enableReinitialize
        validationSchema={twoFactorAuthValidation}
        initialValues={securityDetails.details}
        onSubmit={(values) => {
          securityDetails.handleUpdate(values);
          setEditableFalse();
        }}
      >
        {({ handleSubmit, resetForm, values }) => (
          <>
            <ProfileSection.Actions>
              <LinkButton
                content="Change Password"
                onClick={() =>
                  props.onChangeView(securityDetailsViews.changePassword)
                }
              />
              {renderActions(resetForm)}
            </ProfileSection.Actions>
            <ProfileSection.Content>
              <TwoFactorAuthForm
                editable={editable}
                formId={FORM_ID}
                onSubmit={handleSubmit}
                values={values}
              />
            </ProfileSection.Content>
          </>
        )}
      </Formik>
    </ProfileSection>
  );
};

export default TwoFactorAuth;
