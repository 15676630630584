import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontWeight("high")}
  ${helper.getFontSize("small")}
`;

export { Container };
