import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  background: ${({ theme }) => theme.colors.antiFlashWhite};
  border: 1px solid ${({ theme }) => theme.colors.alpha};
  border-radius: ${helper.getSpacing("extraSmall")};
  position: absolute;
  bottom: ${helper.getSpacing("small")};
  right: ${helper.getSpacing("small")};
  width: ${helper.getSpacing("huge")};
  height: ${helper.getSpacing("extraLarge")};
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export { Container };
