import { links } from "config/Twitter";
import { constants } from "config/common";
import { APIError } from "errors";
import { ScheduleRetweetAdapter } from "adapters/Twitter";
import { dolphynAPI } from "api/instances";
import {
  TPostScheduleRetweetPayload,
  TPatchScheduleRetweetPayload,
  TDeleteScheduleRetweetPayload,
} from "./types";

const URL = links.baseTwitterApiUrl.append("/schedule_retweet");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new ScheduleRetweetAdapter();

export type TScheduleRetweetPayload = {
  isAuthenticated?: boolean;
};

const scheduleRetweet = {
  getScheduleRetweet: async ({ isAuthenticated }: TScheduleRetweetPayload) => {
    try {
      if (isAuthenticated) {
        const headers = adapter.getConfig();
        const { data } = await dolphynAPI(URL, headers);
        return adapter.parseGetResponse(data);
      }
      const { data } = await dolphynAPI(URL);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  postScheduleRetweet: async (values: TPostScheduleRetweetPayload) => {
    try {
      if (values.isAuthenticated) {
        const headers = adapter.getConfig();
        const payload = adapter.getPostPayload(values);
        const { data } = await dolphynAPI.post(URL, payload, headers);

        return adapter.parsePostResponse(data);
      }

      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload);

      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  // for edit also it will be post
  patchScheduleRetweet: async (values: TPatchScheduleRetweetPayload) => {
    try {
      if (values.isAuthenticated) {
        const headers = adapter.getConfig();
        const payload = adapter.getPatchPayload(values);
        const { data } = await dolphynAPI.post(
          `${URL}/${values.id}`,
          payload,
          headers
        );

        return adapter.parsePostResponse(data);
      }

      const payload = adapter.getPatchPayload(values);
      const { data } = await dolphynAPI.post(`${URL}/${values.id}`, payload);

      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },

  deleteScheduleRetweet: async (values: TDeleteScheduleRetweetPayload) => {
    try {
      const headers = adapter.getConfig();
      const { data } = await dolphynAPI.delete(`${URL}/${values.id}`, headers);

      return adapter.parseDeleteResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default scheduleRetweet;
