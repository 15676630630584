import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

type HeaderProps = {
  textAlign?: "left" | "right" | "center";
};

const Header = styled.h2.attrs((props: HeaderProps) => ({
  textAlign: props.textAlign || "left",
}))`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  ${helper.getFontWeight("medium")};
  font-size: ${helper.getSpacing("small")};
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: ${({ textAlign }) => textAlign};
  margin: unset;
`;

export default Header;
