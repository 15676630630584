import styled, { DefaultColors } from "styled-components";

type IProps = {
  variant: keyof DefaultColors;
};

const BorderedPage = styled.div<IProps>`
  border: 50px solid;
  min-height: 100vh;
  border-color: ${({ variant, theme }) => theme.colors[variant]};
`;

export default BorderedPage;
