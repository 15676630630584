import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  scroll-behavior: smooth;
  ${helper.getStyledScrollbar()};
`;

export { Container };
