import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const List = styled.ul`
  display: flex;
  justify-content: center;
  gap: ${helper.getSpacing("extraSmall")};
  padding: unset;
  margin: unset;
  li {
    text-transform: capitalize;
    list-style: none;
    ${helper.getFontSize("extraSmall")}
    span {
      width: 10px;
      height: 10px;
      display: inline-block;
      margin-right: ${helper.getSpacing("extraSmall")};
    }
  }
`;

export { List };
