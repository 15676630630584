import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  gap: ${helper.getSpacing("extraSmall")};
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
  p {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export { Container };
