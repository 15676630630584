import { dolphynAPI } from "api/instances";
import { constants } from "config/common";
import { links } from "config/Admin";
import { APIError } from "errors";
import { EmailAdapter } from "adapters/Admin";
import { IEmailPayload } from "adapters/Admin/types";

const error = new APIError(constants.DEFAULT_API_ERROR);
const URL = links.baseAdminApiUrl.append("/send_email");
const adapter = new EmailAdapter();

const Email = {
  getEmail: async () => {
    try {
      const { data } = await dolphynAPI(URL);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
  postEmail: async (payload: IEmailPayload) => {
    try {
      const apiPayload = adapter.postPayload(payload);
      const { data } = await dolphynAPI.post(URL, apiPayload);
      return adapter.parsePostResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default Email;
