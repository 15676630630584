import { backendApiUrl, originUrl } from "helpers/UrlInstances";
import {
  facebookAuthApi,
  twitterAuthApi,
  instagramAuthApi,
  linkedinAuthApi,
} from "config/Auth/links";
import UrlHelper from "helpers/UrlHelper";

export const SOCIAL_ACCOUNT_FACEBOOK_AUTH_LINK =
  facebookAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.rootRoutes.socialAccount),
  }));

export const SOCIAL_ACCOUNT_TWITTER_AUTH_LINK =
  twitterAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.rootRoutes.socialAccount),
  }));

export const SOCIAL_ACCOUNT_INSTAGRAM_AUTH_LINK =
  instagramAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.rootRoutes.socialAccount),
  }));

export const SOCIAL_ACCOUNT_LINKEDIN_AUTH_LINK =
  linkedinAuthApi.addSearchParamsToUrl((routes) => ({
    redirectUri: originUrl.append(routes.rootRoutes.socialAccount),
  }));

// URL instances

export const socialMediaBaseUrl = new UrlHelper(
  backendApiUrl.append("/social_media/v1/public")
);

export const scheduleBaseUrl = new UrlHelper(
  socialMediaBaseUrl.append("/schedule")
);

export const socialCalendar = new UrlHelper(
  socialMediaBaseUrl.append("/calendar")
);
