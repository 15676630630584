import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 15% 10% 75%;
`;

const TopSection = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${helper.getSpacing("extraSmall")};
`;

const MenuBar = styled.div`
  padding-inline: ${helper.getSpacing("small")};
`;

const Body = styled.div`
  padding-inline: ${helper.getSpacing("small")};
  padding-top: ${helper.getSpacing("small")};
`;

export { Container, TopSection, MenuBar, Body };
