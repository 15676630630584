import { createContext, useEffect } from "react";
import { Spinner } from "components/common";
import { securityDetailsApi } from "api/Profile";
import { setSecurityDetails } from "redux/slices/Profile";
import { useDolphynAuth } from "hooks/Auth";
import { useReactQuery } from "hooks/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import {
  TChangePasswordPayload,
  TSecurityDetails,
} from "adapters/Profile/types";
import { useChangePassword } from "hooks/Profile";

export const SecurityDetailsContext = createContext({
  isLoading: false,
  handleUpdate: (data: Partial<TSecurityDetails>) => {},
  handleChangePassword: (data: TChangePasswordPayload) => {},
  fetch: () => {},
  details: {
    countryCode: "",
    phoneNumber: "",
    twoFactorAuth: false,
  } as TSecurityDetails,
});

const SecurityDetailsProvider = (props: React.PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const { isSignedIn } = useDolphynAuth();
  const details = useAppSelector((state) => state.profile.securityDetails);
  const { execute: fetch, isLoading: fetching } = useReactQuery(
    securityDetailsApi.get,
    {
      onSuccess: (data) => {
        dispatch(setSecurityDetails(data));
      },
    }
  );

  const { execute: handleUpdate, isLoading: updating } = useReactQuery(
    securityDetailsApi.patch,
    {
      onSuccess: (data) => {
        dispatch(setSecurityDetails(data));
      },
    }
  );

  const { execute: handleChangePassword, isLoading: updatingPassword } =
    useChangePassword();

  useEffect(() => {
    if (isSignedIn) fetch();
  }, [isSignedIn, fetch]);

  const isLoading = fetching || updating || updatingPassword;

  return (
    <SecurityDetailsContext.Provider
      value={{ details, handleUpdate, handleChangePassword, fetch, isLoading }}
    >
      <Spinner fullscreen hidden={!isLoading} />
      {props.children}
    </SecurityDetailsContext.Provider>
  );
};

export default SecurityDetailsProvider;
