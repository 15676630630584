import { Container } from "./ModuleLayout.styles";
import TopLogoLayout from "layout/common/TopLogoLayout";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <TopLogoLayout>
      <Container>{props.children}</Container>
    </TopLogoLayout>
  );
};

export default ModuleLayout;
