import {
  FacebookService,
  InstagramService,
  LinkedinService,
  TwitterService,
} from "services/Auth";
import { TTwitterAccount } from "services/Auth/twitterService";
import {
  IFacebookUsers,
  IInstagramUsers,
  ILinkedinUsers,
  IUserTokens,
  TFacebookUserToken,
  TInstagramUserToken,
  TLinkedinUserToken,
  TTwitterUserToken,
} from "./types";

type TAPIResponse = {
  facebook: TFacebookUserToken[];
  instagram: TInstagramUserToken[];
  twitter: TTwitterUserToken[];
  linkedin: TLinkedinUserToken[];
};

class UserTokensAdapter {
  private twitterService = new TwitterService();
  private facebookService = new FacebookService();
  private instagramService = new InstagramService();
  private linkedinService = new LinkedinService();

  private parseResponse = (apiResponse: TAPIResponse): IUserTokens => {
    const facebook = apiResponse.facebook.map((token) => {
      const uid = this.facebookService.getUid(token.id);

      return {
        name: token.name,
        tokenId: token.token_id,
        id: token.id,
        uid,
        profileImageUrl: token.profile_image,
        email: token.email,
        pageList: token.page_list.map((page) => {
          return {
            name: page.name,
            id: page.id,
            profileImageUrl: page.profile_image,
            tokenId: page.token_id,
          };
        }),
      } as IFacebookUsers;
    });

    const instagram = apiResponse.instagram.map((token) => {
      const uid = this.instagramService.getUid(token.facebook_account_id);

      return {
        uid,
        name: token.name,
        tokenId: token.token_id,
        id: token.facebook_account_id,
        profileImageUrl: token.profile_image,
        pageList: token.page_list.map((page) => {
          return {
            name: page.name,
            id: page.id,
            profileImageUrl: page.profile_image,
            pageId: page.facebook_page_id,
            pageName: page.facebook_page_name,
          };
        }),
      } as IInstagramUsers;
    });

    const linkedin = apiResponse.linkedin.map((token) => {
      const uid = this.linkedinService.getUid(token.id);
      return {
        id: token.id,
        uid,
        name: token.name,
        tokenId: token.token_id,
        profileImageUrl: token.profile_image,
        pageList: token.page_list.map((page) => {
          return {
            name: page.name,
            id: page.id,
            profileImageUrl: page.profile_image,
            tokenId: page.token_id,
          };
        }),
      } as ILinkedinUsers;
    });

    const twitter = apiResponse.twitter.map((token) => {
      const uid = this.twitterService.getUid(token.user_name);

      return {
        id: uid,
        uid,
        tokenId: token.token_id,
        name: token.name,
        userId: token.user_id,
        userName: token.user_name,
        profileImageUrl: token.profile_image,
      } as TTwitterAccount;
    });

    return {
      facebook,
      instagram,
      twitter,
      linkedin,
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default UserTokensAdapter;
