import { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDolphynAuth } from "hooks/Auth";
import { Button } from "components/core";

const LOGOUT_CONFIRMATION_MESSAGE = `For security reasons, we will sign you out of all your accounts on Dolphyn when you Log Out.
If you trust this browser and want to stay connected with all of your accounts, close the tab or browser only and open it next time.
Do you still want to log out?`;

interface ILogoutButtonProps {
  trigger: React.ReactNode;
}

const LogoutButton = (props: ILogoutButtonProps) => {
  const { handleSignout } = useDolphynAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };

  return (
    <Modal
      trigger={<div onClick={handleClick}>{props.trigger}</div>}
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
    >
      <Modal.Header>Tip</Modal.Header>
      <Modal.Content>
        <Modal.Description>{LOGOUT_CONFIRMATION_MESSAGE}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="alpha" onClick={handleSignout} content="Continue" />
        <Button
          variant="alpha"
          onClick={() => setModalOpen(false)}
          content="Cancel"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default LogoutButton;
