import { Redirect } from "components/core";
import { applicationRoutes as routes } from "config/common";
import {
  SchedulerProvider,
  ScheduleSecondaryPaneProvider,
  SelectPtatformProvider,
} from "context/SocialMediaPost";
import { SimpleRoute } from "controllers/common";
import {
  ActivityLayout,
  AIContentLayout,
  CalendarLayout,
  ContentCreationLayout,
  LiveTrendsLayout,
  SchedulerLayout,
} from "layout/ContentCreation";
import { AIContentPage } from "pages/ContentCreation";
import {
  LiveTrends,
  Activity,
  SchedulePost,
  Calendar,
} from "pages/SocialMediaPost";
import { Switch } from "react-router";

const ContentCreation = () => {
  return (
    <Switch>
      <SimpleRoute exact path={routes.rootRoutes.contentCreation}>
        <Redirect to={(routes) => routes.contentCreation.aiContent} />
      </SimpleRoute>
      <SimpleRoute
        exact
        layout={ContentCreationLayout}
        path={routes.contentCreation.aiContent}
      >
        <AIContentLayout>
          <AIContentPage />
        </AIContentLayout>
      </SimpleRoute>
      <SimpleRoute
        exact
        layout={ContentCreationLayout}
        path={routes.contentCreation.scheduler}
      >
        <SchedulerLayout>
          <SelectPtatformProvider>
            <SchedulerProvider>
              <ScheduleSecondaryPaneProvider>
                <SchedulePost />
              </ScheduleSecondaryPaneProvider>
            </SchedulerProvider>
          </SelectPtatformProvider>
        </SchedulerLayout>
      </SimpleRoute>
      <SimpleRoute
        exact
        layout={ContentCreationLayout}
        path={routes.contentCreation.activity}
      >
        <ActivityLayout>
          <Activity />
        </ActivityLayout>
      </SimpleRoute>
      <SimpleRoute
        exact
        layout={ContentCreationLayout}
        path={routes.contentCreation.calendar}
      >
        <CalendarLayout>
          <Calendar />
        </CalendarLayout>
      </SimpleRoute>
      <SimpleRoute
        exact
        layout={ContentCreationLayout}
        path={routes.contentCreation.liveTrends}
      >
        <LiveTrendsLayout>
          <LiveTrends />
        </LiveTrendsLayout>
      </SimpleRoute>
    </Switch>
  );
};

export default ContentCreation;
