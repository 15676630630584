import * as Yup from "yup";

const multiKeywordSearchValidation = Yup.object({
  keywords: Yup.array()
    .required("Keywords cannot be empty.")
    .max(10, "Maximum Keywords allowed are 10.")
    .test({
      name: "required",
      message: "Keywords cannot be empty.",
      test: (val) => {
        const keywords = val?.filter((keyword) => keyword !== "");
        return Array.isArray(keywords) && keywords.length > 0;
      },
    }),
});

export default multiKeywordSearchValidation;
