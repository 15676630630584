import { formatRelative } from "date-fns";
import {
  DateTime,
  MainContainer,
  Reply,
  ReplyUsers,
  SubTitle,
  Title,
  Container,
} from "./ReplyingUsername.styles";

interface IReplyingUsernameProps {
  name: string;
  username: string;
  datetime?: Date;
  replyingTo?: string;
}

const ReplyingUsername = ({
  name,
  username,
  datetime,
  replyingTo,
}: IReplyingUsernameProps) => {
  const renderDatetime = () => {
    if (!datetime) return null;
    const relativeTime = formatRelative(datetime, new Date());
    return <DateTime>{relativeTime}</DateTime>;
  };

  return (
    <MainContainer>
      <Container>
        <Title>{name}</Title>
        <SubTitle>@{username}</SubTitle>
        {renderDatetime()}
      </Container>
      {replyingTo && (
        <Container>
          <Reply>Replying to</Reply>
          <ReplyUsers>@{replyingTo}</ReplyUsers>
        </Container>
      )}
    </MainContainer>
  );
};

export default ReplyingUsername;
