import { Container } from "./AxisInfo.styles";

interface IAxisInfo {
  axis?: string;
  text: string;
}

const AxisInfo = ({ axis = "X", text }: IAxisInfo) => {
  return (
    <Container>
      On {axis} axis : <span>{text}</span>
    </Container>
  );
};

export default AxisInfo;
