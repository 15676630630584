import { CalendarWeeksHelper } from "helpers";

const useCalendarWeeks = () => {
  /* 
    Hook to handle state operations of Date[] to be used in Calendar Month view
  
  */

  const helper = new CalendarWeeksHelper();
  const getViewDates = (dates: Date[]) => helper.getViewDates(dates[0]);
  const getCurrentWeekViewDates = () => helper.getViewDates(new Date());
  const getNextWeekDates = (dates: Date[]) => {
    const nextWeek = helper.getNextWeek(dates[0]);
    return helper.getViewDates(nextWeek);
  };
  const getPreviousWeekDates = (dates: Date[]) => {
    const prevWeek = helper.getPreviousWeek(dates[0]);
    return helper.getViewDates(prevWeek);
  };
  return {
    getViewDates,
    getCurrentWeekViewDates,
    getNextWeekDates,
    getPreviousWeekDates,
  };
};

export default useCalendarWeeks;
