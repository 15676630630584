import { useEffect } from "react";
import { useReactQuery } from "hooks/common";
import { scheduleFacebook as api } from "api/SocialMediaPost";
import { useFacebookAuth } from "hooks/Auth";
import { useEventCalendar } from "hooks/common";
import { TFacebookPostObject } from "adapters/SocialMediaPost/types";
import { useFacebookPosts } from ".";

type TOptions = {
  onSuccess: (data: TFacebookPostObject[]) => void;
};

const useFacebookCalendar = (options?: TOptions) => {
  const fbAuth = useFacebookAuth();
  const uid = fbAuth.selectedAccount.uid;
  const { execute, isLoading: fetching } = useReactQuery(api.fetchCalendar, {
    onSuccess: (data) => options?.onSuccess(data),
  });
  const { create, update, remove, isLoading } = useFacebookPosts();
  const { startDate, endDate } = useEventCalendar();

  useEffect(() => {
    if (uid) {
      execute({
        uid,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [endDate, startDate, uid, execute]);

  return { create, update, remove, isLoading: fetching || isLoading };
};

export default useFacebookCalendar;
