import { Button, LinkButton } from "components/core";
import { useNavigation } from "hooks";
import { TextSection } from "../Common";
import { ButtonContainer } from "../Common/styles";

const DolphyInfo = () => {
  const nav = useNavigation();

  return (
    <TextSection style={{ marginBottom: "6rem" }}>
      <TextSection.Header as="h2">
        How
        <span>Dolphy</span>
        works?
      </TextSection.Header>
      <TextSection.Description>
        <p>
          Dolphy reduces time to build relationship with your potential leads,
          and convert them as paid customers. Dolphy uses AI-text analysis,
          automation and reply suggestions feature to accelerate your lead
          nurturing process.
        </p>
        <p>
          Dolphy's AI recommendation engine suggests you right leads, tweets,
          influencers and help you to identify the interest level of your
          potential customers.
        </p>
        <ButtonContainer>
          <Button
            variant="alpha"
            content="Sign Up"
            onClick={() => nav.navigate((routes) => routes.rootRoutes.signUp)}
          />
          <LinkButton
            content="Watch Video"
            style={{
              fontWeight: "500",
              cursor: "pointer",
            }}
          />
        </ButtonContainer>
      </TextSection.Description>
    </TextSection>
  );
};

export default DolphyInfo;
