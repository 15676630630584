import { TGeneralSizes } from "./types";

type TOptions = {
  unit?: string;
  multiplier?: number;
};

class Size {
  private sizes: TGeneralSizes = {
    tiny: 25,
    extraSmall: 50,
    small: 100,
    medium: 160,
    large: 220,
    extraLarge: 280,
    huge: 340,
    none: 0,
  };

  constructor(initialValues?: TGeneralSizes) {
    if (initialValues) {
      this.sizes = initialValues;
    }
  }

  getGeneralSize = (
    value: keyof TGeneralSizes = "small",
    options?: TOptions
  ) => {
    const { unit = "", multiplier = 1 } = options || {};

    return `${(this.sizes[value] / 100) * multiplier}${unit}`;
  };
}

export default Size;
