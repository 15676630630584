import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";
import { TPaddingProps } from "theme/types";

interface IStyledFormProps extends TPaddingProps {}

const helper = new ThemeHelper();

const StyledForm = styled.form<IStyledFormProps>`
  ${({
    pt = "small",
    ps = "small",
    pe = "medium",
    pb = "medium",
    px,
    py,
    padding,
  }) =>
    helper.getPaddings({
      top: pt,
      bottom: pb,
      left: ps,
      right: pe,
      inline: px,
      block: py,
      all: padding,
    })};
`;

export { StyledForm };
