import { TCustomerPortalPayload } from "./types";

type TAPIResponse = {
  redirect_url: string;
};

type TAPIPayload = {
  return_url: string;
};

class CustomerPortalAdapter {
  private parseResponse = (data: TAPIResponse) => ({
    redirectUrl: data.redirect_url,
  });

  getPostPayload = (
    values: TCustomerPortalPayload
  ): { params: TAPIPayload } => ({
    params: { return_url: values.returnUrl },
  });

  parsePostResponse = (data: TAPIResponse) => this.parseResponse(data);
}

export default CustomerPortalAdapter;
