import { format } from "date-fns";
import NavigateDate from "./NavigateDate";
import { useEventCalendar } from "hooks/common";

const DayTools = () => {
  const eventCalendar = useEventCalendar();

  const renderText = () => format(eventCalendar.startDate, "dd MMMM yyyy");

  return (
    <NavigateDate
      onForwardClick={eventCalendar.goToNextDay}
      onBackwardClick={eventCalendar.goToPreviousDay}
      text={renderText()}
    />
  );
};

export default DayTools;
