export type TSearchParams = {
  [prop: string]: string | number;
};

class BaseUrlHelper {
  readonly url: string;

  constructor(path: string) {
    this.url = path;
  }
  append(path: string) {
    return `${this.url}${path}`;
  }
  addSearchParamsToUrl(paramsObject: TSearchParams) {
    const params = new URLSearchParams();
    Object.entries(paramsObject).forEach(([key, value]) => {
      params.set(key, value.toString());
    });
    return `${this.url}?${params.toString()}`;
  }
}

export default BaseUrlHelper;
