import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { setAutodm } from "redux/slices/Twitter";
import { IAutoDm } from "adapters/Twitter/types";

const useAutodm = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.twitter.autodm.data);

  return {
    data,
    setAutodm: (data: IAutoDm) => dispatch(setAutodm(data)),
  };
};

export default useAutodm;
