export const fetchLeads = ["Collecting Your Leads List"];

export const addingInfluencer = ["Adding profile in Influencer List"];

export const removingLead = ["Removing from Leads List"];

export const removingInfluencer = ["Removing from Influencers list"];

export const addingCustomer = [
  "Adding a profile to the leads list, not following on Twitter",
];

export const keywordsSearch = [
  "Performing text analysis using your keywords",
  "Fetching right tweets",
];

export const connectWithTwitter = ["Connecting with Twitter account"];

export const potentialLead = [
  "Collecting bio, Frequently Used Keywords of Profiles",
  "Hold Tight ",
];

export const leadNurturingLoader = [
  "Readying campaigns to boost relationship",
  "Add 5 to 10 keywords to start the campaign",
];

export const autoWelcomeLoader = [
  "Think about welcome message that your followers would embrace ",
];

export const likeMessage = ["Delivering you heartful like miles away"];

export const recentTweetMessage = ["Fetching last 20 tweets"];

export const tweetSearchScheduleRetweetMessage = ["Scheduling Retweet"];

export const followingOnTwitterMessage = ["Following / Unfollowing on Twitter"];
