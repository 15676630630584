import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: ${helper.getSpacing("large")};
  position: relative;
`;

export default Container;
