import { useField } from "formik";
import { languagesListConfig } from "config/common";
import { QuestionCard } from "../common";
import { SuggestionsContainer } from "./Language.styles";
import { Text as Suggestion } from "components/core";
import { TCompleteRegistrationFormValues } from "./types";
import { useRegistrationSteps } from "hooks/Profile";

interface ILanguageProps {
  name: string;
}

const Language = (props: ILanguageProps) => {
  const [field, , helpers] = useField<
    TCompleteRegistrationFormValues["language"]
  >(props.name);
  const steps = useRegistrationSteps();

  const handleSetValue = (
    payload: TCompleteRegistrationFormValues["language"]
  ) => {
    helpers.setValue(payload);
    steps.handleGoToNextStep();
  };

  return (
    <>
      <QuestionCard.Header
        as="p"
        size="medium"
        font="poppins"
        fontWeight="high"
        variant="darkGrey"
        textAlign="center"
        content="Select your Language"
      />
      <QuestionCard.Dropdown
        options={languagesListConfig}
        placeholder="Select Language"
        selection
        search
        value={field.value}
        onChange={(e, { value }) => {
          if (typeof value === "string") {
            handleSetValue(value);
          }
        }}
      />
      <SuggestionsContainer>
        <Suggestion
          variant="alpha"
          as="p"
          content="Suggestions: "
          style={{ margin: "unset" }}
        />
        <Suggestion
          style={{ cursor: "pointer" }}
          variant="lightGrey"
          as="span"
          content="Hindi"
          onClick={() => handleSetValue("Hindi")}
        />
        <Suggestion
          style={{ cursor: "pointer" }}
          variant="lightGrey"
          as="span"
          content="Spanish"
          onClick={() => handleSetValue("Spanish; Castilian")}
        />
      </SuggestionsContainer>
    </>
  );
};

export default Language;
