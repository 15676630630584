import { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { SimpleRoute } from "controllers/common";
import { DashboardLayout } from "layout/Dashboard";
import { Spinner } from "components/common";

const DashboardPage = lazy(() => import("pages/Dashboard/Dashboard"));

const Dashboard = () => {
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <SimpleRoute
          layout={DashboardLayout}
          exact
          path={routes.rootRoutes.dashboard}
        >
          <DashboardPage />
        </SimpleRoute>
      </Switch>
    </Suspense>
  );
};

export default Dashboard;
