import { ChangeAccountCards } from "components/SocialMediaPost/common";
import styled from "styled-components";

const AccountsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-evenly;
  gap: 2.5rem;
`;

const SocialAccount: React.FC = () => {
  return (
    <AccountsGrid>
      <ChangeAccountCards />
    </AccountsGrid>
  );
};

export default SocialAccount;
