import { ModuleMenu, TextSection } from "components/common";
import {
  ModalIcon,
  Recommendation,
} from "components/ProductDemand/Recommendation";
import { SelectCountry } from "components/ProductDemand/SelectCountry";
import { moduleMenuItems } from "config/ProductDemand";
import { Grid, TopSection, Body, MenuBar } from "./ModuleLayout.styles";

const ModuleLayout = (props: React.PropsWithChildren<{}>) => {
  return (
    <Grid>
      <TopSection>
        <div className="modal-icon">
          <TextSection header="Product Search Demand" />
          <ModalIcon />
        </div>
        <SelectCountry />
      </TopSection>
      <Recommendation />
      <MenuBar>
        <ModuleMenu items={moduleMenuItems} />
      </MenuBar>
      <Body>{props.children}</Body>
    </Grid>
  );
};

export default ModuleLayout;
