import { Button } from "components/core";
import { useNavigation } from "hooks/common";
import { Section, Text } from "./CallToAction.styles";

const Pricing = () => {
  const nav = useNavigation();

  return (
    <Section>
      <Text>
        Twitter is direct conversation networking site unlike Facebook,
        Instagram or Pinterest which lead visuals sharing.
        <span> Increase your communication and reduce your CAC by 56%.</span>
      </Text>
      <Button
        variant="alpha"
        size="large"
        content="Sign Up"
        onClick={() => nav.navigate((routes) => routes.rootRoutes.signUp)}
      />
    </Section>
  );
};

export default Pricing;
