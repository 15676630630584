import { useField } from "formik";
import { QuestionCard } from "../common";
import { TCompleteRegistrationFormValues } from "./types";

interface IEmailProps {
  name: string;
}

const Email = ({ name }: IEmailProps) => {
  const [field, , helpers] =
    useField<TCompleteRegistrationFormValues["email"]>(name);

  return (
    <>
      <QuestionCard.Header
        as="h1"
        size="medium"
        font="poppins"
        fontWeight="high"
        variant="darkGrey"
        textAlign="center"
        content="What is your email?"
      />
      <QuestionCard.Input
        placeholder="Enter your email"
        type="email"
        style={{ boxShadow: "unset" }}
        value={field.value}
        onChange={(e) => helpers.setValue(e.target.value)}
        required
      />
    </>
  );
};

export default Email;
