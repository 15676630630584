import { useChangingText } from "hooks/common";
import { TextWrapper } from "./LoaderText.styles";
import { ILoaderTextProps } from "./types";

const LoaderText = (props: ILoaderTextProps) => {
  const { currentText } = useChangingText(props.textData);

  return <TextWrapper key={currentText}>{currentText}</TextWrapper>;
};

export default LoaderText;
