import styled from "styled-components";
import { TProfileNavItem } from "./types";
import { Header, Icon } from "components/core";
import { profileNavItemsMap } from "config/Profile/profileNavItemsMap";
import ThemeHelper from "theme/themeHelper";
import { useDolphynAuth } from "hooks";
import { useAdmin } from "hooks/Admin";

const helper = new ThemeHelper();

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const SubNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("medium")};
  &&& p {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.alpha};
    }
  }
`;

const LogoutContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  &:hover,
  &&& p:hover {
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

interface INavProps {
  activeItem: TProfileNavItem;
  onChange: (item: TProfileNavItem) => void;
}

const Nav = ({ activeItem, onChange }: INavProps) => {
  const { handleSignout } = useDolphynAuth();
  const { isAdmin } = useAdmin();

  const getActiveStatus = (item: TProfileNavItem) => {
    return activeItem === item ? "alpha" : "darkGrey";
  };

  return (
    <Container>
      <SubNav>
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Personal Details"
          variant={getActiveStatus(profileNavItemsMap.personalDetails)}
          onClick={() => onChange(profileNavItemsMap.personalDetails)}
        />
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Security Details"
          variant={getActiveStatus(profileNavItemsMap.securityDetails)}
          onClick={() => onChange(profileNavItemsMap.securityDetails)}
        />
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Keyword Details"
          variant={getActiveStatus(profileNavItemsMap.keywordsDetails)}
          onClick={() => onChange(profileNavItemsMap.keywordsDetails)}
        />
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Payment Details"
          variant={getActiveStatus(profileNavItemsMap.paymentDetails)}
          onClick={() => onChange(profileNavItemsMap.paymentDetails)}
        />
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Notifcations"
          variant={getActiveStatus(profileNavItemsMap.notifications)}
          onClick={() => onChange(profileNavItemsMap.notifications)}
        />
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Invite Users"
          variant={getActiveStatus(profileNavItemsMap.inviteEnterprises)}
          onClick={() => onChange(profileNavItemsMap.inviteEnterprises)}
        />
        <Header
          as="p"
          font="poppins"
          size="small"
          content="Redeem Coupon"
          variant={getActiveStatus(profileNavItemsMap.redeemCoupon)}
          onClick={() => onChange(profileNavItemsMap.redeemCoupon)}
        />
        {isAdmin ? (
          <Header
            as="p"
            font="poppins"
            size="small"
            content="Generate Coupons"
            variant={getActiveStatus(profileNavItemsMap.generateCoupon)}
            onClick={() => onChange(profileNavItemsMap.generateCoupon)}
          />
        ) : null}
      </SubNav>
      <SubNav>
        <LogoutContainer onClick={handleSignout}>
          <Icon name="power off" size="medium" />
          <Header as="p" font="poppins" size="small" content="Logout" />
        </LogoutContainer>
      </SubNav>
    </Container>
  );
};

export default Nav;
