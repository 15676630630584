import { createContext } from "react";
import { useQuery } from "react-query";
import { fetchInfluencersList } from "api/Twitter";
import { Loader } from "components/common";
import { IInfluencersList } from "adapters/Twitter/types";
import { scheduleRetweetLoader } from "config/Twitter";
import { useTwitterAuth } from "hooks";

type TInfluencersListContext = {
  data: IInfluencersList;
};

const initialData: IInfluencersList = {
  influencerList: [],
  tweetActions: false,
};

export const InfluencersListContext =
  createContext<TInfluencersListContext | null>(null);

const InfluencersListProvider = (props: React.PropsWithChildren<{}>) => {
  const { isSignedIn } = useTwitterAuth();
  const { data = initialData, isLoading } = useQuery(
    ["twitter", "influencersList"],
    () => fetchInfluencersList({ isAuthenticated: isSignedIn }),
    {
      staleTime: 900000, // 15 minutes
      cacheTime: 3600000, // 1 hour
    }
  );

  return (
    <InfluencersListContext.Provider value={{ data }}>
      <Loader textData={scheduleRetweetLoader} hidden={!isLoading} />
      {props.children}
    </InfluencersListContext.Provider>
  );
};

export default InfluencersListProvider;
