import CreateError from "./createError";

type TErrorBag<T extends TErrorList> = {
  [key in keyof T]: CreateError;
};

type TErrorList = {
  [name: string]: string;
};

class APIError<T extends TErrorList> {
  private defaultError: CreateError;
  private errorBag: TErrorBag<T>;
  constructor(defaultError = "Something went wrong", errorList?: T) {
    this.defaultError = new CreateError(defaultError);
    this.errorBag = {} as TErrorBag<T>;
    if (errorList) {
      this.setErrorList(errorList);
    }
  }
  private setErrorList = (errorList: TErrorList) => {
    Object.entries(errorList).forEach(([name, message]) => {
      this.errorBag = {
        ...this.errorBag,
        [name]: new CreateError(message),
      };
    });
  };
  private errorMessageExists = (e: unknown) => {
    const errorObject = e as { [prop: string]: any };
    if (
      errorObject?.response?.data?.error_message &&
      typeof errorObject?.response?.data?.error_message === "string"
    ) {
      const message = errorObject.response.data.error_message as string;
      return message;
    }
  };
  throwError = (errorBagItem?: keyof APIError<T>["errorBag"]) => {
    if (errorBagItem) throw this.errorBag[errorBagItem];
    throw this.defaultError;
  };
  throwAxiosError = (
    e: unknown,
    errorBagItem?: keyof APIError<T>["errorBag"]
  ) => {
    if (e instanceof CreateError) throw e;
    const errorMessage = this.errorMessageExists(e);
    if (errorMessage) {
      throw new CreateError(errorMessage);
    }
    this.throwError(errorBagItem);
  };
}

export default APIError;
