import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 10fr 2fr;
  height: 100%;
  gap: ${helper.getSpacing("extraSmall")};
  padding: ${helper.getSpacing("extraSmall")};
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  ${helper.getFontSize("extraSmall")};
  ${helper.getFontWeight("low")};
  text-align: center;
  margin: unset;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: unset;
`;

const Footer = styled.div``;

export { Container, Title, Actions, Footer };
