import { StyledText } from "./Text.styles";
import { ITextProps } from "./types";

const Text = ({ size, variant, text }: ITextProps) => {
  if (!text) return null;

  return (
    <StyledText size={size} variant={variant}>
      {text}
    </StyledText>
  );
};

export default Text;
