import { APIError } from "errors";
import { dolphynAPI } from "api/instances";
import { links, constants } from "config/Profile";
import { ChangePasswordAdapter } from "adapters/Profile";
import { TChangePasswordPayload } from "adapters/Profile/types";

const URL = links.BASE_PROFILE_API_URL.append("/change_password");
const adapter = new ChangePasswordAdapter();
const error = new APIError(constants.DEFAULT_API_ERROR);

const changePassword = {
  post: async (values: TChangePasswordPayload) => {
    try {
      const payload = adapter.getPostPayload(values);
      const { data } = await dolphynAPI.post(URL, payload);

      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default changePassword;
