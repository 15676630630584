import ProfileSection from "./ProfileSection";
import { useBoolean } from "hooks/common";
import KeywordsDetailsForm from "./KeywordsDetailsForm";
import { useKeywordsDetails } from "hooks/Profile";
import { Formik } from "formik";
import { keywordsDetailsValidation } from "config/Profile";

const FORM_ID = "KeywordDetails";

const KeywordDetails = () => {
  const keywordsDetails = useKeywordsDetails();

  const [editable, setEditableTrue, setEditableFalse] = useBoolean();

  return (
    <ProfileSection>
      <Formik
        initialValues={keywordsDetails.details}
        validationSchema={keywordsDetailsValidation}
        onSubmit={(values) => {
          keywordsDetails.handleUpdate(values);
          setEditableFalse();
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <ProfileSection.Actions
              editable={editable}
              onCancel={() => {
                resetForm();
                setEditableFalse();
              }}
              onEdit={setEditableTrue}
              formId={FORM_ID}
            />
            <ProfileSection.Content>
              <KeywordsDetailsForm
                editable={editable}
                name={{
                  primary: "primaryKeyword",
                  secondary: "secondaryKeywords",
                }}
                formId={FORM_ID}
                onSubmit={handleSubmit}
              />
            </ProfileSection.Content>
          </>
        )}
      </Formik>
    </ProfileSection>
  );
};

export default KeywordDetails;
