import styled from "styled-components";

const Container = styled.div`
  color: black;
  text-align: center;
  display: grid;
  justify-items: center;
  align-content: center;
  position: absolute;
  z-index: 10;
  bottom: 1%;
  right: 25%;
  width: 16vw;
  height: 175px;
  border: 1px dashed black;
  border-radius: 15px;
`;

export { Container };
