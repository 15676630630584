import styled, { css } from "styled-components";

type TIconProps = {
  disabled?: boolean;
};

const Container = styled.div<TIconProps>`
  border-radius: 6px;
  padding: 0.4rem;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

interface IIconButtonProps
  extends TIconProps,
    React.HTMLAttributes<HTMLDivElement> {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const IconButton = ({ icon: Icon, ...rest }: IIconButtonProps) => {
  return <Container {...rest}>{<Icon />}</Container>;
};

export default IconButton;
