import { TBaseSocialMediaPost } from "adapters/SocialMediaPost/types";
import { CollectionHelper } from "helpers";

enum Actions {
  SET = "SET",
  UPDATE = "UPDATE",
  REMOVE = "REMOVE",
}

type TState<T extends TBaseSocialMediaPost> = {
  data: T[];
};

type TAction<T extends TBaseSocialMediaPost> =
  | {
      type: Actions.SET;
      payload: T[];
    }
  | {
      type: Actions.UPDATE;
      payload: T;
    }
  | {
      type: Actions.REMOVE;
      payload: string;
    };

const reducer = <T extends TBaseSocialMediaPost>(
  state: TState<T>,
  action: TAction<T>
): TState<T> => {
  switch (action.type) {
    case Actions.SET: {
      const data = action.payload;
      return {
        ...state,
        data,
      };
    }

    case Actions.UPDATE: {
      const helper = new CollectionHelper<T>();
      const data = helper.updateItem(state.data, action.payload);
      return {
        ...state,
        data,
      };
    }

    case Actions.REMOVE: {
      const helper = new CollectionHelper<T>();
      const data = helper.removeItem(state.data, action.payload);
      return {
        ...state,
        data,
      };
    }

    default:
      return state;
  }
};

export { Actions, reducer };
export type { TState, TAction };
