import { dolphynPublicRoot } from "api/instances";
import { throwApiError } from "errors";
import { links, constants } from "config/Auth";
import { AuthService } from "services/Auth";

const URL = links.BASE_ACCOUNTS_API_URL.append("/social_auth/google");

export interface ISocialLoginGoogle {
  data: { accessToken: string; idToken: string };
  redirectUri?: string;
}
const authService = new AuthService();

const socialLoginGoogle = async ({
  data,
  redirectUri = links.SOCIAL_LOGIN_DEFAULT_REDIRECT_URL,
}: ISocialLoginGoogle) => {
  try {
    const { accessToken: access_token, idToken: id_token } = data;

    const { data: apiData } = await dolphynPublicRoot.post(
      URL,
      { access_token, id_token },
      {
        headers: {
          redirectUri,
        },
      }
    );

    if (!("access_token" in apiData)) {
      throw new Error();
    }

    const { access_token: access, refresh_token: refresh } = apiData;

    authService.setCookies(access, refresh);

    return { access, refresh };
  } catch (e) {
    throwApiError(e, constants.DEFAULT_API_ERROR);
  }
};

export default socialLoginGoogle;
