import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const OtherContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${helper.getSpacing("small")};
`;

export { OtherContainer, FormContainer };
