import { css, DefaultColors } from "styled-components";
import { TBorderRadii, TBorderThickness } from "./types";

type TOptions = {
  variant?: keyof DefaultColors;
  type?: keyof React.CSSProperties["borderStyle"];
  important?: boolean;
  radius?: keyof TBorderRadii;
};

class Border {
  private sizes: TBorderRadii = {
    small: 5,
    medium: 10,
    large: 15,
  };
  getBorderRadiusValue = (value: keyof TBorderRadii) => {
    return `${this.sizes[value]}px`;
  };

  getBorderRadius = (value: keyof TBorderRadii) => {
    return css`
      border-radius: ${this.getBorderRadiusValue(value)};
    `;
  };

  private getBorderThickness = (value: keyof TBorderThickness) => {
    return `${this.sizes[value] / 5}px`;
  };

  getBorder = (size: keyof TBorderThickness = "small", options?: TOptions) => {
    const {
      variant = "extraLightGrey",
      type = "solid",
      radius = "small",
    } = options || {};

    return css`
      ${({ theme }) =>
        css`
          border: ${this.getBorderThickness(size)} ${type}
            ${theme.colors[variant]};
        `};
      ${this.getBorderRadius(radius)};
    `;
  };
}

export default Border;
