import { Dimmer } from "semantic-ui-react";
import { Container, Graphic, Message } from "./Spinner.styles";

interface ISpinnerProps {
  message?: string;
  fullscreen?: boolean;
  hidden?: boolean;
}

const Spinner = ({ message, fullscreen, hidden }: ISpinnerProps) => {
  if (hidden) return null;

  if (fullscreen) {
    return (
      <Dimmer active page>
        <Container>
          <Graphic></Graphic>
          <Message>{message}</Message>
        </Container>
      </Dimmer>
    );
  }
  return (
    <>
      <Graphic></Graphic>
      <Message>{message}</Message>
    </>
  );
};

export default Spinner;
