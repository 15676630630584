import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${helper.getSpacing("small")};
`;

export { HeaderContainer };
