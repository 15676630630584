export const telephoneCodeList = [
  {
    key: "MCs5NzI=",
    text: "Israel (+972)",
    flag: "il",
    value: "+972",
  },
  {
    key: "MSs5Mw==",
    text: "Afghanistan (+93)",
    flag: "af",
    value: "+93",
  },
  {
    key: "MiszNTU=",
    text: "Albania (+355)",
    flag: "al",
    value: "+355",
  },
  {
    key: "MysyMTM=",
    text: "Algeria (+213)",
    flag: "dz",
    value: "+213",
  },
  {
    key: "NCsxIDY4NA==",
    text: "AmericanSamoa (+1 684)",
    flag: "as",
    value: "+1 684",
  },
  {
    key: "NSszNzY=",
    text: "Andorra (+376)",
    flag: "ad",
    value: "+376",
  },
  {
    key: "NisyNDQ=",
    text: "Angola (+244)",
    flag: "ao",
    value: "+244",
  },
  {
    key: "NysxIDI2NA==",
    text: "Anguilla (+1 264)",
    flag: "ai",
    value: "+1 264",
  },
  {
    key: "OCsxMjY4",
    text: "Antigua and Barbuda (+1268)",
    flag: "ag",
    value: "+1268",
  },
  {
    key: "OSs1NA==",
    text: "Argentina (+54)",
    flag: "ar",
    value: "+54",
  },
  {
    key: "MTArMzc0",
    text: "Armenia (+374)",
    flag: "am",
    value: "+374",
  },
  {
    key: "MTErMjk3",
    text: "Aruba (+297)",
    flag: "aw",
    value: "+297",
  },
  {
    key: "MTIrNjE=",
    text: "Australia (+61)",
    flag: "au",
    value: "+61",
  },
  {
    key: "MTMrNDM=",
    text: "Austria (+43)",
    flag: "at",
    value: "+43",
  },
  {
    key: "MTQrOTk0",
    text: "Azerbaijan (+994)",
    flag: "az",
    value: "+994",
  },
  {
    key: "MTUrMSAyNDI=",
    text: "Bahamas (+1 242)",
    flag: "bs",
    value: "+1 242",
  },
  {
    key: "MTYrOTcz",
    text: "Bahrain (+973)",
    flag: "bh",
    value: "+973",
  },
  {
    key: "MTcrODgw",
    text: "Bangladesh (+880)",
    flag: "bd",
    value: "+880",
  },
  {
    key: "MTgrMSAyNDY=",
    text: "Barbados (+1 246)",
    flag: "bb",
    value: "+1 246",
  },
  {
    key: "MTkrMzc1",
    text: "Belarus (+375)",
    flag: "by",
    value: "+375",
  },
  {
    key: "MjArMzI=",
    text: "Belgium (+32)",
    flag: "be",
    value: "+32",
  },
  {
    key: "MjErNTAx",
    text: "Belize (+501)",
    flag: "bz",
    value: "+501",
  },
  {
    key: "MjIrMjI5",
    text: "Benin (+229)",
    flag: "bj",
    value: "+229",
  },
  {
    key: "MjMrMSA0NDE=",
    text: "Bermuda (+1 441)",
    flag: "bm",
    value: "+1 441",
  },
  {
    key: "MjQrOTc1",
    text: "Bhutan (+975)",
    flag: "bt",
    value: "+975",
  },
  {
    key: "MjUrMzg3",
    text: "Bosnia and Herzegovina (+387)",
    flag: "ba",
    value: "+387",
  },
  {
    key: "MjYrMjY3",
    text: "Botswana (+267)",
    flag: "bw",
    value: "+267",
  },
  {
    key: "MjcrNTU=",
    text: "Brazil (+55)",
    flag: "br",
    value: "+55",
  },
  {
    key: "MjgrMjQ2",
    text: "British Indian Ocean Territory (+246)",
    flag: "io",
    value: "+246",
  },
  {
    key: "MjkrMzU5",
    text: "Bulgaria (+359)",
    flag: "bg",
    value: "+359",
  },
  {
    key: "MzArMjI2",
    text: "Burkina Faso (+226)",
    flag: "bf",
    value: "+226",
  },
  {
    key: "MzErMjU3",
    text: "Burundi (+257)",
    flag: "bi",
    value: "+257",
  },
  {
    key: "MzIrODU1",
    text: "Cambodia (+855)",
    flag: "kh",
    value: "+855",
  },
  {
    key: "MzMrMjM3",
    text: "Cameroon (+237)",
    flag: "cm",
    value: "+237",
  },
  {
    key: "MzQrMQ==",
    text: "Canada (+1)",
    flag: "ca",
    value: "+1",
  },
  {
    key: "MzUrMjM4",
    text: "Cape Verde (+238)",
    flag: "cv",
    value: "+238",
  },
  {
    key: "MzYrIDM0NQ==",
    text: "Cayman Islands (+ 345)",
    flag: "ky",
    value: "+ 345",
  },
  {
    key: "MzcrMjM2",
    text: "Central African Republic (+236)",
    flag: "cf",
    value: "+236",
  },
  {
    key: "MzgrMjM1",
    text: "Chad (+235)",
    flag: "td",
    value: "+235",
  },
  {
    key: "MzkrNTY=",
    text: "Chile (+56)",
    flag: "cl",
    value: "+56",
  },
  {
    key: "NDArODY=",
    text: "China (+86)",
    flag: "cn",
    value: "+86",
  },
  {
    key: "NDErNjE=",
    text: "Christmas Island (+61)",
    flag: "cx",
    value: "+61",
  },
  {
    key: "NDIrNTc=",
    text: "Colombia (+57)",
    flag: "co",
    value: "+57",
  },
  {
    key: "NDMrMjY5",
    text: "Comoros (+269)",
    flag: "km",
    value: "+269",
  },
  {
    key: "NDQrMjQy",
    text: "Congo (+242)",
    flag: "cg",
    value: "+242",
  },
  {
    key: "NDUrNjgy",
    text: "Cook Islands (+682)",
    flag: "ck",
    value: "+682",
  },
  {
    key: "NDYrNTA2",
    text: "Costa Rica (+506)",
    flag: "cr",
    value: "+506",
  },
  {
    key: "NDcrMzg1",
    text: "Croatia (+385)",
    flag: "hr",
    value: "+385",
  },
  {
    key: "NDgrNTM=",
    text: "Cuba (+53)",
    flag: "cu",
    value: "+53",
  },
  {
    key: "NDkrNTM3",
    text: "Cyprus (+537)",
    flag: "cy",
    value: "+537",
  },
  {
    key: "NTArNDIw",
    text: "Czech Republic (+420)",
    flag: "cz",
    value: "+420",
  },
  {
    key: "NTErNDU=",
    text: "Denmark (+45)",
    flag: "dk",
    value: "+45",
  },
  {
    key: "NTIrMjUz",
    text: "Djibouti (+253)",
    flag: "dj",
    value: "+253",
  },
  {
    key: "NTMrMSA3Njc=",
    text: "Dominica (+1 767)",
    flag: "dm",
    value: "+1 767",
  },
  {
    key: "NTQrMSA4NDk=",
    text: "Dominican Republic (+1 849)",
    flag: "do",
    value: "+1 849",
  },
  {
    key: "NTUrNTkz",
    text: "Ecuador (+593)",
    flag: "ec",
    value: "+593",
  },
  {
    key: "NTYrMjA=",
    text: "Egypt (+20)",
    flag: "eg",
    value: "+20",
  },
  {
    key: "NTcrNTAz",
    text: "El Salvador (+503)",
    flag: "sv",
    value: "+503",
  },
  {
    key: "NTgrMjQw",
    text: "Equatorial Guinea (+240)",
    flag: "gq",
    value: "+240",
  },
  {
    key: "NTkrMjkx",
    text: "Eritrea (+291)",
    flag: "er",
    value: "+291",
  },
  {
    key: "NjArMzcy",
    text: "Estonia (+372)",
    flag: "ee",
    value: "+372",
  },
  {
    key: "NjErMjUx",
    text: "Ethiopia (+251)",
    flag: "et",
    value: "+251",
  },
  {
    key: "NjIrMjk4",
    text: "Faroe Islands (+298)",
    flag: "fo",
    value: "+298",
  },
  {
    key: "NjMrNjc5",
    text: "Fiji (+679)",
    flag: "fj",
    value: "+679",
  },
  {
    key: "NjQrMzU4",
    text: "Finland (+358)",
    flag: "fi",
    value: "+358",
  },
  {
    key: "NjUrMzM=",
    text: "France (+33)",
    flag: "fr",
    value: "+33",
  },
  {
    key: "NjYrNTk0",
    text: "French Guiana (+594)",
    flag: "gf",
    value: "+594",
  },
  {
    key: "NjcrNjg5",
    text: "French Polynesia (+689)",
    flag: "pf",
    value: "+689",
  },
  {
    key: "NjgrMjQx",
    text: "Gabon (+241)",
    flag: "ga",
    value: "+241",
  },
  {
    key: "NjkrMjIw",
    text: "Gambia (+220)",
    flag: "gm",
    value: "+220",
  },
  {
    key: "NzArOTk1",
    text: "Georgia (+995)",
    flag: "ge",
    value: "+995",
  },
  {
    key: "NzErNDk=",
    text: "Germany (+49)",
    flag: "de",
    value: "+49",
  },
  {
    key: "NzIrMjMz",
    text: "Ghana (+233)",
    flag: "gh",
    value: "+233",
  },
  {
    key: "NzMrMzUw",
    text: "Gibraltar (+350)",
    flag: "gi",
    value: "+350",
  },
  {
    key: "NzQrMzA=",
    text: "Greece (+30)",
    flag: "gr",
    value: "+30",
  },
  {
    key: "NzUrMjk5",
    text: "Greenland (+299)",
    flag: "gl",
    value: "+299",
  },
  {
    key: "NzYrMSA0NzM=",
    text: "Grenada (+1 473)",
    flag: "gd",
    value: "+1 473",
  },
  {
    key: "NzcrNTkw",
    text: "Guadeloupe (+590)",
    flag: "gp",
    value: "+590",
  },
  {
    key: "NzgrMSA2NzE=",
    text: "Guam (+1 671)",
    flag: "gu",
    value: "+1 671",
  },
  {
    key: "NzkrNTAy",
    text: "Guatemala (+502)",
    flag: "gt",
    value: "+502",
  },
  {
    key: "ODArMjI0",
    text: "Guinea (+224)",
    flag: "gn",
    value: "+224",
  },
  {
    key: "ODErMjQ1",
    text: "Guinea-Bissau (+245)",
    flag: "gw",
    value: "+245",
  },
  {
    key: "ODIrNTk1",
    text: "Guyana (+595)",
    flag: "gy",
    value: "+595",
  },
  {
    key: "ODMrNTA5",
    text: "Haiti (+509)",
    flag: "ht",
    value: "+509",
  },
  {
    key: "ODQrNTA0",
    text: "Honduras (+504)",
    flag: "hn",
    value: "+504",
  },
  {
    key: "ODUrMzY=",
    text: "Hungary (+36)",
    flag: "hu",
    value: "+36",
  },
  {
    key: "ODYrMzU0",
    text: "Iceland (+354)",
    flag: "is",
    value: "+354",
  },
  {
    key: "ODcrOTE=",
    text: "India (+91)",
    flag: "in",
    value: "+91",
  },
  {
    key: "ODgrNjI=",
    text: "Indonesia (+62)",
    flag: "id",
    value: "+62",
  },
  {
    key: "ODkrOTY0",
    text: "Iraq (+964)",
    flag: "iq",
    value: "+964",
  },
  {
    key: "OTArMzUz",
    text: "Ireland (+353)",
    flag: "ie",
    value: "+353",
  },
  {
    key: "OTErOTcy",
    text: "Israel (+972)",
    flag: "il",
    value: "+972",
  },
  {
    key: "OTIrMzk=",
    text: "Italy (+39)",
    flag: "it",
    value: "+39",
  },
  {
    key: "OTMrMSA4NzY=",
    text: "Jamaica (+1 876)",
    flag: "jm",
    value: "+1 876",
  },
  {
    key: "OTQrODE=",
    text: "Japan (+81)",
    flag: "jp",
    value: "+81",
  },
  {
    key: "OTUrOTYy",
    text: "Jordan (+962)",
    flag: "jo",
    value: "+962",
  },
  {
    key: "OTYrNyA3",
    text: "Kazakhstan (+7 7)",
    flag: "kz",
    value: "+7 7",
  },
  {
    key: "OTcrMjU0",
    text: "Kenya (+254)",
    flag: "ke",
    value: "+254",
  },
  {
    key: "OTgrNjg2",
    text: "Kiribati (+686)",
    flag: "ki",
    value: "+686",
  },
  {
    key: "OTkrOTY1",
    text: "Kuwait (+965)",
    flag: "kw",
    value: "+965",
  },
  {
    key: "MTAwKzk5Ng==",
    text: "Kyrgyzstan (+996)",
    flag: "kg",
    value: "+996",
  },
  {
    key: "MTAxKzM3MQ==",
    text: "Latvia (+371)",
    flag: "lv",
    value: "+371",
  },
  {
    key: "MTAyKzk2MQ==",
    text: "Lebanon (+961)",
    flag: "lb",
    value: "+961",
  },
  {
    key: "MTAzKzI2Ng==",
    text: "Lesotho (+266)",
    flag: "ls",
    value: "+266",
  },
  {
    key: "MTA0KzIzMQ==",
    text: "Liberia (+231)",
    flag: "lr",
    value: "+231",
  },
  {
    key: "MTA1KzQyMw==",
    text: "Liechtenstein (+423)",
    flag: "li",
    value: "+423",
  },
  {
    key: "MTA2KzM3MA==",
    text: "Lithuania (+370)",
    flag: "lt",
    value: "+370",
  },
  {
    key: "MTA3KzM1Mg==",
    text: "Luxembourg (+352)",
    flag: "lu",
    value: "+352",
  },
  {
    key: "MTA4KzI2MQ==",
    text: "Madagascar (+261)",
    flag: "mg",
    value: "+261",
  },
  {
    key: "MTA5KzI2NQ==",
    text: "Malawi (+265)",
    flag: "mw",
    value: "+265",
  },
  {
    key: "MTEwKzYw",
    text: "Malaysia (+60)",
    flag: "my",
    value: "+60",
  },
  {
    key: "MTExKzk2MA==",
    text: "Maldives (+960)",
    flag: "mv",
    value: "+960",
  },
  {
    key: "MTEyKzIyMw==",
    text: "Mali (+223)",
    flag: "ml",
    value: "+223",
  },
  {
    key: "MTEzKzM1Ng==",
    text: "Malta (+356)",
    flag: "mt",
    value: "+356",
  },
  {
    key: "MTE0KzY5Mg==",
    text: "Marshall Islands (+692)",
    flag: "mh",
    value: "+692",
  },
  {
    key: "MTE1KzU5Ng==",
    text: "Martinique (+596)",
    flag: "mq",
    value: "+596",
  },
  {
    key: "MTE2KzIyMg==",
    text: "Mauritania (+222)",
    flag: "mr",
    value: "+222",
  },
  {
    key: "MTE3KzIzMA==",
    text: "Mauritius (+230)",
    flag: "mu",
    value: "+230",
  },
  {
    key: "MTE4KzI2Mg==",
    text: "Mayotte (+262)",
    flag: "yt",
    value: "+262",
  },
  {
    key: "MTE5KzUy",
    text: "Mexico (+52)",
    flag: "mx",
    value: "+52",
  },
  {
    key: "MTIwKzM3Nw==",
    text: "Monaco (+377)",
    flag: "mc",
    value: "+377",
  },
  {
    key: "MTIxKzk3Ng==",
    text: "Mongolia (+976)",
    flag: "mn",
    value: "+976",
  },
  {
    key: "MTIyKzM4Mg==",
    text: "Montenegro (+382)",
    flag: "me",
    value: "+382",
  },
  {
    key: "MTIzKzE2NjQ=",
    text: "Montserrat (+1664)",
    flag: "ms",
    value: "+1664",
  },
  {
    key: "MTI0KzIxMg==",
    text: "Morocco (+212)",
    flag: "ma",
    value: "+212",
  },
  {
    key: "MTI1Kzk1",
    text: "Myanmar (+95)",
    flag: "mm",
    value: "+95",
  },
  {
    key: "MTI2KzI2NA==",
    text: "Namibia (+264)",
    flag: "na",
    value: "+264",
  },
  {
    key: "MTI3KzY3NA==",
    text: "Nauru (+674)",
    flag: "nr",
    value: "+674",
  },
  {
    key: "MTI4Kzk3Nw==",
    text: "Nepal (+977)",
    flag: "np",
    value: "+977",
  },
  {
    key: "MTI5KzMx",
    text: "Netherlands (+31)",
    flag: "nl",
    value: "+31",
  },
  {
    key: "MTMwKzU5OQ==",
    text: "Netherlands Antilles (+599)",
    flag: "an",
    value: "+599",
  },
  {
    key: "MTMxKzY4Nw==",
    text: "New Caledonia (+687)",
    flag: "nc",
    value: "+687",
  },
  {
    key: "MTMyKzY0",
    text: "New Zealand (+64)",
    flag: "nz",
    value: "+64",
  },
  {
    key: "MTMzKzUwNQ==",
    text: "Nicaragua (+505)",
    flag: "ni",
    value: "+505",
  },
  {
    key: "MTM0KzIyNw==",
    text: "Niger (+227)",
    flag: "ne",
    value: "+227",
  },
  {
    key: "MTM1KzIzNA==",
    text: "Nigeria (+234)",
    flag: "ng",
    value: "+234",
  },
  {
    key: "MTM2KzY4Mw==",
    text: "Niue (+683)",
    flag: "nu",
    value: "+683",
  },
  {
    key: "MTM3KzY3Mg==",
    text: "Norfolk Island (+672)",
    flag: "nf",
    value: "+672",
  },
  {
    key: "MTM4KzEgNjcw",
    text: "Northern Mariana Islands (+1 670)",
    flag: "mp",
    value: "+1 670",
  },
  {
    key: "MTM5KzQ3",
    text: "Norway (+47)",
    flag: "no",
    value: "+47",
  },
  {
    key: "MTQwKzk2OA==",
    text: "Oman (+968)",
    flag: "om",
    value: "+968",
  },
  {
    key: "MTQxKzky",
    text: "Pakistan (+92)",
    flag: "pk",
    value: "+92",
  },
  {
    key: "MTQyKzY4MA==",
    text: "Palau (+680)",
    flag: "pw",
    value: "+680",
  },
  {
    key: "MTQzKzUwNw==",
    text: "Panama (+507)",
    flag: "pa",
    value: "+507",
  },
  {
    key: "MTQ0KzY3NQ==",
    text: "Papua New Guinea (+675)",
    flag: "pg",
    value: "+675",
  },
  {
    key: "MTQ1KzU5NQ==",
    text: "Paraguay (+595)",
    flag: "py",
    value: "+595",
  },
  {
    key: "MTQ2KzUx",
    text: "Peru (+51)",
    flag: "pe",
    value: "+51",
  },
  {
    key: "MTQ3KzYz",
    text: "Philippines (+63)",
    flag: "ph",
    value: "+63",
  },
  {
    key: "MTQ4KzQ4",
    text: "Poland (+48)",
    flag: "pl",
    value: "+48",
  },
  {
    key: "MTQ5KzM1MQ==",
    text: "Portugal (+351)",
    flag: "pt",
    value: "+351",
  },
  {
    key: "MTUwKzEgOTM5",
    text: "Puerto Rico (+1 939)",
    flag: "pr",
    value: "+1 939",
  },
  {
    key: "MTUxKzk3NA==",
    text: "Qatar (+974)",
    flag: "qa",
    value: "+974",
  },
  {
    key: "MTUyKzQw",
    text: "Romania (+40)",
    flag: "ro",
    value: "+40",
  },
  {
    key: "MTUzKzI1MA==",
    text: "Rwanda (+250)",
    flag: "rw",
    value: "+250",
  },
  {
    key: "MTU0KzY4NQ==",
    text: "Samoa (+685)",
    flag: "ws",
    value: "+685",
  },
  {
    key: "MTU1KzM3OA==",
    text: "San Marino (+378)",
    flag: "sm",
    value: "+378",
  },
  {
    key: "MTU2Kzk2Ng==",
    text: "Saudi Arabia (+966)",
    flag: "sa",
    value: "+966",
  },
  {
    key: "MTU3KzIyMQ==",
    text: "Senegal (+221)",
    flag: "sn",
    value: "+221",
  },
  {
    key: "MTU4KzM4MQ==",
    text: "Serbia (+381)",
    flag: "rs",
    value: "+381",
  },
  {
    key: "MTU5KzI0OA==",
    text: "Seychelles (+248)",
    flag: "sc",
    value: "+248",
  },
  {
    key: "MTYwKzIzMg==",
    text: "Sierra Leone (+232)",
    flag: "sl",
    value: "+232",
  },
  {
    key: "MTYxKzY1",
    text: "Singapore (+65)",
    flag: "sg",
    value: "+65",
  },
  {
    key: "MTYyKzQyMQ==",
    text: "Slovakia (+421)",
    flag: "sk",
    value: "+421",
  },
  {
    key: "MTYzKzM4Ng==",
    text: "Slovenia (+386)",
    flag: "si",
    value: "+386",
  },
  {
    key: "MTY0KzY3Nw==",
    text: "Solomon Islands (+677)",
    flag: "sb",
    value: "+677",
  },
  {
    key: "MTY1KzI3",
    text: "South Africa (+27)",
    flag: "za",
    value: "+27",
  },
  {
    key: "MTY2KzUwMA==",
    text: "South Georgia and the South Sandwich Islands (+500)",
    flag: "gs",
    value: "+500",
  },
  {
    key: "MTY3KzM0",
    text: "Spain (+34)",
    flag: "es",
    value: "+34",
  },
  {
    key: "MTY4Kzk0",
    text: "Sri Lanka (+94)",
    flag: "lk",
    value: "+94",
  },
  {
    key: "MTY5KzI0OQ==",
    text: "Sudan (+249)",
    flag: "sd",
    value: "+249",
  },
  {
    key: "MTcwKzU5Nw==",
    text: "Suriname (+597)",
    flag: "sr",
    value: "+597",
  },
  {
    key: "MTcxKzI2OA==",
    text: "Swaziland (+268)",
    flag: "sz",
    value: "+268",
  },
  {
    key: "MTcyKzQ2",
    text: "Sweden (+46)",
    flag: "se",
    value: "+46",
  },
  {
    key: "MTczKzQx",
    text: "Switzerland (+41)",
    flag: "ch",
    value: "+41",
  },
  {
    key: "MTc0Kzk5Mg==",
    text: "Tajikistan (+992)",
    flag: "tj",
    value: "+992",
  },
  {
    key: "MTc1KzY2",
    text: "Thailand (+66)",
    flag: "th",
    value: "+66",
  },
  {
    key: "MTc2KzIyOA==",
    text: "Togo (+228)",
    flag: "tg",
    value: "+228",
  },
  {
    key: "MTc3KzY5MA==",
    text: "Tokelau (+690)",
    flag: "tk",
    value: "+690",
  },
  {
    key: "MTc4KzY3Ng==",
    text: "Tonga (+676)",
    flag: "to",
    value: "+676",
  },
  {
    key: "MTc5KzEgODY4",
    text: "Trinidad and Tobago (+1 868)",
    flag: "tt",
    value: "+1 868",
  },
  {
    key: "MTgwKzIxNg==",
    text: "Tunisia (+216)",
    flag: "tn",
    value: "+216",
  },
  {
    key: "MTgxKzkw",
    text: "Turkey (+90)",
    flag: "tr",
    value: "+90",
  },
  {
    key: "MTgyKzk5Mw==",
    text: "Turkmenistan (+993)",
    flag: "tm",
    value: "+993",
  },
  {
    key: "MTgzKzEgNjQ5",
    text: "Turks and Caicos Islands (+1 649)",
    flag: "tc",
    value: "+1 649",
  },
  {
    key: "MTg0KzY4OA==",
    text: "Tuvalu (+688)",
    flag: "tv",
    value: "+688",
  },
  {
    key: "MTg1KzI1Ng==",
    text: "Uganda (+256)",
    flag: "ug",
    value: "+256",
  },
  {
    key: "MTg2KzM4MA==",
    text: "Ukraine (+380)",
    flag: "ua",
    value: "+380",
  },
  {
    key: "MTg3Kzk3MQ==",
    text: "United Arab Emirates (+971)",
    flag: "ae",
    value: "+971",
  },
  {
    key: "MTg4KzQ0",
    text: "United Kingdom (+44)",
    flag: "gb",
    value: "+44",
  },
  {
    key: "MTg5KzE=",
    text: "United States (+1)",
    flag: "us",
    value: "+1",
  },
  {
    key: "MTkwKzU5OA==",
    text: "Uruguay (+598)",
    flag: "uy",
    value: "+598",
  },
  {
    key: "MTkxKzk5OA==",
    text: "Uzbekistan (+998)",
    flag: "uz",
    value: "+998",
  },
  {
    key: "MTkyKzY3OA==",
    text: "Vanuatu (+678)",
    flag: "vu",
    value: "+678",
  },
  {
    key: "MTkzKzY4MQ==",
    text: "Wallis and Futuna (+681)",
    flag: "wf",
    value: "+681",
  },
  {
    key: "MTk0Kzk2Nw==",
    text: "Yemen (+967)",
    flag: "ye",
    value: "+967",
  },
  {
    key: "MTk1KzI2MA==",
    text: "Zambia (+260)",
    flag: "zm",
    value: "+260",
  },
  {
    key: "MTk2KzI2Mw==",
    text: "Zimbabwe (+263)",
    flag: "zw",
    value: "+263",
  },
  {
    key: "MTk5KzU5MQ==",
    text: "Bolivia, Plurinational State of (+591)",
    flag: "bo",
    value: "+591",
  },
  {
    key: "MjAwKzY3Mw==",
    text: "Brunei Darussalam (+673)",
    flag: "bn",
    value: "+673",
  },
  {
    key: "MjAxKzYx",
    text: "Cocos (Keeling) Islands (+61)",
    flag: "cc",
    value: "+61",
  },
  {
    key: "MjAyKzI0Mw==",
    text: "Congo, The Democratic Republic of the (+243)",
    flag: "cd",
    value: "+243",
  },
  {
    key: "MjAzKzIyNQ==",
    text: "Cote d'Ivoire (+225)",
    flag: "ci",
    value: "+225",
  },
  {
    key: "MjA0KzUwMA==",
    text: "Falkland Islands (Malvinas) (+500)",
    flag: "fk",
    value: "+500",
  },
  {
    key: "MjA2KzM3OQ==",
    text: "Holy See (Vatican City State) (+379)",
    flag: "va",
    value: "+379",
  },
  {
    key: "MjA3Kzg1Mg==",
    text: "Hong Kong (+852)",
    flag: "hk",
    value: "+852",
  },
  {
    key: "MjA4Kzk4",
    text: "Iran, Islamic Republic of (+98)",
    flag: "ir",
    value: "+98",
  },
  {
    key: "MjExKzg1MA==",
    text: "Korea, Democratic People's Republic of (+850)",
    flag: "kp",
    value: "+850",
  },
  {
    key: "MjEyKzgy",
    text: "Korea, Republic of (+82)",
    flag: "kr",
    value: "+82",
  },
  {
    key: "MjEzKzg1Ng==",
    text: "Lao People's Democratic Republic (+856)",
    flag: "la",
    value: "+856",
  },
  {
    key: "MjE0KzIxOA==",
    text: "Libyan Arab Jamahiriya (+218)",
    flag: "ly",
    value: "+218",
  },
  {
    key: "MjE1Kzg1Mw==",
    text: "Macao (+853)",
    flag: "mo",
    value: "+853",
  },
  {
    key: "MjE2KzM4OQ==",
    text: "Macedonia, The Former Yugoslav Republic of (+389)",
    flag: "mk",
    value: "+389",
  },
  {
    key: "MjE3KzY5MQ==",
    text: "Micronesia, Federated States of (+691)",
    flag: "fm",
    value: "+691",
  },
  {
    key: "MjE4KzM3Mw==",
    text: "Moldova, Republic of (+373)",
    flag: "md",
    value: "+373",
  },
  {
    key: "MjE5KzI1OA==",
    text: "Mozambique (+258)",
    flag: "mz",
    value: "+258",
  },
  {
    key: "MjIwKzk3MA==",
    text: "Palestinian Territory, Occupied (+970)",
    flag: "ps",
    value: "+970",
  },
  {
    key: "MjIxKzg3Mg==",
    text: "Pitcairn (+872)",
    flag: "pn",
    value: "+872",
  },
  {
    key: "MjIyKzI2Mg==",
    text: "Réunion (+262)",
    flag: "re",
    value: "+262",
  },
  {
    key: "MjIzKzc=",
    text: "Russia (+7)",
    flag: "ru",
    value: "+7",
  },
  {
    key: "MjI1KzI5MA==",
    text: "Saint Helena, Ascension and Tristan Da Cunha (+290)",
    flag: "sh",
    value: "+290",
  },
  {
    key: "MjI2KzEgODY5",
    text: "Saint Kitts and Nevis (+1 869)",
    flag: "kn",
    value: "+1 869",
  },
  {
    key: "MjI3KzEgNzU4",
    text: "Saint Lucia (+1 758)",
    flag: "lc",
    value: "+1 758",
  },
  {
    key: "MjI5KzUwOA==",
    text: "Saint Pierre and Miquelon (+508)",
    flag: "pm",
    value: "+508",
  },
  {
    key: "MjMwKzEgNzg0",
    text: "Saint Vincent and the Grenadines (+1 784)",
    flag: "vc",
    value: "+1 784",
  },
  {
    key: "MjMxKzIzOQ==",
    text: "Sao Tome and Principe (+239)",
    flag: "st",
    value: "+239",
  },
  {
    key: "MjMyKzI1Mg==",
    text: "Somalia (+252)",
    flag: "so",
    value: "+252",
  },
  {
    key: "MjMzKzQ3",
    text: "Svalbard and Jan Mayen (+47)",
    flag: "sj",
    value: "+47",
  },
  {
    key: "MjM0Kzk2Mw==",
    text: "Syrian Arab Republic (+963)",
    flag: "sy",
    value: "+963",
  },
  {
    key: "MjM1Kzg4Ng==",
    text: "Taiwan, Province of China (+886)",
    flag: "tw",
    value: "+886",
  },
  {
    key: "MjM2KzI1NQ==",
    text: "Tanzania, United Republic of (+255)",
    flag: "tz",
    value: "+255",
  },
  {
    key: "MjM3KzY3MA==",
    text: "Timor-Leste (+670)",
    flag: "tl",
    value: "+670",
  },
  {
    key: "MjM4KzU4",
    text: "Venezuela, Bolivarian Republic of (+58)",
    flag: "ve",
    value: "+58",
  },
  {
    key: "MjM5Kzg0",
    text: "Viet Nam (+84)",
    flag: "vn",
    value: "+84",
  },
  {
    key: "MjQwKzEgMjg0",
    text: "Virgin Islands, British (+1 284)",
    flag: "vg",
    value: "+1 284",
  },
  {
    key: "MjQxKzEgMzQw",
    text: "Virgin Islands, U.S. (+1 340)",
    flag: "vi",
    value: "+1 340",
  },
];
