import { TwitterService } from "services/Auth";

type TAPIResponse = {
  generated_content: string[];
};

type TAPIPayload = {
  url: string;
};

class ContentGenerationByUrlAdapter {
  private twitter = new TwitterService();

  private parseResponse = (apiResponse: TAPIResponse) => {
    return {
      generatedContent: apiResponse.generated_content,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TAPIPayload) => {
    return {
      url: data.url,
    };
  };

  getResponse = (apiResponse: TAPIResponse) => this.parseResponse(apiResponse);
}

export default ContentGenerationByUrlAdapter;
