import { TChangePasswordPayload } from "./types";

type TAPIResponse = {
  message: string;
};

type TAPIPostPayload = {
  old_password: string;
  new_password: string;
};

class PlanDetailsAdapter {
  private parseResponse = (data: TAPIResponse): TAPIResponse => {
    return data;
  };

  parseGetResponse = (data: TAPIResponse) => this.parseResponse(data);

  getPostPayload = (data: TChangePasswordPayload): TAPIPostPayload => {
    return {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    };
  };
}

export default PlanDetailsAdapter;
