import { TwitterService } from "services/Auth";
import { TRecentTweets } from "./types";

type TAPIPayload = {
  like: boolean;
};

type TAPIResponse = {
  like: boolean;
};

class LikeRecentAdapter {
  private twitter = new TwitterService();
  private parseResponse = (
    tweets: TRecentTweets,
    apiResponse: TAPIResponse
  ): TRecentTweets => {
    if (apiResponse.like) {
      return {
        ...tweets,
        likeStatus: true,
        likeCount: tweets.likeCount + 1,
      };
    }
    return {
      ...tweets,
      likeStatus: false,
      likeCount: tweets.likeCount - 1,
    };
  };

  getPostUrl = (url: string, recentTweets: TRecentTweets) =>
    `${url}/${recentTweets.tweetId}`;

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostPayload = (data: TRecentTweets): TAPIPayload => {
    return {
      like: !data.likeStatus,
    };
  };
  parsePostResponse = this.parseResponse;
}

export default LikeRecentAdapter;
