export const filterConfig = [
  { key: "lastThirtyDays", value: 30, text: "Last 30 days" },
  { key: "lastFefteenDays", value: 15, text: "Last 15 days" },
  { key: "lastSevenDays", value: 7, text: "Last 7 days" },
];

export const sortByTweetSearch = [
  { key: "likes", value: "likes", text: "Highest Likes" },
  { key: "retweets", value: "retweets", text: "Highest Retweets" },
];

export const scheduleSortBy = [
  { key: "RETWEETED", value: "RETWEETED", text: "Posted" },
  { key: "SCHEDULED", value: "SCHEDULED", text: "Scheduled" },
];

export const sortByPotentialLead = [
  { key: "tweets", value: "tweets", text: "Highest Tweets" },
  { key: "followers", value: "followers", text: "Highest Followers" },
  { key: "following", value: "following", text: "Highest Following" },
];

export const sortByPotentialCustomer = [
  { key: "tweets", value: "tweets", text: "Highest Tweets" },
  { key: "followers", value: "followers", text: "Highest Followers" },
  { key: "following", value: "following", text: "Highest Following" },
];

export const sortByInfluencerDataBase = [
  { key: "tweets", value: "tweets", text: "Highest Tweets" },
  { key: "followers", value: "followers", text: "Highest Followers" },
  { key: "following", value: "following", text: "Highest Following" },
];
