import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRegistrationStatus } from "adapters/Profile/types";

type TRegistrationStatusState = {
  completed: TRegistrationStatus["isCountry"];
  country: TRegistrationStatus["isCountry"];
  email: TRegistrationStatus["isEmail"];
  keywords: TRegistrationStatus["isKeywords"];
  occupation: TRegistrationStatus["isOccupation"];
  product: TRegistrationStatus["isProduct"];
  referral: TRegistrationStatus["isReferral"];
  timezone_language: TRegistrationStatus["isTimezoneLanguage"];
  toBeFetched: boolean;
};

const initialState: TRegistrationStatusState = {
  completed: true,
  country: true,
  email: true,
  keywords: true,
  occupation: true,
  product: true,
  referral: true,
  timezone_language: true,
  toBeFetched: true,
};

export const registrationStatus = createSlice({
  name: "registrationStatus",
  initialState,
  reducers: {
    setRegistration: (state, action: PayloadAction<boolean>) => {
      state.completed = action.payload;
      state.toBeFetched = false;
    },
    setCountry: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        country: action.payload,
      };
    },
    setEmail: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    setKeywords: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        keywords: action.payload,
      };
    },
    setOccupation: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        occupation: action.payload,
      };
    },
    setProduct: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        product: action.payload,
      };
    },
    setReferral: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        referral: action.payload,
      };
    },
    setTimezoneLanguage: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        timezone_language: action.payload,
      };
    },
  },
});

export const {
  setRegistration,
  setEmail,
  setCountry,
  setKeywords,
  setOccupation,
  setProduct,
  setReferral,
  setTimezoneLanguage,
} = registrationStatus.actions;

export default registrationStatus.reducer;
