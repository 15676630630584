import {
  ContentGenerationForm,
  GeneratedContent,
  RemoveTarget,
} from "components/ContentCreation/AIContent";
import { PostScheduler } from "components/Twitter";
import { PostSchedulerProvider } from "context";
import {
  ContentGenerationProvider,
  ScheduleForMonthProvider,
} from "context/ContentCreation";

const AIContent: React.FC = () => {
  return (
    <ContentGenerationProvider>
      <ScheduleForMonthProvider>
        <ContentGenerationForm />
        <GeneratedContent />
        <RemoveTarget />
        <PostSchedulerProvider>
          <PostScheduler />
        </PostSchedulerProvider>
      </ScheduleForMonthProvider>
    </ContentGenerationProvider>
  );
};

export default AIContent;
