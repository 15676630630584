import ReactDOM from "react-dom";
import { Container } from "./Dimmer.styles";
import { IDimmerStyleProps } from "./types";

interface IDimmerProps
  extends React.ComponentPropsWithoutRef<"div">,
    IDimmerStyleProps {}

const Dimmer = (props: IDimmerProps) => {
  return ReactDOM.createPortal(
    <Container {...props} />,
    document.getElementById("dimmer")!
  );
};

export default Dimmer;
