import { dolphynPublicAPI } from "api/instances";
import { links, constants } from "config/Pricing";
import { CompareAdapter } from "adapters/Pricing";
import { APIError } from "errors";

const URL = links.BASE_PRODUCT_URL.append("/pricing/compare");
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new CompareAdapter();

const compare = {
  get: async () => {
    try {
      const { data } = await dolphynPublicAPI(URL);
      return adapter.parseGetResponse(data);
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default compare;
