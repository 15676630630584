import { useReactQuery } from "hooks/common";
import { scheduleRetweet as api } from "api/Twitter";
import {
  TDeleteScheduleRetweetPayload,
  TPatchScheduleRetweetPayload,
  TPostScheduleRetweetPayload,
} from "api/Twitter/types";
import { IScheduleRetweet } from "adapters/Twitter/types";

type TOptions = {
  onSuccess?: (data?: IScheduleRetweet) => void;
};

const useTwitterSchedule = () => {
  const postApi = useReactQuery(api.postScheduleRetweet);
  const patchApi = useReactQuery(api.patchScheduleRetweet);
  const deleteApi = useReactQuery(api.deleteScheduleRetweet);

  const create = async (
    data: TPostScheduleRetweetPayload,
    options?: TOptions
  ) => {
    await postApi.executeAsync(data, options);
  };

  const update = (data: TPatchScheduleRetweetPayload, options?: TOptions) => {
    patchApi.execute(data, options);
  };

  const remove = (data: TDeleteScheduleRetweetPayload, options?: TOptions) => {
    deleteApi.execute(data, options);
  };

  const isLoading =
    postApi.isLoading || patchApi.isLoading || deleteApi.isLoading;

  return {
    create,
    update,
    remove,
    isLoading,
  };
};

export default useTwitterSchedule;
