import Pagination from "@mui/material/Pagination";
import { Body, PaginationContainer } from "./CardList.styles";
import { useActivity } from "hooks/SocialMediaPost";
import { usePagination } from "hooks/common";
import Card from "./Card";
import { postStatusMap } from "config/SocialMediaPost";

const ITEMS_PER_PAGE = 6;

const CardList = () => {
  const activities = useActivity();
  const pagination = usePagination(activities.data, ITEMS_PER_PAGE);

  const renderAcivities = () => {
    return pagination.paginatedData.map((activity, key) => (
      <Card
        key={key}
        formValues={{ ...activity, files: [] }}
        editable={activity.postStatus === postStatusMap.scheduled}
        deletable
        {...activity}
      />
    ));
  };

  return (
    <>
      <Body>{renderAcivities()}</Body>
      <PaginationContainer>
        <Pagination
          count={pagination.totalPages}
          shape="rounded"
          page={pagination.currentPage}
          onChange={pagination.handlePageChange}
        />
      </PaginationContainer>
    </>
  );
};

export default CardList;
