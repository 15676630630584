import { Modal } from "components/core";
import { useBoolean, useTwitterAuth } from "hooks";
import { TTwitterAccount } from "services/Auth/twitterService";
import CurrentAccount from "./CurrentAccount";
import { Container, Image } from "./SingleAccount.styles";

type TSingleAccountPorps = {
  account: TTwitterAccount;
};

const SingleAccount = ({ account }: TSingleAccountPorps) => {
  const { selectedAccount } = useTwitterAuth();
  const [isOpen, onOpen, onClose] = useBoolean();

  const renderAccountInfo = () => (
    <Modal
      open={isOpen}
      onOutsideClick={onClose}
      style={{ width: "275px", height: "240px", top: "-20%", right: "-35%" }}
    >
      <CurrentAccount account={account} />
    </Modal>
  );

  return (
    <Container
      selected={account.userName === selectedAccount.userName}
      onClick={onOpen}
    >
      {renderAccountInfo()}
      <Image src={account.profileImageUrl} alt={account.userName} />
    </Container>
  );
};

export default SingleAccount;
