import { combineReducers } from "redux";
import googleAnalyticsAuth from "./googleAnalyticsAuth";
import googleAnalyticsAccountData from "./googleAnalyticsAccountData";
import googleAnalyticsSelection from "./googleAnalyticsSelection";
import googleSearchConsoleAuth from "./googleSearchConsoleAuth";
import googleSearchConsoleSiteData from "./googleSearchConsoleSiteData";
import googleSearchConsoleSelection from "./googleSearchConsoleSelection";
import trackPerformanceMenu from "./trackPerformanceMenu";

export default combineReducers({
  googleAnalyticsAuth,
  googleSearchConsoleAuth,
  googleAnalyticsAccountData,
  googleAnalyticsSelection,
  googleSearchConsoleSiteData,
  googleSearchConsoleSelection,
  trackPerformanceMenu,
});

export * from "./googleAnalyticsAuth";
export * from "./googleAnalyticsAccountData";
export * from "./googleAnalyticsSelection";
export * from "./googleSearchConsoleAuth";
export * from "./googleSearchConsoleSiteData";
export * from "./googleSearchConsoleSelection";
export * from "./trackPerformanceMenu";
