import { AuthPopUpWindow } from "components/Auth";
import { links, socialMedia } from "config/SocialMediaPost";
import { AccountCard, Loader } from "components/common";
import { ReactComponent as TwitterIcon } from "assets/icons/SocialMediaPost/social-media-post-twitter-icon.svg";
import { useTwitterAuth } from "hooks/Auth";

const TwitterAccountCard = () => {
  const twitter = useTwitterAuth();

  return (
    <>
      <Loader textData={socialMedia} hidden={!twitter.isLoading} />
      <AccountCard>
        <AccountCard.Logo icon={<TwitterIcon />} />
        {twitter.accounts.map((account, key) => (
          <AccountCard.Account
            key={key}
            title={account.userName}
            image={account.profileImageUrl}
            onDelete={() => twitter.removeAccount(account.uid)}
            active={twitter.isAccountSelected(account.uid)}
            onClick={() => twitter.selectAccount(account.uid)}
          />
        ))}
        <AuthPopUpWindow
          trigger={
            <AccountCard.Button content="Connect" variant="success" outline />
          }
          url={links.SOCIAL_ACCOUNT_TWITTER_AUTH_LINK}
          expectedParam="oauth_token"
          onSuccess={(params, redirectUrl) =>
            redirectUrl && twitter.login(redirectUrl)
          }
        />
      </AccountCard>
    </>
  );
};

export default TwitterAccountCard;
