import GoogleIcon from "assets/icons/common/google-icon.svg";
import FacebookIcon from "assets/icons/common/facebook-icon.svg";
import TwitterIcon from "assets/icons/common/twitter-icon.svg";
import LinkedinIcon from "assets/icons/SocialMediaPost/social-media-post-linkedin-icon.svg";
import InstagramIcon from "assets/icons/SocialMediaPost/social-media-post-instagram-icon.svg";
import BlogIcon from "assets/icons/Profile/while-reading-blog.svg";
import OnlineSearchIcon from "assets/icons/Profile/online-search.svg";
import ReferralIcon from "assets/icons/Profile/referral.svg";

const data = [
  { id: 1, icon: GoogleIcon, text: "Google Search", value: "google search" },
  { id: 2, icon: TwitterIcon, text: "Twitter", value: "twitter" },
  { id: 3, icon: InstagramIcon, text: "Instagram", value: "instagram" },
  { id: 4, icon: LinkedinIcon, text: "LinkedIn", value: "linkedin" },
  { id: 5, icon: FacebookIcon, text: "Facebook", value: "facebook" },
  {
    id: 6,
    icon: BlogIcon,
    text: "While Reading Blog",
    value: "while reading blog",
  },
  { id: 7, icon: ReferralIcon, text: "Referral", value: "referral" },
  {
    id: 8,
    icon: OnlineSearchIcon,
    text: "Other Online Search",
    value: "other online option",
  },
];

export default data;
