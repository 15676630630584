import ProfileSection from "./ProfileSection";
import { useBoolean } from "hooks/common";
import { useNotificationPreferences } from "hooks/Profile";
import { Formik } from "formik";
import NotificationsForm from "./NotificationsForm";

const FORM_ID = "Notifications";

const Notifications = () => {
  const notifications = useNotificationPreferences();

  const [editable, setEditableTrue, setEditableFalse] = useBoolean();

  return (
    <ProfileSection>
      <Formik
        initialValues={notifications.details}
        onSubmit={(values) => {
          notifications.handleUpdate(values);
          setEditableFalse();
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <>
            <ProfileSection.Actions
              editable={editable}
              onCancel={() => {
                resetForm();
                setEditableFalse();
              }}
              onEdit={setEditableTrue}
              formId={FORM_ID}
            />
            <ProfileSection.Content>
              <NotificationsForm
                editable={editable}
                formId={FORM_ID}
                onSubmit={handleSubmit}
              />
            </ProfileSection.Content>
          </>
        )}
      </Formik>
    </ProfileSection>
  );
};

export default Notifications;
