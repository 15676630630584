import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

type TOptionProps = {
  active?: boolean;
};

const helper = new ThemeHelper();

const StyledOption = styled.div<TOptionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  text-align: center;
  cursor: pointer;
  padding: ${helper.getSpacing("extraSmall")};
  border: 1px solid transparent;
  img {
    width: 35%;
    height: 35%;
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.alpha};
  }
  ${({ active }) => {
    if (active)
      return css`
        border-color: ${({ theme }) => theme.colors.alpha};
      `;
  }}
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
`;

export { StyledOption, Icon };
