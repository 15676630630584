import { AuthPopUpWindow } from "components/Auth";
import { connectWithTwitter, links } from "config/Twitter";
import { Loader } from "components/common";
import { useTwitterAuth } from "hooks/Auth";
import { useNavigation } from "hooks/common";

interface ITwitterAuthPopupProps {
  onSuccessUrl?: string;
  children: React.ReactElement;
}

const TwitterAuthPopup = (props: ITwitterAuthPopupProps) => {
  const twitter = useTwitterAuth();
  const navigation = useNavigation();

  const handleSuccess = () => {
    const { onSuccessUrl } = props;
    if (onSuccessUrl) {
      return navigation.navigate(() => onSuccessUrl);
    }
  };

  return (
    <>
      <Loader textData={connectWithTwitter} hidden={!twitter.isLoading} />
      <AuthPopUpWindow
        trigger={props.children}
        url={links.TWITTER_MODULE_AUTH_URL}
        expectedParam="oauth_token"
        onSuccess={(params, redirectUrl) => {
          if (redirectUrl) {
            twitter.login(redirectUrl, {
              onSuccess: handleSuccess,
            });
          }
        }}
      />
    </>
  );
};

export default TwitterAuthPopup;
