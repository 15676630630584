import { IMultiKeywordSearchFormikValues } from "context/ProductDemand/types";

const multiKeywordsSearchFieldsMap: {
  [prop in keyof IMultiKeywordSearchFormikValues]: keyof IMultiKeywordSearchFormikValues;
} = {
  keywords: "keywords",
  country: "country",
} as const;

export default multiKeywordsSearchFieldsMap;
