import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { theme } from "theme";
import { AddInfluencerIcon } from "../common/icons";
import { Container } from "./InfluencerTarget.styles";

const InfluencerTarget = () => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: () => ({ name: ItemTypes.INFLUENCER }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = isOver && canDrop;
  const opacity = canDrop ? 0.77 : 0;
  const display = canDrop ? "grid" : "none";
  const backgroundColor = isOver
    ? theme.colors.mountainMeadow
    : theme.colors.bubbles;

  return (
    <Container ref={drop} style={{ opacity, display, backgroundColor }}>
      <AddInfluencerIcon />
      {isActive ? "Release to drop" : "Add as Influencer"}
    </Container>
  );
};

export default InfluencerTarget;
