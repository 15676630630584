import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPersonalDetails } from "adapters/Profile/types";

const initialState: TPersonalDetails = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  organizationName: "",
  organizationSize: "",
  timezone: "",
  country: "",
  language: "",
};

export const personalDetails = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    setPersonalDetails: (
      state,
      action: PayloadAction<Partial<TPersonalDetails>>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setPersonalDetails } = personalDetails.actions;

export default personalDetails.reducer;
