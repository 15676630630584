import {
  Container,
  ImageContainer,
  TextContainer,
  Header,
  Description,
  Button,
} from "./CardListSection.styles";

export interface CardListSectionProps {
  header?: string;
  description?: string;
  buttonText?: string;
  image?: string;
  onClick?: () => void;
}

interface IImageProps extends React.ComponentPropsWithoutRef<"img"> {}

const Image = (props: IImageProps) => {
  return (
    <ImageContainer>
      <img alt={props.alt} {...props} />
    </ImageContainer>
  );
};

interface ITextProps {
  header?: string;
  description?: string;
}

const Text = ({ header, description }: ITextProps) => {
  return (
    <TextContainer>
      <Header>{header}</Header>
      <Description>{description}</Description>
    </TextContainer>
  );
};

const CardListSection = ({
  header,
  description,
  image,
  buttonText,
  onClick,
  children,
}: React.PropsWithChildren<CardListSectionProps>) => {
  if (children) return <Container>{children}</Container>;

  return (
    <Container>
      <Image src={image} />
      <Text header={header} description={description} />
      <Button variant="alpha" outline content={buttonText} onClick={onClick} />
    </Container>
  );
};

CardListSection.Image = Image;
CardListSection.Text = Text;
CardListSection.Button = Button;

export default CardListSection;
