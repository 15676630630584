export type TTrend = {
  id: string | number;
  title: string;
  value?: string;
};

type TAPIResponse = {
  google_trend: {
    title: string;
  }[];
  twitter_trend: {
    title: string;
  }[];
  youtube_trend: {
    title: string;
    view_count: "6308694";
  }[];
};

export type TLiveTrendsData = {
  google: TTrend[];
  twitter: TTrend[];
  youtube: TTrend[];
};

class LiveTrendsAdapter {
  private getGoogleTrends = (data: TAPIResponse["google_trend"]): TTrend[] => {
    return data.map((trend, id) => ({
      title: trend.title,
      id,
    }));
  };

  private getYoutubeTrends = (
    data: TAPIResponse["youtube_trend"]
  ): TTrend[] => {
    return data.map((trend, id) => ({
      title: trend.title,
      id,
      value: trend.view_count,
    }));
  };

  private getTwitterTrends = (
    data: TAPIResponse["twitter_trend"]
  ): TTrend[] => {
    return data.map((trend, id) => ({
      title: trend.title,
      id,
    }));
  };

  parseGetResponse = ({
    google_trend,
    youtube_trend,
    twitter_trend,
  }: TAPIResponse) => {
    const data: TLiveTrendsData = {
      google: this.getGoogleTrends(google_trend),
      youtube: this.getYoutubeTrends(youtube_trend),
      twitter: this.getTwitterTrends(twitter_trend),
    };
    return data;
  };
}

export default LiveTrendsAdapter;
