import { constants } from "config/Auth";
import { CookieService } from "services/common";

export type TDolphynAccessCookie = string;
export type TDolphynRefreshCookie = string;

class AuthService {
  private access: CookieService<TDolphynAccessCookie> = new CookieService(
    constants.DOLPHYN_ACCESS_COOKIE
  );
  private refresh: CookieService<TDolphynRefreshCookie> = new CookieService(
    constants.DOLPHYN_REFRESH_COOKIE
  );

  setCookies = (
    access: TDolphynAccessCookie,
    refresh: TDolphynRefreshCookie
  ) => {
    this.access.setCookie(access, constants.DOLPHYN_ACCESS_COOKIE_EXPIRY);
    this.refresh.setCookie(refresh, constants.DOLPHYN_REFRESH_COOKIE_EXPIRY);
  };

  setAccessCookie = (access: string) => {
    this.access.setCookie(access, constants.DOLPHYN_ACCESS_COOKIE_EXPIRY);
  };

  getAccessCookie = () => {
    return this.access.getCookie();
  };

  getRefreshCookie = () => {
    return this.refresh.getCookie();
  };

  getAsyncCookies = async () => {
    const access = await this.access.getAsyncCookieOrError();
    const refresh = await this.refresh.getAsyncCookieOrError();
    if (typeof access === "string" && typeof refresh === "string")
      return {
        access,
        refresh,
      };
    return undefined;
  };

  removeRefreshCookie = () => {
    this.refresh.removeCookie();
  };

  isAuthenticated = () => {
    return this.refresh.isCookiePresent();
  };

  clearData = () => {
    this.access.removeCookie();
    this.refresh.removeCookie();
  };
}

export default AuthService;
