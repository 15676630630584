import { ActionButton } from "components/core";

interface IReplyButtonProps {
  onClick?: () => void;
  count?: number;
}

const InstantReplyButton = ({ count, onClick }: IReplyButtonProps) => {
  return (
    <ActionButton
      icon="chat"
      variant="alpha"
      content={count}
      size="tiny"
      outline
      onClick={onClick}
      borderless
    />
  );
};

export default InstantReplyButton;
