import { dolphynAPI } from "api/instances";
import { links, constants } from "config/common";
import TimezonesAdapter from "adapters/common/timezones";
import { APIError } from "errors";
import { TTimezonesPayload } from "./types";

const URL = links.timezonesUrl;
const error = new APIError(constants.DEFAULT_API_ERROR);
const adapter = new TimezonesAdapter();

const timezones = {
  get: async (payload: TTimezonesPayload) => {
    try {
      const response = await dolphynAPI(URL, { params: payload });
      const data = adapter.parseGetResponse(response.data);
      return data;
    } catch (e) {
      error.throwAxiosError(e);
    }
  },
};

export default timezones;
