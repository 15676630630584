import { Icon } from "components/core";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 20px 16px;
`;

const Content = styled.div``;

const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .name {
    color: #000000;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    padding-top: 5px;
  }

  .username {
    color: #5f5f5f;
    font-size: 13px;
    line-height: 12px;
  }
`;

const TweetText = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.lightGrey};
  white-space: pre-line;
  font-size: 13px;
`;

const ImageContainer = styled.div``;

const Line = styled.div`
  width: 2px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #e5e5e5;
`;

const FilesPreview = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FileContainer = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 10px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const RemoveIcon = styled(Icon).attrs({
  name: "trash alternate",
})`
  color: #5f5f5f;
  cursor: pointer;
`;

const Bottom = styled.div`
  padding-top: 8px;
`;

export {
  Bottom,
  RemoveIcon,
  StyledImage,
  FileContainer,
  FilesPreview,
  ImageContainer,
  TweetText,
  User,
  Header,
  Content,
  Container,
  Line,
  Image,
};
