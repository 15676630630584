import styled, { css } from "styled-components";
import { TIconContainerProps } from "./types";

const IconContainer = styled.span<TIconContainerProps>`
  svg {
    width: 50px;
  }
  svg rect {
    fill: ${({ theme, variant }) => {
      if (variant) return theme.colors[variant];
      return theme.colors.white;
    }};
  }
  ${({ onClick }) => {
    if (onClick)
      return css`
        cursor: pointer;
      `;
  }}
`;

export { IconContainer };
