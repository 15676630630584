import { css } from "styled-components";
import { TSpacings } from "./types";

type TBoxValues = {
  top?: keyof TSpacings;
  bottom?: keyof TSpacings;
  left?: keyof TSpacings;
  right?: keyof TSpacings;
  inline?: keyof TSpacings;
  block?: keyof TSpacings;
  all?: keyof TSpacings;
};

class Spacing {
  private spacing: TSpacings = {
    tiny: 25,
    extraSmall: 50,
    small: 100,
    medium: 200,
    large: 400,
    extraLarge: 600,
    huge: 1000,
    none: 0,
  };

  getSpacing = (value: keyof TSpacings = "small") => {
    const spacing = this.spacing[value] / 100;
    return spacing + "rem";
  };

  getMargins = (value: TBoxValues) => {
    const { top, bottom, left, right, inline, block, all } = value;

    return css`
      ${top &&
      css`
        margin-top: ${this.getSpacing(top)};
      `}
      ${bottom &&
      css`
        margin-bottom: ${this.getSpacing(bottom)};
      `}
      ${left &&
      css`
        margin-left: ${this.getSpacing(top)};
      `}
      ${right &&
      css`
        margin-right: ${this.getSpacing(top)};
      `}
      ${inline &&
      css`
        margin-inline: ${this.getSpacing(inline)};
      `}
      ${block &&
      css`
        margin-block: ${this.getSpacing(block)};
      `}
      ${all &&
      css`
        margin: ${this.getSpacing(all)};
      `}
    `;
  };

  getPaddings = (value: TBoxValues) => {
    const { top, bottom, left, right, inline, block, all } = value;

    return css`
      ${top &&
      css`
        padding-top: ${this.getSpacing(top)};
      `}
      ${bottom &&
      css`
        padding-bottom: ${this.getSpacing(bottom)};
      `}
      ${left &&
      css`
        padding-left: ${this.getSpacing(left)};
      `}
      ${right &&
      css`
        padding-right: ${this.getSpacing(right)};
      `}
      ${inline &&
      css`
        padding-inline: ${this.getSpacing(inline)};
      `}
      ${block &&
      css`
        padding-block: ${this.getSpacing(block)};
      `}
      ${all &&
      css`
        padding: ${this.getSpacing(all)};
      `}
    `;
  };
}

export default Spacing;
