import { Flex } from "components/core";
import { BaseModuleLayout } from "layout/common";
import TwitterLayoutHeader from "./LayoutHeader";

const AutoWelcome = (props: React.PropsWithChildren<{}>) => {
  return (
    <Flex direction="column">
      <TwitterLayoutHeader header="Auto Welcome" />
      <BaseModuleLayout.Body>{props.children}</BaseModuleLayout.Body>
    </Flex>
  );
};

export default AutoWelcome;
