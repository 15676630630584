import { TwitterService } from "services/Auth";
import { TInfluencer } from "./types";

type TAPIPayload = {
  follow: boolean;
};

type TAPIResponse = {
  message: string;
};

class FollowInfluencerAdapter {
  private twitter = new TwitterService();
  private parseResponse = (
    tweetCard: TInfluencer,
    apiResponse: TAPIResponse
  ) => {
    if (apiResponse.message) {
      return {
        message: apiResponse.message,
      };
    }
  };

  getPostUrl = (url: string, tweetCard: TInfluencer) =>
    `${url}/${tweetCard.leadUsername}`;

  getPostPayload = (data: TInfluencer): TAPIPayload => {
    return {
      follow: !data.follow,
    };
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  parsePostResponse = this.parseResponse;
}

export default FollowInfluencerAdapter;
