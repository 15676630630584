import { TNotificationPreferences } from "./types";

type TAPIResponse = {
  daily_activity_email: boolean;
  news_letter_email: boolean;
  marketing_email: boolean;
  social_media_post_notification: boolean;
  twitter_email: boolean;
};

class NotificationPreferencesAdapter {
  private parseResponse = (data: TAPIResponse): TNotificationPreferences => {
    const notificationPreferences: TNotificationPreferences = {
      dailyActivity: data.daily_activity_email,
      newsletter: data.news_letter_email,
      marketing: data.marketing_email,
      socialMediaPost: data.social_media_post_notification,
      twitterEmail: data.twitter_email,
    };

    return notificationPreferences;
  };

  parseGetResponse = (data: TAPIResponse): TNotificationPreferences => {
    return this.parseResponse(data);
  };

  getPatchPayload = (payload: Partial<TNotificationPreferences>) => {
    const apiPayload: Partial<TAPIResponse> = {
      daily_activity_email: payload.dailyActivity,
      news_letter_email: payload.newsletter,
      marketing_email: payload.marketing,
      social_media_post_notification: payload.socialMediaPost,
      twitter_email: payload.twitterEmail,
    };

    return apiPayload;
  };
}

export default NotificationPreferencesAdapter;
