import axios from "axios";
import { configureAuthHeaders } from "helpers";

const { REACT_APP_BACKEND_ROOT_URL } = process.env;

const dolphynTrendLive = axios.create({
  baseURL: REACT_APP_BACKEND_ROOT_URL,
});

dolphynTrendLive.interceptors.request.use(
  (config) => configureAuthHeaders(config),
  (error) => {
    return Promise.reject(error);
  }
);

export default dolphynTrendLive;
