import WordLimit from "./WordLimit";
import { Container, Toolbar, TextArea } from "./Textbox.styles";

const Textbox = ({
  children,
  style,
  ...props
}: React.PropsWithChildren<React.ComponentPropsWithoutRef<"textarea">>) => {
  return (
    <Container style={style}>
      <TextArea rows={5} placeholder="Enter your text" {...props} />
      {children}
    </Container>
  );
};

Textbox.WordLimit = WordLimit;
Textbox.Toolbar = Toolbar;

export default Textbox;
