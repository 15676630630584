import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files: [],
  isSubNavOpen: false,
};

export const scheduler = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    setFiles: (state, action) => {
      return {
        ...state,
        files: action.payload,
      };
    },
    setSubNavOpen: (state) => {
      return {
        ...state,
        isSubNavOpen: true,
      };
    },
    setSubNavClose: (state) => {
      return {
        ...state,
        isSubNavOpen: false,
      };
    },
  },
});

export const { setFiles, setSubNavClose, setSubNavOpen } = scheduler.actions;

export default scheduler.reducer;
