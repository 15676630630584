import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showPotentialFilter: false,
  mostTweets: false,
  mostFollowers: false,
  mostFollowing: false,
  bioIncludes: [] as string[],
  bioExcludes: [] as string[],
  location: [] as string[],
  filterKeywords: [] as string[],
};

export const potentialCustomer = createSlice({
  name: "potentialCustomer",
  initialState,
  reducers: {
    setShowPotentialFilter: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showPotentialFilter: action.payload,
      };
    },
    setMostTweets: (state) => {
      return {
        ...state,
        mostTweets: true,
        mostFollowers: false,
        mostFollowing: false,
      };
    },
    setMostFollowers: (state) => {
      return {
        ...state,
        mostTweets: false,
        mostFollowers: true,
        mostFollowing: false,
      };
    },
    setMostFollowing: (state) => {
      return {
        ...state,
        mostTweets: false,
        mostFollowers: false,
        mostFollowing: true,
      };
    },
    setBioIncludes: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        bioIncludes: action.payload,
      };
    },
    setBioExcludes: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        bioExcludes: action.payload,
      };
    },
    setLocation: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        location: action.payload,
      };
    },
    setFilterKeywords: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        filterKeywords: action.payload,
      };
    },
  },
});

export const {
  setShowPotentialFilter,
  setMostTweets,
  setMostFollowers,
  setMostFollowing,
  setBioIncludes,
  setBioExcludes,
  setLocation,
  setFilterKeywords,
} = potentialCustomer.actions;

export default potentialCustomer.reducer;
