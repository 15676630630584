import Username from "./Username";
import IconButton from "./IconButton";
import ReplyingUsername from "./ReplyingUsername";
import Stat from "./Stat";
import {
  Content,
  Container,
  Head,
  Stats,
  Action,
  Image,
  Bio,
  TweetSearchBio,
  ScheduledAt,
  FlexBetweet,
} from "./TweetCard.styles";
import Keywords from "./Keywords";
import LikeButton from "./LikeButton";
import ReplyButton from "./ReplyButton";
import RetweetButton from "./RetweetButton";
import InstantReplyButton from "./InstantReplyButton";
import MenuIcon from "./MenuIcon";
import { LinkButton } from "components/core";
import DolphyEngagement from "./DolphyEngagement";
import EditButton from "./EditButton";

interface ITwitterCardProps {
  children?: React.ReactNode;
}

const TweetCard = ({ children }: ITwitterCardProps) => {
  return <Container>{children}</Container>;
};

TweetCard.Image = Image;
TweetCard.Content = Content;
TweetCard.Head = Head;
TweetCard.Username = Username;
TweetCard.Action = Action;
TweetCard.IconButton = IconButton;
TweetCard.Bio = Bio;
TweetCard.TSBio = TweetSearchBio;
TweetCard.Stats = Stats;
TweetCard.Stat = Stat;
TweetCard.Keywords = Keywords;
TweetCard.ReplyingUsername = ReplyingUsername;
TweetCard.LikeButton = LikeButton;
TweetCard.ReplyButton = ReplyButton;
TweetCard.RetweetButton = RetweetButton;
TweetCard.InstantReplyButton = InstantReplyButton;
TweetCard.MenuIcon = MenuIcon;
TweetCard.LinkButton = LinkButton;
TweetCard.Engagement = DolphyEngagement;
TweetCard.EditButton = EditButton;
TweetCard.ScheduledAt = ScheduledAt;
TweetCard.FlexBetweet = FlexBetweet;

export default TweetCard;
