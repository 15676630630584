import styled, { keyframes } from "styled-components";

const loadAnimation = keyframes`
0% {
	box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
		0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}

5%,
95% {
	box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
		0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}

10%,
59% {
	box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
		-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
		-0.297em -0.775em 0 -0.477em;
}

20% {
	box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
		-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
		-0.749em -0.34em 0 -0.477em;
}

38% {
	box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
		-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
		-0.82em -0.09em 0 -0.477em;
}

100% {
	box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
		0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
}
`;

const roundAnimation = keyframes`
0% {
	transform: rotate(0deg);
}

100% {
	transform: rotate(360deg);
}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Graphic = styled.div`
  animation: ${loadAnimation} 2s infinite ease,
    ${roundAnimation} 2s infinite ease;
  border-radius: 50%;
  color: black;
  font-size: 20px;
  height: 1em;
  width: 1em;
  margin: 5rem;
  color: ${({ theme }) => theme.colors.alpha};
  font-size: 3rem;
`;

const Message = styled.div`
  color: ${({ theme }) => theme.colors.alpha};
  font-size: 1.5rem;
`;

export { Container, Graphic, Message };
