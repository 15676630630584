import { css } from "styled-components";
import Border from "./border";
import Color from "./color";
import Font from "./font";
import Position from "./position";
import Shadow from "./shadow";
import Size from "./size";
import Spacing from "./spacing";
import FormControl from "./formControl";

class ThemeHelper {
  font = new Font();
  spacing = new Spacing();
  color = new Color();
  position = new Position();
  size = new Size();
  shadow = new Shadow();
  border = new Border();
  formControl = new FormControl();

  getGeneralSize = this.size.getGeneralSize;

  getFontSize = this.font.getFontSize;

  getLineHeight = this.font.getLineHeight;

  getFontWeight = this.font.getFontWeight;

  getSpacing = this.spacing.getSpacing;

  getMargins = this.spacing.getMargins;

  getPaddings = this.spacing.getPaddings;

  getComplementaryColor = this.color.getComplementaryColor;

  getPosition = this.position.getPosition;

  lighten = this.color.lighten;

  darken = this.color.darken;

  transparentize = this.color.transparentize;

  getBoxShadow = this.shadow.getBoxShadow;

  getBorder = this.border.getBorder;

  getBorderRadius = this.border.getBorderRadius;

  containerize = (width: number = 90) => {
    return css`
      margin: 0 auto;
      width: min(${width}%, 1400px);
    `;
  };

  centerContent = () => {
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
    `;
  };

  getStyledScrollbar = (size: number = 0.2) => {
    return css`
      ::-webkit-scrollbar {
        width: ${size}rem;
        height: ${size}rem;
      }
      ::-webkit-scrollbar-track {
        background-color: unset;
      }
    `;
  };
}

export default ThemeHelper;
