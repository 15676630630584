import { TwitterService } from "services/Auth";
import { TAddInfluencerPayload } from "./types";

type TAPIResponse = {
  message: string;
};

class AddInfluencerAdapter {
  private twitter = new TwitterService();
  private parseResponse = (apiResponse: TAPIResponse) => {
    if (apiResponse.message) {
      return {
        message: apiResponse.message,
      };
    }
  };

  getConfig = () => ({ headers: this.twitter.getHeadersOrError() });

  getPostUrl = (url: string, tweetCard: TAddInfluencerPayload) =>
    `${url}/${tweetCard.leadUsername}`;

  getPostPayload = (data: TAddInfluencerPayload) => {
    return {
      keyword: data.word,
    };
  };

  parsePostResponse = this.parseResponse;
}

export default AddInfluencerAdapter;
