import { TFacebookPlatformDropdownItem } from "context/SocialMediaPost/types";
import { useAppSelector } from "redux/store/hooks";

const useFacebookPages = () => {
  const accountUid = useAppSelector(
    (state) => state.auth.facebookSelection.uid
  );
  const pages = useAppSelector(
    (state) => state.auth.facebookSelection.selectedAccountPages
  );

  const options = pages.map((option) => ({
    id: option.id,
    title: option.name,
    image: option.profileImageUrl,
  })) as TFacebookPlatformDropdownItem[];

  return {
    accountUid,
    options,
  };
};

export default useFacebookPages;
