import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const InstantReplyGrid = styled.div`
  margin-top: ${helper.getSpacing("small")};
  display: grid;
  grid-template-columns: 6fr 7fr;
  column-gap: ${helper.getSpacing("small")};
  height: 85vh;
  overflow: auto;
  ${helper.getStyledScrollbar()};
`;

const TweetCardContainer = styled.div`
  padding: 4px 4px;
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("extraSmall")};
  height: 85vh;
  overflow: auto;
  ${helper.getStyledScrollbar()};
`;

export { TweetCardContainer, InstantReplyGrid };
