import { Text } from "components/core";
import styled from "styled-components";

const Container = styled.div`
  padding: 0.5rem;
`;

interface IWordLimitProps extends React.ComponentPropsWithoutRef<"div"> {
  remaining: number;
}

const WordLimit = ({ remaining, ...props }: IWordLimitProps) => {
  return (
    <Container {...props}>
      <Text
        style={{ textAlign: "right", fontSize: "12px" }}
        variant="lightGrey"
        content={`${remaining}`}
      />
    </Container>
  );
};

export default WordLimit;
