import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const TopSectionWrapper = styled.div`
  padding-top: ${helper.getSpacing("extraSmall")};
  #twitter-recommendation {
    ${helper.getStyledScrollbar()};
  }
`;

export { TopSectionWrapper };
