import { TPlan, TPlanDetails } from "./types";

type TAPIResponse = {
  currency: string;
  payment_method: string;
  plan_type: string;
  expiry: string;
  payment_details: {
    title: string;
    value: string;
  }[];
};

class PlanDetailsAdapter {
  private parseResponse = (data: TAPIResponse): TPlanDetails => {
    const details: TPlanDetails = {
      currency: data.currency || null,
      paymentMethod: data.payment_method || null,
      plan: data.plan_type as TPlan,
      expiry: data.expiry || null,
      paymentDetails: data.payment_details ?? [],
    };

    return details;
  };

  parseGetResponse = (data: TAPIResponse): TPlanDetails => {
    return this.parseResponse(data);
  };
}

export default PlanDetailsAdapter;
