import { Icon } from "components/core";
import { useRef } from "react";

interface IFileAttacherProps
  extends Omit<
    React.ComponentPropsWithoutRef<"input">,
    "type" | "onChange" | "hidden"
  > {
  onChange: (file: File[]) => void;
}

const FileAttacher = ({ onChange, ...props }: IFileAttacherProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <>
      <Icon name="attach" onClick={handleIconClick} variant="lightGrey" />
      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={(e) => onChange(Array.from(e.target.files || []))}
        {...props}
      />
    </>
  );
};

export default FileAttacher;
