import { Header as BaseHeader, StrictHeaderProps } from "semantic-ui-react";
import styled, { DefaultFonts } from "styled-components";
import ThemeHelper from "theme/themeHelper";
import {
  IDesignSystemProps,
  TFontSizes,
  TFontWeights,
  TMarginProps,
} from "theme/types";

const helper = new ThemeHelper();

interface IStyledHeaderProps extends TMarginProps {
  variant?: IDesignSystemProps["variant"];
  fontWeight?: keyof TFontWeights;
  font?: keyof DefaultFonts;
  size?: keyof TFontSizes;
  style?: React.CSSProperties;
}

interface IHeaderOwnProps<T extends React.ElementType>
  extends Omit<StrictHeaderProps, "size">,
    IStyledHeaderProps {
  as?: T;
}

export type IHeaderProps<T extends React.ElementType> = IHeaderOwnProps<T> &
  Omit<React.ComponentProps<T>, keyof IHeaderOwnProps<T>>;

const StyledHeader = styled(BaseHeader)<IStyledHeaderProps>`
  &&& {
    font-family: ${({ font = "playfair", theme }) => theme.fonts[font]};
    color: ${({ variant, theme }) => variant && theme.colors[variant]};
    margin: unset;
    ${({ size = "small" }) => helper.getFontSize(size)};
    ${({ fontWeight = "medium" }) => helper.getFontWeight(fontWeight)};
    ${({ mt, ms, mb, me }) =>
      helper.getMargins({ top: mt, bottom: mb, left: ms, right: me })};
  }
`;

const Header = <T extends React.ElementType = "div">({
  as,
  children,
  ...props
}: IHeaderProps<T>) => (
  <StyledHeader forwardedAs={as as any} {...props}>
    {children}
  </StyledHeader>
);

export default Header;
