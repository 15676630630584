class GraphHelper<T extends Record<string, any>> {
  private raiseToPower(payload: number, base: number, power: number) {
    return payload * Math.pow(base, power);
  }

  findHighestValue = (data: T[]) => {
    /* If array is empty return 0 as highest value */
    if (!data.length) return 0;

    /* Map through array of keys in first object of data and find highest number out of it. */
    const highestValuesList = Object.keys(data[0]).map((p) => {
      const param = p as keyof T;
      const result = data.reduce((prev, current) =>
        prev[param] > current[param] ? prev : current
      )[param];
      if (typeof result === "number") return result as number;
      /* If not a number return 0 */
      return 0;
    });

    /* Find highest value from  highestValuesList*/
    const highestValue = highestValuesList.reduce(
      (prev, current) => (prev > current ? prev : current),
      0
    );

    return highestValue;
  };

  converToMultiple = (payload: number, multipleOf = 10) => {
    const payloadString = String(Math.ceil(payload));
    const totalDigits = payloadString.length - 1;
    const firstDigit = parseInt(payloadString[0], 10);
    const lastDigit = parseInt(payloadString[totalDigits], 10);

    const raiseToPower = (digit: number) =>
      this.raiseToPower(digit, multipleOf, totalDigits);

    if (payload <= 1) return 1;
    if (payload <= 10) return 10;
    if (payload <= 100) return 100;
    if (lastDigit === 0) return raiseToPower(firstDigit);
    return raiseToPower(firstDigit + 1);
  };
}

export default GraphHelper;
