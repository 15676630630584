import { Fragment } from "react";
import { Flex } from "components/core";
import { TPreviewUrl } from "helpers/FileHelper";
import { Container, CloseIcon } from "./Preview.styles";
import CSVPreview from "assets/images/common/csv-preview.jpg";

interface IPreviewProps extends React.ComponentPropsWithoutRef<"div"> {
  previewUrls: TPreviewUrl[];
  onDelete: (url: TPreviewUrl) => void;
}

const Preview = ({ previewUrls, onDelete, ...props }: IPreviewProps) => {
  const renderImagePreview = (url: TPreviewUrl) => {
    return (
      <Container>
        <CloseIcon name="close" onClick={() => onDelete(url)} />
        <img src={url.url} alt="preview" />
      </Container>
    );
  };

  const renderVideoPreview = (url: TPreviewUrl) => {
    return (
      <Container>
        <CloseIcon name="close" onClick={() => onDelete(url)} />
        <video controls>
          <source src={url.url} type="video/mp4" />
        </video>
      </Container>
    );
  };

  const renderTextPreview = (url: TPreviewUrl) => {
    return (
      <Container>
        <CloseIcon name="close" onClick={() => onDelete(url)} />
        <img src={CSVPreview} alt="preview" />
      </Container>
    );
  };

  const renderPreview = (url: TPreviewUrl) => {
    switch (url.type) {
      case "image":
        return renderImagePreview(url);

      case "video":
        return renderVideoPreview(url);

      case "text":
        return renderTextPreview(url);

      default:
        return null;
    }
  };

  if (!previewUrls) return null;

  return (
    <Flex justify="flex-start" align="center" gap="small" {...props}>
      {previewUrls.map((url, key) => (
        <Fragment key={key}>{renderPreview(url)}</Fragment>
      ))}
    </Flex>
  );
};

export default Preview;
