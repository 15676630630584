import { IPreferencesData } from "./types";

type TAPIResponse = {
  timeperiod: {
    years: number;
  };
  custom_timeperiod: {
    start_date: string;
    end_date: string;
  };
  country: {
    value: string;
    code: string;
  };
  available_countries: {
    value: string;
    code: string;
  }[];
};

class PreferencesAdapter {
  private parseResponse = (apiResponse: TAPIResponse): IPreferencesData => {
    const countryList = apiResponse.available_countries.map((country) => ({
      key: country.code,
      value: country.value,
      flag: country.code,
      text: country.value,
    }));
    return {
      startDate: new Date(apiResponse.custom_timeperiod.start_date),
      endDate: new Date(apiResponse.custom_timeperiod.end_date),
      countryList,
      currentCountry: apiResponse.country.value,
    };
  };

  parseGetResponse = (apiResponse: TAPIResponse) =>
    this.parseResponse(apiResponse);
}

export default PreferencesAdapter;
