import { transparentize } from "polished";
import { css, DefaultColors } from "styled-components";
import { TShadowIntensities, TShadowPosition } from "./types";

type TOptions = {
  intensity?: keyof TShadowIntensities;
  position?: TShadowPosition;
  important?: boolean;
};

class Shadow {
  private intensity: TShadowIntensities = {
    low: 100,
    medium: 200,
    high: 300,
  };

  getBoxShadow = (
    color: keyof DefaultColors = "darkGrey",
    options?: TOptions
  ) => {
    const {
      intensity = "low",
      position = "right",
      important = false,
    } = options || {};
    const offsetX = position === "right" ? `9px` : `-9px`;

    return css`
      box-shadow: ${offsetX} 9px 8px
        ${({ theme }) =>
          `${transparentize(
            (this.intensity[intensity] / 100) * 0.3,
            theme.colors[color]
          )} ${important ? "!important" : ""}`};
    `;
  };
}

export default Shadow;
