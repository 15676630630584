import { useDrop } from "react-dnd";
import { theme } from "theme";
import { ItemTypes } from "./ItemTypes";
import { RemoveIcon } from "../common/icons";
import { Container } from "./RemoveTarget.styles";

type RemoveTargetProps = {
  style?: React.CSSProperties;
};

const RemoveTarget = ({ style }: RemoveTargetProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: () => ({ name: ItemTypes.REMOVE }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = isOver && canDrop;
  const opacity = canDrop ? 0.77 : 0;
  const display = canDrop ? "grid" : "none";
  const backgroundColor = isOver
    ? theme.colors.mountainMeadow
    : theme.colors.bubbles;

  return (
    <Container
      ref={drop}
      style={{ opacity, display, backgroundColor, ...style }}
    >
      <RemoveIcon />
      {isActive ? "Release to drop" : "Drop for remove it"}
    </Container>
  );
};

export default RemoveTarget;
