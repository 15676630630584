import { TRegistrationStatus } from "./types";

type TAPIResponse = {
  country: boolean;
  email: boolean;
  keywords: boolean;
  occupation: boolean;
  product: boolean;
  referral: boolean;
  timezone_language: boolean;
};

class RegistrationStatusAdapter {
  private parseResponse = (data: TAPIResponse): TRegistrationStatus => {
    const isCountry: boolean = data.country || false;
    const isEmail: boolean = data.email || false;
    const isKeywords: boolean = data.keywords || false;
    const isOccupation: boolean = data.occupation || false;
    const isProduct: boolean = data.product || false;
    const isReferral: boolean = data.referral || false;
    const isTimezoneLanguage: boolean = data.timezone_language || false;

    return {
      isCountry,
      isEmail,
      isKeywords,
      isOccupation,
      isProduct,
      isReferral,
      isTimezoneLanguage,
    };
  };

  parseGetResponse = (data: TAPIResponse): TRegistrationStatus => {
    return this.parseResponse(data);
  };
}

export default RegistrationStatusAdapter;
