import { Dropdown } from "components/core";
import { countryListConfig } from "config/common";
import { Container } from "./SelectCountry.styles";
import { usePreferences } from "hooks/ProductDemand";

const SelectCountry = () => {
  const preferences = usePreferences();

  return (
    <Container>
      <Dropdown
        style={{ minWidth: "190px" }}
        options={countryListConfig}
        placeholder="Select country"
        search
        selection
        value={preferences.data.currentCountry}
        onChange={(e, { value }) =>
          typeof value === "string" &&
          preferences.handleUpdatePreferences({ currentCountry: value })
        }
      />
    </Container>
  );
};

export default SelectCountry;
