import { Header } from "components/core";
import { HeaderContainer } from "./ContentHeader.styles";
import Illustration from "./Illustration";

interface ContainerHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
}

const ContainerHeader = ({
  image,
  children,
  ...props
}: React.PropsWithChildren<ContainerHeaderProps>) => {
  return (
    <HeaderContainer {...props}>
      {image && <Illustration src={image} alt="register-illustration" />}
      <Header
        as="p"
        font="poppins"
        size="small"
        variant="darkGrey"
        textAlign="center"
      >
        {children}
      </Header>
    </HeaderContainer>
  );
};

export default ContainerHeader;
