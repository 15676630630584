import { AuthFormIllustration } from "./common";
import illustration from "assets/images/Auth/signin-illustration.png";

const SigninIllustration = () => {
  return (
    <AuthFormIllustration
      header="Welcome Back!"
      subHeader="Enter your details to dive-in to the dashboard"
      illustration={illustration}
    />
  );
};

export default SigninIllustration;
